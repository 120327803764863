import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';

import { withRouter } from 'react-router-dom';
import { getDateTimeFormat } from 'lib/formatter';

const ServiceReportTable = ({
  translate,
  history,
  serviceReports,
  setQuery,
  query,
}) => {
  const columns = [
    {
      field: 'reportNo',
      title: translate('reportNo'),
      render: (rowData) => {
        return <Typography>{rowData.reportNumber}</Typography>;
      },
      sorting: false,
    },
    {
      field: 'type',
      title: translate('type'),
      render: (rowData) => {
        return (
          <Typography>
            {translate(`${rowData.reportFor}_${rowData.reportType}`)}
          </Typography>
        );
      },
      sorting: false,
    },
    {
      field: 'issuedAt',
      title: translate('issuedAt'),
      render: (rowData) => {
        return (
          <Grid>
            <Typography>
              {moment(rowData.createdAt).format(getDateTimeFormat())}
            </Typography>
            {rowData.createdBy && (
              <Typography>
                {rowData.createdBy.fullName}
              </Typography>
            )}
          </Grid>
        );
      },
      sorting: false,
    },
    {
      field: 'lastUpdated',
      title: translate('lastUpdated'),
      render: (rowData) => {
        return (
          <Grid>
            {moment(rowData.updatedAt).format(getDateTimeFormat())}
            {rowData.createdBy && (
              <Typography>
                {rowData.createdBy.fullName}
              </Typography>
            )}
          </Grid>
        );
      },
      sorting: false,
    },
    {
      field: 'Status',
      title: translate('Common:status'),
      render: (rowData) => {
        return <Typography>{rowData.status}</Typography>;
      },
      sorting: false,
    },
  ];

  const handleClickReportRow = async (data) => {
    history.push(`${data.jobId}/service-report/${data.id}/view`);
  };

  const handleChangePage = (page, rowPerPage) => {
    setQuery({ ...query, skip: page * rowPerPage, limit: rowPerPage });
  };

  const options = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    actionsColumnIndex: -1,
    search: false,
    draggable: false,
  };

  return (
    <MaterialTable
      title={null}
      columns={columns}
      options={options}
      page={serviceReports.page}
      totalCount={serviceReports.total}
      data={serviceReports.list}
      onChangePage={handleChangePage}
      onRowClick={(event, rowData) => handleClickReportRow(rowData)}
      components={{
        Toolbar: (props) => (
          <div
            style={{
              height: '0px',
            }}
          >
            <MTableToolbar {...props} />
          </div>
        ),
      }}
    />
  );
};

export default withRouter(ServiceReportTable);

import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Custom components
import { ClientForm } from '../components';
// Redux
import { connect } from 'react-redux';

import { compose } from 'redux';

import { withRouter } from 'react-router-dom';
// Dispatch
import { saveClientDetails } from 'redux/client';

// Notify to show message
import { showErrorMessage } from 'lib/notifier';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

const mapDispatchToProps = {
  saveClientDetails
};

class NewClient extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.prepareClientDataBeforeSave = this.prepareClientDataBeforeSave.bind(this);
    this.handleCancelClientForm = this.handleCancelClientForm.bind(this);
  }

  handleCancelClientForm() {
    this.props.history.goBack();
  }

  handleSubmit(values) {
    const { saveClientDetails, history } = this.props;
    const clientDetails = this.prepareClientDataBeforeSave(values);

    saveClientDetails(clientDetails, (response) => {
      if (response.status === 200) {
        history.push('/clients');
      } else {
        showErrorMessage(response.data.message);
      }
    });
  }

  prepareClientDataBeforeSave(values) {
    const { properties } = values;

    const isBillingAddress = properties.find(item => item.isBillingAddress);
    if (isBillingAddress) {
      // Init billing address base on property details
      const billingAddess = {
        address: isBillingAddress.address,
        unitNumber: isBillingAddress.unitNumber,
        city: isBillingAddress.city,
        zipCode: isBillingAddress.zipCode,
        state: isBillingAddress.state,
        countryCode: isBillingAddress.countryCode,
      };

      Object.assign(values, { billingAddress: billingAddess });
    }

    return values;
  }

  render() {
    const { classes, translate } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <ClientForm
              onSubmit={values => this.handleSubmit(values)}
              handleCancelClientForm={this.handleCancelClientForm}
              translate={translate}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

NewClient.propTypes = {
  classes: PropTypes.object.isRequired
};


export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(NewClient);

import React from 'react';
import { Button, FormLabel, Grid, IconButton } from '@material-ui/core';
// Redux
import { Field } from 'redux-form';

import { DeleteRounded as DeleteIcon } from '@material-ui/icons';
import { TextInput, ReactSelect } from 'components/inputs';

const taxComponentObject = {
  pk: null,
  name: null,
  taxRateIds: []
};

const TaxComponents = props => {
  const { styles, fields, translate, taxRates } = props;
  const taxRateOptions = taxRates.map(item => {
    if (!item.name) return undefined;

    const labels = [item.name];
    if (item.percentage) {
      labels.push(`(${item.percentage || 0} %)`);
    }
    return { label: labels.join(' ') , value: item.id, percentage: item.percentage };
  }).filter(Boolean);


  function getTaxApplicable(index) {
    const target = fields.get(index);
    const taxApplicable = (target.taxRateIds || []).reduce((sum, id) => {
      return sum + parseFloat(((taxRates.find(e => e.id === id) || {}).percentage || 0));
    }, 0);
    return taxApplicable;
  }

  function addTaxComponent() {
    fields.push(taxComponentObject);
  }

  function handleDeleteTaxComponent(event, index) {
    event.preventDefault();
    fields.remove(index);
  }

  function handleChangeOption(options, index) {
    let taxApplicable = 0;
    if (options && options.length > 0) {
      taxApplicable = options.reduce((sum, id) => {
        return sum + parseFloat(((taxRates.find(e => e.id === id) || {}).percentage || 0));
      }, 0);
    }
    fields.get(index).taxApplicable = taxApplicable;
  }

  return (
    <Grid item container>
      <Grid item xs={12} sm={12} md={12} className={styles.labelActionHeader}>
        <FormLabel component="legend" className={styles.sectionLabel}>
          { translate('CompanySetting:taxComponents') }</FormLabel>
        <Button onClick={addTaxComponent} className={styles.addBtn}>
          { translate('CompanySetting:addTaxComponent') }</Button>
      </Grid>
      {
        fields.map((field, index) => {
          return (
            <Grid container direction='row' justify='center' alignItems="center" spacing={2} key={index}>
              <Grid item lg={5} md={4} sm={6}>
                <Field
                  name={`${field}.name`}
                  margin="normal"
                  component={TextInput}
                  label={translate('CompanySetting:taxComponentTitle')}
                  variant='outlined'
                  required
                  index={index}
                />

              </Grid>
              <Grid item lg={5} md={5} sm={6}>
                <Field
                  name={`${field}.taxRateIds`}
                  component={ReactSelect}
                  label={translate('CompanySetting:includedTaxRates')}
                  margin="normal"
                  options={taxRateOptions}
                  className={styles.taxRateSelector}
                  classNamePrefix="select"
                  placeholder=""
                  required
                  isMulti
                  onChange={options => handleChangeOption(options, index)}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={12}>
                <label htmlFor="">
                  {translate('CompanySetting:taxApplicable')}: {getTaxApplicable(index)}%
                </label>
                <IconButton aria-label='Delete' onClick={event => handleDeleteTaxComponent(event, index)}>
                  <DeleteIcon style={{ width: 30, height: 30, color: 'red' }} />
                </IconButton>
              </Grid>
            </Grid>
          );
        })
      }
    </Grid>
  );
};

export default TaxComponents;

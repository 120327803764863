import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { deleteEnterpriseAccount } from "redux/company";

import { EnterpriseDetails } from "..";

import {
  DeleteOutlined as DeleteOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";
import { showErrorMessage, showSuccessMessage } from "lib/notifier";

import styles from "../../details.module.scss";

const GeneralInfo = (props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    t,
    companyDetails,
    history,
    match: { params },
  } = props;

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClickEdit = () => history.push(`${params.enterpriseId}/edit`);

  const handleConfirmDelete = async () => {
    const response = await dispatch(
      deleteEnterpriseAccount(params.enterpriseId)
    ).catch((e) => ({ e }));
    if (response.e) return showErrorMessage(response.e);
    showSuccessMessage(
      t("companyArchived", {
        companyName: companyDetails.tradingName || companyDetails.name,
      })
    );
    history.push("/enterprise");
  };

  return (
    <Card className="p_25">
      {/* dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography variant="h4">{t("deleteEnterpriseAccount")}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>{t("enterpriseDescription")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            {t("Common:close")}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            className={styles.confirmDelete}
            autoFocus
          >
            {t("Common:delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container
        justify="flex-end"
        direction="row"
        alignItems="center"
        className="mb_10"
      >
        <Grid item>
          <Button onClick={handleClickOpen}>
            <DeleteOutlinedIcon className={`mr_5 ${styles.deleteText}`} />
            <Typography className={styles.deleteText}>
              {t("deleteAccount")}
            </Typography>
          </Button>

          <Button onClick={handleClickEdit}>
            <EditOutlinedIcon className="mr_5" />
            <Typography className={styles.fontBoldWeight}>
              {t("Common:edit")}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <EnterpriseDetails t={t} companyDetails={companyDetails} />
      </Grid>
    </Card>
  );
};

export default GeneralInfo;

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { formatPrice } from 'lib/formatter';

const ServiceOnly = (props) => {
  const { translate, itemDetails } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align='left'>{translate('productService')}</TableCell>
          <TableCell align='left'>{translate('description')}</TableCell>
          <TableCell align='left'>{translate('quantity')}</TableCell>
          {/* <TableCell align="left">{translate('unitPrice')}</TableCell>
          <TableCell align="left">{translate('total')}</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(itemDetails) &&
          itemDetails.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell align='left'>{row.name}</TableCell>
                <TableCell style={{ minWidth: 150 }} align='left'>
                  {row.description}
                </TableCell>
                <TableCell align='left'>{row.quantity}</TableCell>
                {/* <TableCell style={{ minWidth: 150 }} align="left">
                  {formatPrice(row.unitPrice, row.currency)}
                </TableCell>
                <TableCell style={{ minWidth: 150 }} align="left">
                  {formatPrice(row.total, row.currency)}
                </TableCell> */}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default ServiceOnly;

import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { updateJobDetails } from 'redux/job';

// Material helpers
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'pages/Dashboard/components';

// Material components
import {
  Button,
  Grid,
  Typography,
  withStyles, Popover
} from '@material-ui/core';

// Material icons
import {
  CalendarTodayOutlined as CalendarIcon, Room, AccessAlarm
} from '@material-ui/icons';

import { formatDate, getTimeFormat } from 'lib/formatter';
// Component styles
import { JobDetailsModalStyles } from './styles';

import Countries from 'lib/countryList';
import { showErrorMessage } from 'lib/notifier';


let JobDetailsModal = props => {
  const {
    anchorEl,
    classes,
    showJobDetails,
    handleCloseJobDetails,
    currentJobVisit,
    afterAssignedTeam,
    translate,
    workerDetails
  } = props;

  const { job: { client: { displayName } = {} } = {} } = currentJobVisit;
  const clientName = displayName;

  const clientAddress = currentJobVisit && currentJobVisit.job && currentJobVisit.job.property &&
    [ currentJobVisit.job.property.address,
      currentJobVisit.job.property.city,
      currentJobVisit.job.property.state,
      currentJobVisit.job.property.zipCode,
      new Countries().countryName(currentJobVisit.job.property.countryCode)
    ].filter(Boolean).join(', ');

  function handleAssignJobToWorker() {
    const values = {
      id: currentJobVisit.job && currentJobVisit.job.id,
      team: [{ 'id': workerDetails.id }]
    };

    props.updateJobDetails(values).then(response => {
      if (response.status === 200) {
        afterAssignedTeam();
      }
    }, error => {
      showErrorMessage(error);
    });
  }

  return (
    <Popover
      id="rbc-selected"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={showJobDetails}
      onClose={handleCloseJobDetails}
      anchorEl={anchorEl}
    >
      <Portlet className={classes.portletWrap}>
        <PortletHeader className={classes.portletHeader}>
          <Grid container spacing={2} direction='row' className={classes.section}>
            <PortletLabel title={`${translate('Job:jobId')}: ${currentJobVisit && currentJobVisit.job.publicId}`} />
          </Grid>
        </PortletHeader>
        <PortletContent className={classes.portletContent}>
          <Grid container spacing={2} direction='row' className={classes.section}>
            <Grid item xl={8} lg={8} md={8} xs={8}>
              <Typography variant='h5' className={classes.clientName}>
                { clientName }
              </Typography>
              <div className={classes.jobDescription}>{currentJobVisit && currentJobVisit.title}</div>
            </Grid>

          </Grid>

          <Grid container spacing={2} direction='row' className={classes.section}>
            <Room className={classes.iconStyle} />
            <div className={classes.contentStyle}>
              { clientAddress }
            </div>

          </Grid>

          <Grid container spacing={2} direction='row' className={classes.section}>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              {translate('Job:startJob')}
            </Grid>

            <Grid item xl={8} lg={8} md={8} xs={8}>
              <CalendarIcon className={classes.iconStyle} />
              <div className={classes.contentStyle}>
                {
                  currentJobVisit && currentJobVisit.start &&
                    formatDate(currentJobVisit.start, 'DD MMMM YYYY')
                }
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={4}>
              <AccessAlarm className={classes.iconStyle} />
              <div className={classes.contentStyle}>
                {
                  currentJobVisit && currentJobVisit.start &&
                    formatDate(currentJobVisit.start, getTimeFormat())
                }
              </div>
            </Grid>

          </Grid>

          <Grid container spacing={2} direction='row' className={classes.section}>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              {translate('Job:endJob')}
            </Grid>
            <Grid item xl={8} lg={8} md={8} xs={8}>
              <CalendarIcon className={classes.iconStyle} />
              <div className={classes.contentStyle}>
                {
                  currentJobVisit && currentJobVisit.end &&
                    formatDate(currentJobVisit.end, 'DD MMMM YYYY')
                }
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={4}>
              <AccessAlarm className={classes.iconStyle} />
              <div className={classes.contentStyle}>
                {
                  currentJobVisit && currentJobVisit.end &&
                    formatDate(currentJobVisit.end, getTimeFormat())
                }
              </div>
            </Grid>

          </Grid>

          <Grid container spacing={2} direction='row' className={classes.section}>
            {
              currentJobVisit &&
                currentJobVisit.notes &&
                <div className={classes.noteWrap}>
                  { currentJobVisit.notes }
                </div>
            }

          </Grid>

          <Grid container spacing={2} direction='row' justify="flex-end" className={classes.section}>
            <Button
              className={classes.btnStyles}
              onClick={handleCloseJobDetails}
            >
              {translate('Common:cancel')}
            </Button>
            <Button
              className={classes.btnStyles}
              onClick={() => handleAssignJobToWorker(currentJobVisit)}
            >
              {translate('Tracking:assign')}
            </Button>
          </Grid>

        </PortletContent>
      </Portlet>
    </Popover>
  );

};


const mapStateToProps = (state, ownProps) => {
  const team = Object.fromEntries((
    ownProps.currentJobVisit &&
    ownProps.currentJobVisit.team &&
    ownProps.currentJobVisit.team.map(item => [item.id, true])
  ) || []);
  return ({
    initialValues: {
      id: (ownProps.currentJobVisit && ownProps.currentJobVisit.id) || null,
      team
    },

    staffs: state.staff.staffs,
    assignees: team,
    jobDetailsFormValue: state.form.jobForm && state.form.jobForm.values,
  });
};

const mapDispatchToProps = dispatch => ({
  clearTeam: () => { dispatch(change('jobForm', 'team', null)); },
  updateTeam: (team) => { dispatch(change('jobForm', 'team', team)); },
  updateJobDetails: (values) => dispatch(updateJobDetails(values)),
});

JobDetailsModal = reduxForm({
  form: 'jobForm',
})(JobDetailsModal);

export default compose(
  withStyles(JobDetailsModalStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(JobDetailsModal);
import React, { useState, useEffect } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material components
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Grid,
  Typography,
  withStyles,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getStaffs, getTimesheetStaffs } from 'redux/staff';
// Component styles
import styles from './style';

import { showErrorMessage } from 'lib/notifier';
import { TimesheetTab } from '../components/utils';
import scssStyles from './styles.module.scss';

const wmLogo = require('assets/images/circle-human.png');
const preSelectImg = require('assets/images/pre-select-staff.png');

class TimesheetList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffList: [],
      staffTimesheets: [],
      currentStaffId: -1,
      selectedMonth: -1,
      fromDate: null,
      toDate: null,
      anchorElDetailsPopup: null,
      jobList: [],
      visitDetails: {},
      openVisitDetails: false,
      workedHours: 0,

      $from: moment().startOf('month').format(),
      $to: moment().endOf('month').format(),
      total: 0,
      rowsPerPage: 10,
      page: 0,
    };
    this.handleSelectStaff = this.handleSelectStaff.bind(this);
  }

  componentDidMount() {
    this.fetchStaffs();
  }

  handleSelectStaff = (currentStaffId) => {
    this.setState({ currentStaffId });
  };

  async fetchStaffs() {
    const {
      error,
      data,
    } = await this.props.getTimesheetStaffs().catch((e) => ({ error: e }));
    if (error) return showErrorMessage(error);
    this.setState({ staffList: data.data });
  }

  render() {
    const { classes, translate } = this.props;
    const { currentStaffId } = this.state;
    return (
      <div className={classes.root}>
        <Grid container className={scssStyles.gridContainer} justify="center">
          <Grid className={scssStyles.staffList} item lg={3} xs={4}>
            <RenderStaffList
              staffList={this.state.staffList}
              translate={translate}
              classes={classes}
              currentStaffId={currentStaffId}
              handleSelectStaff={this.handleSelectStaff}
            />
          </Grid>
          <Grid className={classes.tableContainer} item lg={9} xs={8}>
            {currentStaffId !== -1 ? (
              <TimesheetTab
                currentStaffId={currentStaffId}
                translate={translate}
              />
            ) : (
              <Paper className={classes.portlet}>
                <div className={scssStyles.imgContainer}>
                  <img
                    className={classes.preSelectImg}
                    src={preSelectImg}
                    alt="Contemplative Reptile"
                  />
                  <div>{translate('Timesheet:selectStaffToSeeTimesheet')}</div>
                </div>
              </Paper>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

TimesheetList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  jobStatuses: state.config.configs.jobStatuses || [],
});

const mapDispatchToProps = (dispatch) => ({
  getStaffs: (params) => dispatch(getStaffs(params)),
  getTimesheetStaffs: (params) => dispatch(getTimesheetStaffs(params)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(TimesheetList);

// Component to render staff list =======
const RenderStaffList = (props) => {
  const {
    staffList,
    classes,
    handleSelectStaff,
    translate,
    currentStaffId,
  } = props;
  const [list, setList] = useState([]);
  const filterStaff = (query) => {
    const filteredList = staffList.filter((staff) => {
      const fullname = staff.fullName.toLowerCase();
      return fullname.includes(query.toLowerCase());
    });
    setList(filteredList);
  };
  useEffect(() => {
    if (Array.isArray(staffList)) setList(staffList);
  }, [staffList]);
  return (
    <Paper className={classes.gridContainer}>
      <List className={classes.listStaff}>
        <Typography className={classes.listStaffTitle} variant="h3">
          {translate('Timesheet:staffList')}
        </Typography>
        <TextField
          classes={{ root: classes.searchBox }}
          className={classes.searchTextBox}
          placeholder="Search Staff"
          variant="filled"
          onChange={(e) => filterStaff(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {list.map((staff) => {
          return (
            <ListItem
              key={staff.id}
              classes={{ selected: classes.staffSelected }}
              className={classes.listItem}
              selected={staff.id === currentStaffId}
              onClick={() => handleSelectStaff(staff.id)}
            >
              <ListItemAvatar>
                <Avatar
                  alt="Staff Avatar"
                  src={staff.avatar ? staff.avatar : wmLogo}
                />
              </ListItemAvatar>
              <ListItemText
                classes={{ primary: scssStyles.itemText }}
                primary={staff.fullName}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default {
  addJob:	'+ งานใหม่',
  calendar:	'ปฏิทิน',
  allJobs:	'ปฏิทิน',
  unassignedJobs:	'งานที่ยังไม่ได้มอบหมาย',
  unscheduledJobs:	'งานที่ยังไม่ได้นัดหมาย',
  filter:	'ตัวกรอง',
  pendingAction:	'แจ้งเตือน',
  Month: 'เดือน',
  Week: 'อาทิตย์',
  Day: 'วัน',
  Today: 'วันนี้',
  newJob: 'งานใหม่',
  addToDate: 'เพิ่มไปที่',
  updateJobDetails: 'อัปเดตรายละเอียดงาน',
  jobId: 'รหัสงาน',
  visitDetails: 'รายละเอียดการเข้างาน',
  job: 'งาน',
  needSchedule: 'Need to schedule',
  needAssigned: 'Need to assign',
  visit: 'Visit {{visitId}}'
};
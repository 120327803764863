import { GET, DELETE, POST, PATCH } from 'lib/api';

const HUB_ROUTE = 'hub';


const initialState = {
  details: {},
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

// Action Creators
export function getHub(options = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return GET(HUB_ROUTE, accessToken, options)
      .then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function getHubWithParams(params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return GET(HUB_ROUTE, accessToken, { params })
      .then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function deleteHub(id, params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return DELETE(`/${HUB_ROUTE}/${id}`, accessToken, { params }).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export function getHubDetails(id, params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/${HUB_ROUTE}/${id}`, accessToken, { params }).then(response => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export function addHub(hubDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return POST(HUB_ROUTE, accessToken, { data: hubDetails }).then(response => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function updateHub(id, hubDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(`/${HUB_ROUTE}/${id}/`, accessToken, { data: hubDetails }).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function hubAction(actionPayload) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return POST(`/${HUB_ROUTE}/actions`, accessToken, { data: actionPayload }).then(response => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}
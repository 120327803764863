import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';


import {
  // Material components
  Grid,
  Typography,

  // Material helpers
  withStyles
} from '@material-ui/core';

// Component styles
import PaymentFormStyle from './styles';
import TextInput from 'components/inputs/TextInput';
import { DatePicker } from 'components/inputs';

const required = value => value ? undefined : 'Required';
const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined;
const minValue0 = minValue(0);
const maxValue = max => value => value && parseFloat(value) > parseFloat(max) ? `Must be at most ${max}` : undefined;

// the reason for this is to avoid infinite loop when we set maxValueAmountValidation
// because when we set maxValueAmountValidation
// it will rerender and call the function to set maxValueAmountValidation again
// and it will rerender again and again, infinite loop
let maxValueAmountValidation;
let maxValueAmount;

const mapStateToProps = state => {
  if (state.invoice.invoice.invoiceBalance !== maxValueAmount) {
    maxValueAmount = state.invoice.invoice.invoiceBalance;
    maxValueAmountValidation = maxValue(maxValueAmount);
  }
  return ({
  });
};

const mapDispatchToProps = dispatch => ({
});

const FormRow = props => {
  const { label, name, select, selectOptions, timeDate = false, classes, ...rest } = props;

  return (
    <Grid container direction='row' alignItems="center">
      <Grid container item>
        <Typography>
          {label}
        </Typography>
      </Grid>
      <Grid container item>
        {
          select && selectOptions ?
            <Field
              name={name}
              component={TextInput}
              margin="dense"
              required
              style={{ background: '#F2F2F2', borderRadius: 4 }}
              variant="outlined"
              select
              className={classes.customizedInput}
              SelectProps={{ native: true }}
              {...rest}
            >
              <option value='' />
              {
                selectOptions && selectOptions.length > 0 ?
                  selectOptions.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  )) :
                  <option key='' value='' />
              }
            </Field> :
            <Field
              name={name}
              component={timeDate ? DatePicker : TextInput}
              margin="dense"
              className={classes.customizedInput}
              style={{ background: '#F2F2F2', borderRadius: 4 }}
              required
              variant="outlined"
              {...rest}
            />
        }
      </Grid>
    </Grid>
  );
};

function PaymentForm(props) {
  const {
    appliedToOptions,
    formPaymentMethod,
    newPayment = false,
    paymentMethods,
    translate,
    classes
  } = props;

  return (
    <Grid>
      <FormRow
        label={translate('Client:paymentMethod')}
        name="paymentMethod"
        select
        classes={classes}
        selectOptions={paymentMethods}
      />
      <FormRow
        label={translate('Client:amount')}
        name="amount"
        classes={classes}
        validate={[required, number, minValue0, maxValueAmountValidation]}
      />
      {
        !newPayment &&
        <FormRow
          label={translate('Client:appliedTo')}
          name="appliedTo"
          select
          classes={classes}
          selectOptions={appliedToOptions}
        />
      }
      <FormRow
        label={translate('Client:paymentDate')}
        name="paymentDate"
        classes={classes}
        timeDate
      />
      {
        formPaymentMethod === 'CHEQUE' &&
        <FormRow
          label={translate('Client:chequeNumber')}
          name="chequeNumber"
          classes={classes}
        />
      }
      {
        (formPaymentMethod === 'CREDIT_CARD' || formPaymentMethod === 'BANK_TRANSFER') &&
        <FormRow
          label={translate('Client:reference')}
          name="reference"
          classes={classes}
        />
      }
      <FormRow
        label={translate('Client:details')}
        name="notes"
        multiline
        classes={classes}
        rows="3"
      />
    </Grid>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(PaymentFormStyle)
)(PaymentForm);
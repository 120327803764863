// Format client
function formatClient(client) {
  if (!client) return null;

  const { displayName, email, id, phoneNumber } = client;

  return {
    email,
    id,
    phoneNumber,
    name: displayName,
  };
}

// Format products
function formatProducts(products) {
  const items = [];

  if (products.length < 1) {
    const item = {};

    item.name = '';
    item.description = '';
    item.quantity = 1;
    item.unitPrice = '0.00';
    item.total = '0.00';

    items.push(item);
  } else {
    products.forEach((product) => {
      const item = {};

      item.name = product.name;
      item.description = product.description;
      item.quantity = product.quantity;
      item.unitPrice = product.unitPrice;
      item.total = product.total;
      item.inventories = product.inventories;

      items.push(item);
    });
  }
  return items;
}

// Format categories
// function formatCategories(categoryies) {
//   if (!categoryies || categoryies.length < 1) {
//     return;
//   };

//   const result = {};

//   categoryies.forEach(category => {
//     result[category.id] = true;
//   });

//   return result;
// };

// Format products from quote items
function formatProductsQuote(products) {
  const items = [];

  if (products.length < 1) {
    const item = {};

    item.name = '';
    item.description = '';
    item.quantity = 1;
    item.unitPrice = '0.00';
    item.total = '0.00';

    items.push(item);
  } else {
    products.forEach((product) => {
      const item = {};

      item.name = product.name;
      item.description = product.description;
      item.quantity = product.quantity;
      item.unitPrice = product.unitPrice;
      item.total = product.totalBeforeTax;

      items.push(item);
    });
  }

  return items;
}

// Format client
function formatClientQuote(client) {
  if (!client) {
    return;
  }

  const { id, displayName, contactEmails, contactPhones } = client;

  const contactEmail =
    contactEmails.length > 0 &&
    contactEmails.find((mail) => mail.typeId === 'MAIN');
  const contactPhoneNumber =
    contactPhones.length > 0 &&
    contactPhones.find((phone) => phone.typeId === 'MAIN');

  const value = {
    id,
    email: (contactEmail && contactEmail.email) || null,
    name: displayName,
    phoneNumber: (contactPhoneNumber && contactPhoneNumber.phoneNumber) || null,
  };

  return value;
}

// format quote to request
export function formatRequestFromQuote(quoteValue) {
  const {
    quoteId,
    client,
    description,
    id,
    items,
    notes,
    property,
    requestTitle,
  } = quoteValue;

  const values = {};
  values.id = id || null;
  values.selectedClient = formatClientQuote(client);
  values.selectedProperty = property || null;
  values.title = requestTitle || null;
  values.description = description || null;
  values.internalNotes = notes || null;
  values.team = {};
  values.quoteId = quoteId;
  // Convert Product
  const formattedProducts = formatProductsQuote(items);
  values.products = formattedProducts || [];

  return values;
}

export default (requestValues = {}) => {
  const {
    archived,
    client,
    description,
    end,
    publicId,
    items,
    notes,
    number,
    property,
    start,
    statusId,
    title,
    additionalSettings,
    categories,
    orderId,
    deliveryNote,
    hub,
  } = requestValues;

  const values = {};
  values.publicId = publicId || null;
  values.status = archived || statusId || null;
  values.archived = archived || null;
  values.requestNumber = number || null;
  values.selectedClient = formatClient(client);
  values.selectedProperty = property || null;
  values.title = title || null;
  values.description = description || null;
  values.internalNotes = notes || null;
  values.startTime = start && end ? start : null;
  values.endTime = start && end ? end : null;
  values.startDate = start && end ? start : null;
  values.endDate = start && end ? end : null;
  values.additionalSettings = additionalSettings || [
    {
      skipOnTheWayTracking: false,
      skipArrivedTracking: false,
    },
  ];

  values.categories = categories || [];
  values.products = formatProducts(items) || [];
  values.orderId = orderId;
  values.dn = deliveryNote;
  values.hub = hub && { label: hub.name, value: hub.id };

  return values;
};

export default {
  addJob:	'+ New Job',
  calendar:	'Calendar',
  allJobs:	'All Jobs',
  unassignedJobs:	'Unassigned Jobs',
  unscheduledJobs:	'Unscheduled Jobs',
  filter:	'Filter',
  pendingAction:	'Pending Action',
  Month: 'Month',
  Week: 'Week',
  Day: 'Day',
  Today: 'Today',
  newJob: 'New Job',
  addToDate: 'Add to',
  jobDetails: 'Job Details',
  jobId: 'Job ID',
  visitDetails: 'Visit Details',
  job: 'Job',
  needSchedule: 'Need to schedule',
  needAssigned: 'Need to assign',
  timeRange: 'Time Range',
  selectCategory: 'Select category Tag',
  category: 'Category',
  'Enterprise Calendar': 'Calendar',
  visit: 'Visit {{visitId}}'
};
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core';
import { Portlet, PortletContent } from 'pages/Dashboard/components';

const styles = (theme) => ({
  root: {
    padding: 30,
  },
  sectionWrap: {
    border: `1px solid ${theme.palette.border}`,
  },
  cardHeader: {
    backgroundColor: theme.palette.background.muted2,
  },
  portlet: {
    border: 'none',
  },
  content: {
    padding: 20,
  },
  noteContent: {
    wordBreak: 'break-word',
  },
});

class Notes extends Component {
  render() {
    const { notes, classes } = this.props;

    return (
      <Portlet>
        <PortletContent className={classes.noteContent}>
          {notes || 'No notes recorded.'}
        </PortletContent>
      </Portlet>
    );
  }
}

export default withStyles(styles)(Notes);

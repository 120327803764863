import React, { useRef } from 'react';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Paper, withStyles, Typography } from '@material-ui/core';

import { getJobs } from 'redux/job';
import moment from 'moment';
import styles from './style';
import { StatusChip } from 'pages/Dashboard/components';
import { showErrorMessage } from 'lib/notifier';
import { getDateTimeFormat } from 'lib/formatter';

const options = {
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  actionsColumnIndex: -1,
  sorting: true,
  search: false,
  draggable: false,
  toolbar: false,
};

const renderColumns = (translate) => [
  {
    field: 'title',
    title: translate('Common:title'),
    sorting: false,
    filtering: false,
  },
  {
    field: 'start',
    title: translate('Job:startAt'),
    sorting: true,
    filtering: false,
    render: (rowData) => {
      if (!rowData.jobSchedule || !rowData.jobSchedule.start) return null;
      return (
        <Typography variant="body1">
          {moment(rowData.jobSchedule.start).format(
            getDateTimeFormat()
          )}
        </Typography>
      );
    },
  },
  {
    field: 'end',
    title: translate('Job:endAt'),
    sorting: true,
    filtering: false,
    render: (rowData) => {
      if (!rowData.jobSchedule || !rowData.jobSchedule.end) return null;
      return (
        <Typography variant="body1">
          {moment(rowData.jobSchedule.end).format(
            getDateTimeFormat()
          )}
        </Typography>
      );
    },
  },
  {
    field: 'team',
    title: translate('Job:assignedTo'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!Array.isArray(rowData.team)) return null;
      return (
        <Typography variant="body1">
          {rowData.team.map((member) => member.fullName).join(', ')}
        </Typography>
      );
    },
  },
  {
    field: 'statusId',
    title: translate('Job:status'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!rowData.statusId) return null;
      return <StatusChip job status={rowData.statusId} />;
    },
  },
];

const ClientJobs = (props) => {
  const {
    classes,
    translate,
    clientDetails = {},
  } = props;

  const dispatch = useDispatch(useDispatch);
  const columns = renderColumns(translate);
  const tableRef = useRef();
  const handleRowClick = (e, rowData) => window.open(`/jobs/${rowData.publicId}`, '_blank');

  const fetchVisits = async (query) => {
    const { page, pageSize, orderBy, orderDirection } = query;
    let sortParams = {};
    if (orderBy) {
      sortParams = { field: orderBy['field'], orderDirection };
    }

    const params = {
      limit: pageSize,
      skip: page * pageSize,
      clientId: clientDetails.id,
      sort: sortParams
    };
    const response = await dispatch(getJobs(params)).catch((e) => ({ e }));
    if (response.e) {
      showErrorMessage(response.e);
      return Promise.reject(response.e);
    }
    return Promise.resolve({
      data: response.data.data,
      page,
      totalCount: response.data.total,
    });
  };

  return (
    <div className="list-visit">
      <div className="list-visit-wraper">
        <Paper className={classes.paperStyle}>
          <MaterialTable
            title={null}
            columns={columns}
            options={options}
            tableRef={tableRef}
            data={fetchVisits}
            onRowClick={handleRowClick}
          />
        </Paper>
      </div>
    </div>
  );
};

export default compose(withStyles(styles), withRouter)(ClientJobs);

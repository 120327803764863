import React, { useEffect } from 'react';

import { reduxForm, Field, change, Fields } from 'redux-form';
import { useDispatch } from 'react-redux';
import { Button, Typography, Box } from '@material-ui/core';
import styles from '../../components.module.scss';
import { showErrorMessage } from 'lib/notifier';

import { getClientDetails } from 'redux/client';

import detailsIcon from 'assets/images/icons/details.svg';

const FORM_NAME = 'GeneralInfoForm';

const RenderField = ({ input, label, isBoldText }) => {
  if (!input.value) return null;
  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography variant="subtitle1" className={styles.form_title}>{label}</Typography>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h5"
          className={isBoldText ? styles.boldText : styles.normalText}
        >
          {input.value}
        </Typography>
      </Box>
    </Box>
  );
};

const RenderFields = (props) => {
  const { label } = props;
  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography variant="subtitle1" className={styles.form_title}>{label}</Typography>
      <Box display="flex" justifyContent="center" flexDirection="column">
        <Typography variant="body1">
          {props[props.names[0]].input.value}
        </Typography>
        <Typography variant="body1">
          {props[props.names[1]].input.value}
        </Typography>
      </Box>
    </Box>
  );
};

const renderPropertyField = ({ input, label }) => {
  if (!input.value || !Object.keys(input.value).length) return null;
  const { unitNumber, address, city, name } = input.value;

  return (
    <Box mt={2} display="flex" flexDirection="column">
      {label && <Typography variant="subtitle1" className={styles.form_title}>{label}</Typography>}
      <Typography className="font_bold">{name}</Typography>
      <Typography>
        {[unitNumber, address, city].filter(Boolean).join(', ')}
      </Typography>
    </Box>
  );
};

const ClientDetails = (props) => {
  const { translate, history, visitDetails: { job } = {} } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!job || !job.clientId && !job.client.id) return null;
    (async () => {
      const result = await dispatch(
        getClientDetails(job.clientId || job.client.id)
      ).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);

      const {
        displayName,
        contactEmails,
        contactPhones,
        billingAddress,
      } = result.data;

      const { email } =
        contactEmails.find((mail) => mail.typeId === 'MAIN') || {};

      const { countryPhoneCode, phoneNumber } = contactPhones.find(
        (phoneDetails) => phoneDetails.typeId === 'MAIN' || {}
      );

      dispatch(change(FORM_NAME, 'name', displayName));
      dispatch(change(FORM_NAME, 'contactEmails', email));
      dispatch(
        change(
          FORM_NAME,
          'contactPhones',
          [countryPhoneCode, phoneNumber].filter(Boolean).join('')
        )
      );
      dispatch(change(FORM_NAME, 'properties', billingAddress));
    })();
  }, [job, dispatch]);

  const handleClickManagement =() => window.open(`/clients/${job.clientId || job.client.id}`, '_blank');

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" className={styles.modal_title}>
          {translate('clientDetails')}
        </Typography>
        <Button onClick={handleClickManagement} variant="contained" className={styles.btn_management}>
          <img src={detailsIcon} alt="detailsIcon" className="mr_5" />
          {translate('fullClientDetails')}
        </Button>
      </Box>

      <Field name="name" label={translate('name')} component={RenderField} />
      <Fields
        names={['contactPhones', 'contactEmails']}
        component={RenderFields}
        label={translate('contactInfo')}
        translate={translate}
      />

      <Field
        name="properties"
        label={translate('propertyAddress')}
        component={renderPropertyField}
      />
    </Box>
  );
};

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(ClientDetails);

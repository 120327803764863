import React from 'react';
import { Tooltip } from '@material-ui/core';
import itemPicked from 'assets/images/icons/item_picked.svg';
import { useTranslation } from 'react-i18next';

export const CollectedIndicator = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('Job:itemPicked')}>
      <img
        style={{ minHeight: 24, minWidth: 24 }}
        src={itemPicked}
        alt="itemPicked"
      />
    </Tooltip>
  );
};
import React from "react";

// redux
import { deleteStaff } from "redux/staff";
import { useDispatch } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "lib/notifier";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import styles from "../../details.module.scss";

const ConfirmDeleteModal = ({
  t,
  openConfirmModal,
  handleClose,
  selectedStaff = {},
  companyDetails = {},
  refetchTable,
}) => {
  const dispatch = useDispatch();
  const handleConfirmDelete = async () => {
    const response = await dispatch(
      deleteStaff(selectedStaff.id, { enterpriseAccountId: companyDetails.id })
    ).catch((e) => ({
      e,
    }));
    handleClose();
    if (response.e) return showErrorMessage(response.e);
    showSuccessMessage(
      t("userAlreadyArchived", { user: selectedStaff.fullName })
    );
    refetchTable && refetchTable();
  };

  return (
    <Dialog
      open={openConfirmModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Typography variant="h4">{t("deleteEnterpriseUser")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{t("enterpriseDescription")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          {t("Common:close")}
        </Button>
        <Button
          onClick={handleConfirmDelete}
          className={styles.confirmDelete}
          autoFocus
        >
          {t("Common:delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteModal;

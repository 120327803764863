const TaxSettingValidation = values => {

  let errors = { message: {} };
  const taxNumberErrors = [];
  const taxRateErrors = [];
  const taxComponentErrors = [];

  if (values) {
    if (values.taxNumbers && values.taxNumbers.length > 0) {
      values.taxNumbers.forEach((item, itemIndex) => {

        if (!item.name) {
          taxNumberErrors[itemIndex] = {
            ...taxNumberErrors[itemIndex] || {},
            name: 'required',
          };
          errors = {
            ...errors,
            message: {
              ...errors.message,
              taxName: 'taxNameRequired',
            }
          };
        }

        if (!item.number) {
          taxNumberErrors[itemIndex] = {
            ...taxNumberErrors[itemIndex] || {},
            number: 'required',

          };
          errors = {
            ...errors,
            message: {
              ...errors.message,
              taxNumber: 'taxNumberRequired',
            }
          };
        }
      });
    }

    if (taxNumberErrors.length > 0) {
      errors.taxNumbers = taxNumberErrors;
    }

    // Validation for tax rate ======
    if (values.taxRates && values.taxRates.length > 0) {
      values.taxRates.forEach((item, itemIndex) => {

        if (!item.name) {
          taxRateErrors[itemIndex] = {
            ...taxRateErrors[itemIndex] || {},
            name: 'required',
          };
          errors = {
            ...errors,
            message: {
              ...errors.message,
              name: 'taxRateNameRequired',
            }
          };
        }

        if (!item.percentage) {
          taxRateErrors[itemIndex] = {
            ...taxRateErrors[itemIndex] || {},
            percentage: 'required',

          };
          errors = {
            ...errors,
            message: {
              ...errors.message,
              percentage: 'taxRatePercentageRequired',
            }
          };
        }
      });
    }

    if (taxRateErrors.length > 0) {
      errors.taxRates = taxRateErrors;
    }

    // Validation for tax rate ======
    if (values.taxComponents && values.taxComponents.length > 0) {
      values.taxComponents.forEach((item, itemIndex) => {

        if (!item.name) {
          taxComponentErrors[itemIndex] = {
            ...taxComponentErrors[itemIndex] || {},
            name: 'required',
          };
          errors = {
            ...errors,
            message: {
              ...errors.message,
              name: 'taxComponentNameRequired',
            }
          };
        }

        if (!item.taxRateIds || item.taxRateIds.length === 0) {
          taxComponentErrors[itemIndex] = {
            ...taxComponentErrors[itemIndex] || {},
            taxRateIds: true,

          };
          errors = {
            ...errors,
            message: {
              ...errors.message,
              taxRateIds: 'taxRateIdsRequired',
            }
          };
        }
      });
    }

    if (taxComponentErrors.length > 0) {
      errors.taxComponents = taxComponentErrors;
    }
  }

  return errors;
};

export default TaxSettingValidation;

import React, { useEffect, useState } from 'react';

// Externals
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Redux
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { updateStaff, getStaff, uploadAvatar } from 'redux/staff';

// Material helpers
// Material components
import { Grid, withStyles } from '@material-ui/core';

// Custom components
import { StaffFormCard } from '../components/cards';
import { StaffsToolbar } from '../components/utils';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});


const UpdateStaff = props => {
  const { classes, history, translate,
    match: {
      params: {
        staffId,
      },
    },
  } = props;

  const [staff, setStaff] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchStaffDetails = async () => {
      const result = await dispatch(getStaff(staffId)).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
      setStaff(result.data);
    };

    fetchStaffDetails();
  }, []);


  const handleSubmitStaff = async data => {
    const formData = new FormData();
    formData.append('staffInfo', JSON.stringify(data));
    try {
      const response = await dispatch(updateStaff(staffId, formData));
      if (response.status === 200) {
        const updatedStaff = response.data;
        const staffName = [updatedStaff.firstName, updatedStaff.lastName].filter(Boolean).join(' ');
        showSuccessMessage(translate('updatedSuccess', { staffName }));
        history.push('/teams');
      } else {
        showErrorMessage(response.data.message);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const uploadProfilePictureAfterCropped = async (fileBlob) => {
    const res = await fetch(fileBlob);
    const blob = await res.blob();
    const formData = new FormData();
    formData.append('file', blob);
    const result = await dispatch(uploadAvatar(staffId, formData)).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    setStaff({ ...staff, avatar: result.data.url });
    dispatch(change('staffForm', 'avatar', result.data.url));
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify='center'>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          xs={12}
        >
          <StaffsToolbar translate={translate} />
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          {/* TODO: Add loading here to wait for data */}
          { staff &&
            <StaffFormCard
              staff={staff}
              uploadProfilePictureAfterCropped={uploadProfilePictureAfterCropped}
              onSubmit={handleSubmitStaff}
              translate={translate}
            />}
        </Grid>
      </Grid>
    </div>
  );
};

UpdateStaff.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(UpdateStaff);

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showErrorMessage } from 'lib/notifier';
import { getEnterpriseJobDetails } from 'redux/dashboard';

import {
  JobHeader,
  JobDetailsWrapper,
  AboutJob,
  ClientDetails,
  JobTabs,
  DownloadingNotification,
} from './components';

const JobDetails = (props) => {
  const { t } = useTranslation('Enterprise');
  const dispatch = useDispatch();
  const { jobPublicId } = props;

  const [jobDetails, setJobDetails] = useState({});
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);

  useEffect(() => {
    if (!jobPublicId) return;
    (async () => {
      const response = await dispatch(
        getEnterpriseJobDetails({ jobId: jobPublicId })
      ).catch((e) => ({ e }));
      if (response.e) return showErrorMessage(response.e);
      setJobDetails(response);
    })();
  }, [dispatch, getEnterpriseJobDetails, jobPublicId]);

  return (
    <Box p={2}>
      <Box mb={2}>
        <Typography component="span">{t('Common:backTo')}</Typography>
        <Link to="/enterprise-jobs">
          {t('jobs')}
        </Link>
      </Box>
      <DownloadingNotification
        {...props}
        t={t}
        isOpenDownloadModal={isOpenDownloadModal}
        setIsOpenDownloadModal={setIsOpenDownloadModal}
      />
      <JobDetailsWrapper {...props} t={t} jobDetails={jobDetails}>
        <Grid container display="flex">
          <Grid item md={9} sm={7}>
            <JobHeader {...props} t={t} />

            <JobTabs
              {...props}
              t={t}
              jobDetails={jobDetails}
              setIsOpenDownloadModal={setIsOpenDownloadModal}
              isOpenDownloadModal={isOpenDownloadModal}
            />
          </Grid>
          <Grid item md={3} sm={5}>
            <Box px={1}>
              <AboutJob {...props} t={t} />
              <Box mt={1}>
                <ClientDetails {...props} t={t} jobDetails={jobDetails} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </JobDetailsWrapper>
    </Box>
  );
};

export default JobDetails;

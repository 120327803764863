import React from 'react';
import { withStyles ,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';


import { formatDate } from 'lib/formatter';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    width: '100%',
  },
  selectClientSection: {
    width: '100%'
  },
  paperClientDropdown: {
    maxWidth: '80%'
  },
  selectJob: {
    margin: 0,
    width: '100%'
  },
});


class JobSelection extends React.Component {

  state = {
    selectedJobs: new Set()
  }

  toggleSelectJobs(event, job) {
    const { handleSelectJobs } = this.props;
    const selectedCheckboxes = this.state.selectedJobs;
    if (selectedCheckboxes.has(job)) {
      selectedCheckboxes.delete(job);
    } else {
      selectedCheckboxes.add(job);
    }
    this.setState({ selectedJobs: selectedCheckboxes });
    handleSelectJobs(Array.from(this.state.selectedJobs));
  }

  render() {
    const { classes, jobList, translate } = this.props;
    return (
      <Grid item container md={6} sm={12} className={classes.root}>
        <Typography gutterBottom component="div" className={classes.title}>
          {translate('selectJob')}
        </Typography>
        {
          jobList && jobList.length > 0 ?
            <Grid container direction='row'>
              {
                jobList.map((job, index) => {
                  return (
                    <Grid item md={12} sm={12} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox onChange={event => this.toggleSelectJobs(event, job)} value={job.id} />
                        }
                        label={`${job.id} - ${formatDate(job.createdAt, 'MMM DD, YYYY')} - ${job.title}`}
                      />

                    </Grid>
                  );
                })
              }
            </Grid>
            :
            <Typography className={classes.noneAssignText}>{translate('noJobForSelection')}.</Typography>
        }
      </Grid>
    );
  }
}

export default withStyles(styles)(JobSelection);




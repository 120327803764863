export default theme => ({
  root: {
  },
  dialogWrap: {
    minWidth: 500,
  },
  row: {
    display: 'flex',
    flexDirection: 'column'
  },
  fieldLabel: {
    flexGrow: 1,
    fontSize: 12,
    color: theme.palette.text.grey700
  },
  fieldWrapper: {
    width: '100%',
  },
  additionLabel: {
    fontSize: 11,
    color: theme.palette.text.grey700
  },
  limitChars: {
    textAlign: 'right'
  }
});

/* eslint-disable no-nested-ternary */
import React, { forwardRef, createRef } from 'react';

// Externals
import classNames from 'classnames';
import Types from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
  ArrowUpward,
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveOutlined,
  SaveAlt,
  Search,
  ToggleOff,
  Visibility,
} from '@material-ui/icons';

// Component styles
import styles from './styles';
import { GET } from 'lib/api';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <SaveOutlined {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ToggleOff: forwardRef((props, ref) => <ToggleOff {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />)
};

const mapStateToProps = (state) => ({
  accessToken: state.auth.accessToken,
});

const RenderAddressProps = props => {
  const { address, address2, zipCode, city, state, name, classes, unitNumber } = props;

  const cityState = city || state ?
    city ?
      state?
        `${city}, ${state}` : `${city}`
      : ''
    : state || '' ;

  return (
    <>
      <Grid container direction='column'>
        {
          name &&
          <Grid item container direction='row'>
            <Typography className={classes.title} variant='body1'>{name}</Typography>
          </Grid>
        }

        <Grid item container direction='row'>
          {
            unitNumber &&
            <Typography className={classes.textStyle} variant='body1'>{unitNumber}</Typography>
          }
          {
            address &&
              <Typography className={classes.textStyle} variant='body1'>{address}</Typography>
          }
          {
            address2 &&
              <Typography className={classes.textStyle} variant='body1'>, {address2}</Typography>
          }
        </Grid>
        <Grid item container direction='row'>
          {
            zipCode &&
              <Typography className={classes.textStyle} variant='body1'>{zipCode}</Typography>
          }
          {
            cityState &&
              <Typography className={classes.textStyle} variant='body1'>, {cityState}</Typography>
          }
        </Grid>
      </Grid>
    </>
  );
};

function SelectPropertyModal(props) {
  const {
    classes,
    open,
    handleClose,
    accessToken,
    selectedClient,
    handlePropertySelect,
    handleAddNewProperty,
    translate
  } = props;

  const tableRef = createRef();

  const columns = [
    {
      field: 'property',
      title: translate('property'),
      render: rowData => <RenderAddressProps {...rowData} {...props} />
    },
  ];

  const options = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    search: true,
    debounceInterval: 1000
  };

  return (
    <Dialog
      maxWidth='lg'
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle disableTypography className={classNames(classes.root, classes.clientDialogTitle)}>
        <Typography variant="h6">{translate('Job:selectCreateProperty')}</Typography>
        {open ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {translate('Job:whichProperty')}
        </Typography>
        <MaterialTable
          title={null}
          columns={columns}
          options={options}
          icons={tableIcons}
          tableRef={tableRef}
          data={query =>
            new Promise((resolve) => {
              const { pageSize, page, search } = query;

              GET(
                '/properties',
                accessToken,
                {
                  params: {
                    ownerId: selectedClient.id,
                    $limit: pageSize,
                    $skip: pageSize * page,
                    $q: search
                  }
                }
              ).then(response => {
                const { data } = response;
                const properties = (data && data.data) || [];

                resolve({
                  data: properties,
                  page,
                  totalCount: data.total
                });
              }).catch(() => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
              });
            })}
          onRowClick={(event, rowData) => handlePropertySelect(rowData)}
          components={{
            Toolbar: toolbarProps => (
              <div>
                <Grid container direction='row'>
                  <Grid item container justify='center' alignItems='center' md={6} sm={12}>
                    {/* <SearchInput /> */}
                    <MTableToolbar {...toolbarProps} />
                  </Grid>
                  <Grid item container justify='center' alignItems='center' md={1} sm={12}>
                    {translate('Common:or')}
                  </Grid>
                  <Grid item container justify='center' alignItems='center' md={5} sm={12}>
                    <Button onClick={handleAddNewProperty}>{translate('Job:createNewProperty')}</Button>
                  </Grid>
                </Grid>
              </div>
            ),
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

RenderAddressProps.propsTypes = {
  form: Types.string.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(SelectPropertyModal);
export default value => {
  if (!value) {
    return '0.00';
  }
  value = value
    .toString()
    .replace(/[^0-9.-]/g, ''); // Remove all chars except numbers and .

  // To format a number to be decimal, we just simple use Number and toFixed function.
  return Number.parseFloat(value).toFixed(2);
};

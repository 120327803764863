const { store } = require('services/store');

export const getAccessibleView = (viewName) => {
  if (!viewName) return false;
  const state = store.getState();
  const { currentCompanyPermissions } = state.auth;

  if (!currentCompanyPermissions || !Array.isArray(currentCompanyPermissions) || !currentCompanyPermissions.length)
    return false;

  return currentCompanyPermissions.includes(viewName);
};

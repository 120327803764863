import React from 'react';
import { Link } from 'react-router-dom';

// externals

// Redux
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

// materials
import {
  Container,
  Button,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Logo from '../../../../components/logo';
import { EMAIL_VALIDATION } from 'common/constant';

// common
import { COLORS } from 'common/colors';
import Countries from 'lib/countryList';
import { SelectBox, TextInput } from 'components/inputs';

const validate = values => {
  const errors = { message: {} };
  const requiredFields = [
    'email',
    'password',
    'passwordConfirmation',
    'firstName',
    'phoneNumber',
    'countryCode',
    'countryPhoneCode',
    'companyTypeId'
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'required';
      errors.message[field] = 'required';
    }
  });
  if (values.email &&
    !(EMAIL_VALIDATION.test(values.email))) {
    errors.email = 'invalidEmail';
    errors.message.email = 'invalidEmail';
  }

  if ( values.password && !(/^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?!.*\s).*$/.test(values.password))
  ) {
    errors.password = 'hintPassword';
    errors.message.password = 'hintPassword';
  }

  if ( values.companyTypeId && values.companyTypeId !== 'INDIVIDUAL' && !values.companyName) {
    errors.companyName = 'organisationNameRequired';
    errors.message.companyName = 'organisationNameRequired';
  }

  if ( values.password && values.passwordConfirmation && values.password !== values.passwordConfirmation ) {
    errors.passwordConfirmation = 'passwordConfirmationNotMatch';
    errors.message.passwordConfirmation = 'passwordConfirmationNotMatch';
  }

  return errors;
};

const styles = {
  container: {
    width: '100%',
    maxWidth: 600,
    margin: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  signUpTitle: {
    color: COLORS.blue,
    fontWeight: '900',
    marginVertical: 0,
    marginBottom: 10,
    // fontFamily: 'Open Sans',
  },
  signUpSubtitle: {
    color: COLORS.blue,
    fontSize: 12,
    letterSpacing: 1,
    marginVertical: 0,
  },
  signUpButton: {
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 50,
    marginBottom: 20,
  },
  compulsoryTitle: {
    fontSize: 14,
    letterSpacing: 1,
    marginVertical: 0,
  },

  termsPrivacyText: {
    color: COLORS.blue,
    // fontFamily: 'Nunito Sans',
    letterSpacing: 1,
    marginBottom: 15,
  },
  alrdHadAccount: {
    color: COLORS.blue,
    // fontFamily: 'Nunito Sans',
    letterSpacing: 1,
    marginBottom: 15,
  },
  link: {
    marginLeft: 5,
    marginRight: 5,
    color: COLORS.blue,
    textDecoration: 'underline'
  },
  companyTypes: {
    display: 'flex',
    WebkitFlexFlow: 'row wrap',
    justifyContent: 'space-between'
  }
};

const countryCodeOptions = new Countries().countryCodes();
const countryPhoneCodeOptions = new Countries().countryPhoneCodes();

let SignUpForm = (props) => {

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirmation, setshowPasswordConfirmation] = React.useState(false);
  const [enableOrganisationName, setEnableOrganisationName] = React.useState(false);
  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleClickShowPasswordConfirmation() {
    setshowPasswordConfirmation(!showPasswordConfirmation);
  }

  function handleChangeCompanyType(event, value) {
    // this.setState({ enableOrganisationName: value !== 'INDIVIDUAL' });
    setEnableOrganisationName(value !== 'INDIVIDUAL');
  }

  const { pristine, invalid, submitting, handleSubmit, translate } = props;

  const radioButton = ({ input, ...rest }) => (
    <RadioGroup {...input} {...rest}>
      { props.companyTypes.map(function radioGenerator(item, i) {
        return <FormControlLabel key={i} value={`${item.id}`} control={<Radio />} label={item.name} />;
      })}
    </RadioGroup>
  );
  return (
    <Container className="sign-up-form">
      <Logo />
      <div>
        <div style={{ textAlign: 'center' }}>
          <Typography className="sign-up-title" style={styles.signUpTitle}>
            {translate('startFreeTrial')}</Typography>
          <Typography className="sign-up-subtitle" style={styles.signUpSubtitle}>
            {translate('featuresSupportNoCard')}</Typography>
        </div>
        <form noValidate className="form-body" onSubmit={handleSubmit}>
          <Grid item container spacing={2}>
            <Grid item container xs={12} sm={12}>
              <Typography className="compulsory-title" style={styles.compulsoryTitle}>
                {translate('CompanySetting:compulsoryField')}</Typography>
            </Grid>
            <Grid item container xs={12} sm={6}>
              <Field
                name="email"
                component={TextInput}
                label={translate('Common:yourEmail')}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Field
                name="firstName"
                component={TextInput}
                margin="normal"
                label={translate('Common:firstName')}
                required
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Field
                name="lastName"
                component={TextInput}
                margin="normal"
                label={translate('Common:lastName')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="password"
                component={TextInput}
                label={translate('Common:yourPassword')}
                type={showPassword ? 'text' : 'password'}
                margin="normal"
                required
                InputProps={{
                  inputProps: { minLength: 8 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                        tabIndex={-1}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="passwordConfirmation"
                component={TextInput}
                label={translate('Common:confirmYourPassword')}
                type={showPasswordConfirmation ? 'text' : 'password'}
                margin="normal"
                required

                InputProps={{
                  inputProps: { minLength: 8 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPasswordConfirmation}
                        tabIndex={-1}
                      >
                        {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name="companyTypeId"
                className="company-types"
                style={styles.companyTypes}
                component={radioButton}
                onChange={(event, value) => handleChangeCompanyType(event, value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name="companyName"
                component={TextInput}
                margin="normal"
                label={translate('Common:organisationName')}
                className="organisation_name"
                disabled={!enableOrganisationName}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Field
                name="countryCode"
                component={SelectBox}
                label={translate('Common:yourCountry')}
                options={countryCodeOptions}
              />
            </Grid>

            <Grid item xs={false} sm={3} md={3} />

            <Grid item xs={12} sm={3} md={2}>
              <Field
                name="countryPhoneCode"
                component={SelectBox}
                label={translate('Common:countryCode')}
                options={countryPhoneCodeOptions}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={4}>
              <Field
                name="phoneNumber"
                component={TextInput}
                margin="normal"
                label={translate('Common:yourMobileNumber')}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>

              <div style={styles.signUpButton}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={pristine || submitting || invalid}
                  className="medium-btn blue-btn"
                >
                  {translate('createAccount')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <Grid item container justify='center'>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ textAlign: 'center' }}>
            <Typography className="terms-privacy-text" variant='subtitle1' style={styles.termsPrivacyText}>
              By continuing, you agree to our
              <Link style={styles.link} to="/term-of-service">Terms of Service</Link>
              {translate('and')}
              <Link style={styles.link} to="/privacy-policy">{translate('privacyPolicy')}</Link>
            </Typography>
            <Typography className="alrd-had-account" variant='subtitle1' style={styles.alrdHadAccount}>
              {translate('haveAccount')}
              <Link style={styles.link} to="/log-in">{translate('Common:login')}</Link>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

SignUpForm = reduxForm({ form: 'authForm', validate })(SignUpForm);

export default connect(state => ({
  initialValues: {
    email: null,
    password: null,
    passwordConfirmation: null,
    firstName: null,
    lastName: null,
    companyName: null,
    countryPhoneCode: new Countries().getCountryPhoneCodeByCountryCode(state.auth.geoCountry.countryCode) || null,
    countryCode: state.auth.geoCountry.countryCode || null,
    phoneNumber: null,
    companyTypeId: null
  },
  companyTypes: state.config.configs.companyTypes || []
}), null)(SignUpForm);

export default theme => ({
  root: {},
  filterDropdownWrap: {
    width: '100%',
    paddingTop: 5,
    margin: 0
  },
  filterTitle: {
    height: 24,
    lineHeight: '24px'
  },
  quoteTableToolbar: {
    padding: '10px 0 10px 14px',
    '& .MuiToolbar-root' : {
      alignItems: 'flex-end',
    }
  },
  filterDropdown: {
    height: 40,
    margin: 0
  },
  customDueDate: {
    paddingRight: 10
  }
});

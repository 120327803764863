import { COLORS, grey } from 'common/colors';

export default theme => ({
  root: {
  },

  cardHeader: {
    backgroundColor: grey[100],

    '& .close-modal': {
      position: 'absolute',
      top: 10,
      right: 10,
      cursor: 'pointer',

      '&.close-icon': {
        height: 32,
        width: 32,
        fontSize: 32,
      }
    }
  },
  paperStyle: {
    overflow: 'scroll'
  },
  visitListPortlet: {
    border: 'none',
  },

  visitTableContent: {
    padding: 0,
  },

  visitRow: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS.black,
    }
  },

  emptyList: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },

  scheduleJobButton: {
  },

});

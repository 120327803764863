import React from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
// import component
import { ServiceReportForm } from '../components/forms';
import { useSelector, useDispatch, connect } from 'react-redux';
import { compose } from 'redux';
import { addServiceReport } from 'redux/job';
import { useTranslation } from 'react-i18next';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import { formValueSelector } from 'redux-form'; // ES6
import { SERVICE_REPORT_FORM_NAME } from 'common/constant';

import ServiceReportToolbar from '../components/utils/ServiceReportToolbar';
import ServiceReportHeader from '../components/utils/ServiceReportHeader';
import styles from './styles.module.scss';
import moment from 'moment';

const NewServiceReport = ({ history, actionCallback }) => {
  const job = useSelector((state) => state.job) || {};
  const visit = job.visitReportGenerateFrom || '';
  const { t: translate } = useTranslation('Job');

  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    let reportDetailsPayload = {
      jobId: ((job || {}).job || {}).publicId,
      description: values.jobDescription,
      remark: values.actionTaken,
      customer: values.customer,
      phoneNumber: values.phoneNumber,
      address: values.address,
      reportFor: visit && visit.id ? 'VISIT' : 'JOB',
      reportType: values.reportType,
      issuedAt: moment(),
      scheduledAt: values.scheduleDate,
      requestedAt: values.requestDate,
      medias: values.medias,
      workerTimelog: {
        worker: values.serviceBy,
        arrival_at: values.timeArrival ? moment(values.timeArrival): null,
        completion_at: values.timeOfCompletion ? moment(values.timeOfCompletion): null,
      }
    };
    if (visit && visit.id)
      reportDetailsPayload = { ...reportDetailsPayload, visitId: visit.id };

    if (values.reportType !== 'PICTORIAL') {

      // get list device fill full information
      const deviceInfo = values.deviceInfo.filter(
        (device) =>
          device.modelNumber && device.serialNumber && device.serviceType
      );
      if (deviceInfo && deviceInfo.length)
        reportDetailsPayload = { ...reportDetailsPayload, deviceInfo };

      // service provide
      const filteredServices = values.products.filter(
        (product) => product.name
      );
      if (filteredServices && filteredServices.length)
        reportDetailsPayload = {
          ...reportDetailsPayload,
          serviceItems: filteredServices,
        };
    }

    const evaluateIds = values.evaluation.map((image) => ({
      id: image.id,
      caption: image.caption,
    }));
    const afterJobIds = values.afterJob.map((image) => ({
      id: image.id,
      caption: image.caption,
    }));

    reportDetailsPayload = {
      ...reportDetailsPayload,
      medias: evaluateIds.concat(afterJobIds),
    };

    let payload = {
      serviceReportData: reportDetailsPayload,
    };

    if (actionCallback) {
      payload = { ...payload, actionCallback };
    }

    const result = await dispatch(addServiceReport(payload)).catch((e) => ({
      e,
    }));
    if (result.e) return showErrorMessage(result.e);
    showSuccessMessage(translate('createSuccessfully'));
    const reportId = result.data && result.data.data && result.data.data.id;
    reportId ? history.replace(`${reportId}/view`) : history.goBack();
  };
  return (
    <>
      <ServiceReportToolbar isFromNewServiceReport />
      {/* Service report content */}
      <Grid className={styles.newContent}>
        <ServiceReportHeader />
        <Grid className={styles.form}>
          <ServiceReportForm
            isFromNewServiceReport
            onSubmit={handleSubmit}
            translate={translate}
          />
        </Grid>
      </Grid>
    </>
  );
};

const selector = formValueSelector(SERVICE_REPORT_FORM_NAME);

const mapStateToProps = (state) => {
  const actionCallback = selector(state, 'actionCallback');
  return {
    actionCallback: actionCallback || '',
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(NewServiceReport);

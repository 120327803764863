import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

export default connect(null, null)(route => {
  const { path, routes } = route;

  return (
    <>
      <Route
        path={path}
        render={props => (
          <route.component {...props} {...route} routes={routes} />
        )}
      />
    </>
  );
});

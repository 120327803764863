import React from "react";

// Externals
import { Link } from "react-router-dom";

import {
  // Material components
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,

  // Material helpers
  withStyles,
} from "@material-ui/core";

// Component styles
import { InvoiceHeaderStyles } from "./styles";

const UNAPPROVAL_STATUS = ["DRAFT", "AWAITING_APPROVAL"];

export default withStyles(InvoiceHeaderStyles)((props) => {
  const {
    classes,
    handleClickArchive,
    handleClickStatus,
    handleCollectPayment,
    handleEmailInvoice,
    invoice,
    invoiceStatuses,
    translate,
  } = props;
  const { id, statusId, paid } = invoice;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const allowedStatuses = [
    "UNPAID",
    "PAID",
    "BAD_DEBT",
    "AWAITING_APPROVAL",
    "AWAITING_PAYMENT",
    "APPROVED",
  ];

  return (
    <>
      <Grid item md={5} sm={12}>
        <Typography variant="h2">{invoice && invoice.subject}</Typography>
        <Typography variant="h4" className={classes.subTitle}>
          ID: {invoice && invoice.id}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="flex-end"
        spacing={1}
        md={7}
        sm={12}
      >
        {!paid && statusId !== "PAID" && !UNAPPROVAL_STATUS.includes(statusId) && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.mainActionButton}
              onClick={handleCollectPayment}
              disabled={invoice.invoiceBalance <= 0}
            >
              {translate("recordPayment")}
            </Button>
          </Grid>
        )}
        {!UNAPPROVAL_STATUS.includes(statusId) && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.mainActionButton}
              onClick={handleEmailInvoice}
            >
              {translate("emailInvoice")}
            </Button>
          </Grid>
        )}
        <Grid item>
          {(invoice.permissions || {}).ableToEdit ? (
            <Link to={`/invoices/${id}/edit`}>
              <Button variant="contained" color="primary">
                {translate("Common:edit")}
              </Button>
            </Link>
          ) : (
            <Button variant="contained" color="primary" disabled>
              {translate("Common:edit")}
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            aria-controls="status-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            {translate("Common:markAs")}
          </Button>
          <Menu
            id="status-menu"
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.popoverWithArrow}
          >
            {invoiceStatuses &&
              invoiceStatuses.map((item) => {
                if (allowedStatuses.includes(item.id)) {
                  return (
                    <MenuItem
                      key={item.id}
                      disabled={statusId === item.id}
                      onClick={(e) => {
                        handleClickStatus(e, item.id);
                        setAnchorEl(null);
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  );
                }
                return null;
              })}
          </Menu>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickArchive}
          >
            {invoice.archived
              ? translate("Common:unarchive")
              : translate("Common:archive")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
});

import React, { useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { updateVisit } from 'redux/visit';
import { showErrorMessage } from 'lib/notifier';

// Material helpers
import { Dialog, Typography, Box, IconButton } from '@material-ui/core';
import styles from './styles.module.scss';
import { VisitDetailsForm } from 'pages/Dashboard/components';

import { Close as CloseIcon } from '@material-ui/icons';

const EditVisitDetailsModal = (props) => {
  const {
    translate,
    isOpenEditModal,
    visitId,
    visitDetails,
    afterSaveVisitDetails,
    setIsOpenEditModal,
  } = props;

  const dispatch = useDispatch();

  const handleCloseEditForm = () => {
    setIsOpenEditModal(false);
  };

  const [isDisable, setIsDisable] = useState(false);

  const handleScheduleLater = event => {
    setIsDisable(event.target.checked);
  };

  const handleSubmit = async (values) => {
    const {
      title,
      startOnDate,
      startOnTime,
      endOnDate,
      endOnTime,
      team,
      description,
      isScheduleLater
    } = values;
    const payload = {
      jobId: visitDetails.job && visitDetails.job.publicId,
      id: visitId,
      title,
      virtual: false,
      description,
      team:
        Array.isArray(team) &&
        team.map((item) => ({
          id: item.id,
        })),
      start:
        startOnDate &&
        startOnTime &&
        moment(startOnDate)
          .hour(moment(startOnTime).hour())
          .minute(moment(startOnTime).minute())
          .toISOString(),
      end:
        endOnDate &&
        endOnTime &&
        moment(endOnDate)
          .hour(moment(endOnTime).hour())
          .minute(moment(endOnTime).minute())
          .toISOString(),
      isSetUnschedule: isScheduleLater
    };

    const result = await dispatch(updateVisit(payload)).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);

    const { data } = result.data;
    afterSaveVisitDetails && afterSaveVisitDetails(data);
    setIsOpenEditModal(false);
  };

  return (
    <Dialog
      classes={{ paper: styles.dialog_edit_visit }}
      open={isOpenEditModal}
      onClose={handleCloseEditForm}
    >
      <Box
        className={styles.border_bottom}
        pl={2}
        py={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={styles.modal_title}>
          {translate('editVisit')}
        </Typography>
        <IconButton onClick={handleCloseEditForm}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box p={2}>
        <VisitDetailsForm
          {...props}
          onSubmit={handleSubmit}
          handleCloseForm={handleCloseEditForm}
          handleScheduleLater={handleScheduleLater}
          isDisable={isDisable}
        />
      </Box>
    </Dialog>
  );
};

export default EditVisitDetailsModal;

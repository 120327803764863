import React from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import styles from './components.module.scss';
import moment from 'moment';
import { DATE_FORMAT } from 'common/constant';

const VisitOnFilter = ({ t, visitOn, selectedVisitIds, setSelectedVisitIds }) => {
  const handleChangeFilter = (value) => {
    const index = selectedVisitIds.indexOf(value);
    if (index === -1) return setSelectedVisitIds([...selectedVisitIds, value]);
    const filterValues = [...selectedVisitIds];
    filterValues.splice(index, 1);
    setSelectedVisitIds(filterValues);
  };

  const getDate=(date) => {
    if(date !== 'Unscheduled') {
      return moment(date, DATE_FORMAT).format('ll');
    }
    return date;
  };

  return (
    <Grid>
      <Box mb={2}>
        <Typography>{t('visitOn')}</Typography>
      </Box>

      {Array.isArray(visitOn) &&
        visitOn.map((visit) => (
          <Box display="flex" mt={1} key={visit.visitId}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedVisitIds.includes(visit.visitId)}
                  onChange={() => handleChangeFilter(visit.visitId)}
                  color="primary"
                  className={styles.checkBox_style}
                  classes={{
                    checked: styles.checked_state,
                  }}
                />
              }
              label={
                <Box display="flex" flexDirection="column">
                  <Box color="#A2A2A2">{visit.publicId}</Box>
                  <Box>{getDate(visit.visitOn)}</Box>
                </Box>
              }
              classes={{
                root: styles.checkbox_root,
                label: styles.checkbox_label,
              }}
            />
          </Box>
        ))}
    </Grid>
  );
};

export default VisitOnFilter;

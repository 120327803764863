import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field, Fields, FieldArray } from 'redux-form';

// import component
import styles from './styles.module.scss';

const ServiceProvide = ({ translate, serviceProvidedList }) => {
  if (!serviceProvidedList || serviceProvidedList.length === 0) return null;

  const renderTypography = ({ input, bold }) => {
    return (
      <Typography
        style={{ wordBreak: 'break-word', paddingRight: '5px' }}
        variant="body1"
        className={bold ? styles.boldText : null}
      >
        {input.value}
      </Typography>
    );
  };

  const renderFields = ({ keys, serviceProvided }) => {
    // serviceProvide from redux-form
    return (
      <Grid container>
        {keys.map((name, index) => (
          <Grid item key={index}>
            {renderTypography({
              ...serviceProvided[serviceProvided.length - 1][name],
              bold: true,
            })}
          </Grid>
        ))}
      </Grid>
    );
  };
  const renderServiceItems = ({ fields }) => {
    return fields.map((field, index) => {
      return (
        <Grid
          container
          wrap="nowrap"
          key={index}
          className={index % 2 !== 0 ? styles.backgroundRow : styles.normalRow}
        >
          <Grid item md={3}>
            <Field name={`${field}.name`} component={renderTypography} />
          </Grid>
          <Grid item md={3}>
            <Field name={`${field}.description`} component={renderTypography} />
          </Grid>
          <Grid item md={2}>
            <Field name={`${field}.quantity`} component={renderTypography} />
          </Grid>
          <Grid item md={2}>
            <Fields
              names={[`${field}.unitPrice`, `${field}.currency`]}
              component={renderFields}
              keys={['currency', 'unitPrice']}
            />
          </Grid>
          <Grid item md={2}>
            <Fields
              names={[`${field}.total`, `${field}.currency`]}
              component={renderFields}
              keys={['currency', 'total']}
            />
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid container className={`mt_20 ${styles.deviceInfo}`}>
      <Grid item container className="mb_20 pl_10 pr_10">
        <Typography variant="h5" className={styles.boldText}>
          {translate('serviceProvide')}
        </Typography>
      </Grid>
      <Grid item container className="pl_10 pr_10">
        <Grid item md={3}>
          <Typography className={styles.boldText}>
            {translate('title')}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography className={styles.boldText}>
            {translate('description')}
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography className={styles.boldText}>
            {translate('qty')}
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography className={styles.boldText}>
            {translate('unitPriceServiceReport')}
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography className={styles.boldText}>
            {translate('total')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container className="mt_10">
        <FieldArray
          name="serviceProvided"
          component={renderServiceItems}
          translate={translate}
        />
      </Grid>
    </Grid>
  );
};

export default ServiceProvide;

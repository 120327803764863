import React from 'react';
// Redux
import { connect } from 'react-redux';
import { reduxForm, submit, Field, formValueSelector } from 'redux-form';
// Material components
import { Button, Grid, Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import { DatePicker, TimePicker } from 'components/inputs';
import { moment } from 'lib/app-moment';

const selector = formValueSelector('EditClockInOutForm');

let EditClockInOutModal = (props) => {
  const { translate, handleSubmit, setOpenModal, duration } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6}>
          <Typography className={styles.contentTitle} variant="h5">
            {translate('Timesheet:clockIn')}
          </Typography>
          <Grid container>
            <Grid item xs={5}>
              <Field
                name="clockInTime"
                component={TimePicker}
                value=""
                minDate={null}
              />
            </Grid>
            <Grid item xs={7} className={styles.dateField}>
              <Field
                className={styles.textColor}
                name="clockInDate"
                component={DatePicker}
                minDate={null}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles.contentTitle} variant="h5">
            {translate('Timesheet:clockOut')}
          </Typography>
          <Grid container>
            <Grid item xs={5}>
              <Field
                className={styles.textColor}
                name="clockOutTime"
                component={TimePicker}
                value=""
                minDate={null}
              />
            </Grid>
            <Grid item xs={7} className={styles.dateField}>
              <Field
                className={styles.textColor}
                name="clockOutDate"
                component={DatePicker}
                minDate={null}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={styles.durationField}
        container
        justify="flex-end"
        alignItems="center"
      >
        <div>
          Duration (hh:mm): <span>{duration}</span>
        </div>
      </Grid>
      <div className={styles.groupButton}>
        <Button onClick={() => setOpenModal(false)}>Cancel</Button>
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  submitClientForm: () => dispatch(submit('clientForm')),
});

const convertSecondToHour = (second) => {
  if (!Number.isInteger(second)) return '00:00';
  let hh = Math.floor(second / 1000 / 3600);
  let mm = Math.floor(((second / 1000) % 3600) / 60);
  mm = mm < 10 ? `0${mm}` : mm;
  hh = hh < 10 ? `0${hh}` : hh;
  const workedHours = [hh, mm].join(':');
  return workedHours;
};

const calculateDuration = ({ clockInTime, clockInDate, clockOutTime, clockOutDate }) => {
  if (!moment(clockInTime).isValid() || !moment(clockInDate).isValid()
  || !moment(clockOutTime).isValid() || !moment(clockOutDate).isValid()) {
    return '00:00';
  }
  const originalInTime = moment(clockInDate).format('MM-DD-YYYY ') + moment(clockInTime).format('HH:mm Z');
  const originalOutTime = moment(clockOutDate).format('MM-DD-YYYY ') + moment(clockOutTime).format('HH:mm Z');
  const diffInMilisecond = moment(originalOutTime).diff(moment(originalInTime));
  if(diffInMilisecond < 0) {
    return 'Invalid date';
  }
  return convertSecondToHour(diffInMilisecond);
};

const mapStateToProps = (state, ownProps) => {
  const { timesheet, timeSelectedRow } = ownProps;
  const times = selector(state,'clockInTime', 'clockInDate', 'clockOutTime', 'clockOutDate');
  const duration = calculateDuration(times);
  return {
    initialValues: {
      id: (timesheet && timesheet.id) || null,
      clockInTime: (timesheet && timesheet.originalInTime) || null,
      clockInDate: (timesheet && timesheet.originalInTime) || moment(timeSelectedRow),
      clockOutTime: (timesheet && timesheet.originalOutTime) || null,
      clockOutDate: (timesheet && timesheet.originalOutTime) || moment(timeSelectedRow),
    },
    duration
  };
};

EditClockInOutModal = reduxForm({
  form: 'EditClockInOutForm',
  enableReinitialize: false,
  destroyOnUnmount: true,
})(EditClockInOutModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditClockInOutModal);

import React, { useState, useRef } from 'react';
import { Field } from 'redux-form';

import { Grid, Box } from '@material-ui/core';

import { renderField, renderCopyField, renderFieldTeam, renderLocation } from './components';

const VisitDetailsForm = ({ translate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const inputEl = useRef(null);

  const handleClick = (event) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Box mb={2}>
        <Field
          name="publicId"
          component={renderCopyField}
          label={translate('visitId')}
          translate={translate}
        />
      </Box>
      <Box mb={2}>
        <Field
          name="title"
          component={renderField}
          label={translate('visitTitle')}
        />
      </Box>
      {/* <Box mb={2}>
        <Field
          name="createdAt"
          component={renderField}
          label={translate('dateCreated')}
        />
      </Box> */}

      <Box mb={2}>
        <Grid container>
          <Grid item md={6}>
            <Field
              name="startDateTime"
              component={renderField}
              label={translate('startTime')}
            />
          </Grid>
          <Grid item md={6}>
            <Field
              name="endDateTime"
              component={renderField}
              label={translate('endTime')}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb={2}>
        <Field
          name="description"
          component={renderField}
          label={translate('description')}
        />
      </Box>

      <Box mb={2}>
        <Field
          name="propertyAddress"
          component={renderLocation}
          label={translate('location')}
          translate={translate}
        />
      </Box>

      <Box mb={2}>
        <Field
          name="team"
          component={renderFieldTeam}
          label={translate('assignedTeam')}
          translate={translate}
          handleClick={handleClick}
          anchorEl={anchorEl}
          inputEl={inputEl}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

export default VisitDetailsForm;

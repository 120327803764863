import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import MaterialTable, { MTableToolbar } from "material-table";
import {
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  Popover,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { WAREHOUSE_ACTIONS } from "common/constant";

import { getHub, hubAction } from "redux/hub";
import { Add as AddIcon, MoreHoriz as MoreHorizIcon } from "@material-ui/icons";
import { showErrorMessage, showSuccessMessage } from "lib/notifier";
import styles from "./styles.module.scss";

import archiveIcon from "assets/images/icons/archive.svg";
import editIcon from "assets/images/icons/edit.svg";

const options = {
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  search: true,
  debounceInterval: 1000,
  searchFieldAlignment: "left",
};

const renderFields = ({ translate }) => [
  {
    field: "archived",
    filtering: false,
    sorting: false,
    render: (rowData) => {
      if (!rowData.archived) return null;
      return (
        <Tooltip title={translate("Common:archived")}>
          <img
            style={{ minWidth: 20, height: 20 }}
            src={archiveIcon}
            alt="archiveIcon"
          />
        </Tooltip>
      );
    },
  },
  {
    field: "name",
    title: translate("name"),
    filtering: false,
  },
  {
    field: "address",
    title: translate("address"),
    filtering: false,
    sorting: false,
  },
  {
    field: "postalCode",
    cellStyle: {
      width: "15%",
    },
    title: translate("city/postal"),
    filtering: false,
    render: (rowData) => {
      return (
        <Grid container direction="column">
          <Grid item>
            <Typography>{rowData.city}</Typography>
          </Grid>
          <Grid item>
            <Typography style={{ color: "#A2A2A2" }}>
              {rowData.zipCode}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: "description",
    title: translate("Common:description"),
    filtering: false,
    sorting: false,
  },
  {
    field: "note",
    title: translate("note"),
    filtering: false,
    sorting: false,
  },
];

const HubTable = ({ history, enterpriseId, tableTitle }) => {
  const { t: translate } = useTranslation("Hub");

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [includingArchived, setIncludingArchived] = useState(false);
  const tableRef = useRef();
  const dispatch = useDispatch();
  let columns = renderFields({ translate });

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(rowData);
  };

  const handleClose = () => {
    setIsOpenModalConfirm(false);
    setAnchorEl(null);
    setSelectedRow({});
  };

  const handleOpenModal = () => {
    setAnchorEl(null);
    setIsOpenModalConfirm(true);
  };

  const handleConfirm = async () => {
    const isArchive = selectedRow.archived;
    const payload = {
      action: isArchive
        ? WAREHOUSE_ACTIONS.UNARCHIVE_WAREHOUSE
        : WAREHOUSE_ACTIONS.ARCHIVE_WAREHOUSE,
      hubId: selectedRow.id,
      enterpriseId,
    };
    const result = await dispatch(hubAction(payload)).catch((e) => ({
      e,
    }));

    if (result.e) return showErrorMessage(result.e);
    handleClose();
    showSuccessMessage(
      translate(isArchive ? "unarchiveConfirm" : "archiveConfirm")
    );
    // refresh table
    if (tableRef.current) {
      tableRef.current.onQueryChange({ page: 0 });
    }
  };

  const handleClickEdit = async () =>
    history.push(`/hub/${selectedRow.id}/edit`, {
      data: { enterpriseId },
    });

  columns = [
    ...columns,
    {
      width: "3%",
      field: "action",
      filtering: false,
      sorting: false,
      disableClick: true,
      render: (rowData) => {
        return (
          <>
            <IconButton onClick={(item) => handleClick(item, rowData)}>
              <MoreHorizIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const fetchWarehouses = async (query) => {
    const {
      page,
      pageSize,
      search,
      orderBy,
      orderDirection,
      includeArchived,
    } = query;

    let sortParams = { "$sort[name]": 1 };
    if (orderBy) {
      const sortOrder = orderDirection === "asc" ? 1 : -1;
      const sortKey = `$sort[${orderBy["field"]}]`;
      sortParams = { [sortKey]: sortOrder };
    }

    let params = {
      $limit: pageSize,
      $skip: page * pageSize,
      $q: search,
      enterpriseId,
      ...sortParams,
    };

    if (includeArchived) params = { ...params, includeArchived };

    const response = await dispatch(getHub({ params })).catch((e) => ({
      e,
    }));
    if (response.e) {
      showErrorMessage(response.e);
      return Promise.reject(response);
    }
    return Promise.resolve({
      data: response.data,
      page,
      totalCount: response.total,
    });
  };

  const handleClickCreate = () =>
    history.push("/hub/new", { data: { enterpriseId } });

  const handleRefreshTable = (event) => {
    setIncludingArchived(event.target.checked);
    if (tableRef.current) {
      tableRef.current.onQueryChange({
        includeArchived: event.target.checked,
        page: 0,
      });
    }
  };

  return (
    <>
      <Dialog
        open={isOpenModalConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography variant="h4">
            {translate(selectedRow.archived ? "unarchiveHub" : "archiveHub")}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            className={styles.close_btn}
          >
            {translate("Common:close")}
          </Button>
          <Button
            onClick={handleConfirm}
            className={styles.confirm_archived}
            variant="contained"
            autoFocus
          >
            {translate(
              selectedRow.archived ? "Common:unarchive" : "Common:archive"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        classes={{ paper: styles.border_adjust }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={2}>
          <MenuItem onClick={handleClickEdit}>
            <img src={editIcon} alt="edit_icon" className="mr_10" />
            <Typography className={styles.edit_color}>
              {translate("edit")}
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleOpenModal} className="mt_10">
            <img src={archiveIcon} alt="archive_icon" className="mr_10" />
            <Typography>
              {selectedRow && selectedRow.archived
                ? translate("Common:unarchive")
                : translate("Common:archive")}
            </Typography>
          </MenuItem>
        </Box>
      </Popover>
      <MaterialTable
        title={tableTitle || null}
        columns={columns}
        options={options}
        data={fetchWarehouses}
        tableRef={tableRef}
        onRowClick={(event, rowData) =>
          history.push(`/hub/${rowData.id}`, { data: { enterpriseId } })
        }
        components={{
          Toolbar: (props) => (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pr={2}
              className={styles.toolbar}
            >
              <Box display="flex" alignItems="center">
                <MTableToolbar {...props} />
                <FormControlLabel
                  classes={{ root: styles.form_label }}
                  control={
                    <Checkbox
                      checked={includingArchived}
                      onChange={handleRefreshTable}
                      value="includingArchived"
                    />
                  }
                  label={translate("Client:showArchived")}
                />
              </Box>
              <Grid item>
                <Button
                  onClick={handleClickCreate}
                  className={styles.btn_add_warehouse}
                  variant="contained"
                >
                  <AddIcon />
                  {translate("newHub")}
                </Button>
              </Grid>
            </Box>
          ),
        }}
      />
    </>
  );
};

export default HubTable;

import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import BusinessIcon from '@material-ui/icons/Business';

import moment from 'moment';
import { getTimeFormat } from 'lib/formatter';

function ClientHeader() {
  const [time, setTime] = useState(moment());
  useEffect(() => {
    const timerID = setInterval( () => setTime(moment()), 60000 );
    return function cleanup() {
      clearInterval(timerID);
    };
  });
  return (
    <div className={styles.header}>
      <div className={styles.userAvatar}>
        <div className={styles.avatar}><BusinessIcon fontSize="large" color="primary" /></div>
      </div>
      <span>
        {time.format('DD MMMM YYYY')}
        {' '}|{' '}
        {time.format(getTimeFormat())}
      </span>
    </div>
  );
}

export default ClientHeader;
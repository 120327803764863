export default theme => ({
  formContainer: {
    position: 'relative'
  },
  locationSuggestion: {
    padding: 4,
    position: 'absolute',
    width: '100%',
    marginLeft: '-4px',
    zIndex: 10,
    background: 'white'
  },
  searchResults: {
    // backgroundColor: theme.palette.common.neutral,
    maxHeight: 240,
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  addressIcon: {
    width: 22,
    marginLeft: '-15px',
    marginRight: 5
  },
  itemAddress: {
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.common.neutral
    }
  },
  poweredBy: {
    color: '#A2A2A2'
  }
});
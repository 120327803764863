import React, { useEffect } from 'react';

import { reduxForm, Field, change } from 'redux-form';
import { useDispatch } from 'react-redux';
import {
  Button,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import styles from '../../components.module.scss';
import moment from 'moment';
import { StatusChip } from 'pages/Dashboard/components';
import { showErrorMessage } from 'lib/notifier';

import { getVisits } from 'redux/visit';

import detailsIcon from 'assets/images/icons/details.svg';
import { getDateTimeFormat } from 'lib/formatter';

const FORM_NAME = 'GeneralInfoForm';

const RenderVisitTable = ({ input, translate }) => {
  if (!Array.isArray(input.value)) return null;
  return (
    <Box mt={1} display="flex" flexDirection="column">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={styles.header_text}>
              {translate('title')}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate('startAt')}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate('endAt')}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate('status')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {input.value.map((row) => (
            <TableRow>
              <TableCell className={styles.table_content}>
                {row.title}
              </TableCell>
              <TableCell className={styles.table_content}>
                {row.start &&
                  moment(row.start).format(getDateTimeFormat())}
              </TableCell>
              <TableCell className={styles.table_content}>
                {row.end &&
                  moment(row.end).format(getDateTimeFormat())}
              </TableCell>
              <TableCell className={styles.table_content}>
                {row.statusId && <StatusChip job status={row.statusId} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const Visits = (props) => {
  const { translate, visitDetails: { job } = {} } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!job || !job.publicId) return null;
    (async () => {
      const result = await dispatch(getVisits({ jobId: job.publicId })).catch(
        (e) => ({
          e,
        })
      );
      if (result.e) return showErrorMessage(result.e);
      dispatch(change(FORM_NAME, 'visits', result.data.data));
    })();
  }, [job, dispatch]);

  const handleClickManagement = () =>
    window.open(`/jobs/${job.publicId}`, '_blank');

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" className={styles.modal_title}>
          {translate('visits')}
        </Typography>
        <Button
          onClick={handleClickManagement}
          variant="contained"
          className={styles.btn_management}
        >
          <img src={detailsIcon} alt="detailsIcon" className="mr_5" />
          {translate('visitManagement')}
        </Button>
      </Box>

      <Field name="visits" component={RenderVisitTable} translate={translate} />
    </Box>
  );
};

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(Visits);

import React from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// shared component
import { GoogleMap } from 'components/gpsTracking';
// Redux
import { connect } from 'react-redux';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { getLocations } from 'redux/location';
// Component styles
import styles from './styles';

import { showErrorMessage } from 'lib/notifier';


const pinkMarker = require('assets/images/marker/pink-marker.svg');
const cyanMarker = require('assets/images/marker/cyan-marker.svg');
const darkYellowMarker = require('assets/images/marker/dark-yellow-marker.svg');
const purpleMarker = require('assets/images/marker/purple-marker.svg');
const blueMarker = require('assets/images/marker/blue-marker.svg');


const heightTopbar = '64px';
const mapStyles = {
  height: `calc(100vh - ${heightTopbar})`,
  width: '100%'
};

const workerIcon = status => {
  let icon;

  switch (status) {
    case 'ON_STANDBY':
      icon = darkYellowMarker;
      break;

    case 'ON_THE_WAY':
      icon = pinkMarker;
      break;

    case 'ARRIVED':
      icon = purpleMarker;
      break;

    case 'STARTED':
      icon = cyanMarker;
      break;

    case 'COMPLETED':
      icon = blueMarker;
      break;

    default:
      icon = pinkMarker;
  }
  return icon;
};

const formatPlaces = (places=[]) => {
  return places.map(item => ({
    location: item.userLocation,
    icon: workerIcon(item.nextJob ? item.nextJob.status : ''),
    contentInfoWindow: item.fullName,
    userId: item.id
  }));
};


class TrackingMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      places: [],
      showMap: false
    };
    this.onClickMarker = this.onClickMarker.bind(this);
  }

  async componentDidMount() {
    const { getLocations } = this.props;
    try {
      const response = await getLocations();
      const markers = formatPlaces(response.data);
      this.setState({ places: markers, showMap: true });
    } catch (error) {
      showErrorMessage(error);
    }
  }

  componentWillUnmount() {
    this.setState({ showMap: false });
  }

  onClickMarker(marker) {
    this.props.history.push(`/gps/${marker.userId}/details`);
  }

  render() {

    const { classes } = this.props;
    const { places, showMap } = this.state;

    return (
      <div className={classes.root}>
        <Grid
          container
          justify="center"
        >
          <Grid
            item
            lg={12}
            xs={12}
          >
            <div className={classes.content} style={mapStyles}>
              {
                showMap &&
                <GoogleMap places={places} onClickMarker={this.onClickMarker} />
              }
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TrackingMap.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  getLocations: (options) => dispatch(getLocations(options)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(TrackingMap);
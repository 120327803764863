/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { showErrorMessage } from 'lib/notifier';
// Material components
import {
  Dialog,
  DialogContent,
  Grid,
  withStyles,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';

// Material icons
import { Close as CloseIcon } from '@material-ui/icons';
import folderIcon from 'assets/images/folder.svg';
import tagsIcon from 'assets/images/tags.svg';
import editIcon from 'assets/icons/edit.svg';
import { getMedia } from 'redux/media';
// Component styles
import styles from './styles.module.scss';
import { LoadingModal } from 'pages/Dashboard/components';
import { moment } from 'lib/app-moment';
import { DeleteMediaDetails } from '../index';
import { MEDIA_TYPES, MEDIA_PHASES } from 'common/constant';
import EditTags from './components/EditTags';
import VisitAssign from './components/VisitAssign';

const Tag = ({ children }) => {
  return (
    <Grid className={styles.div_tag}>
      <span className={styles.tag}>{children}</span>
    </Grid>
  );
};

let MediaDetails = (props) => {
  const {
    open,
    changeModalState,
    currentMediaSelected,
    fetchMedia,
    fetchVisits,
    getMedia,
    afterUpdateMedia,
    translate,
  } = props;
  const [mediaDetails, setMediaDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [editTag, setIsEditTag] = useState(false);
  const getMediaDetails = async () => {
    const response = await getMedia(currentMediaSelected.id).catch((e) => ({
      e,
    }));
    if (response.e) return showErrorMessage(response.e);
    setMediaDetails(response.data);
  };
  useEffect(() => {
    setIsLoading(true);
    getMediaDetails();
    setIsLoading(false);
  }, [currentMediaSelected.id]);

  if (isLoading) return <LoadingModal open={isLoading} />;
  let leftComponent = null;
  if (mediaDetails && mediaDetails.mediaType) {
    if (mediaDetails.mediaType === MEDIA_TYPES.PHOTO.toString()) {
      leftComponent = (
        <img
          className={styles.img_notes}
          alt="note_image"
          src={mediaDetails.mediaUrl}
        />
      );
    } else if (mediaDetails.mediaType === MEDIA_TYPES.VIDEO.toString()) {
      leftComponent = (
        <video
          className={styles.video}
          src={mediaDetails.mediaUrl}
          autoPlay
          loop
          controls
        />
      );
    } else {
      leftComponent = (
        <Grid
          className={styles.fileStyles}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <img alt="media_file" src={require('assets/icons/file.svg')} />
          </Grid>
          <Grid item className={styles.paddingVertical}>
            <span>{mediaDetails.fileName}</span>
          </Grid>
          <Button
            onClick={() =>
              window.open(
                mediaDetails.mediaUrl,
                '_blank',
                'noopener,noreferrer'
              )}
            className={styles.btnViewFile}
          >
            <img alt="media_file" src={require('assets/icons/viewFile.svg')} />
            <span>{translate('viewFile')}</span>
          </Button>
        </Grid>
      );
    }
  }
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{ paper: styles.modalPaper }}
      onClose={() => changeModalState(false)}
    >
      <DialogContent className={styles.modalContent}>
        <Grid className={styles.container} container>
          <Grid
            xs={7}
            item
            container
            justify="center"
            alignItems="center"
            className={styles.fileContainer}
          >
            {leftComponent}
          </Grid>
          <Grid
            xs={5}
            item
            className={styles.container}
            container
            direction="column"
          >
            <Grid container item justify="flex-end" className={styles.header}>
              <Grid>
                <DeleteMediaDetails
                  changeModalState={changeModalState}
                  fetchMedia={fetchMedia}
                  fetchVisits={fetchVisits}
                  afterUpdateMedia={afterUpdateMedia}
                  mediaDetails={mediaDetails}
                />
              </Grid>
              <Grid className={styles.closeIcon}>
                <IconButton
                  className={styles.iconBtn}
                  onClick={() => changeModalState(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item className={styles.content}>
              <Grid item className={styles.title}>
                {mediaDetails && mediaDetails.issuedBy && (
                  <Grid>
                    <Typography variant="h4">
                      {mediaDetails.issuedBy.fullName}
                    </Typography>
                  </Grid>
                )}
                <Grid>
                  <Typography className={styles.dateTime}>
                    {translate('submittedOn')}{' '}
                    {moment(mediaDetails.createdAt).format(
                      'MMM DD, YYYY - hh:mm'
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item className={styles.evalSpacing}>
                <img src={folderIcon} alt="folder_icon" className="mr_10" />
                <Typography className={styles.evaluation}>
                  {MEDIA_PHASES[mediaDetails.mediaPhase]}
                </Typography>
              </Grid>

              <Grid
                container
                item
              >
                <VisitAssign
                  fetchVisits={fetchVisits}
                  mediaDetails={mediaDetails}
                  setMediaDetails={setMediaDetails}
                  getMediaDetails={getMediaDetails}
                />
              </Grid>

              <Grid className={styles.paddingTop}>
                {!editTag ? (
                  <Grid item>
                    <Grid
                      container
                      justify="space-between"
                      className={styles.mb_15}
                    >
                      <Grid item className={styles.tagContainer}>
                        <img src={tagsIcon} alt="tag_icon" className="mr_10" />
                        <Typography className={styles.evaluation}>
                          {translate('tag')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => setIsEditTag(true)}
                          color="secondary"
                        >
                          <img src={editIcon} alt="edit_icon" />
                          <span className={styles.editTag}>
                            {translate('Common:edit')}
                          </span>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container>
                      {mediaDetails &&
                        mediaDetails.tags &&
                        mediaDetails.tags.map((tag, index) => (
                          <Tag key={index}>{tag && tag.text}</Tag>
                        ))}
                    </Grid>
                  </Grid>
                ) : (
                  <EditTags
                    setMediaDetails={setMediaDetails}
                    setIsEditTag={setIsEditTag}
                    currentMediaSelected={currentMediaSelected}
                    translate={translate}
                    tags={mediaDetails && mediaDetails.tags}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMedia: (params) => dispatch(getMedia(params)),
});

MediaDetails = reduxForm({
  form: 'mediaDetailsForm',
})(MediaDetails);

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(MediaDetails);

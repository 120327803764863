import React from 'react';

// Material components
import { Button, TextField } from '@material-ui/core';

export default props => {
  const { input, buttonLabel, buttonClass, id, inputClass, ...rest } = props;
  const { input: { onChange, onBlur } } = props;
  delete input.value;

  const onFileChange = handler => event => {
    const targetFile = event.target.files[0];

    if (targetFile) {
      handler({ file: targetFile, name: targetFile.name });
    } else {
      handler(null);
    }
  };

  return (
    <>
      <TextField
        accept='.jpg, .png, .jpeg'
        className={inputClass}
        id={id}
        type="file"
        onChange={onFileChange(onChange)}
        onBlur={onFileChange(onBlur)}
        {...input}
        {...rest}
      />
      <label htmlFor={id}>
        <Button component="span" color="primary" className={buttonClass}>
          {buttonLabel}
        </Button>
      </label>
    </>
  );
};
import React, { useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
// Externals
import PropTypes from 'prop-types';

// Redux
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { searchPlaces, getPlaceDetails } from 'redux/location';
import { showErrorMessage } from 'lib/notifier';

import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  isPristine,
} from 'redux-form';
// Material Components
import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Avatar,
  Fab,
  CircularProgress,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
// Shared components
import { TextInput, ReactSelect } from 'components/inputs';
import Countries from 'lib/countryList';
import GoogleSrc from 'assets/images/google.svg';

// Component styles
import styles from './styles.module.scss';

const countryList = new Countries();
const staffDetailsForm = formValueSelector('BusinessDetailsForm');
let timer;
const SEARCH_TIMEOUT = 800;
const FORM_NAME = 'BusinessDetailsForm';

const getSearchOptions = ({ auth = {} }) => {
  const { currentUser } = auth;
  let language = 'en';
  let placeSearchOptions = {
    language,
  };
  // pick first company in list for country code
  const companies = (currentUser && currentUser.companies) || [];
  if (
    Array.isArray(companies) &&
    companies.length > 0 &&
    companies[0].countryCode
  ) {
    const { countryCode } = companies[0];
    // get language from user first, if not have get from countrycode
    language =
      currentUser && currentUser.language
        ? currentUser.language
        : countryList.getLanguageCodeByCountryCode(countryCode);
    placeSearchOptions = {
      ...placeSearchOptions,
      language,
      components: `country:${countryCode}`,
    };
  }
  return placeSearchOptions;
};

const mapStateToProps = (state, ownProps) => {
  const { workingHours, ...otherProps } = ownProps.companyDetails;
  return {
    initialValues: {
      ...otherProps,
    },
    profilePictureUrl: staffDetailsForm(state, 'profilePicture'),
    companyTypes: state.config.configs.companyTypes,
    pristine: isPristine(ownProps.form)(state),
    placeSearchOptions: getSearchOptions(state),
  };
};
const countryCodeOptions = new Countries().countryCodes();
const countryPhoneCodeOptions = new Countries().countryCodesPhoneCodes();

const mapDispatchToProps = (dispatch) => ({
  updateProfilePicture: (file) =>
    dispatch(change('BusinessDetailsForm', 'profilePicture', file)),
});

let BusinessDetails = (props) => {
  const dispatch = useDispatch();
  const {
    pristine,
    invalid,
    submitting,
    handleSubmit,
    translate,
    profilePictureUrl,
    placeSearchOptions,
    companyTypes,
  } = props;
  const [isSearchingPlace, setIsSearchingPlace] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const radioButton = ({ input, ...rest }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <RadioGroup {...input} {...rest}>
      {companyTypes.map((item, i) => {
        return (
          <FormControlLabel
            key={i}
            value={`${item.id}`}
            control={<Radio />}
            label={item.name}
          />
        );
      })}
    </RadioGroup>
  );

  let profilePicture;
  const imageType = (profilePictureUrl && typeof profilePictureUrl) || null;

  switch (imageType) {
    case 'object':
      profilePicture =
        (profilePictureUrl &&
          Object.keys(profilePictureUrl).length > 0 &&
          URL.createObjectURL(profilePictureUrl.file)) ||
        null;
      break;

    case 'string':
      profilePicture = profilePictureUrl;
      break;

    default:
      break;
  }

  function handleChange(files) {
    const file = files && files[0];
    props.updateProfilePicture(file ? { file } : null);
  }

  function handleRemoveProfilePicture() {
    props.updateProfilePicture(null);
  }

  function parsePlaceDetails(place) {
    let placeAddress = place.formatted_address;
    if (place.name && !place.formatted_address.includes(place.name)) {
      placeAddress = `${place.name}, ${place.formatted_address}`;
    }
    const location = {
      address: placeAddress,
      placeId: place.place_id,
      lat: place.geometry.location.lat,
      lng: place.geometry.location.lng,
      name: place.name,
    };
    const addressComponents = place.address_components;
    const floor = addressComponents.find((item) =>
      item.types.includes('floor')
    );
    const room = addressComponents.find((item) => item.types.includes('room'));
    const city = addressComponents.find((item) =>
      item.types.includes('locality')
    );
    const postalCode = addressComponents.find((item) =>
      item.types.includes('postal_code')
    );
    const state = addressComponents.find((item) =>
      item.types.includes('administrative_area_level_1')
    );
    const country = addressComponents.find((item) =>
      item.types.includes('country')
    );
    location.unitNumber = [floor && floor.long_name, room && room.long_name]
      .filter(Boolean)
      .join(' ');
    location.city = (city && city.long_name) || '';
    location.postalCode = (postalCode && postalCode.long_name) || '';
    location.state = (state && state.long_name) || '';
    location.countryCode = (country && country.short_name) || '';
    return location;
  }

  const handleSelectLocation = ({
    countryCode,
    postalCode,
    city,
    address,
    state,
  }) => {
    dispatch(change(FORM_NAME, 'country', countryCode));
    dispatch(change(FORM_NAME, 'postalCode', postalCode));
    dispatch(change(FORM_NAME, 'city', city));
    dispatch(change(FORM_NAME, 'address', address));
    dispatch(change(FORM_NAME, 'state', state));
  };

  async function chooseLocation(event, location) {
    event.preventDefault();
    const response = await dispatch(
      getPlaceDetails(location.place_id)
    ).catch((error) => ({ error }));
    if (response && response.error) return showErrorMessage(response.error);
    if (response && response.status !== 200)
      return showErrorMessage(
        response && response.data && response.data.message
      );
    const { result } = response.data;
    const locationDetail = parsePlaceDetails(result);
    handleSelectLocation(locationDetail);
    setSearchResults(null);
  }

  const addressSearching = (event) => {
    const textSearch = event.target.value;
    clearTimeout(timer);
    if (!textSearch) {
      setIsSearchingPlace(false);
      return setSearchResults(null);
    }
    setIsSearchingPlace(true);
    // eslint-disable-next-line no-return-assign
    return (timer = setTimeout(async () => {
      const response = await dispatch(
        searchPlaces(textSearch, placeSearchOptions)
      ).catch((error) => ({ error }));
      setIsSearchingPlace(false);
      if (response && response.error) return showErrorMessage(response.error);
      if (response && response.status !== 200)
        return showErrorMessage(response.data && response.data.message);
      const { predictions } = response.data;
      setSearchResults(predictions);
    }, SEARCH_TIMEOUT));
  };

  const handleClickAway = () => setSearchResults(null);

  return (
    <div className={styles.companySetting}>
      <form className="form-body" onSubmit={handleSubmit}>
        <div className={styles.formTitle}>
          <div className={styles.title}>
            <h2>{translate('CompanySetting:businessDetails')}</h2>
          </div>
          <div className={styles.submitBtnWrap}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              size="large"
              className={styles.btnStyle}
              disabled={pristine || submitting || invalid}
            >
              {translate('CompanySetting:saveChange')}
            </Button>
          </div>
        </div>

        <FormLabel component="legend" className={styles.formLabel}>
          {translate('CompanySetting:profilePicture')}
        </FormLabel>

        <Grid item md={12} className={styles.dropzoneSection}>
          {profilePicture ? (
            <div className={styles.previewImageWrap}>
              <Avatar className={styles.avatar} src={profilePicture} />
              <Fab
                onClick={handleRemoveProfilePicture}
                aria-label="Delete"
                className={styles.removeBtn}
              >
                <DeleteIcon />
              </Fab>
            </div>
          ) : (
            <>
              <DropzoneArea
                dropzoneText={translate('Common:dropFileToUpload')}
                dropzoneClass={styles.dropzoneContent}
                acceptedFiles={['image/jpeg', 'image/png']}
                filesLimit={1}
                maxFileSize={99000}
                onChange={handleChange}
              />
              <FormLabel component="legend" className={styles.fileUploadHelper}>
                {translate('CompanySetting:fileUploadHelper')}
              </FormLabel>
            </>
          )}
        </Grid>

        <Grid item container spacing={2} justify="flex-start">
          <Grid item xs={12} sm={6} md={6}>
            <FormLabel component="legend" className={styles.formLabel}>
              {translate('CompanySetting:businessType')}
            </FormLabel>
            <Field
              name="companyTypeId"
              className={styles.companyTypes}
              component={radioButton}
            />
          </Grid>
        </Grid>
        <FormLabel component="legend" className={styles.formLabel}>
          {translate('CompanySetting:compulsoryField')}
        </FormLabel>
        <Grid item container spacing={2} justify="center">
          <Grid item xs={12} sm={6}>
            <Field
              name="name"
              component={TextInput}
              margin="normal"
              label={translate('CompanySetting:fullLegalName')}
              type="text"
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Field
              name="countryPhoneCode"
              component={ReactSelect}
              label={translate('Common:countryCode')}
              margin="normal"
              options={countryPhoneCodeOptions}
              className={styles.countryPhoneCodeSelector}
              classNamePrefix="select"
              placeholder=""
              required
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <Field
              name="contactPhoneNumber"
              component={TextInput}
              margin="normal"
              label={translate('Common:contactPhoneNumber')}
              type="number"
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="center">
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="registrationNumber"
              component={TextInput}
              label={translate('CompanySetting:registrationNumber')}
              margin="normal"
              type="text"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="contactEmail"
              component={TextInput}
              label={translate('Common:email')}
              margin="normal"
              type="text"
              variant="outlined"
              required
              helperText={translate('CompanySetting:emailHelper')}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="center">
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="tradingName"
              component={TextInput}
              margin="normal"
              label={translate('CompanySetting:tradingName')}
              type="text"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="website"
              component={TextInput}
              margin="normal"
              label={translate('CompanySetting:website')}
              type="text"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2} className={styles.registeredAddress}>
          {translate('CompanySetting:registeredAddress')}
        </Grid>

        <Grid item container xs={12} sm={12} md={12}>
          <Field
            name="address"
            component={TextInput}
            margin="normal"
            label={translate('Common:companyAddress1')}
            type="text"
            variant="outlined"
            autoComplete="nickname"
            onChange={(e) => addressSearching(e)}
            InputProps={{
              endAdornment: isSearchingPlace && (
                <CircularProgress size={25} position="end" color="secondary" />
              ),
            }}
            required
          />
          {Array.isArray(searchResults) && !!searchResults.length > 0 && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Grid container className={styles.container}>
                <div className={styles.addressWrapper}>
                  <Grid className={styles.addressList}>
                    <List>
                      {searchResults.map((item) => {
                        return (
                          <ListItem
                            key={item.place_id}
                            className={styles.itemAddress}
                            onClick={(e) => chooseLocation(e, item)}
                          >
                            <ListItemText
                              primary={
                                item.structured_formatting &&
                                item.structured_formatting.main_text
                              }
                              secondary={
                                item.structured_formatting &&
                                item.structured_formatting.secondary_text
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Box
                    alignItem="center"
                    display="flex"
                    justifyContent="center"
                    p={1}
                  >
                    <Typography
                      variant="subtitle1"
                      className={styles.poweredBy}
                    >
                      {translate('Common:poweredBy')}
                    </Typography>
                    <img className="ml_5" src={GoogleSrc} alt="GoogleSrc" />
                  </Box>
                </div>
              </Grid>
            </ClickAwayListener>
          )}
        </Grid>
        <Grid item container spacing={2} justify="center">
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="city"
              component={TextInput}
              margin="normal"
              label={translate('city')}
              type="text"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="state"
              component={TextInput}
              margin="normal"
              label={translate('state')}
              type="text"
              variant="outlined"
              required
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="center">
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="zipCode"
              component={TextInput}
              margin="normal"
              label={translate('postalCode')}
              type="number"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              name="countryCode"
              component={ReactSelect}
              label={translate('Common:country')}
              margin="normal"
              options={countryCodeOptions}
              className={styles.countryPhoneCodeSelector}
              classNamePrefix="select"
              placeholder=""
              required
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

BusinessDetails.propTypes = {
  className: PropTypes.string,
};

BusinessDetails = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(BusinessDetails);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BusinessDetails
);

export default {
  // common && crumbs
  newQuote: 'ใบเสนอราคาใหม่',
  quotes: 'ใบเสนอราคาทั้งหมด',
  quote: 'ใบเสนอราคา',
  quoteDetails: 'รายละเอียดใบเสนอราคา',
  editQuote: 'แก้ไขใบเสนอราคา',
  quoteForClient: 'เสนอราคาให้กับ',
  quoteTitle: 'หัวข้อใบเสนอราคา',
  jobType: 'ชนิดของงาน',
  clientName: 'ชื่อลูกค้า',
  propertyAddress: 'ที่อยู่',
  billingAddress: 'ที่อยู่ในการออกใบกำกับภาษี',
  contactDetails:	'รายละเอียดการติดต่อ',
  saveQuote: 'บันทึกใบเสนอราคา',
  issuedAt: 'ออกใบเสนอราคา',
  expireAt: 'หมดอายุ',
  validity: 'ใช้ได้จนถึง',
  copyQuoteTo: 'คัดลอกใบเสนอราคาไปยัง',
  convertToJob: 'สร้างงาน',
  noQuoteMatches: 'ไม่มีใบเสนอราคาที่ตรงกัน',

  // line items
  lineItems:	'รายการทั้งหมด',
  serviceProduct:	'สินค้า/บริการ',
  qty:	'จำนวน',
  unitPrice:	'ราคาต่อหน่วย',
  taxRate:	'ภาษี (%)',
  totalBeforeTax:	'ยอดรวม (ก่อนภาษี)',
  item:	'รายการ',
  addLineItem:	'+ เพิ่มรายการ',
  clientMessage:	'หมายเหตุ',
  subtotal:	'รวม',
  discount:	'ส่วนลด',
  tax:	'ภาษี',
  total:	'รวม',
  totalPrice: 'ราคารวมทั้งหมด',
  deposit: 'มัดจำ',

  internalNotes:	'บันทึกภายใน',
  comment: 'คอมเมนท์ (สำหรับใช้ภายในเท่านั้น)',
  noteDetails:	'รายละเอียดบันทึก',
  createdOn: 'สร้างขึ้นเมื่อ',
  emailQuote:	'อีเมลใบเสนอราคา',
  quantity: 'จำนวน',
  productService: 'สินค้า/บริการ',
  jobTitle: 'หัวข้องาน',
  startDate: 'เวลาเริ่ม',
  endDate: 'เวลาจบ',
  sendAs: 'ส่งทาง',
  collectSignature: 'เก็บลายเซ็น',
  downloadPdf: 'ดาวน์โหลดไฟล์ PDF',
  print: 'ปรินท์',
  status: 'สถานะ',
  dueDate: 'ครบกำหนด',
  jobPrice: 'ราคางาน',

  // client modal
  selectCreateClient:	'เลือกหรือสร้างลูกค้าใหม่',
  whichClient:	'งานนี้ถูกสร้างสำหรับลูกค้าบัญชีใด',
  createClient:	'สร้างลูกค้าใหม่',
  properties:	'สถานที่ทั้งหมด',
  noPropertyRecorded:	'ยังไม่มีสถานที่ที่บันทึกไว้',
  createNewClient:	'สร้างลูกค้าใหม่',

  // property modal
  selectCreateProperty:	'เลือกหรือสร้างสถานที่ใหม่',
  whichProperty:	'งานนี้ถูกสร้างสำหรับสถานที่ใด',
  property:	'สถานที่',
  createProperty:	'สร้างสถานที่',
  updateProperty:	'อัปเดตสถานที่',
  createPropertyFor: 'สร้างสถานที่ใหม่สำหรับ',
  updatePropertyFor: 'อัปเดตสถานที่ใหม่สำหรับ',
  createNewProperty:	'สร้างสถานที่ใหม่',
  coordsRequired: 'จำเป็นต้องใส่พิกัดของสถานที่เพื่อที่ GPS จะทำงานได้สมบูรณ์ กรุณาใส่เลขละติจูดและลองจิจูดสำหรับสถานที่นี้ หรือลองกดค้นหาสถานที่อีกครั้ง',
  quoteCopied: 'Quote copied!',
};
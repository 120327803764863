import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ClockInOut, VisitTimesheet } from "../form";
import {
  AppBar,
  Tabs,
  Tab,
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  Tooltip,
  Checkbox,
  IconButton,
  Grid,
} from "@material-ui/core";
import { DateRangePicker } from "react-date-range";
import GetAppIcon from "@material-ui/icons/GetApp";
import { compose } from "redux";
import { connect } from "react-redux";
import { getTimesheet, getTimesheetVisit } from "redux/timesheet";
import { showErrorMessage } from "lib/notifier";
import {
  AvTimer as AvTimerIcon,
  BusinessCenter as BusinessCenterIcon,
} from "@material-ui/icons";
import styles from "./styles.module.scss";
import { moment } from "lib/app-moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import FilterIcon from "assets/images/timesheet/Filter.png";
import { defaultStaticRanges } from "./customeRangeCalendar";
import "./calendar.css";

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className={styles.panelTimesheet}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white",
    color: "black",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "none",
    maxHeight: 70,
  },
  datePicker: {
    position: "absolute",
    right: theme.spacing(3),
    zIndex: 2,
    boxShadow:
      "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14)",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
  },
  divider: {
    height: "100%",
    width: 1,
    margin: "0 10",
  },
  panel: {
    height: "83vh",
    overflow: "scroll",
  },
  iconWrapper: {
    width: "100%",
    display: "inline-flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    "& svg": {
      marginBottom: "0px !important",
      marginRight: 8,
    },
  },
  textColor: {
    color: "#3450c8 !important",
    fontWeight: "700 !important",
  },
  selectedCICO: {
    fontWeight: "700 !important",
  },
  indicator: {
    backgroundColor: "gray",
  },
  selectFont: {
    fontWeight: "700 !important",
  },
  rootTabs: {
    fontSize: "16px",
    fontWeight: 700,
    padding: "0 20px",
  },
  menuExport: {
    // fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 15,
    alignItems: "center",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

function TimesheetTab(props) {
  const {
    translate,
    currentStaffId,
    getTimesheetAction,
    getTimesheetVisitAction,
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const getEndDateCurrentMonth = () => {
    const startOfMonth = moment().startOf("month").toDate();
    const endOfMonth = moment().endOf("month").toDate();
    return { startDate: startOfMonth, endDate: endOfMonth };
  };
  const defaultRange = {
    ...getEndDateCurrentMonth(),
    key: "selection",
  };
  const [dateRange, setDateValue] = useState(defaultRange);
  const [lateOnlyCheckbox, setLateValue] = useState(false);
  const handleChange = (event, newValue) => {
    // handle case when setState in filer, newvalue = undefined
    if (newValue === undefined) return;
    if (!newValue) {
      setDateValue({
        ...getEndDateCurrentMonth(),
        key: "selection",
      });
    } else {
      setDateValue({
        startDate: moment().subtract(7, "days").startOf("day").toDate(),
        endDate: moment().startOf("day").toDate(),
        key: "selection",
      });
    }
    setValue(newValue);
  };
  const handleShowLate = () => {
    setLateValue(!lateOnlyCheckbox);
  };

  const query = {
    fromDate: dateRange.startDate,
    toDate: dateRange.endDate,
    showLateOnly: lateOnlyCheckbox,
    userId: currentStaffId,
  };
  return (
    <>
      <AppBar className={classes.appBar} position="static">
        <Grid className={styles.tabItem} item xs={6}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="timesheet_tab"
            indicatorColor="primary"
            textColor="primary"
            classes={{ indicator: classes.indicator }}
          >
            <Tab
              className={classes.rootTabs}
              classes={{
                wrapper: classes.iconWrapper,
                selected: classes.selectedCICO,
              }}
              icon={<AvTimerIcon />}
              label={translate("Timesheet:clockinout")}
            />
            <Tab
              className={classes.rootTabs}
              classes={{
                wrapper: classes.iconWrapper,
                selected: classes.textColor,
              }}
              icon={<BusinessCenterIcon />}
              label={translate("Timesheet:visits")}
            />
          </Tabs>
        </Grid>
        <Grid className={styles.btnContainer} item xs={6}>
          <Grid className={styles.btnContainer} alignItems="center" container>
            <div className={classes.btnGroup}>
              <DateTimeSelector
                dateRange={dateRange}
                setDateValue={setDateValue}
                tabValue={value}
              />
              <FilterCheckBox
                lateOnlyCheckbox={lateOnlyCheckbox}
                translate={translate}
                handleShowLate={handleShowLate}
              />
            </div>
            <div className={styles.exportStyle}>
              <ExportCheckbox
                getTimesheet={getTimesheetAction}
                getTimesheetVisit={getTimesheetVisitAction}
                tabValue={value}
                classes={classes}
                query={query}
                translate={translate}
              />
            </div>
          </Grid>
        </Grid>
      </AppBar>
      <TabPanel classes={classes} value={value} index={0}>
        <ClockInOut
          currentStaffId={currentStaffId}
          query={query}
          translate={translate}
        />
      </TabPanel>
      <TabPanel classes={classes} value={value} index={1}>
        <VisitTimesheet
          currentStaffId={currentStaffId}
          translate={translate}
          query={query}
        />
      </TabPanel>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getTimesheetVisitAction: (option) => dispatch(getTimesheetVisit(option)),
  getTimesheetAction: (option) => dispatch(getTimesheet(option)),
});

export default compose(connect(null, mapDispatchToProps))(TimesheetTab);

const FilterCheckBox = (props) => {
  const { translate, handleShowLate, lateOnlyCheckbox } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        className={styles.filterBtn}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={FilterIcon} alt="filte_icon" />
        {translate("Timesheet:filter")}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                onClick={handleShowLate}
                checked={lateOnlyCheckbox}
                name="showLate"
                color="primary"
              />
            }
            label={translate("Timesheet:showLateOnly")}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

const ExportCheckbox = (props) => {
  const { translate, classes, query, tabValue } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const tabsAction = {
    0: (opts) => {
      return props.getTimesheet(opts);
    },
    1: (opts) => {
      return props.getTimesheetVisit(opts);
    },
  };
  const handleExport = async (type) => {
    let opts = {
      userId: query.userId,
      $from: query.fromDate,
      $to: query.toDate,
      [type]: 1,
    };
    if (query.showLateOnly) opts = { ...opts, lateOnly: query.showLateOnly };
    const { error, url } = await tabsAction[tabValue](opts).catch((e) => ({
      error: e,
    }));
    if (error) return showErrorMessage(error);
    return window.open(url, "_blank");
  };
  return (
    <>
      <Tooltip title={translate("Timesheet:exports")} aria-label="add">
        <IconButton aria-label="exports" onClick={handleClick}>
          <GetAppIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className={classes.menuExport} disabled>
          {translate("Timesheet:exportTo")}
        </MenuItem>
        <MenuItem onClick={() => handleExport("exportXLXS")}>.xlsx</MenuItem>
      </Menu>
    </>
  );
};

const DateTimeSelector = (props) => {
  const { setDateValue, dateRange, tabValue } = props;
  const getDisplayValueFromDateRange = (tempDateRange) => {
    const { startDate: tempStartDate, endDate: tempEndDate } = tempDateRange;
    const momentStartDate = moment(tempStartDate);
    const momentEndDate = moment(tempEndDate);
    return {
      startDate: new Date(
        momentStartDate.year(),
        momentStartDate.month(),
        momentStartDate.date()
      ),
      endDate: new Date(
        new Date(
          momentEndDate.year(),
          momentEndDate.month(),
          momentEndDate.date() + 1
        ) - 1
      ),
      key: "selection",
    };
  };
  const [
    dateRangePickerDisplayValue,
    setDateRangePickerDisplayValue,
  ] = useState(getDisplayValueFromDateRange(dateRange));
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setDateRangePickerDisplayValue(getDisplayValueFromDateRange(dateRange));
  }, [tabValue]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnChange = (payload) => {
    const { startDate: start, endDate: end } = payload.selection;
    setDateValue({
      ...payload.selection,
      startDate: moment()
        .startOf("days")
        .year(start.getFullYear())
        .month(start.getMonth())
        .date(start.getDate())
        .toDate(),

      endDate: moment()
        .endOf("days")
        .year(end.getFullYear())
        .month(end.getMonth())
        .date(end.getDate())
        .toDate(),
    });

    setDateRangePickerDisplayValue({
      startDate: start,
      endDate: end,
      key: "selection",
    });
  };
  const { startDate, endDate } = dateRangePickerDisplayValue;
  return (
    <>
      <Button variant="contained" onClick={handleClick}>
        {`${moment(startDate).format("MMM DD, YYYY")} - ${moment(
          endDate
        ).format("MMM DD, YYYY")}`}
        <CalendarTodayIcon className={styles.iconCalendar} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <DateRangePicker
          showPreview={false}
          onChange={handleOnChange}
          staticRanges={defaultStaticRanges}
          ranges={[dateRangePickerDisplayValue]}
        />
      </Menu>
    </>
  );
};

import React from 'react';

// Material components
// Material helpers
import { Grid, Typography, withStyles, Box } from '@material-ui/core';

import { Link } from 'react-router-dom';

import {
  // ContactDetails,
  AddressDetails,
} from 'pages/Dashboard/components';

// Component styles
import { RequestClientDetailsStyle } from './styles';
import { RequestClientNameHeader, DetailsTable } from '../utils';

function RequestClientDetailsCard(props) {
  const {
    allowedStatuses,
    classes,
    request,
    requestStatuses,
    translate,
    handleClickStatus,
    handleClickArchive,
  } = props;

  return (
    <Grid container>
      {request && Object.keys(request).length > 0 && (
        <>
          <Grid item container direction="row">
            <RequestClientNameHeader
              request={request}
              requestStatuses={requestStatuses}
              translate={translate}
              handleClickStatus={handleClickStatus}
              handleClickArchive={handleClickArchive}
              allowedStatuses={allowedStatuses}
            />
          </Grid>
          {/* // Request details box */}
          <Grid container direction="row" justify="space-between">
            <Grid
              item
              container
              className={classes.borderContainer}
              spacing={1}
              xs={5}
            >
              <Grid item container direction="row">
                <DetailsTable
                  requestArchived={request.archived}
                  requestId={request.id}
                  requestStatus={request.statusId}
                  requestType="oneOff"
                  requestStart={request.requestSchedule && request.requestSchedule.start}
                  requestEnd={request.requestSchedule && request.requestSchedule.end}
                  requestBillingFrequency={undefined}
                  translate={translate}
                  categories={request.categories}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={7}
              spacing={1}
              className={classes.borderContainer}
            >
              <Grid item container direction="column">
                <Box mb={1}>
                  <Typography variant="h3" className={classes.requestDetailsTitle}>
                    {translate('Common:clientDetails')}
                  </Typography>
                </Box>
                <Grid item container justify="space-between" direction="row">
                  <Grid item md={3}>
                    {request && request.client && (
                      <Grid item md={12}>
                        <Typography className={classes.titleColor} variant="body1">
                          {translate('Common:name')}
                        </Typography>
                        <Link to={`/clients/${request.client.id}`}>
                          <Typography
                            variant="h6"
                            className={classes.clientName}
                          >
                            {request.client.displayName ||
                              translate('Client:clientHasNoName')}
                          </Typography>
                        </Link>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item md={5}>
                    <AddressDetails
                      titleStyle={{
                        fontWeight: 400,
                        marginBottom: 0,
                        color: 'rgba(0, 0, 0, 0.5)',
                        fontSize: 14
                      }}
                      textStyle={{ color: 'black' }}
                      address={request.property}
                      translate={translate}
                      title={translate('Common:propertyAddress')}
                    />
                  </Grid>
                  <Grid item md={4} style={{ paddingLeft: 8 }}>
                    {request.client && request.client.email ? (
                      <Grid item md={12} className={classes.emailContainer}>
                        <Typography
                          className={classes.titleColor}
                          ariant="body2"
                        >
                          {translate('Common:email')}
                        </Typography>
                        <Typography className={classes.textDetails}>
                          {request.client.email}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item className={classes.emailContainer}>
                        <Typography className={classes.noContacts}>
                          {translate('Common:noEmailRecorded')}
                        </Typography>
                      </Grid>
                    )}
                    {request.client && request.client.phoneNumber && (
                      <Grid item md={12}>
                        <Typography
                          className={classes.titleColor}
                          ariant="body2"
                        >
                          {translate('Common:phoneNumber')}
                        </Typography>
                        <Typography className={classes.textDetails}>
                          {request.client.phoneNumber}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default withStyles(RequestClientDetailsStyle)(RequestClientDetailsCard);

export default (theme) => ({
  chip:{
    borderRadius: 4,
    fontWeight: 600,
    paddingLeft: 10,
    paddingRight: 10,
  },
  reviewChip:{
    backgroundColor: theme.palette.chip.inReview.wm_bg,
    color: theme.palette.chip.inReview.wm_color,
  },
  cardChip: {
    marginLeft: 6,
  },
  tableChip: {
    margin: '13px 0 10px 0',
    minWidth: '90px',
    padding: 0,
    fontSize: '12px',
  },
});
import React from "react";

// Externals
import classNames from "classnames";

// Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

// Material icons
import { Close as CloseIcon } from "@material-ui/icons";

// Component styles
import { modalStyles } from "./styles";

// Custom Components
import { ProductServiceForm } from "../forms";
import validate from "../utils/Validate";

const mapStateToProps = (state) => ({
  initialValues: {
    name: state.product.product.name || state.product.service.name || null,
    typeId:
      state.product.product.typeId || state.product.service.typeId || null,
    description:
      state.product.product.description ||
      state.product.service.description ||
      null,
    unitPrice:
      state.product.product.unitPrice ||
      state.product.service.unitPrice ||
      "0.00",
    taxRateId:
      (state.product.product.taxRate && state.product.product.taxRate.id) ||
      (state.product.service.taxRate && state.product.service.taxRate.id) ||
      null,
    taxComponentFk:
      (state.product.product.taxComponent &&
        state.product.product.taxComponent.pk) ||
      (state.product.service.taxComponent &&
        state.product.service.taxComponent.pk) ||
      null,
    currency:
      state.product.product.currency || state.product.service.currency || null,
    sortOrder:
      state.product.product.sortOrder ||
      state.product.service.sortOrder ||
      null,
    model: state.product.product.model || state.product.service.model || null,
  },
  currency: state.auth.currentUser.companies[0].currency,
  objectId: state.product.product.id || state.product.service.id,
  currentCompany: state.auth.currentUser.companies[0],
});

function ProductServiceModal(props) {
  const {
    classes,
    currency,
    open,
    handleClose,
    handleSubmit,
    handleDelete,
    pristine,
    invalid,
    submitting,
    taxRates,
    taxComponents,
    translate,
    currentCompany,
    title = "",
    custom = false,
    serviceModal = false,
  } = props;

  let companyTag = currentCompany.tag || "";
  companyTag = companyTag.replace(/(\r\n|\n|\r)/gm, "").trim();

  return (
    <Dialog
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle
        disableTypography
        className={classNames(classes.root, classes.modalTitle)}
      >
        {title && (
          <Typography variant="h5" className={classes.titleText}>
            {translate(title)}
          </Typography>
        )}
        {open ? (
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <ProductServiceForm
            custom={custom}
            taxRates={taxRates}
            taxComponents={taxComponents}
            currency={currency}
            translate={translate}
            serviceModal={serviceModal}
          />
        </DialogContent>
        <DialogActions disableSpacing className={classes.modalFooter}>
          {props.objectId && (
            <Button
              className={classes.deleteButton}
              variant="contained"
              onClick={handleDelete}
            >
              {translate("Common:delete")}
            </Button>
          )}
          <Button onClick={handleClose} color="secondary">
            {translate("Common:cancel")}
          </Button>
          <Button
            type="submit"
            className={classes.submitButton}
            color="primary"
            variant="contained"
            disabled={pristine || invalid || submitting}
          >
            {translate("Common:save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(modalStyles)
)(reduxForm({ form: "productDetails", validate })(ProductServiceModal));

const heightTopbar = '62px';

export default theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    textAlign: 'center',
    position: 'relative'
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '554px'
  },
  workerDetailsSection: {
    height: `calc(100vh - ${heightTopbar})`,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: '5px 20px',
    paddingBottom: 60,
    position: 'relative',
  },
  rowDetails: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '8px',
  },
  workerAvatar: {
    minWidth: 80,
    minHeight: 80,
    maxWidth: 120,
    maxHeight: 120,
    '& .MuiAvatar-root' : {
      width: '100%',
      height: '100%'
    }
  },
  statusButtonWrap: {
    flexGrow: 1,
    textAlign: 'center',
    marginRight: '-20px',
  },
  statusButton: {
    fontSize: 16,
    padding: '2px 20px',
    marginTop: 50,
    backgroundColor: theme.palette.primary.main,

    '& .MuiButton-label': {
      textTransform: 'uppercase',
      color:  theme.palette.common.white,
    }
  },
  textField: {
    width: '100%',
    minHeight: 35,
    '-webkit-box-shadow': '0px 0px 5px rgba(53, 53, 53, 0.2)',
    '-moz-box-shadow':    '0px 0px 5px rgba(53, 53, 53, 0.2)',
    boxShadow:            '0px 0px 5px rgba(53, 53, 53, 0.2)',

    borderRadius: '5px',
    padding: '5px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiSvgIcon-root': {
      height: '100%',
      paddingRight: 5,
      color: theme.palette.calendar.blue
    }
  },
  valueIcon: {
    marginRight: 15,
  },
  text: {
    color: theme.palette.calendar.blue
  },
  value: {
    fontSize: '16px',
  },
  label: {
    paddingBottom: 5,
    fontSize: '13px',
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
  footerAction: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: -1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  actionBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.calendar.blue,
    textTransform: 'uppercase',
    flexGrow: 1,
    borderRadius: 0,
    height: 50,
    borderLeft: `1px solid ${theme.palette.common.white}`,
    width: '33.33%',
    lineHeight: '18px',
    fontSize: '13px',
    textAlign: 'left',
    '& .MuiButton-label': {
      textTransform: 'uppercase',
      color:  theme.palette.common.white,
    },

    '&:hover': {
      backgroundColor: theme.palette.background.default,

      '& .MuiButton-label': {
        color:  theme.palette.calendar.blue,
      }
    }
  },
  disabledBtn: {
    backgroundColor: theme.palette.common.muted,
    color: theme.palette.text.disabled,
  },
  pointer: {
    cursor: 'pointer'
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: theme.palette.calendar.blue,
    fontSize: '16px',
    borderTop: `1px solid ${theme.palette.border}`,
  },
  jobTimeline: {

  },
  jobAddress: {
    wordBreak: 'break-word',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  nextJobList: {
    maxWidth: 400
  },
  detailsWrap: {
    overflow: 'scroll',
    height: '100%'
  }
});

export default theme => ({
  root: {},
  teamDialogTitle: {
    minWidth: 300,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  teamDialogTitleText: {},
  teamCheckbox: {
    color: theme.palette.primary.main,
    // '&$checked': {
    //   color: theme.palette.primary.main
    // }
  },
  teamDialogAction: {
    justifyContent: 'space-between',
  },
  noneAssignText: {
    fontWeight: 400,
    marginBottom: 30,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
      color: theme.palette.danger.contrastText,
      borderColor: theme.palette.danger.main,
      fontWeight: 550,
    }
  },
});
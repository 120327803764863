import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import CalendarSrc from 'assets/images/enterprise/calendar.png';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import { StatusChip } from 'pages/Dashboard/components';
import './styles.scss';
import moment from 'moment-timezone';
import { getDateTimeFormat } from 'lib/formatter';

const CurrentVisit = ({ visit }) => {
  const { t } = useTranslation('Dashboard');
  if (!visit) return null;
  return (
    <Box
      p={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className="currentVisitWrapper"
    >
      <Box display="flex">
        <img src={CalendarSrc} alt="calendarSrc" />
        <Box ml={2} display="flex" flexDirection="column">
          <Typography variant="h5">{t('currentVisit')}</Typography>
          <Typography>
            {moment(visit?.start).format(getDateTimeFormat())}
          </Typography>
          <Typography variant="subtitle1" className="subtitle">
            ID: {visit?.publicId}
          </Typography>
        </Box>
      </Box>
      <Box>
        <StatusChip statusFor='job' status={visit?.statusId} />
        <Tooltip className="ml_5" title={t('currentVisitToolTip')}>
          <InfoIcon />
        </Tooltip>
      </Box>
    </Box>
  );
};
export default CurrentVisit;
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Card, Box, Grid, Typography, Button } from '@material-ui/core';
import { reduxForm, Field, Fields } from 'redux-form';
import GoogleMapReact from 'google-map-react';

import { showSuccessMessage } from 'lib/notifier';
import styles from '../../styles.module.scss';
import copyIcon from 'assets/images/icons/fi_copy.svg';
import Countries from 'lib/countryList';

const propertyMarker = require('assets/images/marker/map-marker.svg');
const details = require('assets/images/icons/blue_details.svg');

const __process = (typeof global !== 'undefined' ? global : window).process;

const countryList = new Countries();

const FORM_NAME = 'warehouseDetails';

const renderView = ({ title, input, noBorder }) => {
  if (!input.value) return null;
  return (
    <Grid
      container
      className={
        noBorder ? styles.field_container_no_order : styles.field_container
      }
    >
      <Grid item md={4} sm={3}>
        <Typography className={styles.label}>{title}</Typography>
      </Grid>
      <Grid item md={8} sm={9} className="pl_5">
        <Typography>{input.value}</Typography>
      </Grid>
    </Grid>
  );
};

const renderLocality = (props) => {
  const { city, postalCode, title } = props;
  if (!city.input.value || !postalCode.input.value) return null;
  return (
    <Grid container className={styles.field_container}>
      <Grid item md={4} sm={3}>
        <Typography className={styles.label}>{title}</Typography>
      </Grid>
      <Grid container item md={8} sm={9} className="pl_5">
        {city.input.value && <Typography>{city.input.value}</Typography>}
        {postalCode.input.value && (
          <Typography>, {postalCode.input.value}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

const renderMap = (props) => {
  const { lat, lng, title, translate } = props;

  if (!lat.input.value || !lng.input.value) return null;

  const handleClick = () =>
    window.open(
      `https://maps.google.com/?q=${lat.input.value},${lng.input.value}`,
      '_blank'
    );

  return (
    <Grid container className={styles.field_container}>
      <Grid item md={12} sm={12}>
        <Typography className={styles.label}>{title}</Typography>
      </Grid>
      <Grid item container md={12} sm={12} className={styles.map_wrapper}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: __process.env.REACT_APP_GG_MAP_API_KEY,
            language: 'en',
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={{
            lat: parseFloat(lat.input.value),
            lng: parseFloat(lng.input.value),
          }}
          defaultZoom={16}
        >
          <img
            src={propertyMarker}
            alt="propertyMarker"
            lat={parseFloat(lat.input.value)}
            lng={parseFloat(lng.input.value)}
          />
        </GoogleMapReact>

        <Grid item>
          <Grid
            item
            container
            className={`mt_10 ${styles.cursor}`}
            onClick={handleClick}
          >
            <Typography>{translate('openMap')}</Typography>
            <Typography className={styles.map_text}>
              {translate('googleMap')}
            </Typography>
            <img className="ml_5" src={details} alt="details" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const renderCopyField = ({ input, title, translate }) => {
  if (!input.value) return null;

  const handleCopy = () => {
    navigator.clipboard.writeText(input.value);
    showSuccessMessage(translate('Common:textCopied'));
  };
  return (
    <Grid container className={styles.field_container}>
      <Grid item md={4} sm={3}>
        <Typography className={styles.label}>{title}</Typography>
      </Grid>
      <Grid item container md={8} sm={9} className="pl_5">
        <Grid item md={9}>
          <Typography>{input.value}</Typography>
        </Grid>
        <Button
          onClick={handleCopy}
          variant="text"
          style={{ color: '#0075FE' }}
        >
          <img src={copyIcon} alt="copyIcon" />
        </Button>
      </Grid>
    </Grid>
  );
};

const Form = (props) => {
  const { translate } = props;
  return (
    <Card className="mt_10">
      <Box p={3}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item md={6} sm={12}>
            <Field
              name="name"
              component={renderView}
              noBorder
              title={translate('name')}
            />
            <Field
              name="address"
              translate={translate}
              component={renderCopyField}
              title={translate('address')}
            />
            <Field
              name="unitNumber"
              component={renderView}
              title={translate('unitNumber')}
            />
            <Field
              name="city"
              component={renderView}
              title={translate('city')}
            />
            <Field
              name="postalCode"
              component={renderView}
              title={translate('postalCode')}
            />
            <Field
              name="state"
              component={renderView}
              title={translate('state')}
            />
            <Field
              name="country"
              component={renderView}
              title={translate('country')}
            />
            <Field
              name="lat"
              component={renderCopyField}
              translate={translate}
              title={translate('lat')}
            />
            <Field
              name="lng"
              component={renderCopyField}
              translate={translate}
              title={translate('lng')}
            />

            <Fields
              names={['city', 'postalCode']}
              component={renderLocality}
              title={translate('locality')}
            />

            <Fields
              names={['lng', 'lat']}
              component={renderMap}
              translate={translate}
              title={translate('map')}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Field
              name="note"
              noBorder
              component={renderView}
              title={translate('note')}
            />
            <Field
              name="description"
              component={renderView}
              title={translate('description')}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { warehouseDetails } = ownProps;
  return {
    initialValues: {
      name: warehouseDetails.name,
      address: warehouseDetails.address,
      unitNumber: warehouseDetails.unitNumber,
      city: warehouseDetails.city,
      postalCode: warehouseDetails.zipCode,
      state: warehouseDetails.state,
      country: countryList.countryName(warehouseDetails.countryCode),
      lat: warehouseDetails.lat,
      lng: warehouseDetails.lng,
      note: warehouseDetails.note,
      description: warehouseDetails.description,
    },
  };
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })
)(Form);

import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import { getDateTimeFormat } from 'lib/formatter';

const JobDetailsWrapper = ({ children }) => <>{children}</>;

const mapStateToProps = (state, ownProps) => {
  const { t, jobDetails = {} } = ownProps;
  const {
    client = {},
    jobSchedule = {},
    property = {},
    visits = [],
    statusDetail = {},
  } = jobDetails;

  const currentVisitDetails = visits.find(
    (visit) => visit.id === statusDetail.currentVisitId
  );

  return {
    initialValues: {
      visitTime:
        currentVisitDetails &&
        currentVisitDetails.start &&
        moment(currentVisitDetails.start).format(getDateTimeFormat()),
      visitPublicId: currentVisitDetails && currentVisitDetails.publicId,
      title: jobDetails.title,
      jobId: jobDetails.publicId,
      startOn:
        jobSchedule &&
        jobSchedule.start &&
        moment(jobSchedule.start).format(getDateTimeFormat()),
      endOn:
        jobSchedule &&
        jobSchedule.end &&
        moment(jobSchedule.end).format(getDateTimeFormat()),
      clientName: client && client.displayName,
      property,
      clientEmail: client && client.email,
      clientPhoneNumber: client && client.phoneNumber,
      isCollected: jobDetails.isCollected ? t('Job:collected') : t('Job:notCollected')
    },
    jobId: jobDetails.publicId,
  };
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'enterpriseJobDetails',
    enableReinitialize: true,
  })
)(JobDetailsWrapper);

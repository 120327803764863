
import { useMemo } from 'react';

export const useCategoryChecked = (selectedCategoryList, item) => {
  const isChildrenCategoryChecked = (parentCategory) => {
    const { childrenCategories } = parentCategory;
    if (!childrenCategories?.length) {
      return false;
    }
    const totalChildrenCategories = childrenCategories.length;
    const totalSelectCategory = Object.keys(selectedCategoryList).reduce((accumulator ,curValue) => {
      const category = selectedCategoryList[curValue];
      if (category.checked && category.parentCategoryId === parentCategory.id) {
        accumulator += 1;
      };
      return accumulator;
    }, 0);
    return totalSelectCategory === totalChildrenCategories;
  };
  const handleAllChildrenCategory = (callback) => {
    if (item.childrenCategories?.length) {
      const checkChildren = callback(item);
      if (checkChildren) {
        const { childrenCategories } = item;
        return childrenCategories.reduce((accumulator ,childCategory) => {
          if (childCategory.childrenCategories?.length) {
            const checkSubChildren = callback(childCategory);
            if (!checkSubChildren) {
              accumulator = false;
            }
          }
          return accumulator;
        }, true);
      }
      return checkChildren;
    }
  };
  const isChildrenCategoryUnChecked = (parentCategory) => {
    return Object.keys(selectedCategoryList).reduce((accumulator ,curValue) => {
      const category = selectedCategoryList[curValue];
      if (category.checked && category.parentCategoryId === parentCategory.id) {
        accumulator = false;
      };
      return accumulator;
    }, true);
  };
  const isAllChildrenCategoryChecked = useMemo(() => {
    return handleAllChildrenCategory(isChildrenCategoryChecked);
  }, [selectedCategoryList]);
  const isAllChildrenCategoryUnChecked = useMemo(() => {
    return handleAllChildrenCategory(isChildrenCategoryUnChecked);
  }, [selectedCategoryList]);

  return [
    isAllChildrenCategoryChecked,
    isAllChildrenCategoryUnChecked
  ];
};
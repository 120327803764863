import React, { Component } from 'react';

// Redux
import { Field, FieldArray } from 'redux-form';

// Material components
import { Button, Grid, Divider, IconButton } from '@material-ui/core';
import {
  DeleteRounded as DeleteIcon,
  Add
} from '@material-ui/icons';

// shared components
import { Checkbox } from 'components/inputs';
import { BillingAddressForm, PropertyAddressForm } from 'pages/Dashboard/components';

class PropertyAddressesForm extends Component {


  showBillingAddressForm() {
    const { initBillingAddress } = this.props;
    initBillingAddress();
  }

  hideBillingAddressForm() {
    this.props.removeBillingAddress();
  }

  handleSelectLocation(index, property, location) {
    const newProperty = {
      address: location.address,
      unitNumber: location.unitNumber,
      city: location.city,
      zipCode: location.postalCode,
      state: location.state,
      countryCode: location.countryCode,
      lat: location.lat,
      lng: location.lng,
      placeId: location.placeId,
    };
    property = Object.assign(property, newProperty);
    this.props.updateClientProperties(index, property);
  }

  handleSelectBillingAddress(location) {
    const billingAddressData = {
      address: location.address,
      unitNumber: location.unitNumber,
      city: location.city,
      zipCode: location.postalCode,
      state: location.state,
      countryCode: location.countryCode,
    };
    this.props.updateBillingAddress(billingAddressData);
  }

  render() {
    const { classes, translate, initPropertyAddress, clientBillingAddress } = this.props;
    return (
      <div className={classes.section}>
        <Grid container direction='row'>
          <FieldArray
            name='properties'
            classes={classes}
            component={renderPropertyAddress}
            showBillingAddressForm={() => this.showBillingAddressForm()}
            hideBillingAddressForm={() => this.hideBillingAddressForm()}
            translate={translate}
            initPropertyAddress={initPropertyAddress}
            handleSelectLocation={
              (index, currentProperty, location) => this.handleSelectLocation(index, currentProperty, location)}
          />

          <Divider />

          { clientBillingAddress &&
          <Grid container direction='row' item xs={12} md={12} className="billing-address-section">
            <p className={classes.billingAddressTitle}>{translate('Common:billingAddress')}</p>
            <Grid item xs={12} md={12} className={classes.addressFormBG}>
              <BillingAddressForm
                nameField='billingAddress'
                translate={translate}
                handleSelectBillingAddress={location => this.handleSelectBillingAddress(location)}
              />
            </Grid>
          </Grid>}

        </Grid>

      </div>
    );
  }
}

export default PropertyAddressesForm;


// This component is about rendering for Property Address
const renderPropertyAddress = (props) => {
  const {
    fields, classes, initPropertyAddress, showBillingAddressForm, hideBillingAddressForm,
    handleSelectLocation, translate
  } = props;


  function addPropertyAddress(event) {
    event.preventDefault();
    initPropertyAddress();
  }

  function handleDeleteProperty(event, index) {
    event.preventDefault();
    // If the billing address is being same this property,
    // we need show billing address after delete this property
    if ( fields.get(index).isBillingAddress) {
      showBillingAddressForm();
    }

    // Remove property
    fields.remove(index);
  }

  function toggleBillingAddressIsSameAsProperty(event, id) {
    // Uncheck another property's checkbox
    fields.forEach((property, index) => {
      if (index !== id) {
        fields.get(index).isBillingAddress = false;
      }
    });

    // Show or hide billing address form base on checkbox status
    if (event.target.checked) {
      hideBillingAddressForm();
    } else {
      showBillingAddressForm();
    }
  }

  // To show marker of property on map, we need get lat, lng from property
  function getLocationFromProperty(property) {
    if (!property) return [];

    return [{
      location: {
        lat: property.lat ? parseFloat(property.lat) : null,
        lng: property.lng ? parseFloat(property.lng) : null,
      },
      contentInfoWindow: property.address,
    }];
  }

  return (
    <>
      { fields.map((field, index) => {
        return (
          <div key={index} className={classes.propertyWrap}>
            <PropertyAddressForm
              nameField={field}
              translate={translate}
              placeId={fields.get(index).placeId}
              markers={getLocationFromProperty(fields.get(index))}
              handleSelectLocation={location => handleSelectLocation(index, fields.get(index), location)}
            />

            <Field
              component={Checkbox}
              label={translate('Common:billingAddressSamePropertyAddress')}
              name={`${field}.isBillingAddress`}
              onChange={e => toggleBillingAddressIsSameAsProperty(e, index)}
            />

            <div className={classes.deleteButton}>
              {
                fields.length > 1 &&
                <IconButton aria-label='Delete' onClick={event => handleDeleteProperty(event, index)}>
                  <DeleteIcon style={{ width: 30, height: 30, color: 'red' }} />
                </IconButton>
              }
            </div>
          </div>
        );
      })}

      <Grid container direction='row' item xs={12} md={12}>

        <Button
          className={classes.addBtn}
          onClick={(e) => addPropertyAddress(e)}
        >
          <Add />
          {translate('Client:addPropertyAddress')}
        </Button>
      </Grid>

    </>
  );
};

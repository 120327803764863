import { GET, PATCH, POST, DELETE } from 'lib/api';

export function getTimesheet(options = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    const params = { params: options };

    return GET('/timesheets/clocks', accessToken, params).then((response) => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };

}

export function getTimesheetVisit(options = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    const params = { params: options };

    return GET('/timesheets/visits', accessToken, params).then((response) => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function updateClockInOut(id, form) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(`/timesheets/clocks/${id}`, accessToken, { data: form }).then((response) => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function createClockInOut(form) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/timesheets/clocks', accessToken, { data: form }).then((response) => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function deleteClockInOut(id, options = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return DELETE(`/timesheets/clocks/${id}`, accessToken, { params: options }).then((response) => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function deleteVisits(id, options = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return DELETE(`/timesheets/visits/${id}`, accessToken, { params: options }).then((response) => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function editTimesheetVisit(id, options) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(`/timesheets/visits/${id}`, accessToken, { data: options }).then((response) => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function createTimesheetVisit(options) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/timesheets/visits', accessToken, { data: options }).then((response) => {
      return Promise.resolve(response.data);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}
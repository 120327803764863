import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  Grid
} from '@material-ui/core';
import {
  Portlet,
  PortletContent,
  StatusChip,
} from 'pages/Dashboard/components';
import moment from 'moment';

function VisitList(props) {
  const { t, jobDetails } = props;
  const { visits } = jobDetails;
  return (
    <>
      <Portlet>
        <PortletContent noPadding>
          <Paper>
            <Table className="list-visit">
              <TableHead>
                <TableRow>
                  <TableCell align="left">#</TableCell>
                  <TableCell align="left">{t('id')}</TableCell>
                  <TableCell align="left">{t('title')}</TableCell>
                  <TableCell align="left">{t('description')}</TableCell>
                  <TableCell align="left">{t('startAt')}</TableCell>
                  <TableCell align="left">{t('endAt')}</TableCell>
                  <TableCell align="left">{t('assignedTo')}</TableCell>
                  <TableCell align="left">{t('status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visits &&
                    visits.map((row, index) => {
                      const teamMember =
                        (Array.isArray(row.team) &&
                          row.team.map((member) => member.fullName)) ||
                        [];
                      return (
                        <TableRow
                          key={index}
                          className="visit-row"
                        >
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">{row.publicId}</TableCell>
                          <TableCell align="left">{row.title}</TableCell>
                          <TableCell align="left">
                            <Tooltip title={row.description}>
                              <Typography>{row.description}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="left">
                            {row.start
                              ? moment(row.start).format(
                                t('Common:dateTimeFormat')
                              )
                              : ''}
                          </TableCell>
                          <TableCell align="left">
                            {row.end
                              ? moment(row.end).format(
                                t('Common:dateTimeFormat')
                              )
                              : ''}
                          </TableCell>
                          <TableCell align="left">
                            <Grid container alignItems="center">
                              <Typography>
                                {teamMember.splice(0, 1).join(' ')}
                              </Typography>
                              {teamMember.length > 0 && (
                                <Tooltip title={teamMember.join(', ')}>
                                  <Typography className="team-count">+{teamMember.length}</Typography>
                                </Tooltip>
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <StatusChip
                              statusFor='job'
                              status={row.statusId}
                              archived={row.archived}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </Paper>
        </PortletContent>
      </Portlet>
    </>
  );
}

export default VisitList;
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { submitNewEnterpriseAccount } from 'redux/company';

import { Grid, Typography, Button, Card } from '@material-ui/core';
import { EnterpriseForm } from '../components';
import { useTranslation } from 'react-i18next';
import styles from './new.module.scss';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';

const FORM_NAME = 'enterpriseForm';

const NewEnterpriseAccount = (props) => {
  const { history } = props;
  const { t } = useTranslation('Enterprise');
  const dispatch = useDispatch();
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const handleClickBack = () => history.goBack();

  const handleSubmit = async (values) => {
    const companyData = {
      name: values.registeredName,
      tradingName: values.tradingName,
      address: values.address,
      addressLine2: values.address_line_2,
      city: values.city,
      state: values.state,
      zipCode: values.postalCode,
      countryCode: values.country,
      categoryIds: values.currentCategories && values.currentCategories.map(category => category.id),
      active: values.accountStatus
    };
    const formData = new FormData();
    formData.append('companyData', JSON.stringify(companyData));
    if (values.sourceFile) formData.append('file', values.sourceFile[0]);

    const result = await dispatch(
      submitNewEnterpriseAccount(formData)
    ).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    history.push(`/enterprise/${result.data.id}`);
    showSuccessMessage(t('enterpriseCreatedSuccessfully'));
  };

  const handleRemoteSubmit = () => dispatch(submit(FORM_NAME));

  return (
    <Grid style={{ padding: 24 }}>
      {/* header */}
      <Grid container justify="space-between" direction="row">
        <Grid className={styles.headerWrapper} item>
          <Typography>{t('backTo')}</Typography>
          <Button onClick={handleClickBack} variant="text">
            {t('enterpriseAccounts')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!isReadyToSubmit}
            onClick={handleRemoteSubmit}
            variant="contained"
            className={
              isReadyToSubmit ? styles.btnSave : styles.btnSaveDisabled
            }
          >
            {t('Common:save')}
          </Button>
        </Grid>
      </Grid>

      {/* body */}
      <Card className={styles.enterpriseInformation}>
        <Grid className={styles.titleWrapper}>
          <Typography className={styles.title}>
            {t('enterpriseInformation')}
          </Typography>
        </Grid>
        <Grid>
          <EnterpriseForm
            t={t}
            onSubmit={handleSubmit}
            setIsReadyToSubmit={setIsReadyToSubmit}
          />
        </Grid>
      </Card>
    </Grid>
  );
};

export default NewEnterpriseAccount;

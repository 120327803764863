export default (theme) => ({
  root: {
    padding: theme.spacing(2, 4),
  },
  header: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
  },
  body: {
    padding: theme.spacing(2),
  },
  tabpannel: {
    padding: '12px 0px 0px 0px',
  },
  borderNone: {
    '& div': {
      border: 'none',
    }
  },
  indicator: {
    backgroundColor: theme.palette.common.primary_yellow,
  },
  bottom_border: {
    borderBottom: '1px solid #F2F2F2'
  },
  card: {
    overflow: 'unset'
  }
});

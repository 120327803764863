import React, { useState } from 'react';

import {
  Button,
  Grid,
  Typography,
  ListItem,
  ListItemText,
  List,
} from '@material-ui/core';
// Material icons
import {
  EditOutlined as EditOutlinedIcon,
  Add as AddIcon,
} from '@material-ui/icons';

import { CategoryModal } from 'pages/Dashboard/components';
import styles from './category.module.scss';

const CategorySelect = ({ t, input, enterpriseAccountId, isShorterContainer = false }) => {
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  const handleCloseSelectCategoryModal = (_e) => {
    setOpenCategoryModal(false);
  };

  const handleOpenSelectCategoryModal = () => setOpenCategoryModal(true);

  const handleCategorySelection = (data) => {
    input.onChange(data);
    handleCloseSelectCategoryModal();
  };

  return (
    <>
      {openCategoryModal && (
        <CategoryModal
          open={openCategoryModal}
          handleCloseSelectCategoryModal={handleCloseSelectCategoryModal}
          translate={t}
          categories={input.value}
          handleCategorySelection={handleCategorySelection}
          enterpriseAccountId={enterpriseAccountId}
        />
      )}
      <Grid item container alignItems="center" justify="space-between">
        <Typography variant="h5">{t('Job:jobCategory')}</Typography>
        <Button
          color="primary"
          size="small"
          onClick={handleOpenSelectCategoryModal}
        >
          {Array.isArray(input.value) && input.value.length ? (
            <>
              <EditOutlinedIcon fontSize="small" className={styles.addBtn} />
              <Typography className={styles.addBtn}>
                {t('Common:edit')}
              </Typography>
            </>
          ) : (
            <>
              <AddIcon fontSize="small" className={styles.addBtn} />
              <Typography className={styles.addBtn}>
                {t('Common:add')}
              </Typography>
            </>
          )}
        </Button>
      </Grid>

      {/* Category list */}
      {Array.isArray(input.value) && input.value.length > 0 ? (
        <Grid
          item
          container
          className={`${styles.categoryTag} ${
            isShorterContainer && styles.shorterWrapper
          }`}
        >
          <List dense style={{ width: '100%' }}>
            {input.value.map((category) => {
              return (
                <ListItem key={category.id}>
                  <ListItemText primary={category.name} />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          justify="center"
          className={`${styles.noCategory} ${
            isShorterContainer && styles.shorterWrapper
          }`}
        >
          <Typography>{t('Category:noCategoryAdded')}</Typography>
        </Grid>
      )}
    </>
  );
};

export default CategorySelect;

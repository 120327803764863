import React from 'react';

// Material components
import { Modal, DialogContent, withStyles } from '@material-ui/core';

// shared form
import { VisitForm } from 'pages/Dashboard/components';
// Component styles
import { VisitDetailsFormStyle } from './styles';

const VisitDetailsForm = (props) => {
  const {
    classes,
    showVisitDetailsForm,
    handleCloseVisitForm,
    afterSaveVisitDetails,
    translate,
    visitDetails,
  } = props;

  return (
    <Modal disablePortal open={showVisitDetailsForm}>
      <DialogContent className={classes.dialogContent}>
        <VisitForm
          visitDetails={visitDetails}
          afterSavedVisit={afterSaveVisitDetails}
          handleCloseVisitForm={handleCloseVisitForm}
          translate={translate}
        />
      </DialogContent>
    </Modal>
  );
};

export default withStyles(VisitDetailsFormStyle)(VisitDetailsForm);

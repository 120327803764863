import React from 'react';
import { Popper, Card, Box, Typography, Grid } from '@material-ui/core';
import AvatarSrc from 'assets/images/icons/user.svg';

import styles from '../components.module.scss';
import { useTeamStatus } from 'pages/Dashboard/helpers/getTeamStatus';

const TeamPopover = ({ anchorEl, input }) => {
  const id = anchorEl ? 'transitions-popper' : undefined;
  const [getStatus] = useTeamStatus();
  return (
    <Popper
      placement="right"
      disablePortal={false}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
      }}
      style={{ zIndex: 1400 }}
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
    >
      <Card className={styles.card}>
        {input.value.map((user, index) => (
          <Box
            key={index}
            mb={index === input.value.length - 1 ? 0 : 1}
            px={1}
            py={2}
            className={styles.team_card_popover}
            display="flex"
          >
            <img className={styles.user_icon} src={AvatarSrc} alt="AvatarSrc" />
            <Grid>
              <Typography variant="h6">{user.fullName} {getStatus(user.isDeleted, user.isArchived)}</Typography>
              <Typography>{user.email}</Typography>
              <Typography>
                {[user.countryPhoneCode, user.phoneNumber]
                  .filter(Boolean)
                  .join('')}
              </Typography>
            </Grid>
          </Box>
        ))}
      </Card>
    </Popper>
  );
};

export default TeamPopover;

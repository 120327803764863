import { GET, DELETE, PATCH, POST } from 'lib/api';

// Actions
const SET_MEDIA_DETAILS = 'SET_MEDIA_DETAILS';
const SET_MEDIA_IS_SELECTING = 'SET_MEDIA_IS_SELECTING';
const SELECT_MEDIA_ITEM = 'SELECT_MEDIA_ITEM';
const SET_MEDIA_ITEMS = 'SET_MEDIA_ITEMS';
const SET_IS_SHOW_CONFIRM_MODAL = 'SET_IS_SHOW_CONFIRM_MODAL';
const SET_VISIT_SELECTED = 'SET_VISIT_SELECTED';

const initialState = {
  data: null,
  isMultiSelect: false,
  mediaItems: [],
  isShowConfirmModal: false,
  visitSelected: null
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MEDIA_DETAILS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      };
    case SET_MEDIA_IS_SELECTING:
      return {
        ...state,
        isMultiSelect: action.isMultiSelect
      };
    case SELECT_MEDIA_ITEM:
      return {
        ...state,
        mediaItems: state.mediaItems?.map(item => {
          if (item.id === action.itemId) {
            item.isSelected = !item.isSelected;
          }
          return item;
        })
      };
    case SET_MEDIA_ITEMS:
    {
      return {
        ...state,
        mediaItems: action.items
      };
    }
    case SET_IS_SHOW_CONFIRM_MODAL:
    {
      return {
        ...state,
        isShowConfirmModal: action.isShowConfirmModal
      };
    }
    case SET_VISIT_SELECTED:
    {
      return {
        ...state,
        visitSelected: action.visitSelected
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function getMedia(id) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`media/${id}`, accessToken)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function getMediaFiles(data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET('/media', accessToken, { params: data })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function deleteDetails(data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return DELETE('media', accessToken, { params: data })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function updateMediaDetails(id, data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(`media/${id}`, accessToken, { data })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function mediaActions(data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/media-actions', accessToken, { data })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function mediaActionsAdmin(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await POST(
      '/media-actions',
      accessToken,
      data
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function setMediaSelectMultiple(isMultiSelect) {
  return { type: SET_MEDIA_IS_SELECTING, isMultiSelect };
}

export function setMediaList(items) {
  return { type: SET_MEDIA_ITEMS, items };
}

export function changeStatusSelectedItems(itemId) {
  return { type: SELECT_MEDIA_ITEM, itemId };
}

export function changeConfirmModal(isShowConfirmModal) {
  return { type: SET_IS_SHOW_CONFIRM_MODAL, isShowConfirmModal };
}

export function setVisitSelected(visitSelected) {
  return { type: SET_VISIT_SELECTED, visitSelected };
}


// Selector
export function getMediaSelect(state) {
  return state.media.isMultiSelect;
}

export function getMediaItems(state) {
  return state.media.mediaItems;
}

export function getMediaSelected(state) {
  return state.media.mediaItems?.filter((media) => media.isSelected);
}

export function getMediaSelectedCount(state) {
  return state.media.mediaItems?.reduce((count ,item) => (item.isSelected ? count + 1 : count ), 0);
}

export function getMediaSelectedId(state) {
  return getMediaSelected(state).map((media => media.id));
}

export function getIsShowConfirmModal(state) {
  return state.media.isShowConfirmModal;
}

export function getVisitSelected(state) {
  return state.media.visitSelected;
}

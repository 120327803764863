import React, { useEffect } from 'react';

// Externals
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Redux
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { initialize, SubmissionError } from 'redux-form';
import { clearRequest } from 'redux/request';
import { addEnterpriseRequest } from 'redux/enterprise';
import { getQuote } from 'redux/quote';
import { showErrorMessage } from 'lib/notifier';
// Material helpers
// Material components
import { Grid, withStyles } from '@material-ui/core';

// Custom components
import { RequestForm } from '../components/forms';
import { RequestValidation, modifyRequestDataBeforeSubmission,
  initializeRequestData } from '../components/utils';
// Component styles
import styles from './style';

const NewRequest = props => {
  const { classes, history, translate, quoteId } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (quoteId) {
      dispatch(getQuote(quoteId)).then(
        rs => dispatch(initialize('requestForm', initializeRequestData(rs.data)))
      );
    }
    // when unmount, clear request
    return () => dispatch(clearRequest());
  }, []);

  function handleCancelRequestForm() {
    dispatch(clearRequest());
    history.push('/enterprise-requests');
  };

  function submitRequestForm(values) {
    const errors = RequestValidation(values);
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));
      throw new SubmissionError(errors);
    } else {
      values = modifyRequestDataBeforeSubmission(values);
      if (quoteId) {
        values.quoteId = quoteId;
      }
      dispatch(addEnterpriseRequest(values)).then(response => {
        if (response.status === 200) {
          history.push(`/enterprise-requests/${response.data.publicId}`);
        }
      }, error => {
        showErrorMessage(error);
      });
    }
  }

  return (
    <div className={classes.pageRoot}>
      <Grid container spacing={4} justify='center'>
        {/* <Grid item xs={12}>
          <RequestsToolbar translate={translate} />
        </Grid> */}
        <Grid item xs={12}>
          <RequestForm
            quoteId={quoteId}
            handleCancelRequestForm={handleCancelRequestForm}
            onSubmit={values => submitRequestForm(values)}
            translate={translate}
          />
        </Grid>
      </Grid>
    </div>
  );
};

NewRequest.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles),
)(NewRequest);

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Dialog, Grid, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  ArrowBack as ArrowBackIcon,
  InfoOutlined as InfoOutlinedIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
} from '@material-ui/icons';
import { ClientDetails, GeneralInfo } from './components/tabs';
import { getEnterpriseJobDetails } from 'redux/dashboard';
import { showErrorMessage } from 'lib/notifier';
import JobDetailsWrapper from './components/utils/jobDetailsWrapper';
import styles from './dialog.module.scss';

const generateTab = ({ t }) => [
  {
    icon: <InfoOutlinedIcon />,
    text: t('generalInfo'),
    tabContent: GeneralInfo,
  },
  {
    icon: <PersonOutlineOutlinedIcon />,
    text: t('clientDetails'),
    tabContent: ClientDetails,
  },
];

const ModalJobDetails = (props) => {
  const { openJobDetails, handleClose, jobId } = props;
  const { t } = useTranslation('Dashboard');
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [jobDetails, setJobDetails] = useState({});
  const generatedTab = generateTab({ t });
  const handleClickItem = (index) => setSelectedTab(index);
  const Comp = generatedTab[selectedTab].tabContent;

  useEffect(() => {
    if (!jobId) return;
    (async () => {
      const response = await dispatch(
        getEnterpriseJobDetails({ jobId })
      ).catch((e) => ({ e }));
      if (response.e) return showErrorMessage(response.e);
      setJobDetails(response);
    })();
  }, [dispatch, getEnterpriseJobDetails, jobId]);

  return (
    <Dialog
      open={openJobDetails}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: styles.dialogClass }}
    >
      <Grid container>
        <Grid item sm={4} md={4} className={styles.sideBar}>
          <Box p={3}>
            <Button onClick={handleClose}>
              <ArrowBackIcon className="mr_5" />
              {t('Common:back')}
            </Button>

            <Box mt={3} display="flex" flexDirection="column">
              <Typography variant="h5" className={styles.jobDetailsTitle}>
                {t('jobDetails')}
              </Typography>

              {generatedTab.map((item, index) => (
                <Grid
                  className={
                    selectedTab === index ? styles.selectedItem : styles.item
                  }
                  onClick={() => handleClickItem(index)}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    p={1}
                    className={styles.cursor}
                  >
                    {item.icon && (
                      <Grid item className="mr_5">
                        {item.icon}
                      </Grid>
                    )}
                    {item.text && <Grid item>{item.text}</Grid>}
                  </Box>
                </Grid>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item sm={8} md={8} className={styles.contentDialog}>
          <Box p={3}>
            <JobDetailsWrapper t={t} {...props} jobDetails={jobDetails}>
              <Comp t={t} {...props} jobPublicId={jobDetails.publicId} isCollected={jobDetails.isCollected} />
            </JobDetailsWrapper>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalJobDetails;

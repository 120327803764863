import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, Field, formValueSelector } from 'redux-form';

// Material components
import {
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';

// Shared components
import { TextInput } from 'components/inputs';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from 'pages/Dashboard/components';
import LineProductArray from './LineProductArray';
import { OnlyDecimal } from 'components/converters';

// Component styles
import ServiceProductCardStyle from './styles/ServiceProductCard';
import { formatPrice } from 'lib/formatter';

const quoteForm = formValueSelector('quoteForm');

const mapStateToProps = (state) => ({
  currencyCode: quoteForm(state, 'currency'),
  discountType: quoteForm(state, 'discountType'),
  discountValue: quoteForm(state, 'discountValue'),
  quoteItems: quoteForm(state, 'items'),
  withholdingTax23: quoteForm(state, 'withholdingTax23'),
  subTotalIncludeTax: quoteForm(state, 'subTotalIncludeTax'),
  totalAfterTax23: quoteForm(state, 'totalAfterTax23'),
  discountTypeOptions: [
    {
      name: '%',
      value: '%'
    },
    {
      name: quoteForm(state, 'currency'),
      value: quoteForm(state, 'currency')
    }
  ],
  depositTypeOptions: [
    {
      name: '%',
      value: '%'
    },
    {
      name: quoteForm(state, 'currency'),
      value: quoteForm(state, 'currency')
    }
  ],
  depositType: quoteForm(state, 'depositType'),
  depositValue: quoteForm(state, 'depositValue'),
});

function ServiceProductCardForIndo(props) {
  const {
    classes, currencyCode, quoteItems, discountType, discountValue,
    discountTypeOptions, depositTypeOptions, translate, depositType,
    depositValue, subTotalIncludeTax, totalAfterTax23, withholdingTax23
  } = props;

  const subtotal = quoteItems.reduce((acc, item) => {
    acc += parseFloat(item.unitPrice) * parseFloat(item.quantity);
    return acc;
  }, 0);

  const discountAmount = discountType === '%'
    ? parseFloat(discountValue) * parseFloat(subtotal) / 100
    : discountValue;

  let taxAmount = quoteItems.reduce((acc, item) => {
    const itemValue = parseFloat(item.unitPrice) * parseFloat(item.quantity);
    const taxPercent = parseFloat((item.taxRate && item.taxRate.percentage) || 0) / 100;
    const afterDiscount = discountType === '%'
      ? itemValue - itemValue * parseFloat(discountValue) / 100
      : itemValue - (itemValue / subtotal) * discountAmount;
    acc += afterDiscount * taxPercent;
    return acc;
  }, 0);
  taxAmount = Math.max(taxAmount, 0);

  const totalAmount = Math.max(subtotal - discountAmount + taxAmount, 0);

  const depositAmount = depositType === '%'
    ? parseFloat(depositValue) * parseFloat(totalAmount) / 100
    : depositValue;

  return (
    <Portlet>
      <PortletHeader className={classes.cardHeader}>
        <PortletLabel title={translate('lineItems')} />
      </PortletHeader>
      <PortletHeader className={classes.secondaryHeader}>
        <Grid container direction='row' spacing={2}>
          <Grid item md={4} sm={12}>
            <PortletLabel title={translate('serviceProduct')} />
          </Grid>
          <Grid item md={2} sm={12}>
            <PortletLabel title={translate('qty')} />
          </Grid>
          <Grid item md={3} sm={12}>
            <PortletLabel title={translate('unitPrice')} />
          </Grid>

          <Grid item md={3} sm={12}>
            <PortletLabel title={translate('totalIncludeTax')} />
          </Grid>
        </Grid>
      </PortletHeader>
      <PortletContent showOverflow>
        <FieldArray
          name='items'
          component={LineProductArray}
          currencyCode={currencyCode}
          translate={translate}
        />

      </PortletContent>
      <PortletFooter className={classes.cardFooter}>
        <Grid container direction='row' spacing={2}>
          <Grid item container md={6} sm={6}>
            <Field
              name='clientMessage'
              component={TextInput}
              margin="none"
              variant="outlined"
              label={translate('clientMessage')}
              className={classes.textField}
              multiline
              rows="5"
            />
          </Grid>

          <Grid item container md={6} sm={6}>


            <Grid container direction='row' className={classes.alignItems}>
              <Grid item md={4} sm={6}>
                <PortletLabel title={translate('discount')} />
              </Grid>

              <Grid item md={3} sm={6}>
                <Field
                  name='discountValue'
                  component={TextInput}
                  margin="none"
                  variant="outlined"
                  className={classes.textField}
                  type="number"
                  inputProps={{
                    min: 0,
                    step: '.01'
                  }}
                />
              </Grid>
              <Grid item md={2} sm={6}>
                <Field
                  name='discountType'
                  component={TextInput}
                  select
                  SelectProps={{ native: true }}
                  margin="none"
                  variant="outlined"
                  className={classes.selectField}
                >
                  {discountTypeOptions &&
                    discountTypeOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                </Field>
              </Grid>
              <Grid item md={3} sm={6} className={classes.totalPrice}>
                <Typography>
                  {formatPrice(OnlyDecimal(discountAmount), currencyCode)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction='row' className={classes.alignItems}>
              <Grid item md={9} sm={9}>
                <PortletLabel title={translate('subTotalIncludeTax')} />
              </Grid>
              <Grid item md={3} sm={3} className={classes.totalPrice}>

                <Typography>
                  {formatPrice(OnlyDecimal(subTotalIncludeTax), currencyCode)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction='row' className={classes.alignItems}>
              <Grid item md={9} sm={6}>
                <PortletLabel title={translate('taxArtical23')} />
              </Grid>
              <Grid item md={3} sm={6} className={classes.totalPrice}>
                <Typography className={classes.totalPriceText}>
                  {formatPrice(withholdingTax23, currencyCode)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction='row' className={classes.alignItems}>
              <Grid item md={9} sm={6}>
                <PortletLabel title={translate('totalDueAfterTax')} />
              </Grid>
              <Grid item md={3} sm={6} className={classes.totalPrice}>
                <Typography className={classes.totalPriceText}>
                  {formatPrice(totalAfterTax23, currencyCode)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction='row' className={classes.alignItems}>
              <Grid item md={4} sm={6}>
                <PortletLabel title={translate('deposit')} />
              </Grid>
              <Grid item md={3} sm={6}>
                <Field
                  name='depositValue'
                  component={TextInput}
                  margin="none"
                  variant="outlined"
                  className={classes.textField}
                  type="number"
                  inputProps={{
                    min: 0,
                    step: '.01'
                  }}
                />
              </Grid>
              <Grid item md={2} sm={6}>
                <Field
                  name='depositType'
                  component={TextInput}
                  select
                  SelectProps={{ native: true }}
                  margin="none"
                  variant="outlined"
                  className={classes.selectField}
                >
                  {depositTypeOptions &&
                    depositTypeOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                </Field>
              </Grid>
              <Grid item md={3} sm={6} className={classes.totalPrice}>
                <Typography>
                  {formatPrice(OnlyDecimal(depositAmount), currencyCode)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </PortletFooter>
    </Portlet>
  );
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(ServiceProductCardStyle)
)(ServiceProductCardForIndo);

import { DATE_TIME_FORMAT_12H, DATE_TIME_FORMAT_24H,
  TIME_FORMAT_12H_API, TIME_FORMAT_12, TIME_FORMAT_24 } from 'common/constant';
import { moment } from 'lib/app-moment';
import { store } from 'services/store';

const getDefaultOptionForCurrencyCode = (currency) => {
  const defaultOptions = {
    displaySymbol: true,
    symbolPosition: 'prefix',
    decimalScale: 2,
    fixedDecimalScale: true
  };
  if (currency && ['Rp'].map(e => e.toLowerCase()).indexOf(currency.toLowerCase()) >= 0) {
    return {
      ...defaultOptions,
      decimalSeparator: ',',
      thousandSeparator: '.',
      decimalSeparatorForRegExp: ',',
      thousandSeparatorForRegExp: '\\.'
    };
  }
  return {
    ...defaultOptions,
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalSeparatorForRegExp: '\\.',
    thousandSeparatorForRegExp: ','
  };
};

export const formatPrice = (value = 0, currency = '', inputOptions = {}) => {
  // eslint-disable-next-line prefer-const
  let { decimalSeparatorForRegExp, ...defaultOptions } = getDefaultOptionForCurrencyCode(currency);

  const options = {
    ...defaultOptions,
    ...inputOptions
  };
  let currencyValue = 0;
  if (value && !Number.isNaN(parseFloat(value))) {
    currencyValue = parseFloat(value);
  }

  if (options.fixedDecimalScale) {
    currencyValue = currencyValue.toFixed(options.decimalScale);
  } else {
    currencyValue = currencyValue.toFixed(0);
  }

  let finalResult = '';

  // eslint-disable-next-line prefer-const
  let [wholeNumber, fractional] = currencyValue.split('.');
  // wholeNumber = '1234' and fractional = '56'
  // NOTE
  // for spliting '1234' => wholeNumber = '1234' and fractional = undefined
  // for spliting '1234.' => wholeNumber = '1234' and fractional = ''

  // remove leading zeroes and make sure wholeNumber is int
  // parse wholeNumber to int by using Number.parseInt and then turn it back to string
  wholeNumber = Number.parseInt(wholeNumber);
  wholeNumber = (Number.isNaN(wholeNumber) ? 0 : wholeNumber).toString();

  // Add some thousandSeparator to wholeNumber
  wholeNumber = wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, `${options.thousandSeparator}`);
  // => wholeNumber = '1,234'
  finalResult = wholeNumber;
  if (options.fixedDecimalScale && options.decimalScale > 0) {
    finalResult += options.decimalSeparator;
    const fractionalLength = fractional ? fractional.length : 0;
    if (fractionalLength > 0)
      finalResult += fractional;
    for (let i = fractionalLength; i < options.decimalScale; i += 1)
      finalResult += '0';
  }
  if (options.displaySymbol)
    return options.symbolPosition === 'prefix' ? `${currency} ${finalResult}` : `${finalResult} ${currency}`;
  return finalResult;
};

export const normalizePrice = (currency = '', options = {}) => {
  return (value, previousValue) => {
    if (!value) return 0;
    const {
      decimalSeparator,
      decimalSeparatorForRegExp,
      fixedDecimalScale,
      decimalScale,
      thousandSeparatorForRegExp
    } = { ...getDefaultOptionForCurrencyCode(currency), ...options };

    if (
      // Prevent non-digit or non-decimalSeparator characters being entered
      (value[value.length - 1] !== decimalSeparator && Number.isNaN(Number.parseFloat(value[value.length - 1]))) ||

      // Prevent decimalSeparator characters being entered but option is not allow that
      ((!fixedDecimalScale || !decimalScale) && value[value.length - 1] === decimalSeparator) ||

      // Prevent decimalSeparator being entered more than 2 times
      ((value.toString()
        .match(new RegExp(decimalSeparatorForRegExp, 'g'))) || []).length >= 2 ||

      // Prevent more than `decimalScale` character(s) after `decimalSeparator` being entered
      // for example: if decimalScale === 2 and decimalSeparator === '.'
      // 12.34 and we input 5, it should be still 12.34 because we do not allow more than 2 characters after '.'
      (value.indexOf(decimalSeparator) >= 0 && value.substr(value.indexOf(decimalSeparator) + 1).length > decimalScale)
    ) {
      return previousValue;
    }
    // if value is invalid number, return 0
    const valueInString = value.toString().replace(new RegExp(thousandSeparatorForRegExp, 'g'), '');

    let isValidNumber = true;
    isValidNumber = valueInString.match(new RegExp(`^[-+]?\\d*${decimalSeparatorForRegExp}?\\d*$`));
    if (!isValidNumber) {
      value = '0';
    }
    return value.replace(new RegExp(thousandSeparatorForRegExp, 'g'), '')
      .replace(decimalSeparator, '.');
  };
};

export const formatPriceInput = (currency, options = {}) => {
  return (input) => {
    if (!input) return 0;
    // the goal of this function is update current formating
    // for example, current input is 1,234,567
    // and user input 8
    // the value now is 1,234,5678
    // we have to change it to 12,345,678

    // we cant use function formatPrice
    // because when user type 1
    // it will change to 1.00
    // then user type 2
    // it will change to 1.002
    // the problem is user type 1 then 2, instead of 12, it showed 1.002
    // so we have to reimplement some logic of formatPrice here

    const { decimalSeparator,
      thousandSeparator,
      thousandSeparatorForRegExp,
      decimalScale,
      fixedDecimalScale
    } = { ...getDefaultOptionForCurrencyCode(currency), ...options };

    let inputInString = input.toString().replace('.', decimalSeparator);
    if (inputInString.indexOf(decimalSeparator) < 0 &&
      inputInString.length > 1 && Number.parseFloat(inputInString) === 0)
      return 0;

    // let take '1,234.56' as example. It should be string now

    // 1/ remove all thousandSeparator
    inputInString = inputInString.replace(new RegExp(thousandSeparatorForRegExp, 'g'), '');
    // so '1,234.56' should be '1234.56'

    // 2/ split it into 2 part by the decimalSeparator
    // it should be at most 1 decimalSeparator because we already restricted it in normalize function
    // eslint-disable-next-line prefer-const
    let [wholeNumber, fractional] = inputInString.split(decimalSeparator);
    // wholeNumber = '1234' and fractional = '56'
    // NOTE
    // for spliting '1234' => wholeNumber = '1234' and fractional = undefined
    // for spliting '1234.' => wholeNumber = '1234' and fractional = ''

    // 3/ remove leading zeroes and make sure wholeNumber is int
    // parse wholeNumber to int by using Number.parseInt and then turn it back to string
    wholeNumber = Number.parseInt(wholeNumber);
    wholeNumber = (Number.isNaN(wholeNumber) ? 0 : wholeNumber).toString();

    // 4/ Add some thousandSeparator to wholeNumber
    wholeNumber = wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, `${thousandSeparator}`);
    // => wholeNumber = '1,234'

    let finalResult = '';
    finalResult = wholeNumber + (inputInString.indexOf(decimalSeparator) >= 0 ? decimalSeparator : '')
      + (fixedDecimalScale && decimalScale > 0 ? (fractional || '') : '');

    return finalResult;
  };
};

export const formatDate = (value, format = 'MMM DD, YYYY hh:mm A') => {
  return value ? moment(value).format(format) : '';
};

// This function is to format the datetime to time.
// e.g Mon Jan 20 2020 20:00:00 GMT+0700 to 20:00 or 08:00 PM based on input format params
export const parseDateTimeToTime = (value, format = 'hh:mm A') => {
  if (!value) return null;
  return moment(value).isValid() ? moment(value).format(format) : value;
};

// This function is to parse time to datetime.
// e.g 20:00 to Mon Jan 20 2020 20:00:00 GMT+0700 to display on website in somecase;
export const parseTimeToDateTime = (value, format = 'HH:mm') => {
  if (!value) return null;
  return moment(value, format);
};

// This function is to switch time format from 24h to 12h and vice versa
export const switchTimeFormat = (value, currentFormat = 'HH:mm', newFormat = 'hh:mm A') => {
  if (!value) return null;
  return moment(value, currentFormat).format(newFormat);
};

export const letterAvatar = (string1, string2='') => {
  if (!string1) return null;


  const matches = `${string1} ${string2}`.match(/\b(\w)/g);
  return matches.join('').toUpperCase();
};

export const momentWithTimeFormat = (time, formatTime = TIME_FORMAT_12 ) => moment(time, formatTime);

export const getTimeFormat = () => {
  const getCurrentFormatDateTime = store.getState().config.dateTimeFormat;
  return getCurrentFormatDateTime === TIME_FORMAT_12H_API ? TIME_FORMAT_12 : TIME_FORMAT_24;
};
export const getDateTimeFormat = () => {
  const getCurrentFormatDateTime = store.getState().config.dateTimeFormat;
  return getCurrentFormatDateTime === TIME_FORMAT_12H_API ? DATE_TIME_FORMAT_12H : DATE_TIME_FORMAT_24H;
};
import React from 'react';

// Externals
import moment from 'moment';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

// Material helpers
import { withStyles, Divider } from '@material-ui/core';

// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Grow,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// Customised components
import PaymentForm from './PaymentForm';
// Component styles
import UpdatePaymentModalStyle from './styles';

import { formatPrice } from 'lib/formatter';

const paymentForm = formValueSelector('paymentForm');

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    paymentMethod: (ownProps.paymentDetails && ownProps.paymentDetails.paymentMethodId) || null,
    amount: (ownProps.paymentDetails && ownProps.paymentDetails.amount) || null,
    paymentType: (ownProps.paymentDetails && ownProps.paymentDetails.paymentTypeId) || null,
    invoiceId: (ownProps.paymentDetails && ownProps.paymentDetails.invoiceId) || null,
    paymentDate: (ownProps.paymentDetails && ownProps.paymentDetails.paymentDate) || null,
    notes: (ownProps.paymentDetails && ownProps.paymentDetails.notes) || null,
    appliedTo: (ownProps.paymentDetails && ownProps.paymentDetails.paymentTypeId === 'INVOICE' ? ownProps.paymentDetails.invoiceId : ownProps.paymentDetails.paymentTypeId) || null,
    reference: (ownProps.paymentDetails && ownProps.paymentDetails.reference) || null,
    chequeNumber: (ownProps.paymentDetails && ownProps.paymentDetails.chequeNumber) || null,
  },
  formPaymentMethod: paymentForm(state, 'paymentMethod'),
});

const mapDispatchToProps = dispatch => ({
});

function UpdatePaymentModal(props) {
  const {
    classes,
    open,
    formPaymentMethod,
    handleClose,
    handleConfirmDelete,
    handleDownloadReceipt,
    handleEmailReceipt,
    handleSubmit,
    paymentMethods,
    paymentTypes,
    submitting,
    translate,
    paymentDetails,
  } = props;

  const clientInvoices =
    ((paymentDetails && paymentDetails.client && paymentDetails.client.invoices) || [])
      .filter(invoice => invoice.statusId === 'AWAITING_PAYMENT')
      .map(invoice => {
        return { ...invoice, name: `Invoice ${invoice.id}` };
      });

  const appliedToOptions = paymentTypes.filter(a => a.id === 'ACCOUNT').concat(clientInvoices);
  const formatPaymentMethod = value => {
    const method = paymentMethods.find(item => item.id === value);

    return method ? method.name : value;
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      transition={Grow}
    >
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <DialogTitle disableTypography className={classes.teamDialogTitle}>
          <Typography variant='h6' className={classes.teamDialogTitleText}>{`${translate('Common:payment')} ${paymentDetails.id}`}</Typography>
          {open ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent dividers>
          <Grid>

            <Grid style={{ padding: '8px 0', }}>
              <Typography>{formatPrice(paymentDetails.amount, paymentDetails.currency)}</Typography>
            </Grid>

            <Grid item container direction="row" justify="space-between" spacing={1} style={{ padding: '8px 0', }}>
              <Grid item sm={4}>
                <Typography>{translate('Client:appliedTo')}</Typography>
                {paymentDetails.invoiceId ?
                  <Link to={`/invoices/${paymentDetails.invoiceId}`}>
                    {`${translate('invoice')} ${paymentDetails.invoiceId}`}
                  </Link>
                  : translate('Invoice:account')
                }
              </Grid>

              <Divider orientation="vertical" style={{ height: 'auto', width: 1 }} />

              <Grid item sm={3}>
                <Typography>{translate('Client:paymentDate')}</Typography>
                <Typography>{moment(paymentDetails.paymentDate).format('MMM DD, YYYY')}</Typography>
              </Grid>

              <Divider orientation="vertical" style={{ height: 'auto', width: 1 }} />

              <Grid item sm={3}>
                <Typography>{translate('Client:paymentMethod')}</Typography>
                <Typography>{formatPaymentMethod(paymentDetails.paymentMethodId)}</Typography>
              </Grid>
            </Grid>

            <Grid item container direction='row' justify="space-around" alignItems="center" spacing={1} style={{ padding: '8px 0' }}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() => handleEmailReceipt(paymentDetails.id)}
                style={{ marginRight: 8 }}
              >{translate('Client:emailReceipt')}</Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleDownloadReceipt(paymentDetails.id)}
                style={{ marginLeft: 8 }}
              >{translate('downloadPdf')}</Button>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogContent dividers>
          <PaymentForm
            appliedToOptions={appliedToOptions}
            formPaymentMethod={formPaymentMethod}
            paymentMethods={paymentMethods}
            translate={translate}
          />
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-between">
            <Grid>
              <Button
                className={classes.deleteButton}
                variant='contained'
                size="small"
                onClick={handleConfirmDelete}
              >
                {translate('Common:delete')}
              </Button>
            </Grid>
            <Grid>
              <Button
                color="primary"
                size="small"
                onClick={handleClose}
              >
                {translate('Common:cancel')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="small"
                type="submit"
                disabled={submitting}
              >
                {translate('Common:save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(UpdatePaymentModalStyle)
)(reduxForm({ form: 'paymentForm', enableReinitialize: true, destroyOnUnmount: true })(UpdatePaymentModal));

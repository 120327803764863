import React from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import { compose } from 'redux';

// Material helpers
import { withStyles } from '@material-ui/core';


import QuoteListTable from './QuoteListTable';
import QuoteToolbar from './QuoteToolbar';
// Custom components

// Component styles
import styles from './style';

const QuoteList = props => {
  const { classes, history, routes, match, translate } = props;

  const handleNewQuoteClick = () => {
    history.push('/quotes/new');
  };

  return (
    <div className={classes.root}>
      <QuoteToolbar listPage routes={routes} match={match} handleClickNew={handleNewQuoteClick} translate={translate} />
      <div className={classes.content}>
        <QuoteListTable translate={translate} />
      </div>
    </div>
  );
};

QuoteList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(QuoteList);

import React from 'react';
import { TextInput } from 'components/inputs';
import { Button, FormLabel, Grid, IconButton } from '@material-ui/core';
// Redux
import { Field } from 'redux-form';

import { DeleteRounded as DeleteIcon } from '@material-ui/icons';



const renderTaxRates = props => {
  const { styles, fields, translate } = props;

  const taxRateObject = {
    id: `temp_${fields.length + 1}`,
    name: null,
    percentage: null,
  };

  function addTaxRate() {
    fields.push(taxRateObject);
  }

  function handleDeleteTaxRate(index) {
    fields.remove(index);
  }

  return (
    <Grid item container>
      <Grid item xs={12} sm={12} md={12} className={styles.labelActionHeader}>
        <FormLabel component="legend" className={styles.sectionLabel}>{translate('taxRates')}</FormLabel>
        <Button onClick={addTaxRate} className={styles.addBtn}>{translate('addTaxRate')}</Button>
      </Grid>
      {
        fields.map((field, index) => {
          return (
            <Grid container direction='row' justify='center' alignItems="center" spacing={2} key={index}>
              <Grid item lg={6} md={6} sm={12}>
                <Field
                  name={`${field}.name`}
                  component={TextInput}
                  label={translate('CompanySetting:taxName')}
                  variant='outlined'
                  margin='normal'
                  required
                  index={index}
                />

              </Grid>
              <Grid item lg={5} md={5} sm={11}>
                <Field
                  name={`${field}.percentage`}
                  label={translate('CompanySetting:percentage')}
                  component={TextInput}
                  variant='outlined'
                  margin='normal'
                  required
                  type='number'
                  inputProps={{ min: 0, max: 100, step: 0.01 }}
                />
              </Grid>
              <Grid item lg={1} md={1} sm={1}>
                <IconButton aria-label='Delete' onClick={_e => handleDeleteTaxRate(index)}>
                  <DeleteIcon style={{ width: 30, height: 30, color: 'red' }} />
                </IconButton>
              </Grid>
            </Grid>
          );
        })
      }
    </Grid>
  );
};

export default renderTaxRates;

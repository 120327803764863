export default theme => ({
  root: {
    backgroundColor: theme.palette.common.gray,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& span': {
      fontFamily: 'DM Sans',
      fontWeight: 500
    }
  },
  pt_16: {
    paddingTop: 16,
  },
  noPadding: {
    padding: 0,
  },
  displayNone: { display: 'none' },
  fs20: {
    fontSize: '20px !important'
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '63px',
    padding: 8,
    flexShrink: 0,
    backgroundColor: theme.palette.common.white,
    marginLeft: 5
  },
  list: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  fullSideBar: {

  },
  miniSideBar: {
    display: 'none'
  },
  logoCenter: {
    justifyContent: 'center',
  },
  logoLeft: {
    justifyContent: 'flex-start',
  },
  logoLink: {
    fontSize: 0,
    height: '100%'
  },
  logoImage: {
    cursor: 'pointer',
    height: '90%'
  },
  logoDivider: {
    marginBottom: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: '100px',
    height: '100px'
  },
  nameText: {
    marginTop: theme.spacing(2)
  },
  bioText: {},
  profileDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  listSubheader: {
    color: theme.palette.text.secondary
  },
  listItem: {
    cursor: 'pointer',
    '& .MuiListItemIcon-root': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      background: '#E2E2E2',
      borderRadius: 8,
      '& $listItemTitleIcon': {
        color: theme.palette.primary.main,
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
    },
    '& + &': {
      marginTop: theme.spacing(1)
    }
  },
  activeListItem: {
    background: '#FFFFFF',
    borderRadius: 8,
    '& span': {
      color: theme.palette.common.black,
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  listItemTitleIcon: {
    marginRight: 0
  },
  listItemIcon: {
    marginRight: 15,
  },
  itemIcon: {
    marginRight: 15
  },
  listItemTitleText: {
    color: theme.palette.text.gray1,
  },
  listItemText: {
    fontWeight: 500,
    color: theme.palette.text.gray1
  },
  hideListItem: { },
  listDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  nested: {
    height: theme.spacing(5),
  },
  menuSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageIcon: {
    height: '100%',
    objectFit: 'cover'
  },
  iconRoot: {
    textAlign: 'center'
  },
  powerBy: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.5)',
    paddingLeft: 24
  },
  poweredByWmLogo: {
    textAlign: 'left',
    paddingLeft: 24
  },
  wmLogo: {
    height: 30,
    marginTop: 5
  },
  logoContainer: {
    marginTop: 40,
    paddingBottom: 20,
    borderTop: '1px solid #D0D0D0',
    alignItems: 'center',
  },
  popover: {
    fontSize: 20
  },
  borderBottom: {
    borderBottom: '1px solid #D0D0D0',
    paddingBottom: 8,
    marginBottom: 8
  },
  closeLogo: {
    width: 14,
    height: 16
  },
  borderTop: {
    borderTop: '1px solid #D0D0D0',
    paddingTop: 8,
    marginTop: 8
  },
  footerLogoContainer: {
    paddingTop: 12,
    paddingBottom: 20,
    borderTop: '1px solid #D0D0D0',
    alignItems: 'center',
  },
  footerPowerBy: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 12,
    color: '#A2A2A2',
    paddingLeft: 24
  },
  footerLogo: {
    textAlign: 'center',
  },
  footerWmLogo: {
    marginTop: 5,
    width: 128.51,
    height: 40,
  },
});

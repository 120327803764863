export default theme => ({
  oot: {
    padding: theme.spacing(0)
  },
  content: {
    textAlign: 'center',
    position: 'relative'
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '554px'
  }

});

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
// Shared components
import styles from './styles.module.scss';
import TaxSettingForm from './taxSettingsForm';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import TaxSettingValidation from './taxSettingValidation';
import { getCompanyTaxRates, updateCompanyTaxSettings } from 'redux/company';

const TaxSettings = (props) => {
  const { translate } = props;
  const dispatch = useDispatch();
  const [taxes, setTaxes] = useState({});

  useEffect(() => {
    const fetchCompanyTaxRates = async () => {
      try {
        const result = await dispatch(getCompanyTaxRates());
        const formData = {
          id: result.data.id || null,
          taxNumbers: result.data.taxNumbers || [],
          taxRates: result.data.taxRates || [],
          taxComponents: result.data.taxComponents || [],
        };
        setTaxes(formData);
      } catch (error) {
        showErrorMessage(error);
      }
    };

    fetchCompanyTaxRates();
  }, []);

  async function handleCompanyUpdateTaxSettings(values) {
    try {
      const result = await dispatch(
        updateCompanyTaxSettings(values)
      ).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);

      const formData = {
        id: result.data.id || null,
        taxNumbers: result.data.taxNumbers || [],
        taxRates: result.data.taxRates || [],
        taxComponents: result.data.taxComponents || [],
      };
      setTaxes(formData);

      showSuccessMessage(translate('CompanySetting:companyUpdated'));
    } catch (error) {
      showErrorMessage(error);
    }
  }

  function validateAndCallSubmitTaxSettings(values) {
    const errors = TaxSettingValidation(values);
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message)
          .map((msg) => translate(`Error:${msg}`))
          .join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors)
          .map((msg) => translate(`Error:${msg}`))
          .join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));
      throw new SubmissionError(errors);
    }
    handleCompanyUpdateTaxSettings(values);
  }

  return (
    <div className={styles.companySetting}>
      <TaxSettingForm
        {...props}
        taxes={taxes}
        onSubmit={validateAndCallSubmitTaxSettings}
      />
    </div>
  );
};

export default TaxSettings;

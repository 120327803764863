// Palette
import palette from '../palette';

export default {
  root: {
    fontSize: 14,
    borderBottom: `1px solid ${palette.divider}`,
    padding: '14px 40px 14px 16px',
    '&:first-child': {
      paddingLeft: 18,
    }
  },
  head: {
    fontWeight: 600,
    color: palette.text.primary
  },
  body: {
    color: palette.text.grey800,
  },
};

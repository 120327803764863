import { countries } from 'moment-timezone/data/meta/latest.json';

export default class Countries {
  constructor() {
    this.countries = [
      {
        name: 'Malaysia',
        countryPhoneCode: '+60',
        countryCode: 'MY',
        supportedLanguageByGMP: 'ms',
      },
      {
        name: 'Singapore',
        countryPhoneCode: '+65',
        countryCode: 'SG',
        supportedLanguageByGMP: 'en',
      },
      {
        name: 'Thailand',
        countryPhoneCode: '+66',
        countryCode: 'TH',
        supportedLanguageByGMP: 'th',
      },
      {
        name: 'Brunei',
        countryPhoneCode: '+673',
        countryCode: 'BN',
        supportedLanguageByGMP: 'ms',
      },
      {
        name: 'Cambodia',
        countryPhoneCode: '+855',
        countryCode: 'KH',
        supportedLanguageByGMP: 'km',
      },
      {
        name: 'East Timor',
        countryPhoneCode: '+670',
        countryCode: 'TL',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Laos',
        countryPhoneCode: '+856',
        countryCode: 'LA',
        supportedLanguageByGMP: 'lo',
      },
      {
        name: 'Indonesia',
        countryPhoneCode: '+62',
        countryCode: 'ID',
        supportedLanguageByGMP: 'id',
      },
      {
        name: 'Myanmar',
        countryPhoneCode: '+95',
        countryCode: 'MM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Philippines',
        countryPhoneCode: '+63',
        countryCode: 'PH',
        supportedLanguageByGMP: 'fil',
      },
      {
        name: 'Vietnam',
        countryPhoneCode: '+84',
        countryCode: 'VN',
        supportedLanguageByGMP: 'vi',
      },
      {
        name: 'Afghanistan',
        countryPhoneCode: '+93',
        countryCode: 'AF',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Albania',
        countryPhoneCode: '+355',
        countryCode: 'AL',
        supportedLanguageByGMP: 'sq',
      },
      {
        name: 'Algeria',
        countryPhoneCode: '+213',
        countryCode: 'DZ',
        supportedLanguageByGMP: 'ar',
      },
      {
        name: 'American Samoa',
        countryPhoneCode: '+1684',
        countryCode: 'AS',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Andorra',
        countryPhoneCode: '+376',
        countryCode: 'AD',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Angola',
        countryPhoneCode: '+244',
        countryCode: 'AO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Anguilla',
        countryPhoneCode: '+1264',
        countryCode: 'AI',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Antarctica',
        countryPhoneCode: '+672',
        countryCode: 'AQ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Antigua and Barbuda',
        countryPhoneCode: '+1268',
        countryCode: 'AG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Argentina',
        countryPhoneCode: '+54',
        countryCode: 'AR',
        supportedLanguageByGMP: 'es',
      },
      {
        name: 'Armenia',
        countryPhoneCode: '+374',
        countryCode: 'AM',
        supportedLanguageByGMP: 'hy',
      },
      {
        name: 'Aruba',
        countryPhoneCode: '+297',
        countryCode: 'AW',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Australia',
        countryPhoneCode: '+61',
        countryCode: 'AU',
        supportedLanguageByGMP: 'en',
      },
      {
        name: 'Austria',
        countryPhoneCode: '+43',
        countryCode: 'AT',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Azerbaijan',
        countryPhoneCode: '+994',
        countryCode: 'AZ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Bahamas',
        countryPhoneCode: '+1242',
        countryCode: 'BS',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Bahrain',
        countryPhoneCode: '+973',
        countryCode: 'BH',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Bangladesh',
        countryPhoneCode: '+880',
        countryCode: 'BD',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Barbados',
        countryPhoneCode: '+1246',
        countryCode: 'BB',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Belarus',
        countryPhoneCode: '+375',
        countryCode: 'BY',
        supportedLanguageByGMP: 'be',
      },
      {
        name: 'Belgium',
        countryPhoneCode: '+32',
        countryCode: 'BE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Belize',
        countryPhoneCode: '+501',
        countryCode: 'BZ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Benin',
        countryPhoneCode: '+229',
        countryCode: 'BJ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Bermuda',
        countryPhoneCode: '+1441',
        countryCode: 'BM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Bhutan',
        countryPhoneCode: '+975',
        countryCode: 'BT',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Bolivia',
        countryPhoneCode: '+591',
        countryCode: 'BO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Bosnia and Herzegovina',
        countryPhoneCode: '+387',
        countryCode: 'BA',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Botswana',
        countryPhoneCode: '+267',
        countryCode: 'BW',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Brazil',
        countryPhoneCode: '+55',
        countryCode: 'BR',
        supportedLanguageByGMP: 'pt',
      },
      {
        name: 'British Indian Ocean Territory',
        countryPhoneCode: '+246',
        countryCode: 'IO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'British Virgin Islands',
        countryPhoneCode: '+1284',
        countryCode: 'VG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Bulgaria',
        countryPhoneCode: '+359',
        countryCode: 'BG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Burkina Faso',
        countryPhoneCode: '+226',
        countryCode: 'BF',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Burundi',
        countryPhoneCode: '+257',
        countryCode: 'BI',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Cameroon',
        countryPhoneCode: '+237',
        countryCode: 'CM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Canada',
        countryPhoneCode: '+1',
        countryCode: 'CA',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Cape Verde',
        countryPhoneCode: '+238',
        countryCode: 'CV',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Cayman Islands',
        countryPhoneCode: '+1345',
        countryCode: 'KY',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Central African Republic',
        countryPhoneCode: '+236',
        countryCode: 'CF',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Chad',
        countryPhoneCode: '+235',
        countryCode: 'TD',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Chile',
        countryPhoneCode: '+56',
        countryCode: 'CL',
        supportedLanguageByGMP: '',
      },
      {
        name: 'China',
        countryPhoneCode: '+86',
        countryCode: 'CN',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Christmas Island',
        countryPhoneCode: '+61',
        countryCode: 'CX',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Cocos Islands',
        countryPhoneCode: '+61',
        countryCode: 'CC',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Colombia',
        countryPhoneCode: '+57',
        countryCode: 'CO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Comoros',
        countryPhoneCode: '+269',
        countryCode: 'KM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Cook Islands',
        countryPhoneCode: '+682',
        countryCode: 'CK',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Costa Rica',
        countryPhoneCode: '+506',
        countryCode: 'CR',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Croatia',
        countryPhoneCode: '+385',
        countryCode: 'HR',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Cuba',
        countryPhoneCode: '+53',
        countryCode: 'CU',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Curacao',
        countryPhoneCode: '+599',
        countryCode: 'CW',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Cyprus',
        countryPhoneCode: '+357',
        countryCode: 'CY',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Czech Republic',
        countryPhoneCode: '+420',
        countryCode: 'CZ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Democratic Republic of the Congo',
        countryPhoneCode: '+243',
        countryCode: 'CD',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Denmark',
        countryPhoneCode: '+45',
        countryCode: 'DK',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Djibouti',
        countryPhoneCode: '+253',
        countryCode: 'DJ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Dominica',
        countryPhoneCode: '+1767',
        countryCode: 'DM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Dominican Republic',
        countryPhoneCode: '+1809',
        countryCode: 'DO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Ecuador',
        countryPhoneCode: '+593',
        countryCode: 'EC',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Egypt',
        countryPhoneCode: '+20',
        countryCode: 'EG',
        supportedLanguageByGMP: 'ar',
      },
      {
        name: 'El Salvador',
        countryPhoneCode: '+503',
        countryCode: 'SV',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Equatorial Guinea',
        countryPhoneCode: '+240',
        countryCode: 'GQ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Eritrea',
        countryPhoneCode: '+291',
        countryCode: 'ER',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Estonia',
        countryPhoneCode: '+372',
        countryCode: 'EE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Ethiopia',
        countryPhoneCode: '+251',
        countryCode: 'ET',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Falkland Islands',
        countryPhoneCode: '+500',
        countryCode: 'FK',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Faroe Islands',
        countryPhoneCode: '+298',
        countryCode: 'FO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Fiji',
        countryPhoneCode: '+679',
        countryCode: 'FJ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Finland',
        countryPhoneCode: '+358',
        countryCode: 'FI',
        supportedLanguageByGMP: '',
      },
      {
        name: 'France',
        countryPhoneCode: '+33',
        countryCode: 'FR',
        supportedLanguageByGMP: '',
      },
      {
        name: 'French Polynesia',
        countryPhoneCode: '+689',
        countryCode: 'PF',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Gabon',
        countryPhoneCode: '+241',
        countryCode: 'GA',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Gambia',
        countryPhoneCode: '+220',
        countryCode: 'GM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Georgia',
        countryPhoneCode: '+995',
        countryCode: 'GE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Germany',
        countryPhoneCode: '+49',
        countryCode: 'DE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Ghana',
        countryPhoneCode: '+233',
        countryCode: 'GH',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Gibraltar',
        countryPhoneCode: '+350',
        countryCode: 'GI',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Greece',
        countryPhoneCode: '+30',
        countryCode: 'GR',
        supportedLanguageByGMP: 'el',
      },
      {
        name: 'Greenland',
        countryPhoneCode: '+299',
        countryCode: 'GL',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Grenada',
        countryPhoneCode: '+1473',
        countryCode: 'GD',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Guam',
        countryPhoneCode: '+1671',
        countryCode: 'GU',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Guatemala',
        countryPhoneCode: '+502',
        countryCode: 'GT',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Guernsey',
        countryPhoneCode: '+441481',
        countryCode: 'GG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Guinea',
        countryPhoneCode: '+224',
        countryCode: 'GN',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Guinea Bissau',
        countryPhoneCode: '+245',
        countryCode: 'GW',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Guyana',
        countryPhoneCode: '+592',
        countryCode: 'GY',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Haiti',
        countryPhoneCode: '+509',
        countryCode: 'HT',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Honduras',
        countryPhoneCode: '+504',
        countryCode: 'HN',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Hong Kong',
        countryPhoneCode: '+852',
        countryCode: 'HK',
        supportedLanguageByGMP: 'zh',
      },
      {
        name: 'Hungary',
        countryPhoneCode: '+36',
        countryCode: 'HU',
        supportedLanguageByGMP: 'hu',
      },
      {
        name: 'Iceland',
        countryPhoneCode: '+354',
        countryCode: 'IS',
        supportedLanguageByGMP: 'is',
      },
      {
        name: 'India',
        countryPhoneCode: '+91',
        countryCode: 'IN',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Iran',
        countryPhoneCode: '+98',
        countryCode: 'IR',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Iraq',
        countryPhoneCode: '+964',
        countryCode: 'IQ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Ireland',
        countryPhoneCode: '+353',
        countryCode: 'IE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Isle of Man',
        countryPhoneCode: '+441624',
        countryCode: 'IM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Israel',
        countryPhoneCode: '+972',
        countryCode: 'IL',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Italy',
        countryPhoneCode: '+39',
        countryCode: 'IT',
        supportedLanguageByGMP: 'it',
      },
      {
        name: 'Ivory Coast',
        countryPhoneCode: '+225',
        countryCode: 'CI',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Jamaica',
        countryPhoneCode: '+1876',
        countryCode: 'JM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Japan',
        countryPhoneCode: '+81',
        countryCode: 'JP',
        supportedLanguageByGMP: 'ja',
      },
      {
        name: 'Jersey',
        countryPhoneCode: '+441534',
        countryCode: 'JE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Jordan',
        countryPhoneCode: '+962',
        countryCode: 'JO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Kazakhstan',
        countryPhoneCode: '+7',
        countryCode: 'KZ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Kenya',
        countryPhoneCode: '+254',
        countryCode: 'KE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Kiribati',
        countryPhoneCode: '+686',
        countryCode: 'KI',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Kosovo',
        countryPhoneCode: '+383',
        countryCode: 'XK',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Kuwait',
        countryPhoneCode: '+965',
        countryCode: 'KW',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Kyrgyzstan',
        countryPhoneCode: '+996',
        countryCode: 'KG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Latvia',
        countryPhoneCode: '+371',
        countryCode: 'LV',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Lebanon',
        countryPhoneCode: '+961',
        countryCode: 'LB',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Lesotho',
        countryPhoneCode: '+266',
        countryCode: 'LS',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Liberia',
        countryPhoneCode: '+231',
        countryCode: 'LR',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Libya',
        countryPhoneCode: '+218',
        countryCode: 'LY',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Liechtenstein',
        countryPhoneCode: '+423',
        countryCode: 'LI',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Lithuania',
        countryPhoneCode: '+370',
        countryCode: 'LT',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Luxembourg',
        countryPhoneCode: '+352',
        countryCode: 'LU',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Macau',
        countryPhoneCode: '+853',
        countryCode: 'MO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Macedonia',
        countryPhoneCode: '+389',
        countryCode: 'MK',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Madagascar',
        countryPhoneCode: '+261',
        countryCode: 'MG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Malawi',
        countryPhoneCode: '+265',
        countryCode: 'MW',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Maldives',
        countryPhoneCode: '+960',
        countryCode: 'MV',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Mali',
        countryPhoneCode: '+223',
        countryCode: 'ML',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Malta',
        countryPhoneCode: '+356',
        countryCode: 'MT',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Marshall Islands',
        countryPhoneCode: '+692',
        countryCode: 'MH',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Mauritania',
        countryPhoneCode: '+222',
        countryCode: 'MR',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Mauritius',
        countryPhoneCode: '+230',
        countryCode: 'MU',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Mayotte',
        countryPhoneCode: '+262',
        countryCode: 'YT',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Mexico',
        countryPhoneCode: '+52',
        countryCode: 'MX',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Micronesia',
        countryPhoneCode: '+691',
        countryCode: 'FM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Moldova',
        countryPhoneCode: '+373',
        countryCode: 'MD',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Monaco',
        countryPhoneCode: '+377',
        countryCode: 'MC',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Mongolia',
        countryPhoneCode: '+976',
        countryCode: 'MN',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Montenegro',
        countryPhoneCode: '+382',
        countryCode: 'ME',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Montserrat',
        countryPhoneCode: '+1664',
        countryCode: 'MS',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Morocco',
        countryPhoneCode: '+212',
        countryCode: 'MA',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Mozambique',
        countryPhoneCode: '+258',
        countryCode: 'MZ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Namibia',
        countryPhoneCode: '+264',
        countryCode: 'NA',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Nauru',
        countryPhoneCode: '+674',
        countryCode: 'NR',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Nepal',
        countryPhoneCode: '+977',
        countryCode: 'NP',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Netherlands',
        countryPhoneCode: '+31',
        countryCode: 'NL',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Netherlands Antilles',
        countryPhoneCode: '+599',
        countryCode: 'AN',
        supportedLanguageByGMP: '',
      },
      {
        name: 'New Caledonia',
        countryPhoneCode: '+687',
        countryCode: 'NC',
        supportedLanguageByGMP: '',
      },
      {
        name: 'New Zealand',
        countryPhoneCode: '+64',
        countryCode: 'NZ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Nicaragua',
        countryPhoneCode: '+505',
        countryCode: 'NI',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Niger',
        countryPhoneCode: '+227',
        countryCode: 'NE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Nigeria',
        countryPhoneCode: '+234',
        countryCode: 'NG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Niue',
        countryPhoneCode: '+683',
        countryCode: 'NU',
        supportedLanguageByGMP: '',
      },
      {
        name: 'North Korea',
        countryPhoneCode: '+850',
        countryCode: 'KP',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Northern Mariana Islands',
        countryPhoneCode: '+1670',
        countryCode: 'MP',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Norway',
        countryPhoneCode: '+47',
        countryCode: 'NO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Oman',
        countryPhoneCode: '+968',
        countryCode: 'OM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Pakistan',
        countryPhoneCode: '+92',
        countryCode: 'PK',
        supportedLanguageByGMP: 'ur',
      },
      {
        name: 'Palau',
        countryPhoneCode: '+680',
        countryCode: 'PW',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Palestine',
        countryPhoneCode: '+970',
        countryCode: 'PS',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Panama',
        countryPhoneCode: '+507',
        countryCode: 'PA',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Papua New Guinea',
        countryPhoneCode: '+675',
        countryCode: 'PG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Paraguay',
        countryPhoneCode: '+595',
        countryCode: 'PY',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Peru',
        countryPhoneCode: '+51',
        countryCode: 'PE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Pitcairn',
        countryPhoneCode: '+64',
        countryCode: 'PN',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Poland',
        countryPhoneCode: '+48',
        countryCode: 'PL',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Portugal',
        countryPhoneCode: '+351',
        countryCode: 'PT',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Puerto Rico',
        countryPhoneCode: '+1787',
        countryCode: 'PR',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Qatar',
        countryPhoneCode: '+974',
        countryCode: 'QA',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Republic of the Congo',
        countryPhoneCode: '+242',
        countryCode: 'CG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Reunion',
        countryPhoneCode: '+262',
        countryCode: 'RE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Romania',
        countryPhoneCode: '+40',
        countryCode: 'RO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Russia',
        countryPhoneCode: '+7',
        countryCode: 'RU',
        supportedLanguageByGMP: 'ru',
      },
      {
        name: 'Rwanda',
        countryPhoneCode: '+250',
        countryCode: 'RW',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Saint Barthelemy',
        countryPhoneCode: '+590',
        countryCode: 'BL',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Saint Helena',
        countryPhoneCode: '+290',
        countryCode: 'SH',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Saint Kitts and Nevis',
        countryPhoneCode: '+1869',
        countryCode: 'KN',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Saint Lucia',
        countryPhoneCode: '+1758',
        countryCode: 'LC',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Saint Martin',
        countryPhoneCode: '+590',
        countryCode: 'MF',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Saint Pierre and Miquelon',
        countryPhoneCode: '+508',
        countryCode: 'PM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Saint Vincent and the Grenadines',
        countryPhoneCode: '+1784',
        countryCode: 'VC',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Samoa',
        countryPhoneCode: '+685',
        countryCode: 'WS',
        supportedLanguageByGMP: '',
      },
      {
        name: 'San Marino',
        countryPhoneCode: '+378',
        countryCode: 'SM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Sao Tome and Principe',
        countryPhoneCode: '+239',
        countryCode: 'ST',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Saudi Arabia',
        countryPhoneCode: '+966',
        countryCode: 'SA',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Senegal',
        countryPhoneCode: '+221',
        countryCode: 'SN',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Serbia',
        countryPhoneCode: '+381',
        countryCode: 'RS',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Seychelles',
        countryPhoneCode: '+248',
        countryCode: 'SC',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Sierra Leone',
        countryPhoneCode: '+232',
        countryCode: 'SL',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Sint Maarten',
        countryPhoneCode: '+1721',
        countryCode: 'SX',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Slovakia',
        countryPhoneCode: '+421',
        countryCode: 'SK',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Slovenia',
        countryPhoneCode: '+386',
        countryCode: 'SI',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Solomon Islands',
        countryPhoneCode: '+677',
        countryCode: 'SB',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Somalia',
        countryPhoneCode: '+252',
        countryCode: 'SO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'South Africa',
        countryPhoneCode: '+27',
        countryCode: 'ZA',
        supportedLanguageByGMP: 'af',
      },
      {
        name: 'South Korea',
        countryPhoneCode: '+82',
        countryCode: 'KR',
        supportedLanguageByGMP: 'ko',
      },
      {
        name: 'South Sudan',
        countryPhoneCode: '+211',
        countryCode: 'SS',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Spain',
        countryPhoneCode: '+34',
        countryCode: 'ES',
        supportedLanguageByGMP: 'eu',
      },
      {
        name: 'Sri Lanka',
        countryPhoneCode: '+94',
        countryCode: 'LK',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Sudan',
        countryPhoneCode: '+249',
        countryCode: 'SD',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Suriname',
        countryPhoneCode: '+597',
        countryCode: 'SR',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Svalbard and Jan Mayen',
        countryPhoneCode: '+47',
        countryCode: 'SJ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Swaziland',
        countryPhoneCode: '+268',
        countryCode: 'SZ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Sweden',
        countryPhoneCode: '+46',
        countryCode: 'SE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Switzerland',
        countryPhoneCode: '+41',
        countryCode: 'CH',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Syria',
        countryPhoneCode: '+963',
        countryCode: 'SY',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Taiwan',
        countryPhoneCode: '+886',
        countryCode: 'TW',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Tajikistan',
        countryPhoneCode: '+992',
        countryCode: 'TJ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Tanzania',
        countryPhoneCode: '+255',
        countryCode: 'TZ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Togo',
        countryPhoneCode: '+228',
        countryCode: 'TG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Tokelau',
        countryPhoneCode: '+690',
        countryCode: 'TK',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Tonga',
        countryPhoneCode: '+676',
        countryCode: 'TO',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Trinidad and Tobago',
        countryPhoneCode: '+1868',
        countryCode: 'TT',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Tunisia',
        countryPhoneCode: '+216',
        countryCode: 'TN',
        supportedLanguageByGMP: 'tr',
      },
      {
        name: 'Turkey',
        countryPhoneCode: '+90',
        countryCode: 'TR',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Turkmenistan',
        countryPhoneCode: '+993',
        countryCode: 'TM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Turks and Caicos Islands',
        countryPhoneCode: '+1649',
        countryCode: 'TC',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Tuvalu',
        countryPhoneCode: '+688',
        countryCode: 'TV',
        supportedLanguageByGMP: '',
      },
      {
        name: 'U.S. Virgin Islands',
        countryPhoneCode: '+1340',
        countryCode: 'VI',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Uganda',
        countryPhoneCode: '+256',
        countryCode: 'UG',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Ukraine',
        countryPhoneCode: '+380',
        countryCode: 'UA',
        supportedLanguageByGMP: '',
      },
      {
        name: 'United Arab Emirates',
        countryPhoneCode: '+971',
        countryCode: 'AE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'United Kingdom',
        countryPhoneCode: '+44',
        countryCode: 'GB',
        supportedLanguageByGMP: 'en',
      },
      {
        name: 'United States',
        countryPhoneCode: '+1',
        countryCode: 'US',
        supportedLanguageByGMP: 'en',
      },
      {
        name: 'Uruguay',
        countryPhoneCode: '+598',
        countryCode: 'UY',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Uzbekistan',
        countryPhoneCode: '+998',
        countryCode: 'UZ',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Vanuatu',
        countryPhoneCode: '+678',
        countryCode: 'VU',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Vatican',
        countryPhoneCode: '+379',
        countryCode: 'VA',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Venezuela',
        countryPhoneCode: '+58',
        countryCode: 'VE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Wallis and Futuna',
        countryPhoneCode: '+681',
        countryCode: 'WF',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Western Sahara',
        countryPhoneCode: '+212',
        countryCode: 'EH',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Yemen',
        countryPhoneCode: '+967',
        countryCode: 'YE',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Zambia',
        countryPhoneCode: '+260',
        countryCode: 'ZM',
        supportedLanguageByGMP: '',
      },
      {
        name: 'Zimbabwe',
        countryPhoneCode: '+263',
        countryCode: 'ZW',
        supportedLanguageByGMP: '',
      },
    ];

    this.countries.forEach((country) => {
      country.zones = countries[country.countryCode]
        ? countries[country.countryCode].zones
        : [];
    });
  }

  countryPhoneCodes() {
    return this.countries.map((item) => {
      if (item.countryPhoneCode) {
        return {
          id: item.countryPhoneCode,
          name: `${item.name} (${item.countryPhoneCode})`,
        };
      }
      return { id: item.countryPhoneCode, name: item.name };
    });
  }

  countryCodesPhoneCodes() {
    return this.countries.map((item) => {
      if (item.countryPhoneCode) {
        return {
          id: item.countryPhoneCode,
          name: `${item.name} (${item.countryPhoneCode})`,
        };
      }
      return { id: item.countryPhoneCode, name: item.name };
    });
  }

  countryCodes() {
    return this.countries.map((item) => {
      return { id: item.countryCode, name: item.name };
    });
  }

  countryName(countryCode) {
    if (!countryCode) {
      return undefined;
    }

    const country = this.countries.find(
      (item) => item.countryCode === countryCode
    );
    return country ? country.name : '';
  }

  getCountryPhoneCodeByCountryCode(countryCode) {
    // input 'VN' will return '+84'
    if (!countryCode) {
      return null;
    }
    const country = this.countries.find(
      (item) => item.countryCode === countryCode
    );
    return country ? country.countryPhoneCode : null;
  }

  getLanguageCodeByCountryCode(countryCode) {
    if (!countryCode) return null;
    const country = this.countries.find(
      (item) => item.countryCode === countryCode
    );
    return country ? country.supportedLanguageByGMP : 'en';
  }

  getZone(countryCode) {
    const country = this.countries.find((c) => c.countryCode === countryCode);
    if (country) {
      return country.zones[0];
    }
    return null;
  }

  getCountryPhoneCodeByPhoneNumber(phoneNumber) {
    const country = this.countries.find((c) =>
      phoneNumber.includes(c.countryPhoneCode)
    );
    return country.countryPhoneCode;
  }
}

import React from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';

const localizer = momentLocalizer(moment);
const minTime = moment().startOf('day').toDate();
const maxTime = moment().endOf('day').toDate();

const CustomCalendarHeader = ({ label }) => (
  <div
    style={{ paddingTop: 10, textTransform: 'uppercase', fontWeight: 900 }}
  >
    {label}
  </div>
);

const BigCalendar = (props) => {
  const {
    views,
    currentCalendarView,
    Event,
    CustomToolbar,
    eventsList = [],
    handleCalendarViewChange,
    handleSelectSlot,
    onRangeChange,
    onSelectEvent,
  } = props;

  // note: onView have to provide if pass view in props

  return (
    <Calendar
      popup
      selectable
      localizer={localizer}
      events={eventsList}
      startAccessor="start"
      endAccessor="end"
      defaultDate={new Date()}
      views={views}
      view={currentCalendarView}
      showMultiDayTimes
      scrollToTime={moment().toDate()}
      step={30}
      timeslots={2}
      min={minTime}
      max={maxTime}
      components={{
        toolbar: CustomToolbar,
        event: Event,
        day: { header: CustomCalendarHeader },
        week: { header: CustomCalendarHeader },
        month: { header: CustomCalendarHeader },
      }}
      onSelectEvent={onSelectEvent}
      onSelectSlot={handleSelectSlot}
      onView={handleCalendarViewChange}
      onRangeChange={onRangeChange}
    />
  );
};

export default BigCalendar;

import React, { useEffect, useState } from 'react';

// Externals
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getProducts,
  addProduct,
  setCurrentService,
  updateProducts,
  deleteProduct,
  clearProductsServices
} from 'redux/product';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Typography,
  Button,
  List,
  Grid
} from '@material-ui/core';

// Customised components
import {
  Portlet,
  PortletHeader,
  PortletContent,
  PortletLabel,
} from 'pages/Dashboard/components';
import { ProductServiceModal, ConfirmDeleteProductServiceModal } from '../../modals';
import ListItem from '../../lists/ListItem';

// Component styles
import styles from './styles';

const mapStateToProps = state => ({
  serviceDetails: state.form.productDetails && state.form.productDetails.values,
  services: state.product.services,
  serviceId: state.product.serviceId,
});

const mapDispatchToProps = dispatch => ({
  getProducts: (type) => { dispatch(getProducts(type)); },
  setCurrentService: (service) => { dispatch(setCurrentService(service)); },
  addProduct: (service, callback) => { dispatch(addProduct(service, callback)); },
  updateProducts: (id, form, callback) => { dispatch(updateProducts(id, form, callback)); },
  deleteProduct: (id, callback) => { dispatch(deleteProduct(id, callback)); },
  clearProductsServices: () => { dispatch(clearProductsServices()); },
});

function ServicesTable(props) {
  const {
    classes,
    className,
    deleteProduct,
    services,
    updateProducts,
    serviceDetails,
    setCurrentService,
    getProducts,
    addProduct,
    serviceId,
    clearProductsServices,
    taxRates,
    taxComponents,
    translate,
  } = props;
  const [showServiceForm, setShowServiceForm] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const rootClassName = classNames(classes.root, className);

  useEffect(() => {
    return () => clearProductsServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const callback = () => {
      setShowServiceForm(!showServiceForm);
      getProducts('services');
      clearProductsServices();
    };

    if (serviceId) {
      updateProducts(serviceId, serviceDetails, callback);
    } else {
      serviceDetails.typeId='SERVICE';
      serviceDetails.sortOrder = Object.keys(services).length + 1;
      addProduct(serviceDetails, callback);
    }
  };

  const getService = id => {
    Object.keys(services).forEach(key => {
      const service = services[key];
      if (service.id.toString() === id) {
        setCurrentService(service);
      }
    });
  };

  function handleDeleteProduct() {
    setConfirmDelete(true);
  };

  function handleConfirmDelete() {
    setConfirmDelete(false);
    setShowServiceForm(false);
    clearProductsServices();
    deleteProduct(serviceId);
  };

  return (
    <Portlet className={rootClassName}>
      <PortletHeader>
        <PortletLabel
          title={translate('services')}
        />
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => setShowServiceForm(!showServiceForm)}
        >
          {translate('addService')}
        </Button>
      </PortletHeader>
      <PortletContent>
        <Grid container>
          <Grid item md={1} sm={12} />
          <Grid item md={3} sm={12}>
            <Typography gutterBottom variant="h5" component="h2">
              Service Name
            </Typography>
          </Grid>
          <Grid item md={4} sm={12}>
            <Typography gutterBottom variant="h5" component="h2">
              Description
            </Typography>
          </Grid>
          <Grid item md={2} sm={12}>
            <Typography gutterBottom variant="h5" component="h2">
              Unit Price
            </Typography>
          </Grid>
          <Grid item md={1} sm={12}>
            <Typography gutterBottom variant="h5" component="h2">
              Tax Rate
            </Typography>
          </Grid>
        </Grid>
        {
          services && Object.keys(services).length > 0 ?
            <Droppable droppableId='service'>
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {
                    Object.keys(services).map((item, index) => {
                      const service = services[item];
                      return (
                        <ListItem
                          id={service.id}
                          key={service.id}
                          index={index}
                          product={service}
                          handleClick={(row) => {
                            const { id } = row.currentTarget;

                            getService(id);
                            setShowServiceForm(!showServiceForm);
                          }}
                        />
                      );
                    })
                  }
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
            : <Typography>
              {translate('noService')}
            </Typography>
        }
      </PortletContent>

      {
        showServiceForm &&
        <ProductServiceModal
          serviceModal
          taxRates={taxRates}
          taxComponents={taxComponents}
          service
          open={showServiceForm}
          handleClose={() => {
            setShowServiceForm(false);
            clearProductsServices();
          }}
          handleSubmit={handleSubmit}
          handleDelete={handleDeleteProduct}
          translate={translate}
          title={serviceId ? 'updateService' : 'addNewService'}
        />
      }

      {
        confirmDelete &&
          <ConfirmDeleteProductServiceModal
            service
            open={confirmDelete}
            handleClose={() => {
              setConfirmDelete(false);
            }}
            handleConfirmDelete={handleConfirmDelete}
            translate={translate}
          />
      }
    </Portlet>
  );
};

ServicesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};


export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ServicesTable);

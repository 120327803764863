import React from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { Field } from 'redux-form';

// Customised components
import { TextInput } from 'components/inputs';
import { Grid } from '@material-ui/core';

export default (props) => {
  const {
    selections,
  } = props;
  const { t } = useTranslation('Common');

  return (
    <>
      <Grid item xl={6} lg={6} md={6} sm={6}>
        <Field
          component={TextInput}
          label={t('countryCode')}
          margin="dense"
          name="countryPhoneCode"
          required
          select
          SelectProps={{ native: true }}
          variant="outlined"
        >
          <option value='' />
          {
            selections && selections.length &&
            selections.map((option, i) => (
              <option
                key={i}
                value={option.id}
              >
                {option.name}
              </option>
            ))
          }
        </Field>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6}>
        <Field
          name="phoneNumber"
          component={TextInput}
          label={t('phoneNumber')}
          margin="dense"
          required
          type="number"
          variant="outlined"
        />
      </Grid>
    </>
  );
};
import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { arrayRemove } from 'redux-form';

// Material components
// Material helpers
import {
  Button,
  Grid,
  withStyles
} from '@material-ui/core';

// Customised components
import { ProductInput } from '../inputs';

// Component styles
import { ProductInputsFormStyle } from './styles';

const mapDispatchToProps = dispatch => ({
  removeInvoiceItem: (index) => { dispatch(arrayRemove('invoiceForm', 'items', index )); }
});

const ProductInputsForm = props => {
  const {
    fields,
    currencyCode,
    removeInvoiceItem,
    translate
  } = props;

  function appendField() {
    const newProduct = {
      quantity: 1,
      unitPrice: '0',
      total: '0',
      totalIncludeTax: '0',
      name: '',
    };

    fields.push(newProduct);
  }

  function handleDelete(index) {
    removeInvoiceItem( index);
  };

  return (
    <>
      <Grid container direction='column' spacing={3}>
        {
          fields.map((field, index) => {
            return (
              <Grid item key={index}>
                <ProductInput
                  index={index}
                  key={index}
                  name={field}
                  currencyCode={currencyCode}
                  handleDelete={() => handleDelete(index)}
                  translate={translate}
                />
              </Grid>
            );
          })
        }
        <Grid item container alignItems='flex-start'>
          <Button onClick={appendField}>{translate('addLineItem')}</Button>
        </Grid>
      </Grid>

    </>
  );
};

export default compose(
  withStyles(ProductInputsFormStyle),
  connect(null, mapDispatchToProps)
)(ProductInputsForm);
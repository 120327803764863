import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';

// Material helpers
// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Grid,
  Slider
} from '@material-ui/core';

// Material icons
import {
  Cancel as CancelIcon,
  RotateLeft as RotateLeftIcon,
  RotateRight as RotateRightIcon
} from '@material-ui/icons';
import styles from './styles.module.scss';

import { getCroppedImg } from './canvasUtils';


export default props => {
  const { image, onClose, open, croppedImageCallback, cropBtnText, translate } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      croppedImageCallback(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const rightRotateImage = () => {
    let newRotation = rotation + 90;
    if (newRotation === 360) newRotation = 0;
    setRotation(newRotation);
  };

  const leftRotateImage = () => {
    let newRotation = rotation - 90;
    if (newRotation === -360) newRotation = 0;
    setRotation(newRotation);
  };

  return (
    <Dialog
      classes={{ paper: styles.modalPaper }}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle disableTypography className={styles.clientDialogTitle}>
        <Typography variant="h4">{translate('Staff:cropImage')}</Typography>
        {open ? (
          <IconButton aria-label="Close" className={styles.closeButton} onClick={onClose}>
            <CancelIcon fontSize="large" style={{ color: '#808080' }} />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={styles.modalContent}>
        <Grid className={styles.container} container>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
          />
        </Grid>

      </DialogContent>
      <DialogActions>
        <div className={styles.sliderContainer}>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: styles.slider }}
            onChange={(e, value) => setZoom(value)}
          />
        </div>
        <div className={styles.rotationContainer}>
          <IconButton
            onClick={leftRotateImage}
          >
            <RotateLeftIcon fontSize="large" />
          </IconButton>
          <IconButton
            onClick={rightRotateImage}
          >
            <RotateRightIcon fontSize="large" />
          </IconButton>
        </div>
        <Button
          onClick={showCroppedImage}
          variant="contained"
          color="primary"
        >
          {cropBtnText || translate('Staff:crop')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { GET } from 'lib/api';

const UPDATE_PERSIST_CATEGORIES = 'UPDATE_PERSIST_CATEGORIES';

const initialState = {
  persistCategories: [],
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_PERSIST_CATEGORIES:
      return {
        ...state,
        persistCategories: action.categories,
      };

    default:
      return state;
  }
}

export function updateCategories(categories = []) {
  return {
    type: UPDATE_PERSIST_CATEGORIES,
    categories,
  };
}

export function getUserDetails() {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET('/me', accessToken).catch((message) => ({
      e: message,
    }));
    if (result.e) return result.e;
    return result.data;
  };
}

export function getTotalJobsSummary(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET('/enterprise_dashboard', accessToken, {
      params,
    }).catch((message) => ({
      e: message,
    }));
    if (result.e) return result.e;
    return result.data;
  };
}

export function getAnnouncements() {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET('/announcements', accessToken).catch(
      (message) => ({
        e: message,
      })
    );
    if (result.e) return result.e;
    return result.data;
  };
}

export function getEnterpriseJobDetails({ jobId }) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      `/enterprise_account/jobs/${jobId}`,
      accessToken
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result.e;
    return result.data;
  };
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { BusinessDetails, TaxSettings, BusinessHoursPage } from './components';
import styles from './styles.module.scss';
import { getCompanyDetails, clearCompanyDetails,
  companyDetailsUpdate } from 'redux/company';
import { setCurrentCompanyDetails, setCurrentCompanyLogo } from 'redux/auth';
import { uploadCompanyProfilePicture } from 'redux/uploadFile';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import BusinessDetailsValidation from './components/businessDetailsValidation';
import WorkingHoursValidation from './components/workingHoursValidation';
import getBase64 from 'lib/getBase64';
import dataURItoBlob from 'lib/dataURItoBlob';
import { TIME_FORMAT_12, TIME_FORMAT_24 } from 'common/constant';
import moment from 'moment-timezone';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function BusinessSettings(props) {
  const { translate, getCompanyDetailsDispatch, clearCompanyDetailsDispatch } = props;
  const [value, setValue] = React.useState(0);
  const [companyDetails, setCompanyDetails] = useState({});

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try{
        const response = await getCompanyDetailsDispatch();
        const companyDetailsData = {
          id: response.data.id,
          name: response.data.name,
          countryCode: response.data.countryCode,
          profilePicture: response.data.profilePicture,
          countryPhoneCode: response.data.countryPhoneCode,
          contactPhoneNumber: response.data.contactPhoneNumber,
          city: response.data.city,
          state: response.data.state,
          zipCode: response.data.zipCode,
          contactEmail: response.data.contactEmail,
          address: response.data.address,
          companyTypeId: response.data.companyTypeId,
          website: response.data.website,
          tradingName: response.data.tradingName,
          registrationNumber: response.data.registrationNumber,
          workingHours: response.data.workingHours
        };
        setCompanyDetails(companyDetailsData);
      } catch (error) {
        showErrorMessage(error);
      }
    };

    fetchCompanyDetails();

    // 'cleaned up company details';
    return () => {
      clearCompanyDetailsDispatch();
    };
  }, [value]);

  async function handleCompanyUpdate(values) {
    const { companyDetailsUpdateDispatch, uploadCompanyProfilePictureDispatch,
      setCurrentCompanyDetailsDispatch, setCurrentCompanyDetailLogo } = props;
    try {
      if (values && values.profilePicture && typeof values.profilePicture === 'object'
        && Object.keys(values.profilePicture).length > 0) {
        const image = await getBase64(values.profilePicture.file);
        const blob = dataURItoBlob(image);
        const formData = new FormData();
        formData.append('file', blob);
        formData.append('type', 'COMPANY_PROFILE_PICTURE');

        const imageUploadResponse =
          await uploadCompanyProfilePictureDispatch(formData);
        values.profilePicture = imageUploadResponse.url;
      }

      companyDetailsUpdateDispatch(values).then(response => {
        if (response.status === 200) {
          const companyDetailsData = {
            id: response.data.id,
            name: response.data.name,
            countryCode: response.data.countryCode,
            profilePicture: response.data.profilePicture,
            countryPhoneCode: response.data.countryPhoneCode,
            contactPhoneNumber: response.data.contactPhoneNumber,
            city: response.data.city,
            state: response.data.state,
            zipCode: response.data.zipCode,
            contactEmail: response.data.contactEmail,
            address: response.data.address,
            companyTypeId: response.data.companyTypeId,
            website: response.data.website,
            tradingName: response.data.tradingName,
            registrationNumber: response.data.registrationNumber,
            workingHours: response.data.workingHours
          };
          setCompanyDetails(companyDetailsData);
          setCurrentCompanyDetailsDispatch(companyDetailsData);
          setCurrentCompanyDetailLogo(companyDetailsData.profilePicture);
          showSuccessMessage(translate('CompanySetting:companyUpdated'));
        }
      }, error => {
        showErrorMessage(error);
      });
    } catch (error) {
      showErrorMessage(error);
    }
  }

  function validateAndCallSubmitBusinessDetails(values) {
    const errors = BusinessDetailsValidation(values);
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));
      throw new SubmissionError(errors);
    }

    handleCompanyUpdate(values);
  };

  function validateAndCallSubmitBusinessHours(values) {
    const errors = WorkingHoursValidation(values);
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));
      throw new SubmissionError(errors);
    }

    const data = { ...values };
    if (data.workingHours) {
      data.workingHours = data.workingHours.map((item) => {
        if (!item.working) {
          item.startTime = null;
          item.endTime = null;
          return item;
        };

        item.startTime = moment(item.startTime, TIME_FORMAT_12).format(TIME_FORMAT_24);
        item.endTime = moment(item.endTime, TIME_FORMAT_12).format(TIME_FORMAT_24);
        return item;
      });
    }
    handleCompanyUpdate(data);
  }

  return (
    <div className={styles.businessDetails}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleTabChange}
        aria-label="Tabs business setting"
        className={styles.tabs}
      >
        <Tab
          label={translate('CompanySetting:businessDetails')}
          id="vertical-tab-0"
          aria-controls="business-info"
          className={styles.tab}
        />
        <Tab
          label={translate('CompanySetting:taxSettings')}
          id="vertical-tab-1"
          aria-controls="tax-setting"
        />
        <Tab
          label={translate('CompanySetting:businessHours')}
          id="vertical-tab-2"
          aria-controls="business-hours"
        />
      </Tabs>

      <TabPanel value={value} index={0} className={styles.tabPanel}>
        <BusinessDetails companyDetails={companyDetails} translate={translate} onSubmit={validateAndCallSubmitBusinessDetails} />
      </TabPanel>
      <TabPanel value={value} index={1} className={styles.tabPanel}>
        <TaxSettings translate={translate} />
      </TabPanel>
      <TabPanel value={value} index={2} className={styles.tabPanel}>
        <BusinessHoursPage companyDetails={companyDetails} translate={translate} onSubmit={validateAndCallSubmitBusinessHours} />
      </TabPanel>
    </div>
  );
};


const mapDispatchToProps = dispatch => ({
  getCompanyDetailsDispatch: () => dispatch(getCompanyDetails()),
  companyDetailsUpdateDispatch: (companyInfo) => dispatch(companyDetailsUpdate(companyInfo)),
  clearCompanyDetailsDispatch: () => dispatch(clearCompanyDetails()),
  uploadCompanyProfilePictureDispatch: (formData) => dispatch(uploadCompanyProfilePicture(formData)),
  setCurrentCompanyDetailsDispatch: (details) => dispatch(setCurrentCompanyDetails(details)),
  setCurrentCompanyDetailLogo: (companyLogo) => dispatch(setCurrentCompanyLogo(companyLogo))
});

export default compose(
  connect(null, mapDispatchToProps),
)(BusinessSettings);

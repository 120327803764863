/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getHub } from 'redux/hub';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { showErrorMessage } from 'lib/notifier';
import { useTranslation } from 'react-i18next';

import { FormHelperText, Grid, Typography } from '@material-ui/core';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    return new Promise((rs) => {
      const later = async () => {
        clearTimeout(timeout);
        const resp = await func(...args).catch(() => rs([]));
        rs(resp);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    });
  };
};

const HubInput = (props) => {
  const {
    input: { onChange, value },
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation('Common');
  const [defaultOptions, setDefaultOptions] = useState([]);
  const handleChange = (selected) => {
    onChange(selected || []);
  };

  const fetchStaff = async () => {
    const params = { $limit: 10 };
    const result = await dispatch(getHub({ params })).catch((e) => ({
      e,
    }));
    if (result.e) {
      showErrorMessage(result.e);
      return result.e;
    }
    const formattedList = result.data.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    const defaultSelect = {
      value: null,
      label: t('Hub:selectedHubPlaceholder'),
    };
    formattedList.unshift(defaultSelect);
    setDefaultOptions(formattedList);
    return formattedList;
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  const promiseOptions = (inputValue) => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        const params = { $q: inputValue };
        const result = await dispatch(getHub({ params })).catch((e) => ({ e }));
        if (result.e) {
          showErrorMessage(result.e);
          reject(result.e);
        }
        const formattedList = result.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        resolve(formattedList);
      }, 1000);
    });
  };

  const MenuList = (menuProps) => {
    return (
      <components.MenuList {...menuProps}>
        {menuProps.children}
        <Grid
          container
          justify="center"
          style={{ padding: '12px 0px', borderTop: '1px solid #EAEAEA' }}
        >
          <Typography variant="body1" style={{ color: '#666666' }}>
            {t('Hub:cantFindHub')}
          </Typography>
          <Typography variant="body1" style={{ color: '#666666' }}>
            {t('Hub:tryToSearch')}
          </Typography>
        </Grid>
      </components.MenuList>
    );
  };

  return (
    <>
      <AsyncSelect
        closeMenuOnSelect
        value={value}
        onChange={handleChange}
        defaultOptions={defaultOptions}
        loadOptions={debounce(promiseOptions, 500)}
        placeholder={t('Hub:selectedHubPlaceholder')}
        components={{ MenuList }}
      />
      <FormHelperText>{t('Job:optional')}</FormHelperText>
    </>
  );
};

export default HubInput;

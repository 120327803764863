import React from 'react';
import Loader from 'react-loader-spinner';
import { COLORS } from 'common/colors';
import './Loader.scss';

export default props => {
  const { ...rest } = props;
  return (
    <div style={styles.loader}>
      <Loader
        type="Bars"
        color={COLORS.blue}
        height={100}
        width={100}
        {...rest}
      />
    </div>
  );
};

const styles = {
  loader: {
    display: 'flex',
    height: '100%',
    paddingTop: 'auto',
    paddingBottom: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    textAlignVertical: 'middle',
  },
};
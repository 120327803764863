import { EMAIL_VALIDATION } from 'common/constant';

const BusinessDetailsValidation = values => {

  let errors = { message: {} };

  if (values) {

    if (!values.name) {
      errors = {
        ...errors,
        name: 'required',
        message: {
          ...errors.message,
          name: 'organisationNameRequired',
        }
      };
    }

    if (!values.contactEmail) {
      errors = {
        ...errors,
        contactEmail: 'required',
        message: {
          ...errors.message,
          contactEmail: 'organisationEmailRequired'
        }
      };
    }

    if ( values.contactEmail && !EMAIL_VALIDATION.test(values.contactEmail)) {
      errors = {
        ...errors,
        contactEmail: 'invalidEmail',
        message: {
          ...errors.message,
          contactEmail: 'invalidEmail',
        }
      };
    }

    if (!values.contactPhoneNumber) {
      errors = {
        ...errors,
        contactPhoneNumber: 'required',
        message: {
          ...errors.message,
          contactPhoneNumber: 'organisationMobileNumberRequired',
        }
      };
    }

    if (!values.countryCode) {
      errors = {
        ...errors,
        countryCode: 'required',
        message: {
          ...errors.message,
          countryCode: 'countryCodeRequired',
        }
      };
    }

    if (!values.city) {
      errors = {
        ...errors,
        city: 'required',
        message: {
          ...errors.message,
          city: 'cityRequired',
        }
      };
    }
  }

  return errors;
};

export default BusinessDetailsValidation;

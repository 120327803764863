/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select, { components } from 'react-select';
import { MenuItem, Card, withStyles } from '@material-ui/core';
import StatusChip from 'pages/Dashboard/components/StatusChip';
import styles from '../StatusChip/styles';
import { JOB_STATUS_ENTERPRISE_DASHBOARD } from 'common/constant';

export const mapStatusValueToClasses = {
  ON_STANDBY: 'onStandby',
  ON_THE_WAY: 'onTheWay',
  ARRIVED: 'arrived',
  STARTED: 'started',
  COMPLETED: 'completed',
  UNASSIGNED: 'unassigned',
  UNSCHEDULED: 'unscheduled',
  LATE_VISIT: 'lateVisit',
  ARCHIVED: 'archived',
  PENDING: 'unassigned',
  IN_PROGRESS: 'onTheWay'
};

const StatusMultipleSelection = (props) => {
  const {
    input: { onChange, value },
    classes,
    isEnterprise
  } = props;

  const handleChange = (selected) => {
    onChange(selected || []);
  };

  const getStatusChip = (data, innerProps) => isEnterprise ?
    <StatusChip job status={JOB_STATUS_ENTERPRISE_DASHBOARD[data.value]} isForEnterprise {...innerProps} /> :
    <StatusChip job status={data.value} {...innerProps} />;

  const Option = (optionProps) => {
    const { innerRef, innerProps, data } = optionProps;

    return (
      <MenuItem
        ref={innerRef}
        style={{ justifyContent: 'center' }}
        {...innerProps}
      >
        { getStatusChip(data) }
      </MenuItem>
    );
  };

  const MultiValueLabel = (selectedProps) => {
    const { innerProps, data } = selectedProps;
    const newProps = { ...innerProps, customClassName: classes.selectionProps };
    return (
      <>
        { getStatusChip(data, newProps) }
      </>
    );
  };

  const Menu = (menuProps) => {
    const { children, innerRef, innerProps } = menuProps;
    return (
      <Card
        ref={innerRef}
        style={{ width: 'fit-content', position: 'absolute', zIndex: 10 }}
        {...innerProps}
      >
        {children}
      </Card>
    );
  };

  const MultiValueRemove = (removeProps) => {
    const { innerProps } = removeProps;
    const className = classes[mapStatusValueToClasses[removeProps.data.value]];
    const newClassName = `${innerProps.className}  ${className} ${classes.removeStyle}`;
    innerProps.className = newClassName;
    return <components.MultiValueRemove {...removeProps} />;
  };

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      value={value}
      components={{ Option, MultiValueLabel, Menu, MultiValueRemove }}
      onChange={handleChange}
      options={props.options}
      placeholder="All status"
    />
  );
};

export default withStyles(styles, { index: 1 })(StatusMultipleSelection);

// import { setIsLoading } from 'redux/global';
import { POST, GET, PATCH } from 'lib/api';
import { showErrorMessage } from 'lib/notifier';

const SET_CURRENT_PROPERTIES = 'SET_CURRENT_PROPERTIES';
const SET_CLEAR_PROPERTIES = 'SET_CLEAR_PROPERTIES';

const initialState = {
  properties: [],
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_PROPERTIES:
      return {
        ...state,
        properties: action.properties,
      };

    case SET_CLEAR_PROPERTIES:
      return {
        ...state,
        properties: null,
      };

    default: return state;
  }
}

// Action Creators
export function setCurrentProperties(properties) {
  return { type: SET_CURRENT_PROPERTIES, properties };
}

export function clearProperties() {
  return { type: SET_CLEAR_PROPERTIES };
}

export function getProperties(clientId, callback) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    // dispatch(setIsLoading(true));
    GET('/properties', accessToken, { params: { ownerId: clientId } }).then(response => {
      const { data, status } = response;

      if (status === 200) {
        // dispatch(setIsLoading(false));
        !!callback && callback(data.data, data);
      };
    }).catch(error => {
      showErrorMessage(error);
    });
  };
};

export function addProperty(propertyDetails, callback) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    // dispatch(setIsLoading(true))
    POST('/properties', accessToken, { data: { ...propertyDetails } }).then(response => {
      const { data, status } = response;

      if (status === 200) {
        // dispatch(setIsLoading(false));
        !!callback && callback(data);
      };
    }).catch(error => {
      showErrorMessage(error);
    });
  };
};

export function updateProperty(propertyId, propertyDetails, callback) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    // dispatch(setIsLoading(true))
    PATCH(`/properties/${propertyId}`, accessToken, { data: { ...propertyDetails } }).then(response => {
      const { data, status } = response;

      if (status === 200) {
        // dispatch(setIsLoading(false));
        !!callback && callback(data);
      };
    }).catch(error => {
      showErrorMessage(error);
    });
  };
};
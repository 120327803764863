import formValidation from './formValidation';
import { showErrorMessage } from 'lib/notifier';
import { SubmissionError } from 'redux-form';

export default function checkError(values, translate) {
  const errors = formValidation(values);
  // TODO: refactor validation
  if (errors && Object.keys(errors).length > 1) {
    const messages = [];
    if (typeof errors === 'object') {
      const message = Object.values(errors.message).map(msg => translate(`Error:${msg}`)).join('<br />');
      message && messages.push(message);
      if (errors.items && errors.items.length) {
        const itemMessage = Object.values(errors.items)
          .filter(item => item.message && item.message.name)
          .map(item => translate(`Error:${item.message.name}`))
          .join('<br />');
        itemMessage && messages.push(itemMessage);
      }
    } else {
      const message = Object.values(errors).map(msg => translate(`Error:${msg}`)).join('<br />');
      message && messages.push(message);
    }

    if (messages.length) {
      showErrorMessage( messages.join('<br />'));
      throw new SubmissionError(errors);
    }
  }
}
import React, { useState, useEffect, useRef } from 'react';

// import component
import { useDispatch } from 'react-redux';
import { fetchServiceReportDetails, setCurrentView } from 'redux/job';
import { useTranslation } from 'react-i18next';
import { showErrorMessage } from 'lib/notifier';
import { ServiceReportDetailsForm } from './components';
import ServiceReportToolbar from '../components/utils/ServiceReportToolbar';
import { useReactToPrint } from 'react-to-print';

const ServiceReportDetails = (props) => {
  const { t: translate } = useTranslation('Job');
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // fetch report details
    const { reportId } = props;
    const fetchDetails = async () => {
      setIsLoading(true);
      const details = await dispatch(
        fetchServiceReportDetails(reportId)
      ).catch((e) => ({ e }));
      setIsLoading(false);
      if (details.e) return showErrorMessage(details.e);
    };
    dispatch(setCurrentView(true));
    fetchDetails();
  }, []);

  return (
    <>
      <ServiceReportToolbar handlePrint={handlePrint} />

      {!isLoading && (
        <ServiceReportDetailsForm componentRef={componentRef} translate={translate} />
      )}
    </>
  );
};

export default ServiceReportDetails;

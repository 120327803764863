import React from 'react';

import './index.scss';
import { Button } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'pages/Dashboard/components';

class Properties extends React.Component {

  render() {
    const { handleClick, properties, translate } = this.props;
    return(

      <div className="properties">
        <Portlet>
          <PortletHeader className="section-header">
            <PortletLabel title={translate('Client:properties')} />
            <div><Button variant="contained" color="secondary" onClick={handleClick}>New Property</Button></div>
          </PortletHeader>
          <PortletContent>
            {
              properties && properties.map((item, index) => {
                return (
                  <div className="property-item" key={index}>
                    <div>
                      <h4 className="title">{item.name || `${translate('Common:property')} ${index + 1}`}</h4>
                      <div className="address">
                        {
                          [ item.unitNumber,
                            item.address
                          ].filter(e => e)
                            .join(', ').replace(/(,[^,]*),/g, '$1,\n')
                        }
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </PortletContent>
        </Portlet>


      </div>
    );
  }
}

export default Properties;
export default theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  modalContent: {
    padding: theme.spacing(3),
  },
  clientDialogTitle: {
    // minWidth: 800,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  sigCanvas: {
    // maxWidth: 600,
    width: '100%',
    height: 'auto',
    borderStyle: 'dotted',
    borderWidth: 1,
    borderColor: theme.palette.text.secondary,
  },
  signatureText: {
    userSelect: 'none',
  },
  sigImages: {
    backgroundSize: '200px 50px',
    width: 200,
    height: 50,
    backgroundColor: 'white',
  }
});
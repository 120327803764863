import React from 'react';
import { Chip } from '@material-ui/core';
import styles from './styles.module.scss';

const Chipbag = ({ label = '' }) => {
  return (
    <Chip
      className={styles.chipPreviousDay}
      label={label}
    />
  );
};

export default Chipbag;

export default theme => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: 900,
    margin: '0 auto'
  },
  pageContent: {
    marginTop: theme.spacing(2)
  },

  pageTitle: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  listOfTerms: {

  },
  termItem: {
    margin: '20px 30px'
  },

  itemContent: {
    paddingTop: theme.spacing(2),
    lineHeight: '24px'
  },
  listChild: {
    marginLeft: theme.spacing(3),
    padding: theme.spacing(1),
    '& li': {
      listStyleType: 'disc'
    }
  },
  childTerm: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
  }
});

import { GET, DELETE, PATCH, POST } from 'lib/api';

const initialState = {
  visit: {},
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    default: return state;
  }
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export function getVisit(id) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/v2/visits/${id}`, accessToken).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function getVisitInCalendar({ id, isCalendarPage }) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const params = { params: { isCalendarPage } };
    return GET(`/v2/visits/${id}`, accessToken, params).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

const mapOptionsToPayload = ['statusIds', 'jobId', 'staffIds', 'notTimesheetYet', 'jobCategoryIds',
  'clientId', 'serviceReport', 'lateVisit', 'isItemsCollected', 'hubIds', 'isCalendarPage', 'isVerified' ];

export function getVisits(options={}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    const queryParams = {};
    if (options.sort && options.sort.field) {
      queryParams[`$sort[${[options.sort.field]}]`] = options.sort.orderDirection === 'asc' ? 1 : -1;
    }
    if (options.from) {
      queryParams['$from'] = options.from;
    }
    if (options.to) {
      queryParams['$to'] = options.to;
    }

    if (options.limit) {
      queryParams['$limit'] = options.limit;
    }

    if (options.skip) {
      queryParams['$skip'] = options.skip;
    }

    mapOptionsToPayload.forEach(name => {
      if (options[name]) queryParams[name] = options[name];
    });

    const params = { params: queryParams };
    return GET('/v2/visits', accessToken, params).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function addVisit(visitDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    delete visitDetails['virtual']; // Delete virtual if it is in visit data

    return POST('/v2/visits', accessToken, { data: visitDetails }).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function updateVisit(visitDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(`/v2/visits/${visitDetails.id}`, accessToken, { data: visitDetails }).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function deleteVisit(id) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return DELETE(`/v2/visits/${id}`, accessToken).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}


export function updateVisitByAction(payload = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('v2/visits/actions', accessToken, { data: payload }).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}
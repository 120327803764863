import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import styles from './styles/sendReport.module.scss';
import { deleteServiceReport } from 'redux/job';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import { withRouter } from 'react-router-dom';

const SendReportModal = ({
  reportDetails,
  isOpenArchiveReportModal,
  setIsOpenArchiveReportModal,
  history,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation('Job');
  const deleteReport = async () => {
    const result = await dispatch(deleteServiceReport(reportDetails.id)).catch(
      (e) => ({
        e,
      })
    );
    if (result.e) return showErrorMessage(result.e);
    showSuccessMessage(translate('deleteSuccessfully'));
    history.goBack();
  };

  return (
    <Dialog
      classes={{ paper: styles.deleteReportDialog }}
      open={isOpenArchiveReportModal}
    >
      <Typography className={styles.title} variant="h4">
        {translate('deleteReport')}
      </Typography>
      <DialogContent className={styles.dialogContent}>
        <Typography className={styles.title2}>
          {translate('deleteDescription')}
        </Typography>
      </DialogContent>
      <DialogActions disableSpacing className={styles.action}>
        <Button
          onClick={() => setIsOpenArchiveReportModal(false)}
          className="mr_10"
        >
          {translate('Common:close')}
        </Button>
        <Button color="primary" variant="contained" onClick={deleteReport}>
          {translate('Common:delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(SendReportModal);

import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { arrayRemove } from 'redux-form';
// Material components
import {
  Button,
  Grid,
} from '@material-ui/core';

// Material helpers
import { withStyles } from '@material-ui/core';

// Customised components
import LineProductInput from './LineProductInput';

// Component styles
import LineProductArrayStyle from './styles/LineProductArrayStyle';

const mapDispatchToProps = dispatch => ({
  removeQuoteItem: (field, index) => dispatch(arrayRemove('quoteForm', field, index ))
});

const LineProductArray = props => {
  const {
    fields,
    currencyCode,
    removeQuoteItem,
    translate,
  } = props;

  function appendField() {
    const newProduct = {
      quantity: 1,
      unitPrice: '0',
      total: '0',
      name: '',
      taxRate: null,
      currency: currencyCode,
    };

    fields.push(newProduct);
  }

  function handleDelete(index) {
    removeQuoteItem('items', index);
  }

  return (
    <>
      <Grid container direction='column' spacing={3}>
        {
          fields.map((field, index) => {
            return (
              <Grid item key={index}>
                <LineProductInput
                  index={index}
                  key={index}
                  name={field}
                  currencyCode={currencyCode}
                  handleDelete={() => handleDelete(index)}
                  translate={translate}
                />
              </Grid>
            );
          })
        }
        <Grid item container alignItems='flex-start'>
          <Button onClick={appendField}>{translate('addLineItem')}</Button>
        </Grid>
      </Grid>

    </>
  );
};

export default compose(
  withStyles(LineProductArrayStyle),
  connect(null, mapDispatchToProps)
)(LineProductArray);

import React, { useState, useEffect } from 'react';

// Material components
import {
  Dialog,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  Typography,
  Box,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { getEnterpriseProperties } from 'redux/enterprise';
import { showErrorMessage } from 'lib/notifier';

import styles from './modal.module.scss';

import { Close as CloseIcon } from '@material-ui/icons';

const PropertiesModal = (props) => {
  const { translate, handleClose, jobDetails } = props;

  const clientId = jobDetails && jobDetails.client && jobDetails.client.id;

  const dispatch = useDispatch();
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    if (!clientId) return null;
    const params = { clientId };

    (async () => {
      const result = await dispatch(getEnterpriseProperties({ params }));
      if (result.e) return showErrorMessage(result.e);
      setProperties(result.data);
    })();
  }, [dispatch, getEnterpriseProperties, clientId]);

  return (
    <Dialog
      classes={{ paper: styles.dialog_wrapper }}
      aria-labelledby="customized-dialog-title"
      open
      transition={Grow}
    >
      <DialogTitle disableTypography className={styles.title_wrapper}>
        <Typography variant="h6">{translate('allAddress')}</Typography>
        <IconButton aria-label="Close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box p={2}>
        {properties.map((property) => (
          <Grid key={property.id} className={styles.border_bottom}>
            {property.name && (
              <Typography variant="title1" className="font_bold">
                {property.name}
              </Typography>
            )}
            <Typography className={styles.address}>
              {property.address}
            </Typography>
          </Grid>
        ))}
      </Box>
    </Dialog>
  );
};

export default PropertiesModal;

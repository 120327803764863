export default {
  requiredEmail: 'จำเป็นต้องใส่อีเมล',
  required: 'จำเป็น',
  chooseUserRole: 'กรุณาเลือกหน้าที่สำหรับผู้ใช้',
  inputJobTitle: 'กรุณาใส่หัวข้องาน',
  noClientSelected: 'ยังไม่ได้เลือกลูกค้า',
  noPropertySelected: 'ยังไม่ได้เลือกสถานที่',
  clientNotFound: 'ไม่พบลูกค้า',
  propertyNotFound: 'ไม่พบสถานที่',
  setStartTime: 'กรุณาเลือกเวลาเริ่มงาน',
  setEndTime: 'กรุณาเลือกเวลาจบงาน',
  setStartDate: 'กรุณาเลือกวันเริ่มงาน',
  setEndDate: 'กรุณาเลือกวันจบงาน',
  invalid: 'กรุณาใส่เฉพาะตัวเลข',
  invalidStartDate: 'วันเริ่มงานไม่ถูกต้อง',
  invalidEndDate: 'วันจบงานไม่ถูกต้อง',
  invalidStartTime: 'เวลาเริ่มงานไม่ถูกต้อง',
  invalidEndTime: 'เวลาจบงานไม่ถูกต้อง',
  invalidEmail: 'อีเมลไม่ถูกต้อง',
  invalidDiscount: 'Invalid discount',
  invalidDeposit: 'Invalid deposit',
  endTimeAfterStartTime: 'เวลาจบงานต้องช้ากว่าเวลาเริ่มงาน',
  endDateAfterStartDate: 'วันจบงานต้องช้ากว่าวันเริ่มงาน',
  invoiceSubject: 'กรุณาใส่หัวข้อใบแจ้งหนี้/ใบกำกับภาษี',
  setItemName: 'กรุณาใส่ชื่อสินค้า/บริการ',
  positiveQuantity: 'จำนวนต้องมากกว่า 0',
  positiveUnitCost: 'ราคาต่อหน่วยต้องมากกว่า 0',
  positiveTotal: 'ราคารวมต้องมากกว่า 0',
  productName: 'ตั้งชื่อสินค้า/บริการ',
  firstNameRequired: 'จำเป็นต้องใส่ชื่อ',
  lastNameRequired: 'จำเป็นต้องใส่นามสกุล',
  emailRequired: 'จำเป็นต้องใส่อีเมล',
  passwordRequired: 'จำเป็นต้องใส่รหัสผ่าน',
  confirmPasswordRequired: 'จำเป็นต้องยืนยันรหัสผ่าน',
  countryPhoneCodeRequired: 'จำเป็นต้องใส่รหัสประเทศ',
  mobileNumberRequired: 'จำเป็นต้องใส่เบอร์โทรศัพท์',
  phoneNumberRequired: 'จำเป็นต้องใส่เบอร์โทรศัพท์',
  addressRequired: 'จำเป็นต้องใส่ที่อยู่',
  cityRequired: 'จำเป็นต้องใส่จังหวัด',
  roleRequired: 'จำเป็นต้องใส่หน้าที่',
  countryCodeRequired: 'จำเป็นต้องใส่ประเทศ',
  zipCodeRequired: 'จำเป็นต้องใส่รหัสไปรษณีย์',
  hintPassword: 'รหัสผ่านจะต้องประกอบไปด้วย 8 ตัวอักษร',
  organisationNameRequired: 'จำเป็นต้องใส่ชื่อขององค์กร',
  organisationEmailRequired: 'จำเป็นต้องใส่อีเมลขององค์กร',
  organisationMobileNumberRequired: 'จำเป็นต้องใส่เบอร์โทรศัพท์ขององค์กร',
  passwordConfirmationNotMatch: 'รหัสผ่านที่ยืนยันไม่ตรงกับรหัสผ่านที่ตั้งไว้',
  visitTitleRequired: 'กรุณาใส่หัวข้อการเข้างาน',
  taxNameRequired: 'จำเป็นต้องใส่ชื่อสำหรับออกใบกำกับภาษี',
  taxNumberRequired: 'จำเป็นต้องใส่เลขประจำตัวผู้เสียภาษี',
  inputQuoteTitle: 'กรุณาใส่หัวข้อใบเสนอราคา',
  selectClient: 'กรุณาเลือกหรือสร้างลูกค้า',
  taxRateNameRequired: 'จำเป็นต้องใส่ชื่อภาษี',
  taxRatePercentageRequired: 'จำเป็นต้องใส่เปอร์เซ็นต์ภาษี',
  workingStartTimeRequired: 'จำเป็นต้องใส่เวลาเริ่มงาน',
  workingEndTimeRequired: 'จำเป็นต้องใส่เวลาจบงาน',
  timeWrongFormat: 'รูปแบบเวลาไม่ถูกต้อง'
};

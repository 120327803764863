import React from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Button, Grid, Typography } from "@material-ui/core";

// Shared components
import { PopupMenu } from "components/popups";

// Component styles
import { RequestsToolbarStyles } from "./styles";

function RequestsToolbar(props) {
  const {
    allowedStatuses,
    classes,
    className,
    clientName,
    detailPage = false,
    handleClickNew,
    handleClickStatus,
    request,
    requestStatuses,
    listPage = false,
    match,
    translate,
    isShowingAccountingFeature,
  } = props;

  const rootClassName = classNames(classes.root, className);

  const crumbsText = clientName ? `/ Request for ${clientName}` : "";
  const crumbsUrl = clientName ? `/requests/${match.params.requestId}` : "";

  const menuData = [
    {
      title: translate("Common:markAs"),
      status: requestStatuses,
      allowedStatuses,
    },
    // {
    //   title: translate("generateFrom"),
    //   menuItems: [
    //     {
    //       label: translate('request'),
    //       action: () => handleGenerateServiceReport('request'),
    //       padding: true
    //     },
    //     {
    //       label: translate("visit"),
    //       action: () => handleGenerateServiceReport("visit"),
    //       padding: true,
    //     },
    //   ],
    // },
  ];

  // if (isShowingAccountingFeature) {
  //   menuData.splice(1, 0, {
  //     menuItems: [
  //       {
  //         label: translate("generateInvoice"),
  //         action: handleGenerateInvoice,
  //         disabled: !((request || {}).permissions || {}).ableToCreateInvoice,
  //       },
  //     ],
  //   });
  // }

  return (
    <div className={rootClassName}>
      {listPage ? (
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button
              color="primary"
              // size="large"
              variant="contained"
              onClick={handleClickNew}
            >
              {translate("addInvoice")}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" justify="space-between">
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            md={4}
            sm={12}
          >
            <Typography variant="h5" className={classes.backTitle}>
              {translate("Common:backTo")}
            </Typography>
            <Link to="/requests">
              <Typography variant="h5" className={classes.backTo}>
                {translate("requests")}
              </Typography>
            </Link>
            {clientName && (
              <Link to={crumbsUrl}>
                <Typography variant="h5" className={classes.backTo}>
                  {crumbsText}
                </Typography>
              </Link>
            )}
          </Grid>
          {detailPage && (
            <Grid
              item
              container
              direction="row"
              justify="flex-end"
              spacing={1}
              md={8}
              sm={12}
            >
              <Grid item>
                <PopupMenu
                  menuData={menuData}
                  status={request.statusId}
                  handleClickStatus={handleClickStatus}
                  btnTitle={translate("Common:moreActions")}
                  btnIcon
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
}

RequestsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array,
};

export default withStyles(RequestsToolbarStyles)(RequestsToolbar);

import React from 'react';

// Externals
import classNames from 'classnames';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

// Material helpers
import { withStyles, Grid, Button } from '@material-ui/core';

// Material components
import { Dialog, IconButton } from '@material-ui/core';

// Material icons
import { Close as CloseIcon } from '@material-ui/icons';

// Component styles
import { SendSMSFormStyles } from './styles';
// Shared components
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
} from 'pages/Dashboard/components';
import { TextInput } from 'components/inputs';

const mapStateToProps = () => ({
  initialValues: {
    message: null,
  },
});

const mapDispatchToProps = () => ({});

function SendSMSForm(props) {
  const { classes, open, handleClose, handleSubmit, translate } = props;

  return (
    <Dialog maxWidth="lg" aria-labelledby="customized-dialog-title" open={open}>
      <Portlet className={classes.dialogWrap}>
        <PortletHeader className="card-header">
          <PortletLabel title={translate('Common:sendSms')} />
          {open ? (
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </PortletHeader>

        <PortletContent className="overview-content">
          <form onSubmit={handleSubmit}>
            <div className={classes.formContainer}>
              <div className={classes.row}>
                <div className={classes.fieldLabel}>
                  {translate('Common:message')}
                </div>
                <div
                  className={classNames(classes.fieldLabel, classes.limitChars)}
                >
                  160 chars
                </div>
              </div>
              <div className={classes.row}>
                <Field
                  name="message"
                  component={TextInput}
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows="5"
                />
              </div>
              <div className={classes.row}>
                <span className={classes.additionLabel}>
                  Charges apply for sending this SMS
                </span>
              </div>
              <div className={classes.row}>
                <Grid container justify="flex-end">
                  <Button type="submit" color="primary" variant="contained">
                    {translate('Common:send')}
                  </Button>
                </Grid>
              </div>
            </div>
          </form>
        </PortletContent>
      </Portlet>
    </Dialog>
  );
}

export default compose(
  withStyles(SendSMSFormStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(reduxForm({ form: 'workerSmsForm', destroyOnUnmount: true })(SendSMSForm));

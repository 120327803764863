import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { showErrorMessage } from 'lib/notifier';
import {
  validateStaffInvitationToken,
  confirmStaffInvitation,
} from 'redux/staff';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
// Component styles
import styles from './StaffInvitation.module.css';

const wpLogo = require('assets/images/logo/logo.png');

const StaffInvitation = (props) => {
  const { t, history, location } = props;
  const [tokenValidity, setTokenValidity] = useState(false);
  const [company, setCompany] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [redirectMessage, setRedirectMessage] = useState(null);
  const params = queryString.parse(location.search);
  const token = params && params.token;

  useEffect(() => {
    async function fetchData() {
      if (token) {
        try {
          const response = await validateStaffInvitationToken({ token });
          if (response.status === 200) {
            setTokenValidity(true);
            setCompany(response.data.company);
          } else {
            setErrorMessage(response.data.message);
          }
        } catch (error) {
          setTokenValidity(false);
          setErrorMessage(error.data.message);
        }
      }
    }
    fetchData();
  }, [token]);

  function confirmInvitation(type) {
    switch (type) {
      case 'ACCEPT':
        confirmStaffInvitation({ token: params.token, action: 'ACCEPTED' })
          .then(
            (response) => {
              if (
                response.status === 200 &&
                response.data &&
                response.data.acceptedAt
              ) {
                response.data.newUser
                  ? setRedirectMessage(
                    t('StaffInvitation:sentPasswordLinkInEmail')
                  )
                  : history.push('/');
              }
            },
            (error) => {
              showErrorMessage(error);
            }
          )
          .catch((error) => {
            showErrorMessage(error);
          });
        break;

      case 'REJECT':
        confirmStaffInvitation({ token: params.token, action: 'REJECTED' })
          .then(
            () => {
              history.push('/');
            },
            (error) => {
              showErrorMessage(error);
            }
          )
          .catch((error) => {
            showErrorMessage(error);
          });
        break;

      default:
        break;
    }
  }

  return (
    <div className={styles.staffInvitation}>
      <div className={styles.headerLogo}>
        <img className={styles.logo} src={wpLogo} alt="header-logo" />
      </div>

      <div className={styles.pageBody}>
        {redirectMessage ? (
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: redirectMessage }}
          />
        ) : tokenValidity ? (
          <>
            <div className={styles.text}>
              {company &&
                t('StaffInvitation:companyHasInvited', {
                  companyName: company.name,
                })}
            </div>

            <div className={styles.btnActions}>
              <Button
                variant="contained"
                color="secondary"
                className={styles.btnAction}
                onClick={() => confirmInvitation('ACCEPT')}
              >
                {t('StaffInvitation:accept')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={styles.btnAction}
                onClick={() => confirmInvitation('REJECT')}
              >
                {t('StaffInvitation:reject')}
              </Button>
            </div>
          </>
        ) : (
          <div className={styles.text}>{errorMessage}</div>
        )}
      </div>
    </div>
  );
};

export default withRouter(StaffInvitation);

import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import { Field } from 'redux-form';

import { StatusChip } from 'pages/Dashboard/components';
import StatusPopover from '../popover/StatusPopover';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const renderStatusBtn = (props) => {
  const { input, handleClick } = props;
  if (!input.value) return null;
  return (
    <Button onClick={handleClick}>
      <StatusChip job status={input.value} />
      <KeyboardArrowDownIcon className="ml_5" />

      <StatusPopover {...props} />
    </Button>
  );
};

const StatusBtn = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);

  const handleCloseStatusMenu = () => setAnchorEl(null);

  return (
    <Field
      name="statusId"
      component={renderStatusBtn}
      handleClick={handleClick}
      statusAnchorEl={anchorEl}
      handleCloseStatusMenu={handleCloseStatusMenu}
      {...props}
    />
  );
};

export default StatusBtn;

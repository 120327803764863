import { GET, PATCH } from 'lib/api';

// For Invoice setting
export function updateInvoiceSetting(invoiceSettingData) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    try {
      const response = await PATCH('/invoice-setting', accessToken, { data: invoiceSettingData });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}

// For Job setting
export function updateJobSetting(jobSettingData) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    try {
      const response = await PATCH('/job-setting', accessToken, { data: jobSettingData });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}

// For Quote setting
export function updateQuoteSetting(quoteSettingData) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    try {
      const response = await PATCH('/quote-setting', accessToken, { data: quoteSettingData });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}

export function getSettings() {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    try {
      const response = await GET('/settings', accessToken);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}

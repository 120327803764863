export default {
  // common && crumbs
  addJob: "+ งานใหม่",
  jobs: "งาน",
  jobDetails: "รายละเอียดงาน",
  editJob: "แก้ไขงาน",
  newJob: "งานใหม่",
  startJob: "วันที่เริ่มงาน",
  endJob: "วันที่จบงาน",
  jobId: "รหัสงาน",
  // jobs table
  status: "สถานะ",
  createdAt: "สร้างเมื่อ",
  id: "รหัส",
  title: "ห้วข้อ",
  client: "ลูกค้า",
  address: "ที่อยู่",
  schedule: "นัดหมาย",
  price: "ราคา",
  actions: "รายการ",
  search: "ค้นหา",
  showArchived: "แสดงข้อมูลที่จัดเก็บ",
  rows: "แถว",
  of: "ของ",
  viewJob: "ดูงาน",
  createdBy: "Created By",

  // DETAILS
  contactDetails: "ข้อมูลผู้ติดต่อ",
  startAt: "เวลาเริ่ม",
  endAt: "เวลาจบ",
  generateInvoice: "สร้างใบแจ้งหนี้/ใบกำกับภาษี",

  // line items
  lineItems: "สินค้า/บริการ",
  productService: "สินค้า/บริการ",
  description: "รายละเอียด",
  quantity: "จำนวน",
  unitPrice: "ราคาต่อหน่วย",
  total: "รวม",
  subtotal: "รวม",

  // visits
  visits: "เข้างาน",
  newVisit: "เข้างานใหม่",
  number: "เลขที่",
  visitTitle: "หัวข้อ",
  assignedTo: "มอบหมายให้",
  scheduleVisit: "นัดหมายการเข้างาน",
  "job#": "งานรหัส #",

  // billing
  billing: "บัญชี",
  date: "วันที่",
  subject: "หัวข้อ",
  totals: "รวม",

  // notes
  noteDetails: "รายละเอียดบันทึกเพิ่มเติม",
  comingSoon: "เร็วๆนี้...",

  // assigned team
  assignedTeam: "มอบหมายงาน",

  // items
  items: "Items",

  // Media/File
  mediaFile: "Media/File",

  // JOB FORM
  oneOffJob: "งานเดี่ยว",
  oneOffJobDescription:
    "งานเดี่ยวที่อาจจะมีการเข้างานหนึ่งครั้งหรือมากกว่าก็ได้",
  recurringJob: "งานแพ็คเกจ (เร็วๆนี้...)",
  recurringJobDescription:
    "งานแพ็คเกจระยะยาวที่มีการเข้างานอย่างเป็นประจำสม่ำเสมอ",
  jobFor: "งานของคุณ",
  jobForClient: "<0><0></0></0>",
  clientName: "ชื่อลูกค้า ",
  propertyAddress: "ที่อยู่ปัจจุบัน",
  email: "อีเมล",
  contactNo: "เบอร์โทรศัพท์ผู้ติดต่อ",
  change: "แก้ไข",
  jobType: "ชนิดของงาน",
  startsOn: "วันเริ่มงาน",
  endsOn: "วันจบงาน",
  billingFrequency: "ความถี่ในการวางบิล",

  // client modal
  selectCreateClient: "เลือกหรือสร้างลูกค้าใหม่",
  whichClient: "งานนี้ถูกสร้างสำหรับลูกค้าบัญชีใด",
  createClient: "สร้างลูกค้าใหม่",
  properties: "สถานที่ทั้งหมด",
  noPropertyRecorded: "ยังไม่มีสถานที่ที่บันทึกไว้",
  createNewClient: "สร้างลูกค้าใหม่",

  // property modal
  selectCreateProperty: "เลือกหรือสร้างสถานที่ใหม่",
  whichProperty: "งานนี้ถูกสร้างสำหรับสถานที่ใด",
  property: "สถานที่",
  createProperty: "สร้างสถานที่",
  updateProperty: "อัปเดตสถานที่",
  createPropertyFor: "สร้างสถานที่ใหม่สำหรับ",
  updatePropertyFor: "อัปเดตสถานที่ใหม่สำหรับ",
  createNewProperty: "สร้างสถานที่ใหม่",
  coordsRequired:
    "จำเป็นต้องใส่พิกัดของสถานที่เพื่อที่ GPS จะทำงานได้สมบูรณ์ กรุณาใส่เลขละติจูดและลองจิจูดสำหรับสถานที่นี้ หรือลองกดค้นหาสถานที่อีกครั้ง",

  // schedule card
  time: "เวลา",
  startDate: "วันที่เริ่มงาน",
  endDate: "วันที่จบงาน",
  startTime: "เวลาเริ่มงาน",
  endTime: "เวลาจบงาน",
  optional: "ไม่บังคับ",

  // team card
  team: "ทีมงาน",
  assign: "+ มอบหมาย",
  noUsersAssigned: "ยังไม่มีพนักงานที่ได้รับมอบหมาย",
  selectTeam: "เลือกพนักงาน",
  noStaffAddUser: "ยังไม่มีพนักงาน กรุณาเพิ่มพนักงานก่อนมอบหมายงาน",
  createUser: "+ เพิ่มพนักงาน",
  done: "สำเร็จ",
  addUser: "เพิ่มพนักงานใหม่",

  // line item card
  serviceProduct: "สินค้า/บริการ",
  qty: "จำนวน",
  addLineItem: "สินค้า/บริการ",
  item: "สินค้า/บริการ",
  internalNotes: "บันทึกสำหรับใช้ภายใน",

  // unassigned job
  unAssignedJobs: "งานที่ยังไม่ได้มอบหมาย",
  noUnassignedJobs: "ไม่มีงานค้าง",
  deleteVisit: "คุณแน่ใจหรือไม่ว่าต้องการลบการเข้างานนี้",
  completed: "เสร็จสิ้น",

  confirmDelete: "DELETE MEDIA",
  confirmDeleteContent:
    "Deleting Media #<0><0></0></0> will permanently remove it from WorkPulse",
  uploadSuccess:
    "Your file(s) are processing, files will be shown after process completed",
  viewFile: "View file",
  tag: "Tag",
  submittedOn: "submitted on",
  dropHere: "Drop files here or use the “Upload” button",

  // serviceReport
  serviceReport: "Service Report",
  btnTitle: "Download template",
  btnDescription: "for printing and general use",
  filters: "Filters",
  type: "Type",
  jobReport: "Job Report",
  visitReport: "Visit Report",
  reportNo: "Report No.",
  issuedAt: "Issued at",
  lastUpdated: "Last updated",
  selectJob: "Select Job’s Visits to create service report",
  generateFrom: "Generate Service Report from...",
  visit: "Visit",
  viewMode: "View mode",
  editMode: "Edit mode",
  view: "View",
  edit: "Edit",
  saveAsDraft: "Save as Draft",
  saveAndSend: "Save and Send",
  full: "Full",
  pictorial: "Pictorial",
  fullDescription: "Report with full information",
  pictorialDescription: "Mainly focus on before & after job pictures",
  companyPhone: "Phone:  ({{countryPhoneCode}}) {{phoneNumber}}",
  companyEmail: "Email:  {{email}}",
  reportNumber: "Report No. <0><0></0></0>",
  reportDate: "Date: <0><0></0></0>",
  customer: "Customer",
  phoneNumber: "Phone number",
  appointmentDate: "Appointment date",
  requestDate: "Request date",
  jobDescription: "Job Description",
  actionTaken: "Action taken / Remark",
  deviceInfo: "Device Info",
  modelNumber: "Model Number",
  serialNumber: "Serial Number",
  serviceType: "Service Type",
  jobImage: "Job’s images",
  browseFromGallery: "Browse from gallery",
  jobMedia: "Job’s Media",
  jobMediaDescription: "You can select multiple items.",
  itemSelected: "{{number}} items selected",
  select: "Select",
  addCaption: "Add Caption",
  serviceProvide: "Service Provided",
  itemNo: "Item No.",
  unitPriceServiceReport: "Unit Price",
  certificate:
    "Certified that the above work has been satisfactorily carried out",
  timeArrival: "Time of arrival",
  serviceBy: "Serviced by",
  timeOfCompletion: "Time of completion",
  scheduleDate: "Schedule Date",
  certified:
    "Certified that the above work has been satisfactorily carried out",
  servicedBy: "Service by",
  clientSignature: "Client’s Signature",
  signatureDescription: "(if signed by representative)",
  dateFormat: "MMM DD, YYYY",
  dateTimeFormat: "MMM DD, YYYY - HH:mm",
  timeFormat: "HH:mm",
  reportType: "Report type: {{type}}",
  generalInfo: "Job’s General Info",
  jobImages: "Job’s images",
  sendReport: "Send Service Report via...",
  reportMethod: "Select how you want to send Service Report",
  deleteReport: "Delete report?",
  deleteDescription:
    "Are you sure you want to delete this? It can not be undone.",
  addMore: "Add more recipient",
  createSuccessfully: "Service Report saved as draft",
  editSuccessfully: "Service Report edited successfully",
  deleteSuccessfully: "Service Report deleted successfully",
  sendSuccess: "Service Report sent successfully",
  serviceTypeSiteRepair: "Site Service/Repair/Calibration",
  serviceTypeInHouseRepair: "In house services/Repair/Calibration",
  serviceTypeFollowUp: "Follow up",
  serviceTypeMaintenance: "Maintenance Contract",
  serviceTypeDiagnostic: "Diagnostic Services",
  serviceTypeInstallation: "Project Installation",
  downloadSuccessfully: "Download Service Report Successfully",
  emailInputPlaceHolder: 'Press "Enter" to submit',
  JOB_FULL: "Job Report - Full",
  JOB_PICTORIAL: "Job Report - Pictorial",
  VISIT_FULL: "Visit Report - Full",
  VISIT_PICTORIAL: "Visit Report - Pictorial",
  totalPrice: "Total Price",
  additionalSetting: "Additional Settings",
  skipOnTheWayTracking: "Skip 'On The Way' tracking",
  skipArrivedTracking: "Skip 'Arrived' tracking",
  jobCategory: "Category Tags",
  addCategory: "+ Category",
  selectClient: "Select client",
  id_category: "Job ID/Order ID/Category",
  weAreGeneratingReport:
    "We are generating your report, this might take more than a minute.",
  viewDetails: "View details",
  changeProgressTimestamp: "Change progress’s timestamp",
  addService: "Add item",
  addAnotherService: "Add another service",
  service: "Service",
  itemName: "Item Name",
  model: "Model.",
  serialNo: "Serial No.",
  viewAs: "View as",
  serviceCard: "Service cards",
  serviceOnly: "Service only",
  itemOnly: "Items only",
  serviceReportNumber: "Service Report ({{numberCreatedSr}})",
  serviceReportFilter: "Filters for jobs' service report availability/presence",
  available: "Available",
  none: "None",
  hasLateVisit: "Has late visit",
  hasNoVisit: "No late visit",
  lateVisit: "Late Visit",
  serviceReportAvailable: "Service Report(s) available.",
  jobHasLate: "This job has late visit(s)",
  scheduleLater:
    "Schedule later, any current schedule will be removed and the visit will be marked as Unscheduled.",
  orderId: "Order ID",
  deliveryNote: "DN Number (Delivery Note)",
  hubInfo: "Hub Info",
  viewJobDetails: "View job details",
  viewClientDetails: "View client details",
  archivedThisJob: "Archive this job?",
  archivedThisJobDescription: "Are you sure you want to archive this Job?",
  unarchive: "Unarchive",
  archive: "Archive",
  deleteThisJob: "Delete this Job?",
  deleteThisJobDescription:
    "Are you sure you want to delete this Job? You can not undo this action.",
  deleteJob: "Delete this Job",
  deleteJobSuccessfully: "Delete job successfully",
  itemPicked: "Job’s items had been collected",
  markItemsCollected: "Mark job’s items Collected",
  markItemsUncollected: "Mark job’s items Uncollected",
  collectedItems: "Collected Items?",
  notCollected: "Not collected",
  collected: "Collected",
  categoryTags: "CATEGORY TAGS",
};

import React, { useState } from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import { searchPlaces, getPlaceDetails } from 'redux/location';

// Material components
import {
  withStyles,
  Grid,
  ListItem,
  List,
  ListItemText,
  CircularProgress,
  Box,
  Typography,
  ClickAwayListener
} from '@material-ui/core';
// Customised components
import { TextInput } from 'components/inputs';
import Countries from 'lib/countryList';
import { showErrorMessage } from 'lib/notifier';
import styles from './styles';
import GoogleSrc from 'assets/images/google.svg';

const countryList = new Countries();
const countryCodeOptions = countryList.countryCodes();

const getSearchOptions = ({ auth = {} }) => {
  const { currentUser } = auth;
  let language = 'en';
  let placeSearchOptions = {
    language,
  };
  // pick first company in list for country code
  const companies = (currentUser && currentUser.companies) || [];
  if (
    Array.isArray(companies) &&
    companies.length > 0 &&
    companies[0].countryCode
  ) {
    const { countryCode } = companies[0];
    // get language from user first, if not have get from countrycode
    language =
      currentUser && currentUser.language
        ? currentUser.language
        : countryList.getLanguageCodeByCountryCode(countryCode);
    placeSearchOptions = {
      ...placeSearchOptions,
      language,
      components: `country:${countryCode}`,
    };
  }
  return placeSearchOptions;
};

const mapStateToProps = (state) => ({
  placeSearchOptions: getSearchOptions(state),
});

const SEARCH_TIMEOUT = 800;
let timer = 0;
function defineFieldName(name, nameOfField) {
  if (nameOfField && name) {
    return `${nameOfField}.${name}`;
  }
  return name;
}

function parsePlaceDetails(place) {
  let placeAddress = place.formatted_address;
  if (place.name && !place.formatted_address.includes(place.name)) {
    placeAddress = `${place.name}, ${place.formatted_address}`;
  }
  const location = {
    address: placeAddress,
    placeId: place.place_id,
    lat: place.geometry.location.lat,
    lng: place.geometry.location.lng,
    name: place.name,
  };
  const addressComponents = place.address_components;
  const floor = addressComponents.find((item) => item.types.includes('floor'));
  const room = addressComponents.find((item) => item.types.includes('room'));
  const city = addressComponents.find((item) =>
    item.types.includes('locality')
  );
  const postalCode = addressComponents.find((item) =>
    item.types.includes('postal_code')
  );
  const state = addressComponents.find((item) =>
    item.types.includes('administrative_area_level_1')
  );
  const country = addressComponents.find((item) =>
    item.types.includes('country')
  );
  location.unitNumber = [floor && floor.long_name, room && room.long_name]
    .filter(Boolean)
    .join(' ');
  location.city = (city && city.long_name) || '';
  location.postalCode = (postalCode && postalCode.long_name) || '';
  location.state = (state && state.long_name) || '';
  location.countryCode = (country && country.short_name) || '';
  return location;
}

function PropertyAddressForm(props) {
  const {
    nameField,
    translate,
    classes,
    handleSelectLocation,
    placeSearchOptions,
  } = props;
  const [searchResults, setSearchResults] = useState(null);
  const [isSearchingPlace, setIsSearchingPlace] = useState(false);

  const propertyAddressField = {
    property: {
      address: {
        label: translate('Common:address'),
        type: 'text',
      },
      unitNumber: {
        label: translate('Common:unitNumber'),
        type: 'text',
      },
      city: {
        label: translate('Common:city'),
        type: 'text',
      },
      zipCode: {
        label: translate('Common:postalCode'),
        type: 'number',
      },
      state: {
        label: translate('Common:state'),
        type: 'text',
      },
      country: {
        label: translate('Common:country'),
        type: 'select',
      },
      latitude: {
        label: translate('Common:latitude'),
        type: 'text',
      },
      longitude: {
        label: translate('Common:longitude'),
        type: 'text',
      },
      additionalDetails: {
        label: translate('Common:additionalDetails'),
        type: 'text',
      },
    },
  };

  function addressSearching(event) {
    const textSearch = event.target.value?.trim();
    clearTimeout(timer);
    if (!textSearch) {
      setIsSearchingPlace(false);
      return setSearchResults(null);
    }
    setIsSearchingPlace(true);
    const { searchPlaces } = props;
    // eslint-disable-next-line no-return-assign
    return (timer = setTimeout(async function () {
      const response = await searchPlaces(
        textSearch,
        placeSearchOptions
      ).catch((error) => ({ error }));
      setIsSearchingPlace(false);
      if (response && response.error) return showErrorMessage(response.error);
      if (response && response.status !== 200)
        return showErrorMessage(response.data && response.data.message);
      const { predictions } = response.data;
      setSearchResults(predictions);
    }, SEARCH_TIMEOUT));
  }

  async function chooseLocation(event, location) {
    event.preventDefault();
    const { getPlaceDetails } = props;
    const response = await getPlaceDetails(
      location.place_id
    ).catch((error) => ({ error }));
    if (response && response.error) return showErrorMessage(response.error);
    if (response && response.status !== 200)
      return showErrorMessage(
        response && response.data && response.data.message
      );
    const { result } = response.data;
    const locationDetail = parsePlaceDetails(result);
    handleSelectLocation(locationDetail);
    setSearchResults(null);
  }

  function handleClickAway() {
    setSearchResults(null);
  }

  return (
    <>
      <Grid
        item
        container
        direction="row"
        spacing={1}
        className={classes.formContainer}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          className={classes.addressContainer}
        >
          <Field
            name={defineFieldName('address', nameField)}
            component={TextInput}
            label={propertyAddressField.property.address.label}
            type={propertyAddressField.property.address.type}
            margin="dense"
            required
            variant="outlined"
            autoComplete="nickname"
            onChange={(e) => addressSearching(e)}
            InputProps={{
              endAdornment: isSearchingPlace && (
                <CircularProgress size={25} position="end" color="secondary" />
              ),
            }}
          />

          {searchResults && !!searchResults.length > 0 && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className={classes.locationSuggestion}>
                <List className={classes.searchResults}>
                  {searchResults.map((item) => {
                    return (
                      <ListItem
                        key={item.place_id}
                        className={classes.itemAddress}
                        onClick={(e) => chooseLocation(e, item)}
                      >
                        <ListItemText
                          primary={
                            item.structured_formatting &&
                            item.structured_formatting.main_text
                          }
                          secondary={
                            item.structured_formatting &&
                            item.structured_formatting.secondary_text
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <Box
                  alignItem="center"
                  display="flex"
                  justifyContent="center"
                  p={1}
                >
                  <Typography variant="subtitle1" className={classes.poweredBy}>
                    {translate('Common:poweredBy')}
                  </Typography>
                  <img className="ml_5" src={GoogleSrc} alt="GoogleSrc" />
                </Box>
              </div>
            </ClickAwayListener>
          )}
          {searchResults && searchResults.length === 0 && (
            <div className={classes.locationSuggestion}>
              <List className={classes.searchResults}>
                <ListItem>{translate('Location:noResultFound')}</ListItem>
              </List>
            </div>
          )}
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Field
            name={defineFieldName('unitNumber', nameField)}
            component={TextInput}
            label={propertyAddressField.property.unitNumber.label}
            type={propertyAddressField.property.unitNumber.type}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Field
            name={defineFieldName('city', nameField)}
            component={TextInput}
            label={propertyAddressField.property.city.label}
            type={propertyAddressField.property.city.type}
            margin="dense"
            required
            variant="outlined"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Field
            name={defineFieldName('zipCode', nameField)}
            component={TextInput}
            label={propertyAddressField.property.zipCode.label}
            type={propertyAddressField.property.zipCode.type}
            margin="dense"
            variant="outlined"
            required
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Field
            name={defineFieldName('state', nameField)}
            component={TextInput}
            label={propertyAddressField.property.state.label}
            type={propertyAddressField.property.state.type}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Field
            name={defineFieldName('countryCode', nameField)}
            component={TextInput}
            label={propertyAddressField.property.country.label}
            select
            SelectProps={{ native: true }}
            margin="dense"
            required
            variant="outlined"
          >
            <option key="" value="">
              {' '}
            </option>
            {countryCodeOptions &&
              countryCodeOptions.length &&
              countryCodeOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
          </Field>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Field
            name={defineFieldName('additionalDetails', nameField)}
            component={TextInput}
            label={propertyAddressField.property.additionalDetails.label}
            type={propertyAddressField.property.additionalDetails.type}
            margin="dense"
            variant="outlined"
            multiline
            rows="4"
          />
        </Grid>
      </Grid>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  searchPlaces: (textSearch, opts) => dispatch(searchPlaces(textSearch, opts)),
  getPlaceDetails: (placeId) => dispatch(getPlaceDetails(placeId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(PropertyAddressForm);

import React from 'react';
import styles from './tabItem.module.scss';
import { Field, Fields } from 'redux-form';

import { Typography, Grid, Box, Tooltip } from '@material-ui/core';
import RenderField from '../renderField';
import CopyButton from '../utils/copyBtn';
import InfoIcon from '@material-ui/icons/Info';
import CalendarSrc from 'assets/images/enterprise/calendar.png';
import { StatusChip } from 'pages/Dashboard/components';
import moment from 'moment';
import { CollectedIndicator } from 'pages/Dashboard/components/Indicator';
import { getDateTimeFormat } from 'lib/formatter';
import { EnterpriseStatusChip } from 'pages/Dashboard/components/EnterpriseStatuschip';

const RenderFields = (props) => {
  const { label, t } = props;
  const start = props[props.names[0]].input.value;
  const end = props[props.names[1]].input.value;

  if (!start || !end) return null;
  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography className={styles.formTitle}>{label}</Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="h5" className={styles.boldText}>
          {start}
        </Typography>
        <Box mx={1}>
          <Typography className={styles.formTitle}>{t('to')}</Typography>
        </Box>
        <Typography variant="h5" className={styles.boldText}>
          {end}
        </Typography>
      </Box>
    </Box>
  );
};

const RenderStatusChip = ({ input, label }) => {
  if (!input.value) return null;
  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography className={styles.formTitle}>{label}</Typography>
      <Box className={styles.statusChip} display="flex" alignItems="center">
        <EnterpriseStatusChip groupStatusFilter={input.value} />
      </Box>
    </Box>
  );
};

const RenderCurrentVisit = ({ input, t }) => {
  if (!input.value) return null;
  return (
    <Box
      mt={3}
      p={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={styles.currentVisitWrapper}
    >
      <Box display="flex">
        <img src={CalendarSrc} alt="calendarSrc" />
        <Box ml={2} display="flex" flexDirection="column">
          <Typography variant="h5">{t('currentVisit')}</Typography>
          <Typography variant="subtitle1" className={`${styles.subtitle} mt_5`}>
            {moment(input.value.start).format(getDateTimeFormat())}
          </Typography>
          <Typography variant="subtitle1" className={styles.subtitle}>
            ID: {input.value.publicId}
          </Typography>
        </Box>
      </Box>
      <Box>
        <StatusChip job status={input.value.statusId} />

        <Tooltip className="ml_5" title={t('currentVisitToolTip')}>
          <InfoIcon />
        </Tooltip>
      </Box>
    </Box>
  );
};

const GeneralInfo = ({ t, jobPublicId, isHideJobId, isCollected }) => {
  return (
    <Grid>
      <Typography variant="h4" className={styles.generalInfo}>
        {t('generalInfo')}
      </Typography>

      <Field name="currentVisit" component={RenderCurrentVisit} t={t} />

      <Box display="flex" flexDirection="column">
        <Grid container a>
          {!isHideJobId && (
            <Grid container item sm={6} md={6} alignItems="flex-end">
              <Field
                label={t('jobId')}
                name="jobId"
                component={RenderField}
                t={t}
                isBoldText
              />

              <CopyButton t={t} jobId={jobPublicId} />
            </Grid>
          )}
          <Grid item sm={6} md={6}>
            <Box display="flex" alignItems="flex-end">
              <Field
                label={t('status')}
                name="groupStatusFilter"
                component={RenderStatusChip}
                isBoldText
              />
              <Box pl={0.5}>
                <Tooltip title={t('statusDescription')}>
                  <InfoIcon style={{ color: '#D0D0D0' }} />
                </Tooltip>
              </Box>
              <Box pl={1}>
                {isCollected && <CollectedIndicator />}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Field
          label={t('jobTitle')}
          name="jobTitle"
          component={RenderField}
          t={t}
          isBoldText
        />
        <Field
          label={t('dateCreated')}
          name="dateCreated"
          component={RenderField}
          t={t}
          isBoldText
        />
        <Fields
          label={t('schedule')}
          names={['scheduleStart', 'scheduleEnd']}
          component={RenderFields}
          t={t}
        />
        <Field
          label={t('location')}
          name="location"
          component={RenderField}
          t={t}
        />
        <Field
          label={t('jobDescription')}
          name="jobDescription"
          component={RenderField}
          t={t}
        />
      </Box>
    </Grid>
  );
};

export default GeneralInfo;

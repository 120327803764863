import React from 'react';
import { useDispatch } from 'react-redux';
// Material components
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';

import { addEntepriseProduct, updateEntepriseProduct } from 'redux/enterprise';
import { showErrorMessage } from 'lib/notifier';
// Material icons
import { Close as CloseIcon } from '@material-ui/icons';

// Component styles
import styles from './styles.module.scss';
import ServiceForm from './serviceForm';

function ServiceModal(props) {
  const {
    t,
    selectedRow,
    setSelectedRow,
    setIsOpenModal,
    isServiceForm,
    enterpriseId,
    tableRef,
  } = props;

  const dispatch = useDispatch();

  const handleClose = () => {
    setIsOpenModal(false);
    setSelectedRow('');
  };

  const handleSubmit = async (values) => {
    const {
      name,
      typeId,
      description,
      unitPrice,
      currency,
      id,
      model,
      isUpdateForm,
    } = values;
    const payload = {
      name,
      typeId,
      description,
      unitPrice,
      currency,
      model,
      enterpriseId,
    };
    const response = await dispatch(
      isUpdateForm
        ? updateEntepriseProduct(id, payload)
        : addEntepriseProduct(payload)
    ).catch((e) => ({ e }));

    if (response.e) return showErrorMessage(response.e);
    handleClose();
    tableRef && tableRef.current.onQueryChange({ page: 0 });
  };

  return (
    <Dialog onClose={handleClose} open fullWidth>
      <DialogTitle disableTypography className={styles.title_wrapper}>
        <Typography variant="h5">
          {isServiceForm ? (
            <>
              {t(
                selectedRow ? 'Product:updateService' : 'Product:addNewService'
              )}
            </>
          ) : (
            <>
              {t(
                selectedRow ? 'Product:updateProduct' : 'Product:addNewProduct'
              )}
            </>
          )}
        </Typography>

        <IconButton aria-label="Close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ServiceForm
          isServiceForm={isServiceForm}
          t={t}
          handleClose={handleClose}
          onSubmit={handleSubmit}
          selectedRow={selectedRow}
          tableRef={tableRef}
          enterpriseId={enterpriseId}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ServiceModal;

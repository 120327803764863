import { MenuItem } from '@material-ui/core';
import { ALL_VISIT_STATUS } from 'common/constant';
import React from 'react';

const ListMenuItem = ({
  t,
  currentVisitStatus,
  completedReviewConfirm,
  cancelReviewConfirm,
}) => {
  switch (currentVisitStatus) {
    case ALL_VISIT_STATUS.COMPLETED:
      return (
        <>
          <MenuItem
            onClick={completedReviewConfirm}
            className="review-menu-item"
          >{t('confirmCompleteClose')}</MenuItem>
          <MenuItem
            onClick={cancelReviewConfirm}
            className="review-menu-item"
          >{t('cancelClose')}</MenuItem>
        </>
      );
    case ALL_VISIT_STATUS.CANCELLED:
      return (
        <>
          <MenuItem
            onClick={cancelReviewConfirm}
            className="review-menu-item"
          >{t('confirmCancelClose')}</MenuItem>
          <MenuItem
            onClick={completedReviewConfirm}
            className="review-menu-item"
          >{t('completeClose')}</MenuItem>
        </>
      );
    default:
      return (
        <>
          <MenuItem
            onClick={completedReviewConfirm}
            className="review-menu-item"
          >{t('confirmCompleteClose')}</MenuItem>
          <MenuItem
            onClick={cancelReviewConfirm}
            className="review-menu-item"
          >{t('confirmCancelClose')}</MenuItem>
        </>
      );
  }
};

export default ListMenuItem;
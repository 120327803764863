export default values => {
  let errors = { message: {} };

  const itemErrors = [];
  if (!(values.client && Object.keys(values.client).length)) {
    errors = {
      ...errors,
      subject: 'required',
      message: {
        ...errors.message,
        subject: 'selectClient',
      }
    };
  }

  if (values.items && values.items.length) {
    values.items.forEach((item, itemIndex) => {

      itemErrors[itemIndex] = {};
      if (!item.name) {
        itemErrors[itemIndex] = {
          ...itemErrors[itemIndex],
          name: 'required',
          message: {
            ...itemErrors[itemIndex].message,
            name: 'setItemName',
          }
        };
      }
      if (!item.quantity || item.quantity <= 0) {
        itemErrors[itemIndex] = {
          ...itemErrors[itemIndex],
          quantity: 'positiveQuantity',
          message: {
            ...itemErrors[itemIndex].message,
            quantity: 'positiveQuantity',
          }
        };
      }

      if (!item.unitPrice || item.unitPrice < 0) {
        itemErrors[itemIndex] = {
          ...itemErrors[itemIndex],
          unitPrice: 'positiveUnitCost',
          message: {
            ...itemErrors[itemIndex].message,
            unitPrice: 'positiveUnitCost',
          }
        };
      }

      if (!item.total || item.total < 0) {
        itemErrors[itemIndex] = {
          ...itemErrors[itemIndex],
          total: 'positiveTotal',
          message: {
            ...itemErrors[itemIndex].message,
            total: 'positiveTotal',
          }
        };
      }
    });
  }

  if ((values.discountType === '%' && parseFloat(values.discountValue || 0) > 100)
    || values.discountValue === '') {
    errors = {
      ...errors,
      discountValue: 'invalidDiscount',
      message: {
        ...errors.message,
        discountValue: 'invalidDiscount',
      }
    };
  }

  if (values.depositValue === '') {
    errors = {
      ...errors,
      depositValue: 'invalidDeposit',
      message: {
        ...errors.message,
        depositValue: 'invalidDeposit',
      }
    };
  }

  if (itemErrors.length > 0) {
    errors.items = itemErrors;
  }

  return errors;
};

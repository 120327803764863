import React from 'react';
import { getStatusButtonReview } from './hook';
import { useHandleModal } from 'hook/modal';
import ReviewButton from './components/ReviewButton';
import './styles.scss';
import { NewVisitModal } from '../../../components/modals';
import { useTranslation } from 'react-i18next';
import { BackdropCustom, useBackdrop } from 'lib/loading';
import { ConfirmReviewModal } from './components/ReviewDialog';
import { useDispatch } from 'react-redux';
import { setOpenConfirmReviewModal } from 'redux/jobVerification';

export const HandleReviewModal = ({ job, refetchJobDetail, afterSaveVisitDetails }) => {
  const { t: translate } = useTranslation('Job');
  const [openBackdrop, withLoading]= useBackdrop();
  const { verified, startVerifiedAt, currentVisit, publicId } = job;
  const dispatch = useDispatch();
  const { open: isOpenNewVisit, openModal: openNewVisitModal, setModal } = useHandleModal();
  const status = getStatusButtonReview({ verified, startVerifiedAt });
  const openModal = () => {
    dispatch(setOpenConfirmReviewModal(true));
  };

  const setOpenNewVisitModal = () => {
    openNewVisitModal();
    dispatch(setOpenConfirmReviewModal(false));
  };

  return (
    <>
      <ReviewButton
        openModal={openModal}
        status={status}
        currentVisitStatus={currentVisit?.statusId}
        jobId={publicId}
        refetchJobDetail={refetchJobDetail}
        afterSaveVisitDetails={afterSaveVisitDetails}
        withLoading={withLoading}
        hasPendingVisit={job.hasPendingVisit}
      />
      <ConfirmReviewModal
        currentVisit={currentVisit}
        jobId={publicId}
        openNewVisitModal={setOpenNewVisitModal}
        refetchJobDetail={refetchJobDetail}
        afterSaveVisitDetails={afterSaveVisitDetails}
        withLoading={withLoading}
      />
      <NewVisitModal
        isOpen={isOpenNewVisit}
        setIsOpenEditModal={setModal}
        job={job}
        translate={translate}
        afterSaveVisitDetails={afterSaveVisitDetails}
      />
      <BackdropCustom open={openBackdrop} />
    </>
  );
};
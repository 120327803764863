import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import AuthPage from 'pages/AuthPage';
import BlogPage from 'pages/BlogPage';
import ClientTracking from 'pages/ClientTracking';
import ContactPage from 'pages/ContactPage';
import FeaturePage from 'pages/FeaturePage';
import PricingPage from 'pages/PricingPage';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import ShortUrlPage from 'pages/ShortUrlPage';
import StaffInvitation from 'pages/StaffInvitation';
import TermOfService from 'pages/TermOfService';
import SubscriptionAgreement from '../pages/SubscriptionAgreement';
import { RegisterInterest, ContactUs } from 'pages/MarketingPages';
import { NotFound } from 'pages/ErrorPage';

export const publicRouteList = [
  {
    path: '/',
    component: AuthPage,
    translationNameSpace: 'LandingPage',
  },
  // {
  //   path: '/sign-up',
  //   component: AuthPage,
  //   translationNameSpace: 'Auth'
  // },
  {
    path: '/log-in',
    component: AuthPage,
    translationNameSpace: 'Auth',
  },
  {
    path: '/term-of-service',
    component: TermOfService,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/subscription-agreement',
    component: SubscriptionAgreement,
  },
  {
    path: '/forgot',
    component: AuthPage,
    translationNameSpace: 'Auth',
  },
  {
    path: '/forgot/:id',
    component: AuthPage,
    translationNameSpace: 'Auth',
  },
  {
    path: '/tracking',
    component: ClientTracking,
    translationNameSpace: 'Tracking',
  },
  {
    path: '/staff-invitation',
    component: StaffInvitation,
    translationNameSpace: 'StaffInvitation',
  },
  {
    path: '/features',
    component: FeaturePage,
    translationNameSpace: 'LandingPage',
  },
  {
    path: '/pricing',
    component: PricingPage,
    translationNameSpace: 'LandingPage',
  },
  {
    path: '/blog',
    component: BlogPage,
    translationNameSpace: 'LandingPage',
  },
  {
    path: '/contact',
    component: ContactPage,
    translationNameSpace: 'LandingPage',
  },
  {
    path: '/register-interest-1',
    component: RegisterInterest,
    translationNameSpace: 'LandingPage',
  },
  {
    path: '/contact-us',
    component: ContactUs,
    translationNameSpace: 'LandingPage',
  },
  {
    path: '/s/:id',
    component: ShortUrlPage,
  },
  {
    path: '/not-found',
    component: NotFound,
  },
];

const routeComponents = publicRouteList.map(
  ({ path, component, translationNameSpace }, key) => {
    const componentWithTranslations = withTranslation(translationNameSpace)(
      component
    );

    return (
      <Route
        exact
        path={path}
        component={componentWithTranslations}
        key={key}
      />
    );
  }
);

export default () => {
  return (
    <Switch>
      {routeComponents}
      <Redirect to='/log-in' />
    </Switch>
  );
};

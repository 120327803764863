import React from 'react';
import { Box, Typography, Card } from '@material-ui/core';
import styles from './card.module.scss';

const CardCustom = ({ title, children, customCardStyle }) => {
  return (
    <Card className={`${styles.card} ${customCardStyle}`}>
      <Box p={2} className={styles.borderBottom}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box p={2}>{children}</Box>
    </Card>
  );
};

export default CardCustom;

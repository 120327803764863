import { ValidationScheduleDatetime } from 'components/validations';

export default values => {
  let errors = { message: {} };

  // if (!values.title) {
  //   errors = {
  //     ...errors,
  //     title: 'required',
  //     message: {
  //       ...errors.message,
  //       title: 'inputJobTitle',
  //     }
  //   };
  // }

  // Client && Property Check
  const selectedClientPresence = (!values.selectedClient || Object.keys(values.selectedClient).length < 1);
  const selectedPropertyPresence = (!values.selectedProperty || Object.keys(values.selectedProperty).length < 1);
  if (selectedClientPresence && selectedPropertyPresence) {
    errors = {
      ...errors,
      message: {
        ...errors.message,
        selectedClient: 'noClientSelected',
        selectedProperty: 'noPropertySelected'
      }
    };
  } else if (selectedClientPresence) {
    errors = {
      ...errors,
      message: {
        ...errors.message,
        selectedClient: 'noClientSelected',
        client: 'clientNotFound'
      }
    };
  } else if (selectedPropertyPresence) {
    errors = {
      ...errors,
      message: {
        ...errors.message,
        selectedProperty: 'noPropertySelected',
        property: 'propertyNotFound'
      }
    };
  };

  // Validate date and time for job
  errors = ValidationScheduleDatetime(values, errors);

  return errors;
};

import React from 'react';
import { HubTable } from 'pages/Dashboard/components';
import { Box } from '@material-ui/core';

const HubList = (props) => {
  return (
    <Box px={3} py={1}>
      <HubTable {...props} />
    </Box>
  );
};

export default HubList;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Typography,
  Grid,
  Box,
  ButtonGroup,
  Button,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { showErrorMessage } from 'lib/notifier';
import { ItemsForm } from './components';
import { getJobItems, jobActions } from 'redux/job';

import styles from './styles.module.scss';

import downloadSrc from 'assets/images/icons/download.svg';

const ItemList = (props) => {
  const { translate, jobId, job, fetchJobDetails } = props;
  const dispatch = useDispatch();

  const [itemDetails, setItemDetails] = useState([]);
  const [selectedButton, setSelectedButton] = useState(0);
  const [
    isShowingItemCollectedModal,
    setIsShowingItemCollectedModal,
  ] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await dispatch(getJobItems(jobId)).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
      setItemDetails((result.data && result.data.data) || []);
    })();
  }, [dispatch, getJobItems]);

  const handleClickDownload = async () => {
    const result = await dispatch(
      getJobItems(jobId, { exportXlsx: 1 })
    ).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);

    if (result.data && result.data.file) {
      return window.open(result.data.file, '_blank');
    }

    showErrorMessage(translate('Common:someThingWentWrong'));
  };

  const handleClickBtn = (number = 0) => setSelectedButton(number);

  const handleOpenItemCollectedModal = () =>
    setIsShowingItemCollectedModal(true);

  const handleCloseItemCollectedModal = () =>
    setIsShowingItemCollectedModal(false);

  const handleConfirmChangeItemCollected = async () => {
    const action = job.isCollected ? 'MARK_UNCOLLECTED' : 'MARK_COLLECTED';
    const result = await dispatch(jobActions(jobId, action)).catch((e) => ({
      e,
    }));
    if (result.e) return showErrorMessage(result.e);
    handleCloseItemCollectedModal();
    fetchJobDetails();
  };

  return (
    <>
      <Dialog
        open={isShowingItemCollectedModal}
        onClose={handleCloseItemCollectedModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography variant="h4" className="mb_5">
            {translate(
              job.isCollected
                ? 'markItemUncollectedTitle'
                : 'markItemCollectedTitle'
            )}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseItemCollectedModal} variant="outlined">
            {translate('Common:close')}
          </Button>
          <Button
            onClick={handleConfirmChangeItemCollected}
            className={styles.confirm_change_collected}
            variant="contained"
            autoFocus
          >
            {translate('Common:confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box p={3}>
        <Grid container justify="space-between">
          <Box display="flex" alignItems="center">
            <FormControlLabel
              classes={{ root: styles.form_label }}
              control={
                <Checkbox
                  checked={job.isCollected}
                  onChange={handleOpenItemCollectedModal}
                  classes={{
                    checked: styles.checked_state,
                  }}
                />
              }
              label={translate('markItemsCollected')}
            />
          </Box>
          <Box display="flex">
            <Box display="flex" alignItems="center">
              <Typography className="mr_10">{translate('viewAs')}</Typography>

              <ButtonGroup
                variant="contained"
                aria-label="contained primary button group"
                classes={{ root: styles.btn_group }}
              >
                <Button
                  onClick={() => handleClickBtn(0)}
                  disabled={selectedButton === 0}
                >
                  <Typography>{translate('serviceCard')}</Typography>
                </Button>
                <Button
                  onClick={() => handleClickBtn(1)}
                  disabled={selectedButton === 1}
                >
                  <Typography>{translate('serviceOnly')}</Typography>
                </Button>
                <Button
                  onClick={() => handleClickBtn(2)}
                  disabled={selectedButton === 2}
                >
                  <Typography>{translate('itemOnly')}</Typography>
                </Button>
              </ButtonGroup>
            </Box>
            <Box display="flex" ml={3}>
              <ButtonGroup
                classes={{ root: styles.btn_group }}
                variant="contained"
                aria-label="contained primary button group"
              >
                <Button onClick={handleClickDownload}>
                  <img src={downloadSrc} alt="download_src" />
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Grid>

        <ItemsForm
          translate={translate}
          itemDetails={itemDetails}
          selectedButton={selectedButton}
        />
      </Box>
    </>
  );
};

export default ItemList;

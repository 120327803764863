import React from "react";

// Externals
import classNames from "classnames";
import i18n from "i18next";
import { connect } from "react-redux";
import { getConfigs } from "redux/config";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Button, Menu, MenuItem, MenuList } from "@material-ui/core";

import {
  ExpandMore as ExpandMoreIcon,
  Public as PublicIcon,
} from "@material-ui/icons";

import iconSrc from "assets/images/language_icon.svg";

const styles = (theme) => ({
  moreIcon: {
    marginRight: theme.spacing(1),
  },
  sectionTitle: {
    color: theme.palette.text.primary,
    opacity: 0.7,
    paddingLeft: 4,
    paddingTop: 4,
    paddingBottom: 4,
  },
  popoverWithArrow: {
    "& .MuiPopover-paper": {
      padding: 8,
      minWidth: 180,
      marginTop: 10,
      overflowX: "initial",
      overflowY: "initial",
      backgroundColor: theme.palette.background.muted2,
      borderColor: theme.palette.background.muted2,
      borderWidth: 1,
      borderStyle: "solid",
      "&:before": {
        content: '""',
        position: "absolute",
        right: 20,
        top: -9,
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "0 20px 20px 20px",
        borderColor: `transparent transparent ${theme.palette.background.muted2} transparent`,
        zIndex: 9998,
      },
    },
  },
  btnStyle: {
    // minWidth: 120,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 4,
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  globeIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
});

const ChangeLanguageMenu = (props) => {
  const {
    classes,
    getConfigs,
    fromLandingPage,
    marginTop = false,
    selectedLanguage = "en-US",
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const languages = {
    "en-US": "English",
  };

  return (
    <>
      <Button
        aria-controls="change-language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classNames(classes.btnStyle, {
          [classes.marginTop]: marginTop,
        })}
      >
        {fromLandingPage ? (
          <img src={iconSrc} className={classes.globeIcon} alt="icon" />
        ) : (
          <PublicIcon className={classes.globeIcon} />
        )}

        {languages[selectedLanguage] && languages[selectedLanguage]}
        {fromLandingPage && <ExpandMoreIcon />}
      </Button>
      <Menu
        id="change-language-menu"
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        className={classNames(classes.arrow, classes.popoverWithArrow)}
      >
        {languages &&
          Object.keys(languages).length > 0 &&
          Object.keys(languages).map((language, index) => {
            const lang = languages[language];
            return (
              <MenuList key={index}>
                <MenuItem
                  disabled={language === selectedLanguage}
                  onClick={() => {
                    i18n.changeLanguage(language);
                    getConfigs();
                    handleClose();
                  }}
                >
                  {lang}
                </MenuItem>
              </MenuList>
            );
          })}
      </Menu>
    </>
  );
};

export default connect(null, { getConfigs })(
  withStyles(styles)(ChangeLanguageMenu)
);

import React, { useState, useEffect } from 'react';

// redux
import { getStaff, updateStaff } from 'redux/staff';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import { showErrorMessage } from 'lib/notifier';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  IconButton,
  DialogActions,
  Button,
} from '@material-ui/core';
import EditUserDetailsForm from '../form/enterpriseUser';
import { Close as CloseIcon } from '@material-ui/icons';
import ConfirmChangeOwnerModal from './confirmChangingOwner';
import styles from '../../details.module.scss';

const FORM_NAME = 'EnterpriseUser';
const OWNER_ROLE = 'OWNER';
const ADMIN_ROLE = 'ADMIN';

const UserDetails = ({
  t,
  openEditUserDetailsModal,
  handleClose,
  selectedStaff = {},
  companyDetails = {},
  refetchTable,
}) => {
  const dispatch = useDispatch();
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [isOpenChangingModal, setIsOpenChangeModal] = useState(false);
  const [isChangingOwner, setIsChangingOwner] = useState(false);
  const [staffDetails, setStaffDetails] = useState({});

  useEffect(() => {
    if (!selectedStaff.id) return;
    (async () => {
      const response = await dispatch(
        getStaff(selectedStaff.id, { enterpriseAccountId: companyDetails.id })
      ).catch((e) => ({ e }));
      if (response.e) return showErrorMessage(response.e);
      setStaffDetails(response.data);
    })();
  }, [selectedStaff]);

  const handleCloseConfirm = () => setIsOpenChangeModal(false);

  const userConfirmed = async () => {
    handleCloseConfirm();
    dispatch(submit(FORM_NAME));
  };

  const handleSubmitEditUser = () => {
    if (!isChangingOwner) return userConfirmed();
    return setIsOpenChangeModal(true);
  };

  const handleSubmit = async (values) => {
    const payload = {
      ...staffDetails,
      archived: !values.accountStatus,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      notes: values.notes,
      countryPhoneCode: values.countryPhoneCode,
      phoneNumber: values.phoneNumber,
      enterpriseAccountId: companyDetails.id,
      isContactPerson: values.isContactPerson,
      roleId: values.isOwner ? OWNER_ROLE : ADMIN_ROLE,
      categoryIds: values.categories.map((category) => category.id)
    };

    const response = await dispatch(
      updateStaff(selectedStaff.id, { staffInfo: payload })
    ).catch((e) => ({ e }));

    if (response.e) return showErrorMessage(response.e);
    handleClose();
    // update table after change staffInfo
    refetchTable && refetchTable();
  };

  return (
    <>
      <ConfirmChangeOwnerModal
        t={t}
        openConfirmModal={isOpenChangingModal}
        handleClose={handleCloseConfirm}
        userConfirmed={userConfirmed}
      />
      <Dialog
        open={openEditUserDetailsModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: styles.dialogClass }}
      >
        <DialogTitle className={styles.borderBottom}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h4">{t('editEnterpriseUser')}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent className={styles.detailsContent}>
          <Box display="flex" flexDirection="row">
            <EditUserDetailsForm
              onSubmit={handleSubmit}
              userDetails={staffDetails}
              companyDetails={companyDetails}
              t={t}
              setIsReadyToSubmit={setIsReadyToSubmit}
              setIsChangingOwner={setIsChangingOwner}
            />
          </Box>
        </DialogContent>
        <DialogActions className={styles.borderTop}>
          <Button
            onClick={handleSubmitEditUser}
            disabled={!isReadyToSubmit}
            className={styles.createNewEnterprise}
            autoFocus
          >
            {t('Common:save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDetails;

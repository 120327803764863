import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import styles from './jobHeader.module.scss';
import { Field } from 'redux-form';
import { RenderField } from 'components/view';

import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import CalendarSrc from 'assets/images/enterprise/calendar.png';

const RenderFieldVisit = ({
  input,
  valueVariant = 'title1',
  t,
  customStyle,
}) => {
  if (!input.value) return null;
  return (
    <Box display="flex" flexDirection="row">
      <Typography className={customStyle} variant={valueVariant}>
        {t('id')}: {input.value}
      </Typography>
    </Box>
  );
};

const JobHeader = ({ t }) => {
  return (
    <Box
      className={styles.header_bg}
      p={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex">
        <Box mr={2}>
          <img src={CalendarSrc} alt="CalendarSrc" />
        </Box>
        <Grid>
          <Box>
            <Typography className="font_bold">{t('currentVisit')}</Typography>

            <Box mt={1}>
              <Field
                name="visitTime"
                component={RenderField}
                valueVariant="subtitle1"
                customStyle={styles.grey_text}
              />
              <Field
                name="visitPublicId"
                component={RenderFieldVisit}
                valueVariant="subtitle1"
                label="id"
                customStyle={styles.grey_text}
                t={t}
              />
            </Box>
          </Box>
        </Grid>
      </Box>
      <Grid>
        <InfoOutlinedIcon style={{ fontSize: 40 }} />
      </Grid>
    </Box>
  );
};

export default JobHeader;

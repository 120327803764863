import React from "react";

// Externals
import classNames from "classnames";
import { Draggable } from "react-beautiful-dnd";

// Material components
import {
  Grid,
  ListItem,
  ListItemText,
  ListItemIcon,
  withStyles,
} from "@material-ui/core";

// Material icons
import { Sort as SortIcon } from "@material-ui/icons";

// Component styles
import styles from "./styles";
import { formatPrice } from "lib/formatter";

export default withStyles(styles)((props) => {
  const {
    classes,
    className,
    handleClick,
    product,
    index,
    isDragging,
    ...rest
  } = props;
  const {
    name,
    description,
    currency,
    unitPrice,
    taxRate,
    sortOrder,
    taxComponent,
    model,
  } = product;

  const draggableId = (sortOrder - 1).toString();

  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <ListItem
          {...rest}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={handleClick}
          className={classNames({
            [classes.root]: true,
            [classes.isDragging]: snapshot.isDragging,
          })}
        >
          <Grid container>
            <Grid item md={1} sm={12}>
              <ListItemIcon>
                <SortIcon />
              </ListItemIcon>
            </Grid>
            <Grid item md={3} sm={12}>
              <ListItemText className={classes.prodName} primary={name} />
            </Grid>
            <Grid item md={3} sm={12}>
              <ListItemText
                primary={product && description ? description : null}
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <ListItemText primary={product && model ? model : null} />
            </Grid>
            <Grid item md={2} sm={12}>
              <ListItemText
                style={{ textAlign: "left" }}
                primary={
                  product && unitPrice
                    ? formatPrice(unitPrice, currency)
                    : "N/A"
                }
              />
            </Grid>
            <Grid item md={1} sm={12}>
              <ListItemText
                style={{ textAlign: "center" }}
                primary={(product && taxRate && taxRate.name) || "N/A"}
              />
            </Grid>
          </Grid>
        </ListItem>
      )}
    </Draggable>
  );
});

export default {
  // common && crumbs
  timesheet: 'Timesheet',
  clockIn: 'Clock In',
  clockOut: 'Clock Out',
  viewDetails: 'View Details',
  jobID: 'Job ID',
  jobTitle: 'Job Title',
  timesheetHours: 'Timesheet Hours (hh:mm)',
  status:	'Status',
  rating: 'Rating',
  startTime: 'Start Time',
  endTime: 'End Time',
  title: 'Title',
  startDate: 'Start Date',
  endDate: 'End Date',
  totalHours: 'Total Hours',
  staffList: 'Staff list',
  selectStaffToSeeTimesheet: 'Please select a staff from Staff List to view timesheet',
  lastMonth: 'Last month',
  thisMonth: 'This month',
  noTimesheetFound: 'No data recorded',
  overview: 'Overview',
  clockinout: 'Clock In / Out',
  visits: 'Visits',
  showLateOnly: 'Show late only',
  filter: 'Filter',
  duration: 'Duration (hh:mm)',
  actions: 'Actions',
  total: 'Total',
  add: 'Add',
  approve: 'Approve',
  decline: 'Decline',
  edit: 'Edit',
  delete: 'Delete',
  info: 'Counted from 00:00 to Clocked Out time',
  previousDay: 'PREVIOUS DAY',
  nextDay: 'NEXT DAY',
  exportTo: 'Export To',
  exports: 'Exports',
  jobId: 'Job ID',
  askConfirmationBeforeDelete: 'Do you want to delete ?',
  createClockInOut: 'Create new Clock In - Clock Out',
  editClockInOut: 'Edit Clock In - Clock Out',
  ontheway: 'On the way',
  arrived: 'Arrived',
  cancelled: 'Cancelled',
  start: 'Start',
  completed: 'Completed',
  outTimebeforInTime : 'Invalid date range',
  cancel: 'Cancel',
  cancelUpperCase: 'CANCEL',
  saveUpperCase: 'SAVE',
  createUpperCase: 'CREATE',
  createTimesheet: 'Create Timesheet',
  createTimesheetUpperCase: 'CREATE TIMESHEET',
  editVisits: 'Edit Visit Timesheet',
  createVisits: 'Create new Visit Timesheet',
  noNotes: 'No Notes To Display',
  late: 'Late',
  overtime: 'Overtime',
  visitTitle: 'Visit Title',
  processEditing: 'Process Editing',
  processCreating: 'Process Creating',
  selectJob: 'Select Job’s Visits to create timesheet',
  noVisit: 'The job has no visit for the staff you selected',
  Hrs: 'Hrs',
  Mins: 'Mins',
  jobFor: 'Job For',
  createSuccess: 'Create Successfully',
  deleteSuccess: 'Delete Successfully',
  updateSuccess: 'Update Successfully',
  noTimeValid: 'Please Fill Time',
  timeNotCorrect: 'Filled times not correct'
};
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Grid, Button, Typography } from "@material-ui/core";
import { forgotPassword } from "redux/auth";
import TextInput from "components/inputs/TextInput";
import styles from "./forgotPwdStyle.module.scss";
import MediaQuery from "react-responsive";
import Logo from "../../../../components/logo";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { EMAIL_VALIDATION } from "common/constant";

const imageBg = require("assets/images/office.jpg");
const imageBgMb = require("assets/images/office.jpg");

const mapStateToProps = (state) => ({
  initialValues: {
    email: null,
  },
});

const mapDispatchToProps = {
  forgotPassword,
};

const validate = (values) => {
  let errors = { message: {} };
  const requiredFields = ["email"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
      errors.message[field] = `${field}Required`;
    }
  });
  if (values.email && !EMAIL_VALIDATION.test(values.email)) {
    errors = {
      ...errors,
      email: "invalidEmail",
      message: {
        ...errors.message,
        email: "invalidEmail",
      },
    };
  }

  if (
    values.password &&
    !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/.test(values.password)
  ) {
    errors.password = "hintPassword";
    errors.message.password = "hintPassword";
  }
  return errors;
};

let ForgotPasswordPage = (props) => {
  const { pristine, invalid, submitting, handleSubmit, translate } = props;

  return (
    // <Container>
    <Grid
      item
      container
      wrap="wrap"
      justify="center"
      spacing={0}
      className={styles.forgetPwdContentContainer}
    >
      <Grid item xs={11} md={6} className={styles.leftPane}>
        <Grid className={styles.formContainer} container>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid className={styles.w_50}>
              <Logo />
              <Typography
                variant="subtitle1"
                className={styles.termsPrivacyText}
              >
                <Link className={styles.link} to="/log-in">
                  {translate("backSignIn")}
                </Link>
              </Typography>
              <Grid item className={styles.textAlignLeft}>
                <Typography className={styles.signInTitle}>
                  {translate("forgotPassword")}
                </Typography>
                <Typography className={styles.signInSubtitle}>
                  {translate("resetEmail")}
                </Typography>
              </Grid>
              <Grid item className={styles.signInForm}>
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field
                      name="email"
                      component={TextInput}
                      label={translate("Common:email")}
                      margin="normal"
                      variant="outlined"
                      className={styles.emailBox}
                    />
                  </div>
                  <div className={styles.signInButton}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={pristine || submitting || invalid}
                      className={styles.btnStyle}
                    >
                      <span className={styles.btnLabel}>
                        {translate("resetPassword")}
                      </span>
                    </Button>
                  </div>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MediaQuery query="(min-device-width: 768px)">
        <Grid item md={6} className={styles.rightPane}>
          <div className={styles.imageContainer}>
            <img
              src={imageBg}
              className={styles.cleanerImage}
              alt={translate("Common:WM")}
            />
          </div>
        </Grid>
      </MediaQuery>
      <MediaQuery query="(max-device-width: 768px)">
        <Grid item className={styles.rightPane}>
          <div className={styles.imageContainer}>
            <img
              src={imageBgMb}
              className={styles.cleanerImage}
              alt={translate("Common:WM")}
            />
          </div>
        </Grid>
      </MediaQuery>
    </Grid>
    // </Container>
  );
};

ForgotPasswordPage = reduxForm({
  form: "forgotPassword",
  validate,
})(ForgotPasswordPage);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);

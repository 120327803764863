import React, { useEffect } from 'react';

// Externals
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Redux
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { initialize, SubmissionError } from 'redux-form';
import { clearJob, getJob, updateJobDetails } from 'redux/job';

// Material components
import { Grid, withStyles } from '@material-ui/core';

// Custom components
import { JobValidation, modifyJobDataBeforeSubmission, initializeJobData } from '../components/utils';
import { JobForm } from '../components/forms';
import { showErrorMessage } from 'lib/notifier';
// Component styles
import styles from './style';

const mapStateToProps = state => ({
  clientName: state.job.job && state.job.job.selectedClient && state.job.job.selectedClient.name,
});

const UpdateJob = props => {
  const {
    classes,
    history,
    match: {
      params: {
        jobId,
      },
    },
    translate,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    // fetchJobDetails onMount
    dispatch(getJob(jobId)).then(rs => {
      dispatch(initialize('jobForm', initializeJobData(rs.data)));
    });
  }, []);

  function handleCancelJobForm() {
    dispatch(clearJob());
    history.push('/jobs');
  };

  function submitJobForm(values) {
    const errors = JobValidation(values);
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));

      throw new SubmissionError(errors);
    } else {
      values = modifyJobDataBeforeSubmission(values);
      dispatch(updateJobDetails(values)).then(response => {
        dispatch(clearJob());
        history.push(`/jobs/${jobId}`);
      }, error => {
        showErrorMessage(error);
      });
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify='center'>
        {/* <Grid
          item
          md={12}
          lg={12}
          xl={10}
          xs={12}
        >
          <JobsToolbar clientName={clientName} match={props.match} translate={translate} />
        </Grid> */}
        <Grid
          item
          md={12}
          lg={12}
          xl={10}
          xs={12}
        >
          <JobForm handleCancelJobForm={handleCancelJobForm} isEditForm onSubmit={values => submitJobForm(values)} translate={translate} />
        </Grid>
      </Grid>
    </div>
  );
};

UpdateJob.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, null)
)(UpdateJob);

const validate = values => {
console.log("values", values)
  let errors = { message: {} };

  if (!values.name) {
    errors = {
      ...errors,
      name: 'required',
      message: {
        ...errors.message,
        name: 'productName',
      }
    };
  }

  if (!values.model) {
    errors.model = 'required';
  }

  // Validate only positive float/number
  if (values.unitPrice &&
    !/^\d+(\.\d{1,2})?$/.test(values.unitPrice)
  ) {
    errors.unitPrice = 'invalid';
  }

  return errors;
};

export default validate;
import React from 'react';

import styles from '../../dashboard.module.scss';
import NoData from 'assets/images/dashboard/no-data.svg';
import { Box, Typography } from '@material-ui/core';

const Empty = ({ t }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      className={styles.emptyWrapper}
    >
      <img src={NoData} alt="NoData" />
      <Box
        mt={3}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="20%"
      >
        <Typography variant="h6" className={styles.noDataTitle}>{t('noData')}</Typography>
        <Typography align="center" className={styles.textGrey}>
          {t('noDataDescription')}
        </Typography>
      </Box>
    </Box>
  );
};

export default Empty;

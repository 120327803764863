import React, { useState, useEffect } from 'react';

// Externals
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, arraySplice } from 'redux-form';

// Material components
import { Typography, TextField, MenuItem, Paper, withStyles } from '@material-ui/core';

// Component styles
import { ServiceProductInputStyle } from './styles';
import { OnlyDecimal } from 'components/converters';

const mapStateToProps = (state, ownProps) => {
  const jobForm = formValueSelector(ownProps.formName || 'jobForm');

  return {
    formValue: jobForm(state, 'products'),
    formName: ownProps.formName || 'jobForm'
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addJobItem: (index, item) => {
      dispatch(
        arraySplice(ownProps.formName || 'jobForm', 'products', index, 1, item)
      );
    },
  };
};

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="dense"
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSectionTitle(section) {
  return (
    <MenuItem disabled style={{ backgroundColor: 'lightGray', fontSize: 14 }}>
      <strong>{section.title.toUpperCase()}</strong>
    </MenuItem>
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 600 : 500, fontSize: 14 }}
            >
              {part.text}
            </span>
          );
        })}
        <Typography style={{ color: 'gray' }}>
          {suggestion.value.description}
        </Typography>
      </div>
    </MenuItem>
  );
}

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(data, value) {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(escapedValue, 'i');

  return data
    .map((section) => {
      const matchedSuggestions = section.suggestions.filter((suggestion) =>
        regex.test(suggestion.label)
      );
      return {
        title: section.title,
        suggestions: matchedSuggestions,
      };
    })
    .filter((section) => section.suggestions.length > 0);
}

function getSectionSuggestions(section) {
  return section.suggestions;
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

const ServiceProductInput = (props) => {
  const {
    classes,
    input,
    meta: { error },
    formValue,
    options,
    index,
    translate,
    cardIndex,
  } = props;
  const [suggestions, setSuggestions] = useState([]);
  const { value } = input;
  useEffect(() => {
    suggestions.length < 1 && setSuggestions(options);
  }, [suggestions]);

  function handleSuggestionsFetchRequested(item) {
    const suggestionOptions = getSuggestions(options, item.value);

    setSuggestions(suggestionOptions);
  }

  const handleSuggestionsClearRequested = () => {
    setSuggestions(options);
  };

  const handleShouldRenderSuggestions = () => {
    return suggestions.length > 0;
  };

  const recalculate = (item) => {
    let sumTaxPercentage = 0;
    if (item.taxRate) {
      sumTaxPercentage = parseFloat(item.taxRate.percentage || 0);
    }

    if (item.taxComponent) {
      sumTaxPercentage = (item.taxComponent.taxRates || []).reduce(
        (sum, taxRate) => {
          return sum + parseFloat(taxRate.percentage || 0);
        },
        0
      );
    }
    const itemInitalTotal =
      parseFloat(item.quantity) * parseFloat(item.unitPrice);
    const itemTotalAfterDiscount = parseFloat(itemInitalTotal);
    const itemTaxAmount =
      (itemTotalAfterDiscount * parseFloat(sumTaxPercentage)) / 100;

    const totalIncludeTax = OnlyDecimal(itemInitalTotal + itemTaxAmount);

    const inventories = (item.inventories || []).map((inventory) => {
      if (!inventory.total || !inventory.unitPrice)
        return {
          ...inventory,
          total: '0.00',
        };
      return {
        ...inventory,
        total: OnlyDecimal(
          inventory.quantity * parseFloat(inventory.unitPrice)
        ),
      };
    });

    const calculatedSubtotal = OnlyDecimal(
      parseFloat(
        inventories.reduce((prev, current) => {
          return prev + parseFloat(current.total);
        }, 0)
      ) + parseFloat(totalIncludeTax)
    );
    item.calculatedSubtotal = calculatedSubtotal;
  };

  function handleUpdateItemFromSuggesstion(item) {
    if (!item) {
      return;
    }

    const unitPrice = item.unitPrice || '0.00';
    const taxRate = item.taxRate || {};
    const taxComponent = item.taxComponent || {};
    let itemTaxPercentage = 0;
    if (taxRate.percentage) {
      itemTaxPercentage = parseFloat(taxRate.percentage || 0);
    }
    if (taxComponent.taxRates) {
      itemTaxPercentage = (taxComponent.taxRates || []).reduce(
        (sum, taxItem) => {
          return sum + parseFloat(taxItem.percentage || 0);
        },
        0
      );
    }

    const taxAmount = (parseFloat(item.unitPrice) * itemTaxPercentage) / 100;
    const totalIncludeTax = parseFloat(item.unitPrice) + taxAmount;

    const existedItem = formValue && formValue[cardIndex];

    const newItem = {
      quantity: 1,
      name: item.name,
      unitPrice: OnlyDecimal(unitPrice),
      total: OnlyDecimal(totalIncludeTax),
      model: item.model,
    };
    existedItem.inventories.splice(index, 1, newItem);
    recalculate(existedItem);
  }

  const handleChange = () => (event, { newValue }) => {
    if (newValue === undefined) {
      return;
    }

    const item = newValue.value || newValue;

    if (item.constructor === Object) {
      handleUpdateItemFromSuggesstion(item);
    }

    input.onChange(item.name || item);
  };
  return (
    <div className={classes.root}>
      <Autosuggest
        multiSection
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        shouldRenderSuggestions={handleShouldRenderSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={renderInputComponent}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        inputProps={{
          classes,
          id: 'react-autosuggest-simple',
          placeholder: translate('Common:search'),
          value,
          onChange: handleChange(),
          error: !!error,
          helperText: error || null,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={(options) => (
          <Paper
            {...options.containerProps}
            square
            className={classes.suggestionTopModal}
          >
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
};

export default compose(
  withStyles(ServiceProductInputStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(ServiceProductInput);

import React, { createRef } from 'react';

// Externals
import classNames from 'classnames';
import SignatureCanvas from 'react-signature-canvas';
import dataURItoBlob from 'lib/dataURItoBlob';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// Component styles
import { SignatureModalStyle } from './styles';
import { uploadSignature } from 'redux/invoice';

const mapStateToProps = state => ({
  invoiceId: state.invoice.invoice.id,
});

const mapDispatchToProps = {
  uploadSignature,
};

const SignatureModal = props => {
  const {
    classes,
    invoiceId,
    open,
    handleClose,
    afterUploadSignature,
    uploadSignature,
  } = props;
  const signatureRef = createRef();

  const handleClear = () => signatureRef.current && signatureRef.current.clear();
  const handleSign = async () => {
    const image = signatureRef.current && signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    const blob = dataURItoBlob(image);
    const formData = new FormData();
    formData.append('file', blob);

    await uploadSignature(invoiceId, formData);
    afterUploadSignature();
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle disableTypography className={classNames(classes.root, classes.clientDialogTitle)}>
        <Typography variant="h6">Signature Pad</Typography>
        {open ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        <Grid container justify="center">
          <SignatureCanvas
            ref={signatureRef}
            canvasProps={{
              className: classes.sigCanvas
            }}
          />
          <Typography className={classes.signatureText}>
            Signature
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleSign}
        >
          Sign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(
  withStyles(SignatureModalStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(SignatureModal);
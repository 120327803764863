import moment from 'moment';

const payloadPreparation = (query, sortParams) => {
  // scheduledOn and createdOn structure will be [start, end]
  const scheduledOn = query.scheduledOn || [];
  const completionOn = query.completionTime || [];

  return {
    limit: query.pageSize,
    skip: query.page && query.pageSize && query.page * query.pageSize,
    searchString: query.search,
    sort: sortParams,
    includingArchived: query.includingArchived,
    staffIds: query.staffs && query.staffs.map((staff) => staff.value),
    statusIds:
      (query.status &&
        query.status !== 'ALL' &&
        (Array.isArray(query.status)
          ? query.status.map((item) => item.value)
          : query.status)) ||
      '',
    createdOnFrom:
      query.createdOn &&
      query.createdOn[0] &&
      moment(query.createdOn[0]).startOf('day'),
    createdOnTo:
      query.createdOn &&
      query.createdOn[1] &&
      moment(query.createdOn[1]).endOf('day'),
    completionTimeFrom:
      completionOn[0] && moment(completionOn[0]).startOf('day'),
    completionTimeTo:
      completionOn[1] && moment(completionOn[1]).endOf('day'),
    scheduledOnFrom:
      scheduledOn[0] && moment(scheduledOn[0]).startOf('day'),
    scheduledOnTo: scheduledOn[1] && moment(scheduledOn[1]).endOf('day'),
    jobCategoryIds: query.categoryIds,
    serviceReport:
      Array.isArray(query.serviceReport) &&
      query.serviceReport.reduce((prev, current) => {
        if (!current.checked) return prev;
        return [...prev, current.value];
      }, []),
    lateVisit:
      Array.isArray(query.lateVisit) &&
      query.lateVisit.reduce((prev, current) => {
        if (!current.checked) return prev;
        return [...prev, current.value];
      }, []),
    exportXLSX: query.exportXLSX,
    hubIds: query.hubs && query.hubs.map((hub) => hub.value),
    isItemsCollected:
      Array.isArray(query.isCollectedItems) &&
      query.isCollectedItems.reduce((prev, current) => {
        if (!current.checked) return prev;
        return [...prev, current.value];
      }, []),
    isVerified: query.isVerified
  };
};

export default payloadPreparation;
const drawerWidth = 251;

export default theme => ({
  root: {},
  sideBarBg: {
    background: theme.palette.common.gray,
    overflowX: 'hidden'
  },
  topbar: {
    position: 'fixed',
    // width: '100%',
    top: 0,
    left: 0,
    right: 'auto',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: 41,
    width: 'calc(100% - 41px)',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 68px)',
      marginLeft: 68
    },
  },
  topbarShift: {
    marginLeft: drawerWidth,
    width: `calc(100vw - ${drawerWidth}px)`
  },
  sidebar: {
    width: drawerWidth - 1,
  },
  sideBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100vw - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
      background: 'rgba(0,0,0,0.1)'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: 'rgba(0,0,0,0.2)'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,0.4)'
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: 'rgba(0,0,0,.9)'
    }
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    paddingRight: theme.spacing(1),
    width: 41,
    [theme.breakpoints.up('sm')]: {
      width: 68
    },
    '&::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
      background: 'rgba(0,0,0,0.1)'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: 'rgba(0,0,0,0.2)'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'rgba(0,0,0,0.4)'
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: 'rgba(0,0,0,.9)'
    }
  },
  content: {
    paddingTop: '64px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    flexGrow: 1,
    marginLeft: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 67,
    },
  },
  contentShift: {
    marginLeft: drawerWidth - 1
  }
});

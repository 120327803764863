import { IsValidDateTime } from 'components/validations';
import moment from 'moment';

export default (values, errors) => {
  errors = errors || {};
  // All is empty, skip validation:
  if (
    !values.startDate &&
    !values.endDate &&
    !values.startTime &&
    !values.endTime
  ) {
    return errors;
  }

  // ===== check each field
  if (values.startDate) {
    if (!IsValidDateTime(values.startDate)) {
      errors = {
        ...errors,
        startDate: 'invalid',
        message: {
          ...errors.message,
          startDate: 'invalidStartDate',
        },
      };
    }

    if (!values.startTime) {
      errors = {
        ...errors,
        startTime: 'required',
        message: {
          ...errors.message,
          startTime: 'setStartTime',
        },
      };
    }

    if (!values.endDate) {
      errors = {
        ...errors,
        endDate: 'required',
        message: {
          ...errors.message,
          endDate: 'setEndDate',
        },
      };
    }

    if (!values.endTime) {
      errors = {
        ...errors,
        endTime: 'required',
        message: {
          ...errors.message,
          endTime: 'setEndTime',
        },
      };
    }
  }

  if (!values.startDate && (values.startTime || values.endDate)) {
    errors = {
      ...errors,
      startDate: 'required',
      message: {
        ...errors.message,
        startDate: 'setStartDate',
      },
    };
  }

  if (values.endDate) {
    if (!values.endTime) {
      errors = {
        ...errors,
        endTime: 'required',
        message: {
          ...errors.message,
          endTime: 'setEndTime',
        },
      };
    }

    if (!IsValidDateTime(values.endDate)) {
      errors = {
        ...errors,
        endDate: 'invalid',
        message: {
          ...errors.message,
          endDate: 'invalidEndDate',
        },
      };
    }
  }

  if (!values.endDate && values.endTime) {
    errors = {
      ...errors,
      endDate: 'required',
      message: {
        ...errors.message,
        endDate: 'setEndDate',
      },
    };
  }

  if (values.startDate && values.endDate) {
    if (moment(values.startDate).isAfter(moment(values.endDate), 'day')) {
      errors = {
        ...errors,
        endDate: 'endDateAfterStartDate',
        message: {
          ...errors.message,
          endDate: 'endDateAfterStartDate',
        },
      };
    } else if (moment(values.startDate).isSame(moment(values.endDate), 'day')) {
      if (
        values.startTime &&
        values.endTime &&
        moment(values.startTime).format('HH:mm') >=
          moment(values.endTime).format('HH:mm')
      ) {
        errors = {
          ...errors,
          endTime: 'endTimeAfterStartTime',
          message: {
            ...errors.message,
            endTime: 'endTimeAfterStartTime',
          },
        };
      }
    }
  }

  // Check invalid start time and end time
  if (values.startTime && !IsValidDateTime(values.startTime)) {
    errors = {
      ...errors,
      startTime: 'invalid',
      message: {
        ...errors.message,
        startTime: 'invalidStartTime',
      },
    };
  }

  if (values.endTime && !IsValidDateTime(values.endTime)) {
    errors = {
      ...errors,
      endTime: 'invalid',
      message: {
        ...errors.message,
        endTime: 'invalidEndTime',
      },
    };
  }

  return errors;
};

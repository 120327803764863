import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { deleteEntepriseProduct } from "redux/enterprise";
import {
  Grid,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import TextInput from "components/inputs/TextInput";
import styles from "./styles.module.scss";
import { showErrorMessage } from "lib/notifier";

const mapStateToProps = (state, ownProps) => {
  const selectedRow = ownProps.selectedRow || {};
  const { currency } = state.auth.currentUser.companies[0];
  return {
    initialValues: {
      id: selectedRow.id,
      name: selectedRow.name,
      description: selectedRow.description,
      unitPrice: selectedRow.unitPrice,
      typeId: ownProps.isServiceForm ? "SERVICE" : "PRODUCT",
      currency,
      isUpdateForm: !!ownProps.selectedRow,
      model: selectedRow.model,
    },
    currency,
  };
};

let ServiceForm = (props) => {
  const {
    pristine,
    invalid,
    submitting,
    handleSubmit,
    t,
    currency,
    selectedRow,
    handleClose,
    tableRef,
    enterpriseId,
    isServiceForm,
  } = props;
  const dispatch = useDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleConfirmDelete = async () => {
    const params = { enterpriseId };
    const response = await dispatch(
      deleteEntepriseProduct(selectedRow.id, params)
    ).catch((e) => ({ e }));

    if (response.e) return showErrorMessage(response.e);
    handleClose();
    tableRef && tableRef.current.onQueryChange({ page: 0 });
  };
  const handleCloseModal = () => setOpenConfirmModal(false);
  const handleDelete = () => setOpenConfirmModal(true);

  return (
    <>
      <Dialog
        open={openConfirmModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography variant="h4">{t("deleteThisItem")}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>{t("deleteThisItemDescription")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained">
            {t("Common:close")}
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmDelete}
            className={styles.confirmDelete}
            autoFocus
          >
            {t("Common:delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Field
              name="name"
              component={TextInput}
              label={t("Common:name")}
              variant="outlined"
              inputProps={{
                maxLength: 100,
              }}
              required
              helperText={t("Product:nameLimitCharacters")}
            />
          </Grid>

          {!isServiceForm && (
            <Grid item xs={12} sm={12}>
              <Field
                name="model"
                component={TextInput}
                label={t("Common:model")}
                variant="outlined"
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <Field
              name="description"
              component={TextInput}
              label={t("Common:description")}
              variant="outlined"
              multiline
              rows="5"
              rowsMax="5"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              name="unitPrice"
              component={TextInput}
              variant="outlined"
              label={t("Product:unitCost")}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
                inputProps: { min: 0, step: ".01" },
              }}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Field
              name="taxRateId"
              component={TextInput}
              label={t("Product:taxRate")}
              variant="outlined"
              select
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
            >
              <option key="" value="">
                {t("Product:noTax")}
              </option>
            </Field>
          </Grid>
        </Grid>
        <Grid container className="mt_20" wrap="nowrap">
          <Grid item>
            {selectedRow && (
              <Button
                variant="contained"
                onClick={handleDelete}
                className={styles.btn_delete}
              >
                {t("Common:delete")}
              </Button>
            )}
          </Grid>
          <Grid item container justify="flex-end">
            <Button onClick={handleClose} color="secondary">
              {t("Common:cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={pristine || invalid || submitting}
            >
              {t("Common:save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

const validate = (values) => {
  const errors = { message: {} };
  const requiredFields = ["name", "unitPrice"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
      errors.message[field] = `${field}Required`;
    }
  });
  return errors;
};

ServiceForm = reduxForm({
  form: "serviceForm",
  validate,
})(ServiceForm);

export default connect(mapStateToProps, null)(ServiceForm);

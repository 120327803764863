import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { signOutUser } from "api/authenticationApi";

const wmLogo = require("assets/images/logo/logo.png");

function PaymentPending(props) {
  const { translate } = props;
  return (
    <div className={styles.container}>
      <Grid item container direction="row" justify="space-between">
        <Grid item>
          <Link to="/">
            <img src={wmLogo} alt="WorkPulse" className={styles.logo} />
          </Link>
        </Grid>
      </Grid>
      <div className={styles.logout}>
        <div>Please contact your admin/owner to pay subscription</div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => signOutUser()}
        >
          {translate("logout")}
        </Button>
      </div>
    </div>
  );
}

export default PaymentPending;

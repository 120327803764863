export default theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    // paddingTop: 0,
    // marginBottom: theme.spacing(2)
  },
  backTitle: {
    // marginLeft: theme.spacing(1),
  },
  backTo: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
});

import React from 'react';

// Externals
import classNames from 'classnames';
import moment from 'moment';

// Material components
// Material helpers
import { Grid, Typography, withStyles } from '@material-ui/core';

// Component styles
import { DetailsTableStyles } from './styles';
import { StatusChip } from 'pages/Dashboard/components';
import { getDateTimeFormat } from 'lib/formatter';

const DetailsRow = (props) => {
  const { classes, title, details, detailsClasses } = props;

  return (
    <Grid container className={classes.mr_15} item direction="column">
      <Grid item>
        <Typography variant="h5" className={classes.grayColor}>{title}</Typography>
      </Grid>
      <Grid item>
        <Typography className={detailsClasses}>{details}</Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(DetailsTableStyles)((props) => {
  const {
    archived,
    classes,
    invoiceDueDate,
    invoiceIssueDate,
    invoiceStatus,
    // jobIds,
    translate,
  } = props;

  const detailsClassName = classNames(
    classes.textDetails,
    classes.textDetailsBold
  );
  const getIssuedDate = () =>
    invoiceIssueDate
      ? moment(invoiceIssueDate).format(getDateTimeFormat())
      : translate('notSentYet');
  const getDueDate = () =>
    invoiceIssueDate
      ? moment(invoiceDueDate).format(getDateTimeFormat())
      : translate('notSetYet');

  return (
    <>
      <Grid container justify="space-between">
        <Typography variant="h4" className={classes.invoiceDetailsTitle}>
          {translate('invoiceDetails')}
        </Typography>
        <StatusChip invoice status={invoiceStatus} archived={archived} />
      </Grid>

      <Grid container direction="row">
        {!!invoiceIssueDate && (
          <DetailsRow
            classes={classes}
            title={translate('issued')}
            details={getIssuedDate()}
            detailsClasses={detailsClassName}
          />
        )}
        {!!invoiceDueDate && (
          <DetailsRow
            classes={classes}
            title={translate('due')}
            details={getDueDate()}
            detailsClasses={detailsClassName}
          />
        )}
      </Grid>
    </>
  );
});

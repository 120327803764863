import React, { useState } from 'react';

// Externals
import classNames from 'classnames';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, change, formValueSelector } from 'redux-form';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// Customised components
import PropertyAddressForm from 'pages/Dashboard/components/PropertyAddressForm';
import validation from './validation';

// Component styles
import styles from './styles';

const propertyValues = formValueSelector('newProperty');

function CreatePropertyModal(props) {
  const {
    classes,
    open,
    handleClick,
    handleClose,
    handleSubmit,
    selectedClient,
    pristine,
    invalid,
    submitting,
    translate,
  } = props;

  const modalTitle = selectedClient ? `${translate('createPropertyFor')} ${selectedClient.name}` : translate('createNewProperty');
  const [place, setPlace] = useState(null);

  function handleSelectPlace(place) {
    setPlace(place);
    props.updateProperty(place);
  }

  function getLocationFromProperty() {
    const { state } = props;
    if (place) {
      return [{
        location: {
          lat: place.lat ? parseFloat(place.lat) : null,
          lng: place.lng ? parseFloat(place.lng) : null,
        },
        contentInfoWindow: place.address,
      }];
    }
    return [{
      location: {
        lat: propertyValues(state, 'lat') ? parseFloat(propertyValues(state, 'lat')) : null,
        lng: propertyValues(state, 'lng') ? parseFloat(propertyValues(state, 'lng')) : null,
      },
      contentInfoWindow: propertyValues(state, 'address'),
    }];


  }

  return (
    <Dialog
      maxWidth='lg'
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <form
        noValidate
        onSubmit={handleSubmit}
      >
        <DialogTitle disableTypography className={classNames(classes.root, classes.clientDialogTitle)}>
          <Typography variant="h6">{modalTitle}</Typography>
          {open ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent dividers className={classes.modalContent}>
          <Typography className="compulsory-title">{translate('CompanySetting:compulsoryField')}</Typography>
          <PropertyAddressForm
            translate={translate}
            placeId={place && place.placeId}
            markers={getLocationFromProperty()}
            handleSelectLocation={place => handleSelectPlace(place)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              handleClose();
            }}
          >
            {translate('Common:cancel')}
          </Button>
          <Button
            color="primary"
            onClick={handleClick}
            variant="contained"
            size="small"
            type="button"
            disabled={pristine || invalid || submitting}
          >
            {translate('createProperty')}
          </Button>
        </DialogActions>
      </form>


    </Dialog>
  );
};

const mapDispatchToProps = dispatch => ({
  updateProperty: (property) => {
    dispatch(change('newProperty', 'address', property.address));
    dispatch(change('newProperty', 'unitNumber', property.unitNumber));
    dispatch(change('newProperty', 'city', property.city));
    dispatch(change('newProperty', 'zipCode', property.zipCode));
    dispatch(change('newProperty', 'state', property.state));
    dispatch(change('newProperty', 'countryCode', property.countryCode));
    dispatch(change('newProperty', 'lat', property.lat));
    dispatch(change('newProperty', 'lng', property.lng));
    dispatch(change('newProperty', 'placeId', property.placeId));
    dispatch(change('newProperty', 'zipCode', property.postalCode));
  },
});

const mapStateToProps = state => ({
  state
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(reduxForm({ form: 'newProperty', validate: validation, destroyOnUnmount: true })(CreatePropertyModal));

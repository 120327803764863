import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS_CONFIRM_BUTTON } from '../constants';

export const ConfirmButton = ({
  buttonConfirmStatus,
  completeReviewJob,
  cancelReviewJob,
  completeCurrentVisit,
  cancelCurrentVisit,
  openAndCreateVisit,
  confirmOpenJobOnly
}) => {
  const { t } = useTranslation('Job');
  switch (buttonConfirmStatus) {
    case STATUS_CONFIRM_BUTTON.UNSCHEDULE:
      return (
        <Button
          variant='contained'
          onClick={openAndCreateVisit}
          className='confirm-button'
        >
          {t('confirm')}
        </Button>
      );
    case STATUS_CONFIRM_BUTTON.COMPLETED:
      return (
        <Button
          variant='contained'
          onClick={completeReviewJob}
          className='confirm-button'
        >
          {t('completeCloseJob')}
        </Button>
      );
    case STATUS_CONFIRM_BUTTON.OPEN_CREATE_VISIT:
      return (
        <Button
          variant='contained'
          onClick={openAndCreateVisit}
          className='confirm-button'
        >
          {t('openCreateVisit')}
        </Button>
      );
    case STATUS_CONFIRM_BUTTON.COMPLETED_CURRENT_VISIT:
      return (
        <Button
          variant='contained'
          onClick={completeCurrentVisit}
          className='confirm-button'
        >
          {t('completeCurrentVisit')}
        </Button>
      );
    case STATUS_CONFIRM_BUTTON.CANCELED_CURRENT_VISIT:
      return (
        <Button
          variant='contained'
          onClick={cancelCurrentVisit}
          className='confirm-button'
        >
          {t('cancelCurrentVisit')}
        </Button>
      );
    case STATUS_CONFIRM_BUTTON.OPEN_JOB_ONLY:
      return (
        <Button
          variant='contained'
          onClick={confirmOpenJobOnly}
          className='confirm-button'
        >
          {t('confirm')}
        </Button>
      );
    default:
      return (
        <Button
          variant='contained'
          onClick={cancelReviewJob}
          className='confirm-button'
        >
          {t('cancelCloseJob')}
        </Button>
      );
  }
};
import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Button,
  Grid,
  IconButton,
  Typography,
  TablePagination,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stepper,
  Chip,
  Step,
  StepLabel,
} from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  getTimesheetVisit,
  deleteVisits,
  editTimesheetVisit,
  createTimesheetVisit,
} from "redux/timesheet";
import { getVisit } from "redux/visit";
import { moment } from "lib/app-moment";
import AddIcon from "@material-ui/icons/Add";
// import CheckCircleOutlineIcon fr om '@material-ui/icons/CheckCircleOutline';
import {
  // HighlightOff as HighlightOffIcon,
  AccessTime as AccessTimeIcon,
  LocationOn as LocationOnIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  Info as InfoIcon,
} from "@material-ui/icons";
import { StatusChip } from "pages/Dashboard/components";
import { VisitDetailTab, ChipBag } from "../utils";
import { showErrorMessage, showInfoMessage } from "lib/notifier";
import styles from "./styles.module.scss";
import { EditVisitModal } from "../modal";
import lateLogo from "assets/images/timesheet/Late.png";
import overtimeLogo from "assets/images/timesheet/Overtime.png";
import SelectingJobAndVisitForCreateTimesheet from "../modal/SelectingJobAndVisitForCreateTimesheet";
import { getTimeFormat } from "lib/formatter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  addBtn: {
    textAlign: "right",
    "& .subTitle": {
      color: theme.palette.background.wm_visit,
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      "& .btnIcon": {
        marginRight: theme.spacing(1),
      },
    },
  },
  customHoverFocus: {
    opacity: 0.5,
    "&:hover": { opacity: 1 },
  },
  approveBtn: {
    "&:hover": { color: theme.palette.background.wm_sucess },
  },
  declineBtn: {
    "&:hover": { color: theme.palette.background.wm_decline },
  },
  visitRow: {
    "& td": {
      "&:first-child": {
        paddingTop: theme.spacing(1),
      },
      "&:last-child": {
        paddingTop: theme.spacing(2),
      },
      // fontFamily: 'Roboto',
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
    },
    "&:hover": {
      backgroundColor: theme.palette.background.wm_hover_color,
    },
  },
  groupBag: {
    display: "flex",
  },
  dotContainer: {
    display: "inline-flex",
    alignItems: "center",
    color: theme.palette.background.wm_visit,
    // fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: theme.palette.background.wm_visit,
    borderRadius: "50%",
    display: "inline-block",
    margin: theme.spacing(1),
  },
  chipPreviousDay: {
    marginLeft: theme.spacing(1),
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: 3,
  },
  iconInfo: {
    color: "#DADADA",
  },
  statusBadge: {
    borderRadius: 4,
    padding: theme.spacing(1),
  },
}));

function Visits(props) {
  const { translate, query, currentStaffId } = props;
  const [timesheets, setTimeSheet] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowPerPage] = useState(10);
  const [showAddModal, setShowAddModal] = useState(false);
  const [addModalInputData, setAddModalInputData] = useState({});
  const [timeSelectedRow, setTimeSelectedRow] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVisitDetailModal, setOpenVisitDetailModal] = useState(false);
  const [currentSelectedVisit, setCurrentSelectedVisit] = useState("");
  const [selectedTimesheet, setSelectedTimesheet] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [
    openSelectingJobAndVisitModal,
    setOpenSelectingJobAndVisitModal,
  ] = useState(false);
  const [
    selectedVisitForAddingTimesheet,
    setSelectedVisitForAddingTimesheet,
  ] = useState(false);

  useEffect(() => {
    if (!openSelectingJobAndVisitModal && selectedVisitForAddingTimesheet) {
      setAddModalInputData({
        action: "CREATE",
        visitData: selectedVisitForAddingTimesheet,
      });
      setOpenSelectingJobAndVisitModal(false);
      setShowAddModal(true);
    }
  }, [openSelectingJobAndVisitModal, selectedVisitForAddingTimesheet]);

  const classes = useStyles();
  let tableOpts = {
    userId: query.userId,
    $from: query.fromDate,
    $to: query.toDate,
    $limit: rowsPerPage,
    $skip: page * rowsPerPage,
    currentStaffId,
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowPerPage(parseInt(event.target.value));
    setPage(0);
  };

  async function fetchTimeSheet() {
    if (query.showLateOnly)
      tableOpts = { ...tableOpts, lateOnly: query.showLateOnly };
    const result = await props.getTimesheetVisit(tableOpts);
    setIsLoading(false);
    setTimeSheet(result.data);
    setTotal(result.total);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchTimeSheet();
  }, [query, rowsPerPage, page]);

  const handleAddModalSubmitNew = async (value) => {
    const steps = ["ontheway", "arrived", "start", "completed", "cancelled"];
    const dateList = steps.map((step) => {
      const visitDate = value[`${step}_visitDate`];
      const visitTime = value[`${step}_visitTime`];
      if (visitDate && visitTime) {
        const fullDatetime =
          moment(visitDate).format("MM/DD/YYYY ") +
          moment(visitTime).format("HH:mm");
        return { key: step, value: fullDatetime };
      }
      return true;
    });
    const originalList = dateList.filter((item) => {
      return item.key;
    });
    if (!originalList.length)
      return showErrorMessage(translate("Timesheet:noTimeValid"));
    const sortedList = [...originalList].sort(
      (a, b) => moment(a.value) - moment(b.value)
    );
    if (JSON.stringify(originalList) !== JSON.stringify(sortedList)) {
      return showErrorMessage(translate("Timesheet:timeNotCorrect"));
    }
    const { title } = value;
    let opts = {
      title,
      currentQuery: tableOpts,
    };
    const statusToPayload = {
      ontheway: "onTheWayAt",
      arrived: "arrivedAt",
      start: "startedAt",
      completed: "completedAt",
      cancelled: "cancelledAt",
    };
    originalList.forEach((item) => {
      opts = { ...opts, [statusToPayload[item.key]]: moment(item.value) };
    });
    opts = { ...opts, cancelledAt: opts.completedAt ? null : opts.cancelledAt };
    if (value.action === "EDIT") {
      try {
        const result = await props.editTimesheetVisit(
          selectedTimesheet.id,
          opts
        );
        if (result.err) return showErrorMessage(result.err);
        result.data ? setTimeSheet(result.data) : setPage(0);
        setShowAddModal(false);
      } catch (error) {
        showErrorMessage(error);
      }
    } else if (value.action === "CREATE") {
      try {
        const visitId = selectedVisitForAddingTimesheet.id;
        const result = await props.createTimesheetVisit({
          ...opts,
          visitId,
          userId: currentStaffId,
        });
        if (result.err) return showErrorMessage(result.err);
        result.data ? setTimeSheet(result.data) : setPage(0);
        setShowAddModal(false);
      } catch (error) {
        showErrorMessage(error);
      }
    }
  };
  const handleSubmitDelete = async () => {
    setShowDeleteModal(false);
    const { id } = currentSelectedVisit;
    const opts = { currentQuery: tableOpts };
    const result = await props.deleteVisits(id, opts).catch((error) => ({
      error,
    }));
    if (result.error) return showErrorMessage(result.error);
    showInfoMessage(translate("Timesheet:deleteSuccess"));
    result.data ? setTimeSheet(result.data) : setPage(0);
  };
  if (isLoading) {
    return (
      <Grid
        container
        className={styles.loadingScreen}
        justify="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      <Grid
        className={styles.tableContainer}
        container
        direction="column"
        justify="space-between"
      >
        <div className={styles.tableContent}>
          <Table className={styles.tableRoot}>
            <RenderTimesheetTableHeader
              classes={classes}
              translate={translate}
            />
            <TableBody>
              {timesheets.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <RenderTimesheetDateHeader
                      timesheet={item}
                      translate={translate}
                      classes={classes}
                      setTimeSelectedRow={setTimeSelectedRow}
                      currentStaffId={currentStaffId}
                      setOpenSelectingJobAndVisitModal={
                        setOpenSelectingJobAndVisitModal
                      }
                    />
                    <RenderTimesheetRow
                      timesheets={(item && item.timesheets) || []}
                      translate={translate}
                      classes={classes}
                      setCurrentSelectedVisit={setCurrentSelectedVisit}
                      setShowAddModal={setShowAddModal}
                      setShowDeleteModal={setShowDeleteModal}
                      getVisit={props.getVisit}
                      deleteVisitsActions={props.deleteVisits}
                      setOpenVisitDetailModal={setOpenVisitDetailModal}
                      tableOpts={tableOpts}
                      setAddModalInputData={setAddModalInputData}
                      setSelectedTimesheet={setSelectedTimesheet}
                    />
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {openSelectingJobAndVisitModal && (
        <>
          <Dialog
            fullWidth
            maxWidth="lg"
            aria-labelledby="customized-dialog-title"
            open={openSelectingJobAndVisitModal}
            classes={{ paper: styles.defaultWidth }}
          >
            <DialogContent
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <SelectingJobAndVisitForCreateTimesheet
                translate={translate}
                setOpenModal={setOpenSelectingJobAndVisitModal}
                setSelectedVisitForAddingTimesheet={
                  setSelectedVisitForAddingTimesheet
                }
                staffId={currentStaffId}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
      {showAddModal && (
        <EditVisitModal
          handleModalSubmit={handleAddModalSubmitNew}
          setShowAddModal={setShowAddModal}
          translate={translate}
          timeSelectedRow={timeSelectedRow}
          inputData={addModalInputData}
        />
      )}
      {showDeleteModal && (
        <DeleteComponent
          translate={translate}
          handleSubmitDelete={handleSubmitDelete}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      {showVisitDetailModal && (
        <VisitDetailModal
          setOpenModal={setOpenVisitDetailModal}
          timesheet={currentSelectedVisit}
          getVisitAction={props.getVisit}
          translate={translate}
        />
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getTimesheetVisit: (option) => dispatch(getTimesheetVisit(option)),
  deleteVisits: (id, opts) => dispatch(deleteVisits(id, opts)),
  getVisit: (id) => dispatch(getVisit(id)),
  editTimesheetVisit: (id, opts) => dispatch(editTimesheetVisit(id, opts)),
  createTimesheetVisit: (opts) => dispatch(createTimesheetVisit(opts)),
});

export default compose(connect(null, mapDispatchToProps))(Visits);

const convertSecondToFormat = (second, translate) => {
  const hours = Math.floor(second / 3600);
  const minutes = Math.round((second % 3600) / 60);
  let format = "";
  if (hours > 0) format += `${hours} ${translate("Timesheet:Hrs")} `;
  format += `${minutes} ${translate("Timesheet:Mins")}`;
  return format;
};
const convertSecondToHour = (second) => {
  let hh = Math.floor(second / 3600);
  let mm = Math.round((second % 3600) / 60);
  mm = mm < 10 ? `0${mm}` : mm;
  hh = hh < 10 ? `0${hh}` : hh;
  const workedHours = [hh, mm].join(":");
  return workedHours;
};
const RenderTimesheetTableHeader = (props) => {
  const { translate } = props;

  return (
    <TableHead className={styles.tableHeadVisit}>
      <TableRow>
        <TableCell className={styles.stickyHeader} colSpan={1} />
        <TableCell className={styles.stickyHeader} colSpan={2}>
          {translate("Timesheet:startTime")}
        </TableCell>
        <TableCell className={styles.stickyHeader} colSpan={2}>
          {translate("Timesheet:endTime")}
        </TableCell>
        <TableCell className={styles.stickyHeader} colSpan={2}>
          {translate("Timesheet:duration")}
        </TableCell>
        <TableCell className={styles.stickyHeader} colSpan={1}>
          {translate("Timesheet:title")}
        </TableCell>
        <TableCell className={styles.stickyHeader} colSpan={1}>
          {translate("Timesheet:jobId")}
        </TableCell>
        <TableCell className={styles.stickyHeader} colSpan={1}>
          {translate("Timesheet:status")}
        </TableCell>
        <TableCell className={styles.stickyHeader} colSpan={2}>
          {translate("Timesheet:actions")}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const RenderTimesheetDateHeader = (props) => {
  const {
    classes,
    translate,
    timesheet,
    setOpenSelectingJobAndVisitModal,
    setTimeSelectedRow,
  } = props;
  const { durationInSecond } = timesheet;
  const totalHours = `${convertSecondToFormat(durationInSecond, translate)}`;
  return (
    <>
      <TableRow style={{ borderBottom: "3px solid #2E4F9B" }}>
        <TableCell colSpan={1} />
        <TableCell colSpan={8}>
          <div className={classes.dotContainer}>
            <span>{moment(timesheet.startDay).format("MMM DD, YYYY")}</span>
            <span className={classes.dot} />
            <span>
              {translate("Timesheet:total")}: {totalHours}
            </span>
          </div>
        </TableCell>
        <TableCell colSpan={3} className={classes.addBtn}>
          <Button
            className="subTitle"
            onClick={() => {
              setTimeSelectedRow(timesheet.startDay);
              setOpenSelectingJobAndVisitModal(true);
            }}
          >
            <AddIcon className="btnIcon" />
            {translate("Timesheet:add")}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const RenderTimesheetRow = (props) => {
  const {
    timesheets,
    classes,
    translate,
    setCurrentSelectedVisit,
    setShowDeleteModal,
    setOpenVisitDetailModal,
    setShowAddModal,
    setAddModalInputData,
    setSelectedTimesheet,
  } = props;
  return (
    <>
      {timesheets.length > 0 &&
        timesheets.map((timesheet, index) => {
          let lateTime;
          let overTime;
          if (timesheet.overtimeInSecond)
            overTime = `${convertSecondToFormat(
              timesheet.overtimeInSecond,
              translate
            )} ${translate("Timesheet:overtime")}`;
          if (timesheet.lateInSecond)
            lateTime = `${convertSecondToFormat(
              timesheet.lateInSecond,
              translate
            )} ${translate("Timesheet:late")}`;
          return (
            <TableRow
              onClick={() => {
                setCurrentSelectedVisit(timesheet);
                setOpenVisitDetailModal(true);
              }}
              className={classes.visitRow}
              key={index}
            >
              <TableCell colSpan={1}>
                <div className={classes.groupBag}>
                  {timesheet.overtimeInSecond > 0 && (
                    <Tooltip title={overTime} aria-label="add">
                      <img
                        className={styles.overTime}
                        src={overtimeLogo}
                        alt="overtime"
                      />
                    </Tooltip>
                  )}
                  {timesheet.lateInSecond > 0 && (
                    <Tooltip title={lateTime} aria-label="add">
                      <img
                        className={styles.avatarLate}
                        src={lateLogo}
                        alt="late"
                      />
                    </Tooltip>
                  )}
                </div>
              </TableCell>
              <TableCell colSpan={2}>
                {moment(timesheet.starTime).format(getTimeFormat())}
                {timesheet.isPreviousDayOverlapping && (
                  <ChipBag label={translate("Timesheet:previousDay")} />
                )}
              </TableCell>
              <TableCell colSpan={2}>
                {timesheet.endTime &&
                  moment(timesheet.endTime).format(getTimeFormat())}
                {timesheet.isNextDayOverlapping && (
                  <ChipBag label={translate("Timesheet:nextDay")} />
                )}
              </TableCell>
              <TableCell colSpan={2}>
                <Tooltip title={translate("Timesheet:info")} aria-label="add">
                  <InfoIcon className={classes.iconInfo} />
                </Tooltip>
                {convertSecondToHour(timesheet.durationInSecond)}
              </TableCell>
              <TableCell colSpan={1}>{timesheet.title}</TableCell>
              <TableCell colSpan={1}>{timesheet.jobId}</TableCell>
              <TableCell colSpan={1}>
                <StatusChip job status={timesheet.status} />
              </TableCell>
              <TableCell colSpan={2}>
                {/* <IconButton disabled className={classes.approveBtn}>
                  <CheckCircleOutlineIcon />
                </IconButton>
                <IconButton disabled className={classes.declineBtn}>
                  <HighlightOffIcon />
                </IconButton> */}
                <Tooltip title={translate("Timesheet:edit")} aria-label="add">
                  <IconButton
                    className={classes.customHoverFocus}
                    onClick={async (e) => {
                      e.stopPropagation();
                      try {
                        const visitData = (
                          await props.getVisit(timesheet.visitId)
                        ).data;
                        setAddModalInputData({
                          action: "EDIT",
                          visitData,
                        });
                        setSelectedTimesheet(timesheet);
                        setCurrentSelectedVisit(visitData);
                        setShowAddModal(true);
                      } catch (error) {
                        showErrorMessage(error);
                      }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={translate("Timesheet:delete")} aria-label="add">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentSelectedVisit(timesheet);
                      setShowDeleteModal(true);
                    }}
                    className={classes.customHoverFocus}
                    style={{ color: "#FF3B30" }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}

      {timesheets.length === 0 && (
        <TableRow>
          <TableCell
            style={{ paddingTop: 0 }}
            colSpan={12}
            className={classes.emptyList}
          >
            <Grid
              className={styles.notimesheetDataRow}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography
                style={{
                  verticalAlign: "middle",
                  textAlign: "center",
                  color: "#7d7d7d",
                }}
              >
                {translate("Timesheet:noTimesheetFound")}
              </Typography>
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const DeleteComponent = (props) => {
  const { translate, setShowDeleteModal, handleSubmitDelete } = props;
  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open
      >
        <DialogTitle id="confirmation-dialog-title">
          {translate("Timesheet:askConfirmationBeforeDelete")}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowDeleteModal(false)} color="primary">
            {translate("Common:cancel")}
          </Button>
          <Button onClick={handleSubmitDelete} color="primary">
            {translate("Common:ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const VisitDetailModal = (props) => {
  const { translate, getVisitAction, timesheet, setOpenModal } = props;
  const [visitDetails, setVisitDetail] = useState({});
  const [isFetchingData, setStatus] = useState(true);
  useEffect(() => {
    const fetchVisitDetails = async () => {
      if (!timesheet || !timesheet.id)
        showErrorMessage(translate("Timesheet:notFoundId"));
      const { data, error } = await getVisitAction(
        timesheet.visitId
      ).catch((e) => ({ error: e }));
      setStatus(false);
      if (error) {
        showErrorMessage(error);
        setOpenModal(false);
      }
      setVisitDetail(data);
    };
    fetchVisitDetails();
  }, []);
  if (isFetchingData) {
    return (
      <Dialog
        classes={{ paper: styles.loadingDialog }}
        maxWidth="lg"
        aria-labelledby="confirmation-dialog-title"
        open
      >
        <CircularProgress />
      </Dialog>
    );
  }
  const { job = {}, start, end, lateInSecond, overtimeInSecond } = visitDetails;
  const fullName = job && job.client && job.client.displayName;
  const property = (job && job.property) || {};
  const fullAddress = [
    property.unitNumber,
    property.address,
    property.city,
    property.state,
    property.countryCode,
  ]
    .filter(Boolean)
    .join(", ");
  const convertDateToFormattedString = (dayStart, dayEnd) => {
    const compareDate = moment(dayStart).isSame(dayEnd, "day");
    if (compareDate)
      return `${moment(dayStart).format("HH:mm")} - ${moment(dayEnd).format(
        "HH:mm MMM DD, YYYY"
      )}`;
    return `${moment(dayStart).format("HH:mm MMM DD, YYYY")} - ${moment(
      dayEnd
    ).format("HH:mm MMM DD, YYYY")}`;
  };
  const jobDate = convertDateToFormattedString(start, end);
  const renderChip = (time, overTimeType) => {
    if (!lateInSecond) return false;
    let styleIcon = styles.avatarLate;
    let srcIcon = lateLogo;
    let word = "Timesheet:late";
    if (overTimeType) {
      styleIcon = styles.overTime;
      srcIcon = overtimeLogo;
      word = "Timesheet:overtime";
    }
    return (
      <Chip
        className={styles.chipVisit}
        icon={<img className={styleIcon} src={srcIcon} alt="icon_logo" />}
        label={`${convertSecondToFormat(time, translate)} ${translate(word)}`}
      />
    );
  };
  return (
    <Dialog
      maxWidth="lg"
      aria-labelledby="confirmation-dialog-title"
      classes={{ paperScrollPaper: styles.visitDetailsSheet }}
      open
      onClose={() => setOpenModal(false)}
    >
      <DialogTitle classes={{ root: styles.dialogTitle }} disableTypography>
        <Typography className={styles.headerDetail} variant="h4">
          {translate("Timesheet:visits")}
        </Typography>
        {renderChip(lateInSecond)}
        {renderChip(overtimeInSecond, true)}
      </DialogTitle>
      <DialogContent className={styles.container} dividers>
        <div className={styles.visitContainer}>
          <div className={styles.visit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className={styles.titleDetail} variant="h5">
                  {translate("Timesheet:jobFor")} {fullName} -{" "}
                  {visitDetails.title}
                </Typography>
              </Grid>
              <Grid className={styles.visitInfo} item xs={12}>
                <Grid item xs={1}>
                  <LocationOnIcon />
                </Grid>
                <Grid item xs={11}>
                  <Typography className={styles.fontWeightBold} variant="body1">
                    {property.name}
                  </Typography>
                  {fullAddress}
                </Grid>
              </Grid>
              <Grid className={styles.visitInfo} item xs={12}>
                <Grid item xs={1}>
                  <AccessTimeIcon />
                </Grid>
                <Grid item xs={11}>
                  {jobDate}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={styles.process}>
            <VerticalLinearStepper
              translate={translate}
              visitDetails={visitDetails}
            />
          </div>
        </div>
        <div className={styles.visitContainer}>
          <VisitDetailTab
            translate={translate}
            fullName={fullName}
            visitDetails={visitDetails}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const VerticalLinearStepper = ({ translate, visitDetails = {} }) => {
  const steps = ["ontheway", "arrived", "start", "completed", "cancelled"];
  const statusField = {
    ontheway: "onTheWayAt",
    arrived: "arrivedAt",
    start: "startedAt",
    completed: "completedAt",
    cancelled: "cancelledAt",
  };
  const mapObjectByKey = [];
  steps.forEach((item) => {
    const value = visitDetails[statusField[item]];
    if (value)
      mapObjectByKey.push({
        key: item,
        value: visitDetails[statusField[item]],
      });
  });
  return (
    <Stepper classes={{ root: styles.stepper }} orientation="vertical">
      {mapObjectByKey.map((label, index) => {
        const labelProps = {};
        labelProps.optional = (
          <Typography variant="caption" color="error">
            {label.value ? moment(label.value).format("HH:mm") : "__:__"}
          </Typography>
        );
        return (
          <Step key={index}>
            <StepLabel
              classes={{
                labelContainer: styles.step_container,
                iconContainer: styles.iconContainer,
                label: styles.labelText,
              }}
              StepIconProps={{
                classes: { root: styles.icon, text: styles.iconText },
              }}
              {...labelProps}
            >
              {translate(`Timesheet:${label.key}`)}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field, FieldArray } from 'redux-form';

// import component
import styles from './styles.module.scss';

const DeviceInfo = ({ translate, renderTypography, deviceInfo }) => {
  if (!deviceInfo || deviceInfo.length === 0) return null;

  const renderDeviceInfo = ({ fields }) => {
    return fields.map((field, index) => {
      return (
        <Grid
          container
          wrap="nowrap"
          key={index}
          className={index % 2 !== 0 ? styles.backgroundRow : styles.normalRow}
        >
          <Grid item md={4}>
            <Field name={`${field}.modelNumber`} component={renderTypography} />
          </Grid>
          <Grid item md={4}>
            <Field
              name={`${field}.serialNumber`}
              component={renderTypography}
            />
          </Grid>
          <Grid item md={4}>
            <Field name={`${field}.serviceType`} component={renderTypography} />
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid container item md={12} className={styles.deviceInfo}>
      <Grid item container className="pl_10 pr_10">
        <Grid item md={4}>
          <Typography>{translate('modelNumber')}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography>{translate('serialNumber')}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography>{translate('serviceType')}</Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <FieldArray
          name="deviceInfo"
          component={renderDeviceInfo}
          translate={translate}
        />
      </Grid>
    </Grid>
  );
};

export default DeviceInfo;

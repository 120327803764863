import React from 'react';
import { useTranslation } from 'react-i18next';

// Material components
import { KeyboardDatePicker } from '@material-ui/pickers';

// Material helpers
import { withStyles } from '@material-ui/core';

// Component styles
const styles = {
  inputAdornment: {
    position: 'end',
    '& .MuiIconButton-root': {
      padding: '10px 10px 10px 5px',
    }
  },
  root: {
    width: '100%'
  }
};

export default withStyles(styles)(props => {
  const { classes, input, label, meta: { touched, error } = {}, helperText, ...rest } = props;
  const { t } = useTranslation('Error');

  function handleDateChange(value) {
    input.onChange(value);
  }

  if(rest.minDate === null) {
    rest.minDate = undefined;
  }

  return (
    <KeyboardDatePicker
      className={classes.root}
      InputAdornmentProps={{
        className: classes.inputAdornment,
      }}
      inputVariant={props.variant || 'outlined'}
      margin="dense"
      label={label}
      format="DD/MM/yyyy"
      value={(input && input.value) || null}
      onChange={value => handleDateChange(value)}
      placeholder="DD/MM/YYYY"
      minDateMessage="Invalid Date"
      maxDateMessage="Invalid Date"
      error={touched && (error !== undefined)}
      helperText={touched ? t(error) || helperText : helperText}
      {...rest}
    />

  );
});
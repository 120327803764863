import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Component styles
import { CalendarToolbarStyles } from './styles';

// Common components
import { PopupMenu } from 'components/popups';

const CalendarToolbar = props => {
  const {
    classes,
    className,
    openJobForm,
    translate
  } = props;

  const rootClassName = classNames(classes.root, className);
  const menuData = [
    {
      menuItems: [
        {
          label: translate('Job:newJob'),
          action: () => openJobForm(),
        },
      ]
    }
  ];

  return (
    <div className={rootClassName}>
      <Grid container direction='row' justify='flex-end' spacing={1}>
        {/* <Grid item>
          <PopupMenu
            menuData={filterMenu}
            btnTitle={translate('filter')}
            btnIcon
            selected={currentFilter}
          />
        </Grid> */}
        <Grid item>
          <PopupMenu
            menuData={menuData}
            btnTitle={translate('Common:moreActions')}
            btnIcon
          />
        </Grid>
      </Grid>
    </div>
  );
};

CalendarToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array
};

export default withStyles(CalendarToolbarStyles)(CalendarToolbar);

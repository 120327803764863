import React, { useEffect, useState } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import InjectedPaymentForm from './InjectedPaymentForm';
import { compose } from 'redux';
import { connect } from 'react-redux';

function PaymentMethod(props) {
  const {
    stripeConfig,
    translate,
    getPayment = () => {},
    setLoading = () => {},
    closePaymentMethodDialog,
    closeSubscriptionPlanModal,
  } = props;
  const [stripe, setStripe] = useState(null);
  // eslint-disable-next-line
  useEffect(() => {
    if (stripeConfig && stripeConfig.publishKey) {
      if (window.Stripe) {
        setStripe(window.Stripe(stripeConfig.publishKey));
      } else if (stripeConfig) {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.async = true;
        script.onload = () => {
          setStripe(window.Stripe(stripeConfig.publishKey));
        };
        document.body.appendChild(script);
      }
    }
    // eslint-disable-next-line
  }, [stripeConfig.publishKey]);

  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <InjectedPaymentForm
          getPayment={getPayment}
          setLoading={setLoading}
          closePaymentMethodDialog={closePaymentMethodDialog}
          closeSubscriptionPlanModal={closeSubscriptionPlanModal}
          translate={translate}
        />
      </Elements>
    </StripeProvider>
  );
}

const mapState = (state) => ({
  stripeConfig: state.config.configs.stripe,
});

export default compose(
  connect(mapState, () => ({})),
)(PaymentMethod);
export default theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  backTitle: {},
  backTo: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
});

import React from 'react';
import {
  Chip,
  Grid,
  Typography,
  TextField,
  Paper,
  withStyles,
  LinearProgress
} from '@material-ui/core';

import { getClients, } from 'redux/client';
import { compose } from 'redux';
import { connect } from 'react-redux';

// Component
import { showErrorMessage } from 'lib/notifier';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    width: '100%',
  },
  selectClientSection: {
    width: '100%'
  },
  paperClientDropdown: {
    maxWidth: '80%'
  },
  clientItem: {
    height: 80,
    margin: 0,
    padding: '5px 20px',
    borderBottom: '1px solid #ccc',
    '&:hover': {
      backgroundColor: theme.palette.primary.grey,
      cursor: 'pointer'
    },
  },
  extraInfo: {
    padding: '2px 0 0',
    fontSize: 14
  },
  searchField: {
    width: '50%'
  },
  selectedClient: {
    width: '100%'
  },
  teamChip: {
    margin: 2,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.muted2,
  },
  noClientFound: {
    height: 50,
    lineHeight: '50px',
    paddingLeft: 20
  },
  searchingWrap: {
    width: '50%'
  },
  searching: {
    height: 50,
    paddingTop: 8
  },
  progress: {
    margin: theme.spacing(2),
  },
});
const SEARCH_TIMEOUT = 1000;
let timer = 0;

class ClientSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showClientSearch: true,
      showClientList: false,
      visitList: [],
      selectedClient: null,
      isSearching: false
    };
    this.handleSearchClient = this.handleSearchClient.bind(this);
    this.removeClient = this.removeClient.bind(this);
    this.chooseClient = this.chooseClient.bind(this);
  }

  handleSearchClient(event) {
    const { value } = event.target;
    const obj = this;

    const { getClients } = this.props;

    this.setState({ isSearching: true, showClientList: false });
    clearTimeout (timer);

    timer = setTimeout(function() {
      getClients({
        limit: 10,
        skip: 0,
        searchString: value
      }).then((response) => {
        if (response.status === 200) {
          const { data } = response.data;
          obj.setState({ visitList: data, showClientList: true, isSearching: false });
        } else {
          showErrorMessage(response.data.message);
        }
      }, error => {
        showErrorMessage(error);
      });

    }, SEARCH_TIMEOUT);
  }

  chooseClient(client) {
    this.setState(
      { selectedClient: client,
        showClientSearch: false,
        showClientList: false
      });
    this.props.handleSelectClient(client);
  }

  removeClient() {
    this.setState({ selectedClient: null, showClientSearch: true });
    this.props.handleSelectClient(null);
  }

  getClientName(client) {
    if (!client) return null;

    return client.displayName;
  }

  render() {
    const { showClientList, visitList, selectedClient, showClientSearch, isSearching } = this.state;
    const { classes, translate } = this.props;
    return (
      <Grid item container md={6} sm={12} className={classes.root}>
        <Typography gutterBottom component="div" className={classes.title}>
          {translate('Job:whichClient')}
        </Typography>

        {
          selectedClient &&
          <div className={classes.selectedClient}>
            <Chip
              className={classes.teamChip}
              label={this.getClientName(selectedClient)}
              onDelete={() => {
                this.removeClient();
              }}
            />
          </div>
        }
        {
          showClientSearch &&

          <div className={classes.selectClientSection}>
            <TextField
              label="Search Client"
              className={classes.searchField}
              onChange={event => this.handleSearchClient(event)}
              margin="normal"
              variant="outlined"

            />
            {
              isSearching &&
              <Paper className={classes.searchingWrap}>
                <div className={classes.searching}>
                  <LinearProgress className={classes.progress} />
                </div>
              </Paper>
            }
            { showClientList ? (
              <Paper className={classes.paperClientDropdown}>
                {
                  visitList && visitList.length > 0 ?
                    visitList.map((item, index) => {
                      return (
                        <div className={classes.clientItem} key={index} onClick={event => this.chooseClient(item)}>
                          <h4>{this.getClientName(item)}</h4>
                          <p className={classes.extraInfo}>
                            {
                              item.properties && item.properties.length > 1 ?
                                `${item.properties.length} properties`
                                : `${item.properties.length} property`
                            }
                          </p>
                          <p className={classes.extraInfo}>{item.emails && item.emails.length > 0 && item.emails[0].email}</p>
                        </div>
                      );
                    })
                    :
                    <div className={classes.noClientFound}>
                      <p>No client found</p>
                    </div>
                }

              </Paper>
            ) : null }
          </div>
        }
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClients: (options) => dispatch(getClients(options)),
});

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(ClientSelection);

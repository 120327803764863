export default theme => ({
  root: {},
  formField: {
    margin: theme.spacing(2)
  },
  textField: {
    width: '360px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  fullTextField: {
    width: 480,
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  role: {
    height: '100%',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'left',
    '&:not(:first-of-type)': {
      borderLeft: `1px solid ${theme.palette.divider}`
    },
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.muted
  },
  selectedRole: {
    backgroundColor: theme.palette.background.paper
  },
  roleDetails: {
    marginLeft: theme.spacing(2),
    flexGrow: 1
  },
  roleTitle: {},
  roleDescription: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary
  },
  roleSelectBtn: {
    marginTop: 'auto',
  },
  profile: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  avatar: {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '110px',
    width: '110px',
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: theme.palette.primary.light
  },
  countryPhoneInput: {
    display: 'flex',
    flexDirection: 'row'
  }
});

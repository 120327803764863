import React, { Component } from 'react';

// Material components
import { Grid, Tab, Tabs, AppBar, Typography } from '@material-ui/core';

// Redux
import { connect } from 'react-redux';
import { change, formValueSelector, submit } from 'redux-form';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { addProperty } from 'redux/property';
import { getClientDetails, updateClientDetails } from 'redux/client';
import { setSelectedClient, setSelectedProperty } from 'redux/job';
import { getVisit } from 'redux/visit';
import { clearInvoice, initInvoiceFormData } from 'redux/invoice';
import {
  getPayment,
  updatePayment,
  deletePayment,
  getPayments,
  downloadPdf as downloadReceipt,
  emailReceipt,
} from 'redux/payment';

// Custom components
import {
  Properties,
  ClientSchedule,
  Jobs,
  Invoices,
  BillingHistoryCard,
  TitleToolBar,
} from './components';
import {
  CreatePropertyModal,
  SelectPropertyModal,
  SelectJobModal,
  UpdatePaymentModal,
  ConfirmDeleteModal,
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  ConfirmEmailModal,
  LoadingModal,
} from 'pages/Dashboard/components';
import {
  showErrorMessage,
  showSuccessMessage,
  showInfoMessage,
} from 'lib/notifier';
import { PopupMenu } from 'components/popups/';
import { getAccessibleView } from 'lib/permissions';
import { VIEWS_NAME } from 'common/constant';
import { Trans } from 'react-i18next';
// css
import './index.scss';

const jobForm = formValueSelector('jobForm');

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

class ClientDetails extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      visits: [],
      isLoading: false,
      isSubmittingBtn: false,
      openSelectProperty: false,
      openSelectJob: false,
      openCreateProperty: false,
      openUpdatePayment: false,
      openInvoiceInfo: false,
      openConfirmDelete: false,
      confirmEmailModal: false,
      paymentId: null,
      paymentDetails: null,
      clientDetails: null,
      clientBillings: null,
      isShowingAccountingFeature: false,
    };
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.fetchClientDetails = this.fetchClientDetails.bind(this);
    this.toggleArchiveClient = this.toggleArchiveClient.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.fetchClientDetails();

    // check permission showing accounting feature
    const isShowAccountingFeature = getAccessibleView(VIEWS_NAME.invoices);
    this.setState({ isShowingAccountingFeature: isShowAccountingFeature });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async fetchClientDetails() {
    const { clientId, getClientDetails } = this.props;
    try {
      this.setState({ isLoading: true });
      const response = await getClientDetails(clientId);
      if (this._isMounted) {
        this.setState({ clientDetails: response.data, isLoading: false });
        await this.fetchPayments(clientId);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      showErrorMessage(error);
    }
  }

  async fetchPayments() {
    const { clientId, getPayments } = this.props;
    try {
      const response = await getPayments({ clientId });
      if (this._isMounted) {
        this.setState({
          clientBillings: response.data.payments,
          isLoading: false,
        });
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  handleChangeTab(event, newValue) {
    this.setState({ tabIndex: newValue });
  }

  async toggleArchiveClient(event) {
    const { updateClientDetails, history, translate } = this.props;
    const {
      displayName,
      id: clientId,
      archived: clientArchiveStatus,
    } = this.state.clientDetails;
    const clientName = displayName;
    this.setState({ isSubmittingBtn: true });
    try {
      const response = await updateClientDetails({
        id: clientId,
        archived: !clientArchiveStatus,
      });
      this.setState({ isSubmittingBtn: false, clientDetails: response.data });
      if (response.data.archived) {
        showSuccessMessage(translate('archivedSuccess', { clientName }));
        history.push('/clients');
      } else {
        showSuccessMessage(translate('unarchivedSuccess', { clientName }));
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  render() {
    const {
      addProperty,
      currency,
      emailReceipt,
      emailTypes,
      getPayment,
      history,
      initInvoiceFormData,
      paymentMethods,
      paymentTypes,
      phoneTypes,
      setSelectedClient,
      setSelectedProperty,
      submitNewProperty,
      updatePayment,
      deletePayment,
      downloadReceipt,
      translate,
    } = this.props;

    const {
      openSelectProperty,
      openCreateProperty,
      openSelectJob,
      openUpdatePayment,
      openConfirmDelete,
      confirmEmailModal,
      paymentDetails,
      clientDetails,
      clientBillings,
      isShowingAccountingFeature,
    } = this.state;

    const {
      id: clientId,
      displayName,
      properties: clientProperties,
      contactEmails: emails,
      contactPhones: phoneNumbers,
    } = clientDetails || {};

    const clientName = displayName;

    const handleClose = (type) => {
      if (type === 'select') {
        this.setState({ openSelectProperty: false });
      } else if (type === 'create') {
        this.setState({ openCreateProperty: false });
      }
    };

    const handleCancelJobSelection = () => {
      this.props.clearInvoice();
      this.setState({ openSelectJob: false });
    };

    const handlePropertySelect = (property) => {
      setSelectedProperty(property);
      history.push('/jobs/new');
      this.setState({ openSelectProperty: false });
    };

    const handleFinishJobSelection = async (jobIds) => {
      if (!jobIds.length) return;

      try {
        const res = await initInvoiceFormData(jobIds, clientDetails);
        if (res === 'ok') {
          this.setState({ openSelectJob: false });
          history.push('/invoices/new');
        }
      } catch (error) {
        showErrorMessage(error);
      }
    };

    const handleAddNewProperty = () => {
      this.setState({ openSelectProperty: false });
      this.setState({ openCreateProperty: true });
    };

    const handleSubmitNewProperty = (values) => {
      const callback = (newProperty) => {
        this.fetchClientDetails();
        this.setState({ openCreateProperty: false });
        this.setState({ openSelectProperty: false });
      };

      values.ownerId = clientDetails.id;

      addProperty(values, callback);
    };

    const handleCreateJob = () => {
      const contactEmail =
        emails.length > 0 && emails.find((email) => email.typeId === 'MAIN');
      const contactPhoneNumber =
        phoneNumbers.length > 0 &&
        phoneNumbers.find((phoneNumber) => phoneNumber.typeId === 'MAIN');

      if (clientId && clientName) {
        setSelectedClient({
          id: clientId,
          name: clientName,
          email: (contactEmail && contactEmail.email) || null,
          phoneNumber:
            (contactPhoneNumber && contactPhoneNumber.phoneNumber) || null,
        });
      }

      if (clientProperties && clientProperties.length > 0) {
        if (clientProperties.length < 2) {
          const property = clientProperties[0];
          setSelectedProperty(property);
          history.push('/jobs/new');
        } else if (clientProperties.length > 1) {
          this.setState({ openSelectProperty: true });
        }
      } else if (clientProperties.length <= 0) {
        this.setState({ openSelectProperty: true });
      }
    };

    const handleShowJobSelectionToCreateInvoice = () => {
      const { setSelectedClientForInvoice } = this.props;

      setSelectedClientForInvoice(clientDetails);
      this.setState({ openSelectJob: true });
    };

    const handleCreateProperty = () => {
      this.setState({ openSelectProperty: false });
      this.setState({ openCreateProperty: true });
    };

    const handleClickBillingHistory = async (paymentId) => {
      try {
        const response = await getPayment(paymentId);
        this.setState({
          paymentDetails: response.data,
          openUpdatePayment: true,
        });
      } catch (error) {
        showErrorMessage(error);
      }
    };

    const handleUpdatePayment = async (values) => {
      const submitValues = {
        amount: values.amount,
        notes: values.notes,
        paymentMethodId: values.paymentMethod,
        paymentDate: values.paymentDate,
        reference: values.reference,
        chequeNumber: values.chequeNumber,
        clientId,
      };

      if (values.appliedTo) {
        if (values.appliedTo === 'ACCOUNT') {
          submitValues.paymentTypeId = 'ACCOUNT';
          submitValues.invoiceId = null;
        }

        if (values.appliedTo !== 'ACCOUNT') {
          submitValues.invoiceId = values.appliedTo;
          submitValues.paymentTypeId = 'INVOICE';
        }
      }

      await updatePayment(submitValues, paymentDetails.id);
      await this.fetchPayments(clientId);
      this.setState({ paymentDetails: null, openUpdatePayment: false });
    };

    const handleCloseUpdatePaymentModal = () => {
      this.setState({ paymentDetails: null, openUpdatePayment: false });
    };

    const handleConfirmDelete = () =>
      this.setState({ openConfirmDelete: true });

    const handleCloseConfirmDeleteModal = () =>
      this.setState({ openConfirmDelete: false });

    const handleDeletePayment = async () => {
      this.setState({ isLoading: true });

      try {
        await deletePayment(paymentDetails.id);

        this.setState({
          openConfirmDelete: false,
          openUpdatePayment: false,
          paymentDetails: null,
          isLoading: false,
        });
        await this.fetchPayments(clientId);
      } catch (error) {
        showErrorMessage(error);
      }
    };

    const handleDownloadReceipt = async (paymentId) => {
      this.setState({ isLoading: true });
      try {
        const response = await downloadReceipt(paymentId);
        this.setState({ isLoading: false });
        window.open(response.data.receiptFile, '_blank');
      } catch (error) {
        showErrorMessage(error);
      }
    };

    const handleEmailReceipt = (paymentId) => {
      this.setState({ confirmEmailModal: true, paymentId });
    };

    const overviewMenuData = [
      {
        title: translate('Common:createNew'),
        menuItems: [
          {
            label: translate('Common:job'),
            action: handleCreateJob,
          },
          {
            label: translate('Common:invoice'),
            action: handleShowJobSelectionToCreateInvoice,
          },
        ],
      },
    ];

    const handleConfirmReceiptEmail = async (value) => {
      this.setState({ confirmEmailModal: false });
      showInfoMessage(translate('Invoice:sendingEmail'));
      try {
        await emailReceipt(this.state.paymentId, value.email);
        showSuccessMessage(translate('Invoice:sentPaymentEmail'));
      } catch (error) {
        showErrorMessage(error);
      }
    };

    const handleCloseConfirmEmailModal = () => {
      this.setState({ confirmEmailModal: false });
    };

    return (
      <>
        {clientDetails && (
          <div className="client-details">
            <TitleToolBar
              archiveDisabled={this.state.isSubmittingBtn}
              clientDetails={clientDetails}
              detailPage
              handleClickArchive={this.toggleArchiveClient}
              handleCreateJob={handleCreateJob}
              handleShowJobSelectionToCreateInvoice={
                handleShowJobSelectionToCreateInvoice
              }
              handleCreateProperty={handleCreateProperty}
              translate={translate}
              isShowingAccountingFeature={isShowingAccountingFeature}
            />
            <Grid container spacing={4}>
              <Grid item xl={8} lg={8} md={8} xs={12} className="client-body">
                <Properties
                  properties={clientProperties}
                  handleClick={handleAddNewProperty}
                  translate={translate}
                />

                <Portlet className="overviews">
                  <PortletHeader className="card-header">
                    <PortletLabel title={translate('overview')} />
                    <PopupMenu
                      btnTitle={translate('Common:new')}
                      btnIcon
                      menuData={overviewMenuData}
                    />
                  </PortletHeader>
                  <PortletContent className="overview-content">
                    <AppBar position="static" className="app-bar">
                      <Tabs
                        className="tabs"
                        variant="fullWidth"
                        value={this.state.tabIndex}
                        onChange={this.handleChangeTab}
                      >
                        <Tab
                          className="tab-item"
                          label={translate('Job:jobs')}
                        />
                        {isShowingAccountingFeature && (
                          <Tab
                            className="tab-item"
                            label={translate('Invoice:invoices')}
                          />
                        )}
                      </Tabs>
                    </AppBar>

                    {this.state.tabIndex === 0 && (
                      <TabContainer>
                        <Jobs
                          clientDetails={clientDetails}
                          statuses={this.props.statuses}
                          translate={translate}
                          handleCreateJob={handleCreateJob}
                        />
                      </TabContainer>
                    )}

                    {this.state.tabIndex === 1 && (
                      <TabContainer>
                        <Invoices
                          clientDetails={clientDetails}
                          invoices={clientDetails.invoices}
                          statuses={this.props.statuses}
                          jobs={clientDetails.jobs}
                          translate={translate}
                          handleCreateInvoice={
                            handleShowJobSelectionToCreateInvoice
                          }
                        />
                      </TabContainer>
                    )}
                  </PortletContent>
                </Portlet>

                <div className="recent-pricing">
                  <ClientSchedule
                    clientDetails={clientDetails}
                    statuses={this.props.statuses}
                    getVisit={this.props.getVisit}
                    translate={translate}
                  />
                </div>
              </Grid>

              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                xs={12}
                className="client-right-column"
              >
                <div className="title">{translate('contactInfo')}</div>
                <div className="content-info">
                  {clientDetails.contactEmails &&
                    clientDetails.contactEmails.map((item, index) => {
                      const type = emailTypes.find(
                        (emailType) => emailType.id === item.typeId
                      );

                      return (
                        <div className="contact-info-item" key={index}>
                          <div className="name">{type && type.name}</div>
                          <div className="value">{item.email}</div>
                        </div>
                      );
                    })}

                  {clientDetails.contactPhones &&
                    clientDetails.contactPhones.map((item, index) => {
                      const type = phoneTypes.find(
                        (phoneType) => phoneType.id === item.typeId
                      );

                      return (
                        <div className="contact-info-item" key={index}>
                          <div className="name">{type && type.name}</div>
                          <div className="value">
                            ({item.countryPhoneCode})-{item.phoneNumber}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {isShowingAccountingFeature && (
                  <div>
                    <BillingHistoryCard
                      accountBalance={clientDetails.accountBalance}
                      currency={currency}
                      payments={clientBillings}
                      handleClickBillingHistory={handleClickBillingHistory}
                      translate={translate}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        )}
        {this.state.isLoading && <LoadingModal open={this.state.isLoading} />}

        {openSelectProperty && (
          <SelectPropertyModal
            open={openSelectProperty}
            handleClose={() => handleClose('select')}
            handlePropertySelect={(property) => handlePropertySelect(property)}
            handleAddNewProperty={handleAddNewProperty}
            selectedClient={clientDetails}
            translate={translate}
          />
        )}
        {openCreateProperty && (
          <CreatePropertyModal
            open={openCreateProperty}
            handleClick={submitNewProperty}
            handleClose={() => handleClose('create')}
            onSubmit={handleSubmitNewProperty}
            translate={translate}
          />
        )}

        {openSelectJob && (
          <SelectJobModal
            open={openSelectJob}
            client={clientDetails}
            handleCancelJobSelection={() => handleCancelJobSelection()}
            handleFinishJobSelection={(jobs) => handleFinishJobSelection(jobs)}
            translate={translate}
          />
        )}
        {openUpdatePayment && paymentDetails && (
          <UpdatePaymentModal
            onSubmit={handleUpdatePayment}
            handleClose={handleCloseUpdatePaymentModal}
            handleConfirmDelete={handleConfirmDelete}
            handleDownloadReceipt={handleDownloadReceipt}
            handleEmailReceipt={handleEmailReceipt}
            open={openUpdatePayment}
            paymentDetails={paymentDetails}
            paymentMethods={paymentMethods}
            paymentTypes={paymentTypes}
            translate={translate}
          />
        )}

        {openConfirmDelete && (
          <ConfirmDeleteModal
            open={openConfirmDelete}
            handleClose={handleCloseConfirmDeleteModal}
            handleConfirmDelete={handleDeletePayment}
            translate={translate}
            dialogTitle={
              <Trans i18nKey="Client:deletePayment">
                {paymentDetails && paymentDetails.id}
              </Trans>
            }
            dialogBodyText={
              <Trans i18nKey="Client:permanentDelete">
                {paymentDetails && paymentDetails.id}
              </Trans>
            }
          />
        )}

        {confirmEmailModal && (
          <ConfirmEmailModal
            onSubmit={handleConfirmReceiptEmail}
            open={confirmEmailModal}
            handleClose={handleCloseConfirmEmailModal}
            translate={translate}
            title="sendReceiptToEmail"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currency: state.auth.currentUser.companies[0].currency,
  selectedClient: jobForm(state, 'selectedClient'),
  paymentMethods: state.config.configs.paymentMethods,
  paymentTypes: state.config.configs.paymentTypes,
  emailTypes: state.config.configs.emailTypes,
  phoneTypes: state.config.configs.phoneTypes,
  statuses: state.config.configs.statuses,
});

const mapDispatchToProps = (dispatch) => ({
  getClientDetails: (clientId) => dispatch(getClientDetails(clientId)),
  updateClientDetails: (data) => dispatch(updateClientDetails(data)),
  setSelectedClient: (client) => dispatch(setSelectedClient(client)),
  setSelectedProperty: (property) => dispatch(setSelectedProperty(property)),
  setSelectedClientForInvoice: (client) =>
    dispatch(change('invoice', 'client', client)),
  setSelectedJobIdsForInvoice: (jobIds) =>
    dispatch(change('invoice', 'jobIds', jobIds)),
  setItemsForInvoice: (items) => dispatch(change('invoice', 'items', items)),
  addProperty: (values, callback) => dispatch(addProperty(values, callback)),
  submitNewProperty: () => dispatch(submit('newProperty')),
  clearInvoice: () => dispatch(clearInvoice()),
  getVisit: (id) => dispatch(getVisit(id)),
  deletePayment: (id) => dispatch(deletePayment(id)),
  getPayments: (options) => dispatch(getPayments(options)),
  updatePayment: (payment, paymentId) =>
    dispatch(updatePayment(payment, paymentId)),
  downloadReceipt: (paymentId) => dispatch(downloadReceipt(paymentId)),
  getPayment: (paymentId) => dispatch(getPayment(paymentId)),
  emailReceipt: (paymentId, email) => dispatch(emailReceipt(paymentId, email)),
  initInvoiceFormData: (jobIds, client) =>
    dispatch(initInvoiceFormData(jobIds, client)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ClientDetails);

import React from 'react';

// Externals
import classNames from 'classnames';

// Material components
// Material helpers
import { Grid, Typography, withStyles, Box } from '@material-ui/core';

// Component styles
import { DetailsTableStyles } from './styles';

import { StatusChip } from 'pages/Dashboard/components';
import moment from 'moment';
import { getDateTimeFormat } from 'lib/formatter';

const DetailsRow = (props) => {
  const { classes, title, details, detailsClasses } = props;

  return (
    <Grid item container direction="column">
      <Grid item>
        <Typography className={classes.titleColor}>{title}</Typography>
      </Grid>
      <Grid item>
        <Typography className={detailsClasses}>{details}</Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(DetailsTableStyles)((props) => {
  const {
    classes,
    requestArchived,
    requestBillingFrequency,
    requestEnd,
    requestStart,
    requestStatus,
    requestType,
    translate,
    categories = [],
  } = props;

  const detailsClassName = classNames(
    classes.textDetails,
    classes.textDetailsBold
  );
  const getRequestType =
    requestType === 'oneOff' ? translate('oneOffRequest') : 'Recurring Request';
  const formatDateTime = (schedule) =>
    moment(schedule).format(getDateTimeFormat());

  return (
    <>
      <Box
        width={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="h3" className={classes.requestDetailsTitle}>
          {translate('requestDetails')}
        </Typography>
        <Grid>
          {!!requestStatus && (
            <StatusChip request status={requestStatus} archived={requestArchived} />
          )}
        </Grid>
      </Box>
      <Grid container>
        <Grid item sm={6} md={6}>
          {!!requestType && (
            <DetailsRow
              classes={classes}
              title={translate('requestType')}
              details={getRequestType}
              detailsClasses={detailsClassName}
            />
          )}
          {!!requestStart && (
            <DetailsRow
              classes={classes}
              title={translate('startsOn')}
              details={formatDateTime(requestStart)}
              detailsClasses={detailsClassName}
            />
          )}
          {!!requestEnd && (
            <DetailsRow
              classes={classes}
              title={translate('endsOn')}
              details={formatDateTime(requestEnd)}
              detailsClasses={detailsClassName}
            />
          )}
          {!!requestBillingFrequency && (
            <DetailsRow
              classes={classes}
              title={translate('billingFrequency')}
              details={requestBillingFrequency}
              detailsClasses={detailsClassName}
            />
          )}
        </Grid>
        <Grid item sm={6} md={6}>
          {Array.isArray(categories) && categories.length > 0 && (
            <>
              <Grid item>
                <Typography className={classes.titleColor}>
                  {translate('Category:category')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {categories.map((cat) => cat.name).join(', ')}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
});

import React, { useEffect } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, change, getFormSyncErrors, getFormMeta, getFormSubmitErrors } from 'redux-form';
import { getRoles } from 'redux/staff';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, Grid, Typography } from '@material-ui/core';

// Component styles
import styles from './styles';

const allowedRoles = ['WORKER', 'SUPERVISOR', 'SCHEDULER', 'MANAGER'];

const SelectButton = props => {
  const { translate, ...rest } = props;
  return (
    <Button
      label='Select'
      size="medium"
      color='primary'
      {...rest}
    >
      {translate('select')}
    </Button>
  );
};

const PermissionsForm = props => {
  const {
    changeRole,
    classes,
    roleId,
    roles,
    translate,
  } = props;

  useEffect(() => {
    getRoles();
  }, []);

  const handleRoleChange = id => changeRole(id);

  return (
    <Grid container direction='row' spacing={0}>
      {
        roles &&
        roles.map(item => {
          return (
            allowedRoles.includes(item.id) &&
            <Grid item key={item.id} xl={3} lg={3} md={3} sm={3}>
              <Grid
                item
                container
                justify='space-between'
                direction='column'
                className={
                  classNames({
                    [classes.role]: true,
                    [classes.selectedRole]: roleId && item.id === roleId
                  })}
              >
                <Grid item>
                  <Typography
                    className={classes.roleTitle}
                    variant="h5"
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    className={classes.roleDescription}
                    variant="body2"
                  >
                    {item.description}
                  </Typography>
                </Grid>
                <Grid item>
                  <Field
                    name='roleId'
                    component={SelectButton}
                    variant={roleId && item.id === roleId ? 'contained' : 'outlined'}
                    onClick={() => handleRoleChange(item.id)}
                    className={classes.roleSelectBtn}
                    translate={translate}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })
      }
    </Grid>
  );
};

PermissionsForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  roles: state.staff.roles,
  roleError: getFormSyncErrors('staffForm')(state),
  fields: getFormMeta('staffForm')(state),
  submitErrors: getFormSubmitErrors('myForm')(state),
});

const mapDispatchToProps = dispatch => ({
  changeRole: (role) => { dispatch(change('staffForm', 'roleId', role)); },
  getRoles: () => { dispatch(getRoles()); },
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(PermissionsForm);
import { getDateTimeFormat } from 'lib/formatter';
import moment from 'moment';

const prepareFormValue = (data, translate) => {
  const {
    publicId,
    title,
    createdAt,
    start,
    end,
    job,
    team,
    description,
    statusId,
    onTheWayAt,
    arrivedAt,
    startedAt,
    completedAt,
    cancelledAt,
  } = data;
  const property = (job && job.property) || {};

  return {
    publicId,
    title,
    createdAt:
      createdAt && moment(createdAt).format(getDateTimeFormat()),
    startDateTime:
      start && moment(start).format(getDateTimeFormat()),
    endDateTime: end && moment(end).format(getDateTimeFormat()),
    address: [property.name, property.unitNumber, property.address]
      .filter(Boolean)
      .join(', '),
    propertyAddress: {
      name: property.name,
      unitNumber: property.unitNumber,
      address: property.address,
    },
    team,
    description,
    statusId,
    ontheway:
      onTheWayAt && moment(onTheWayAt).format(getDateTimeFormat()),
    arrived:
      arrivedAt && moment(arrivedAt).format(getDateTimeFormat()),
    start:
      startedAt && moment(startedAt).format(getDateTimeFormat()),
    completed:
      completedAt &&
      moment(completedAt).format(getDateTimeFormat()),
    cancelled:
      cancelledAt &&
      moment(cancelledAt).format(getDateTimeFormat()),
  };
};

export default prepareFormValue;
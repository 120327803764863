import React from 'react';
import MaterialTable from 'material-table';
import { showErrorMessage } from 'lib/notifier';
import { useDispatch } from 'react-redux';
// Shared components
import { Typography, Grid } from '@material-ui/core';
import moment from 'moment';
import {
  getEnterpriseServiceReport,
  serviceReportActions,
} from 'redux/enterprise';

import DownloadSrc from 'assets/images/icons/download.svg';
import { getDateTimeFormat } from 'lib/formatter';

const options = {
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  search: false,
  debounceInterval: 1000,
  actionsColumnIndex: -1,
  toolbar: false,
};

const renderFields = ({ translate }) => [
  {
    field: 'id',
    title: translate('Job:reportNo'),
    sorting: false,
  },
  {
    field: 'type',
    title: translate('type'),
    render: (rowData) => {
      return (
        <Typography>
          {translate(`Job:${rowData.reportFor}_${rowData.reportType}`)}
        </Typography>
      );
    },
    sorting: false,
  },
  {
    field: 'issuedAt',
    title: translate('Job:issuedAt'),
    render: (rowData) => {
      return (
        <Grid>
          <Typography>
            {moment(rowData.createdAt).format(
              getDateTimeFormat()
            )}
          </Typography>
          {rowData.createdBy && (
            <Typography>{rowData.createdBy.fullName}</Typography>
          )}
        </Grid>
      );
    },
    sorting: false,
  },
  {
    field: 'Status',
    title: translate('Common:status'),
    render: (rowData) => {
      return <Typography>{rowData.status}</Typography>;
    },
    sorting: false,
  },
];

const Table = ({ tableRef, translate, jobPublicId }) => {
  const columns = renderFields({ translate });
  const dispatch = useDispatch();

  const fetchServiceReport = async (query) => {
    const { page, pageSize, selectedVisitIds, typeFilter } = query;

    const params = {
      $limit: pageSize,
      $skip: page * pageSize,
      jobId: jobPublicId,
      reportFors: typeFilter,
      visitIds: selectedVisitIds,
    };

    const response = await dispatch(
      getEnterpriseServiceReport({ params })
    ).catch((e) => ({ e }));
    if (response.e) {
      showErrorMessage(response.e);
      return Promise.reject(response);
    }
    return Promise.resolve({
      data: response.data,
      page,
      totalCount: response.total,
    });
  };

  const handleDownloadSR = async (rowData) => {
    const data = {
      action: 'DOWNLOAD',
      serviceReportId: rowData.id,
      jobId: jobPublicId,
    };
    const result = await dispatch(serviceReportActions({ data }));
    if (result.e) return showErrorMessage(result.e);
    if (!result.url) return false;
    window.open(result.url, '_blank');
  };

  return (
    <MaterialTable
      title={null}
      columns={columns}
      options={options}
      tableRef={tableRef}
      data={fetchServiceReport}
      actions={[
        () => ({
          icon: () => <img src={DownloadSrc} alt="DownloadSrc" />,
          tooltip: translate('downloadSR'),
          onClick: (event, rowData) => handleDownloadSR(rowData),
        }),
      ]}
    />
  );
};

export default Table;

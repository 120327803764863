export default theme => ({
  root: {},
  jobClientName: {
    marginBottom: theme.spacing(2),
  },
  jobClientDetail:{
    marginTop: 15,
  },
  jobDetail: {
    marginLeft: theme.spacing(4),
    borderRadius: 6,
    backgroundColor: 'white',
    padding: theme.spacing(4),
  },
  clientButton: {
    display: 'flex',
    flexFlow: 'row wrap',
    verticalAlign: 'bottom',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  selectClientButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  linkIcon: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    verticalAlign: 'center',
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  selectAddIcon: {
    color: theme.palette.primary.main,
  },
  clientNameText: {
    color: theme.palette.text.secondary,
    marginLeft: 15,
    marginRight: 15,
    // paddingLeft: 2,
    // borderBottom: `2px dashed ${theme.palette.text.secondary}`,
    verticalAlign: 'middle',
    alignItems: 'center',
    alignSelf: 'center',
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  addButton: {
    marginBottom: 2
  },
});

import { POST, GET, PATCH, DELETE } from 'lib/api';

const REQUEST_ACTIONS = '/v2/requests/actions';

// Actions
const SET_CURRENT_REQUEST = 'SET_CURRENT_REQUEST';
const SET_CLEAR_REQUEST = 'SET_CLEAR_REQUEST';
const SET_REQUEST_TYPE = 'SET_REQUEST_TYPE';
const SET_ASSIGNEES = 'SET_ASSIGNEES';
const SET_IS_CUSTOM_PRODUCT = 'SET_IS_CUSTOM_PRODUCT';
const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';
const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
const SET_IS_SAVING = 'SET_IS_SAVING';
const SET_REQUEST_ITEMS = 'SET_REQUEST_ITEMS';
const SET_SERVICE_REPORT_DETAILS = 'SET_SERVICE_REPORT_DETAILS';
const SET_IS_VIEW_MODE = 'SET_IS_VIEW_MODE';
const SET_SERVICE_REPORT_FILTER_LIST = 'SET_SERVICE_REPORT_FILTER_LIST';
const SET_VISIT_ID_REPORT_GENERATE_FROM = 'SET_VISIT_ID_REPORT_GENERATE_FROM';

const initialState = {
  request: {},
  requestType: 'oneOff',
  assignees: null,
  isCustomProduct: [],
  productIndex: null,
  selectedClient: null,
  selectedProperty: null,
  modalVisibility: false,
  modalType: null,
  isSaving: false,
  requestItems: [],
  reportDetails: {},
  isViewServiceReportMode: true,
  serviceReportFilterList: [],
  visitReportGenerateFrom: '',
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_REQUEST:
      return {
        ...state,
        request: {
          ...action.request,
        },
      };

    case SET_REQUEST_TYPE:
      return {
        ...state,
        requestType: action.requestType,
      };

    case SET_ASSIGNEES:
      return {
        ...state,
        assignees: action.assignees,
      };

    case SET_IS_CUSTOM_PRODUCT:
      return {
        ...state,
        isCustomProduct: action.isCustomProduct,
      };

    case SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.client,
      };

    case SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.property,
      };

    case SET_MODAL_VISIBILITY:
      return {
        ...state,
        modalVisibility: action.visibility,
        modalType: action.modalType,
      };

    case SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.isSaving,
      };

    case SET_REQUEST_ITEMS:
      return {
        ...state,
        requestItems: action.requestItems,
      };

    case SET_CLEAR_REQUEST:
      return {
        ...state,
        request: {},
        assignees: null,
        isCustomProduct: [],
        productIndex: [],
        selectedClient: null,
        client: null,
        selectedProperty: null,
        modalVisibility: false,
        modalType: null,
        isSaving: false,
        requestItems: [],
      };

    case SET_SERVICE_REPORT_DETAILS:
      return {
        ...state,
        reportDetails: action.details,
      };

    case SET_IS_VIEW_MODE:
      return {
        ...state,
        isViewServiceReportMode: action.mode,
      };

    case SET_SERVICE_REPORT_FILTER_LIST:
      return {
        ...state,
        serviceReportFilterList: action.list,
      };

    case SET_VISIT_ID_REPORT_GENERATE_FROM:
      return {
        ...state,
        visitReportGenerateFrom: action.visitId,
      };

    default:
      return state;
  }
}

// Action Creators
export function setCurrentRequest(request) {
  return { type: SET_CURRENT_REQUEST, request };
}

export function clearRequest() {
  return { type: SET_CLEAR_REQUEST };
}

export function setRequestType(requestType) {
  return { type: SET_REQUEST_TYPE, requestType };
}

export function setSelectedClient(client) {
  return { type: SET_SELECTED_CLIENT, client };
}

export function setModalVisibility(visibility, modalType) {
  return { type: SET_MODAL_VISIBILITY, visibility, modalType };
}

export function setIsSaving(isSaving) {
  return { type: SET_IS_SAVING, isSaving };
}

export function setSelectedProperty(property) {
  return { type: SET_SELECTED_PROPERTY, property };
}

export function setRequestItems(requestItems) {
  return { type: SET_REQUEST_ITEMS, requestItems };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export function getRequest(id) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/v2/requests/${id}`, accessToken)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function addRequest(data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/v2/requests', accessToken, { data })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

// ****** getRequests params
// options = {
//   clientId: xxxyyyzzz
//   limit: limit,
//   skip: skip,
//   from: from,
//   to: to,
//   searchString: null,
//   sort: {
//     field: 'fieldNameHere',
//     orderDirection: 'asc'
//   }
// }
const mapOptionsToPayload = [
  'includingArchived',
  'clientId',
  'statusIds',
  'staffIds',
  'scheduledOnFrom',
  'scheduledOnTo',
  'createdOnFrom',
  'createdOnTo',
  'requestCategoryIds',
  'clientId',
  'serviceReport',
  'lateVisit',
  'exportXLSX',
  'completionTimeFrom',
  'completionTimeTo',
  'hubIds',
  'isItemsCollected',
  'isVerified',
];

export function getRequests(options = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    const queryParams = {};

    mapOptionsToPayload.forEach((name) => {
      if (options[name]) queryParams[name] = options[name];
    });

    if (options.limit) {
      queryParams['$limit'] = options.limit;
    }

    if (options.skip) {
      queryParams['$skip'] = options.skip;
    }

    if (options.searchString) {
      queryParams['$q'] = options.searchString;
    }

    if (options.sort && options.sort.field) {
      queryParams[`$sort[${options.sort.field}]`] =
        options.sort.orderDirection === 'asc' ? 1 : -1;
    }
    if (options.from) {
      queryParams['$from'] = options.from;
    }
    if (options.to) {
      queryParams['$to'] = options.to;
    }

    const params = { params: queryParams };
    return GET('/v2/requests', accessToken, params)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function updateRequestDetails(requestDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(
      `/v2/requests/${requestDetails.publicId || requestDetails.id}`,
      accessToken,
      { data: requestDetails }
    )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

// Sophie
export function deleteRequest(requestDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return DELETE(`/v2/requests/${requestDetails.publicId || requestDetails.id}`, accessToken)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function setCurrentView(mode) {
  return { type: SET_IS_VIEW_MODE, mode };
}

export function sendToClientVia(payload) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('service-report-actions', accessToken, { data: payload })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function getRequestItems(requestIds, params = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const searchParams = { ...params, requestIds };

    return GET('/request-items', accessToken, { params: searchParams })
      .then(
        (response) => {
          // dispatch(setRequestItems(response.data.data));
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function requestActions(requestId, action) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const { request } = getState().request;
    const payload = {
      action,
      requestId,
    };

    return POST(REQUEST_ACTIONS, accessToken, { data: payload })
      .then(
        (response) => {
          const updatedJob = { ...request, ...response.data };
          return Promise.resolve(updatedJob);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}
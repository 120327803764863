import React from 'react';
import { compose } from 'redux';
import { Chip, withStyles } from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';

const ReviewChip = props => {
  const { classes, tableChip = false } = props;

  const chipClassName = classNames(classes.chip, classes.reviewChip, {
    [classes.cardChip]: !tableChip,
    [classes.tableChip]: tableChip,
  });

  return (
    <Chip
      size='small'
      label='In Review'
      className={chipClassName}
    />
  );
};

export default compose(
  withStyles(styles, { index: 1 }),
)(ReviewChip);

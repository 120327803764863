export default theme => ({
  root: {
  },
  header: {
    backgroundColor: theme.palette.background.muted2,
  },

  requestHeader: {
    marginBottom: 5,
  },

  requestAdditionalSettings: {
    padding: theme.spacing(2),
  },

  requestClientDetail:{
    marginTop: 15,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  clientButton: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginLeft: 10,
    borderBottom: `2px dashed ${theme.palette.text.secondary}`
  },
  clientName: {
    color: theme.palette.text.secondary,
    marginRight: 15,
    paddingLeft: 2,
  },
  addButton: {
    marginBottom: 2
  },
  requestType: {
    padding: theme.spacing(3)
  },
  requestTypeButton: {
    borderRadius: '0 0 0 0',
    backgroundColor: theme.palette.background.muted2,
    border: 'none',
    borderTop: `3px solid ${theme.palette.common.white}`,
    color: theme.palette.text.secondary,
    '&:disabled': {
      borderRadius: '0 0 0 0',
      backgroundColor: theme.palette.background.muted2,
      border: 'none',
      borderTop: `3px solid ${theme.palette.common.white}`,
      color: theme.palette.text.secondary,
    }
  },
  selectedButtonRight: {
    backgroundColor: theme.palette.background.paper,
    borderTop: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.text.primary
  },
  selectedButtonLeft: {
    backgroundColor: theme.palette.background.paper,
    borderTop: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.text.primary
  },
  requestTypeButtonText: {
    color: theme.palette.text.secondary,
  },
  selectedButtonTextLeft: {
    color: theme.palette.text.primary,
  },
  selectedButtonTextRight: {
    color: theme.palette.text.primary,
  },
  formField: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '360px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  fullTextField: {
    width: 480,
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },

  categoryList: {
    minHeight: 182,
    border: `1px solid ${theme.palette.border}`,
    width: '100%',
    padding: '0px 5px',
    borderRadius: 5
  },

  noCategory: {
    minHeight: 182,
    border: `1px solid ${theme.palette.border}`,
    width: '100%',
    padding: 10,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center'
  },

  catItem: {
    borderBottom: `1px solid ${theme.palette.border}`
  },

  save_btn: {
    background: '#479ecf',
    color: '#3A4457',
    boxShadow: 'unset'
  },
  btn_add: {
    color: '#0075FE'
  }
});

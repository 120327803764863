import React from 'react';

// Redux
import { Field } from 'redux-form';

// Customised components
import { TextInput } from 'components/inputs';

export default props => {
  const { translate } = props;

  return (
    <Field
      name='notes'
      label={translate('noteDetails')}
      component={TextInput}
      variant='outlined'
      margin='dense'
      multiline
      rows="5"
    />
  );
};
export default theme => ({
  root: {},
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  modalTitle: {
    fontSize: 24,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.header
  },
  titleText: {
    fontWeight: 600,
  },
  modalContent: {
    padding: theme.spacing(2)
  },
  modalBody: {},
  modalFooter: {
    padding: theme.spacing(2)
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.danger.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
      color: theme.palette.danger.contrastText,
      borderColor: theme.palette.danger.main,
      fontWeight: 550,
    }
  },
});

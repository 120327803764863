import React, { Component, forwardRef } from 'react';
import { withRouter, Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getRequests, clearRequest } from 'redux/request';
import { updateRequestDetails } from 'redux/request';
import { deleteRequest } from 'redux/request';
import { reset } from 'redux-form';
import { setIsRequestsFiltering } from 'redux/global';
import {
  QUERY_STRING_DECODE,
  QUERY_STRING_ENCODE,
  REQUEST_STATUS,
  SIDE_BAR_FILTER_FORM_NAME,
} from 'common/constant';
// Material helpers
import {
  withStyles,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Chip,
  Grid,
  Typography,
  IconButton,
  Popover,
  Box,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core';

import payloadPreparation from '../components/utils/payloadPreparation';

// Material icons
import {
  ArrowUpward,
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ToggleOff,
  Visibility,
  ViewColumn,
  FolderOpen as FolderOpenIcon,
  MoreHoriz as MoreHorizIcon,
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletContent,
  StatusChip,
  FilterSidebar,
} from 'pages/Dashboard/components';
import { formatDate, getDateTimeFormat } from 'lib/formatter';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import queryString from 'query-string';
import { convertDateInParams } from './utils';

// Component styles
import styles from './styles';
import ReviewChip from 'pages/Dashboard/components/ReviewChip';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ToggleOff: forwardRef((props, ref) => <ToggleOff {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
};

const REQUEST_STATUSES = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'CONVERTED', label: 'Converted' },
];

class RequestsTable extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.queryToFetchRequests = this.queryToFetchRequests.bind(this);
    this.tableRef = React.createRef();
    this.state = {
      includingArchived: false,
      rowsPerPage: 10,
      anchorEl: null,
      selectedRow: {},
      isOpenModalConfirm: false,
      isOpenConfirmDeleteModal: false,
      isOpenConfirmItemsCollectedModal: false,
    };
    this.handleRefreshTable = this.handleRefreshTable.bind(this);
    this.updateFilterStatus = this.updateFilterStatus.bind(this);
    this.onSaveToParams = this.onSaveToParams.bind(this);
    this.onGetSearchParams = this.onGetSearchParams.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.clearRequest();
  }

  componentWillUnmount() {
    this._isMounted = false;
    // reset filter form
    this.props.setIsRequestsFilteringDispatch(false);
    this.props.resetForm(SIDE_BAR_FILTER_FORM_NAME);
  }

  handleRefreshTable(event) {
    this.setState({ includingArchived: event.target.checked });
    if (this.tableRef.current) {
      this.tableRef.current.onQueryChange({
        includingArchived: event.target.checked,
        page: 0,
      });
    }
  }

  onSaveToParams = (values) => {
    const { sort, from, to, skip, ...urlSearch } = values;
    const payload = payloadPreparation(urlSearch);
    const stringified = queryString.stringify(payload, QUERY_STRING_ENCODE);
    this.props.history.replace({ ...this.props.location, search: stringified });
  };

  onSaveFilters = (values) => {
    this.onSaveToParams(values);
    //  make sure values not string
    if (this.tableRef.current && values && Object.keys(values).length) {
      this.tableRef.current.onQueryChange({
        includingArchived: this.state.includingArchived,
        page: 0,
        ...values,
      });
    }
  };

  updateFilterStatus = (status) => {
    this.props.setIsRequestsFilteringDispatch(status);
  };

  onGetSearchParams = () => {
    const getSearchParams = this.props.location.search;
    const parseSearch = queryString.parse(getSearchParams, QUERY_STRING_DECODE);
    return { ...parseSearch };
  };

  queryToFetchRequests(query) {
    return new Promise((resolve, reject) => {
      let sortParams = {};
      if (query.orderBy) {
        sortParams = {
          field: query.orderBy['field'],
          orderDirection: query.orderDirection,
        };
      }
      const searchParams = this.onGetSearchParams();
      const payload = payloadPreparation(query, sortParams);
      const result = convertDateInParams({ ...payload, ...searchParams });
      this.props.getRequests(result).then(
        (response) => {
          if (response.status === 200) {
            resolve({
              data: response.data.data,
              page: query.page,
              totalCount: parseInt(response.data.total),
            });
          }
        },
        (error) => {
          console.log('error', error);
          reject(error);
        }
      );
    });
  }

  render() {
    const { classes, className, translate, dispatchUpdateRequest, dispatchDeleteRequest } = this.props;
    const rootClassName = classNames(classes.root, className);
    const {
      anchorEl,
      selectedRow,
      isOpenModalConfirm,
      isOpenConfirmDeleteModal,
    } = this.state;

    const setAnchorEl = (value) => this.setState({ anchorEl: value });
    const setSelectedRow = (value = {}) =>
      this.setState({ selectedRow: value });
    const cellStyle = {
      paddingLeft: 10,
      paddingRight: 10,
      verticalAlign: 'top',
      minWidth: 100,
    };

    const options = {
      pageSize: this.state.rowsPerPage,
      pageSizeOptions: [10, 25, 50],
      actionsColumnIndex: -1,
      headerStyle: cellStyle,
      sorting: true,
      search: false,
      debounceInterval: 1000,
      draggable: false,
    };

    const handleClick = (event, rowData) => {
      setAnchorEl(event.currentTarget);
      setSelectedRow(rowData);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setSelectedRow({});
    };

    const handleClickView = () => {
      window.open(`requests/${selectedRow.publicId}`);
      handleClose();
    };
    const handleEdit = () => {
      window.open(`requests/${selectedRow.publicId}/edit`);
      handleClose();
    };
    const handleShowConfirmationModal = () => {
      setAnchorEl(null);
      this.setState({ isOpenModalConfirm: true });
    };
    const handleCloseModal = () => this.setState({ isOpenModalConfirm: false });
    const handleConfirmArchive = async () => {
      const wantToArchive = selectedRow.archived ? false : true;
      const result = await dispatchUpdateRequest({
        publicId: selectedRow.publicId,
        archived: wantToArchive,
      }).catch(e => e);
      if (result.e) return showErrorMessage(result.e);
      handleCloseModal();

      this.tableRef.current.onQueryChange({
        page: 0,
      });
    };

    const handleShowConfirmationDeleteModal = () => {
      setAnchorEl(null);
      this.setState({ isOpenConfirmDeleteModal: true });
    };
    const handleCloseDeleteModal = () => this.setState({ isOpenConfirmDeleteModal: false });
    
    // Sophie
    const handleConfirmDelete = async () => {
      // const action = 'DELETE_REQUEST';
      const result = await dispatchDeleteRequest({
        publicId: selectedRow.publicId,
        deleted: true,
      }).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
      showSuccessMessage(translate('deleteRequestSuccessfully'));
      handleCloseDeleteModal();
      this.tableRef.current.onQueryChange({ page: 0 });
    };
    
    const getReviewStatus = (verified, startVerifiedAt) => {
      if (verified) return 'REVIEWED';
      return startVerifiedAt ? 'IN_REVIEW' : '';
    };

    const columns = [
      {
        field: 'statusId',
        title: translate('status'),
        cellStyle,
        sorting: true,
        filtering: false,
        render: (rowData) => {
          return (
            <>
              <StatusChip
                request
                tableChip
                status={rowData.statusId}
                archived={rowData.archived}
                reviewStatus={getReviewStatus(
                  rowData.verified,
                  rowData.startVerifiedAt
                )}
              />
              {getReviewStatus(rowData.verified, rowData.startVerifiedAt) ===
                'IN_REVIEW' && <ReviewChip tableChip />}
            </>
          );
        },
      },
      {
        field: 'publicId',
        title: translate('id_category'),
        cellStyle,
        sorting: false,
        filtering: false,
        render: (rowData) => {
          return (
            <Grid container spacing={1}>
              <Tooltip title={translate('viewRequestDetails')}>
                <Link to={`/requests/${rowData.publicId}`} target="_blank">
                  {rowData.publicId}
                </Link>
              </Tooltip>
              <Grid container className="mt_5">
                {rowData.orderId}
              </Grid>
              {rowData.categories && rowData.categories.length > 0 && (
                <Grid item container direction="row" alignItems="center">
                  <FolderOpenIcon />
                  <Typography className={classes.categoryName}>
                    {rowData.categories[0].name}
                  </Typography>
                  {rowData.categories.length > 1 && (
                    <Tooltip
                      title={`${translate(
                        'Category:Category'
                      )}: ${rowData.categories
                        .map((cat) => cat.name)
                        .join(', ')}`}
                    >
                      <Chip
                        label={`+ ${rowData.categories.length - 1}`}
                        size="small"
                        className={classes.categoryChip}
                      />
                    </Tooltip>
                  )}
                </Grid>
              )}
            </Grid>
          );
        },
      },
      {
        field: 'clientName',
        title: translate('client'),
        cellStyle,
        sorting: true,
        filtering: false,
        render: (rowData) => {
          const { client: { id, displayName, phoneNumber } = {} } = rowData;
          return (
            <Grid>
              <Tooltip title={translate('viewClientDetails')}>
                <Link to={`/clients/${id}`} target="_blank">
                  {displayName}
                </Link>
              </Tooltip>
              <Grid className="mt_10" container>
                {phoneNumber}
              </Grid>
            </Grid>
          );
        },
      },
      {
        field: 'address',
        title: translate('address'),
        cellStyle: { ...cellStyle, minWidth: 200 },
        filtering: false,
        sorting: false,
        render: (rowData) => {
          if (!rowData.property) return null;
          return (
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                {rowData.property.name}
              </Typography>
              {[rowData.property.unitNumber, rowData.property.address]
                .filter(Boolean)
                .join(', ')}
            </div>
          );
        },
      },
      {
        field: 'hub',
        title: translate('Hub:hub'),
        cellStyle,
        filtering: false,
        sorting: false,
        render: (rowData) => {
          if (!rowData.hub) return null;
          return (
            <>
              <Grid container className="mb_5">
                <Typography variant="h6">{rowData.hub.name}</Typography>
              </Grid>
              <Grid container>
                <Typography>{rowData.hub.address}</Typography>
              </Grid>
            </>
          );
        },
      },
      {
        field: 'createdAt',
        title: translate('createdAtAndBy'),
        sorting: true,
        filtering: false,
        cellStyle,
        render: (rowData) => {
          return (
            <Grid>
              <Grid container>
                {rowData.createdAt &&
                  formatDate(rowData.createdAt, getDateTimeFormat())}
              </Grid>
              <Grid container>
                <Typography className={classes.created_by}>
                  {rowData.createdByUser && (
                    <span>{rowData.createdByUser.fullName}</span>
                  )}
                </Typography>
              </Grid>
            </Grid>
          );
        },
      },
      {
        field: 'action',
        filtering: false,
        sorting: false,
        width: '5%',
        render: (rowData) => {
          return (
            <>
              <IconButton onClick={(item) => handleClick(item, rowData)}>
                <MoreHorizIcon />
              </IconButton>
            </>
          );
        },
      },
    ];

    return (
      <>
        <FilterSidebar
          requestFiltering
          classNames={classNames}
          statues={REQUEST_STATUSES}
          onSubmit={this.onSaveFilters}
          updateFilterStatus={this.updateFilterStatus}
        />
        <Dialog
          open={isOpenConfirmDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography variant="h4" className="mb_5">
              {translate('deleteThisRequest')}
            </Typography>
            {translate('deleteThisRequestDescription')}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} variant="contained">
              {translate('Common:close')}
            </Button>
            <Button
              onClick={handleConfirmDelete}
              className={classes.confirm_archived}
              variant="contained"
              autoFocus
            >
              {translate('Common:delete')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isOpenModalConfirm}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography variant="h4" className="mb_5">
              {translate(
                selectedRow.archived
                  ? 'unarchivedThisRequest'
                  : 'archivedThisRequest'
              )}
            </Typography>
            {translate(
              selectedRow.archived
                ? 'unarchivedThisRequestDescription'
                : 'archivedThisRequestDescription'
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} variant="contained">
              {translate('Common:close')}
            </Button>
            <Button
              onClick={handleConfirmArchive}
              className={classes.confirm_archived}
              variant="contained"
              autoFocus
            >
              {translate(
                selectedRow.archived ? 'Common:unarchive' : 'Common:archive'
              )}
            </Button>
          </DialogActions>
        </Dialog>
        <Popover
          classes={{ paper: styles.border_adjust }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box p={2}>
            <MenuItem onClick={handleClickView}>
              <Typography className={styles.edit_color}>
                {translate('Common:view')}
              </Typography>
            </MenuItem>
            { selectedRow.statusId !== REQUEST_STATUS.CONVERTED ? 
              <>
                <MenuItem onClick={handleEdit} className="mt_10">
                  <Typography>{translate('Common:edit')}</Typography>
                </MenuItem>
                <MenuItem onClick={handleShowConfirmationModal} className="mt_10">
                  <Typography>
                    {translate(selectedRow.archived ? 'Common:unarchive' : 'Common:archive')}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleShowConfirmationDeleteModal}
                  className="mt_10"
                >
                  <Typography className={classes.delete_btn}>
                    {translate('Request:deleteRequest')}
                  </Typography>
                </MenuItem>
              </>
              : null }
          </Box>
        </Popover>
        <Portlet className={rootClassName}>
          <PortletContent noPadding>
            <MaterialTable
              title={null}
              columns={columns}
              options={options}
              icons={tableIcons}
              tableRef={this.tableRef}
              data={(query) =>
                this.queryToFetchRequests({
                  ...query,
                })
              }
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                    <div className={classes.includeArchiveCheckbox}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.includingArchived}
                            onChange={this.handleRefreshTable}
                            value="includingArchived"
                          />
                        }
                        label={translate('showArchived')}
                      />
                    </div>
                  </div>
                ),
              }}
              localization={{
                toolbar: {
                  searchTooltip: translate('Common:search'),
                  searchPlaceholder: translate('Common:search'),
                },
                pagination: {
                  labelRowsSelect: translate('Common:rows'),
                  labelDisplayedRows: ` {from}-{to} ${translate(
                    'Common:of'
                  )} {count}`,
                  firstTooltip: translate('Common:firstPage'),
                  previousTooltip: translate('Common:previousPage'),
                  nextTooltip: translate('Common:nextPage'),
                  lastTooltip: translate('Common:lastPage'),
                },
                header: {
                  actions: translate('Common:actions'),
                },
              }}
            />
          </PortletContent>
        </Portlet>
      </>
    );
  }
}

RequestsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  clearRequest: () => dispatch(clearRequest()),
  getRequests: (options = {}) => dispatch(getRequests(options)),
  setIsRequestsFilteringDispatch: (status) => dispatch(setIsRequestsFiltering(status)),
  resetForm: (name) => dispatch(reset(name)),
  dispatchUpdateRequest: (request) => dispatch(updateRequestDetails(request)),
  dispatchDeleteRequest: (request) => dispatch(deleteRequest(request)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(RequestsTable);

import React, { useEffect, useState } from 'react';

// Externals
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getProducts,
  addProduct,
  setCurrentProduct,
  updateProducts,
  deleteProduct,
  clearProductsServices
} from 'redux/product';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Typography,
  Button,
  List,
  Grid
} from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletContent,
  PortletLabel,
} from 'pages/Dashboard/components';
import { ProductServiceModal, ConfirmDeleteProductServiceModal } from '../../modals';
import ListItem from '../../lists/ListItem';

// Component styles
import styles from './styles';

const mapStateToProps = state => ({
  productDetails: state.form.productDetails && state.form.productDetails.values,
  products: state.product.products,
  productId: state.product.productId,
});

const mapDispatchToProps = dispatch => ({
  getProducts: (type) => { dispatch(getProducts(type)); },
  setCurrentProduct: (product) => { dispatch(setCurrentProduct(product)); },
  addProduct: (product, callback) => { dispatch(addProduct(product, callback)); },
  updateProducts: (id, form, callback) => { dispatch(updateProducts(id, form, callback)); },
  deleteProduct: (id, callback) => { dispatch(deleteProduct(id, callback)); },
  clearProductsServices: () => { dispatch(clearProductsServices()); },
});

function ProductsTable(props) {
  const {
    classes,
    className,
    deleteProduct,
    products,
    productId,
    updateProducts,
    productDetails,
    setCurrentProduct,
    getProducts,
    addProduct,
    clearProductsServices,
    taxRates,
    taxComponents,
    translate,
  } = props;
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [showProductForm, setShowProductForm] = useState(false);
  const rootClassName = classNames(classes.root, className);

  useEffect(() => {
    return () => clearProductsServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const callback = () => {
      setShowProductForm(!showProductForm);
      getProducts('products');
      clearProductsServices();
    };
    if (productId) {
      updateProducts(productId, productDetails, callback);
    } else {
      productDetails.typeId='PRODUCT';
      productDetails.sortOrder = Object.keys(products).length + 1;
      addProduct(productDetails, callback);
    }
  };

  const getProduct = id => {
    Object.keys(products).forEach(key => {
      const product = products[key];
      if (product.id.toString() === id) {
        setCurrentProduct(product);
      }
    });
  };

  function handleDeleteProduct() {
    setConfirmDelete(true);
  };

  function handleConfirmDelete() {
    setConfirmDelete(false);
    setShowProductForm(false);
    clearProductsServices();
    deleteProduct(productId);
  };

  return (
    <Portlet className={rootClassName}>
      <PortletHeader>
        <PortletLabel
          title={translate('products')}
        />
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => setShowProductForm(!showProductForm)}
        >
          {translate('addProduct')}
        </Button>
      </PortletHeader>
      <PortletContent>
        <Grid container>
          <Grid item md={1} sm={12}> </Grid>
          <Grid item md={3} sm={12}>
            <Typography gutterBottom variant="h5" component="h2">
              Product Name
            </Typography>
          </Grid>
          <Grid item md={3} sm={12}>
            <Typography gutterBottom variant="h5" component="h2">
              Description
            </Typography>
          </Grid>
          <Grid item md={1} sm={12}>
            <Typography gutterBottom variant="h5" component="h2">
              {translate('Common:model')}
            </Typography>
          </Grid>
          <Grid item md={2} sm={12}>
            <Typography gutterBottom variant="h5" component="h2">
              Unit Price
            </Typography>
          </Grid>
          <Grid item md={1} sm={12}>
            <Typography gutterBottom variant="h5" component="h2">
              Tax Rate
            </Typography>
          </Grid>
        </Grid>
        {
          products && Object.keys(products).length > 0 ?
            <Droppable droppableId='product'>
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {
                    Object.keys(products).map((item, index) => {
                      const product = products[item];
                      return (
                        <ListItem
                          id={product.id}
                          key={product.id}
                          index={index}
                          product={product}
                          handleClick={(row) => {
                            const { id } = row.currentTarget;

                            getProduct(id);
                            setShowProductForm(!showProductForm);
                          }}
                        />
                      );
                    })
                  }
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
            : <Typography>
              {translate('noProduct')}
            </Typography>
        }
      </PortletContent>

      {
        showProductForm &&
          <ProductServiceModal
            taxRates={taxRates}
            taxComponents={taxComponents}
            product
            open={showProductForm}
            handleClose={() => {
              setShowProductForm(false);
              clearProductsServices();
            }}
            handleSubmit={handleSubmit}
            handleDelete={handleDeleteProduct}
            translate={translate}
            title={productId ? 'Product:updateProduct' : 'Product:addNewProduct'}
          />
      }
      {
        confirmDelete &&
          <ConfirmDeleteProductServiceModal
            product
            open={confirmDelete}
            handleClose={() => {
              setConfirmDelete(false);
            }}
            handleConfirmDelete={handleConfirmDelete}
            translate={translate}
          />
      }
    </Portlet>
  );
};

ProductsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};


export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ProductsTable);

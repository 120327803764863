import React, { useRef } from 'react';
import moment from 'moment';

// redux
import { fetchEnterpriseList } from 'redux/enterprise';
import { useDispatch } from 'react-redux';

import MaterialTable, { MTableToolbar } from 'material-table';
import { Button, Grid, Typography, Tooltip } from '@material-ui/core';
import { StatusChip } from '../../components';

import styles from './table.module.scss';
import { Add as AddIcon } from '@material-ui/icons';
import { showErrorMessage } from 'lib/notifier';
import { getDateTimeFormat } from 'lib/formatter';

const options = {
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  actionsColumnIndex: -1,
  sorting: true,
  search: true,
  debounceInterval: 1000,
  draggable: false,
};

const renderColumn = ({ t }) => [
  {
    field: 'active',
    title: t('status'),
    sorting: true,
    cellStyle: { verticalAlign: 'top' },
    filtering: false,
    render: (rowData) => {
      return <StatusChip isActive={rowData.active} />;
    },
  },
  {
    field: 'createdAt',
    title: t('createdAt/By'),
    sorting: true,
    filtering: false,
    cellStyle: { verticalAlign: 'top' },
    render: (rowData) => {
      if (!rowData.createdAt) return <Typography>-</Typography>;
      return (
        <Grid container>
          <Typography>
            {moment(rowData.createdAt).format(getDateTimeFormat())}
          </Typography>
          <Grid item container>
            <Typography className={styles.textGrey}>
              {rowData.createdBy}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: 'id',
    title: t('id'),
    cellStyle: { verticalAlign: 'top' },
    sorting: false,
    filtering: false,
    render: (rowData) => {
      return <Typography>{rowData.companyId}</Typography>;
    },
  },
  {
    field: 'name',
    title: t('enterpriseName'),
    cellStyle: { verticalAlign: 'top' },
    sorting: true,
    filtering: false,
    render: (rowData) => {
      if (!rowData.tradingName && !rowData.companyName) return <Typography>-</Typography>;
      if (rowData.tradingName) return <Typography>{rowData.tradingName}</Typography>;
      return <Typography>{rowData.companyName}</Typography>;
    },
  },
  {
    field: 'contact',
    title: t('contact'),
    sorting: false,
    filtering: false,
    cellStyle: { verticalAlign: 'top' },
    render: (rowData) => {
      if (
        !rowData.contactPerson ||
        !Object.keys(rowData.contactPerson).length ||
        !rowData.contactPerson.user
      )
        return <Typography>-</Typography>;
      return (
        <Grid container direction="column">
          <Typography>{rowData.contactPerson.user.fullName}</Typography>
          <Typography className={styles.textGrey}>
            {rowData.contactPerson.user.countryPhoneCode}
            {rowData.contactPerson.user.phoneNumber}
          </Typography>
          <Typography className={styles.textGrey}>
            {rowData.contactPerson.user.email}
          </Typography>
        </Grid>
      );
    },
  },
  {
    field: 'categoryTags',
    title: t('tag'),
    sorting: false,
    filtering: false,
    cellStyle: { verticalAlign: 'top' },
    render: (rowData) => {
      if (!Array.isArray(rowData.categoryTags) || !rowData.categoryTags.length)
        return <Typography>-</Typography>;

      return (
        <Grid container alignItems="center">
          <Typography>{rowData.categoryTags[0]}</Typography>
          {rowData.categoryTags.length > 1 && (
            <Tooltip
              title={`${t('Category:category')}: ${rowData.categoryTags.join(
                ', '
              )}`}
            >
              <Grid item className={styles.activeState}>
                <Grid
                  container
                  item
                  className={styles.chip}
                  alignItems="center"
                  justify="center"
                >
                  <Typography className={styles.chipText}>
                    +{rowData.categoryTags.length - 1}
                  </Typography>
                </Grid>
              </Grid>
            </Tooltip>
          )}
        </Grid>
      );
    },
  },
  {
    field: 'total',
    title: t('total'),
    cellStyle: { verticalAlign: 'top' },
    sorting: false,
    filtering: false,
    render: (rowData) => <Typography>{rowData.numberWorker}</Typography>,
  },
];

const Table = ({ t, history }) => {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const columns = renderColumn({ t });

  const fetchCompany = async (query) => {
    const { page, pageSize, orderBy, orderDirection, search } = query;
    let sortParams = '';
    if (orderBy) {
      const sortOrder = orderDirection === 'asc' ? 1 : -1;
      const sortKey = `$sort[${orderBy['field']}]`;
      sortParams = { [sortKey]: sortOrder };
    }

    const params = {
      $limit: pageSize,
      $offset: page * pageSize,
      ...(sortParams || {}),
      ...(search ? { $q: search } : {}),
    };

    const response = await dispatch(fetchEnterpriseList({ params }));
    if (response.message) {
      showErrorMessage(response.message);
      return Promise.reject(response.message);
    }

    return Promise.resolve({
      data: response.data,
      page,
      totalCount: response.total,
    });
  };

  const handleRowClick = (rowData) =>
    window.open(`/enterprise/${rowData.companyId}`, '_blank');

  const handleCreateNewAccount = () => history.push('/enterprise/new');

  return (
    <MaterialTable
      style={{
        display: 'grid',
        gridTemplateColums: '1fr',
        gridTemplateRows: 'auto 1fr auto',
        height: 'calc(100vh - 112px)',
      }}
      title={null}
      columns={columns}
      options={options}
      tableRef={tableRef}
      data={fetchCompany}
      onRowClick={(event, rowData) => handleRowClick(rowData)}
      components={{
        Toolbar: (props) => (
          <Grid container justify="flex-end" alignItems="center">
            <MTableToolbar {...props} />
            <Grid item className={styles.spacingRight}>
              <Button
                onClick={handleCreateNewAccount}
                className={styles.btnNew}
                variant="contained"
              >
                <AddIcon />
                {t('newAccount')}
              </Button>
            </Grid>
          </Grid>
        ),
      }}
    />
  );
};

export default Table;

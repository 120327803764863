import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// Material helpers
import { withStyles } from '@material-ui/core';

// Custom components
import { StaffsTable, StaffsHeader } from '../components/utils';

// Component styles
import styles from './style';

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
};

function StaffList(props) {
  const { classes, routes, match, translate } = props;
  const tableRef = useRef();

  return (
    <div className={classes.root}>
      <StaffsHeader tableRef={tableRef} routes={routes} match={match} translate={translate} />
      <div className={classes.content}>
        <StaffsTable tableRef={tableRef} translate={translate} />
      </div>
    </div>
  );
};

StaffList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(StaffList);

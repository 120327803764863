import { change } from 'redux-form';

import { showErrorMessage } from 'lib/notifier';
import { GET, POST, PATCH , DELETE } from 'lib/api';

// Actions
const SET_CURRENT_STAFF = 'SET_CURRENT_STAFF';
const SET_STAFFS = 'SET_STAFFS';
const SET_ROLES = 'SET_ROLES';
const SET_UPLINE = 'SET_UPLINE';
const SET_ADMIN = 'SET_ADMIN';
const SET_CLEAR_STAFF = 'SET_CLEAR_STAFF';
const SET_CLEAR_STAFFS = 'SET_CLEAR_STAFFS';

const initialState = {
  staffId: null,
  staff: {},
  staffs: [],
  roles: null,
  upline: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_STAFF:
      return {
        ...state,
        staffId: action.details.id,
        staff: {
          ...state.staff,
          ...action.details,
        }
      };

    case SET_STAFFS:
      return {
        ...state,
        staffs: action.staffs
      };

    case SET_ROLES:
      return {
        ...state,
        roles: action.roles
      };

    case SET_UPLINE:
      return {
        ...state,
        upline: action.staffs
      };

    case SET_ADMIN:
      return {
        ...state,
        staff: {
          ...state.staff,
          roleId: action.role,
          reportingTo: null,
        }
      };

    case SET_CLEAR_STAFF:
      return {
        ...state,
        staffId: null,
        staff: {},
        upline: null
      };

    case SET_CLEAR_STAFFS:
      return {
        ...state,
        staffs: []
      };

    default: return state;
  }
}

// Action Creators
export function setCurrentStaff(details) {
  return { type: SET_CURRENT_STAFF, details };
}

export function setStaffs(staffs) {
  return { type: SET_STAFFS, staffs };
}

export function setRoles(roles) {
  return { type: SET_ROLES, roles };
}

export function setUpline(staffs) {
  return { type: SET_UPLINE, staffs };
}

export function setAdmin(role) {
  return { type: SET_ADMIN, role };
}

export function clearStaff() {
  return { type: SET_CLEAR_STAFF };
}

export function clearStaffs() {
  return { type: SET_CLEAR_STAFFS };
}


// side effects, only as applicable
// e.g. thunks, epics, etc
export function getStaffs(params) {
  params = { $limit: 100, ...params }; // Append $limit if it's unspecified
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return GET('/staffs', accessToken, { params }).then((response) => {
      if (response.status === 200 ) dispatch(setStaffs(response.data.data));

      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function getStaff(id, params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`staffs/${id}`, accessToken, { params }).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function addStaff(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/staffs', accessToken, { data }).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      showErrorMessage(error.response);
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function updateStaff(id, data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(`staffs/${id}`, accessToken, { data })
      .then((response) => {
        return Promise.resolve(response);
      },(error) => {
        return Promise.reject(error.response);
      }).catch(error => {
        return Promise.reject(error);
      });
  };
}

export function deleteStaff(id, params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return DELETE(`/staffs/${id}`, accessToken, { params }).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export function reinviteStaff(id, enterpriseAccountId) {
  return (dispatch, getState) => {
    const data = { staffId: id, action: 'REINVITE_STAFF', ...(enterpriseAccountId ? { enterpriseAccountId }: {}) };
    const { accessToken } = getState().auth;
    return POST('/staff-action/', accessToken, { data }).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export function makeAdmin() {
  return (dispatch, getState) => {
    const currentRole = getState().staff.staff.roleId;

    if (currentRole === 'ADMIN') {
      dispatch(change('staffForm', 'roleId', null));
      dispatch(setAdmin(null));
    } else {
      dispatch(change('staffForm', 'roleId', 'ADMIN'));
      dispatch(setAdmin('ADMIN')); }
  };
}

export function getRoles() {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    GET('/configs', accessToken).then(result => {
      const { data, status } = result;

      if (status === 200) {
        dispatch(setRoles(data.roles));
      };
    }).catch(error => {
      showErrorMessage(error);
    });
  };
}

export function getUpline() {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    GET('/staffs?roleId=5', accessToken).then(result => {
      const { data } = result.data;

      if (result.status === 200) {
        dispatch(setUpline(data));
      };
    }).catch(error => {
      showErrorMessage(error);
    });
  };
}

export function uploadAvatar(staffId, formData) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const params = { type: 'PROFILE_PICTURE', id: staffId };
    const data = { params, data: formData };
    return POST('/upload', accessToken, data).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function sendSmsToStaff(staffId, message) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    const data = {
      staffId,
      message,
      action: 'SEND_SMS'
    };

    return POST('/staff-action', accessToken, { data }).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
};


export function validateStaffInvitationToken(params) {
  return GET('/staff-invitation', null, { params }).then(response => {
    return Promise.resolve(response);
  }, (error) => {
    return Promise.reject(error.response);
  }).catch(err => {
    return Promise.reject(err);
  });
}

export function confirmStaffInvitation(data) {
  return POST('/staff-invitation', null, { data }).then(response => {
    return Promise.resolve(response);
  }, (error) => {
    return Promise.reject(error.response);
  }).catch(err => {
    return Promise.reject(err);
  });
}

export function getTimesheetStaffs(params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET('/timesheets/staffs', accessToken, { params }).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}
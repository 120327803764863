import React, { useRef, useState } from 'react';

// Redux
import { connect, useDispatch } from 'react-redux';
import { reduxForm, Field, Fields, change } from 'redux-form';
import moment from 'moment';

// Material helpers
import { Button, Grid, Typography, Box } from '@material-ui/core';
import { Checkbox, TextInput } from 'components/inputs';
import styles from './components/components.module.scss';

import { renderDateAndTime, renderTeamCard } from './components';
import { CategoryModal } from '..';

// this form is exported from shared components/index don't change folder structure
let EditVisitDetails = (props) => {
  const {
    handleSubmit,
    pristine,
    submitting,
    translate,
    handleCloseForm,
    isDisable,
    handleScheduleLater
  } = props;

  const [isOpenSelectTeamModal, setIsOpenSelectTeamModal] = useState(false);
  const dispatch = useDispatch();
  const [ isOpenCategoryModal, setIsOpenCategoryModal ] = useState(false);
  const currentCategoriesStaff = useRef();
  const handleOpenModalCategory = ()=> {
    setIsOpenCategoryModal(true);
  };
  const handleCloseModalCategory = ()=> {
    setIsOpenCategoryModal(false);
  };
  const handleCategorySelectionStaff = (data) => {
    dispatch(change('newStaff', 'categories', data));
    currentCategoriesStaff.current = data;
    setIsOpenCategoryModal(false);
    if (Array.isArray(data) && data.length) {
      const categoryIds = data.map((category) => category.id);
      dispatch(change('newStaff', 'categoryIds', categoryIds));
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column">
          <Grid container>
            <Grid item container sm={3} md={2} display="flex" alignItems="center">
              <Typography className={styles.label}>
                {translate('visitTitle')}
              </Typography>
            </Grid>
            <Grid item sm={9} md={10}>
              <Field
                name="title"
                component={TextInput}
                margin="dense"
                required
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container className="mt_15">
            <Grid item container sm={3} md={2} display="flex" alignItems="center">
              <Typography className={styles.label}>
                {translate('startOn')}
              </Typography>
            </Grid>
            <Grid item sm={9} md={10}>
              <Fields
                names={['startOnDate', 'startOnTime']}
                component={renderDateAndTime}
                type="text"
                disabled={isDisable}
              />
            </Grid>
          </Grid>

          <Grid container className="mt_15">
            <Grid item container sm={3} md={2} display="flex" alignItems="center">
              <Typography className={styles.label}>
                {translate('endOn')}
              </Typography>
            </Grid>
            <Grid item sm={9} md={10}>
              <Fields
                names={['endOnDate', 'endOnTime']}
                component={renderDateAndTime}
                type="text"
                disabled={isDisable}
              />
            </Grid>
          </Grid>

          <Grid container className="mt_10">
            <Grid item sm={3} md={2} />
            <Grid item sm={9} md={10}>
              <Field
                name="isScheduleLater"
                component={Checkbox}
                labelClassName={styles.label}
                label={translate('Job:scheduleLater')}
                onChange={event => handleScheduleLater(event)}
              />
            </Grid>
          </Grid>

          <Grid container className="mt_15">
            <Grid item container sm={3} md={2} display="flex" alignItems="center">
              <Typography className={styles.label}>
                {translate('team')}
              </Typography>
            </Grid>
            <Grid item sm={9} md={10}>
              <Field
                name="team"
                component={renderTeamCard}
                translate={translate}
                isOpenSelectTeamModal={isOpenSelectTeamModal}
                setIsOpenSelectTeamModal={setIsOpenSelectTeamModal}
                handleOpenModalCategory={handleOpenModalCategory}
                currentCategoriesStaff={currentCategoriesStaff}
              />
            </Grid>
          </Grid>

          <Grid container className="mt_15">
            <Grid item container sm={3} md={2} display="flex" alignItems="center">
              <Typography className={styles.label}>
                {translate('description')}
              </Typography>
            </Grid>
            <Grid item sm={9} md={10}>
              <Field
                name="description"
                component={TextInput}
                margin="dense"
                variant="outlined"
                rows="4"
                multiline
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              onClick={handleCloseForm}
              variant="contained"
              className={`${styles.btn_cancel} mr_5`}
            >
              {translate('discard')}
            </Button>
            <Button
              className={styles.save_btn}
              disabled={pristine || submitting}
              variant="contained"
              type="submit"
            >
              {translate('saveChanges')}
            </Button>
          </Box>
        </Box>
      </form>
      {isOpenCategoryModal && (
        <CategoryModal
          open={isOpenCategoryModal}
          handleCloseSelectCategoryModal={handleCloseModalCategory}
          translate={translate}
          categories={currentCategoriesStaff.current}
          handleCategorySelection={handleCategorySelectionStaff}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { translate } = ownProps;
  const visitDetails = ownProps.visitDetails || {};
  const { title, start, end, team, description } = visitDetails;
  return {
    initialValues: {
      title,
      startOnDate: start && moment(start).format(translate('Job:dateFormat')),
      startOnTime: start && moment(start).toDate(),
      endOnDate: end && moment(end).format(translate('Job:dateFormat')),
      endOnTime: end && moment(end).toDate(),
      team,
      description,
    },
  };
};

EditVisitDetails = reduxForm({
  form: 'EditVisitDetailsForm',
})(EditVisitDetails);

export default connect(mapStateToProps, null)(EditVisitDetails);

export default theme => ({
  root: {},
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  modalTitle: {
    backgroundColor: theme.palette.background.header
  },
  titleText: {
    fontWeight: 600,
  },
  modalFooter: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginRight: 'auto',
    color: theme.palette.danger.main,
    borderColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.main,
      color: theme.palette.danger.contrastText,
    },
  },
  submitButton: {
    marginLeft: theme.spacing(1),
  },
});

import React from 'react';
import { Trans } from 'react-i18next';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// Component styles
import { ConfirmDeleteModalStyles } from './styles';

const selectedItem = formValueSelector('productDetails');

const mapStateToProps = state => ({
  initialValues: {
    name: state.product.product.name || state.product.service.name || null,
    description: state.product.product.description || state.product.service.description || null,
    unitPrice: state.product.product.unitPrice || state.product.service.unitPrice || 0.00,
    taxRateId: (state.product.product.taxRate && state.product.product.taxRate.id) || (state.product.service.taxRate && state.product.service.taxRate.id) || false,
  },
  name: selectedItem(state, 'name'),
});

function ConfirmDeleteProductServiceModal(props) {
  const {
    classes,
    name,
    open,
    product=false,
    handleClose,
    handleConfirmDelete,
    translate,
  } = props;

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      // fullWidth
      className={classes.modalBox}
    >
      <DialogTitle className={classes.modalTitle}>
        <Typography className={classes.titleText}>
          { product ? translate('deleteProduct') : translate('deleteService') }
        </Typography>
        {open ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Typography className={classes.modalBody}>
          <Trans i18nKey="Product:permanentDelete">
            {name}
          </Trans>
        </Typography>
      </DialogContent>
      <DialogActions disableSpacing className={classes.modalFooter}>
        <Button onClick={handleClose} color="secondary">
          {translate('Common:cancel')}
        </Button>
        <Button
          className={classes.deleteButton}
          variant='contained'
          onClick={handleConfirmDelete}
        >
          {translate('Common:uppercaseDelete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(ConfirmDeleteModalStyles)
)(ConfirmDeleteProductServiceModal);
export default {
  // LANDING PAGE
  marketingMsg1: "The Best Software to Run Your Services Business",
  marketingMsg2:
    "Our technology lets you manage your staff, customer relationships and operations, likes magic",
  startFreeTrial: "Start Free Trial",
  greatForBusiness: "Great for these businesses:",
  businessOptions:
    "Cleaning | Plumbing | Electrical | Air conditioning <br/> Movers & Lorry | Landscaping | Handyman <br/> and more",
  howWMHelp: "How can <0><0></0></0> help?",
  beforeJob: "Before the Job",
  onlineBooking: "Online Booking",
  staffScheduling: "Staff Scheduling",
  jobEstimates: "Job Estimates",
  jobAssignment: "Job Assignment",
  duringTheJob: "During the Job:",
  employerDispatch: "Employer Dispatch",
  onMyWay: "On-My-Way SMS",
  gpsTracking: "Worker GPS Tracking",
  afterJob: "After the Job",
  eInvoicing: "E-receipts",
  digitalPayments: "Digital Payments",
  followUpMarketing: "Follow-up Marketing",
  staffTimesheets: "Staff Timesheets",
  signUpNow: "Sign Up Now",
  signUpReason:
    "We can help modernise your business, increase sales, and reduce your operating costs. Start your free trial today!",
  getStarted: "Get Started",
  talkToUs: "TALK TO US",
  runBusinessLikeMagic: "Empowering Productivity, Energizing Results!",
  contactUs: "Contact Us",
  termsOfService: "Terms of Service",
  privacyPolicy: "Privacy Policy",
  copyrightMY: "COPYRIGHT © 2023 FTS",
  regNumMY: "ALL RIGHTS RESERVED",
  copyrightSG: "COPYRIGHT © 2023 FTS",
  regNumSG: "ALL RIGHTS RESERVED",

  // ADVANTAGE SLIDER
  whyWM: "Why Choose <0><0></0></0>?",
  moreJobs: "Get more jobs",
  moreJobsReason:
    "Make it easier for people to find and book services from you. Your dedicated booking page makes it seamless for customers to book you online.",
  organiseTeam: "Organise Your Team",
  organiseTeamReason:
    "Scheduling and monitoring tools allow you to assign jobs to your team, and to track their progress. You will receive instant feedback from customers about service quality. Get the most out of your staff by keeping their work organised.",
  impressCustomers: "Impress your customers",
  impressCustomersReason:
    "Tech-savvy customers expect real-time information and excellent customer service. Give your customers an easy way to book, automate reminders of upcoming jobs and get real-time tracking (including on-the-way notifications) of your team members.",
  receivePayments: "Digital Receipts",
  receivePaymentsReason:
    "Get rid of the paperwork - make it easier to send invoices and legally-compliant receipts to your customers. Get paid faster and keep better records of work.",
  powerfulMarketingTools: "Powerful Marketing Tools",
  marketingToolsReason:
    "Keep your customers loyal by marketing to them on a regular basis. WorkPulse provides tools to remind your customers when their next service is due. Increase your sales by activating existing customers",

  // NAVBAR
  home: "Home",
  features: "Features",
  pricing: "Pricing",
  blog: "Blog",
  pages: "Pages",
  contact: "Contact",

  // PRICE LIST
  pricingPlan: "Pricing Plan",
  perMonth: "/ month",
  basic: "Basic",
  basicDesc:
    "For the ‘One-Man-Show’ with flexibility to add on features when needed",
  plus: "Plus",
  plusDesc:
    "For SMEs who want to go Digital and where Team tracking is important",
  pro: "Pro",
  proDesc:
    "For larger teams who want to grow their business with an all-inclusive price",
  enterprise: "Enterprise",
  enterpriseDesc:
    "For large companies who wish to highlight their brand more prominently in the market",
  oneUser: "1 User",
  threeUsers: "Up to 3 Users",
  sixUsers: "Up to 6 Users",
  enterpriseUsers: "More than 10 Users",
  personalisedBranding: "Personalised Branding",
  advancedCustomisations: "Advanced Customisations",
  jobManagement: "Job Management",
  workScheduling: "Work Scheduling",
  jobStatusNotification: "Job Status Notification",
  customerManagement: "Customer Management",
  customerDatabase: "Customer Database",
  smsNotification: "SMS Notification*",
  emailNotification: "Email Notification",
  digitalAccountingTools: "Digital Accounting Tools",
  paperlessQuotation: "Paperless Quotation",
  paperlessInvoicing: "Paperless Invoicing",
  paymentRecording: "Payment Recording",
  teamManagement: "Team Management",
  employeeTimesheets: "Employee Timesheets",
  realTimeTracking: "GPS Tracking for Customer",
  teamTracking: "GPS Tracking for Team",
  marketingTools: "Marketing Tools",
  smsMarketing: "SMS Marketing*",
  emailMarketing: "Email Marketing*",
  advancedSupport: "Advanced Support",
  dedicatedManager: "Dedicated Account Manager",
  extras: "Extras",
  extrasDescBasic: "GPS Tracking for Team and Employee Timesheets",
  extrasDescPlus: "Employee Timesheets",
  availableAtExtraCharge: "(available at extra charge)",
  allFeaturesUnderPlus: "All features under Plus Plan",
  allFeaturesUnderBasic: "All features under Basic Plan",
  chargesApply: "*usage charges apply",

  // FOOTER
  aboutUs: "About Us",
  faq: "FAQ",
  termsAndConditions: "Terms & Conditions",

  // FEATURE PAGE
  productFeatures: "Product Features",

  // CONTACT PAGE
  sendMessage: "Send Message",

  caseStudies: "Case Studies",
  trustedBy: "Trusted by",
  whyChooseWM: "Why WorkPulse?",
  letSimplify: "Let's simplify and optimise your worker operations",
  outsource: "Outsource operational complexity",
  outsourceDescription:
    "We have a nationwide network of vetted technicians to help you. Our team will fully manage their schedules so you can focus on your most important tasks.",
  completeService: "Complete service reporting",
  completeServiceDescription:
    "Our proprietary work management software maintains detailed service reports of all work delivered. Using our dashboard you can get real-time reporting of what work has been completed.",
  flexibleTeam: "Fully flexible teams",
  flexibleTeamDescription:
    "Grow and downsize your blue-collar teams with ease. There’s no need to maintain a full-time team, we provide a “workforce on the cloud”.",
  labourManagement: "Labour management",
  labourManagementDescription:
    "Why go through the headache of sourcing and managing your own blue-collar teams. Our account management team sources and manages teams from our database of thousands of vetted workers.",
  letUsSupport: "Let us support your business",
  copyRight: "FTS ∙",
  registration: "Registration No. 202001040E",
  caseStudy: "Case Study",
  mqdcTitle: "MQDC Development Corporation - replacing onsite handyman teams",
  mqdcDesciption:
    "MQDC is a major property developer of luxury condominiums. Previously each condo maintained a small team of technicians to deal with building faults reported by purchasers. Today, WorkPulse is their complete ‘workforce on the cloud’, managing everything from leaking piping, to replacing damaged floorboards for homeowners.",
  kimberlyTitle:
    "Kimberly-Clark - maintaining a vast network of towel dispensers",
  kimberlyDescription:
    "Kimberly Clark wanted a simpler way to maintain their network of hand-towel dispensers found in many public bathrooms. WorkPulse was able to source and manage a team of technicians to maintain hundreds of dispensers across Bangkok, allowing Kimberly Clark to focus on sales and account management, instead of managing  blue-collar team.",
  samsungTitle: "Samsung.com - powering nationwide appliance installation",
  samsungDescription:
    "Samsung needed a nationwide team to deliver and install bulky appliances (TVs, Aircons and Washing Machines) sold via their ecommerce platforms. As the exclusive partner, WorkPulse was able to provide a nationwide team of technicians to install customer purchases - increasing online sales and reducing internal operational headaches.",
  typeOfLabour: "Other type of labour",
  typeNeeded: "Type of Labour Needed",
  submitRequest: "Book a Demo",
  contactName: "Contact name",
  businessName: "Business name",
  email: "Email",
  phoneNumber: "Phone Number",
  city: "City",
  technicians: "AC Technicians",
  handyman: "Handyman",
  cleaners: "Cleaners",
  "electrician/plumber": "Electricians/Plumbers",
  contractors: "Contractors",
  flexibleSkill: "Flexible skilled",
  blueCollar: "blue-collar workforce for all your needs.",
  integratable:
    "WorkPulse is a platform that allows you to request fully-managed technicians for your business and keep track of the work delivered. All integratable into your existing operations workflow.",
  demand: "Your fully managed technician workforce available on demand.",
  powerBy: "Powered by",
  legal: "Legal",
  privacy: "Privacy Policy",
  tof: "Terms of Service",
  reachUs: "Reach us",
  flexibleWorkforce: "Flexible & Reliable Workforce",
  flexibleWorkforceDescription:
    "Outsource your blue-collar labour needs with our “workforce on the cloud”. Pay-as-you-go instead of maintaining a fixed payroll of technicians.",
  workerManagement: "Worker Management Software",
  workerManagementDescription:
    "Keep track of all the labour supplied or work completed, and pay only for work completed.",
  outsourceOperation: "Outsource Labour-intensive Operations",
  outsourceOperationDescription:
    "There’s no need for you to be distracted by managing your own technical workforce. We provide and manage pre-vetted blue-collar labour to help you focus on your core business.",
  easyBilling: "Easy Monthly Billing",
  easyBillingDescription:
    "Consolidated Monthly billing for all labour and services rendered.",
  getStartedNow: "Get Started",
  whatOther: "What's other type?",
  ourTeam: "Our teams deployed to your worksite",
  selectCountry: "Select country",

  aboutUsContent:
    "<0>WorkPulse by FTS</0> - formerly known as FTS for Enterprise - is a provider of flexible teams of  blue-collar workers in Southeast Asia, powered with advanced worker management technology.  Part of the FTS Group, one of Southeast Asia’s best-known home and office services technology companies, WorkPulse allows enterprise clients to request fully-managed technicians for their business and keep track of the work delivered via a beautiful and intuitive dashboard.  WorkPulse is available in Singapore, Malaysia and Thailand.  WorkPulse is trusted by leading organisations in Southeast Asia including Samsung, EcoWorld, CapitaLand and more.",
  learnMore: "Learn more",
  shortedAboutUsContent:
    "<0>WorkPulse by FTS</0> - formerly known as FTS for Enterprise - is a provider of flexible teams of  blue-collar workers in Southeast Asia, powered with advanced worker management technology.  Part of the FTS Group, one of Southeast Asia’s best-known home and office services technology companies,",
  seeLess: "see less",
  seeMore: "see more",
};

export default theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  modalContent: {
    padding: theme.spacing(3),
  },
  portletHeader: {
    // backgroundColor: theme.palette.calendar.blue,
    border: 'none'
  },
  portletWrap: {
    padding: 0,
    margin: 0,
    maxWidth: 600
  },
  portletLabel: {
    color: '#2E4F9B',
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
    }
  },
  portletContent: {
    padding: 0
  },

  menuList: {
    padding: 0
  },

  menuItem: {
    padding: '15px',
    fontSize: 14
  },
  iconStyle: {
    fontSize: 30,
    paddingRight: 15,
    color: theme.palette.calendar.blue
  }
});

import { momentWithTimeFormat } from 'lib/formatter';

const invalidTime = value => {
  return value && value.toString() === 'Invalid Date';
};

const WorkingHoursValidation = values => {

  let errors = { message: {} };
  const workingHourErrors = [];

  if (values) {
    // Validation for working hours =====
    if (values.workingHours && values.workingHours.length > 0) {
      values.workingHours.forEach((item, itemIndex) => {
        if (!item.working) { return; };
        if (!item.startTime) {
          workingHourErrors[itemIndex] = {
            ...workingHourErrors[itemIndex] || {},
            startTime: 'required',
          };
          errors = {
            ...errors,
            message: {
              ...errors.message,
              startTime: 'workingStartTimeRequired',
            }
          };
        }

        if (!item.endTime) {
          workingHourErrors[itemIndex] = {
            ...workingHourErrors[itemIndex] || {},
            endTime: 'required',
          };
          errors = {
            ...errors,
            message: {
              ...errors.message,
              endTime: 'workingEndTimeRequired',
            }
          };
        }

        if (item.startTime && invalidTime(item.startTime)) {
          workingHourErrors[itemIndex] = {
            ...workingHourErrors[itemIndex] || {},
            startTime: 'timeWrongFormat',
          };
          errors = {
            ...errors,
            message: {
              ...errors.message,
              startTime: 'timeWrongFormat',
            }
          };
        }

        if (item.endTime && invalidTime(item.endTime)) {
          workingHourErrors[itemIndex] = {
            ...workingHourErrors[itemIndex] || {},
            endTime: 'timeWrongFormat',
          };
          errors = {
            ...errors,
            message: {
              ...errors.message,
              endTime: 'timeWrongFormat',
            }
          };
        }

        if (item.startTime && item.endTime) {
          const startTime = momentWithTimeFormat(item.startTime);
          const endTime = momentWithTimeFormat(item.endTime);
          if (startTime.isSameOrAfter(endTime)) {
            workingHourErrors[itemIndex] = {
              ...workingHourErrors[itemIndex] || {},
              endTime: 'endTimeAfterStartTime',
            };
            errors = {
              ...errors,
              message: {
                ...errors.message,
                endTime: 'endTimeAfterStartTime',
              }
            };
          }
        }
      });
    }

    if (workingHourErrors.length > 0) {
      errors.workingHours = workingHourErrors;
    }
  }

  return errors;
};

export default WorkingHoursValidation;

import React, { useEffect, useState } from 'react';
import printJS from 'print-js';

// Externals
import { withRouter } from 'react-router-dom';

// Material components
import { Grid, withStyles, Typography } from '@material-ui/core';

// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  clearQuote,
  copyQuote,
  downloadPdf,
  emailQuote,
  getQuote,
  setCurrentQuote,
  smsQuote,
  updateQuote,
} from 'redux/quote';

// Shared components
import {
  ConfirmEmailModal,
  ConfirmSmsModal,
  LoadingModal,
  Portlet,
  PortletContent,
} from 'pages/Dashboard/components';
// TODO: Reuse note component for job/invoice/quote
// import { Notes } from '../../Jobs/JobDetails/components';
import { showSuccessMessage, showInfoMessage, showErrorMessage } from 'lib/notifier';
import { QuoteClientDetailsCard } from '../components/cards';
import { QuoteDetailsTable, QuoteToolbar } from '../components/utils';
import CopyQuote from '../forms/CopyQuote';

// Component styles
import styles from './styles';
import { getClientEmail, getClientPhoneObject } from 'lib/clientContact';

const mapStateToProps = state => ({
  clientId: state.quote.quote.client && state.quote.quote.client.id,
  client: state.quote.quote.client,
  quote: state.quote.quote,
  quoteStatuses: state.config.configs.quoteStatuses,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => ({
  clearQuote: () => dispatch(clearQuote()),
  downloadPdf: (quoteId) => dispatch(downloadPdf(quoteId)),
  getQuote: (quoteId) => dispatch(getQuote(quoteId)),
  emailQuote: (quoteId, email) => dispatch(emailQuote(quoteId, email)),
  smsQuote: (quoteId, phoneCountryCode, phoneNumber) =>
    dispatch(smsQuote(quoteId, phoneCountryCode, phoneNumber)),
  copyQuote: (quoteId, clientId, propertyId) => dispatch(copyQuote(quoteId, clientId, propertyId)),
  setCurrentQuote: (QuoteDetails) => dispatch(setCurrentQuote(QuoteDetails)),
  updateQuote: (QuoteDetails) => dispatch(updateQuote(QuoteDetails)),
});



function QuoteDetails(props) {
  const {
    classes,
    client,
    downloadPdf,
    emailQuote,
    getQuote,
    quoteId,
    quote,
    quoteStatuses,
    setCurrentQuote,
    smsQuote,
    copyQuote,
    translate,
    updateQuote,
    history,
    clearQuote,
    currentUser
  } = props;

  let companyTag = (currentUser.companies[0] || {}).tag || '';
  companyTag = companyTag.replace(/(\r\n|\n|\r)/gm, '').trim();

  const [isLoading, setIsLoading] = useState(false);
  const [confirmEmailModal, setConfirmEmailModal] = useState(false);
  const [confirmSmsModal, setConfirmSmsModal] = useState(false);
  const [copyQuoteModal, setCopyQuoteModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const discountObj = {
    discountType: quote.discountType,
    discountValue: quote.discountValue,
    discountFinal: quote.discountFinal,
  };

  useEffect(() => {
    getQuote(quoteId);
    return () => { clearQuote(); };
    // eslint-disable-next-line
  }, [quoteId]);

  const toggleArchiveQuote = event => {
    updateQuote({ id: quoteId, status: quote.status === 'ARCHIVED' ? 'UNARCHIVED' : 'ARCHIVED' }).then(response => {
      setCurrentQuote({ ...quote, status: response.data.status });
    }, error => {
      showErrorMessage(error);
    });
  };

  const updateQuoteStatus = (event, status) => {
    updateQuote({ id: quoteId, status }).then(response => {
      setCurrentQuote({ ...quote, status, ...response.data });
    }, error => {
      showErrorMessage(error);
    });
  };

  const handleEmailQuote = () => {
    setModalType('emailQuote');
    setConfirmEmailModal(true);
  };

  const handleSmsQuote = () => setConfirmSmsModal(true);
  const handleDownloadPdf = async () => {
    setIsLoading(true);
    const response = await downloadPdf(quoteId);
    setIsLoading(false);
    window.open(response.data.fileUrl, '_blank');
  };
  const handlePrint = async () => {
    setIsLoading(true);
    const response = await downloadPdf(quoteId);
    setIsLoading(false);
    printJS({
      printable: response.data.fileUrl,
      type: 'pdf',
      showModal: true,
      modalMessage: translate('Quote:preparePrinting')
    });
  };
  const handleCloseLoading = () => setIsLoading(false);

  const handleCloseConfirmEmailModal = () => {
    setModalType(null);
    setConfirmEmailModal(false);
  };

  const handleConfirmQuoteEmail = async value => {
    setConfirmEmailModal(false);
    showInfoMessage(translate('Quote:sendingEmail'));
    try {
      await emailQuote(quoteId, value.email);
      showSuccessMessage(translate('Quote:sentEmail'));
      getQuote(quoteId);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const handleCloseConfirmSmsModal = () => setConfirmSmsModal(false);

  const handleConfirmSmsPhone = async value => {
    setConfirmSmsModal(false);
    showInfoMessage(translate('Quote:sendingSMS'));
    try {
      await smsQuote(quoteId, value.countryPhoneCode, value.phoneNumber);
      showSuccessMessage(translate('Quote:sentSMS'));
      getQuote(quoteId);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  function handleCopyQuote() {
    setModalType('copyQuote');
    setCopyQuoteModal(true);
  }

  function handleCloseCopyQuote() {
    setModalType(null);
    setCopyQuoteModal(false);
  }

  async function submitCopyQuote(client, property) {
    showInfoMessage(translate('Quote:quoteCopying'));
    try {
      const response = await copyQuote(quoteId, client.id, property.id);
      showSuccessMessage(translate('Quote:quoteCopied'));
      history.push(`/quotes/${response.data.id}`);
      getQuote(quoteId);
    } catch (error) {
      showErrorMessage(error);
    }
  }

  function handleConvertToJob() {
    history.push(`/jobs/new/${quoteId}`);
  }

  return (
    <div className={classes.root}>
      <Grid container justify='center'>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <QuoteToolbar
            detailPage
            handleClickStatus={updateQuoteStatus}
            handleDownloadPdf={handleDownloadPdf}
            handleEmailQuote={handleEmailQuote}
            handlePrint={handlePrint}
            handleSmsQuote={handleSmsQuote}
            handleCopyQuote={handleCopyQuote}
            handleConvertToJob={handleConvertToJob}
            quote={quote}
            translate={translate}
          />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Portlet>
            <PortletContent className={classes.header}>
              <QuoteClientDetailsCard
                quote={quote}
                quoteStatuses={quoteStatuses}
                handleClickArchive={toggleArchiveQuote}
                handleClickStatus={updateQuoteStatus}
                handleEmailQuote={handleEmailQuote}
                translate={translate}
              />
            </PortletContent>
            <PortletContent className={classes.body}>
              <QuoteDetailsTable
                accountBalance={quote.accountBalance}
                clientMessage={quote.clientMessage}
                currency={quote.currency}
                quoteBalance={quote.quoteBalance}
                quoteItems={quote.items}
                subtotal={quote.subtotal}
                discount={discountObj}
                deposit={quote.depositFinal}
                tax={quote.taxFinal}
                total={quote.totalFinal}
                subTotalIncludeTax={quote.subTotalIncludeTax}
                withholdingTax23={quote.withholdingTax23}
                translate={translate}
              />
              <Grid container justify="center" className={classes.quoteMessage}>
                <Typography>
                  {
                    quote && quote.clientMessage ? quote.clientMessage : translate('thankYou')
                  }
                </Typography>
              </Grid>
              <PortletContent className={classes.spacingBottom}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="h4" className={classes.note}>
                      {translate('note')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {quote.notes
                        ? quote.notes
                        : translate('noNote')}
                    </Typography>
                  </Grid>
                </Grid>
              </PortletContent>
            </PortletContent>
          </Portlet>
        </Grid>
      </Grid>
      {/* <Grid>
        <Notes notes={quote.notes} translate={translate} />
      </Grid> */}
      { isLoading &&
        <LoadingModal open={isLoading} handleClose={handleCloseLoading} />
      }

      { confirmEmailModal && modalType === 'emailQuote' &&
        <ConfirmEmailModal
          onSubmit={handleConfirmQuoteEmail}
          open={confirmEmailModal && modalType === 'emailQuote'}
          handleClose={handleCloseConfirmEmailModal}
          translate={translate}
          title="sendQuoteToEmail"
          clientEmail={getClientEmail(client)}
        />
      }
      { confirmSmsModal &&
        <ConfirmSmsModal
          title='smsQuoteTo'
          clientPhone={getClientPhoneObject(client)}
          onSubmit={handleConfirmSmsPhone}
          open={confirmSmsModal}
          handleClose={handleCloseConfirmSmsModal}
          translate={translate}
        />
      }
      { copyQuoteModal && modalType === 'copyQuote' &&
        <CopyQuote
          submitCopyQuote={submitCopyQuote}
          handleCloseCopyQuote={handleCloseCopyQuote}
          translate={translate}
        />
      }
    </div>
  );
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(QuoteDetails);
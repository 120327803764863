import React, { useState } from 'react';
import moment from 'moment';
import { Box, Typography, Grid } from '@material-ui/core';
import styles from './client.module.scss';

import { Cached as CachedIcon } from '@material-ui/icons';
import {
  CreatedJobStatus,
  LineChart,
  Announcements,
  MostRecentJobs,
} from '../component';
import { getDateTimeFormat } from 'lib/formatter';

const ClientDashboard = (props) => {
  const { t } = props;
  const [latestUpdate, setLatestUpdate] = useState(moment());

  const updateDashboard = () => setLatestUpdate(moment());

  return (
    <Grid container spacing={2}>
      <Grid item sm={9} md={9}>
        <Box py={3} pl={3}>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography>
              {t('latestUpdateIs', {
                time: moment(latestUpdate).format(getDateTimeFormat()),
              })}
            </Typography>
            <Box
              ml={2}
              alignItems="center"
              display="flex"
              onClick={updateDashboard}
              className={styles.cursor}
            >
              <CachedIcon />
              <Typography>{t('refresh')}</Typography>
            </Box>
          </Box>
          <Grid container className={styles.firstRowMargin} spacing={2}>
            <Grid item sm={12} md={12}>
              <CreatedJobStatus {...props} latestUpdate={latestUpdate} />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 8 }}>
            <Grid item sm={12} md={12}>
              <LineChart {...props} latestUpdate={latestUpdate} />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 8 }}>
            <Grid item sm={12} md={12}>
              <MostRecentJobs {...props} latestUpdate={latestUpdate} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item sm={3} md={3}>
        <Box py={3} pr={2}>
          <Announcements t={t} {...props} latestUpdate={latestUpdate} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ClientDashboard;

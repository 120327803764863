import moment from 'moment-timezone';

function idObjectOnly(item) {
  if (!item || !item.id) return {};
  return { id: item.id };
};

// Format datetime
function formatDateTime(date, time) {
  if(moment.isMoment(date) && moment.isMoment(time)) {
    return date.clone()
      .millisecond(time.millisecond())
      .second(time.second())
      .minute(time.minute())
      .hour(time.hour())
      .toDate();
  }
  const newDate = new Date(date);
  const newTime = new Date(time || date);

  if (!!date && !!time) {
    return new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newTime.getHours(),
      newTime.getMinutes(),
      0);
  }
  if (date) {
    return new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newTime.getHours() + 1,
      0, 0);
  }
  return null;
};

// Format products
function formatProducts(products, currencyCode, isProductsPristine, ) {
  if (products.length < 2 && isProductsPristine) {
    return [];
  };

  const items = [];
  products.forEach(product => {
    if (product.name === '') {
      return;
    }
    const cloneProduct = { ...product };
    const filteredInventories = product.inventories.reduce((prev, currentValue) => {
      if (!currentValue.name) return prev;
      return [...prev, currentValue];
    }, []);
    cloneProduct.currency = currencyCode || null;
    cloneProduct.inventories = filteredInventories;
    items.push(cloneProduct);
  });

  return items;
};

// Format team
function formatTeam(team) {
  if (!team || Object.keys(team).length < 1) {
    return;
  };

  const items = [];

  Object.keys(team).forEach(member => {
    if (!team[member]) return;
    items.push({ 'id': member });
  });

  return items;
};

export default (jobFormValues, currencyCode, isProductsPristine) => {
  const { startDate, endDate, startTime, publicId, endTime, team, selectedClient,
    selectedProperty, internalNotes, title, description, products,
    additionalSettings, categories, orderId, dn, hub
  } = jobFormValues;
  const values = {};

  values.publicId = publicId || null;
  values.client = idObjectOnly(selectedClient);
  values.property = idObjectOnly(selectedProperty);
  values.title = title || null;
  values.description = description || null;
  values.notes = internalNotes || null;
  values.additionalSettings = additionalSettings || null;
  values.categories = categories || null;
  // Convert team
  const formattedTeam = formatTeam(team);
  values.team = formattedTeam || [];

  // Convert Time
  const jobStartTime = (startDate && startTime) && formatDateTime(startDate, startTime);
  const jobEndTime = (endDate && endTime) && formatDateTime(endDate, endTime);

  values.start = (jobStartTime && jobStartTime.toISOString()) || null;
  values.end = (jobEndTime && jobEndTime.toISOString()) || null;

  // Convert Product
  const formattedProducts = formatProducts(products, currencyCode, isProductsPristine);
  values.items = formattedProducts || [];
  values.orderId = orderId && orderId.trim();
  values.deliveryNote = dn;
  values.hubId = hub && hub.value;
  return values;
};

export default {
  name: 'Location\'s name',
  address: 'Address',
  'city/postal': 'City/ \n Postal code',
  note: 'Note for driver',
  newHub: 'New Hub',
  edit: 'Edit',
  remove: 'Remove',
  delete: 'Delete',
  unitNumber: 'Unit/House Number and Building Name',
  city: 'City',
  postalCode: 'Postal Code',
  state: 'State',
  country: 'Country',
  lat: 'Latitude',
  lng: 'Longtitude',
  description: 'Description',
  archiveHub: 'Are you sure you want to archive this hub?',
  unarchiveHub: 'Are you sure you want to unarchive this hub?',
  archiveConfirm: 'Archive hub successfully',
  unarchiveConfirm: 'Unarchive hub successfully',
  createSuccessfully: 'New hub has been created successfully',
  hub: 'Hub',
  map: 'Map',
  locality: 'Locality',
  openMap: 'Open in',
  googleMap: 'Google Maps',
  hubDetails: 'Hub details',
  cantFindHub: 'Can\'t find the hub you\'re looking for?',
  tryToSearch: 'Try typing the hub\'s name to search.'
};
import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { toggleFilterSidebar } from 'redux/global';
import { useDispatch, useSelector } from 'react-redux';
import { getRequests } from 'redux/request';

// Material components
import { withStyles, Button } from '@material-ui/core';

import styles from './styles';
import filterSrc from 'assets/images/calendar/icon_filter.svg';

const RequestsToolbar = (props)=> {
  const dispatch = useDispatch();
  const { classes, className, translate } = props;
  const rootClassName = classNames(classes.root, className);

  const isRequestsFiltering = useSelector(state => state.global.isRequestFiltering || false);
  const filters = useSelector(state => state.global.filterParams);

  return (
    <div className={rootClassName}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        {/* btn toggle filer menu
        <Button
          size="medium"
          variant="contained"
          style={{ border: 'none', marginRight: 15 }}
          onClick={() => dispatch(toggleFilterSidebar(true))}
          className={classNames(
            'toolbar-navigation-button',
            classes.textStyle,
            classes.backgroundBtn,
            classes.shadowNone
          )}
        >
          <img className={classes.imgFilter} src={filterSrc} alt="icon_filter" />
          {translate('Common:filter')}: {isRequestsFiltering ? translate('Common:on') : translate('Common:off')}
        </Button> */}

        <Link to='/requests/new'>
          <Button color="primary" size="small" variant="contained">
            {translate('addRequest')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

RequestsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RequestsToolbar);

import { setIsLoading } from './global';
import { POST, GET, PATCH, DELETE } from 'lib/api';

// Actions
const SET_CURRENT_PAYMENT = 'SET_CURRENT_PAYMENT';
const SET_CLEAR_PAYMENT = 'SET_CLEAR_PAYMENT';

const initialState = {
  payment: {},
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_PAYMENT:
      return {
        ...state,
        payment: {
          ...action.payment,
        }
      };

    case SET_CLEAR_PAYMENT:
      return {
        ...state,
        payment: {},
      };

    default: return state;
  }
}

// Action Creators
export function setCurrentPayment(payment) {
  return { type: SET_CURRENT_PAYMENT, payment };
}

export function clearPayment() {
  return { type: SET_CLEAR_PAYMENT };
}


export function getPayments(options) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const queryParams = {};

    if (options.limit) queryParams['$limit'] = options.limit;

    if (options.skip) queryParams['$skip'] = options.skip;

    if (options.clientId) queryParams['clientId'] = options.clientId;

    if (options.invoiceId) queryParams['invoiceId'] = options.invoiceId;

    const params = { params: queryParams };
    return GET('/payments', accessToken, params)
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

export function getPayment(paymentId) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return GET(`/payments/${paymentId}`, accessToken)
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

export function addPayment(payment) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return POST('/payments', accessToken, { data: payment })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

export function updatePayment(payment, paymentId) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    dispatch(setIsLoading(true));

    return PATCH(`/payments/${paymentId}`, accessToken, { data: payment })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

export function deletePayment(paymentId) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return DELETE(`/payments/${paymentId}`, accessToken).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function emailReceipt(paymentId, email) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const data = { action: 'SEND_EMAIL', paymentId, receiver: email };

    POST('/payment-action', accessToken, { data })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
};

export function downloadPdf(paymentId) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return GET(`/payments/${paymentId}`, accessToken, { params: { pdf: true } })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
};

import React from 'react';

import moment from 'moment';

// Material components
import { Grid, List, ListItem, ListItemText, Typography, Divider, withStyles } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletContent,
  PortletFooter,
  PortletHeader
} from 'pages/Dashboard/components';
import { formatPrice } from 'lib/formatter';

// Component styles
import styles from './styles';

const BillingItems = withStyles(styles)(props => {
  const {
    accountBalance,
    classes,
    currency,
    billItem,
    handleClickBillingHistory,
    translate,
    balance = false,
    lastIndex = false,
  } = props;

  let title; let totalAmount; let paidDate;

  if (balance) {
    title = translate('currentBalance');
    totalAmount = formatPrice(accountBalance, currency);
    paidDate = null;
  } else if(billItem.invoiceId) {
    title = billItem.subject || `${translate('paymentForInvoice')} ${billItem.invoiceId}`;
    totalAmount = `- ${formatPrice(billItem.amount, currency)}`;
    paidDate = billItem.paymentDate;
  } else {
    title = billItem.subject || `${translate('paymentForAccount')}`;
    totalAmount = `- ${formatPrice(billItem.amount, currency)}`;
    paidDate = billItem.paymentDate;
  }

  return (
    <>
      <ListItem className={classes.listItem} onClick={() => handleClickBillingHistory(billItem.id)}>
        <Grid container direction='row' justify='space-between'>
          <Grid item md={8} sm={8}>
            { !balance &&
            <Typography className={classes.topDate}>
              {moment(paidDate).format('MMM DD YYYY')}
            </Typography>}
            <Typography>
              {title}
            </Typography>
          </Grid>
          <Grid item md={4} sm={4} container direction='row' justify='flex-end' alignItems='flex-end'>
            <Grid item>
              <Typography>
                {totalAmount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>

      { !lastIndex && <Divider /> }

    </>
  );
});

export default withStyles(styles)(props => {
  const { accountBalance, classes, currency, payments, handleClickBillingHistory, translate } = props;

  return (
    <Portlet className={classes.billingHistoryCard}>
      <PortletHeader grey>
        <Typography className={classes.headerTitle}>
          {translate('billingHistory')}
        </Typography>
      </PortletHeader>
      <PortletContent noPadding>
        <List>
          {
            payments && payments.length > 0 ?
              payments.map((item, index) => {
                return (
                  <BillingItems
                    currency={currency}
                    billItem={item}
                    handleClickBillingHistory={handleClickBillingHistory}
                    key={`${index}-${item.id}`}
                    lastIndex={payments.length > 1 && payments.length === index + 1}
                    translate={translate}
                  />
                ); })
              : <ListItem><ListItemText>{translate('noBillingDetails')}</ListItemText></ListItem>
          }
        </List>
      </PortletContent>
      <PortletFooter success>
        <Grid container direction='row' justify='space-between'>
          <Grid item md={8} sm={8}>
            <Typography className={classes.balanceFooter}>
              {translate('currentBalance')}
            </Typography>
          </Grid>
          <Grid item md={4} sm={4} container direction='row' justify='flex-end'>
            <Typography className={classes.balanceFooter}>
              {formatPrice(accountBalance, currency)}
            </Typography>
          </Grid>
        </Grid>
      </PortletFooter>
    </Portlet>
  );
});
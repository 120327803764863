import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { addStaff, getStaffs, clearStaffs } from 'redux/staff';

// Material components
import {
  Button,
  Chip,
  Typography,
  withStyles
} from '@material-ui/core';

// Shared components
import SelectTeamModal from 'pages/Dashboard/components/SelectTeamModal';
import Portlet from 'pages/Dashboard/components/Portlet';
import PortletHeader from 'pages/Dashboard/components/PortletHeader';
import PortletLabel from 'pages/Dashboard/components/PortletLabel';
import PortletContent from 'pages/Dashboard/components/PortletContent';
import CreateStaffModal from 'pages/Dashboard/components/CreateStaffModal';

// Component styles
import TeamCardStyle from './styles';
import { withTeamStatusHook } from 'pages/Dashboard/helpers/getTeamStatus';

const mapStateToProps = state => ({
  staffs: state.staff.staffs,
  staffDetails: (state.form.newStaff && state.form.newStaff.values) || {},
});

const mapDispatchToProps = (dispatch) => ({
  getStaffs: (params) => dispatch(getStaffs(params)),
  addStaff: (staffDetails) => dispatch(addStaff(staffDetails)),
  submitStaffForm: () => dispatch(submit('newStaff')),
  clearStaffs: () => dispatch(clearStaffs())
});

class TeamCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openStaffList: false,
      openNewStaff: false
    };
    this.handleAssignStaff = this.handleAssignStaff.bind(this);
    this.handleCreateNewStaff = this.handleCreateNewStaff.bind(this);
    this.handleCloseSelectTeamModal = this.handleCloseSelectTeamModal.bind(this);
    this.handleCreateStaff = this.handleCreateStaff.bind(this);
    this.handleCreateClose = this.handleCreateClose.bind(this);
    this.removeStaff = this.removeStaff.bind(this);
    this.handleClearTeam = this.handleClearTeam.bind(this);
    this.finishSelectionTeam = this.finishSelectionTeam.bind(this);
  }

  componentDidMount() {
    this.props.clearStaffs();
    this.props.getStaffs({ $scope: 'JOB_MANAGEMENT|ASSIGN_JOB' });
  }

  handleAssignStaff() {
    this.setState({ openStaffList: !this.state.openStaffList });
  };

  async handleCreateNewStaff() {
    const formData = new FormData();
    formData.append('staffInfo', JSON.stringify(this.props.staffDetails));
    await this.props.addStaff(formData);

    await this.props.getStaffs({ $scope: 'JOB_MANAGEMENT|ASSIGN_JOB' });
    this.setState({ openNewStaff: false });
    this.setState({ openStaffList: true });
  };

  handleCloseSelectTeamModal() {
    this.setState({ openStaffList: false });
  };

  handleCreateStaff() {
    this.setState({ openStaffList: false });
    this.setState({ openNewStaff: true });
  };

  handleCreateClose() {
    this.setState({ openNewStaff: false, openStaffList: true });
  };

  handleClearTeam() {
    this.props.clearTeam();
  }

  removeStaff(assignee) {
    const { assignees } = this.props;
    const newTeam = {};

    Object.keys(assignees).map(item => { newTeam[item] = assignees[item]; });
    delete newTeam[`${assignee}`];

    this.props.updateTeam(newTeam);
  };

  finishSelectionTeam() {
    this.setState({ openStaffList: false });
    if (this.props.saveTeamAfterChosen) {
      this.props.handleConfirmTeam();
    }
  }

  render() {

    const {
      assignees,
      classes,
      staffs,
      submitStaffForm,
      translate,
      handleOpenModalCategory,
      team
    } = this.props;


    const { openStaffList, openNewStaff } = this.state;

    return (
      <Portlet className={classes.root}>
        <PortletHeader className={classes.cardHeader}>
          <PortletLabel title={translate('Job:team')} />
          <Button
            className={classes.assignButton}
            size='small'
            onClick={this.handleAssignStaff}
          >
            {translate('Job:assign')}
          </Button>
        </PortletHeader>
        <PortletContent>
          {
            assignees && !!Object.keys(assignees).length ?

              Object.keys(assignees).map((assignee) => {
                const selectedStaff = staffs.find(staff => staff.id === assignee)
                || team.find(staff => staff.id === assignee);

                return assignees[assignee] && selectedStaff ?
                  <Chip
                    key={selectedStaff.id}
                    className={classes.teamChip}
                    label={selectedStaff.fullName + this.props.getStatus(selectedStaff.deleted, selectedStaff.archived)}
                    onDelete={() => {
                      this.removeStaff(selectedStaff.id);
                    }}
                  />
                  : null;
              })
              : <Typography className={classes.noneAssignText}>
                {translate('Job:noUsersAssigned')}
              </Typography>
          }
        </PortletContent>
        {
          openStaffList &&
            <SelectTeamModal
              staffs={staffs}
              open={openStaffList}
              handleCloseSelectTeamModal={this.handleCloseSelectTeamModal}
              finishTeamSelection={this.finishSelectionTeam}
              handleCreateUser={this.handleCreateStaff}
              handleClearTeam={this.handleClearTeam}
              translate={translate}
            />
        }
        {
          openNewStaff &&
            <CreateStaffModal
              open={openNewStaff}
              onSubmit={this.handleCreateNewStaff}
              handleClick={submitStaffForm}
              handleClose={this.handleCreateClose}
              translate={translate}
              handleOpenModalCategory={handleOpenModalCategory}
              currentCategoriesStaff={this.props.currentCategoriesStaff}
            />
        }
      </Portlet>
    );
  }

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(TeamCardStyle),
  withTeamStatusHook
)(TeamCard);
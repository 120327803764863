import React from 'react';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'pages/Dashboard/components';
import { AdditionalSetting } from '../forms';

// Component styles
import { InternalNotesCardStyle } from './styles';

function InternalNotesCard(props) {
  const { classes, translate } = props;

  return (
    <Portlet>
      <PortletHeader className={classes.cardHeader}>
        <PortletLabel title={translate('Job:additionalSetting')} />
      </PortletHeader>
      <PortletContent>
        <AdditionalSetting translate={translate} />
      </PortletContent>
    </Portlet>
  );
};

export default withStyles(InternalNotesCardStyle)(InternalNotesCard);
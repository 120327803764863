import React, { useEffect, useState } from 'react';
import { Grid, Collapse, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import { Folder as FolderIcon, FolderOpen as FolderOpenIcon } from '@material-ui/icons';
import { Trans } from 'react-i18next';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories, addCategory, updateCategory, deleteCategory } from 'redux/category';
import { Form, Template } from './components';
import { ConfirmDeleteModal } from 'pages/Dashboard/components';
import { showErrorMessage } from 'lib/notifier';

const CategoriesComponent = props => {
  const { translate } = props;
  const [collapseCatIds, setCollapseCatId] = useState([]);
  const [categories, setCategories] = useState([]);
  const [addNewCategaroy, setAddNewCategaroy] = useState(false);
  const [editingCatId, updateEditingCatId] = useState();
  const [isDeletingCatOptions, setIsDeletingCat] = useState({});

  const dispatch = useDispatch();

  const currentRoleId = useSelector((state) => (state.auth.currentUser.companies[0] || {}).roleId);
  const isFullPermissionOnCategory = ['ADMIN', 'OWNER'].includes(currentRoleId);

  useEffect(() => {
    async function getCompanyCategories() {
      const result = await dispatch(getCategories({ limit: 100 }));
      if (result.status === 200) {
        setCategories(result.data.data);
      }
    }

    getCompanyCategories();
  }, []);

  // updateCollapseStatus handle collapse categories list
  const updateCollapseStatus = (catId) => {
    if (!catId) return;
    const index = collapseCatIds.findIndex(item => item === catId);
    const shadowCollapseCatIds = collapseCatIds.slice();
    if (index === -1) {
      shadowCollapseCatIds.push(catId);
    } else {
      shadowCollapseCatIds.splice(index, 1);
    }
    setCollapseCatId(shadowCollapseCatIds);
  };

  // This method is for opening the child categories for a parent category
  const openCollapseCategory = (catId) => {
    const collapseCatIndex = collapseCatIds.findIndex(item => item === catId);
    if (collapseCatIndex === -1) {
      const shadowCollapseCatIds = collapseCatIds.slice();
      shadowCollapseCatIds.push(catId);
      setCollapseCatId(shadowCollapseCatIds);
    }
  };

  // Handle add empty sub category to show form
  const addSubCategaroy = (catId) => {
    const catIndex = categories.findIndex(item => item.id === catId);
    if (catIndex === -1) return false;

    const shadowCategories = categories.slice();
    shadowCategories[catIndex].childrenCategories = shadowCategories[catIndex].childrenCategories || [];

    // only one form allow to add, so check if empty form already exist. Skip it
    const isInitialBefore = shadowCategories[catIndex].childrenCategories
      .filter(item => item.parentCategoryId === catId && !item.name);
    if (isInitialBefore.length) return false;
    shadowCategories[catIndex].childrenCategories.unshift({ parentCategoryId: catId, name: '' });
    setCategories(shadowCategories);
    // Open collapse category if it closed
    openCollapseCategory(catId);
  };

  // Handle add empty sub sub category to show form
  const addSubSubCategaroy = (lv1CatId, lv2CatId) => {
    const shadowCategories = categories.slice();
    const catLv1Index = categories.findIndex(item => item.id === lv1CatId);
    if (catLv1Index >= 0) {
      const lv1ChildrenCategories = shadowCategories[catLv1Index].childrenCategories;
      const catLv2Index = lv1ChildrenCategories.findIndex(item => item.id === lv2CatId);
      if (catLv2Index >= 0) {
        let lv2ChildrenCategories = lv1ChildrenCategories[catLv2Index].childrenCategories || [];
        lv2ChildrenCategories = lv2ChildrenCategories.filter(item => !!item.id);
        lv2ChildrenCategories.unshift({ parentCategoryId: lv2CatId, name: '' });
        shadowCategories[catLv1Index].childrenCategories[catLv2Index].childrenCategories = lv2ChildrenCategories;
        setCategories(shadowCategories);
      }
    }
    // Open collapse category if it closed
    openCollapseCategory(lv2CatId);
  };

  // Handle remove empty sub category to hide the form
  const cancelAddingSubCategory = (lv1CatId) => {
    const catIndex = categories.findIndex(item => item.id === lv1CatId);
    if (catIndex >= 0) {
      const shadowCategories = categories.slice();
      shadowCategories[catIndex].childrenCategories =
        (shadowCategories[catIndex].childrenCategories|| []).filter(item => !!item.id);
      setCategories(shadowCategories);
    }
  };

  // Handle remove empty sub sub category to hide the form
  const cancelAddingSubSubCategory = (lv1CatId, lv2CatId) => {
    const shadowCategories = categories.slice();
    const catLv1Index = categories.findIndex(item => item.id === lv1CatId);
    if (catLv1Index >= 0) {
      const lv1ChildrenCategories = shadowCategories[catLv1Index].childrenCategories;
      const catLv2Index = lv1ChildrenCategories.findIndex(item => item.id === lv2CatId);
      if (catLv2Index >= 0) {
        let lv2ChildrenCategories = lv1ChildrenCategories[catLv2Index].childrenCategories || [];
        lv2ChildrenCategories = lv2ChildrenCategories.filter(item => !!item.id);
        shadowCategories[catLv1Index].childrenCategories[catLv2Index].childrenCategories = lv2ChildrenCategories;
        setCategories(shadowCategories);
      }
    }
  };

  const handleCategoryName = (categoryName) => {
    if (categoryName === null || categoryName.trim() === '') {
      return true;
    }
    return false;
  };

  // lv1CatId is cat lv1
  // Handle submit to add a category and sync data after added
  const handleSubmitCategoryForm = async (value, lv1CatId=null) => {
    if (handleCategoryName(value.name)) {
      return showErrorMessage(translate('Category:invalidCategoryName'));
    }
    const result = await dispatch(addCategory(value));
    if (result.status === 200) {
      const newCat = result.data;
      if (!newCat.parentCategoryId) {
        // Handle for root cat
        setCategories([newCat, ...categories]);
        setAddNewCategaroy(false);
      } else if (newCat.parentCategoryId === lv1CatId) {
        // Handle for Sub category
        const catLv1Index = categories.findIndex(item => item.id === newCat.parentCategoryId);
        const shadowCategories = categories.slice();
        let lv1ChildrenCategories = shadowCategories[catLv1Index].childrenCategories || [];
        lv1ChildrenCategories = lv1ChildrenCategories.filter(item => !!item.id);
        lv1ChildrenCategories.unshift(newCat);
        shadowCategories[catLv1Index].childrenCategories = lv1ChildrenCategories;
        setCategories(shadowCategories);
      } else {
        // Handle for Sub sub category
        const shadowCategories = categories.slice();
        const catLv1Index = categories.findIndex(item => item.id === lv1CatId);
        const lv1ChildrenCategories = shadowCategories[catLv1Index].childrenCategories;
        const catLv2Index = lv1ChildrenCategories.findIndex(item => item.id === newCat.parentCategoryId);
        let lv2ChildrenCategories = lv1ChildrenCategories[catLv2Index].childrenCategories || [];
        lv2ChildrenCategories = lv2ChildrenCategories.filter(item => !!item.id);
        lv2ChildrenCategories.unshift(newCat);
        shadowCategories[catLv1Index].childrenCategories[catLv2Index].childrenCategories = lv2ChildrenCategories;
        setCategories(shadowCategories);
      }
    }
  };

  // Handle setting editing category to show the form
  const editCategaroy = (lv1CatId) => {
    updateEditingCatId(lv1CatId);
  };

  // Handle removing editing category to show the form
  const cancelEditCategory = () => {
    updateEditingCatId();
  };

  // Handle update a category and sync data after updated
  const handleUpdateCategory = async (value, lv1CatId=null) => {
    if (handleCategoryName(value.name)) {
      return showErrorMessage(translate('Category:invalidCategoryName'));
    }

    const result = await dispatch(updateCategory(value));
    if (result.status === 200) {
      const updatedCat = result.data;
      if (!updatedCat.parentCategoryId) {
        // Handle for root cat
        const catLv1Index = categories.findIndex(item => item.id === updatedCat.id);
        const shadowCategories = categories.slice();
        shadowCategories[catLv1Index] = { ...shadowCategories[catLv1Index], ...updatedCat };
        setCategories(shadowCategories);
        updateEditingCatId();
      } else if (updatedCat.parentCategoryId === lv1CatId) {
        // Handle for Sub category
        const catLv1Index = categories.findIndex(item => item.id === updatedCat.parentCategoryId);
        if (catLv1Index === -1) return;

        const shadowCategories = categories.slice();
        const lv1ChildrenCategories = shadowCategories[catLv1Index].childrenCategories || [];
        const catLv2Index = lv1ChildrenCategories.findIndex(item => item.id === updatedCat.id);
        if (catLv2Index === -1) return ;

        lv1ChildrenCategories[catLv2Index] = { ...lv1ChildrenCategories[catLv2Index], ...updatedCat };
        shadowCategories[catLv1Index].childrenCategories = lv1ChildrenCategories;
        setCategories(shadowCategories);
        updateEditingCatId();
      } else {
        // Handle for Sub sub category
        const catLv1Index = categories.findIndex(item => item.id === lv1CatId);
        if (catLv1Index === -1) return;

        const shadowCategories = categories.slice();
        const lv1ChildrenCategories = shadowCategories[catLv1Index].childrenCategories || [];
        const catLv2Index = lv1ChildrenCategories.findIndex(item => item.id === updatedCat.parentCategoryId);
        if (catLv2Index === -1) return ;

        const lv2ChildrenCategories = lv1ChildrenCategories[catLv2Index].childrenCategories || [];
        const catLv3Index = lv2ChildrenCategories.findIndex(item => item.id === updatedCat.id);
        if (catLv3Index === -1) return ;

        lv2ChildrenCategories[catLv3Index] = { ...lv2ChildrenCategories[catLv3Index], ...updatedCat };
        shadowCategories[catLv1Index].childrenCategories[catLv2Index].childrenCategories = lv2ChildrenCategories;
        setCategories(shadowCategories);
        updateEditingCatId();
      }
    }
  };

  // Handle to show confirmation dialog to make sure user want to delete a category
  const handleConfirmDeletion = (options={}) => {
    setIsDeletingCat(options);
  };

  // Handle to close confirmation dialog
  const handleCloseConfirmDeleteModal = () => {
    setIsDeletingCat({});
  };

  // Handle delete a category
  const handleDeleteCategory = async (options) => {
    const result = await dispatch(deleteCategory(options));
    if (result.status === 200) {
      const deletedCat = result.data;
      if (!deletedCat.parentCategoryId) {
        // Handle for root cat
        const catLv1Index = categories.findIndex(item => item.id === deletedCat.id);
        const shadowCategories = categories.slice();
        shadowCategories.splice(catLv1Index, 1);
        setCategories(shadowCategories);
        setIsDeletingCat({});
      } else if (deletedCat.parentCategoryId === options.lv1CatId) {
        // Handle for Sub category
        const catLv1Index = categories.findIndex(item => item.id === deletedCat.parentCategoryId);
        if (catLv1Index === -1) return;

        const shadowCategories = categories.slice();
        const lv1ChildrenCategories = shadowCategories[catLv1Index].childrenCategories || [];
        const catLv2Index = lv1ChildrenCategories.findIndex(item => item.id === deletedCat.id);
        if (catLv2Index === -1) return ;

        lv1ChildrenCategories.splice(catLv2Index, 1);
        shadowCategories[catLv1Index].childrenCategories = lv1ChildrenCategories;
        setCategories(shadowCategories);
        setIsDeletingCat({});
      } else {
        // Handle for Sub sub category
        const catLv1Index = categories.findIndex(item => item.id === options.lv1CatId);
        if (catLv1Index === -1) return;

        const shadowCategories = categories.slice();
        const lv1ChildrenCategories = shadowCategories[catLv1Index].childrenCategories || [];
        const catLv2Index = lv1ChildrenCategories.findIndex(item => item.id === deletedCat.parentCategoryId);
        if (catLv2Index === -1) return ;

        const lv2ChildrenCategories = lv1ChildrenCategories[catLv2Index].childrenCategories || [];
        const catLv3Index = lv2ChildrenCategories.findIndex(item => item.id === deletedCat.id);
        if (catLv3Index === -1) return ;

        lv2ChildrenCategories.splice(catLv3Index, 1);
        shadowCategories[catLv1Index].childrenCategories[catLv2Index].childrenCategories = lv2ChildrenCategories;
        setCategories(shadowCategories);
        setIsDeletingCat({});
      }
    }
  };

  return (
    <div className={styles.companySetting}>
      <Grid item container spacing={1} justify='flex-start'>
        { !addNewCategaroy && isFullPermissionOnCategory &&
          <div className={styles.addCatWraper} onClick={_e => setAddNewCategaroy(true)}>
            <FolderIcon />
            <span>{translate('Category:newCategory')}</span>
          </div> }
        {/* Form 1 */}
        { addNewCategaroy && isFullPermissionOnCategory &&
          <Form
            catIcon={<FolderIcon />}
            onCloseForm={_e => setAddNewCategaroy(false)}
            onSubmit={handleSubmitCategoryForm}
          /> }

        {/* Layer 1 =========================================== */}
        <List className={styles.categories}>
          { categories.map((lv1Cat, index) => {

            const lv2Categories = lv1Cat.childrenCategories || [];
            const collapseOpenedLv1 = collapseCatIds.includes(lv1Cat.id);

            return (
              <div key={index} className={styles.lv1CatWrapper}>
                <Template
                  ableToAddSubCat
                  catIcon={<FolderIcon />}
                  category={lv1Cat}
                  editingCatId={editingCatId}
                  collapseOpened={collapseOpenedLv1}
                  haveSubCategories={lv2Categories.length > 0}
                  isFullPermissionOnCategory={isFullPermissionOnCategory}
                  addSubCategaroyText={translate('Category:addSubCategory')}
                  editCategaroy={_e => editCategaroy( lv1Cat.id )}
                  addSubCategaroy={_e => addSubCategaroy(lv1Cat.id)}
                  cancelEditCategory={_e => cancelEditCategory(lv1Cat.id)}
                  handleUpdateCategory={value => handleUpdateCategory(value)}
                  confirmDeletion={_e => handleConfirmDeletion(lv1Cat)}
                  updateCollapse={_e => updateCollapseStatus(lv1Cat.id)}
                />

                <div className={styles.nestedLv1}>
                  {/* Layer 2 (Sub Categories) =========================================== */}
                  { lv2Categories.length > 0 && lv2Categories.map((lv2Cat, index2) => {

                    const lv3Categories = lv2Cat.childrenCategories || [];
                    const collapseOpenedLv2 = collapseCatIds.includes(lv2Cat.id);

                    return (
                      <Collapse key={index2} in={collapseOpenedLv1} timeout="auto" unmountOnExit>
                        <List className={styles.lv2CatWrapper}>

                          <Template
                            ableToAddSubCat
                            category={lv2Cat}
                            catIcon={<FolderIcon />}
                            editingCatId={editingCatId}
                            collapseOpened={collapseOpenedLv2}
                            haveSubCategories={lv3Categories.length > 0}
                            isFullPermissionOnCategory={isFullPermissionOnCategory}
                            addSubCategaroyText={translate('Category:addSubSubCategory')}
                            editCategaroy={_e => editCategaroy( lv2Cat.id )}
                            addSubCategaroy={_e => addSubSubCategaroy(lv1Cat.id, lv2Cat.id)}
                            cancelEditCategory={_e => cancelEditCategory(lv2Cat.id)}
                            cancelAddingCategory={_e => cancelAddingSubCategory(lv1Cat.id)}
                            handleUpdateCategory={value => handleUpdateCategory(value, lv1Cat.id)}
                            handleSubmitCategoryForm={value => handleSubmitCategoryForm(value, lv1Cat.id)}
                            confirmDeletion={_e => handleConfirmDeletion({ lv1CatId: lv1Cat.id, ...lv2Cat })}
                            updateCollapse={_e => updateCollapseStatus(lv2Cat.id)}
                          />
                          <div className={styles.nestedLv2}>
                            {/* Layer 3 (Sub sub-categories)  =========================================== */}
                            { lv3Categories.length > 0 && lv3Categories.map((lv3Cat, index3) => {
                              return (
                                <Collapse
                                  key={index3}
                                  in={collapseOpenedLv2}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List className={styles.lv3CatWrapper}>
                                    <Template
                                      category={lv3Cat}
                                      catIcon={<FolderOpenIcon />}
                                      editingCatId={editingCatId}
                                      isFullPermissionOnCategory={isFullPermissionOnCategory}
                                      editCategaroy={_e => editCategaroy( lv3Cat.id )}
                                      cancelEditCategory={_e => cancelEditCategory(lv3Cat.id)}
                                      cancelAddingCategory={_e => cancelAddingSubSubCategory(lv1Cat.id, lv2Cat.id)}
                                      handleUpdateCategory={value => handleUpdateCategory(value, lv1Cat.id)}
                                      handleSubmitCategoryForm={value => handleSubmitCategoryForm(value, lv1Cat.id)}
                                      confirmDeletion={_e => handleConfirmDeletion({ lv1CatId: lv1Cat.id, ...lv3Cat })}
                                    />
                                  </List>
                                </Collapse>);
                            })}
                          </div>
                        </List>
                      </Collapse>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </List>
      </Grid>
      {/* Confirmation delete dialog */}
      { isDeletingCatOptions.id &&
        <ConfirmDeleteModal
          open={!!isDeletingCatOptions.id}
          handleClose={_e => handleCloseConfirmDeleteModal()}
          handleConfirmDelete={_e => handleDeleteCategory(isDeletingCatOptions)}
          translate={translate}
          dialogTitle={
            <Trans i18nKey="Category:deleteCategory">
              {isDeletingCatOptions.name}
            </Trans>
          }
        >
          <div className={styles.dialogDeletionConfirmation}>
            <Typography component="span">
              {translate('Category:sureToDeleteCategory')}
            </Typography>
            <ul className={styles.noticeItems}>
              <li className={styles.noticeItem}>
                <Typography component="span">
                  {translate('Category:noticeForSubcategories')}
                </Typography>
              </li>
              <li className={styles.noticeItem}>
                <Typography component="span">
                  {translate('Category:noticeForItemsAssociation')}
                </Typography>
              </li>
            </ul>
          </div>
        </ConfirmDeleteModal>}
    </div>
  );
};

export default CategoriesComponent;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Card,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { updateRequestDetails, deleteRequest } from 'redux/request';

import { Field, Fields } from 'redux-form';
import { RenderField } from 'components/view';
import { StatusChip } from 'pages/Dashboard/components';

import styles from '../card.module.scss';

import editSrc from 'assets/images/icons/edit.svg';

import { MorePopover } from './components';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import { HandleConvertModal } from '../../ConvertButton';
import ReviewChip from 'pages/Dashboard/components/ReviewChip';
import { REQUEST_STATUS } from 'common/constant';

const RenderStatusChip = ({
  statusId,
  verified,
  startVerifiedAt,
  label,
  labelStyle,
}) => {
  const getReviewStatus = () => {
    if (verified?.input.value) return 'REVIEWED';
    return startVerifiedAt?.input.value ? 'IN_REVIEW' : '';
  };

  if (!statusId?.input.value) return null;
  return (
    <Box display='flex' flexDirection='row'>
      <Box width='30%' className='mr_5'>
        {label && <Typography className={labelStyle}>{label}</Typography>}
      </Box>
      <Box width='60%' display='flex' flexDirection='row' alignItems='center'>
        <StatusChip
          statusFor='request'
          status={statusId?.input.value}
          reviewStatus={getReviewStatus()}
        />
        {getReviewStatus() === 'IN_REVIEW' && (
          <ReviewChip status={getReviewStatus()} />
        )}
      </Box>
    </Box>
  );
};

const AboutRequest = (props) => {
  const { translate, history, request, fetchRequestDetails } = props;
  const dispatch = useDispatch();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(
    false
  );

  const handleClickEdit = () => {
    const {
      location: { pathname },
    } = history;
    history.push(`${pathname}/edit`);
  };

  const handleCloseModal = () => setIsOpenConfirmModal(false);
  const handleOpenModal = () => setIsOpenConfirmModal(true);

  const handleConfirmArchive = async () => {
    const wantToArchive = request.archived ? false : true;
    const result = await dispatch(
      updateRequestDetails({
        publicId: request.publicId,
        archived: wantToArchive,
      })
    ).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    fetchRequestDetails();
    handleCloseModal();
  };

  const handleCloseDeleteModal = () => setIsOpenConfirmDeleteModal(false);

  const handleConfirmDelete = async () => {
    const result = await dispatch(
      deleteRequest({ publicId: request.publicId, deleted: true })
    ).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    showSuccessMessage(translate('deleteRequestSuccessfully'));
    history.push('/requests');
  };

  return (
    <Card>
      <Dialog
        open={isOpenConfirmDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <Typography variant='h4' className='mb_5'>
            {translate('deleteThisRequest')}
          </Typography>
          {translate('deleteThisRequestDescription')}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} variant='contained'>
            {translate('Common:close')}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            className={styles.confirm_archived}
            variant='contained'
            autoFocus
          >
            {translate('Common:delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenConfirmModal}
        onClose={handleCloseModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <Typography variant='h4' className='mb_5'>
            {translate(
              request.archived ? 'unarchivedThisRequest' : 'archivedThisRequest'
            )}
          </Typography>
          {translate(
            request.archived
              ? 'unarchivedThisRequestDescription'
              : 'archivedThisRequestDescription'
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant='contained'>
            {translate('Common:close')}
          </Button>
          <Button
            onClick={handleConfirmArchive}
            className={styles.confirm_archived}
            variant='contained'
            autoFocus
          >
            {translate(
              request.archived ? 'Common:unarchive' : 'Common:archive'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Box p={2}>
        <Typography className={`${styles.grey_color} font_bold`}>
          {translate('Enterprise:aboutThisRequest')}
        </Typography>

        <Box mt={2}>
          <Field
            name='title'
            component={RenderField}
            label={translate('requestTitle')}
            flexDirection='row'
            labelStyle={styles.grey_color}
          />
        </Box>
        <Box mt={2}>
          <Field
            name='publicId'
            component={RenderField}
            label={translate('requestId')}
            flexDirection='row'
            labelStyle={styles.grey_color}
            isShowCopyBtn
          />{' '}
        </Box>
        <Box mt={2}>
          <Field
            name='description'
            component={RenderField}
            label={translate('description')}
            flexDirection='row'
            labelStyle={styles.grey_color}
          />
        </Box>
        <Box mt={2}>
          <Field
            name='orderId'
            component={RenderField}
            label={translate('orderId')}
            flexDirection='row'
            labelStyle={styles.grey_color}
            isShowCopyBtn
          />
        </Box>
        <Box mt={2}>
          <Fields
            names={['statusId', 'verified', 'startVerifiedAt']}
            component={RenderStatusChip}
            label={translate('status')}
            flexDirection='row'
            labelStyle={styles.grey_color}
          />{' '}
        </Box>
        {request.statusId !== REQUEST_STATUS.CONVERTED ? (
          <Box mt={2} display='flex'>
            {request.archived ? (
              <Button
                onClick={handleOpenModal}
                fullWidth
                variant='contained'
                className={`${styles.btn_details}`}
              >
                {translate('unarchive')}
              </Button>
            ) : (
              <>
                <Box style={{ flex: 1 }} className='mr_5'>
                  <HandleConvertModal
                    request={request}
                    history={history}
                    refetchRequestDetail={fetchRequestDetails}
                  />
                </Box>
                <IconButton
                  onClick={handleClickEdit}
                  className={`${styles.icon_btn} mr_5`}
                >
                  <img src={editSrc} alt='editSrc' />
                </IconButton>
                <MorePopover
                  {...props}
                  translate={translate}
                  setIsOpenConfirmModal={setIsOpenConfirmModal}
                  setIsOpenConfirmDeleteModal={setIsOpenConfirmDeleteModal}
                />
              </>
            )}
          </Box>
        ) : null}
      </Box>
    </Card>
  );
};

export default withRouter(AboutRequest);

import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  ExpandLess,
  Add as AddIcon,
  EditOutlined as EditOutlinedIcon,
  Close as CloseIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import styles from '../styles.module.scss';
import CategoryForm from './form';

const CategoryRenderTemplate = (props) => {
  const {
    category,
    cancelAddingCategory,
    handleSubmitCategoryForm,
    editingCatId,
    cancelEditCategory,
    handleUpdateCategory,
    editCategaroy,
    addSubCategaroy,
    addSubCategaroyText,
    haveSubCategories = false,
    ableToAddSubCat = false,
    catIcon,
    confirmDeletion,
    updateCollapse,
    collapseOpened,
    isFullPermissionOnCategory
  } = props;

  return (
    <>
      {!category.id && (
        <CategoryForm
          catIcon={catIcon}
          category={category}
          onCloseForm={cancelAddingCategory}
          formName={(category.id || category.parentCategoryId).toString()}
          onSubmit={(value) => handleSubmitCategoryForm(value)}
        />
      )}

      {category.id && category.id === editingCatId && (
        <CategoryForm
          catIcon={catIcon}
          category={category}
          formName={category.id.toString()}
          onCloseForm={cancelEditCategory}
          onSubmit={(value) => handleUpdateCategory(value)}
        />
      )}

      {category.id && category.id !== editingCatId && (
        <ListItem className={styles.categoryItem}>
          {haveSubCategories && (
            <div
              className={styles.collapseIconWrapper}
              onClick={updateCollapse}
            >
              {collapseOpened ? <ExpandLess /> : <ChevronRightIcon />}
            </div>
          )}
          <ListItemIcon> {catIcon} </ListItemIcon>

          <ListItemText
            className={styles.categoryName}
            primary={category.name}
          />

          { ableToAddSubCat && isFullPermissionOnCategory && (
            <div className={styles.addCatBtn} onClick={addSubCategaroy}>
              <AddIcon /> <span>{addSubCategaroyText}</span>
            </div>
          )}
          { isFullPermissionOnCategory &&
            <EditOutlinedIcon
              className={styles.editCatBtn}
              onClick={editCategaroy}
            /> }

          { isFullPermissionOnCategory && <CloseIcon
            onClick={confirmDeletion}
            className={styles.removeCatBtn}
          /> }
        </ListItem>
      )}
    </>
  );
};

export default CategoryRenderTemplate;

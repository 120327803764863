/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getStaffs } from 'redux/staff';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { showErrorMessage } from 'lib/notifier';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@material-ui/core';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    return new Promise((rs) => {
      const later = async () => {
        clearTimeout(timeout);
        const resp = await func(...args).catch(() => rs([]));
        rs(resp);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    });
  };
};

const StaffSearch = (props) => {
  const {
    input: { onChange, value },
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation('Common');
  const [defaultOptions, setDefaultOptions] = useState([]);
  const handleChange = (selected) => {
    onChange(selected || []);
  };

  const fetchStaff = async () => {
    const result = await dispatch(getStaffs({ $limit: 10 })).catch((e) => ({
      e,
    }));
    if (result.e) {
      showErrorMessage(result.e);
      return result.e;
    }
    const formattedList = result.data.data.map((tag) => ({
      value: tag.id,
      label: [tag.firstName, tag.lastName].filter((e) => e).join(' '),
    }));
    setDefaultOptions(formattedList);
    return formattedList;
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  const promiseOptions = (inputValue) => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        const result = await dispatch(
          getStaffs({ $q: inputValue })
        ).catch((e) => ({ e }));
        if (result.e) {
          showErrorMessage(result.e);
          reject(result.e);
        }
        const formattedList = result.data.data.map((tag) => ({
          value: tag.id,
          label: [tag.firstName, tag.lastName].filter((e) => e).join(' '),
        }));
        resolve(formattedList);
      }, 1000);
    });
  };

  const MenuList = (menuProps) => {
    return (
      <components.MenuList {...menuProps}>
        {menuProps.children}
        <Grid
          container
          justify="center"
          style={{ padding: '12px 0px', borderTop: '1px solid #EAEAEA' }}
        >
          <Typography variant="body1" style={{ color: '#666666' }}>
            {t('cantFindWorker')}
          </Typography>
          <Typography variant="body1" style={{ color: '#666666' }}>
            {t('tryToSearch')}
          </Typography>
        </Grid>
      </components.MenuList>
    );
  };

  return (
    <AsyncSelect
      isMulti
      closeMenuOnSelect={false}
      value={value}
      onChange={handleChange}
      defaultOptions={defaultOptions}
      loadOptions={debounce(promiseOptions, 500)}
      placeholder="Search to select worker(s)"
      components={{ MenuList }}
    />
  );
};

export default StaffSearch;

import React, { useState } from 'react';

// redux
import { addStaff } from 'redux/staff';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  IconButton,
  DialogActions,
  Button,
} from '@material-ui/core';
import EditUserDetailsForm from '../form/enterpriseUser';
import { Close as CloseIcon } from '@material-ui/icons';
import ConfirmChangeOwnerModal from './confirmChangingOwner';

import styles from '../../details.module.scss';

const FORM_NAME = 'EnterpriseUser';
const OWNER_ROLE = 'OWNER';
const ADMIN_ROLE = 'ADMIN';

const UserDetails = ({
  t,
  openNewUserDetailsModal,
  handleClose,
  companyDetails,
  refetchTable,
}) => {
  const dispatch = useDispatch();
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [isOpenChangingModal, setIsOpenChangeModal] = useState(false);
  const [isChangingOwner, setIsChangingOwner] = useState(false);

  const handleSubmitNewUser = () => {
    if (!isChangingOwner) return dispatch(submit(FORM_NAME));
    return setIsOpenChangeModal(true);
  };

  const handleCloseConfirm = () => setIsOpenChangeModal(false);

  const userConfirmed = async () => {
    handleCloseConfirm();
    dispatch(submit(FORM_NAME));
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    const payload = {
      archived: !values.accountStatus,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      notes: values.notes,
      countryPhoneCode: values.countryPhoneCode,
      phoneNumber: values.phoneNumber,
      isContactPerson: values.isContactPerson,
      title: values.title,
      enterpriseAccountId: companyDetails.id,
      isOwner: values.isOwner,
      roleId: values.isOwner ? OWNER_ROLE : ADMIN_ROLE,
      categoryIds: values.categories.map((category) => category.id)
    };
    formData.append('staffInfo', JSON.stringify(payload));

    const response = await dispatch(addStaff(formData)).catch((e) => ({ e }));
    if (response.e) return showErrorMessage(response.e);
    showSuccessMessage(
      t('createUserSuccessfully', {
        userName: response.data && response.data.fullName,
      })
    );
    handleClose();
    // update table after change staffInfo
    refetchTable && refetchTable();
  };

  return (
    <>
      <ConfirmChangeOwnerModal
        t={t}
        openConfirmModal={isOpenChangingModal}
        handleClose={handleCloseConfirm}
        userConfirmed={userConfirmed}
      />
      <Dialog
        open={openNewUserDetailsModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: styles.dialogClass }}
      >
        <DialogTitle className={styles.borderBottom}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h4">{t('createEnterpriseUser')}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent className={styles.detailsContent}>
          <Box display="flex" flexDirection="row">
            <EditUserDetailsForm
              onSubmit={handleSubmit}
              t={t}
              setIsReadyToSubmit={setIsReadyToSubmit}
              companyDetails={companyDetails}
              setIsChangingOwner={setIsChangingOwner}
            />
          </Box>
        </DialogContent>
        <DialogActions className={styles.borderTop}>
          <Button
            onClick={handleSubmitNewUser}
            disabled={!isReadyToSubmit}
            className={styles.createNewEnterprise}
            autoFocus
          >
            {t('Common:create')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDetails;

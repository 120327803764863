export default theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  modalContent: {
    padding: theme.spacing(3),
  },
  clientDialogTitle: {
    // minWidth: 800,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontWeight: 'bold'
  },
  textStyle: {
    color: '#4C4C4C'
  }
});
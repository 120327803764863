import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { Grid, Box, Typography, Tooltip } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';
import { Doughnut } from 'react-chartjs-2';
import moment from 'moment';

import { getTotalJobsSummary } from 'redux/dashboard';
import { showErrorMessage } from 'lib/notifier';
import CardCustom from '../Card';
import styles from './styles.module.scss';

const CREATED_JOB_STATUS = 'CREATED_JOB_STATUS';

const mapResponseToChartData = (values) => [
  values.countPendingJobs,
  values.countInProgressJobs,
  values.countCompletedJobs,
  values.countCancelledJobs,
];

const CreatedJobStatus = ({
  t,
  isParentLoading,
  selectedDateRange,
  latestUpdate,
  selectedCategories,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCompletedJobs, setTotalCompletedJobs] = useState(0);
  const [totalCreatedJobs, setTotalCreatedJobs] = useState(0);

  const drawChart = (chartData) => {
    setData({
      labels: [t('pending'), t('inProgress'), t('completed'), t('cancelled')],
      datasets: [
        {
          label: 'label',
          data: chartData,
          backgroundColor: ['#FFCF26', '#26E5FF', '#2697FF', '#EE2727'],
          borderColor: ['#FFCF26', '#26E5FF', '#2697FF', '#EE2727'],
        },
      ],
    });
  };

  useEffect(() => {
    if (
      !Array.isArray(selectedCategories) ||
      !selectedCategories.length ||
      isParentLoading
    )
      return;

    const { endDate, startDate } = selectedDateRange;
    const categoryIds = selectedCategories.map((category) => category.id);
    (async () => {
      const params = {
        type: CREATED_JOB_STATUS,
        categoryIds,
        ...(endDate ? { to: moment(endDate).toISOString() } : {}),
        ...(startDate ? { from: moment(startDate).toISOString() } : {}),
      };
      setIsLoading(true);
      const response = await dispatch(getTotalJobsSummary(params));
      setIsLoading(false);
      if (response.e) return showErrorMessage(response.e);
      const chartData = mapResponseToChartData(response);
      setDisplayData(chartData);
      drawChart(chartData);
      setTotalCompletedJobs(response.countCompletedJobsInTimeRange);
      setTotalCreatedJobs(response.countCreatedJobs);
    })();
  }, [isParentLoading, selectedDateRange, latestUpdate, selectedCategories]);

  if (isParentLoading || isLoading)
    return <Skeleton style={{ transform: 'unset' }} height={240} />;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4} md={4}>
          <CardCustom title={t('jobSummary')}>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" className={styles.greyColor}>
                {t('completed')}
              </Typography>
              <Tooltip title="Total of completed jobs in selected time">
                <InfoIcon className={styles.greyColor} />
              </Tooltip>
            </Box>
            <Typography variant="h4">{totalCompletedJobs}</Typography>

            <Box mt={3}>
              <Typography variant="h5" className={styles.greyColor}>
                {t('created')}
              </Typography>
            </Box>
            <Typography variant="h4">{totalCreatedJobs}</Typography>
          </CardCustom>
        </Grid>
        <Grid item sm={8} md={8}>

          <CardCustom title={t('createdJobStatus')}>
            <Grid container style={{ zIndex: 1, position: 'relative' }}>
              <Grid item md={4} sm={4}>
                <Doughnut
                  data={data}
                  width={100}
                  height={100}
                  options={{
                    cutout: 40,
                    responsive: false,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: false,
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item md={8} sm={8}>
                <Box display="flex">
                  <Box display="flex" width="50%">
                    <Grid className={styles.circlePending} />
                    <Box display="flex" flexDirection="column">
                      <Typography className={styles.textHeader}>
                        {t('pending')}
                      </Typography>
                      <Typography variant="h4">{displayData[0]}</Typography>
                    </Box>
                  </Box>
                  <Box display="flex" width="50%">
                    <Grid className={styles.circleInProgress} />
                    <Box display="flex" flexDirection="column">
                      <Typography className={styles.textHeader}>
                        {t('inProgress')}
                      </Typography>
                      <Typography variant="h4">{displayData[1]}</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" mt={3}>
                  <Box display="flex" width="50%">
                    <Grid className={styles.circleCompleted} />
                    <Box display="flex" flexDirection="column">
                      <Typography className={styles.textHeader}>
                        {t('completed')}
                      </Typography>
                      <Typography variant="h4">{displayData[2]}</Typography>
                    </Box>
                  </Box>
                  <Box display="flex" width="50%">
                    <Grid className={styles.circleCancelled} />
                    <Box display="flex" flexDirection="column">
                      <Typography className={styles.textHeader}>
                        {t('cancelled')}
                      </Typography>
                      <Typography variant="h4">{displayData[3]}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardCustom>
        </Grid>
      </Grid>
    </>
  );
};

export default CreatedJobStatus;

import React from 'react';
import { Trans } from 'react-i18next';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// Component styles
import { ConfirmArchiveJobModalStyle } from './styles';

const mapStateToProps = state => ({

});

function ConfirmArchiveJobModal(props) {
  const {
    classes,
    jobId = 'testId',
    open,
    handleClose,
    handleConfirmArchive,
    translate
  } = props;

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      // fullWidth
      className={classes.modalBox}
    >
      <DialogTitle className={classes.modalTitle}>
        <Typography className={classes.titleText}>
          {translate('archiveJob')}
        </Typography>
        {open ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <Typography className={classes.modalBody}>
          <Trans i18nKey="Job:archiveAndCloseJob">
            {jobId}
          </Trans>
        </Typography>
      </DialogContent>
      <DialogContent className={classes.modalContent}>
        <Typography className={classes.modalBody}>
          {translate('archiveJobAndVisit')}
        </Typography>
      </DialogContent>
      <DialogActions disableSpacing className={classes.modalFooter}>
        <Button onClick={handleClose} color="secondary">
          {translate('Common:cancel')}
        </Button>
        <Button
          className={classes.deleteButton}
          variant='contained'
          onClick={() => { handleConfirmArchive(); handleClose(); }}
        >
          {translate('Common:confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(ConfirmArchiveJobModalStyle)
)(ConfirmArchiveJobModal);
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalJobsSummary } from 'redux/dashboard';
import moment from 'moment';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Card,
  Button,
} from '@material-ui/core';
import styles from './mostRecent.module.scss';
import { Skeleton } from '@material-ui/lab';
import { showErrorMessage } from 'lib/notifier';
import { ModalJobDetails } from 'pages/Dashboard/components';
import { StatusFilterMenu } from './components';
import CalendarSrc from 'assets/images/dashboard/calendar-light.svg';
import { CollectedIndicator } from 'pages/Dashboard/components/Indicator';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import { getDateTimeFormat } from 'lib/formatter';
import { EnterpriseStatusChip } from 'pages/Dashboard/components/EnterpriseStatuschip';

const RECENT_JOBS = 'RECENT_JOBS';

const TotalJobSummary = ({
  t,
  isParentLoading,
  latestUpdate,
  selectedCategories,
}) => {
  const dispatch = useDispatch();
  const [filterJob, setFilterJob] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [openJobDetails, setOpenJobDetails] = useState(false);
  const [jobId, setJobId] = useState('');

  // temp hide jobId when country code is TH
  const userCompany = useSelector((state) => state.auth.currentUser && state.auth.currentUser.companies &&
    state.auth.currentUser.companies[0]);
  const isHideJobId = userCompany.countryCode === 'TH';
  const isEnterpriseUser = useSelector((state) => state.auth.isEnterpriseUser);

  useEffect(() => {
    if (
      !Array.isArray(selectedCategories) ||
      !selectedCategories.length ||
      isParentLoading
    )
      return;
    const categoryIds = selectedCategories.map((category) => category.id);
    (async () => {
      const params = {
        type: RECENT_JOBS,
        categoryIds,
        ...(filterJob ? { sortBy: filterJob } : {}),
      };
      setIsLoading(true);
      const response = await dispatch(getTotalJobsSummary(params));
      setIsLoading(false);
      if (response.e) return showErrorMessage(response.e);
      setTableRows(response);
    })();
  }, [isParentLoading, filterJob, latestUpdate, dispatch, selectedCategories]);

  if (isParentLoading || isLoading)
    return <Skeleton style={{ transform: 'unset' }} height={240} />;

  const handleOpenJobDetails = (selectedJobId) => {
    setOpenJobDetails(true);
    setJobId(selectedJobId);
  };

  const handleClose = () => setOpenJobDetails(false);

  return (
    <>
      {openJobDetails && (
        <ModalJobDetails
          openJobDetails={openJobDetails}
          jobId={jobId}
          handleClose={handleClose}
          isHideJobId={isHideJobId}
        />
      )}

      <Card className={styles.recentJobs}>
        <Box
          p={2}
          className={styles.borderBottom}
          display="flex"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="h5">{t('mostRecentJob')}</Typography>
            <Box display="flex" mt={1}>
              <img src={CalendarSrc} alt="CalendarSrc" />
              <Typography
                variant="subtitle1"
                className={styles.widgetNotRelate}
              >
                {t('widgetNotAffect')}
              </Typography>
            </Box>
          </Box>
          <StatusFilterMenu
            t={t}
            setFilterJob={setFilterJob}
            filterJob={filterJob}
          />
        </Box>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.headerText}>{t('date')}</TableCell>
              {!isHideJobId && (
                <TableCell className={styles.headerText}>
                  {t('Job:jobIdAndOrderId')}
                </TableCell>
              )}
              <TableCell className={styles.headerText}>{t('title')}</TableCell>
              <TableCell className={styles.headerText}>{t('status')}</TableCell>
              <TableCell className={styles.headerText} />
              <TableCell className={styles.headerText} />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(tableRows) && tableRows.length > 0 ? (
              tableRows.map((row) => (
                <TableRow>
                  <TableCell>
                    {row.jobSchedule && row.jobSchedule.start ? (
                      moment(row.jobSchedule.start).format(
                        getDateTimeFormat()
                      )
                    ) : (
                      <Typography>{t('TBA')}</Typography>
                    )}
                  </TableCell>
                  {!isHideJobId && (
                    <TableCell>
                      <Box>
                        {row.publicId}
                      </Box>
                      <Box className={styles.orderId}>
                        {row.orderId}
                      </Box>
                    </TableCell>
                  )}
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    <EnterpriseStatusChip
                      groupStatusFilter={row.groupStatusFilter}
                    />
                  </TableCell>
                  <TableCell>{row.isCollected && <CollectedIndicator />}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleOpenJobDetails(row.id)}>
                      {t('details')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    p={3}
                  >
                    <Typography>{t('noJobs')}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box pr={3} py={1} display="flex" justifyContent="flex-end">
          <Link to={isEnterpriseUser ? '/enterprise-jobs' : '/jobs'}>
            <Button className={styles.viewAll}>
              {t('Enterprise:viewAll')}
              <KeyboardArrowRight />
            </Button>
          </Link>
        </Box>
      </Card>
    </>
  );
};

export default TotalJobSummary;

import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

// Material icons
import {
  CloudUpload
} from '@material-ui/icons';

// Component styles
import styles from './styles';

class DropZoneUploadFile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hightlight: false
    };
    this.fileInputRef = React.createRef();
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    this.fileInputRef.current.click();
  }

  onDragOver(evt) {
    evt.preventDefault();

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }


  onDrop(event) {
    event.preventDefault();
    const { files } = event.dataTransfer;
    this.props.handleCloseFileUploader();
    this.props.processClientImport(files[0]);
  }

  onFilesAdded(evt) {
    const { files } = evt.target;
    this.props.handleCloseFileUploader();
    this.props.processClientImport(files[0]);
  }

  render() {
    const { classes, openFileSelector, handleCloseFileUploader, translate } = this.props;

    return (
      <Dialog open={openFileSelector} onClose={handleCloseFileUploader} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Please choose csv file to import client</DialogTitle>
        <DialogContent>
          <div
            className={`${classes.dropzone} ${this.state.hightlight ? classes.highlight : ''}`}
            onClick={this.openFileDialog}
            onDragOver={this.onDragOver}
            onDragLeave={this.onDragLeave}
            onDrop={this.onDrop}
          >
            <CloudUpload />
            <input
              ref={this.fileInputRef}
              className={classes.fileInput}
              type="file"
              multiple
              onChange={this.onFilesAdded}
            />
            <span>Upload File</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFileUploader} color="primary">
            {translate('Common:cancel')}
          </Button>

        </DialogActions>
      </Dialog>
    );
  }
}

DropZoneUploadFile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array
};

DropZoneUploadFile.defaultProps = {
  selectedUsers: []
};

export default withStyles(styles)(DropZoneUploadFile);

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getInvoices } from 'redux/invoice';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  Typography,
} from '@material-ui/core';
import {
  Portlet,
  PortletContent,
} from 'pages/Dashboard/components';
import { OnlyDecimal } from 'components/converters';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { formatPrice } from 'lib/formatter';
import { showErrorMessage } from 'lib/notifier';

const styles = (theme) => ({
  root: {},
  cardHeader: {
    backgroundColor: theme.palette.background.muted2,
  },
  portlet: {
    border: 'none',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  assignButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  invoiceId: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
});

const Invoices = (props) => {
  const {
    invoiceStatuses,
    currency,
    classes,
    translate,
    jobId
  } = props;
  const dispatch = useDispatch();
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    (async () => {
      const params = {
        $limit: 100,
        $skip: 0,
        jobId
      };
      const response = await dispatch(getInvoices({ params, url: 'v2/invoices' })).catch((e) => ({ e }));
      if (response.e) return showErrorMessage(response.e);
      if (response.data && response.data.data) {
        setInvoices(response.data.data);
      }
    })();
  }, [dispatch]);

  const clickBillingRow = (id) => {
    props.history.push(`/invoices/${id}`);
  };

  function getStatus(id) {
    const jobItem = invoiceStatuses.find((item) => item.id === id);
    return jobItem ? jobItem.name : id;
  }

  let totalInvoice = 0;
  invoices &&
    invoices.forEach((invoice) => {
      totalInvoice = OnlyDecimal(
        parseFloat(totalInvoice) + parseFloat(invoice.total)
      );
    });

  return (
    <Portlet>
      <PortletContent noPadding>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">{translate('id')}</TableCell>
                <TableCell align="left">{translate('date')}</TableCell>
                <TableCell align="left">{translate('subject')}</TableCell>
                <TableCell align="left">{translate('status')}</TableCell>
                <TableCell align="left">{translate('total')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices &&
                invoices.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      className={classes.tableRow}
                      onClick={() => clickBillingRow(row.id)}
                    >
                      <TableCell align="left">
                        <Typography className={classes.invoiceId}>
                          {row.id}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {' '}
                        {moment(row.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell style={{ minWidth: 150 }} align="left">
                        {row.subject}
                      </TableCell>
                      <TableCell style={{ minWidth: 150 }} align="left">
                        {getStatus(row.statusId)}
                      </TableCell>
                      <TableCell style={{ minWidth: 150 }} align="left">
                        {formatPrice(row.total, row.currency)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TableCell align="left">
                  <strong>{translate('totals')}</strong>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell align="left">
                  <strong>{formatPrice(totalInvoice, currency)}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </PortletContent>
    </Portlet>
  );
};

export default compose(withStyles(styles), withRouter)(Invoices);

import { DATE_FORMAT } from 'common/constant';
import moment from 'moment-timezone';
import React from 'react';

const { Box } = require('@material-ui/core');

const LabelVisitOn = (props) => {
  const { publicId, visitOn } = props;
  const getDate=(date) => {
    if(date !== 'Unscheduled') {
      return moment(date, DATE_FORMAT).format('ll');
    }
    return date;
  };
  return (
    <Box display="flex" flexDirection="column">
      <Box color="#A2A2A2">{publicId}</Box>
      <Box>{getDate(visitOn)}</Box>
    </Box>
  );
};

export default LabelVisitOn;
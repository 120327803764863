import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grow,
  IconButton,
  Typography,
} from '@material-ui/core';
import { EMAIL_VALIDATION } from 'common/constant';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// Customised components
import { TextInput } from 'components/inputs';

import withStopPropagation from 'lib/stopPropagation';

// Component styles
import styles from './styles';

const mapStateToProps = (state, ownProps) => {
  let { clientEmail } = ownProps;

  if (!clientEmail) {
    const { client } = state.invoice.invoice;
    if (client && client.email) {
      clientEmail = client.email;
    } else if (client && client.contactEmails) {
      clientEmail = (client.contactEmails.find(email => email.typeId === 'MAIN') || {}).email;
    }
  }

  return ({
    initialValues: {
      email: clientEmail
    },
  });
};

const mapDispatchToProps = dispatch => ({
});

const validate = values => {
  let errors = { message: {} };
  const requiredFields = [
    'email',
  ];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
      errors.message[field] = `${field}Required`;
    }
  });
  if (
    values.email &&
    !EMAIL_VALIDATION.test(values.email)
  ) {
    errors = {
      ...errors,
      email: 'invalidEmail',
      message: {
        ...errors.message,
        email: 'invalidEmail'
      }
    };
  }

  return errors;
};

function ConfirmEmailModal(props) {
  const {
    classes,
    invalid,
    open,
    handleClose,
    submitting,
    translate,
    title = 'sendInvoiceToEmail',
    handleSubmit
  } = props;

  function handleSubmitForm(event) {
    return withStopPropagation(handleSubmit)(event);
  }

  return (
    <Dialog
      maxWidth='lg'
      aria-labelledby="customized-dialog-title"
      open={open}
      transition={Grow}
    >
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmitForm}
      >
        <DialogTitle disableTypography className={classes.teamDialogTitle}>
          <Typography variant='h6' className={classes.teamDialogTitleText}>{translate(`Common:${title}`)}</Typography>
          {open ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Field
            component={TextInput}
            name="email"
            label={translate('Common:email')}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            {translate('Common:cancel')}
          </Button>
          <Button
            color="primary"
            disabled={invalid || submitting}
            variant="contained"
            size="small"
            type="submit"
          >
            {translate('Common:sendEmail')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(reduxForm({ form: 'paymentEmail', validate, enableReinitialize : true, destroyOnUnmount: true })(ConfirmEmailModal));

export default theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.neutral}`,
    borderRadius: '5px',
    display: 'flex',
    flexBasis: '420px',
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
});

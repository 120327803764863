import React, { useEffect } from 'react';
import { setSubscription } from 'api/subscriptionApi';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';
import SubscriptionForm from './SubscriptionForm';
import { DEFAULT_PLAN } from 'common/constant';
import { showSuccessMessage } from 'lib/notifier';

function formatFormData(data) {
  const {
    planCode, billingInterval,
    useTeamTracking, useTimesheet,
    // useMarketing
  } = data.subscription;
  return {
    planCode: planCode || DEFAULT_PLAN,
    billingInterval: billingInterval || 'month',
    useTeamTracking,
    useTimesheet,
    // useMarketing,
  };
}

const SubscriptionPlan = (props) => {
  const { translate, initForm, plans, paymentMethod, hasBillingMethod,
    showPaymentMethodDialog, closeSubscriptionPlanModal } = props;

  useEffect(() => {
    initForm(formatFormData(plans));
  }, [plans]);

  function handleSubmit(values) {
    setSubscription(values).then(res => {
      if (res.data.subscription && !hasBillingMethod) {
        showPaymentMethodDialog();
      } else {
        showSuccessMessage(translate('subscriptionPlanSaved'));
        closeSubscriptionPlanModal();
      }
    });
  }

  return (
    <>
      <SubscriptionForm onSubmit={handleSubmit} translate={translate} paymentMethod={paymentMethod} plans={plans} />
    </>
  );
};

const mapDispatch = (dispatch) => ({
  initForm: data => dispatch(initialize('subscriptionForm', data)),
});

export default compose(
  connect(null, mapDispatch),
  reduxForm({ form: 'subscriptionForm' }),
)(SubscriptionPlan);
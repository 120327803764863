export default {
  // common && crumbs
  addInvoice:	'+ ใบแจ้งหนี้/ใบกำกับภาษีใหม่',
  invoices:	'ใบแจ้งหนี้/ใบกำกับภาษี',
  invoiceDetails:	'รายละเอียดใบแจ้งหนี้/ใบกำกับภาษี',
  editInvoice:	'แก้ไขใบแจ้งหนี้/ใบกำกับภาษี',
  invoice:	'ใบแจ้งหนี้/ใบกำกับภาษี',

  // invoices table
  status:	'สถานะ',
  dueOn:	'ครบกำหนดชำระ',
  number:	'Number',
  title:	'หัวข้อ',
  client:	'ลูกค้า',
  price:	'ราคา',
  schedule:	'นัดหมาย',
  search:	'ค้นหา',
  showArchived:	'แสดงข้อมูลที่จัดเก็บ',
  rows:	'แถว',
  of:	'ของ',
  createdBy: 'Created By',

  // invoice toolbar menu
  sendAs:	'ส่งทาง',
  collectSignature:	'เก็บลายเซ็น',
  downloadPdf:	'ดาวน์โหลด PDF',
  print:	'ปรินท์',
  edit: 'แก้ไข',

  // DETAILS
  backTo:	'กลับไปยัง: ',
  markAs:	'เปลี่ยนสถานะเป็น...',
  archive:	'จัดเก็บ',
  unarchive:	'ยกเลิกการจัดเก็บ',
  collectPayment:	'ชำระเงิน',
  recordPayment: 'บันทึกการรับชำระเงิน',
  emailInvoice:	'อีเมลใบแจ้งหนี้/ใบกำกับภาษี',
  billingAddress:	'ที่อยู่ในการออกใบแจ้งหนี้/ใบกำกับภาษี',
  contactDetails:	'ข้อมูลผู้ติดต่อ',
  clientDetails: 'Client Details',
  invoiceNumber:	'เลขใบแจ้งหนี้/ใบกำกับภาษี',
  issued:	'ออกเมื่อ',
  clientNoName:	'ไม่มีชื่อลูกค้า',
  jobs: 'งาน',
  oneJob: '1 งาน',
  notSentYet: 'ยังไม่ถูกส่ง',
  notSetYet: 'ยังไม่ถูกเลือก',
  signature: 'ลายเซ็นต์',
  signedAt: 'วันที่',

  // product/service
  productService:	'สินค้า/บริการ',
  quantity:	'จำนวน',
  unitPrice:	'ราคาต่อหน่วย',
  taxRate:	'อัตราภาษี (%)',
  total:	'รวม',
  beforeTax:	'ก่อน \n ภาษี',
  subtotal:	'รวม',
  tax:	'ภาษี',
  totalDue:	'รวมทั้งหมด',
  invoiceBalance:	'ยอดค้างชำระ',
  accountBalance:	'ยอดค้างชำระรวม',
  discount:	'ส่วนลด',


  // INVOICE FORM
  newInvoice:	'ใบแจ้งหนี้/ใบกำกับภาษีใหม่',
  whichClient:	'ใบแจ้งหนี้/ใบกำกับภาษีนี้ถูกสร้างสำหรับลูกค้าบัญชีใด',
  searchClient:	'ค้นหาลูกค้า',
  saveInvoice:	'บันทึกใบแจ้งหนี้/ใบกำกับภาษี',
  saveAnd:	'บันทึกและ...',
  invoiceFor:	'ใบแจ้งหนี้/ใบกำกับภาษีสำหรับ',
  invoiceSubject:	'หัวข้อใบแจ้งหนี้/ใบกำกับภาษี',
  inputInvoiceSubject:	'ใส่หัวข้อใบแจ้งหนี้/ใบกำกับภาษีตรงนี้',
  contactNo:	'เบอร์โทรศัพท์ผู้ติดต่อ',
  issuedAt:	'ออกเมื่อ',
  paymentDue:	'ครบกำหนดชำระ',
  dueDate: 'วันครบกำหนดชำระ',
  nextStep: 'ต่อไป',
  selectJob: 'เลือกงานที่คุณต้องการออกใบแจ้งหนี้/ใบกำกับภาษี',
  noJobForSelection: 'ยังไม่มีงานสำหรับลูกค้านี้',
  thankYou: 'Thank you for your business. Please contact us with any questions regarding this Quote.',
  noNote: 'No notes recorded.',
  note: 'Note',
  // line items
  lineItems:	'สินค้า/บริการ',
  serviceProduct:	'สินค้า/บริการ',
  qty:	'จำนวน',
  totalBeforeTax:	'ยอดรวม (ก่อนภาษี)',
  item:	'สินค้า/บริการ',
  addLineItem:	'+ เพิ่มสินค้า/บริการ',
  clientMessage:	'หมายเหตุ',
  totalPrice: 'ราคารวม',

  internalNotes:	'บันทึกสำหรับใช้ภายใน',
  noteDetails:	'รายละเอียดบันทึก',
  sendingEmail: 'กำลังส่งอีเมล...',
  sendingSMS: 'กำลังส่ง SMS...',
  sentEmail: 'ส่งอีเมลใบแจ้งหนี้/ใบกำกับภาษีเรียบร้อยแล้ว',
  sentSMS: 'ส่ง SMS แล้ว!',
  exportInvoices: 'ส่งอออกใบแจ้งหนี้/ใบกำกับภาษี',
  issuedAtTo: 'ถึง',
  issuedAtFrom: 'จาก',
  createdOn: 'สร้างขึ้นเมื่อ',
};

export const convertDateInParams = ({ completionTimeFrom, completionTimeTo, createdOnFrom,
  createdOnTo, scheduledOnFrom, scheduledOnTo, ...rest }) => {
  return { ...rest,
    completionTimeFrom: completionTimeFrom && new Date(Number(completionTimeFrom)),
    completionTimeTo: completionTimeTo && new Date(Number(completionTimeTo)),
    createdOnFrom: createdOnFrom && new Date(Number(createdOnFrom)),
    createdOnTo: createdOnTo && new Date(Number(createdOnTo)),
    scheduledOnFrom: scheduledOnFrom && new Date(Number(scheduledOnFrom)),
    scheduledOnTo: scheduledOnTo && new Date(Number(scheduledOnTo)),
  };
};
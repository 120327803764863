function idObjectOnly(item) {
  if (!item || !item.id) return {};
  return { id: item.id };
}

export default (requestFormValues) => {
  const {
    publicId,
    selectedClient,
    selectedProperty,
    internalNotes,
    title,
    description,
    categories,
    products,
    orderId,
    dn,
    hub,
  } = requestFormValues;
  const values = {};

  values.publicId = publicId || null;
  values.client = idObjectOnly(selectedClient);
  values.property = idObjectOnly(selectedProperty);
  values.title = title || null;
  values.description = description || null;
  values.notes = internalNotes || null;
  values.categories = categories || null;
  values.items = products || null;

  values.orderId = orderId && orderId.trim();
  values.deliveryNote = dn;
  values.hubId = hub && hub.value;
  return values;
};

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const defaultTimeToHideNotifier = 4000;

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.info.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    '& b': {
      color: 'white'
    }
  }
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (

    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
};

let openSnackbarFn;

class Notifier extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      variant: props.variant || 'info',
      vertical: props.vertical || 'top',
      horizontal: props.vertical || 'center',
      message: props.message || '',
      autoHideDuration: defaultTimeToHideNotifier,
      closedCallback: null
    };

    this.handleSnackbarRequestClose = this.handleSnackbarRequestClose.bind(this);
    this.openSnackbar = this.openSnackbar.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  handleSnackbarRequestClose = () => {
    this.setState({
      open: false,
      message: '',
    });
  };

  openSnackbar = ({ message, variant, autoHideDuration, closedCallback }) => {
    this.setState({
      message,
      open: true,
      variant: variant || 'info',
      autoHideDuration: autoHideDuration || defaultTimeToHideNotifier,
      closedCallback
    });
  };


  handleClose() {
    this.setState({ open: false, message: null });
    if (this.state.closedCallback) this.state.closedCallback();
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: this.state.vertical || 'top',
          horizontal: this.state.horizontal || 'center'
        }}
        open={this.state.open}
        autoHideDuration={this.state.autoHideDuration}
        onClose={this.handleClose}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant={this.state.variant}
          message={this.state.message}
        />
      </Snackbar>
    );
  }
}

export function openSnackbar(options) {
  openSnackbarFn(options);
}

export default Notifier;
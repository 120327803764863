import React from "react";

// Externals
import { Link } from "react-router-dom";

// Material components
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";

// Component styles
import { RequestClientNameHeaderStyles } from "./styles";
import ConfirmArchiveRequestModal from "../modals/ConfirmArchiveRequestModal";

export default withStyles(RequestClientNameHeaderStyles)((props) => {
  const {
    allowedStatuses,
    classes,
    handleClickArchive,
    handleClickStatus,
    request,
    requestStatuses,
    translate,
  } = props;
  const { publicId, statusId } = request;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmArchiveRequest, setConfirmArchiveRequest] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Grid className={classes.headerContainer} item md={5} sm={12}>
        <Grid item container direction="column">
          <Grid itemScope>
            <Typography className={classes.requestTitle} variant="h3">
              {request.title}
            </Typography>
          </Grid>
          <Grid item container direction="row">
            <Typography
              className={classes.textColor}
              style={{ marginRight: 10 }}
              variant="h4"
            >
              ID:{" "}
            </Typography>
            <Typography className={classes.textColor} variant="h4">
              {request.publicId}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="flex-end"
        spacing={1}
        md={7}
        sm={12}
      >
        <Grid item>
          {(request.permissions || {}).ableToEdit ? (
            <Link to={`/requests/${publicId}/edit`}>
              <Button variant="contained" color="primary">
                {translate("Common:edit")}
              </Button>
            </Link>
          ) : (
            <Button variant="contained" color="primary" disabled>
              {translate("Common:edit")}
            </Button>
          )}
        </Grid>
        {!request.archived && (
          <Grid item>
            <Button
              aria-controls="status-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              {translate("Common:markAs")}
            </Button>
            <Menu
              id="status-menu"
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.popoverWithArrow}
            >
              {requestStatuses &&
                requestStatuses.map((item) => {
                  if (allowedStatuses.includes(item.id)) {
                    return (
                      <MenuItem
                        key={item.id}
                        disabled={statusId === item.id}
                        onClick={(e) => {
                          handleClickStatus(e, item.id);
                          setAnchorEl(null);
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
            </Menu>
          </Grid>
        )}

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (request.archived) return handleClickArchive();
              return setConfirmArchiveRequest(true);
            }}
          >
            {request.archived
              ? translate("Common:unarchive")
              : translate("Common:archive")}
          </Button>

          {confirmArchiveRequest && (
            <ConfirmArchiveRequestModal
              open={confirmArchiveRequest}
              handleClose={() => {
                setConfirmArchiveRequest(false);
              }}
              handleConfirmArchive={handleClickArchive}
              translate={translate}
              requestId={request.publicId}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
});

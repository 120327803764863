import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import styles from './styles/ServiceReportHeader.module.scss';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import CompanyLogoSrc from 'assets/images/service-report/companyLogo.svg';

const ServiceReportHeader = ({ noPadding, reportNo, createdAt }) => {
  const { t: translate } = useTranslation('Job');
  const companyDetails = useSelector(
    (state) =>
      (state.auth.currentUser && state.auth.currentUser.companies[0]) || {}
  );
  return (
    <Grid
      container
      justify="space-between"
      wrap="nowrap"
      className={noPadding ? null : 'p_10 pl_20 pr_20'}
    >
      <Grid container item className={styles.dp_flex}>
        <img
          className={styles.companyImg}
          src={
            companyDetails.profilePicture
              ? companyDetails.profilePicture
              : CompanyLogoSrc
          }
          alt="company_logo"
        />
        <Grid item>
          <Typography variant="h3" className="mb_10">
            {companyDetails.name}
          </Typography>
          <Typography variant="body1">{companyDetails.address}</Typography>
          <Typography variant="body1">
            <Trans
              i18nKey="Job:companyPhone"
              values={{
                countryPhoneCode: companyDetails.countryPhoneCode,
                phoneNumber: companyDetails.contactPhoneNumber,
              }}
            />
          </Typography>
          <Typography variant="body1">
            <Trans
              i18nKey="Job:companyEmail"
              values={{ email: companyDetails.contactEmail }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid container item direction="column" alignItems="flex-end">
        <Typography variant="h3" className="mb_20">
          {translate('serviceReport').toUpperCase()}
        </Typography>
        {reportNo && (
          <Typography variant="body1">
            <Trans i18nKey="Job:reportNumber">
              <strong>{reportNo.toString()}</strong>
            </Trans>
          </Typography>
        )}
        {createdAt && (
          <Typography variant="body1">
            <Trans i18nKey="Job:reportDate">
              <strong>{createdAt}</strong>
            </Trans>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ServiceReportHeader;

export const checkItemsCheckbox = (values) =>
  values?.reduce((prev, current) => {
    if (!current.checked) return prev;
    return [...prev, current.value];
  }, []);

export function checkStatusIds(statusIds) {
  if (statusIds) {
    if (Array.isArray(statusIds) ) {
      return statusIds;
    }
    return [statusIds];
  }
  return statusIds;
}
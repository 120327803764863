import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export function BackdropCustom(props) {
  const { open, ...rest } = props;
  const classes = useStyles();

  return (
    <Backdrop {...rest} className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
export function useBackdrop() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = async () => {
    setOpen(true);
  };

  const withLoading = (promise) => {
    return Promise.all([promise, handleOpen()]).then(values => values[0]).finally(() => {
      handleClose();
    });
  };
  return [open, withLoading];
}
export function withBackdropHook(Component) {
  return function WrappedComponent(props) {
    const [open, withLoading] = useBackdrop();
    return <Component {...props} backdrop={open} withLoading={withLoading} />;
  };
}

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  FieldArray,
} from 'redux-form';
// Material Components
import { Button, Grid } from '@material-ui/core';
// Shared components
import styles from './styles.module.scss';
import TaxRates from './taxRates';
import TaxNumbers from './taxNumbers';
import TaxComponents from './taxComponents';

const taxSettingForm = formValueSelector('TaxSettingForm');

let TaxSettingsForm = (props) => {
  const {
    pristine,
    invalid,
    submitting,
    handleSubmit,
    translate,
    taxRates = [],
  } = props;

  return (
    <div className={styles.companySetting}>
      <form className="form-body" onSubmit={handleSubmit}>
        <div className={styles.formTitle}>
          <div className={styles.title}>
            <h2>{translate('CompanySetting:taxSettings')}</h2>
            <h5>{translate('CompanySetting:ifAvailable')}</h5>
          </div>
          <div className={styles.submitBtnWrap}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              size="large"
              className={styles.btnStyle}
              disabled={pristine || submitting || invalid}
            >
              {translate('CompanySetting:saveChange')}
            </Button>
          </div>
        </div>
        <Grid item container spacing={2} justify="center">
          <FieldArray
            name="taxNumbers"
            component={TaxNumbers}
            styles={styles}
            translate={translate}
          />
          <FieldArray
            name="taxRates"
            component={TaxRates}
            styles={styles}
            translate={translate}
          />
          <FieldArray
            name="taxComponents"
            component={TaxComponents}
            styles={styles}
            translate={translate}
            taxRates={taxRates}
            rerenderOnEveryChange
          />
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { taxes } = ownProps;
  return {
    initialValues: {
      ...taxes,
    },
    taxRates: taxSettingForm(state, 'taxRates'),
  };
};

TaxSettingsForm = reduxForm({
  form: 'TaxSettingForm',
  enableReinitialize: true
})(TaxSettingsForm);

export default compose(connect(mapStateToProps, null))(TaxSettingsForm);

import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid, Typography } from '@material-ui/core';

// Component styles
import styles from './styles';

const StaffToolbar = props => {
  const {
    classes,
    className,
    translate,
  } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Grid container direction='row' justify='space-between'>
        <Grid item container direction='row' alignItems='center' md={4} sm={12}>
          <Typography variant='h5' className={classes.backTitle}>
            {translate('Common:backTo')}
          </Typography>
          <Link to='/teams'>
            <Typography variant='h5' className={classes.backTo}>
              {translate('team')}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </div>
  ); };

StaffToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array
};

StaffToolbar.defaultProps = {
  selectedUsers: []
};

export default withStyles(styles)(StaffToolbar);

import React from 'react';
import { ConvertButton } from './components';
import './styles.scss';
import { BackdropCustom, useBackdrop } from 'lib/loading';

export const HandleConvertModal = (props) => {
  const [openBackdrop] = useBackdrop();
  const { request, history } = props;
  return (
    <>
      <ConvertButton request={request} history={history}/>
      <BackdropCustom open={openBackdrop} />
    </>
  );
};

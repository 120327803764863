export default {
  jobID: 'Job ID',
  nextJobs: 'Next Jobs',
  estimateTimeOfArrival: 'Estimated time of arrival',
  clientName: 'Client Name',
  propertyAddress: 'Property Address',
  jobTitle: 'Job Title',
  sendSMS:	'Send SMS',
  noJob: 'Current job unavailable',
  unscheduled: 'Unscheduled',
  currentJob: 'Current Job Details',
  jobSchedule: 'Job Schedule',
};
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import styles from '../components.module.scss';

const mapStatusIdToClassName = {
  COMPLETED: styles.completedEvent,
  LATE_VISIT: styles.lateEvent,
  UNASSIGNED: styles.unassignedEvent,
  ON_STANDBY: styles.onStandbyEvent,
  ON_THE_WAY: styles.onTheWayEvent,
  ARRIVED: styles.arrivedEvent,
  STARTED: styles.startedEvent,
  CANCELLED: styles.cancelledEvent
};

const Event = ({ event }) => {
  const { t } = useTranslation('Calendar');
  const itemClassName =
    mapStatusIdToClassName[event.statusId] || styles.onStandbyEvent;

  return (
    <Grid className={itemClassName}>
      <Grid container className={styles.eventTitle}>
        {t('job')}: {moment(event.start).format('HH:mm A')}
      </Grid>
      <Grid className={styles.eventTitle}>{event.title}</Grid>
    </Grid>
  );
};

export default Event;

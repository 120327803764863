import React from 'react';

import { Field, FieldArray } from 'redux-form';
import { Checkbox } from 'components/inputs';

const settingComponent = props => {
  const { translate, fields } = props;
  return (
    fields.map((field, index) => {
      return(
        <div key={index}>
          <div>
            <Field
              component={Checkbox}
              label={translate('Job:skipOnTheWayTracking')}
              name={`${field}.skipOnTheWayTracking`}
            />
          </div>
          <div>
            <Field
              component={Checkbox}
              label={translate('Job:skipArrivedTracking')}
              name={`${field}.skipArrivedTracking`}
            />
          </div>
        </div>
      );
    })
  );
};

export default props => {
  const { translate } = props;

  return (
    <>
      <FieldArray
        name='additionalSettings'
        component={settingComponent}
        translate={translate}
      />
    </>
  );
};

import React from 'react';

// Externals
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getLocationDetails } from 'redux/location';
import { sendSmsToStaff } from 'redux/staff';
import { getVisits } from 'redux/visit';

// Material helpers
// Material components
import { Grid, Avatar, Button, Menu, MenuItem, withStyles } from '@material-ui/core';

// Material Icons
import { Person, Phone, Email, ArrowDropDown } from '@material-ui/icons';

// shared component
import { DirectionMap } from 'components/gpsTracking';
import { showSuccessMessage, showErrorMessage } from 'lib/notifier';
// modal components
import { SendSMSForm, SendNotificationForm, UnassignedJobList } from './components';
import { STATUSES } from 'common/constant';
import StaffDefaultAvatar from 'assets/images/staff_default_avatar.svg';
// Component styles
import styles from './styles';
import { getDateTimeFormat, getTimeFormat } from 'lib/formatter';

const heightTopbar = '62px';

class WorkerTrackingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workerDetails: null,
      showSmsForm: false,
      showNotificationForm: false,
      showUnassignedJobVisits: false,
      unAssignedJobVisits: [],
      estimateOfTimeArrival: null,
      drowdownNextJobAnchorEl: null
    };
    this.closeSendingSmsForm = this.closeSendingSmsForm.bind(this);
    this.closeSendingNotificationForm = this.closeSendingNotificationForm.bind(this);
    this.handleSendSmsToStaff = this.handleSendSmsToStaff.bind(this);
    this.handleCloseUnassignedJobModal = this.handleCloseUnassignedJobModal.bind(this);
    this.handleUpdateETAFromMap = this.handleUpdateETAFromMap.bind(this);
    this.handleCloseNextJobList = this.handleCloseNextJobList.bind(this);
  }

  componentDidMount() {
    this.fetchWorderDetails(this.props.userId);
  }

  fetchWorderDetails(workerId) {
    this.props.getLocationDetails(workerId).then(response => {
      const { data } = response;

      if (data.currentJob) {
        data.currentJob.jobLocation = data.currentJob && data.currentJob.jobLocation;
      }
      this.setState({ workerDetails: data });
    }, error => {
      showErrorMessage(error);
    });
  }

  fetchUnassignedJobVisits() {
    const options = { statusId: STATUSES.UNASSIGNED };
    options.sort = { field: 'createdAt', orderDirection: 'asc' };
    return this.props.getVisits(options).then((response) => {
      if (response.status === 200) {
        const visits = [...response.data.data];
        this.setState({
          unAssignedJobVisits: visits
        });
      }
    }, (error) => {
      showErrorMessage(error);
    });
  }

  showFormToSendSMS() {
    this.setState({ showSmsForm: true });
  }

  closeSendingSmsForm() {
    this.setState({ showSmsForm: false });
  }

  showFormToSendNotification() {
    this.setState({ showNotificationForm: true });
  }

  closeSendingNotificationForm() {
    this.setState({ showNotificationForm: false });
  }

  showUnassignedJobVisitsPopup() {
    this.fetchUnassignedJobVisits();
    this.setState({ showUnassignedJobVisits: true });
  }

  closeUnassignedJobVisitsPopup() {
    this.setState({ showUnassignedJobVisits: false, unAssignedJobVisits: [] });
  }

  handleSendSmsToStaff(value) {
    const { sendSmsToStaffFunction } = this.props;
    const { workerDetails } = this.state;
    sendSmsToStaffFunction(
      workerDetails.id,
      value.message
    ).then(response => {
      if (response.status === 200) {
        showSuccessMessage('SMS sent!');
        this.closeSendingSmsForm();
      } else {
        showErrorMessage(response.data.message);
      };
    }, error => {
      showErrorMessage(error);
    });
  }

  clientName() {
    const { workerDetails: { currentJob: { job: { client:
      { displayName } = {} } = {} } = {} } = {} } = this.state;
    return displayName;
  }

  handleCloseUnassignedJobModal() {
    this.setState({ showUnassignedJobVisits: false });
  }

  handleUpdateETAFromMap(timeInSecond) {
    let date = null;
    if (timeInSecond) {
      date = new Date();
      date.setSeconds(date.getSeconds() + parseInt(timeInSecond));
    }
    this.setState({ estimateOfTimeArrival: date });
  }

  handleOpenNextJobList(event) {
    this.setState({ drowdownNextJobAnchorEl: event.currentTarget });
  }

  handleCloseNextJobList() {
    this.setState({ drowdownNextJobAnchorEl: null });
  }


  render() {
    function getAddress(location) {
      return [
        location.unitNumber,
        location.address,
        location.city
      ].filter(e => e)
        .join(', ');
    }

    const { classes, translate } = this.props;
    const { showNotificationForm, workerDetails, showSmsForm, drowdownNextJobAnchorEl,
      unAssignedJobVisits, showUnassignedJobVisits, estimateOfTimeArrival } = this.state;

    return (
      <div className={classes.root}>
        <Grid
          container
          justify="center"
        >
          <Grid
            item
            lg={3}
            xs={12}
          >
            <div className={classes.workerDetailsSection}>
              <Grid container spacing={2} className={classes.detailsWrap}>
                <Grid item lg={12} xs={12}>
                  <div className={classes.rowDetails}>
                    <div className={classes.workerAvatar}>
                      <Avatar className={classes.avatar} src={(workerDetails || {}).avatar || StaffDefaultAvatar} />
                    </div>
                    <div className={classes.statusButtonWrap}>
                      { workerDetails && workerDetails.currentJob && workerDetails.currentJob.status &&
                        <div>
                          <Button className={classes.statusButton}>{workerDetails.currentJob.status}</Button>
                        </div>
                      }
                    </div>
                  </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div className={classes.rowDetails}>
                    <div className={classes.textField}>
                      <Person className={classes.valueIcon} />
                      <div className={classes.text}>
                        <div className={classes.value}>
                          {workerDetails && workerDetails.fullName}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.rowDetails}>
                    <div className={classes.textField}>
                      <Phone className={classes.valueIcon} />
                      <div className={classes.text}>
                        <div className={classes.value}>
                          { workerDetails && [ workerDetails.countryPhoneCode,
                            workerDetails.phoneNumber].filter(Boolean).join(' ')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.rowDetails}>
                    <div className={classes.textField}>
                      <Email className={classes.valueIcon} />
                      <div className={classes.text}>
                        <div className={classes.value}>
                          {workerDetails && workerDetails.email}
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    workerDetails && workerDetails.currentJob &&
                    <>
                      <div className={classes.rowDetails}>
                        <div className={classes.text}>
                          <div className={classnames(classes.label, classes.sectionTitle)}>
                            {translate('WorkerTracking:currentJob')}
                          </div>
                        </div>
                      </div>

                      <div className={classes.rowDetails}>
                        <Grid container spacing={1}>
                          {/* JOB Id */}
                          <Grid item lg={6} xs={6}>
                            <div className={classes.textField}>
                              <div className={classes.text}>
                                <div className={classes.label}>{translate('WorkerTracking:jobID')}:</div>
                                <div className={classes.value}>
                                  {workerDetails && workerDetails.currentJob && workerDetails.currentJob.job.publicId }
                                </div>
                              </div>
                            </div>
                          </Grid>

                          {/* Worker ETA */}
                          <Grid item lg={6} xs={6}>
                            <div className={classes.textField}>
                              <div className={classes.text}>
                                <div className={classes.label}>
                                  {translate('WorkerTracking:estimateTimeOfArrival')}:</div>
                                <div className={classes.value}>
                                  {estimateOfTimeArrival ? moment(estimateOfTimeArrival).format(getTimeFormat()) : ''}
                                </div>
                              </div>
                            </div>

                          </Grid>
                        </Grid>
                      </div>

                      {/* JOB Client */}
                      <div className={classes.rowDetails}>
                        <div className={classes.textField}>
                          <div className={classes.text}>
                            <div className={classes.label}>{translate('WorkerTracking:clientName')}:</div>
                            <div className={classes.value}>{this.clientName()}</div>
                          </div>
                        </div>
                      </div>

                      {/* JOB Title */}
                      <div className={classes.rowDetails}>
                        <div className={classes.textField}>
                          <div className={classes.text}>
                            <div className={classes.label}>{translate('WorkerTracking:jobTitle')}:</div>
                            <div className={classes.value}>
                              { workerDetails && workerDetails.currentJob && workerDetails.currentJob.title }
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* JOB Schedule */}
                      <div className={classes.rowDetails}>
                        <div className={classes.textField}>
                          <div className={classes.text}>
                            <div className={classes.label}>{translate('WorkerTracking:jobSchedule')}:</div>
                            <div className={classes.value}>
                              {
                                workerDetails &&
                                `${ moment( workerDetails.currentJob.start).format(getTimeFormat())}
                                -
                                ${moment( workerDetails.currentJob.end).format(getTimeFormat())}`
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* JOB Address */}
                      <div className={classes.rowDetails}>
                        <div className={classes.textField}>
                          <div className={classes.text}>
                            <div className={classes.label}>{translate('WorkerTracking:propertyAddress')}:</div>
                            <div className={classes.value}> {
                              workerDetails && workerDetails.currentJob &&
                              workerDetails.currentJob.job &&
                              workerDetails.currentJob.job.property &&
                              [ workerDetails.currentJob.job.property.unitNumber,
                                workerDetails.currentJob.job.property.address,
                                workerDetails.currentJob.job.property.city,
                                workerDetails.currentJob.job.property.state
                              ].filter(e => e)
                                .join(', ')
                            }</div>
                          </div>
                        </div>
                      </div>
                    </>
                  }

                  {/* NEXT JOB */}
                  <div className={classes.rowDetails}>
                    <div className={classes.text}>
                      <div className={classnames(classes.label, classes.sectionTitle)}>
                        {translate('WorkerTracking:nextJobs')}
                      </div>
                    </div>
                  </div>
                  <div className={classes.rowDetails}>
                    {
                      workerDetails && workerDetails.nextJobs &&
                      workerDetails.nextJobs[0] ?

                        <div
                          className={classnames(classes.textField, classes.pointer)}
                          onClick={(e) => this.handleOpenNextJobList(e)}
                        >
                          <div className={classes.text}>
                            <>
                              <div className={classes.jobTimeline}>
                                {
                                  workerDetails.nextJobs[0].start ?
                                    `${ moment( workerDetails.nextJobs[0].start).format(getDateTimeFormat())}
                                    -
                                    ${moment( workerDetails.nextJobs[0].end).format(getTimeFormat())}`

                                    : translate('WorkerTracking:unscheduled')
                                }
                              </div>
                              <div className={classes.jobAddress}>
                                {
                                  workerDetails && workerDetails.nextJobs &&
                                  workerDetails.nextJobs[0].job.property &&
                                  getAddress(workerDetails.nextJobs[0].job.property)
                                }
                              </div>
                            </>
                          </div>
                          <ArrowDropDown />
                        </div>
                        :
                        <div className={classes.textField}>
                          <div className={classes.text}>
                            {translate('WorkerTracking:noJob')}
                          </div>
                        </div>

                    }

                  </div>
                </Grid>
              </Grid>
              <div className={classes.footerAction}>
                <Button
                  className={classes.actionBtn}
                  onClick={_e => this.showFormToSendSMS()}
                >
                  {translate('Common:sendSms')}
                </Button>
                <Button
                  disabled
                  className={classnames(classes.actionBtn, classes.disabledBtn)}
                  onClick={_e => this.showFormToSendNotification()}
                >
                  {translate('Common:sendNotification')}
                </Button>
                <Button
                  className={classnames(classes.actionBtn)}
                  onClick={_e => this.showUnassignedJobVisitsPopup()}
                >
                  {translate('Common:assignJob')}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={9}
            xs={12}
          >
            <div className={classes.content} style={{ height: `calc(100vh - ${heightTopbar})`, width: '100%' }}>
              {
                workerDetails && workerDetails.userLocation &&
                <DirectionMap
                  workerDetails={workerDetails}
                  handleUpdateETAFromMap={value => this.handleUpdateETAFromMap(value)}
                />
              }
            </div>
          </Grid>
        </Grid>

        {
          showSmsForm &&
          <SendSMSForm
            open={showSmsForm}
            translate={translate}
            handleClose={this.closeSendingSmsForm}
            onSubmit={this.handleSendSmsToStaff}
          />
        }

        {
          showNotificationForm &&
          <SendNotificationForm
            open={showNotificationForm}
            translate={translate}
            handleClose={this.closeSendingNotificationForm}
          />
        }

        {
          showUnassignedJobVisits &&
          <UnassignedJobList
            open={showUnassignedJobVisits}
            translate={translate}
            unAssignedJobVisits={unAssignedJobVisits}
            workerDetails={workerDetails}
            handleCloseUnassignedJobModal={this.handleCloseUnassignedJobModal}
          />
        }

        {/* Dropdown for next job */}
        <Menu
          id="simple-menu"
          anchorEl={drowdownNextJobAnchorEl}
          keepMounted
          open={Boolean(drowdownNextJobAnchorEl)}
          onClose={this.handleCloseNextJobList}
          className={classes.nextJobList}
        >
          {
            workerDetails && workerDetails.nextJobs && workerDetails.nextJobs.map((item, index) => {
              return (
                <MenuItem className={classes.menuItem} key={index}>
                  <div className={classes.jobTimeline}>
                    {
                      item.start ?
                        `${ moment( item.start).format(getTimeFormat())}
                        -
                        ${moment( item.end).format(getTimeFormat())}`

                        : translate('WorkerTracking:unscheduled')
                    }
                  </div>
                  <div className={classes.jobAddress}>
                    {
                      item.job.property &&
                      getAddress(item.job.property)
                    }
                  </div>
                </MenuItem>
              );
            })
          }

        </Menu>
      </div>
    );
  }
}

WorkerTrackingDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  getLocationDetails: (userId, options) => dispatch(getLocationDetails(userId, options)),
  sendSmsToStaffFunction: (staffId, message) => dispatch(sendSmsToStaff(staffId, message)),
  getVisits: (options) => dispatch(getVisits(options)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(WorkerTrackingDetails);
import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import styles from './styles/sendReport.module.scss';
import { formValueSelector, change } from 'redux-form';
import { sendToClientVia } from 'redux/job';
import { useDispatch, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import { SendReportForm } from '../forms';
import { SERVICE_REPORT_FORM_NAME, EMAIL_VALIDATION } from 'common/constant';
import sendIcon from 'assets/images/service-report/send.svg';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';


const SendReportModal = ({
  reportDetails,
  isOpenSendToClientModal,
  setIsOpenModal,
  clientEmail,
  isSaveAndSendAction,
  changeField,
  submitRemoteForm,
  phoneNumber,
  countryPhoneCode,
}) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation('Job');
  const defaultEmailTag = clientEmail ? [clientEmail] : [];
  const [emailTags, setTags] = useState(defaultEmailTag);
  const [checkboxState, setCheckboxState] = useState({
    sms: false,
    email: false,
  });

  const addTag = (tag) => {
    setTags(tag);
  };

  const handleChangeCheckbox = (e) => {
    const { value } = e.target;
    setCheckboxState({
      ...checkboxState,
      [value]: !checkboxState[value],
    });
  };

  const handleSubmit = async () => {
    // store to redux form, then call with submit form
    if (isSaveAndSendAction) {
      let callBack = {
        sendEmail: false,
        sendSms: false,
      };
      if (phoneNumber && countryPhoneCode)
        callBack = {
          ...callBack,
          sendSms: true,
          phoneNumbers: countryPhoneCode + phoneNumber,
        };
      if (emailTags.length)
        callBack = { ...callBack, sendEmail: true, emails: emailTags };

      await changeField('actionCallback', callBack);
      setIsOpenModal(false);
      return submitRemoteForm();
    }

    let payload = {
      reportId: reportDetails.id,
      action: 'SEND_TO_CLIENT',
    };

    if (phoneNumber && countryPhoneCode)
      payload = { ...payload, phoneNumbers: [countryPhoneCode + phoneNumber] };
    if (emailTags.length) payload = { ...payload, emails: emailTags };

    const result = await dispatch(sendToClientVia(payload)).catch((e) => ({
      e,
    }));
    if (result.e) return showErrorMessage(result.e);
    showSuccessMessage(translate('sendSuccess'));
    setIsOpenModal(false);
  };

  return (
    <Dialog
      classes={{ paper: styles.sendReportDialog }}
      open={isOpenSendToClientModal}
    >
      <Typography className={styles.title} variant="h4">
        {translate('sendReport')}
      </Typography>
      <DialogContent className={styles.dialogContent}>
        <Typography className={styles.title2}>
          {translate('reportMethod')}
        </Typography>
        <FormControlLabel
          style={{ width: '100%', marginBottom: 0 }}
          control={
            <Checkbox
              checked={checkboxState.sms}
              onChange={handleChangeCheckbox}
              value="sms"
              classes={{ root: styles.checkbox, checked: styles.checked }}
            />
          }
          label={translate('Common:sms')}
        />
        {checkboxState.sms && <SendReportForm translate={translate} />}

        <FormControlLabel
          style={{ width: '100%', marginBottom: 0 }}
          control={
            <Checkbox
              checked={checkboxState.email}
              onChange={handleChangeCheckbox}
              value="email"
              classes={{ root: styles.checkbox, checked: styles.checked }}
            />
          }
          label={translate('Common:email')}
        />
        {checkboxState.email && (
          <TagsInput
            inputProps={{
              placeholder: translate('emailInputPlaceHolder'),
            }}
            onlyUnique
            addKeys={[9, 13, 186]} // tab, enter, semicolon
            value={emailTags}
            validationRegex={EMAIL_VALIDATION}
            onChange={(value) => addTag(value)}
            addOnBlur
          />
        )}
      </DialogContent>
      <DialogActions disableSpacing className={styles.action}>
        <Button onClick={() => setIsOpenModal(false)} className="mr_10">
          {translate('Common:close')}
        </Button>
        <Button
          disabled={!emailTags.length && (!phoneNumber || !countryPhoneCode)}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          <img className="mr_10" src={sendIcon} alt="send_img" />
          {translate('Common:send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const selector = formValueSelector('sendReportForm');

const mapStateToProps = (state) => {
  const { countryPhoneCode, phoneNumber } = selector(
    state,
    'countryPhoneCode',
    'phoneNumber'
  );
  const { client } = state.job.job;
  const clientEmail = client && client.email;
  return {
    countryPhoneCode: countryPhoneCode || '',
    phoneNumber: phoneNumber || '',
    clientEmail: clientEmail || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => {
    dispatch(change(SERVICE_REPORT_FORM_NAME, field, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SendReportModal);

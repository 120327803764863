export default theme => ({
  root: {
    border: '1px solid lightGray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
  },
  isDragging: {
    backgroundColor: 'lightPink',
  },
  prodName: {
    wordBreak: 'break-word'
  }
});

import React from 'react';
import { Box, Button, Dialog, Grid, Typography, withStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { changeConfirmModal, getVisitSelected, mediaActions } from 'redux/media';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import { useTranslation } from 'react-i18next';

const styles = () => ({
  paddingItem: {
    paddingBottom: '12px',
    paddingTop: '12px'
  },
  borderBottom: {
    borderBottom: '1px solid #A2A2A2'
  },
  image: {
    width: '60px',
  },
  itemVisit: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    alignItems: 'start'
  },
  itemInMiddle: {
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center'
  }
});

const CheckVisitUpdateModal = ({ showConfirmModal, mediaList, classes, fetchMedia, fetchVisits }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visitSelected = useSelector(getVisitSelected);
  const getSelectedMediaList = () => {
    return mediaList.filter(media => media.isSelected);
  };

  const closeConfirmModal = () => {
    dispatch(changeConfirmModal(false));
  };

  const handleAssignVisit = async () => {
    const result = await dispatch(
      mediaActions({
        visitId: visitSelected?.id,
        action: 'ASSIGN_OR_REMOVE_MEDIA_TO_VISIT',
        mediaIds: getSelectedMediaList().map(media => media.id)
      })
    ).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    await fetchVisits();
    await fetchMedia();
    showSuccessMessage(t('Common:completed'));
    closeConfirmModal();
  };

  return (
    <Dialog
      open={showConfirmModal}
    >
      <Box
        p={4}
      >
        <Box pb={3}>
          <Typography variant='h2'>{t('Job:confirmVisitTitle')}</Typography>
        </Box>
        <Grid container pb={1}>
          <Grid xs={2} />
          <Grid
            item
            xs={4}
            className={`${classes.borderBottom} ${classes.itemVisit}`}
          >
            {t('Job:currentVisit')}
          </Grid>
          <Grid
            xs={3}
            className={classes.borderBottom}
          />
          <Grid
            item
            xs={3}
            className={`${classes.borderBottom} ${classes.itemVisit}`}
          >
            {t('Job:newVisit')}
          </Grid>
          {getSelectedMediaList().map((media) => {
            return (
              <Grid
                container
                key={media.id}
                xs={12}
                direction="row"
              >
                <Grid
                  xs={2}
                  alignItems="center"
                  justifyContent="center"
                  className={`${classes.paddingItem}`}
                >
                  <img
                    className={classes.image}
                    alt="note_image"
                    src={media.mediaUrl}
                  />
                </Grid>
                <Grid
                  xs={4}
                  className={`${classes.borderBottom} ${classes.itemVisit} ${classes.paddingItem}`}
                >
                  <Box>
                    {media.visit?.publicId }
                  </Box>
                  <Box>
                    {media.visit?.title }
                  </Box>
                </Grid>
                <Grid
                  xs={3}
                  className={`${classes.borderBottom} ${classes.itemInMiddle} ${classes.paddingItem}`}
                >
                  <ArrowForwardIcon style={{ color:'#A2A2A2' }} />
                </Grid>
                <Grid
                  className={`${classes.borderBottom} ${classes.itemVisit} ${classes.paddingItem}`}
                  xs={3}
                >
                  <Box>{visitSelected?.publicId}</Box>
                  <Box>{visitSelected?.title}</Box>
                </Grid>
              </Grid>
            );

          })}
        </Grid>
        <Box mt={4} justifyContent='flex-end' display='flex'>
          <Button
            onClick={closeConfirmModal}
            variant='outlined'
            style={{
              marginRight: '12px'
            }}
          >
            {t('Common:close')}
          </Button>
          <Button
            variant='contained'
            className='save_btn'
            onClick={handleAssignVisit}
          >
            {t('Common:confirm')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default compose(withStyles(styles))(CheckVisitUpdateModal);
import React from 'react';

// Externals
import classNames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';

// Material components
import {
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';

// Component styles
import { DetailsTableStyles } from './styles';
import { StatusChip } from 'pages/Dashboard/components';
import { getDateTimeFormat } from 'lib/formatter';

const DetailsRow = props => {
  const { classes, title, details, jobIds, detailsClasses } = props;

  return (
    <Grid container className={classes.itemSpace} item direction='column'>
      <Grid item>
        <Typography variant='h5' className={classes.titleHeader}>{title}</Typography>
      </Grid>
      <Grid item>
        {
          !jobIds ?
            <Typography className={detailsClasses}>{details}</Typography>
            : <Grid container direction='row'>
              {
                jobIds.map((jobId, index) =>
                  <Link to={`/jobs/${jobId}`} key={index} style={{ paddingRight: 5 }}>
                    <Typography className={classes.jobId}>
                      {jobId}
                      {index === jobIds.length - 1 ? '' : ','}
                    </Typography>
                  </Link>
                )
              }
            </Grid>
        }
      </Grid>
    </Grid>
  );
};

export default withStyles(DetailsTableStyles)((props) => {
  const {
    archived,
    classes,
    quoteExpiryDate,
    quoteIssueDate,
    quoteNumber,
    quoteStatus,
    jobIds,
    translate
  } = props;

  const detailsClassName = classNames(classes.textDetails, classes.textDetailsBold);
  const getIssuedDate = () => quoteIssueDate ? moment(quoteIssueDate).format(getDateTimeFormat()) : translate('notSentYet');
  const getExpiryDate = () => quoteExpiryDate ? moment(quoteExpiryDate).format(getDateTimeFormat()) : translate('notSetYet');
  const getJobIds = () => jobIds.length > 1 ? `${jobIds.length} ${translate('jobs')}` : translate('oneJob');

  return (
    <>
      <Grid container justify='space-between'>
        <Typography variant='h3' className={classes.quoteDetailsTitle}>{translate('quoteDetails')}</Typography>
        <StatusChip quote status={quoteStatus} archived={archived} />
      </Grid>
      <Grid container direction='row'>
        {
          !!quoteNumber &&
            <DetailsRow
              classes={classes}
              title={translate('quoteNumber')}
              details={quoteNumber}
              detailsClasses={detailsClassName}
            />
        }
        {
          !!jobIds &&
            <DetailsRow
              classes={classes}
              jobIds={jobIds}
              title={getJobIds()}
            />
        }
        {
          !!quoteIssueDate &&
            <DetailsRow
              classes={classes}
              title={translate('issuedAt')}
              details={getIssuedDate()}
              detailsClasses={detailsClassName}
            />
        }
        {
          !!quoteExpiryDate &&
            <DetailsRow
              classes={classes}
              title={translate('expireAt')}
              details={getExpiryDate()}
              detailsClasses={detailsClassName}
            />
        }
      </Grid>
    </>
  );
});
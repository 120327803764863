import React from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import styles from './styles.module.scss';
import { connect, useDispatch } from 'react-redux';
import { submit, reduxForm } from 'redux-form';
import { VisitTimeStepperFields } from 'pages/Dashboard/components';

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

const FORM_NAME = 'VisitTimeAdjustmentForm';

const EditVisitModal = (props) => {
  const { translate, handleSubmit, setShowAddModal, visitData } = props;

  const dispatch = useDispatch();

  const handleSubmitForm = () => {
    dispatch(submit(FORM_NAME));
  };

  return (
    <Dialog classes={{ paper: styles.defaultWidth }} maxWidth="lg" open>
      <DialogTitle disableTypography className={styles.titleWrapper}>
        <Grid container alignItems="center">
          <Button onClick={() => setShowAddModal(false)}>
            <ArrowBackIcon />
          </Button>
          <Typography className={styles.title} variant="h6">
            {translate('Job:changeProgressTimestamp')}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent className={styles.container}>
        <form onSubmit={handleSubmit}>
          <VisitTimeStepperFields
            translate={translate}
            defaultVisit={visitData}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          className={styles.submitBtn}
          onClick={handleSubmitForm}
          variant="contained"
          color="primary"
        >
          {translate('Timesheet:saveUpperCase')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  const visitData = ownProps.visitData || {};
  const {
    onTheWayAt,
    arrivedAt,
    startedAt,
    completedAt,
    cancelledAt,
  } = visitData;

  return {
    initialValues: {
      ontheway_visitDate: onTheWayAt,
      ontheway_visitTime: onTheWayAt,
      arrived_visitTime: arrivedAt,
      arrived_visitDate: arrivedAt,
      start_visitTime: startedAt,
      start_visitDate: startedAt,
      completed_visitDate: completedAt,
      completed_visitTime: completedAt,
      cancelled_visitDate: cancelledAt,
      cancelled_visitTime: cancelledAt,
    },
  };
};
export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: false,
    destroyOnUnmount: true,
  })(EditVisitModal)
);

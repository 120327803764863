import React from "react";
import {
  Grid,
  Stepper,
  StepLabel,
  StepConnector,
  Step,
} from "@material-ui/core";
import styles from "./styles.module.scss";
import { Field } from "redux-form";
import { DatePicker, TimePicker } from "components/inputs";

const VisitTimeStepperFields = (props) => {
  const { translate, defaultVisit } = props;
  const steps = ["ontheway", "arrived", "start", "completed", "cancelled"];
  return (
    <Stepper
      classes={{ root: styles.stepper }}
      className={styles.stepperLimit}
      connector={<StepConnector classes={{ root: styles.connectorStyles }} />}
      orientation="vertical"
    >
      {steps.map((label, index) => {
        const labelProps = {};
        labelProps.optional = (
          <Grid container justify="flex-end" wrap="nowrap" className="ml_10">
            <Grid className={styles.visitTimeEdit} item>
              <Field
                name={`${label}_visitTime`}
                component={TimePicker}
                checkOnBlur={false}
                value=""
                minDate={null}
                disabled={label === "cancelled" && defaultVisit.completedAt}
              />
            </Grid>
            <Grid item>
              <Field
                name={`${label}_visitDate`}
                component={DatePicker}
                minDate={null}
                disabled={label === "cancelled" && defaultVisit.completedAt}
              />
            </Grid>
          </Grid>
        );

        return (
          <Step key={index}>
            <StepLabel
              className={styles.labelStepper}
              classes={{
                labelContainer: styles.step_modified_container,
                label: styles.labelText,
              }}
              StepIconProps={{
                classes: { root: styles.icon, text: styles.iconText },
              }}
              {...labelProps}
            >
              {translate(`Timesheet:${label}`)}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default VisitTimeStepperFields;

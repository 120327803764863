import React, { forwardRef } from 'react';

// Externals
import classNames from 'classnames';

// Material components
import {
  withStyles,
  Grid,
  Button,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  Divider,
} from '@material-ui/core';

import { KeyboardArrowDown as DownArrow } from '@material-ui/icons';
import checkIcon from 'assets/images/service-report/checkmark.svg';
import { useTranslation } from 'react-i18next';

// Component styles
const styles = (theme) => ({
  moreIcon: {
    marginRight: theme.spacing(1),
  },
  sectionTitle: {
    color: theme.palette.text.primary,
    opacity: 0.7,
    paddingLeft: 4,
    paddingTop: 4,
    paddingBottom: 4,
  },
  popoverWithArrow: {
    '& .MuiPopover-paper': {
      padding: '16px 20px',
      minWidth: 180,
      marginTop: 10,
      overflowX: 'initial',
      overflowY: 'initial',
      borderColor: '#e2e2e2',
      borderWidth: 1,
      borderStyle: 'solid',
      boxShadow: '0px 2px 3px 0px rgb(0 0 0 / 20%)',
      '&:before': {
        content: '""',
        position: 'absolute',
        right: 10,
        top: -9,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 8px 8px 8px',
        borderColor: 'transparent',
        zIndex: 9998,
      },
    },
  },
  btnStyle: {
    minWidth: 100,
    paddingLeft: 8,
    paddingRight: 0,
    borderRadius: 4,
  },
  padding: {
    paddingLeft: 16,
  },
  descriptionStyle: {
    maxWidth: 200,
    whiteSpace: 'normal',
    color: '#666666',
  },
});

const MenuSection = withStyles(styles)(
  forwardRef((props, ref) => {
    const {
      classes,
      handleClose,
      lastIndex,
      sectionItem,
      selected,
      reportType,
    } = props;
    return (
      <MenuList innerRef={ref}>
        {sectionItem &&
          !!sectionItem.menuItems &&
          sectionItem.menuItems.map((item) => (
            <MenuItem
              key={item.label}
              disabled={
                item.disabled || (!!selected && item.id && selected === item.id)
              }
              onClick={() => {
                item.action();
                handleClose();
              }}
              className={item.padding ? classes.padding : null}
            >
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item container direction="column">
                  <Typography variant="h5" className="pt_10 pb_5">
                    {item.label}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.descriptionStyle}
                  >
                    {item.description}
                  </Typography>
                </Grid>
                {reportType.toLowerCase() === item.label.toLowerCase() && (
                  <Grid item className="ml_10">
                    <img src={checkIcon} alt="checkIcon" />
                  </Grid>
                )}
              </Grid>
            </MenuItem>
          ))}
        {!lastIndex && <Divider variant="fullWidth" />}
      </MenuList>
    );
  })
);

const PopupMenu = (props) => {
  const {
    classes,
    id,
    status,
    handleClickStatus,
    menuData,
    selected,
    textStyle,
    btnCustomizeStyle,
    reportType,
  } = props;
  const { t: translate } = useTranslation('Job');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        aria-controls={id}
        aria-haspopup="true"
        color="secondary"
        onClick={handleClick}
        style={textStyle}
        className={`${classes.btnStyle} ${btnCustomizeStyle}`}
      >
        <Grid container direction="row" justify="space-between">
          <Typography variant="h6" color="inherit">
            {translate('reportType')}
            {reportType}
          </Typography>{' '}
          <DownArrow />
        </Grid>
      </Button>
      <Menu
        id={id}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        className={classNames(classes.arrow, classes.popoverWithArrow)}
      >
        {menuData &&
          menuData.length > 0 &&
          menuData.map((section, index) => (
            <MenuSection
              handleClickStatus={handleClickStatus}
              handleClose={handleClose}
              lastIndex={index + 1 === menuData.length}
              itemStatus={status}
              key={index}
              sectionItem={section}
              selected={selected}
              reportType={reportType}
            />
          ))}
      </Menu>
    </>
  );
};

export default withStyles(styles)(PopupMenu);

import { white } from 'common/colors';

export default theme => ({
  root: {},
  cardHeader: {
    backgroundColor: theme.palette.background.muted2
  },
  secondaryHeader: {
    maxHeight: 45,
  },
  productNameDesc: {
    margin: 0,
  },
  assignButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  teamChip: {
    margin: 2,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.muted2,
  },
  noneAssignText: {
    fontWeight: 400,
  },
  totalPrice: {
    textAlign: 'right',
  },
  totalPriceText: {
    fontWeight: 600,
    fontSize: 16,
  },
  cardFooter: {
    backgroundColor: theme.palette.background.muted2
  },
  alignItems: {
    alignContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.border}`,
    height: 50
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    position: 'relative',

    '& .MuiOutlinedInput-root': {
      backgroundColor: white
    },

    '& .MuiOutlinedInput-input': {
      padding: 10,
    },

    '& .MuiFormHelperText-root.Mui-error': {
      top: '100%',
      position: 'absolute',
      marginTop: '2px',
      lineHeight: '16px'
    }
  },
  selectField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    '& .MuiOutlinedInput-input': {
      padding: '10px 30px 10px 10px',
    }
  },
  alignItemsCenter: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
});
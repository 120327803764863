import palette from './palette';

export default {
  fontFamily: [
    // 'DM Sans',
    // 'Open Sans',
    // 'Nunito Sans',
    // 'Roboto',
    // '"Helvetica Neue"',
    // 'Arial',
    'DM Sans'
  ].join(','),
  h1: {
    color: palette.text.primary,
    fontWeight: '700',
    fontSize: '35px',
    letterSpacing: '-0.24px',
    lineHeight: '40px',
    fontFamily: 'DM Sans'
  },
  h2: {
    color: palette.text.primary,
    fontWeight: '700',
    fontSize: '29px',
    letterSpacing: '-0.24px',
    lineHeight: '32px',
    fontFamily: 'DM Sans'
  },
  h3: {
    color: palette.text.primary,
    fontWeight: '600',
    fontSize: '24px',
    letterSpacing: '-0.06px',
    lineHeight: '28px',
    fontFamily: 'DM Sans'
  },
  h4: {
    color: palette.text.primary,
    fontWeight: '600',
    fontSize: '20px',
    // fontWeight: '800',
    // fontSize: 30,
    letterSpacing: '-0.06px',
    lineHeight: '24px',
    fontFamily: 'DM Sans'
  },
  h5: {
    color: palette.text.primary,
    fontWeight: '600',
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
    fontFamily: 'DM Sans'
  },
  h6: {
    color: palette.text.primary,
    fontWeight: '600',
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
    fontFamily: 'DM Sans'
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '12px',
    letterSpacing: '-0.05px',
    lineHeight: '16px'
  },
  subtitle2: {
    color: palette.text.primary,
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '16px'
  },
  body1: {
    color: palette.text.primary,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px'
  },
  body2: {
    color: palette.text.primary,
    fontSize: '12px',
    letterSpacing: '-0.04px',
    lineHeight: '14px'
  },
  button: {
    color: palette.text.primary,
    fontSize: '14px'
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '12px',
    letterSpacing: '0.3px',
    lineHeight: '16px'
  }
};

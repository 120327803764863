import React from 'react';
import MaterialTable from 'material-table';
import { showErrorMessage } from 'lib/notifier';
import { useDispatch } from 'react-redux';
// Shared components
import { Typography, Grid, Tooltip, Chip, Box } from '@material-ui/core';
import { formatDate, getDateTimeFormat } from 'lib/formatter';
import styles from './jobTable.module.scss';
import { getEnterpriseJobList } from 'redux/enterprise';
import moment from 'moment';

import { FolderOpen as FolderOpenIcon } from '@material-ui/icons';
import { CollectedIndicator } from 'pages/Dashboard/components/Indicator';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { convertDateInParams } from 'pages/Dashboard/views/Jobs/JobList/components/JobsTable/utils';
import { JOB_STATUS_ENTERPRISE_DASHBOARD, QUERY_STRING_DECODE } from 'common/constant';
import { EnterpriseStatusChip } from 'pages/Dashboard/components/EnterpriseStatuschip';

const options = {
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  search: true,
  debounceInterval: 1000,
};

const renderFields = ({ translate }) => [
  {
    field: 'status',
    filtering: false,
    sorting: false,
    cellStyle: { maxWidth: 100, width: '6%' },
    render: (rowData) => {
      return (
        <Box justifyContent="center" display="flex" alignItems="center">
          {rowData.isCollected && <CollectedIndicator />}
        </Box>
      );
    },
  },
  {
    field: 'status',
    title: translate('status'),
    filtering: false,
    sorting: false,
    render: (rowData) => {
      return (
        <EnterpriseStatusChip
          groupStatusFilter={JOB_STATUS_ENTERPRISE_DASHBOARD[rowData.statusId]}
          archived={rowData.archived}
        />
      );
    },
  },
  {
    field: 'idCategory',
    title: translate('Job:id_category'),
    filtering: false,
    sorting: false,
    render: (rowData) => {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}> {rowData.publicId} </Grid>
          {rowData.orderId && <Grid item xs={12}>{rowData.orderId}</Grid>}
          {rowData.categories && rowData.categories.length > 0 && (
            <Grid item container direction="row" alignItems="center">
              <Box mr={1}>
                <FolderOpenIcon />
              </Box>
              <Typography>{rowData.categories[0].name}</Typography>
              {rowData.categories.length > 1 && (
                <Tooltip
                  title={`${translate(
                    'Category:Category'
                  )}: ${rowData.categories.map((cat) => cat.name).join(', ')}`}
                >
                  <Chip
                    label={`+ ${rowData.categories.length - 1}`}
                    size="small"
                    className={styles.categoryChip}
                  />
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
      );
    },
  },
  {
    field: 'items',
    title: translate('Job:items'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      const services = Array.isArray(rowData.items) && [...rowData.items];
      const items = [...rowData.jobInventories];
      return (
        <Grid container>
          {
            services && (
              <Grid item container className="mb_5">
                <Typography variant="h6">
                  {services.map((item) => item.name).join(', ')}
                </Typography>
              </Grid>
            )
          }
          <Grid item>
            {
              items.splice(0, 3)
                .map((item) => item.name)
                .join(', ')
            }
            {
              items.length > 0 && (
                <Tooltip
                  title={items.map((item) => item.name).join(', ')}
                >
                  <Chip
                    label={`+ ${items.length}`}
                    size="small"
                    className={styles.categoryChip}
                  />
                </Tooltip>
              )
            }
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: 'client',
    filtering: false,
    sorting: false,
    title: translate('client'),
    render: (rowData) => {
      const { client: { displayName, phoneNumber } = {} } = rowData;
      return (
        <Grid>
          <Typography>{displayName}</Typography>
          {phoneNumber && <Typography>{phoneNumber}</Typography>}
        </Grid>
      );
    },
  },
  {
    field: 'address',
    title: translate('job_address'),
    filtering: false,
    sorting: false,
    render: (rowData) => {
      if (!rowData.property) return null;
      return (
        <Grid>
          <Typography style={{ fontWeight: 'bold' }}>
            {rowData.property.name}
          </Typography>
          {[rowData.property.unitNumber, rowData.property.address]
            .filter(Boolean)
            .join(', ')}
        </Grid>
      );
    },
  },
  {
    field: 'schedule',
    title: translate('schedule'),
    filtering: false,
    sorting: false,
    render: (rowData) => {
      return (
        <Grid>
          <Typography>
            {rowData.jobSchedule &&
              formatDate(
                rowData.jobSchedule.start,
                getDateTimeFormat()
              )}
          </Typography>
        </Grid>
      );
    },
  },
  {
    field: 'hub',
    title: translate('Hub:hub'),
    filtering: false,
    sorting: false,
    render: (rowData) => {
      if (!rowData.hub) return null;
      return (
        <>
          <Grid container className="mb_5">
            <Typography variant="h6">{rowData.hub.name}</Typography>
          </Grid>
          <Grid container>
            <Typography>{rowData.hub.address}</Typography>
          </Grid>
        </>
      );
    },
  },
  {
    field: 'createdAt',
    title: translate('createdAt/By'),
    filtering: false,
    sorting: false,
    render: (rowData) => {
      return (
        <Grid>
          <Typography>
            {rowData.createdAt &&
              formatDate(rowData.createdAt, getDateTimeFormat())}
          </Typography>
        </Grid>
      );
    },
  },
];

const Table = ({ tableRef, translate, isLoading }) => {
  const columns = renderFields({ translate });
  const dispatch = useDispatch();
  const location = useLocation();

  const handleRowClick = (e, rowData) => window.open(`/enterprise-jobs/${rowData.id}`, '_blank');

  const onGetSearchParams = () => {
    const getSearchParams = location.search;
    const parseSearch = queryString.parse(getSearchParams, QUERY_STRING_DECODE);
    return { ...parseSearch };
  };

  const fetchJobList = async (query) => {
    if (isLoading) return Promise.resolve({
      data: [],
      page: 0,
      totalCount: 0,
    });

    const { page, search } = query;

    const scheduledOn = query.scheduledOn || [];

    const convertParams = {
      limit: query.pageSize,
      skip: page * query.pageSize,
      statusIds: (query.status && query.status !== 'ALL' &&
        (Array.isArray(query.status) ? query.status.map(item => item.value) : query.status)) || '',
      createdOnFrom: query.createdOn && query.createdOn[0] && moment(query.createdOn[0]).startOf('day'),
      createdOnTo: query.createdOn && query.createdOn[1] && moment(query.createdOn[1]).endOf('day'),
      scheduledOnFrom: scheduledOn[0] && moment(scheduledOn[0]).startOf('day'),
      scheduledOnTo: scheduledOn[1] && moment(scheduledOn[1]).endOf('day'),
      categoryIds: query.categoryIds,
      isItemsCollected:
        Array.isArray(query.isCollectedItems) &&
        query.isCollectedItems.reduce((prev, current) => {
          if (!current.checked) return prev;
          return [...prev, current.value];
        }, []),
      ...(search ? { $q: search } : {}),
    };
    const params = convertDateInParams({ ...convertParams, ...onGetSearchParams() });

    const response = await dispatch(getEnterpriseJobList({ params })).catch((e) => ({ e }));
    if (response.e) {
      showErrorMessage(response.e);
      return Promise.reject(response.e);
    }
    return Promise.resolve({
      data: response.data,
      page,
      totalCount: response.total,
    });
  };

  return (
    <MaterialTable
      title={null}
      columns={columns}
      options={options}
      tableRef={tableRef}
      data={query => fetchJobList({ ...query })}
      onRowClick={handleRowClick}
    />
  );
};

export default Table;

import React, { useEffect } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormSyncErrors, getFormMeta, formValueSelector } from 'redux-form';
import { makeAdmin, clearStaff, getStaffs } from 'redux/staff';

// Material components
import { Button, Grid, FormControlLabel, Checkbox, withStyles, Typography } from '@material-ui/core';

// Customised components
import { PermissionsForm } from '../../forms';

// Customised components
import { TextInput } from 'components/inputs';
import { validate } from '../../utils';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'pages/Dashboard/components';

import { showErrorMessage } from 'lib/notifier';

// Component styles
import styles from './styles';

const staffForm = formValueSelector('staffForm');

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  roleId: staffForm(state, 'roleId'),
  staffs: state.staff.staffs,
  roleError: getFormSyncErrors('staffForm')(state),
  fields: getFormMeta('staffForm')(state),
});

const mapDispatchToProps = dispatch => ({
  makeAdmin: () => { dispatch(makeAdmin()); },
  clearStaff: () => { dispatch(clearStaff()); },
  getStaffs: (params) => dispatch(getStaffs(params)),
});

let PermissionDetails = props => {
  const {
    classes,
    className,
    currentRoute,
    fields,
    invalid,
    isModal = false,
    pristine,
    roleError,
    currentUser,
    staffId,
    roleId,
    staffs,
    submitting,
    translate
  } = props;
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isOwner, setIsOwner] = React.useState(false);
  const [reportingLine, setReportingLine] = React.useState([]);
  const [staffList, setStaffList] = React.useState([]);

  const getReportingLine = staffRole => {
    setReportingLine([]);

    const setLine = roles => {
      const assignableStaffs = [];
      staffList.forEach(staff => {
        if (roles.includes(staff.roleId)) {
          assignableStaffs.push(staff);
        }
      });
      setReportingLine([...reportingLine, ...assignableStaffs]);
    };

    let allowedRoles = [];
    switch (staffRole) {
      case 'WORKER':
        allowedRoles = ['SUPERVISOR', 'SCHEDULER', 'MANAGER', 'ADMIN', 'OWNER'];
        setLine(allowedRoles);
        break;

      case 'SUPERVISOR':
        allowedRoles = ['SCHEDULER', 'MANAGER', 'ADMIN', 'OWNER'];
        setLine(allowedRoles);
        break;

      case 'SCHEDULER':
        allowedRoles = ['MANAGER', 'ADMIN', 'OWNER'];
        setLine(allowedRoles);
        break;

      case 'MANAGER':
        allowedRoles = ['ADMIN', 'OWNER'];
        setLine(allowedRoles);
        break;

      case 'ADMIN':
        allowedRoles = ['OWNER'];
        setLine(allowedRoles);
        break;

      case 'OWNER':
        allowedRoles = [];
        setLine(allowedRoles);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    async function fetchStaffs() {
      try {
        // eslint-disable-next-line max-len
        const res = await props.getStaffs({ $scope: currentUser.id !== staffId ? 'STAFF_MANAGEMENT|ASSIGN_REPORT' : 'STAFF_MANAGEMENT|VIEW' });
        if (res.status === 200) {
          setStaffList(res.data.data);
          staffList.length > 0 && getReportingLine(roleId);
        }
      } catch (error) {
        showErrorMessage(error);
      }
    }

    fetchStaffs();

    return () => props.clearStaff();
  }, []);

  useEffect(() => {
    getReportingLine(roleId);

    // Check for Owner
    if (roleId === 'OWNER') {
      setIsOwner(true);
    };

    // Check for Administrator
    if (roleId === 'ADMIN') {
      setIsAdmin(true);
    };
  }, [staffList, roleId]);

  const handleOnChange = () => {
    setIsAdmin(!isAdmin);
    props.makeAdmin();
  };

  const rootClassName = classNames(classes.root, className);
  const isTouched = fields && fields.roleId && fields.roleId.touched;
  const hasError = roleError && !!roleError.roleId;
  const hasStaffs = staffs && ((typeof staffs === 'object' && Object.keys(staffs).length) || staffs.length);

  return (
    <Portlet
      className={rootClassName}
    >
      <PortletHeader>
        <PortletLabel
          subtitle={translate('permissionLevel')}
          title={translate('roles')}
        />
        {
          !isOwner &&
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isAdmin}
                  onChange={handleOnChange}
                  value={isAdmin}
                />
              )}
              label={translate('makeAdmin')}
            />
        }
      </PortletHeader>
      <PortletContent noPadding={(!isAdmin && isOwner) && (!isOwner && isAdmin)}>
        {
          isAdmin || isOwner ?
            <Typography>
              {
                isAdmin ? translate('adminPermissions') : translate('ownerPermissions')
              }
            </Typography>
            : <>
              {
                isTouched && hasError &&
                <PortletHeader style={{ marginBottom: 8 }}>
                  <PortletLabel
                    subtitle={roleError && roleError.roleId}
                    subtitleStyle={{ color: 'red' }}
                  />
                </PortletHeader>
              }
              <Grid container spacing={2}>
                <PermissionsForm roleId={roleId} translate={translate} />
              </Grid>
            </>
        }
      </PortletContent>
      <PortletFooter className={classes.portletFooter}>
        <Grid container direction='row'>
          <Grid item md={6} sm={12}>
            {
              (!isAdmin || !isOwner) && hasStaffs &&
                <Grid container direction='column' alignContent='flex-start' justify='flex-start'>
                  <Grid item md={6} xs={12}>
                    <Typography variant='h6'>
                      {translate('assignTo')}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {reportingLine.length > 0 ?
                      <Field
                        key='reportingToWithReporting'
                        name="reportingTo"
                        component={TextInput}
                        label={translate('reporting')}
                        margin='dense'
                        select
                        SelectProps={{ native: true }}
                        variant='outlined'
                      >
                        <option key='' value=''>
                          {' '}
                        </option>
                        { hasStaffs &&
                        reportingLine.map((option, i) => {
                          return <option key={i} value={option.id}>
                            {
                              [option.firstName, option.lastName].filter(e => e).join(' ')
                            }
                          </option>;
                        })}
                      </Field>
                      :
                      <Field
                        key="reportingToWithUnReporting"
                        name="reportingTo"
                        component={TextInput}
                        label={translate('reporting')}
                        margin='dense'
                        select
                        SelectProps={{ native: true }}
                        variant='outlined'
                      >
                        <option key='' value=''>
                          {' '}
                        </option>
                      </Field>
                    }
                  </Grid>
                </Grid>
            }
          </Grid>
          {
            !isModal &&
              <Grid item container md={6} sm={12} alignContent='flex-end' justify='flex-end'>
                <Link to='/teams'>
                  <Button color='secondary' onClick={() => props.clearStaff()}>{translate('Common:cancel')}</Button>
                </Link>
                <Button
                  className={classes.saveButton}
                  disabled={pristine || invalid || submitting}
                  type='submit'
                  color='primary'
                  variant='contained'
                >
                  { currentRoute === '/teams/new' ? translate('addNewStaff') : translate('saveDetails') }
                </Button>
              </Grid>
          }
        </Grid>
      </PortletFooter>
    </Portlet>
  );
};

PermissionDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

PermissionDetails = reduxForm({ form: 'staffForm', validate, destroyOnUnmount: true })(PermissionDetails);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(PermissionDetails);

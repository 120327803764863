import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

// Material components
import { Grid } from '@material-ui/core';
import { TextInput, SelectBox } from 'components/inputs';
import Countries from 'lib/countryList';

const mapStateToProps = (state) => {
  const { client } = state.job.job;
  const clientPhoneNumber = client && client.phoneNumber;

  let clientCountryPhoneCode;
  let clientNumber;
  if (clientPhoneNumber) {
    clientCountryPhoneCode = new Countries().getCountryPhoneCodeByPhoneNumber(
      clientPhoneNumber
    );
    clientNumber = clientPhoneNumber.replace(clientCountryPhoneCode, '');
  }

  const countryPhoneCode =
    clientCountryPhoneCode ||
    new Countries().getCountryPhoneCodeByCountryCode(
      (state.auth.currentUser.companies[0] || {}).countryCode
    ) ||
    (state.auth.currentUser.companies[0] || {}).countryPhoneCode ||
    state.auth.currentUser.countryPhoneCode ||
    null;

  return {
    initialValues: {
      phoneNumber: clientNumber || '',
      countryPhoneCode,
    },
  };
};

const countryPhoneCodeOptions = new Countries().countryPhoneCodes();

const SendReportForm = ({ translate }) => {
  return (
    <form>
      <Grid container direction="row" spacing={1} alignItems="center">
        <Grid item md={5} sm={12} className="mr_10 ml_10">
          <Field
            name="countryPhoneCode"
            margin="dense"
            component={SelectBox}
            variant="outlined"
            label={translate('Common:countryCode')}
            options={countryPhoneCodeOptions}
          />
        </Grid>
        <Grid item md={5} sm={12}>
          <Field
            component={TextInput}
            name="phoneNumber"
            type="number"
            margin="dense"
            variant="outlined"
            label={translate('Common:phoneNumber')}
            required
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm({ form: 'sendReportForm' })
)(SendReportForm);

import React from 'react';
import VisitFormComponent from './components/form';
import { Box, Grid, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { addVisit, updateVisit } from 'redux/visit';
import { IsValidDateTime } from 'components/validations';
import VisitFormValidation from './validate';
import './index.scss';
import { showErrorMessage } from 'lib/notifier';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { moment } from 'lib/app-moment';

class VisitForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.prepareVisitData = this.prepareVisitData.bind(this);
  }

  async handleSubmit(values) {
    const { translate } = this.props;
    const errors = VisitFormValidation(values);

    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message)
          .map((msg) => translate(`Error:${msg}`))
          .join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors)
          .map((msg) => translate(`Error:${msg}`))
          .join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));
      throw new SubmissionError(errors);
    }

    const {
      addVisit,
      updateVisit,
      afterSavedVisit,
      handleCloseVisitForm,
    } = this.props;
    values = this.prepareVisitData(values);
    try {
      const response =
        values.virtual || !values.id
          ? await addVisit(values)
          : await updateVisit(values);
      if (response.status === 200) {
        handleCloseVisitForm();
        const { data } = response.data;
        afterSavedVisit(data);
      } else {
        showErrorMessage(response.data.message);
      }
    } catch (error) {
      showErrorMessage(error);
      this.setState({ isSubmitting: false, anchorStatusMenuEl: null });
    }
  }

  prepareVisitData(visitDetails) {
    const newVisitData = Object.assign({}, visitDetails);
    if (visitDetails.team) {
      let selectedStaffs = Object.keys(visitDetails.team).filter(
        (item) => visitDetails.team[item]
      );
      selectedStaffs = this.props.staffs.filter((staff) =>
        selectedStaffs.includes(staff.id)
      );
      newVisitData.team =
        (selectedStaffs &&
          selectedStaffs.map((item) => item && { id: item.id })) ||
        [];
    }

    if (
      IsValidDateTime(newVisitData.startDate) &&
      IsValidDateTime(newVisitData.startTime)
    ) {
      const startDate = moment(newVisitData.startDate);
      const startTime = moment(newVisitData.startTime);
      const startTimeHour = startTime.hour();
      const startTimeMinute = startTime.minute();
      startDate.hour(startTimeHour).minute(startTimeMinute);
      newVisitData.start = startDate;
    } else {
      newVisitData.start = null;
    }

    if (
      IsValidDateTime(newVisitData.endDate) &&
      IsValidDateTime(newVisitData.endTime)
    ) {
      const endDate = moment(newVisitData.endDate);
      const endTime = moment(newVisitData.endTime);
      const endTimeHour = endTime.hour();
      const endTimeMinute = endTime.minute();
      endDate.hour(endTimeHour).minute(endTimeMinute);
      newVisitData.end = endDate;
    } else {
      newVisitData.end = null;
    }

    delete newVisitData['startDate'];
    delete newVisitData['endDate'];
    delete newVisitData['startTime'];
    delete newVisitData['endTime'];

    return newVisitData;
  }

  render() {
    const { visitDetails, handleCloseVisitForm, translate } = this.props;

    return (
      <Grid className="visit-details-form">
        <Box p={3}>
          <Grid container justify="space-between">
            <Typography variant="h4" className="modal_title">{translate('Job:scheduleVisit')}</Typography>
            <Close className="close-icon" onClick={handleCloseVisitForm} />
          </Grid>

          <VisitFormComponent
            visitDetails={visitDetails}
            onSubmit={this.handleSubmit}
            handleCloseVisitForm={handleCloseVisitForm}
            translate={translate}
          />
        </Box>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addVisit: (visitData) => dispatch(addVisit(visitData)),
  updateVisit: (visitData) => dispatch(updateVisit(visitData)),
});

const mapStateToProps = (state) => ({
  staffs: state.staff.staffs,
});

export default compose(
  withTranslation('Job'),
  connect(mapStateToProps, mapDispatchToProps)
)(VisitForm);

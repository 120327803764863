import React from 'react';
import styles from '../tabs/tabItem.module.scss';
import { Typography, Box } from '@material-ui/core';

const RenderField = ({ input, label, isBoldText }) => {
  if (!input.value) return null;
  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography className={styles.formTitle}>{label}</Typography>
      <Typography
        variant="h5"
        className={isBoldText ? styles.boldText : styles.normalText}
      >
        {input.value}
      </Typography>
    </Box>
  );
};

export default RenderField;

export default theme => ({
  root: {},
  header: {},
  quoteClientDetail:{},
  quoteDetail: {
    borderRadius: 6,
    backgroundColor: 'white',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  quoteTitle: {
    fontWeight: 600,
    fontStyle: 'italic',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  quoteHeader: {
    marginBottom: 20,
  },
  borderContainer: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '16px'
  },
  containerStyle: {
    padding: theme.spacing(3)
  },
  quoteDetailsTitle: {
    fontWeight: 600,
    marginBottom: 10,
  },
  itemSpacing: {
    marginRight: 20
  },
  titleColor: {
    color: theme.palette.text.gray,
    fontWeight: 500
  },
  clientName: {
    color: '#305BF1'
  },
  emailContainer: {
    marginBottom: 8
  }
});

import React from 'react';
import { Typography, Button } from '@material-ui/core';
import styles from '../../components.module.scss';
import CopySrc from 'assets/images/dashboard/copy.svg';
import { showSuccessMessage } from 'lib/notifier';

const ButtonCopy = ({ translate, jobId, address }) => {
  const copyItem = address || jobId;
  const handleCopy = () => {
    navigator.clipboard.writeText(copyItem);
    showSuccessMessage(address ? translate('Common:textCopied') : translate('Dashboard:textCopied'));
  };
  return (
    <Button
      onClick={handleCopy}
      className={address ? styles.btn_copy_address : styles.btnCopy}
    >
      <img src={CopySrc} alt="CopySrc" />
      <Typography>{translate('copy')}</Typography>
    </Button>
  );
};

export default ButtonCopy;

import React, { useState } from 'react';
import { Button, MenuItem, Menu, Grid } from '@material-ui/core';
import styles from './media.module.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const DownloadMenu = ({ t, handleDownloadAll, handleEnableSelectFile, isLoading = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onClickAllFiles = () => {
    handleDownloadAll && handleDownloadAll();
    handleClose();
  };

  const onClickSelectFile = () => {
    handleEnableSelectFile && handleEnableSelectFile();
    handleClose();
  };

  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        disabled={isLoading}
        onClick={handleClick}
        className={styles.download_btn}
      >
        {t('download')}
        <KeyboardArrowDownIcon className="ml_5" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid style={{ padding: 8 }}>
          <MenuItem onClick={onClickAllFiles}>{t('allFiles')}</MenuItem>
          <MenuItem onClick={onClickSelectFile}>
            {t('selectFileToDownload')}
          </MenuItem>
        </Grid>
      </Menu>
    </>
  );
};

export default DownloadMenu;

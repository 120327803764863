import React from 'react';

import './index.scss';

class ActiveWork extends React.Component {

  render() {
    return(
      <div>This is for active work</div>
    );
  }
}

export default ActiveWork;
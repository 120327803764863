import React, { Component, forwardRef } from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
// Material helpers
import { withStyles, Checkbox, FormControlLabel, Chip, Tooltip } from '@material-ui/core';

import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  ArrowUpward,
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  Error,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveOutlined,
  SaveAlt,
  Search,
  ToggleOff,
  Visibility,
  AccountBalance,
  Person
} from '@material-ui/icons';

// Shared components
import { Portlet, PortletContent } from 'pages/Dashboard/components';
import PhoneNumberFormat from 'lib/phoneNumberFormat';
// Component styles
import styles from './styles';
import { getClients } from 'redux/client';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <SaveOutlined {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ToggleOff: forwardRef((props, ref) => <ToggleOff {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />)
};

class ClientTable extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
    this.state = {
      selectedClients: [],
      rowsPerPage: 10,
      page: 0,
      includingArchived: false
    };

    this.handleRefreshTable = this.handleRefreshTable.bind(this);
  }


  handleRefreshTable(event) {
    this.setState({ includingArchived: event.target.checked });
    this.tableRef.current && this.tableRef.current.onQueryChange({ includingArchived: this.state.includingArchived, page: 0 });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event });
  };


  render() {
    const { classes, className, getClients, history, translate } = this.props;
    const { includingArchived } = this.state;
    const rootClassName = classNames(classes.root, className);

    const columns = [
      {
        field: 'archived',
        title: translate('Client:status'),
        sorting: false,
        render: rowData => {
          return rowData.archived ?
            <Chip size="small" label={translate('Common:archived')} className={classes.chip} color="primary" />
            :
            <Chip size="small" label={translate('Common:active')} className={classes.chip} color="secondary" />;
        }
      },
      {
        field: 'useCompanyName',
        title: translate('Client:type'),
        sorting: false,
        render: rowData => {
          return rowData.useCompanyName ?
            <Tooltip title="Company">
              <AccountBalance style={{ margin: 0 }} />
            </Tooltip>
            :
            <Tooltip title="Individual"><Person style={{ margin: 0 }} /></Tooltip>;
        }
      },
      {
        field: 'name',
        title: translate('Client:name'),
        sorting: false,
        render: rowData => {
          return <span className={classes.capitalize}>
            { rowData.displayName }
          </span>;
        }
      },

      {
        field: 'emails',
        title: translate('Client:email'),
        sorting: false,
        render: rowData => {

          if (!rowData.emails || rowData.emails.length === 0) {
            return '';
          }
          const mainEmail = rowData.emails.find(item => {
            return item.typeId === 'MAIN';
          });

          return mainEmail ? mainEmail.email : rowData.emails[0].email;
        }
      },
      {
        field: 'phoneNumbers',
        title: translate('Client:phone'),
        sorting: false,
        render: rowData => {
          if (!rowData.phoneNumbers || rowData.phoneNumbers.length === 0) {
            return '';
          }

          const mainPhone = rowData.phoneNumbers.find(item => {
            return item.typeId === 'MAIN';
          });

          return mainPhone ?
            `${mainPhone.countryPhoneCode} ${PhoneNumberFormat(mainPhone.phoneNumber)}`
            :
            `${rowData.phoneNumbers[0].countryPhoneCode} ${PhoneNumberFormat(rowData.phoneNumbers[0].phoneNumber)}`;
        }
      },
      {
        field: 'location',
        title: translate('Client:location'),
        sorting: false,
        render: rowData => {
          if (rowData.properties.length === 0) {
            return '';
          }
          const flag = !rowData.properties || rowData.properties.some(property => !property.lat && !property.lng);
          return flag ?
            <Error style={{ color: 'red' }} />
            :
            '';
        }
      },
    ];

    const options = {
      pageSize: this.state.rowsPerPage,
      pageSizeOptions: [10, 25, 50],
      actionsColumnIndex: -1,
      sorting: true,
      search: true,
      debounceInterval: 1000,
      draggable: false
    };

    const fetchClient = async(query) => {
      const { pageSize, orderBy, orderDirection, search } = query;
      let { page } = query;
      let sortParams = {};
      if (orderBy && orderDirection) {
        sortParams = { field: query.orderBy['field'], orderDirection: query.orderDirection };
      }
      try {
        let response = await getClients(
          {
            limit: pageSize,
            skip: page * pageSize,
            searchString: search,
            sort: sortParams,
            includingArchived
          });
        let clients = response.data.data || [];
        // Try to go back 1 page and fetch data again if this page have no data
        if (!clients.length && page > 0) {
          page -= 1;
          response = await getClients(
            {
              limit: pageSize,
              skip: page * pageSize,
              searchString: search,
              sort: sortParams,
              includingArchived
            });
          clients = response.data.data || [];
        }
        return Promise.resolve({
          data: clients,
          page,
          totalCount: response.data.total
        });
      } catch (error) {
        Promise.reject(error);
      }
    };

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <MaterialTable
            title={null}
            columns={columns}
            options={options}
            icons={tableIcons}
            tableRef={this.tableRef}
            data={fetchClient}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            onRowClick={(event, rowData) => {
              window.open(`/clients/${rowData.id}`, '_blank');
            }}
            actions={[
              rowData => (!rowData.archived && {
                icon: tableIcons.Edit,
                tooltip: translate('editClient'),
                onClick: (event, rowData) => {
                  history.push(`/clients/${rowData.id}/edit`);
                },
              })
            ]}
            components={{
              Toolbar: props => (
                <div>
                  <MTableToolbar {...props} />
                  <div className={classes.includeArchiveCheckbox}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.includingArchived}
                          onChange={this.handleRefreshTable}
                          value="includingArchived"
                        />
                      }
                      label={translate('Client:showArchived')}
                    />
                  </div>
                </div>
              ),
            }}
            localization={{
              toolbar: {
                searchTooltip: translate('Common:search'),
                searchPlaceholder: translate('Common:search'),
              },
              pagination: {
                labelRowsSelect: translate('Common:rows'),
                labelDisplayedRows: ` {from}-{to} ${translate('Common:of')} {count}`,
                firstTooltip: translate('Common:firstPage'),
                previousTooltip: translate('Common:previousPage'),
                nextTooltip: translate('Common:nextPage'),
                lastTooltip: translate('Common:lastPage')
              },
              header: {
                actions: translate('Common:actions')
              },
              body: {
                emptyDataSourceMessage: `No records to display or
                you have to search to see client due to your permission`,
              }
            }}
          />
        </PortletContent>
      </Portlet>
    );
  }
}

ClientTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  clients: PropTypes.array.isRequired
};

ClientTable.defaultProps = {
  clients: [],
  onSelect: () => {},
  onShowDetails: () => {}
};

const mapDispatchToProps = dispatch => ({
  getClients: (options) => dispatch(getClients(options)),
});
export default compose(
  withRouter,
  connect( null, mapDispatchToProps),
  withStyles(styles)
)(ClientTable);

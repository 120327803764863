export default {
  categoryManage: 'CATEGORY FILTER',
  timeRange: 'TIME RANGE',
  noTagAdded: 'No category tag added',
  hello: 'Hello',
  dateFormat: 'MMM DD, YYYY',
  dateTimeFormat: 'MMM DD, YYYY hh:mm A',
  latestUpdateIs: 'Last updated: {{time}}',
  refresh: 'Refresh',
  jobSummary: 'Total Job Summary',
  completed: 'COMPLETED',
  created: 'CREATED',
  timeliness: 'Timeliness Score',
  totalVisits: 'TOTAL COMPLETED VISITS',
  inProgress: 'IN PROGRESS',
  pending: 'PENDING',
  cancelled: 'CANCELLED',
  totalJobSummary: 'Total Job Summary Over Time',
  createdJobStatus: 'Created Job Status',
  announcements: 'Announcements',
  currentTime: 'Current time: {{time}}',
  noAnnouncements: 'No announcement to display.',
  noAnnouncementsDescription:
    'We’ll let you know when we’ve got something new for you.',
  jobId: 'Job ID',
  date: 'Date',
  title: 'Title',
  status: 'Status',
  mostRecentJob: 'Most recent Jobs',
  newest: 'NEWEST',
  noJobs: 'No Jobs Recorded',
  announcementDetails: 'Announcement details',
  noData: 'No data available',
  noDataDescription:
    'There is no data to show. Please choose different filters to try again.',
  generalInfo: 'General Info',
  clientDetails: 'Client details',
  jobDetails: 'JOB DETAILS',
  currentVisitToolTip:
    'Current visit status will be used as the main representative status for job.',
  currentVisit: 'Current/Latest visit',
  dateCreated: 'Date created',
  schedule: 'Schedule',
  location: 'Location',
  jobDescription: 'Job Description',
  jobTitle: 'Job Title',
  name: 'Name',
  contactInfo: 'Contact Info',
  propertyAddress: 'Property Address',
  details: 'Details',
  textCopied: 'ID Copied!',
  copy: 'Copy',
  widgetNotAffect: 'This widget is not affected by time range filter.',
  statusDescription: 'Current visit status will be used as the main representative status for job.',
  to: 'to',
  cancelledAndClosed: 'CANCELLED & CLOSED',
  completedAndClosed: 'COMPLETED & CLOSED',
};

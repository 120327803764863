export default theme => ({
  root: {},
  dropzone: {
    height: 200,
    width: 200,
    backgroundColor: theme.palette.common.white,
    border: '2px dashed rgb(187, 186, 186)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 16,
    margin: '0 auto',
    cursor: 'pointer'
  },
  fileInput: {
    display: 'none',
  },
  highlight: {
    backgroundColor: 'rgb(253, 252, 243)'
  }
});

import { POST } from 'lib/api';

export function uploadCompanyProfilePicture(formData) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return POST('/upload', accessToken, { data: formData }).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };

}

export function uploadJobFiles(formData) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const data = { data: formData };

    return POST('/media', accessToken, data).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };

}

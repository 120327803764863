export default {
  // common && crumbs
  addClient: "+ เพิ่มลูกค้า",
  clientDetails: "รายละเอียดลูกค้า",
  editClient: "แก้ไขข้อมูลลูกค้า",
  clients: "ลูกค้า",
  importClients: "นำเข้าข้อมูลลูกค้า",
  exportClients: "ดาวน์โหลดข้อมูลลูกค้า",
  clientList: "Client List",

  // clients table
  status: "สถานะ",
  type: "ชนิด",
  name: "ชื่อ",
  email: "อีเมล",
  phone: "เบอร์โทรศัพท์",
  actions: "รายการ",
  search: "ค้นหา",
  showArchived: "แสดงข้อมูลที่จัดเก็บ",
  rows: "แถว",
  of: "ของ",
  viewClient: "ดูข้อมูลลูกค้า",
  location: "พิกัดสถานที่?",

  // details
  properties: "สถานที่",
  overview: "ภาพรวม",
  schedule: "นัดหมาย",
  contactInfo: "ข้อมูลผู้ติดต่อ",
  billingHistory: "ประวัติการวางบิล",
  noBillingDetails: "ไม่มีรายละเอียดใบแจ้งหนี้/ใบกำกับภาษี",
  currentBalance: "ยอดค้างชำระปัจจุบัน",
  paymentForInvoice: "ชำระเงินสำหรับใบแจ้งหนี้/ใบกำกับภาษี",
  internalNotes: "บันทึกภายในและไฟล์แนบ",
  saveClient: "บันทึกลูกค้า",
  job: "งาน",
  invoice: "ใบแจ้งหนี้/ใบกำกับภาษี",
  property: "สถานที่",
  payment: "ชำระเงิน",
  addPropertyAddress: "เพิ่มสถานที่",
  addBillingAddress: "เพิ่มที่อยู่ในการออกใบกำกับภาษี",

  // selectjobmodal
  selectItems: "เลือกสินค้า/บริการ",
  noJobAvailable: "ยังไม่มีงานสำหรับลูกค้านี้",

  // createpropertymodal
  createNewProperty: "สร้างสถานที่ใหม่",
  createProperty: "สร้างสถานที่",

  // updatepaymentmodal
  appliedTo: "สำหรับ",
  paymentDate: "วันที่",
  paymentMethod: "ช่องทาง",
  emailReceipt: "อีเมลใบเสร็จรับเงิน/ใบกำกับภาษี",
  downloadPdf: "ดาวน์โหลด PDF",
  amount: "จำนวน",
  chequeNumber: "เลขที่เช็ค",
  reference: "อ้างอิง",
  details: "รายละเอียด",

  // confirmdeletemodal
  deleteProduct: "ต้องการลบสินค้านี้?",
  deleteService: "ต้องการลบบริการนี้?",
  permanentDelete:
    "การลบการชำระเงิน <0><0></0></0> จะเป็นการลบออกจากระบบ WorkPulse และไม่สามารถกู้คืนได้",
  deletePayment: "ลบการชำระเงิน <0><0></0></0>",

  // notifications
  archivedSuccess: "{{clientName}} ถูกจัดเก็บแล้ว",
  unarchivedSuccess: "{{clientName}} ถูกยกเลิกการจัดเก็บแล้ว",
  askConfirmationBeforeArchive: "ต้องการจัดเก็บลูกค้านี้? ",
  askConfirmationBeforeUnarchive: "ต้องการยกเลิกการจัดเก็บลูกค้านี้?",
  updatedSuccess: "อัปเดต {{clientName}} แล้ว",
  // Property
  searchAddress: "ค้นหาที่อยู่",
  clientHasNoName: "ลูกค้านี้ไม่มีชื่อในข้อมูล",
  noJobToCreateInvoice: "There is no job to select.",
  noInvoice: "There is no invoice for this client.",
  createInvoiceNow: "Create a new invoice now!",
  noJob: "There is no job for this client.",
};

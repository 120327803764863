import React from 'react';
import { Link } from 'react-router-dom';

export default function PricingPlan(props) {
  const {
    // country,
    secondaryPage = false, homepage = true, translate } = props;

  // const priceList = {
  //   MY: {
  //     basic: 'RM39.90',
  //     plus: 'RM99.90',
  //     pro: 'RM238',
  //     enterprise: '',
  //   },
  //   SG: {
  //     basic: 'S$39.90',
  //     plus: 'S$99.90',
  //     pro: 'S$238',
  //     enterprise: '',
  //   },
  //   TH: {
  //     basic: 'RM39.90',
  //     plus: 'RM99.90',
  //     pro: 'RM238',
  //     enterprise: '',
  //   },
  // };

  // const getPrice = countryCode => {
  //   switch(countryCode.countryCode) {
  //     case 'MY':
  //       return priceList.MY;
  //     case 'SG':
  // 	  return priceList.SG;
  //     case 'TH':
  // 	  return priceList.TH;
  //     default:
  // 	  return priceList.MY;
  //   }
  // };

  return (
    <>
      {/* <!--::pricing part start::--> */}
      <section className="pricing_part section_padding home_page_pricing text-center">
        <div className="container">
          {
            !secondaryPage &&
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="section_tittle text-center">
                    <h2>{translate('LandingPage:pricingPlan')}</h2>
                  </div>
                </div>
              </div>
          }
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div className="single_pricing_part d-flex flex-column">
                <img src="/img/icon/pricing_icon_1.png" alt="" />
                <div className="pricing_category_name">
                  <h3>{translate('LandingPage:basic')}</h3>
                  <p>{translate('LandingPage:basicDesc')}</p>
                </div>
                {/* <h3>{getPrice(country).basic} <span>{translate('LandingPage:perMonth')}</span></h3> */}
                <div className="list_with_heading">
                  <p>{translate('LandingPage:oneUser')}</p>
                  <p>{translate('LandingPage:jobManagement')}</p>
                  <ul>
                    <li>{translate('LandingPage:workScheduling')}</li>
                    <li>{translate('LandingPage:jobStatusNotification')}</li>
                    <li>{translate('LandingPage:realTimeTracking')}</li>
                  </ul>
                  <p>{translate('LandingPage:customerManagement')}</p>
                  <ul>
                    <li>{translate('LandingPage:customerDatabase')}</li>
                    <li>{translate('LandingPage:smsNotification')}</li>
                    <li>{translate('LandingPage:emailNotification')}</li>
                  </ul>
                  <p>{translate('LandingPage:digitalAccountingTools')}</p>
                  <ul>
                    <li>{translate('LandingPage:paperlessQuotation')}</li>
                    <li>{translate('LandingPage:paperlessInvoicing')}</li>
                    <li>{translate('LandingPage:paymentRecording')}</li>
                  </ul>
                  <br />
                  <p>{translate('LandingPage:extras')}</p>
                  <ul>
                    <li>{translate('LandingPage:extrasDescBasic')}</li>
                    <li>{translate('LandingPage:availableAtExtraCharge')}</li>
                  </ul>
                </div>
                {homepage && <Link to="sign-up" className="pricing_btn mt-auto">
                  {translate('LandingPage:signUpNow')}</Link>}
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single_pricing_part d-flex flex-column">
                <img src="/img/icon/pricing_icon_2.png" alt="" />
                <div className="pricing_category_name">
                  <h3>{translate('LandingPage:plus')}</h3>
                  <p>{translate('LandingPage:plusDesc')}</p>
                </div>
                {/* <h3>{getPrice(country).plus} <span>{translate('LandingPage:perMonth')}</span></h3> */}
                <div className="list_with_heading">
                  <p>{translate('LandingPage:threeUsers')}</p>
                  <p>{translate('LandingPage:jobManagement')}</p>
                  <ul>
                    <li>{translate('LandingPage:workScheduling')}</li>
                    <li>{translate('LandingPage:jobStatusNotification')}</li>
                    <li>{translate('LandingPage:teamTracking')}</li>
                    <li>{translate('LandingPage:realTimeTracking')}</li>
                  </ul>
                  <p>{translate('LandingPage:customerManagement')}</p>
                  <ul>
                    <li>{translate('LandingPage:customerDatabase')}</li>
                    <li>{translate('LandingPage:smsNotification')}</li>
                    <li>{translate('LandingPage:emailNotification')}</li>
                  </ul>
                  <p>{translate('LandingPage:digitalAccountingTools')}</p>
                  <ul>
                    <li>{translate('LandingPage:paperlessQuotation')}</li>
                    <li>{translate('LandingPage:paperlessInvoicing')}</li>
                    <li>{translate('LandingPage:paymentRecording')}</li>
                  </ul>
                  <br />
                  <p>{translate('LandingPage:extras')}</p>
                  <ul>
                    <li>{translate('LandingPage:extrasDescPlus')}</li>
                    <li>{translate('LandingPage:availableAtExtraCharge')}</li>
                  </ul>
                </div>
                {homepage && <Link to="sign-up" className="pricing_btn mt-auto">
                  {translate('LandingPage:signUpNow')}</Link>}
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single_pricing_part d-flex flex-column">
                <img src="/img/icon/pricing_icon_3.png" alt="" />
                <div className="pricing_category_name">
                  <h3>{translate('LandingPage:pro')}</h3>
                  <p>{translate('LandingPage:proDesc')}</p>
                </div>
                {/* <h3>{getPrice(country).pro} <span>{translate('LandingPage:perMonth')}</span></h3> */}
                <div className="list_with_heading">
                  <p>{translate('LandingPage:sixUsers')}</p>
                  <p>{translate('LandingPage:allFeaturesUnderPlus')}</p>
                  <p>+</p>
                  <p>{translate('LandingPage:teamManagement')}</p>
                  <ul>
                    <li>{translate('LandingPage:employeeTimesheets')}</li>
                  </ul>
                  <p>{translate('LandingPage:advancedSupport')}</p>
                  <ul>
                    <li>{translate('LandingPage:dedicatedManager')}</li>
                  </ul>
                </div>
                {homepage && <Link to="sign-up" className="pricing_btn mt-auto">
                  {translate('LandingPage:signUpNow')}</Link>}
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single_pricing_part d-flex flex-column">
                <img src="/img/icon/pricing_icon_4.png" alt="" />
                <div className="pricing_category_name">
                  <h3>{translate('LandingPage:enterprise')}</h3>
                  <p>{translate('LandingPage:enterpriseDesc')}</p>
                </div>
                <div className="list_all_heading">
                  <p>{translate('LandingPage:enterpriseUsers')}</p>
                  <p>{translate('LandingPage:personalisedBranding')}</p>
                  <p>{translate('LandingPage:advancedCustomisations')}</p>
                </div>
                {homepage &&<Link to="contact" className="pricing_btn mt-lg-auto mt-md-auto">
                  {translate('Common:contactUs')}</Link>}
              </div>
            </div>
          </div>
        </div>
        <img src="/img/animate_icon/Ellipse_2.png" alt="" className="feature_icon_2 custom-animation2" />
        <br />
        {translate('LandingPage:chargesApply')}
      </section>
      {/* <!--::pricing part end::--> */}
    </>
  );
}
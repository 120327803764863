export default theme => ({
  root: {},
  cardHeader: {
    backgroundColor: theme.palette.background.muted2
  },
  assignButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  textStyle: {
    color: '#0075FE'
  },
  teamChip: {
    margin: 2,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.muted2,
  },
  noneAssignText: {
    fontWeight: 400,
  },
  visitSchedule: {
    width: '100%'
  },
  cursor: {
    cursor: 'pointer'
  },
  job_title: {
    color: '#3A4457',
    fontWeight: 700
  },
  schedule_start: {
    color: '#A2A2A2'
  }
});

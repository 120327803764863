import React from 'react';

// Externals
import classNames from 'classnames';

// Material components
import { Grid, Typography, withStyles } from '@material-ui/core';

// Component styles
import styles from './styles';

function replaceSecondCommaWithBreakLine(string) {
  return string.replace(/(,[^,]*),/g, '$1,\n');
}

const AddressComponent = (props) => {
  const { address, classes, translate, textStyle } = props;
  if (!address) return null;
  const combinedAddress = [address.unitNumber, address.address].filter(Boolean).join(', ');

  return (
    <Grid item container direction="column">
      {address && Object.keys(address).length > 0 ? (
        <>
          {
            address && address.name &&
            <Typography
              style={textStyle}
              className={classNames(
                classes.textDetails,
                classes.textDetailsBold
              )}
            >
              {replaceSecondCommaWithBreakLine(address.name)}
            </Typography>
          }
          <Grid>
            { combinedAddress && (
              <Typography style={textStyle} className={classes.textDetails}>
                {replaceSecondCommaWithBreakLine(combinedAddress)}
              </Typography>
            )}
          </Grid>
        </>
      ) : (
        <Grid>
          <Typography className={classes.noAddress}>
            {translate('Common:noAddressRecorded')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)((props) => {
  const { address, classes, translate, title, titleStyle, textStyle } = props;

  return (
    <Grid container>
      <Typography
        variant="h5"
        className={classNames(classes.title)}
        style={titleStyle}
      >
        {title}
      </Typography>
      <AddressComponent
        textStyle={textStyle}
        classes={classes}
        address={address}
        translate={translate}
      />
    </Grid>
  );
});

export default theme => ({
  root: {},
  cardHeader: {
    backgroundColor: theme.palette.background.muted2
  },
  portlet: {
    border: 'none',
    paddingBottom: theme.spacing(6),
    borderStyle: 'none',
  },
  visitTableContent: {
    padding: 0
  },
  assignButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  rootCell: {
    fontSize: 16,
    paddingLeft: 18
  },
  headerCell: {
    paddingBottom: theme.spacing(0),
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.text.primary,
  },
  itemTitle: {
    fontWeight: 600,
    fontSize: 16,
  },
  itemDescription: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.grey800,
  },
  itemCell: {
    minWidth: 150,
    verticalAlign: 'top',
  },
  clientMessage: {
    borderTopStyle: 'solid',
    borderTopWidth: 3,
    borderTopColor: theme.palette.divider,
  },
  messageCell: {
    borderBottom: 'none',
    verticalAlign: 'top',
    lineHeight: '24px'
  },
  taxRows: {
    borderBottom: 'none',
  },
  totalCell: {
    fontWeight: 600,
  },
  totalRows: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 3,
    borderBottomColor: theme.palette.divider,
  },
  rowNoBorder: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 3,
    borderBottomColor: 'white',
  },
  balanceCell: {
    border: 'none',
    fontSize: 14,
  },

  signatureTextCell: {
    borderStyle: 'none',
  },
  dateTextCell: {
    borderStyle: 'none',
  },
  blankCell: {
    borderStyle: 'none',
  },
  signatureCell: {
    borderBottom:`1px solid ${theme.palette.divider}`,
    borderTop:`1px solid ${theme.palette.divider}`,
    verticalAlign: 'bottom',
  },
  signatureFile: {
    width: 'auto',
    height: '100%',
    maxHeight: 82,
  },
  tableHeader: {
    borderTop: '1px solid #C4C4C4',
    borderBottom: '1px solid #C4C4C4',
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  },
});
import React, { useState, useEffect } from 'react';

import moment from 'moment';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, formValueSelector, submit, change, arraySplice } from 'redux-form';

// Material components
import {
  Grid, Button, Typography, withStyles
} from '@material-ui/core';

// Component
import SelectClient from './SelectClient';
import QuoteDetailFormStyle from './styles/QuoteDetailFormStyle';
import { InternalNotesCard, ServiceProductCard, ServiceProductCardForIndo }
  from 'pages/Dashboard/views/Quotes/components/cards';
import { ContactDetails, AddressDetails ,
  Portlet,
  PortletFooter,
  PortletContent, ConfirmEmailModal
  , ConfirmSmsModal } from 'pages/Dashboard/components';
import { getClientEmail, getClientPhone, getClientPhoneObject } from 'lib/clientContact';

// shared component
import { TextInput, DatePicker } from 'components/inputs';

import { OnlyDecimal } from 'components/converters';
import SplitButton from 'components/buttons/SplitButton';
import { clearQuote } from 'redux/quote';
import { QUOTE_ALLOW_STATUSES } from 'common/constant';


const quoteForm = formValueSelector('quoteForm');

// Set default payment due date is 14 days.
const DEFAULT_EXPIRE_DATE = 30; // make sure this value >= 0
const getExpiryDate = (expiredAt, currentDate = null) => {
  if (expiredAt === null || expiredAt === undefined || expiredAt === -1) {
    return null;
  }
  const date = currentDate ? new Date(currentDate) : new Date();
  if (expiredAt === 0) {
    return date;
  }

  date.setDate(date.getDate() + expiredAt);
  return date;
};

const expiryOptions = [
  {
    id: 30,
    name: '30 days',
    description: null,
    sortOrder: 1,
    translationKey: 'THIRTY_DAYS',
  },
  {
    id: 7,
    name: '7 days',
    description: null,
    sortOrder: 1,
    translationKey: 'SEVEN_DAYS',
  },
  {
    id: 14,
    name: '14 days',
    description: null,
    sortOrder: 1,
    translationKey: 'FOURTEEN_DAYS',
  },
  {
    id: -1,
    name: 'Custom',
    description: null,
    sortOrder: 1,
    translationKey: 'CUSTOM',
  },
];

const calculateDays = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days');
};

const mapStateToProps = state => {
  const { quote } = state.quote;
  const { quoteSetting } = state.config.configs;
  let quoteItems = (quote && quote.items) || [];
  quoteItems = quoteItems.map(item => {
    let itemTaxPercentage = 0;
    if (item.taxRate) {
      itemTaxPercentage = item.taxRate.percentage || 0;
    }

    if (item.taxComponent) {
      itemTaxPercentage = (item.taxComponent.taxRates || []).reduce((sum, taxRate) => {
        return sum + parseFloat(taxRate.percentage || 0);
      }, 0);
    }
    const taxAmount = parseFloat(item.totalBeforeTax) * parseFloat(itemTaxPercentage) / 100;
    const totalIncludeTax = parseFloat(item.totalBeforeTax) + taxAmount;
    item.totalIncludeTax = totalIncludeTax;
    return item;
  });

  return ({
    initialValues: {
      id: null,
      jobTitle: (quoteSetting && quoteSetting.defaultSubject) || null,
      items: quoteItems,
      client: null,
      property: null,
      clientMessage: (quoteSetting && quoteSetting.defaultNote) || null,
      notes: (state.quote.quote && state.quote.quote.notes),
      discountValue: 0,
      discountType: '%',
      issuedAt: null,
      expiredAt: null,
      depositValue: 0,
      depositType: '%',
      currency: state.auth.currentUser.companies[0].currency,
      validity: (quoteSetting && quoteSetting.defaultValidity) || null,
    },
    client: quoteForm(state, 'client'),
    property: quoteForm(state, 'property'),
    issuedAt: quoteForm(state, 'issuedAt'),
    expiredAt: quoteForm(state, 'expiredAt'),
    validity: quoteForm(state, 'validity'),
    quote: state.quote.quote,
    currentCompany: (state.auth.currentUser && state.auth.currentUser.companies &&
      state.auth.currentUser.companies[0]) || {},
    discountType: quoteForm(state, 'discountType'),
    discountValue: quoteForm(state, 'discountValue'),
    quoteItems: quoteForm(state, 'items'),
  });
};

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('quoteForm')),
  clearQuote: () => dispatch(clearQuote()),
  updateQuoteForm: (attribute, value) => { dispatch(change('quoteForm', attribute, value)); },
  updateQuoteItem: (index, newItem) => { dispatch(arraySplice('quoteForm', 'items', index, 1, newItem)); }
});

let QuoteDetailForm = props => {
  const { client, handleSubmit, classes, clearQuote, setSubmitActionValue,
    handleCancel, submitForm, translate, property, setSubmitAction, discountValue, discountType,
    updateQuoteForm, quote, issuedAt, expiredAt, validity, currentCompany, updateQuoteItem, quoteItems
  } = props;

  let companyTag = currentCompany.tag || '';
  companyTag = companyTag.replace(/(\r\n|\n|\r)/gm, '').trim();
  const [showExpireBy, setShowExpireBy] = useState(false);
  const [expiredAtId, setExpiredAtId] = useState(DEFAULT_EXPIRE_DATE);
  const isCustomExpireBy = expiredAtId && expiredAtId === -1;

  const [confirmEmailModal, setConfirmEmailModal] = useState(false);
  const [confirmSmsModal, setConfirmSmsModal] = useState(false);

  const [modalType, setModalType] = useState(null);

  useEffect(() => {
    if (!validity) {
      if (expiredAt && issuedAt) {
        const count = calculateDays(issuedAt, expiredAt);
        if (expiryOptions.some(option => count === option.id)) {
          updateQuoteForm('validity', count);
        } else {
          updateQuoteForm('validity', -1);
          setExpiredAtId(-1);
        }
      }
    } else {
      updateQuoteForm('validity', validity || DEFAULT_EXPIRE_DATE);
    }
  }, [validity, expiredAt, issuedAt, updateQuoteForm]);

  useEffect(() => {
    return () => clearQuote();
  });

  const clientEmail = getClientEmail(client);
  const clientPhone = getClientPhone(client);

  // Tax is applied after discounts.
  // For indo, it's discount before tax23
  function updateQuoteWhenItemChange() {
    let quoteTaxAmount = 0;
    let discountAmount = 0;
    let totalAmount = 0;
    let discountPercentage = 0;

    const subtotalBeforeDiscount = (quoteItems || []).reduce((sum, currentItem) => {
      return sum + parseFloat(currentItem.unitPrice) * parseFloat(currentItem.quantity);
    }, 0);

    if (!discountValue || parseFloat(discountValue) <= 0) {
      discountAmount = 0;
      discountPercentage = 0;
    } else if (discountType === '%') {
      discountPercentage = parseFloat(discountValue) / 100;
      discountAmount =
        (parseFloat(discountValue) * subtotalBeforeDiscount) / 100;
    } else {
      discountAmount = parseFloat(discountValue);
      discountPercentage = parseFloat(discountValue) / subtotalBeforeDiscount;
    }
    const subtotalAfterDiscount = parseFloat(subtotalBeforeDiscount) - parseFloat(discountAmount);

    (quoteItems || []).forEach((item, index) => {
      let sumTaxPercentage = 0;
      if (item.taxRate) {
        sumTaxPercentage = parseFloat(item.taxRate.percentage || 0);
      }

      if (item.taxComponent) {
        sumTaxPercentage = (item.taxComponent.taxRates || []).reduce((sum, taxRate) => {
          return sum + parseFloat(taxRate.percentage || 0);
        }, 0);
      }
      const itemInitialTotal = parseFloat(item.quantity) * parseFloat(item.unitPrice);
      const itemDiscount = parseFloat(itemInitialTotal) * discountPercentage;
      const itemTotalAfterDiscount = parseFloat(itemInitialTotal) - itemDiscount;
      const itemTaxAmount = itemTotalAfterDiscount * parseFloat(sumTaxPercentage) / 100;

      const newItem = {
        ...item,
        total: OnlyDecimal(itemTotalAfterDiscount),
        tax: itemTaxAmount,
        totalIncludeTax: OnlyDecimal(itemTotalAfterDiscount + itemTaxAmount),
      };

      updateQuoteItem(index, newItem);
      quoteTaxAmount += itemTaxAmount;
    });

    const subTotalIncludeTax = subtotalAfterDiscount + quoteTaxAmount;

    totalAmount = parseFloat(subtotalAfterDiscount) + parseFloat(quoteTaxAmount);

    const withholdingTax23 = 0.02 * parseFloat(subTotalIncludeTax) / 1.1;
    const totalAfterTax23 = totalAmount - withholdingTax23;

    updateQuoteForm('subtotal', OnlyDecimal(subtotalAfterDiscount));
    updateQuoteForm('discount', OnlyDecimal(discountAmount));
    updateQuoteForm('tax', OnlyDecimal(quoteTaxAmount));
    updateQuoteForm('total', OnlyDecimal(totalAmount));
    updateQuoteForm('subTotalIncludeTax', OnlyDecimal(subTotalIncludeTax));
    updateQuoteForm('withholdingTax23', OnlyDecimal(withholdingTax23));
    updateQuoteForm('totalAfterTax23', OnlyDecimal(totalAfterTax23));
  }

  useEffect( () => {
    updateQuoteWhenItemChange();
  }, [discountValue, quoteItems, discountType]);


  function handleEmailQuote() {
    setModalType('emailQuote');
    setConfirmEmailModal(true);
  }

  function handleConfirmQuoteEmail(value) {
    // prevent React batching updates
    Promise.resolve().then(() => {
      setConfirmEmailModal(false);
      setSubmitAction('email');
      setSubmitActionValue(value);
      submitForm();
    });
  }

  function handleCloseConfirmEmailModal() {
    setModalType(null);
    setConfirmEmailModal(false);
  }

  const handleSmsQuote = () => setConfirmSmsModal(true);
  const handleCloseConfirmSmsModal = () => setConfirmSmsModal(false);
  const handleConfirmSmsPhone = value => Promise.resolve().then(() => {
    setConfirmSmsModal(false);
    setSubmitAction('sms');
    setSubmitActionValue(value);
    submitForm();
  });

  const saveButtonOptions = {
    title: translate('saveQuote'),
    menuId: 'save-options',
    menu: []
  };

  if (quote &&
    quote.nextValidStatus &&
    [QUOTE_ALLOW_STATUSES.AWAITING_RESPONSE,
      QUOTE_ALLOW_STATUSES.ACCEPTED,
      QUOTE_ALLOW_STATUSES.CONVERTED].some(s => quote.nextValidStatus.includes(s))) {
    saveButtonOptions.menu.push({
      title: translate('Common:saveAnd'),
      menuItems: [
        {
          label: translate('Common:email'),
          action: handleEmailQuote,
        },
        {
          label: translate('Common:sms'),
          action: handleSmsQuote,
        }
      ]
    });
  }
  function handleChangeExpiredAt(event) {
    const { value } = event.target;

    switch (parseInt(value)) {
      case -1:
        setExpiredAtId(-1);
        updateQuoteForm('expiredAt', quote.expiredAt);
        setShowExpireBy(true);
        break;
      case 0:
        setExpiredAtId(0);
        updateQuoteForm('expiredAt', getExpiryDate(0, issuedAt));
        setShowExpireBy(false);
        break;
      case 7:
        setExpiredAtId(7);
        updateQuoteForm('expiredAt', getExpiryDate(7, issuedAt));
        setShowExpireBy(false);
        break;

      case 14:
        setExpiredAtId(14);
        updateQuoteForm('expiredAt', getExpiryDate(14, issuedAt));
        setShowExpireBy(false);
        break;

      case 30:
        setExpiredAtId(30);
        updateQuoteForm('expiredAt', getExpiryDate(30, issuedAt));
        setShowExpireBy(false);
        break;

      default:
        setExpiredAtId(null);
        updateQuoteForm('expiredAt', null);
        setShowExpireBy(false);
        return;
    };
  }

  function handleChangeIssuedAt(value) {
    updateQuoteForm('expiredAt', getExpiryDate(expiredAtId, value));
  }
  return (
    <form
      autoComplete='off'
      className={classes.formField}
      onSubmit={handleSubmit}
    >
      <Portlet>
        <PortletContent noPadding>
          <Grid container direction='row'>
            <Grid container item xl={8} lg={8} md={8} xs={12} className={classes.leftContent}>
              <Grid item xl={12} lg={12} md={12} xs={12} className={classes.invoiceInfo}>

                <SelectClient translate={translate} />

                <Typography variant='h5' className={classes.title}>
                  {translate('quoteTitle')}
                </Typography>

                <Field
                  name='jobTitle'
                  component={TextInput}
                  variant='outlined'
                  margin='dense'
                  className={classes.subtitle}
                />
              </Grid>

              <Grid item container direction='row' className={classes.contact}>
                <Grid item container direction='column' md={8} sm={12}>
                  <AddressDetails
                    address={property}
                    translate={translate}
                    title={translate('billingAddress')}
                  />
                </Grid>
                <Grid item container direction='column' md={4} sm={12}>
                  <ContactDetails
                    contactEmail={clientEmail}
                    contactPhoneNumber={clientPhone}
                    translate={translate}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={4} xs={12} className={classes.rightContent}>
              <Grid item container direction='row' spacing={1} md={12} sm={12} className={classes.title}>
                <h5>{translate('quoteDetails')}</h5>
              </Grid>
              <Grid item container direction='row' spacing={1} md={12} sm={12}>
                <Grid item md={6} sm={12} className={classes.dateItem}>
                  <div className='type-name'>{translate('issuedAt')}</div>
                </Grid>
                <Grid item md={6} sm={12} className={classes.dateItem}>
                  <div className='value'>
                    <Field
                      name='issuedAt'
                      component={DatePicker}
                      minDate={null}
                      onChange={handleChangeIssuedAt}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item container direction='row' spacing={1} md={12} sm={12}>
                <Grid item md={6} sm={12} className={classes.dateItem}>
                  <div className="type-name">{translate('validity')}</div>
                </Grid>
                <Grid item md={6} sm={12} className={classes.dateItem}>
                  <div className="value">
                    <Field
                      name='validity'
                      component={TextInput}
                      select
                      label=""
                      margin="dense"
                      required
                      variant="outlined"
                      SelectProps={{ native: true }}
                      onChange={handleChangeExpiredAt}
                    >
                      {expiryOptions.map(option => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))
                      }
                    </Field>
                  </div>
                </Grid>
              </Grid>


              {
                (showExpireBy || !!isCustomExpireBy) &&
                <Grid item container direction='row' spacing={1} md={12} sm={12}>
                  <Grid item md={6} sm={12} className={classes.dateItem}>
                    <div className="type-name">{translate('expireAt')}</div>
                  </Grid>
                  <Grid item md={6} sm={12} className={classes.dateItem}>
                    <div className="value">
                      <Field
                        name='expiredAt'
                        component={DatePicker}
                        minDate={issuedAt && issuedAt}
                      />
                    </div>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item md={12} sm={12}>
            <ServiceProductCard translate={translate} />
          </Grid>

          <Grid item md={12} sm={12}>
            <InternalNotesCard translate={translate} />
          </Grid>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Grid container direction='row' justify='flex-end' spacing={1}>
            <Grid item>
              <Button color='secondary' onClick={handleCancel}>{translate('Common:cancel')}</Button>
            </Grid>
            <Grid item>
              <SplitButton {...saveButtonOptions} type='submit' translate={translate} />
            </Grid>
          </Grid>
        </PortletFooter>
      </Portlet>
      {
        confirmEmailModal && modalType === 'emailQuote' &&
        <ConfirmEmailModal
          onSubmit={handleConfirmQuoteEmail}
          open={confirmEmailModal && modalType === 'emailQuote'}
          handleClose={handleCloseConfirmEmailModal}
          translate={translate}
          title="sendQuoteToEmail"
          clientEmail={clientEmail}
        />
      }
      { confirmSmsModal &&
        <ConfirmSmsModal
          title='smsQuoteTo'
          clientPhone={getClientPhoneObject(client)}
          onSubmit={handleConfirmSmsPhone}
          open={confirmSmsModal}
          handleClose={handleCloseConfirmSmsModal}
          translate={translate}
        />
      }
    </form>
  );
};

QuoteDetailForm = reduxForm({
  form: 'quoteForm',
})(QuoteDetailForm);

export default compose(
  withRouter,
  withStyles(QuoteDetailFormStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(QuoteDetailForm);

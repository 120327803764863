import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

// Material components
import {
  Button,
  Grid,
  Typography,
  InputAdornment,
} from '@material-ui/core';

// Material helpers
import { withStyles } from '@material-ui/core';

// Customised components
import { TextInput } from 'components/inputs';
import { ServiceProductInput } from '.';

// Component styles
import { ProductInputStyle } from './styles';

const requestForm = formValueSelector('requestForm');

const mapStateToProps = state => ({
  currency: state.auth.currentUser.companies[0].currency,
  formValue: requestForm(state, 'products'),
  isCustomProduct: state.request.isCustomProduct,
  products: state.product.products || [],
  services: state.product.services || [],
});


const ProductInput = props => {
  const { classes, currency, products, services, name, handleDelete, index, translate } = props;

  // const currentDescription = formValue[index].description;

  const servicesArray = Object.keys(services).map(service => {
    const item = services[service];
    const data = {};
    data.label = item.name;
    data.value = item;
    return data;
  });

  const productsArray = Object.keys(products).map(product => {
    const item = products[product];
    const data = {};
    data.label = item.name;
    data.value = item;
    return data;
  });

  const groupedOptions = [
    {
      title: translate('Common:services'),
      suggestions: servicesArray
    },
    {
      title: translate('Common:products'),
      suggestions: productsArray
    }
  ];

  return (
    <Grid container direction='column'>
      <Grid item lg={1} md={1} sm={1}>
        <Typography className={classes.inputIndex}>
          {`${translate('Request:item')} ${index + 1}`}
        </Typography>
      </Grid>
      <Grid container direction='row' spacing={2}>
        <Grid item lg={6} md={6} sm={12}>
          <Grid container direction='column' spacing={0}>
            <Grid item xs={12} sm={12}>
              <Field
                component={ServiceProductInput}
                index={index}
                inputClass={classes.productName}
                name={`${name}.name`}
                options={groupedOptions}
                translate={translate}
              />
              <Field
                name={`${name}.description`}
                placeholder={translate('Common:description')}
                component={TextInput}
                variant='outlined'
                margin='dense'
                multiline
                rows="2"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* QUANTITY */}
        <Grid item lg={2} md={2} sm={12}>
          <Field
            name={`${name}.quantity`}
            placeholder='Qty'
            component={TextInput}
            variant='outlined'
            margin='dense'
            type='number'
          />
        </Grid>
        {/* UNIT COST */}
        <Grid item container lg={2} md={2} sm={12}>
          <Field
            name={`${name}.unitPrice`}
            placeholder='Unit Price ( tax excluded)'
            component={TextInput}
            variant='outlined'
            margin='dense'
            type='number'
            InputProps={{
              startAdornment: <InputAdornment position='start'>{currency}</InputAdornment>,
              inputProps: { min: 0, step: '.01' }
            }}
          />
        </Grid>
        {/* TOTAL COST */}
        <Grid item container direction='column' spacing={0} lg={2} md={2} sm={12}>
          <Grid item>
            <Field
              name={`${name}.total`}
              placeholder={translate('Request:totalPrice')}
              component={TextInput}
              variant='outlined'
              margin='dense'
              InputProps={{
                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                readOnly: true
              }}
            />
          </Grid>
          <Grid item container justify='flex-end' alignItems='flex-end'>
            <Button
              className={classes.deleteButton}
              variant='outlined'
              size='small'
              onClick={handleDelete}
            >
              {translate('Common:delete')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(
  withStyles(ProductInputStyle),
  connect(mapStateToProps, null),
)(ProductInput);
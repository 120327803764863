import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
} from '@material-ui/core';
import '../styles.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addJob } from 'redux/job';
import { showErrorMessage } from 'lib/notifier';
import { REQUEST_STATUS } from 'common/constant';

export const ConvertButton = (props) => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('Request');
  const { request, history } = props;

  const closeConfirmModal = () => setIsOpenConfirmModal(false);
  const openConfirmModal = () => setIsOpenConfirmModal(true);

  const convertToJob = async () => {
    const job = {
      requestId: request.id,
      title: request.title,
      description: request.description,
      client: {
        id: request.client.id,
      },
      property: {
        id: request.property.id,
      },
      orderId: request.orderId,
      hubId: request.hubId,
      notes: request.notes,
    };
    if (request.categories && request.categories.length > 0) {
      job.categories = request.categories;
    }
    if (request.items && request.items.length > 0) {
      job.items = request.items;
    }
    dispatch(addJob(job)).then(
      (response) => {
        if (response.status === 200) {
          history.push(`/jobs/${response.data.publicId}`);
        }
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  };

  return (
    <>
      {request.statusId !== REQUEST_STATUS.CONVERTED && (
        <>
          <Dialog
            open={isOpenConfirmModal}
            onClose={closeConfirmModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Typography variant="h4" className="mb_5">
                {'Do you want to convert this request to a job?'}
              </Typography>
              {'Once converted, you will not be able to edit this request.'}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeConfirmModal} variant="contained">
                {'Cancel'}
              </Button>
              <Button
                onClick={convertToJob}
                className="confirm-button"
                variant="contained"
                autoFocus
              >
                {'Convert to Job'}
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            fullWidth
            onClick={openConfirmModal}
            className="review-button start-review-button"
          >
            {t('convertToJob')}
          </Button>
        </>
      )}
    </>
  );
};

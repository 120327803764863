export default theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    zIndex: theme.zIndex.appBar,

  },
  paper: {
    position: 'absolute',
    top: 40,
    right: 5,
    width: 300,
    border: '1px solid #DFE3E8'
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%'
  },
  title: {
    marginLeft: theme.spacing(1)
  },
  childTitle: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom'
  },
  menuButton: {
    marginLeft: '-4px'
  },
  notificationsButton: {
    marginLeft: 'auto'
  },
  signOutButton: {
    marginLeft: 'auto'
  },
  menuForUserInfo: {
    display: 'flex',
    flexDirection: 'row'
  },
  userAvatar: {
    flexGrow: 0,
    marginRight: 10
  },
  avatar: {

  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  menuList: {
    padding: 0
  },
  menuItem: {
    padding: 10
  }
});

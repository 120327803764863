import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

// Externals
import PropTypes from 'prop-types';

// Redux
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { setProducts, updateProducts, setServices, getProducts } from 'redux/product';
import { getCompanyTaxRates } from 'redux/company';
// Material helpers
// Material components
import { withStyles, Grid } from '@material-ui/core';
import { showErrorMessage } from 'lib/notifier';

// Custom components
import { ServicesTable, ProductsTable } from '../components/utils';

// Component styles
import styles from './style';

// import { withTranslation } from 'react-i18next';

const mapStateToProps = state => ({
  isLoading: state.global.isLoading,
  products: state.product.products,
  services: state.product.services,
  currentCompany: state.auth.currentUser.companies[0]
});

function ProductList(props) {
  const { classes, products, services, translate, currentCompany } = props;
  const [taxRates, setTaxRates] = useState([]);
  const [taxComponents, setTaxComponents] = useState([]);

  let companyTag = currentCompany.tag || '';
  companyTag = companyTag.replace(/(\r\n|\n|\r)/gm, '').trim();

  const dispatch = useDispatch();

  function getCompanyTaxRate() {
    dispatch(getCompanyTaxRates()).then(response => {
      if (response.status === 200) {
        setTaxRates(response.data.taxRates);
        setTaxComponents(response.data.taxComponents);
      }
    }, error => {
      showErrorMessage(error);
    });
  }

  function getProductsAndServices() {
    dispatch(getProducts('products'));
    dispatch(getProducts('services'));
  }

  useEffect(() => {
    getCompanyTaxRate();
    getProductsAndServices();
  }, []);

  const onDragEnd = result => {
    const { destination, source } = result;
    const productType = destination.droppableId === 'product' ? products : services;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newArr = Array.from(Object.keys(productType));
    newArr.splice(source.index, 1);
    newArr.splice(destination.index, 0, source.index);

    const newProducts = {};

    newArr.map(String).forEach((key, index) => {
      newProducts[index] = productType[key];
      dispatch(updateProducts(productType[key].id, { sortOrder: index+1 }));
    });

    if (destination.droppableId === 'product') {
      dispatch(setProducts(newProducts));
    }

    if (destination.droppableId === 'service') {
      dispatch(setServices(newProducts));
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify='center'>
        <Grid
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <div className={classes.content}>
              <ServicesTable
                taxRates={taxRates}
                taxComponents={taxComponents}
                translate={translate}
              />
            </div>
          </DragDropContext>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={9}
          xs={12}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <div className={classes.content}>
              <ProductsTable taxRates={taxRates} taxComponents={taxComponents} translate={translate} />
            </div>
          </DragDropContext>
        </Grid>
      </Grid>
    </div>
  );
};

ProductList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  withStyles(styles)
)(ProductList);

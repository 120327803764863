// Palette
import palette from '../theme/palette';

export default {
  '& .MuiPopover-paper': {
    padding: 8,
    minWidth: 180,
    marginTop: 10,
    overflowX: 'initial',
    overflowY: 'initial',
    borderColor: palette.primary.light,
    borderWidth: 1,
    borderStyle: 'solid',
    '&:before': {
      content: '""',
      position: 'absolute',
      right: 10,
      top: -9,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 8px 8px 8px',
      borderColor: `transparent transparent ${palette.primary.light} transparent`,
      zIndex: 9998,
    }
  },
};

import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { addStaff, getStaffs, clearStaffs } from 'redux/staff';

// Material components
import { Button, Chip, Typography, withStyles, Grid } from '@material-ui/core';

// Material helpers
import { SelectTeamModal, CreateStaffModal } from 'pages/Dashboard/components';

// Component styles
import TeamCardStyle from './styles';

const mapStateToProps = (state) => ({
  staffs: state.staff.staffs,
  staffDetails: (state.form.newStaff && state.form.newStaff.values) || {},
});

const mapDispatchToProps = (dispatch) => ({
  getStaffs: (params) => dispatch(getStaffs(params)),
  addStaff: (staffDetails) => dispatch(addStaff(staffDetails)),
  submitStaffForm: () => dispatch(submit('newStaff')),
  clearStaffs: () => dispatch(clearStaffs()),
});

class TeamCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openStaffList: false,
      openNewStaff: false,
      staffs: []
    };
    this.handleAssignStaff = this.handleAssignStaff.bind(this);
    this.handleCreateNewStaff = this.handleCreateNewStaff.bind(this);
    this.handleCloseSelectTeamModal = this.handleCloseSelectTeamModal.bind(
      this
    );
    this.handleCreateStaff = this.handleCreateStaff.bind(this);
    this.handleCreateClose = this.handleCreateClose.bind(this);
    this.removeStaff = this.removeStaff.bind(this);
    this.handleClearTeam = this.handleClearTeam.bind(this);
    this.finishSelectionTeam = this.finishSelectionTeam.bind(this);
  }

  componentDidMount() {
    this.props.clearStaffs();
  }

  handleAssignStaff() {
    this.setState({ openStaffList: !this.state.openStaffList });
  }

  async handleCreateNewStaff() {
    const formData = new FormData();
    formData.append('staffInfo', JSON.stringify(this.props.staffDetails));
    await this.props.addStaff(formData);

    await this.props.getStaffs({ $scope: 'JOB_MANAGEMENT|ASSIGN_JOB' });
    this.setState({ openNewStaff: false });
    this.setState({ openStaffList: true });
  }

  handleCloseSelectTeamModal() {
    this.setState({ openStaffList: false });
  }

  handleCreateStaff() {
    this.setState({ openStaffList: false });
    this.setState({ openNewStaff: true });
  }

  handleCreateClose() {
    this.setState({ openNewStaff: false, openStaffList: true });
  }

  handleClearTeam() {
    this.props.clearTeam();
  }

  removeStaff(assignee) {
    const { assignees } = this.props;
    const newTeam = {};

    Object.keys(assignees).map(
      // eslint-disable-next-line no-return-assign
      (item) => (newTeam[item] = assignees[item])
    );
    delete newTeam[`${assignee}`];

    this.props.updateTeam(newTeam);
  }

  finishSelectionTeam() {
    this.setState({ openStaffList: false });
    if (this.props.saveTeamAfterChosen) {
      this.props.handleConfirmTeam();
    }
  }

  render() {
    const {
      classes,
      submitStaffForm,
      translate,
      visitDetails,
    } = this.props;

    const { openStaffList, openNewStaff, staffs } = this.state;
    return (
      <Grid container alignItems="center">
        <Button
          className={classes.assignButton}
          variant="contained"
          size="small"
          onClick={this.handleAssignStaff}
        >
          {translate('Job:assign')}
        </Button>
        {visitDetails && visitDetails.team && visitDetails.team.length ? (
          visitDetails.team.map((assignee) => {
            return (
              <Chip
                key={assignee.id}
                className={classes.teamChip}
                label={assignee.fullName}
                onDelete={() => {
                  this.removeStaff(assignee.id);
                }}
              />
            );
          })
        ) : (
          <Typography className={classes.noneAssignText}>
            {translate('Job:noUsersAssigned')}
          </Typography>
        )}
        {openStaffList && (
          <SelectTeamModal
            staffs={staffs}
            open={openStaffList}
            handleCloseSelectTeamModal={this.handleCloseSelectTeamModal}
            finishTeamSelection={this.finishSelectionTeam}
            handleCreateUser={this.handleCreateStaff}
            handleClearTeam={this.handleClearTeam}
            translate={translate}
            isFromCalendar
          />
        )}
        {openNewStaff && (
          <CreateStaffModal
            open={openNewStaff}
            onSubmit={this.handleCreateNewStaff}
            handleClick={submitStaffForm}
            handleClose={this.handleCreateClose}
            translate={translate}
          />
        )}
      </Grid>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(TeamCardStyle)
)(TeamCard);

export default theme => ({
  root: {},
  teamDialogTitle: {
    minWidth: 300,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  teamDialogTitleText: {
    fontWeight: 700
  },
  teamCheckbox: {
    color: theme.palette.primary.main,
  },
  teamDialogAction: {
    justifyContent: 'space-between',
  },
  noneAssignText: {
    fontWeight: 400,
    marginBottom: 30,
  },
  btnGroup: {
    paddingRight: 24
  },
  customizedInput: {
    '& fieldset': {
      border: 'none'
    },
    '& input, select, textarea': {
      color: 'black'
    }
  }
});
export default {
  address: "ที่อยู่",
  archive: "จัดเก็บ",
  backTo: "กลับไปยัง: ",
  cancel: "ยกเลิก",
  comingSoon: "เร็วๆนี้...",
  close: "ปิด",
  delete: "ลบ",
  edit: "แก้ไข",
  email: "อีเมล",
  emailSms: "อีเมลและ SMS",
  login: "ล็อกอิน",
  logout: "ออกจากระบบ",
  markAs: "เปลี่ยนสถานะเป็น...",
  moreActions: "รายการอื่นๆ",
  name: "ชื่อ",
  phone: "โทรศัพท์",
  phoneNumber: "เบอร์โทรศัพท์",
  signup: "ลงทะเบียน",
  signin: "เข้าสู่ระบบ",
  sms: "SMS",
  unarchive: "ยกเลิกการจัดเก็บ",
  WM: "WorkPulse",
  and: "และ",
  or: "หรือ",
  contactNo: "เบอร์ติดต่อ",
  saveAnd: "บันทึกและ...",
  description: "รายละเอียด",
  done: "สำเร็จ",
  uppercaseDelete: "ลบ",
  save: "บันทึก",
  submit: "ส่ง",
  of: "ของ",
  rows: "แถว",
  firstPage: "หน้าแรก",
  previousPage: "หน้าก่อน",
  nextPage: "หน้าถัดไป",
  lastPage: "หน้าสุดท้าย",
  search: "ค้นหา",
  actions: "รายการ",
  ok: "ตกลง",
  active: "ใช้งานอยู่",
  archived: "จัดเก็บแล้ว",
  approve: "Approve",
  poweredBy: "Powered by",
  // FORMS
  // titles
  mr: "นาย",
  mrs: "นาง",
  ms: "นางสาว",
  miss: "นางสาว",
  dr: "ดร.",

  // address
  billingAddress: "ที่อยู่ในการออกใบแจ้งหนี้/ใบกำกับภาษี",
  city: "จังหวัด",
  country: "ประเทศ",
  countryCode: "รหัสประเทศ",
  postalCode: "รหัสไปรษณีย์",
  propertyAddress: "ที่อยู่ปัจจุบัน",
  billingAddressSamePropertyAddress:
    "ที่อยู่ในการออกใบแจ้งหนี้/ใบกำกับภาษีเป็นที่อยู่เดียวกับที่อยู่ปัจจุบัน",
  street1: "ที่อยู่ 1",
  street2: "ที่อยู่ 2",
  state: "อำเภอ/เขต",
  streetAddress: "ที่อยู่",
  gpsCoordinates: "พิกัด GPS",
  unitNumber: "เลขที่บ้าน และชื่ออาคาร",
  longitude: "ลองจิจูด",
  latitude: "ละติจูด",
  additionalDetails: "รายละเอียดเพิ่มเติม",

  // gender
  gender: "เพศ",
  male: "ชาย",
  female: "หญิง",
  other: "อื่นๆ",

  // company settings
  businessManagement: "การจัดการธุรกิจ",
  businessSettings: "ตั้งค่าบริษัท",
  contactEmail: "อีเมลผู้ติดต่อ",
  contactPhoneNumber: "เบอร์โทรศัพท์ผู้ติดต่อ",
  companyAddress1: "ที่อยู่บริษัท 1",
  companyAddress2: "ที่อยู่บรรทัดที่ 2 (ไม่บังคับ)",
  companyType: "ชนิดของบริษัท",
  taxNumbers: "เลขประจำตัวผู้เสียภาษีอากร",
  taxNumber: "เลขประจำตัวผู้เสียภาษีอากร",
  addTaxNumber: "เพิ่มเลขประจำตัวผู้เสียภาษีอากร",
  taxRates: "อัตราภาษี",
  percentage: "เปอร์เซ็นต์",
  addTaxRate: "เพิ่มอัตราภาษี",
  invoiceMessageTemplate: "แบบร่างข้อความในใบแจ้งหนี้/ใบกำกับภาษี",

  // others
  additionalNotes: "บันทึกเพิ่มเติม",
  addPhoneNumber: "เพิ่มเบอร์โทรศัพท์มือถือ",
  addEmail: "เพิ่มอีเมล",
  clientDetails: "ข้อมูลลูกค้า",
  companyNamePrimary: "ใช้ชื่อบริษัทเป็นชื่อหลัก",
  companyName: "ชื่อบริษัท",
  companyNameOptional: "ชื่อบริษัทไม่บังคับใส่",
  confirmNewPassword: "ยืนยันรหัสผ่านใหม่",
  confirmPassword: "ยืนยันรหัสผ่าน",
  firstName: "ชื่อ",
  lastName: "นามสกุล",
  mobileNumber: "เบอร์โทรศัพท์มือถือ",
  newPassword: "รหัสผ่านใหม่",
  organisationName: "ชื่อองค์กร",
  password: "รหัสผ่าน",
  receiveSms: "เบอร์นี้สามารถรับ SMS ได้",
  yourEmail: "อีเมลของคุณ",
  yourPassword: "รหัสผ่านของคุณ",
  confirmYourPassword: "ยืนยันรหัสผ่านของคุณ",
  yourCountry: "ประเทศของคุณ",
  yourMobileNumber: "เบอร์โทรศัพท์มือถือของคุณ",
  title: "คำนำหน้า",
  type: "ชนิด",
  products: "สินค้า",
  services: "สินค้า",
  companyOrganisationName: "ชื่อบริษัท/องค์กร",
  yourName: "ชื่อ-นามสกุล",
  enterMessage: "ใส่ข้อความ",
  job: "งาน",
  invoice: "ใบแจ้งหนี้/ใบกำกับภาษี",
  jobs: "งาน",
  invoices: "ใบแจ้งหนี้/ใบกำกับภาษี",
  property: "สถานที่",
  createNew: "สร้างใหม่",
  new: "ใหม่",
  status: "สถานะ",
  settings: "ตั้งค่า",
  payment: "บัญชี",
  client: "ลูกค้า",
  info: "ข้อมูล",
  notes: "บันทึก",
  send: "ส่ง",
  forDemoOnly: "สำหรับการสาธิตเท่านั้น",
  sendEmail: "ส่งอีเมล",
  sendingEmail: "Sending Email...",
  sendSms: "ส่ง SMS",
  sendingSms: "Sending SMS..",
  sendInvoiceToEmail: "อีเมลใบแจ้งหนี้/ใบกำกับภาษีไปยัง...",
  sendQuoteToEmail: "Email Quote to...",
  sendReceiptToEmail: "อีเมลใบเสร็จรับเงิน/ใบกำกับภาษีไปยัง...",
  smsInvoiceTo: "SMS ใบแจ้งหนี้/ใบกำกับภาษีไปยัง...",
  smsQuoteTo: "SMS Quote to...",
  paymentAppliedToInvoice: "รับชำระเงินแล้วสำหรับใบแจ้งหนี้/ใบกำกับภาษี",
  noAddressRecorded: "ไม่พบที่อยู่ที่บันทึกไว้",
  noEmailRecorded: "ไม่พบอีเมลที่บันทึกไว้",
  noPhoneRecorded: "ไม่พบเบอร์โทรศัพท์ที่บันทึกไว้",
  noEmailPhoneRecorded: "ไม่พบอีเมลหรือเบอร์โทรศัพท์ที่บันทึกไว้",
  sendNotification: "ส่งการแจ้งเตือน",
  assignJob: "มอบหมายงาน",
  message: "ข้อความ",
  convertToJob: "สร้างงาน",
  approveAnd: "Approve and...",

  all: "ทั้งหมด",
  accept: "ยอมรับ",
  sunday: "วันอาทิตย์",
  monday: "วันจันทร์",
  tuesday: "วันอังคาร",
  wednesday: "วันพุธ",
  thursday: "วันพฤหัส",
  friday: "วันศุกร์",
  saturday: "วันเสาร์",
  dropFileToUpload: "วางไฟล์ที่นี่หรือคลิกเพื่ออัพโหลด",
  workSettings: "การตั้งค่าเกี่ยวกับงาน",
  accountSettingTitle: "ข้อมูลส่วนตัวและการตั้งค่า",
  accountSetting: "การตั้งค่าบัญชี",
  founder_ceo: "ผู้ก่อตั้ง/ประธาน",
  director: "กรรมการ",
  senior_manager: "ผู้บริหาร",
  manager: "ผู้จัดการ",
  supervisor: "ผู้ดูแล",
  associate_or_xecutive: "พนักงาน",
  other_employee: "อื่นๆ",
  english: "อังกฤษ",
  thailand: "ประเทศไทย",

  // filter by date
  last30Days: "30 วันที่ผ่านมา",
  thisMonth: "ภายในเดือนนี้",
  lastMonth: "ภายในเดือนก่อน",
  thisYear: "ภายในปีนี้",
  custom: "ออกเมื่อ",
  issuedAt: "ออกเมื่อ",
  clientLogin: "ล็อกอิน",
  number: "Number",
  team: "TEAM",
  jobInfo: "JOB INFO",
  recordPayment: "Record Payment",
  completed: "Action completed",
  back: "Back",
  filter: "Filter",
  apply: "Apply",
  clear: "Clear",
  assignedWorker: "Assigned Worker",
  on: "On",
  off: "Off",
  createdOn: "Create On",
  scheduledOn: "Scheduled On",
  cantFindWorker: "Can't find the worker you're looking for?",
  tryToSearch: "Try typing the worker's name to search.",
  allDates: "All dates",
  add: "Add",
  category: "Category Tag",
  selectCategory: "Select category tag",
  discard: "Discard",
  searchCategory: "Search category tag",
  deactivated: "Deactivate",
  role: "Role",

  propertyName: "Property Name",
  propertyPlaceholder: "Property Name (Home/Office/School...)",
  dateFormat: "MMM DD, YYYY",
  dateTimeFormat: "MMM DD, YYYY - HH:mm",
  timeFormat: "HH:mm",
  download: "Download",
  complete: "Complete",
  textCopied: "Copied",
  export: "Export",
  someThingWentWrong: "Something Went Wrong",
  "*": "*",
  completionTime: "Completion Time",
  model: "Model.",
  copy: "Copy",
  view: "View",
  generalInfo: "General Info",
  enterpriseUser: "Enterprise Users",
  warehouse: "Warehouse",
  service: "Service",
  product: "Product",
  verifiedJobsOnly: "Verified Jobs Only",
};

import './index.scss';
import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import TextInput from 'components/inputs/TextInput';
import Countries from 'lib/countryList';
import { SelectBox } from 'components/inputs/SelectBox';
import { EMAIL_VALIDATION } from 'common/constant';

const countryPhoneCodeOptions = new Countries().countryPhoneCodes();

let ContactForm = props => {
  const { handleSubmit, translate } = props;

  return (
    <form noValidate className="contact-form-content" onSubmit={handleSubmit}>
      <div className="input-group">
        <Grid item container xs={12} sm={12}>
          <Typography className="compulsory-title">{translate('CompanySetting:compulsoryField')}</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Field
              name="email"
              type="email"
              component={TextInput}
              label={translate('Common:yourEmail')}
              margin="dense"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="name"
              type="text"
              component={TextInput}
              label={translate('Common:yourName')}
              margin="dense"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="countryPhoneCode"
              component={SelectBox}
              label={translate('Common:countryCode')}
              fullWidth
              required
              options={countryPhoneCodeOptions}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="phoneNumber"
              type="number"
              component={TextInput}
              label={translate('Common:yourMobileNumber')}
              margin="dense"
              fullWidth
              required
              onKeyDown={e => /[+\-.,eE]$/.test(e.key) && e.preventDefault()}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              margin="dense"
              name="company"
              required
              label={translate('Common:companyOrganisationName')}
              fullWidth
              component={TextInput}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              id="message"
              type="message"
              name="message"
              required
              margin="dense"
              component={TextInput}
              fullWidth
              multiline
              rows="3"
              rowsMax="5"
              label={translate('Common:enterMessage')}
            />
          </Grid>
          <Grid item xs={12} sm={12} className="form-footer">
            <Button className="medium-btn pink-btn" variant="contained" type="submit">{translate('sendMessage')}</Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  initialValues: {
    name: null,
    email: null,
    company: null,
    message: null,
    countryPhoneCode: new Countries().getCountryPhoneCodeByCountryCode(state.auth.geoCountry.countryCode) || null,
    phoneNumber: null
  }
});

const validate = values => {
  let errors = {};

  const requiredFields = [
    'email',
    'name',
    'countryPhoneCode',
    'phoneNumber',
    'company',
    'message',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = `${field}Required`;
    }
  });

  if (
    values.email &&
    !EMAIL_VALIDATION.test(values.email)
  ) {
    errors = {
      ...errors,
      email: 'invalidEmail',
    };
  }

  return errors;
};

ContactForm = reduxForm({ form: 'contactUsForm', validate })(ContactForm);
export default connect(mapStateToProps, null)(ContactForm);


// import { setIsLoading } from '../redux/global';
import { POST, GET, PATCH } from 'lib/api';

const initialState = {
  quote: {},
  modalVisibility: false,
  modalType: null,
  isSaving: false,
};

const SET_CURRENT_QUOTE = 'SET_CURRENT_QUOTE';
const SET_CLEAR_QUOTE = 'SET_CLEAR_QUOTE';
const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';
const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
const SET_IS_SAVING = 'SET_IS_SAVING';

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_QUOTE:
      return {
        ...state,
        quote: {
          ...action.quote,
        }
      };

    case SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.client
      };

    case SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.property
      };

    case SET_MODAL_VISIBILITY:
      return {
        ...state,
        modalVisibility: action.visibility,
        modalType: action.modalType,
      };

    case SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.isSaving
      };

    case SET_CLEAR_QUOTE:
      return {
        ...state,
        quote: {},
        modalVisibility: false,
      };

    default: return state;
  }
}

// Action Creators
export function setCurrentQuote(quote) {
  return { type: SET_CURRENT_QUOTE, quote };
}

export function clearQuote() {
  return { type: SET_CLEAR_QUOTE };
}

export function setSelectedClient(client) {
  return { type: SET_SELECTED_CLIENT, client };
}

export function setSelectedProperty(property) {
  return { type: SET_SELECTED_PROPERTY, property };
}

export function setModalVisibility(visibility, modalType) {
  return { type: SET_MODAL_VISIBILITY, visibility, modalType };
}

export function setIsSaving(isSaving) {
  return { type: SET_IS_SAVING, isSaving };
}

export function getQuote(id) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/quotes/${id}`, accessToken).then((response) => {
      if (response.status === 200) {
        dispatch(setCurrentQuote(response.data));
      };

      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}


export function getQuotes(params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return GET('/quotes', accessToken, params).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function addQuote(quoteData) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/quotes', accessToken, { data: quoteData })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

export function updateQuote(quoteData) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(`/quotes/${quoteData.id}`, accessToken, { data: quoteData })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

export function downloadPdf(quoteId) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const data = {
      quoteId,
      action: 'EXPORT_PDF'
    };
    return POST('/quote-action', accessToken, { data })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

export function emailQuote(quoteId, email) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const data = { quoteId, action: 'SEND_EMAIL', email };

    return POST('/quote-action', accessToken, { data })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
};

export function smsQuote(quoteId, countryPhoneCode, phoneNumber) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const data = { quoteId, action: 'SEND_SMS', countryPhoneCode, phoneNumber };

    return POST('/quote-action', accessToken, { data })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
};

export function copyQuote(quoteId, clientId, propertyId) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const data = { action: 'COPY', quoteId, clientId, propertyId };

    return POST('/quote-action', accessToken, { data })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Typography,
  IconButton,
  Box,
  Tooltip,
} from '@material-ui/core';
import { mediaActions } from 'redux/media';
import { showErrorMessage } from 'lib/notifier';

import styles from '../styles.module.scss';

import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import VisitMenu from './VisitMenu';

const VisitAssign = (props) => {
  const { mediaDetails, setMediaDetails, getMediaDetails, fetchVisits } = props;
  const { t: translate } = useTranslation('Job');
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRemoveVisit = async () => {
    setIsLoading(true);
    const result = await dispatch(
      mediaActions({
        isRemoveVisit: true,
        action: 'ASSIGN_OR_REMOVE_MEDIA_TO_VISIT',
        mediaIds: [mediaDetails.id],
      })
    ).catch((e) => ({ e }));
    await fetchVisits();
    setIsLoading(false);
    if (result.e) return showErrorMessage(result.e);
    const { visit, ...other } = mediaDetails;
    setMediaDetails({ ...other, ...result.data[0] });
  };

  return (
    <Box
      width="100%"
      className={`${styles.evalSpacing} ${
        mediaDetails.visit?.publicId ? '' : styles.hover
      }`}
    >
      <Box
        display="flex"
        onClick={!mediaDetails.visit?.publicId && handleClick}
        width="100%"
      >
        <ErrorOutlineOutlinedIcon />
        <Box style={{ flex: 1 }} display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" className="ml_10">
            <Typography className={styles.evaluation}>
              {translate('visit')}
            </Typography>

            <Box display="flex" flexDirection="column" mt={1}>
              <Typography className={styles.visit_text}>
                {mediaDetails.visit && mediaDetails.visit.publicId}
              </Typography>
              <Typography className={styles.visit_text}>
                {mediaDetails.visit && mediaDetails.visit.title}
              </Typography>
            </Box>
          </Box>

          {mediaDetails.visit?.publicId && (
            <Box mr={2}>
              <IconButton disabled={isLoading} onClick={handleRemoveVisit} style={{ padding: 0 }}>
                <Tooltip title={translate('remove')}>
                  <CloseIcon />
                </Tooltip>
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      {anchorEl && (
        <VisitMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          translate={translate}
          job={mediaDetails.job}
          mediaIds={[mediaDetails.id]}
          dispatch={dispatch}
          fetchVisits={fetchVisits}
          getMediaDetails={getMediaDetails}
        />
      )}
    </Box>
  );
};


export default VisitAssign;

import React, { useState, useEffect } from 'react';

// Externals
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, arraySplice } from 'redux-form';

// Material components
import { Typography, TextField, MenuItem, Paper, withStyles } from '@material-ui/core';

// Component styles
import { ServiceProductInputStyle } from './styles';
import { OnlyDecimal } from 'components/converters';

const mapStateToProps = (state, ownProps) => {
  const requestForm = formValueSelector(ownProps.formName || 'requestForm');

  return {
    formValue: requestForm(state, 'products'),
    formName: ownProps.formName || 'requestForm',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addRequestItem: (index, item) => {
      dispatch(
        arraySplice(ownProps.formName || 'requestForm', 'products', index, 1, item)
      );
    },
  };
};

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="dense"
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSectionTitle(section) {
  return (
    <MenuItem disabled style={{ backgroundColor: 'lightGray', fontSize: 14 }}>
      <strong>{section.title.toUpperCase()}</strong>
    </MenuItem>
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 600 : 500, fontSize: 14 }}
            >
              {part.text}
            </span>
          );
        })}
        <Typography style={{ color: 'gray' }}>
          {suggestion.value.description}
        </Typography>
      </div>
    </MenuItem>
  );
}

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(data, value) {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(escapedValue, 'i');

  return data
    .map((section) => {
      const matchedSuggestions = section.suggestions.filter((suggestion) =>
        regex.test(suggestion.label)
      );
      return {
        title: section.title,
        suggestions: matchedSuggestions,
      };
    })
    .filter((section) => section.suggestions.length > 0);
}

function getSectionSuggestions(section) {
  return section.suggestions;
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

const ServiceProductInput = (props) => {
  const {
    classes,
    input,
    meta: { error },
    formValue,
    options,
    index,
    translate,
    addRequestItem,
  } = props;
  const [suggestions, setSuggestions] = useState([]);
  const value = (formValue && formValue[index] && formValue[index].name) || '';

  useEffect(() => {
    suggestions.length < 1 && setSuggestions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);

  function handleSuggestionsFetchRequested(item) {
    const suggestionOptions = getSuggestions(options, item.value);

    setSuggestions(suggestionOptions);
  }

  const handleSuggestionsClearRequested = () => {
    setSuggestions(options);
  };

  const handleShouldRenderSuggestions = () => {
    return suggestions.length > 0;
  };

  function handleUpdateItemFromSuggesstion(item) {
    if (!item) {
      return;
    }

    const unitPrice = item.unitPrice || '0.00';
    const taxRate = item.taxRate || {};
    const taxComponent = item.taxComponent || {};
    let itemTaxPercentage = 0;
    if (taxRate.percentage) {
      itemTaxPercentage = parseFloat(taxRate.percentage || 0);
    }
    if (taxComponent.taxRates) {
      itemTaxPercentage = (taxComponent.taxRates || []).reduce(
        (sum, taxItem) => {
          return sum + parseFloat(taxItem.percentage || 0);
        },
        0
      );
    }

    const taxAmount = (parseFloat(item.unitPrice) * itemTaxPercentage) / 100;
    const totalIncludeTax = parseFloat(item.unitPrice) + taxAmount;

    const existedItem = formValue && formValue[index];
    const newItem = {
      taxRate,
      taxComponent,
      totalIncludeTax,
      description: item.description,
      quantity: 1,
      taxRateId: taxRate.id,
      name: item.name,
      unitPrice: OnlyDecimal(unitPrice),
      total: OnlyDecimal(unitPrice),
      currency: item.currency,
      ...(taxComponent ? { taxComponentFk: taxComponent.pk } : {}),
      inventories: (existedItem && existedItem.inventories) || [{
        name: '',
        serialNumber: '',
        model: '',
        quantity: 1,
      }]
    };

    addRequestItem(index, newItem);
  }

  const handleChange = () => (event, { newValue }) => {
    if (newValue === undefined) {
      return;
    }

    const item = newValue.value || newValue;

    if (item.constructor === Object) {
      handleUpdateItemFromSuggesstion(item);
    }

    input.onChange(item.name || item);
  };
  return (
    <div className={classes.root}>
      <Autosuggest
        multiSection
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        shouldRenderSuggestions={handleShouldRenderSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={renderInputComponent}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        getSectionSuggestions={getSectionSuggestions}
        inputProps={{
          classes,
          id: 'react-autosuggest-simple',
          placeholder: translate('Common:search'),
          value,
          onChange: handleChange(),
          error: !!error,
          helperText: error || null,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={(options) => (
          <Paper {...options.containerProps} square className={classes.suggestionModal}>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
};

export default compose(
  withStyles(ServiceProductInputStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(ServiceProductInput);

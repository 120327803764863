export default theme => ({
  root: {},
  title: {
    fontWeight: 600,
    marginBottom: 10,
  },
  textDetails: {
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-line'
  },
  textDetailsBold: {
    fontWeight: 600,
  },

  changeText: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    '&:hover': theme.palette.primary.main
  },
  noAddress: {
    fontSize: 11,
    color: theme.palette.text.grey700,
  }
});

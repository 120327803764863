import React, { useState, useEffect } from 'react';

// Externals
import { withRouter } from 'react-router-dom';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { setModalVisibility, setIsSaving } from 'redux/quote';
import { saveClientDetails } from 'redux/client';
import { addProperty, getProperties } from 'redux/property';

// Material helpers
import { withStyles } from '@material-ui/core';

// Customized components
import { CreateClientModal } from 'pages/Dashboard/views/Jobs/components/modals';
import { showErrorMessage } from 'lib/notifier';
import { SelectClientModal, SelectPropertyModal, CreatePropertyModal } from 'pages/Dashboard/components';

// Component styles
import styles from './styles/SelectClientStyle';

const mapStateToProps = state => ({
  quote: state.form.quote && state.form.quote.values,
  modalVisibility: state.quote.modalVisibility,
  modalType: state.quote.modalType,
});

const mapDispatchToProps = dispatch => ({
  addProperty: (values, callback) => dispatch(addProperty(values, callback)),
  getProperties: (clientId, callback) => dispatch(getProperties(clientId, callback)),
  saveClientDetails: (values, callback) => dispatch(saveClientDetails(values, callback)),
  setIsSaving: (value) => { dispatch(setIsSaving(value)); },
  setModalVisibility: (visibility, type) => dispatch(setModalVisibility(visibility, type)),
  submitNewProperty: () => dispatch(submit('newProperty')),
});

function CopyQuote(props) {
  const {
    modalVisibility,
    modalType,
    submitNewProperty,
    submitCopyQuote,
    handleCloseCopyQuote,
    translate,
  } = props;
  const [openSelectClient, setOpenSelectClient] = useState(true);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [openSelectProperty, setOpenSelectProperty] = useState(false);
  const [openCreateProperty, setOpenCreateProperty] = useState(false);
  // select client & property
  const [selectedClient, setSelectedClient] = useState(null);
  // eslint-disable-next-line
  const [selectedProperty, setSelectedProperty] = useState(null);


  useEffect(() => {
    if (modalType === 'client') {
      setOpenSelectClient(modalVisibility);
    } else if (modalType === 'property') {
      setOpenSelectProperty(modalVisibility);
    };
    // props.resetQuoteForm();

  }, [modalVisibility, modalType]);

  function handleClose(type) {
    if (type === 'select') {
      setOpenSelectClient(false);
      setOpenSelectProperty(false);
    } else if (type === 'create') {
      setOpenCreateClient(false);
      setOpenCreateProperty(false);
    }
    props.setModalVisibility(false, null);
    props.setIsSaving(false);
    handleCloseCopyQuote();
  };

  function handleClientSelect(client) {
    const { id, displayName, properties, emails, phoneNumbers } = client;

    const contactEmail = emails.length > 0 && emails.find(mail => mail.typeId === 'MAIN');
    const contactPhoneNumber = phoneNumbers.length > 0 && phoneNumbers.find(phone => phone.typeId === 'MAIN');
    const username = displayName;
    if (id && username) {
      setSelectedClient({
        id,
        name: username,
        contactEmail,
        contactPhoneNumber,
      });
    };

    if (properties && properties.length > 0) {
      if (properties.length < 2) {
        const property = properties[0];
        setSelectedProperty(property);
        setOpenSelectClient(false);
        props.setModalVisibility(false, null);
        // Submit and close CopyQuote component
        submitCopyQuote(client, property);
        handleCloseCopyQuote();
      } else if (properties.length > 1) {
        setOpenSelectProperty(true);
        setOpenSelectClient(false);
        props.setModalVisibility(false, null);
      };
    } else if (properties.length <= 0) {
      setOpenCreateProperty(true);
      setOpenSelectClient(false);
      props.setModalVisibility(false, null);
    };
  };

  function handleAddNewClient() {
    setOpenSelectClient(false);
    setOpenCreateClient(true);
    props.setModalVisibility(false, null);
  };

  function handlePropertySelect(property) {
    setSelectedProperty(property);
    setOpenSelectProperty(false);
    props.setModalVisibility(false, null);
    // Submit and close CopyQuote component
    submitCopyQuote(selectedClient, property);
    handleCloseCopyQuote();
  };

  function handleAddNewProperty() {
    setOpenSelectProperty(false);
    setOpenCreateProperty(true);
    props.setModalVisibility(false, null);
  };

  function prepareClientDataBeforeSave(values) {
    const { properties } = values;

    const propertyAsBillingAddress = properties.find(item => item.isBillingAddress);
    if (propertyAsBillingAddress) {
      delete propertyAsBillingAddress['isBillingAddress'];
      Object.assign(values, { billingAddress: propertyAsBillingAddress });
    }

    return values;
  };

  function handleSubmitNewClient(client) {
    const clientDetails = prepareClientDataBeforeSave(client);

    props.saveClientDetails(clientDetails, (response) => {
      if (response.status === 200) {
        setOpenCreateClient(false);
        handleClientSelect(response.data);
      } else {
        showErrorMessage(response.data.message);
      };
    });
  };

  function handleSubmitNewProperty(values) {
    const callback = (newProperty) => {
      setSelectedProperty(newProperty);
      setOpenCreateProperty(false);
      setOpenSelectProperty(false);
      // Submit and close CopyQuote component
      submitCopyQuote(selectedClient, newProperty);
      handleCloseCopyQuote();
    };

    values.ownerId = selectedClient.id;

    props.addProperty(values, callback);
  };

  return (
    <>
      {
        openSelectClient &&
        <SelectClientModal
          open={openSelectClient}
          handleClose={() => handleClose('select')}
          handleClientSelect={client => handleClientSelect(client)}
          handleAddNewClient={handleAddNewClient}
          translate={translate}
        />
      }
      {
        openCreateClient &&
        <CreateClientModal
          open={openCreateClient}
          handleClose={() => handleClose('create')}
          handleSubmit={(value) => handleSubmitNewClient(value)}
          handleCancelClientForm={() => handleClose('create')}
          translate={translate}
        />
      }
      {
        openSelectProperty &&
        <SelectPropertyModal
          open={openSelectProperty}
          selectedClient={selectedClient}
          handleClose={() => handleClose('select')}
          handlePropertySelect={property => handlePropertySelect(property)}
          handleAddNewProperty={handleAddNewProperty}
          translate={translate}
        />
      }
      {
        openCreateProperty &&
        <CreatePropertyModal
          open={openCreateProperty}
          selectedClient={selectedClient}
          handleClick={submitNewProperty}
          handleClose={() => handleClose('create')}
          onSubmit={handleSubmitNewProperty}
          translate={translate}
        />
      }
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CopyQuote);
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { showErrorMessage, showInfoMessage } from 'lib/notifier';
import { LoadingModal, Portlet, PortletContent, PortletHeader, PortletLabel } from '../../../components';
import { Button, Container, Dialog, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import styles from './styles.module.css';
import { getPaymentMethod, getSubscription,
  // activateAccount
} from 'api/subscriptionApi';
import PaymentMethod from '../PaymentMethod';
import SubscriptionPlan from '../SubscriptionPlan';
import Cookie from 'js-cookie';

function ManageSubscription(props) {
  const { translate } = props;
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [plans, setPlans] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [showCardInput, setShowCardInput] = useState(false);
  const [showSubscriptionPlanModal, setShowSubscriptionPlanModal] = useState(false);

  const hasBillingMethod = paymentMethod && Object.keys(paymentMethod).length > 0;

  async function getPayment() {
    const res = await getPaymentMethod();
    setPaymentMethod(res.data);
    setShowCardInput(false);
  }

  async function fetchSubscription() {
    try {
      setLoading(true);
      const res = await getSubscription();
      setPlans(res.data);
      const subscriptionData = res.data.subscription;
      setSubscription(res.data.subscription);
      await getPayment();

      setLoading(false);
      if (!subscriptionData.planCode || moment(subscriptionData.billingDue).isBefore(moment())) {
        setShowSubscriptionPlanModal(true);
      }
      const paymentWarning = Cookie.get('paymentWarning');
      if (paymentWarning) {
        showInfoMessage(paymentWarning);
        Cookie.remove('paymentWarning');
      }
    } catch (error) {
      setLoading(false);
      showErrorMessage(error);
    }
  }

  // async function handleDeactivateAccount() {
  //   try {
  //     setLoading(true);
  //     const res = await activateAccount({ active: !subscription.active });
  //     if (res.data) setSubscription(res.data);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     showErrorMessage(error);
  //   }
  // }

  useEffect(() => {
    fetchSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleEdit() {
    console.log('showCardInput', showCardInput)
    setShowCardInput(!showCardInput);
  }

  const isTrialLeft = subscription && (new Date(subscription.trialEnd) > new Date())
    && (!hasBillingMethod || !subscription.planCode);
  const trialDayLeft = isTrialLeft
    ? moment(subscription.trialEnd).diff(moment(new Date()), 'days')
    : null;

  return (
    <Container className={styles.container}>
      <Typography variant='h2' className={styles.title}>
        {translate('planBilling')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          { subscription && <Portlet>
            <PortletHeader>
              <PortletLabel title={translate('accountOverview')} />
            </PortletHeader>
            <PortletContent>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <div>{translate('activeSince')}</div>
                  <div className={styles.status}>{moment(subscription.createdAt).format('DD/MM/YYYY')}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div>{translate('accountStatus')}</div>
                  <div className={styles.status}>
                    {subscription.active ? translate('active') : translate('inactive')}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div>{translate('currentPlan')}</div>
                  <div className={styles.status}>
                    {subscription.planCode || 'PRO'} { isTrialLeft && `(${translate('trial')})`}
                  </div>
                </Grid>
                {isTrialLeft && <Grid item xs={12} sm={6}>
                  <div>
                    {trialDayLeft > 0 && translate('trialWillEnd' , { plan: 'PRO', time: trialDayLeft })}
                    {trialDayLeft <= 0 && translate('trialWasEnd' , { plan: 'PRO' })}
                  </div>
                </Grid>}
                <Grid item xs={12} sm={6}>
                  <div>{translate('nextBillingDate')}</div>
                  <div className={styles.status}>
                    {moment(subscription.billingDue).format('DD/MM/YYYY')}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div>
                    <span className={styles.bold}>{subscription.activeUserCount} {translate('userNum')} </span>
                    {translate('onYourPlan')}
                  </div>
                </Grid>
              </Grid>

              <Button
                className={styles.planBtn}
                color="primary"
                size="small"
                variant="contained"
                onClick={() => setShowSubscriptionPlanModal(true)}
              >
                {translate('changePlan')}
              </Button>

            </PortletContent>
          </Portlet>
          }
          {paymentMethod && <Portlet style={{ marginTop: 24 }}>
            <LoadingModal open={loading} handleClose={() => setLoading(false)} />
            <PortletHeader>
              <PortletLabel title={translate('billingDetails')} />
            </PortletHeader>
            <PortletContent>
              { paymentMethod.card ?
                <div className={styles.portletContainer}>
                  <div className={styles.cardBrand}>
                    <div>{paymentMethod.card.brand}</div>
                  </div>
                  <div>
                    <div>{translate('cardNumber')}</div>
                    <div> *** {paymentMethod.card.last4}</div>
                  </div>
                  <div>
                    <div>{translate('expiresOn')}</div>
                    <div>{paymentMethod.card.exp_month} / {paymentMethod.card.exp_year}</div>
                  </div>
                </div>
                : <div>{translate('pleaseInputCard')}</div>}
              <div style={{ marginTop: 16 }}>
                <Button variant="contained" color="secondary" onClick={handleEdit}>
                  {hasBillingMethod ? translate('edit') : translate('addCard')}
                </Button>
              </div>
            </PortletContent>

          </Portlet>
          }
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          { subscription && <Portlet>
            <PortletHeader>
              <PortletLabel title={translate('deactivateAccount')} />
            </PortletHeader>
            <PortletContent>
              <Button
                className={styles.deactivateBtn}
                color="primary"
                size="small"
                variant="contained"
                disabled={subscription.trial}
                onClick={handleDeactivateAccount}
              >
                {subscription && subscription.active ? translate('deactivate') : translate('activate')}
              </Button>
              <div>{subscription && subscription.trial && translate('deactivateTrial')}</div>
            </PortletContent>
          </Portlet>
          }
        </Grid> */}
      </Grid>
      <Dialog onClose={() => setShowCardInput(false)} open={showCardInput}>
        <div className={styles.payment}>
          <PaymentMethod
            getPayment={getPayment}
            setLoading={setLoading}
            closePaymentMethodDialog={() => setShowCardInput(false)}
            closeSubscriptionPlanModal={() => setShowSubscriptionPlanModal(false)}
            translate={translate}
          />
        </div>
      </Dialog>

      <Dialog
        maxWidth='xl'
        fullWidth
        onClose={() => setShowSubscriptionPlanModal(false)}
        open={showSubscriptionPlanModal}
      >
        <div className={styles.subscriptionPlanModal}>
          <SubscriptionPlan
            hasBillingMethod={hasBillingMethod}
            plans={plans}
            showPaymentMethodDialog={() => setShowCardInput(true)}
            closeSubscriptionPlanModal={() => setShowSubscriptionPlanModal(false)}
            translate={translate}
          />
        </div>
      </Dialog>
    </Container>
  );
}

const mapState = (state) => ({
  companyDetail: state.company.details,
});

const mapDispatch = () => ({});

export default compose(
  connect(mapState, mapDispatch),
)(ManageSubscription);

export default {
  dontHaveAccount: "Interested to use WorkPulse?",
  forFree: "Register here",
  continueAgree: "By continuing, you agree to our",
  termsService: "Terms of Service",
  and: "and",
  privacyPolicy: "Privacy Policy",
  startFreeTrial: "Start Your Free Trial",
  featuresSupportNoCard:
    "All the features, all the support, and no credit card required",
  haveAccount: "Already have an account?",
  createAccount: "Create Account",
  forgotPassword: "Forgot Password?",
  resetEmailMsg: "An email to reset your password has been sent.",
  resetEmail:
    "Enter the email address you registered with to reset your password",
  resetPassword: "Reset Password",
  passwordResetSuccess:
    "Password has been successfully reset. Please log in with your new password.",
  goBack: "< Go back",
  backSignIn: "< Back to Sign In",
  enterNewPassword: "Please enter your new password",
  savePassword: "Save New Password",
  setupTitle: "GREAT, LET'S GET YOU SET UP",
  setupSubtitle:
    "The more we know, the better we can help you customize your new WorkPulse system.",
  businessStartYear: "What year did you start your business?",
  roleInCompany: "Your role in the company?",
  findOutABoutWM: "How did you find out about WorkPulse?",
  companySize: "Company size",
  includeYourself: "Please include yourself",
  selectIndustry: "Select industry",
  selectOneOrMoreIndustries: "Please select one or more industries",
  otherIndustry: "Other Industry",
  inputIndustryIfNotListed: "Please input industry if it is not listed",
  saveFinish: "Save & Finish",
  hintPassword:
    "Password must be 8 characters and contain at least one letter, one number and no space.",
};

import React from 'react';
// Externals
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// Redux
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { addStaff } from 'redux/staff';

// Material helpers
import { withStyles } from '@material-ui/core';

// Custom components
import { StaffFormCard } from '../components/cards';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

const NewStaff = props => {
  const { classes, history, translate } = props;
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    const formData = new FormData();
    // Convert blob url to blob object to upload file
    if (data.avatar) {
      const fileBlob = data.avatar;
      const res = await fetch(fileBlob);
      const blob = await res.blob();
      formData.append('file', blob);
      data.avatar = '';
    }

    formData.append('staffInfo', JSON.stringify(data));
    try {
      const response = await dispatch(addStaff(formData));
      if (response.status === 200) {
        const staff = response.data;
        const staffName = [staff.firstName, staff.lastName].filter(Boolean).join(' ');
        showSuccessMessage(translate('addedSuccess', { staffName }));
        history.push('/teams');
      } else {
        showErrorMessage(response.data.message);
      }
    } catch (error) {
      showErrorMessage(error);
    }

  };

  return (
    <div className={classes.root}>
      <StaffFormCard onSubmit={handleSubmit} translate={translate} />
    </div>
  );
};

NewStaff.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(NewStaff);

export default {
  workSettings: 'การตั้งค่าเกี่ยวกับงาน',
  defaultInvoiceSetting: 'ตั้งค่าเริ่มต้นของใบแจ้งหนี้/ใบกำกับภาษี',
  defaultInvoiceSubject: 'หัวข้อใบแจ้งหนี้/ใบกำกับภาษี',
  defaultInvoiceNote: 'หมายเหตุ',
  defaultPaymentDue: 'ชำระเงินภายใน',
  defaultQuoteValidity: 'การชำระเงิน',
  defaultJobSetting: 'ตั้งค่าเริ่มต้นของงาน',
  defaultJobTitle: 'หัวข้องาน',
  defaultQuoteSetting: 'ตั้งค่าเริ่มต้นของใบเสนอราคา',
  defaultQuoteSubject: 'หัวข้อใบเสนอราคา',
  defaultQuoteNote: 'หมายเหตุ',
  updateQuoteSetting: 'อัปเดตการตั้งค่าใบเสนอราคา',
  updateJobSetting: 'อัปเดตการตั้งค่างาน',
  updateInvoiceSetting: 'อัปเดตการตั้งค่าใบแจ้งหนี้/ใบกำกับภาษี',
  updatedInvoiceSettingSuccessfully: 'อัปเดตการตั้งค่าใบแจ้งหนี้/ใบกำกับภาษีเรียบร้อย',
  updatedJobSettingSuccessfully: 'อัปเดตการตั้งค่างานเรียบร้อย',
  updatedQuoteSettingSuccessfully: 'อัปเดตการตั้งค่าใบเสนอราคาเรียบร้อย',
  day: 'วัน'
};

import React, { useState } from 'react';
import { Box, Card, Typography, Button } from '@material-ui/core';
import { Field } from 'redux-form';
import styles from '../card.module.scss';
import defaultAvatarSrc from 'assets/images/icons/default-avatar.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropertiesModal from './components/propertiesModal';
import { Link, withRouter } from 'react-router-dom';
import { showSuccessMessage } from 'lib/notifier';

const renderField = ({ input, label, customStyle }) => {
  if (!input.value) return null;
  return (
    <Box display='flex' flexDirection='column' justifyContent='center'>
      {label && (
        <Box>
          <Typography className={styles.grey_color}>{label}</Typography>
        </Box>
      )}
      <Typography className={customStyle}>{input.value}</Typography>
    </Box>
  );
};

const renderPropertyField = ({ input, label, translate, handleOpenModal }) => {
  if (!input.value || !Object.keys(input.value).length) return null;
  const { unitNumber, address } = input.value;
  const formattedAddress = unitNumber
    ? [unitNumber, address].join(', ')
    : address;
  const handleCopy = () => {
    navigator.clipboard.writeText(formattedAddress);
    showSuccessMessage(translate('Common:textCopied'));
  };
  return (
    <Box display='flex' flexDirection='column'>
      {label && (
        <Box justifyContent='space-between' display='flex' alignItems='center'>
          <Typography className={styles.grey_color}>{label}</Typography>
          <Button className={styles.view_all_btn} onClick={handleOpenModal}>
            {translate('Enterprise:viewAll')}
          </Button>
        </Box>
      )}
      <Box>
        <Typography className='font_bold'>{input.value.name}</Typography>
        <Typography>{unitNumber}</Typography>
        <Typography>{address}</Typography>
        <Typography
          onClick={handleCopy}
          variant='text'
          className={styles.btn_copy_address}
        >
          {translate('copy')}
        </Typography>
      </Box>
    </Box>
  );
};

const RequestDetails = (props) => {
  const { translate, requestDetails, history } = props;
  const [isOpenModalProperties, setIsOpenModalProperties] = useState(false);

  const handleOpenModal = () => setIsOpenModalProperties(true);
  const handleCloseModal = () => setIsOpenModalProperties(false);

  const clientId =
    requestDetails && requestDetails.client && requestDetails.client.id;

  return (
    <>
      {isOpenModalProperties && (
        <PropertiesModal {...props} handleClose={handleCloseModal} />
      )}
      <Card className={styles.card}>
        <Box p={2} display='flex' alignItems='center' flexDirection='column'>
          <img src={defaultAvatarSrc} alt='defaultAvatarSrc' />
          <Typography className={`${styles.grey_color} font_bold mt_5`}>
            {translate('clientDetailsUpper')}
          </Typography>
          <Field
            name='clientName'
            component={renderField}
            customStyle='font_bold'
          />
        </Box>
        <Box p={2}>
          <Field
            name='property'
            label={translate('propertyAddress')}
            translate={translate}
            component={renderPropertyField}
            handleOpenModal={handleOpenModal}
          />
          <Box mt={2}>
            <Field
              name='clientEmail'
              label={translate('Common:email')}
              component={renderField}
            />
          </Box>
          <Box mt={2}>
            <Field
              name='clientPhoneNumber'
              label={translate('Common:phoneNumber')}
              component={renderField}
            />
          </Box>
        </Box>
        {/* hide view client button */}
        {/* <Box p={2} pt={0}>
          <Link to={`/clients/${clientId}`} target="_blank">
            <Button
              fullWidth
              variant="contained"
              className={styles.btn_details}
            >
              <InfoOutlinedIcon className="mr_5" />
              {translate('details')}
            </Button>
          </Link>
        </Box> */}
      </Card>
    </>
  );
};

export default withRouter(RequestDetails);

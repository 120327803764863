import React, { useEffect } from 'react';
// Redux
import { connect } from 'react-redux';
import { change, reduxForm, formValueSelector, arraySplice } from 'redux-form';
import {
  CustomerInformationCard,
  DeviceInfoCard,
  JobImageCard,
  ServiceProvideCard,
  JobTrackingCard,
} from '../cards';
import { OnlyDecimal } from 'components/converters';
import { SERVICE_REPORT_FORM_NAME } from 'common/constant';
import moment from 'moment';

const serviceReportForm = formValueSelector(SERVICE_REPORT_FORM_NAME);
const ServiceReportTypePictorial = [
  'customer_info',
  'job_image',
  'job_tracking',
];
const ServiceReportTypeFull = [
  'customer_info',
  'device_info',
  'service_provide',
  'job_image',
  'job_tracking',
];

const DEFAULT_SERVICE_PROVIDE = [
  {
    name: '',
    description: '',
    quantity: 1,
    unitPrice: '0.00',
    total: '0.00',
  },
];

const mapStateToProps = (state, ownProps) => {
  const { isFromNewServiceReport, translate, reportDetails = {} } = ownProps;
  const {
    reportType,
    products,
    discountType,
    discountValue,
  } = serviceReportForm(
    state,
    'reportType',
    'products',
    'discountType',
    'discountValue'
  );
  let initial = {};
  const typeOfReport = reportType || reportDetails.reportType || 'FULL';

  const DEFAULT_DEVICE_INFO = [
    {
      modelNumber: '',
      serialNumber: '',
      serviceType: translate('serviceTypeSiteRepair'),
    },
  ];

  if (isFromNewServiceReport) {
    const {
      job: { client, jobSchedule, createdAt, property, description, items, team },
      visitReportGenerateFrom,
    } = state.job;

    // get team from visit first -> from job -> initial
    const teamsName =
      (visitReportGenerateFrom && visitReportGenerateFrom
        .team.map((item) => item.fullName )) ||
      (team && team.map((item) => item.fullName)) || [];
    initial = {
      customer: client.displayName,
      phoneNumber: client.phoneNumber || null,
      address: property && [property.unitNumber, property.address].filter(Boolean).join(', '),
      scheduleDate: jobSchedule && jobSchedule.start,
      requestDate: createdAt,
      jobDescription: description,
      deviceInfo: DEFAULT_DEVICE_INFO,
      products: items.length ? items : DEFAULT_SERVICE_PROVIDE,
      reportType: typeOfReport,
      actionTaken: null,
      evaluation: [],
      afterJob: [],
      timeOfCompletion:
        visitReportGenerateFrom &&
        visitReportGenerateFrom.completedAt &&
        moment(visitReportGenerateFrom.completedAt).format('YYYY-MM-DDTHH:mm'),
      timeArrival:
        visitReportGenerateFrom &&
        visitReportGenerateFrom.arrivedAt &&
        moment(visitReportGenerateFrom.arrivedAt).format('YYYY-MM-DDTHH:mm'),
      serviceBy: teamsName.join(','),
    };
  } else {
    const medias = reportDetails.medias || [];
    // split base on phase type
    const [evaluationMedia, afterJobMedia] = medias.reduce(
      // Use "deconstruction" style assignment
      (result, element) => {
        result[element.mediaPhase === '0' ? 0 : 1].push(element);
        return result;
      },
      [[], []]
    );

    initial = {
      deviceInfo: reportDetails.deviceInfo || DEFAULT_DEVICE_INFO,
      products:
        reportDetails.serviceItems && reportDetails.serviceItems.length > 0
          ? reportDetails.serviceItems
          : DEFAULT_SERVICE_PROVIDE,
      reportType: typeOfReport,
      customer: reportDetails.customer || null,
      phoneNumber: reportDetails.phoneNumber || null,
      address: reportDetails.address || null,
      scheduleDate: reportDetails.scheduledAt || null,
      requestDate: reportDetails.requestedAt || null,
      jobDescription: reportDetails.description || null,
      actionTaken: reportDetails.remark || null,
      serviceBy:
        reportDetails.workerTimelog && reportDetails.workerTimelog.worker,
      timeOfCompletion:
        reportDetails.workerTimelog &&
        reportDetails.workerTimelog.completion_at &&
        moment(reportDetails.workerTimelog.completion_at).format(
          'YYYY-MM-DDThh:mm'
        ),
      timeArrival:
        reportDetails.workerTimelog &&
        reportDetails.workerTimelog.arrival_at &&
        moment(reportDetails.workerTimelog.arrival_at).format(
          'YYYY-MM-DDThh:mm'
        ),
      evaluation: evaluationMedia,
      afterJob: afterJobMedia,
    };
  }

  return {
    initialValues: initial,
    reportType: typeOfReport,
    jobItems: products,
    discountType,
    discountValue,
    currency: state.auth.currentUser.companies[0].currency
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateJobForm: (attribute, value) => {
    dispatch(change(SERVICE_REPORT_FORM_NAME, attribute, value));
  },
  updateJobItem: (index, newItem) => {
    dispatch(
      arraySplice(SERVICE_REPORT_FORM_NAME, 'products', index, 1, newItem)
    );
  },
});

let ServiceReportForm = ({
  handleSubmit,
  jobItems,
  discountType,
  discountValue,
  updateJobForm,
  updateJobItem,
  reportType,
  translate,
  currency
}) => {
  const mapNameToComponent = {
    customer_info: CustomerInformationCard,
    device_info: DeviceInfoCard,
    service_provide: ServiceProvideCard,
    job_image: JobImageCard,
    job_tracking: JobTrackingCard,
  };

  useEffect(() => {
    const updateJobWhenItemChange = () => {
      let quoteTaxAmount = 0;
      let discountAmount = 0;
      let totalAmount = 0;
      let discountPercentage = 0;

      const sustotalBeforeDiscount = (jobItems || []).reduce(
        (sum, currentItem) => {
          return (
            sum +
            parseFloat(currentItem.unitPrice) * parseFloat(currentItem.quantity)
          );
        },
        0
      );

      if (!discountValue || parseFloat(discountValue) <= 0) {
        discountAmount = 0;
        discountPercentage = 0;
      } else if (discountType !== '%') {
        discountAmount = parseFloat(discountValue);
        discountPercentage = parseFloat(discountValue) / sustotalBeforeDiscount;
      } else {
        discountPercentage = parseFloat(discountValue);
        discountAmount =
          (parseFloat(discountValue) * sustotalBeforeDiscount) / 100;
      }
      const sustotalAfterDiscount =
        parseFloat(sustotalBeforeDiscount) - parseFloat(discountAmount);

      (jobItems || []).forEach((item, index) => {
        let sumTaxPercentage = 0;
        if (item.taxRate) {
          sumTaxPercentage = parseFloat(item.taxRate.percentage || 0);
        }

        if (item.taxComponent) {
          sumTaxPercentage = (item.taxComponent.taxRates || []).reduce(
            (sum, taxRate) => {
              return sum + parseFloat(taxRate.percentage || 0);
            },
            0
          );
        }
        const itemInitalTotal =
          parseFloat(item.quantity) * parseFloat(item.unitPrice);
        const itemDiscount =
          (parseFloat(itemInitalTotal) * discountPercentage) / 100;
        const itemTotalAfterDiscount =
          parseFloat(itemInitalTotal) - itemDiscount;
        const itemTaxAmount =
          (itemTotalAfterDiscount * parseFloat(sumTaxPercentage)) / 100;

        const newItem = {
          ...item,
          total: OnlyDecimal(itemTotalAfterDiscount),
          tax: itemTaxAmount,
          totalIncludeTax: OnlyDecimal(itemTotalAfterDiscount + itemTaxAmount),
          currency
        };

        updateJobItem(index, newItem);
        quoteTaxAmount += itemTaxAmount;
      });

      totalAmount =
        parseFloat(sustotalAfterDiscount) + parseFloat(quoteTaxAmount);

      updateJobForm('subtotal', OnlyDecimal(sustotalAfterDiscount));
      updateJobForm('discount', OnlyDecimal(discountAmount));
      updateJobForm('tax', OnlyDecimal(quoteTaxAmount));
      updateJobForm('total', OnlyDecimal(totalAmount));
    };
    // ref JobForm
    updateJobWhenItemChange();
  }, [discountValue, jobItems, discountType]);

  const components =
    reportType === 'FULL' ? ServiceReportTypeFull : ServiceReportTypePictorial;
  return (
    <form id="sr_form" onSubmit={handleSubmit}>
      {components.map((compName, index) => {
        const Comp = mapNameToComponent[compName];
        return (
          <Comp currency={currency} key={index} reportType={reportType} translate={translate} />
        );
      })}
    </form>
  );
};

const validate = (values) => {
  const errors = { message: {} };
  const requiredFields = [
    'customer',
    'phoneNumber',
    'address',
    'reportType',
    'requestDate',
    'scheduleDate',
    'serviceBy',
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'required';
      errors.message[field] = `${field}Required`;
    }
  });
  return errors;
};

ServiceReportForm = reduxForm({
  form: SERVICE_REPORT_FORM_NAME,
  validate,
})(ServiceReportForm);

export default connect(mapStateToProps, mapDispatchToProps)(ServiceReportForm);

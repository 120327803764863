export const VISIT_STATUS = {
  UNSCHEDULED: {
    title: 'Job has no scheduled visit',
    message: 'This job has no scheduled visit to be reviewed. Do you want to create a new visit to review the job?',
  },
  COMPLETED: {
    title: 'Complete this visit?',
    message: 'This is visit is not completed. Do you want to complete this visit then complete and close the job?',
    isShowCurrentVisit: true,
  },
  CANCELED: {
    title: 'Cancel this visit?',
    message: 'This is visit is not completed. Do you want to cancel this visit then cancel and close the job?',
    isShowCurrentVisit: true,
  },
  OPEN: {
    title: 'Open this job?',
    message: 'This job is currently closed. Do you want to reopen the job and create a new visit?',
    isShowOpenJobOnly: true
  },
  CREATE_NEW_VISIT: {
    title: 'Create new visit?',
    message: 'This job is currently closed. Do you want to create a new visit and open the job?',
    isShowCreateVisitOnly: true
  },
  COMPLETED_CLEAN_VISIT: {
    title: 'Is this visit completed?',
    message: 'All remaining visits that are pending will be deleted. Do you want to complete the current visit?',
    isShowCurrentVisit: true,
    isShowCompleteDeleteAllVisits: true
  },
  CANCELED_CLEAN_VISIT: {
    title: 'Cancel this visit & job?',
    message: 'All remaining visits that are pending will be deleted. Do you want to cancel the current visit?',
    isShowCurrentVisit: true,
    isShowCancelDeleteAllVisits: true
  },
  OPEN_JOB_ONLY: {
    title: 'Open this job?',
    message: 'This job is currently closed. Do you want to reopen the job?'
  }
};

export const ACTIONS_REVIEW_JOB = {
  START_JOB_REVIEW: 'START_JOB_REVIEW',
  MARK_REVIEW_AS_CANCELLED: 'MARK_REVIEW_AS_CANCELLED',
  MARK_REVIEW_AS_COMPLETED: 'MARK_REVIEW_AS_COMPLETED',
  COMPLETED_AND_DELETED_PENDING_VISITS: 'COMPLETED_AND_DELETED_PENDING_VISITS',
  CANCELLED_AND_DELETED_PENDING_VISITS: 'CANCELLED_AND_DELETED_PENDING_VISITS',
  COMPLETED_AND_DELETED_ALL_VISITS: 'COMPLETED_AND_DELETED_ALL_VISITS',
  CANCELLED_AND_DELETED_ALL_VISITS: 'CANCELLED_AND_DELETED_ALL_VISITS',
  OPEN_JOB: 'OPEN_JOB',
  CREATE_VISIT_ONLY: 'CREATE_VISIT_ONLY',
};

export const STATUS_CONFIRM_BUTTON = {
  COMPLETED: 'COMPLETED',
  CANCEL: 'CANCEL',
  UNSCHEDULE: 'UNSCHEDULE',
  OPEN_CREATE_VISIT: 'OPEN_CREATE_VISIT',
  CANCELED_CURRENT_VISIT: 'CANCELED_CURRENT_VISIT',
  COMPLETED_CURRENT_VISIT: 'COMPLETED_CURRENT_VISIT',
  OPEN_JOB_ONLY: 'OPEN_JOB_ONLY',
};

export const REVIEW_BUTTON_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_REVIEW: 'IN_REVIEW',
  REVIEWED: 'REVIEWED',
};

export const CREATE_VISIT_ONLY_STATUS = {
  CREATE_VISIT: 'CREATE_VISIT',
  CREATE_VISIT_ONLY: 'CREATE_VISIT_ONLY',
  OPEN_AND_CREATE_VISIT: 'OPEN_AND_CREATE_VISIT',
};
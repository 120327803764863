import React, { forwardRef, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  setCurrentStaff,
  getRoles,
  setStaffs,
  clearStaffs,
  updateStaff,
  deleteStaff,
  reinviteStaff,
  getStaffs,
} from 'redux/staff';

// Material helpers
// Material components
import {
  Avatar,
  withStyles,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
} from '@material-ui/core';

// Material icons
import {
  ArrowUpward,
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveOutlined,
  SaveAlt,
  Search,
  ToggleOff,
  ToggleOn,
  ViewColumn,
  Email,
} from '@material-ui/icons';

// Shared components
import { Portlet, PortletContent } from '../../../../../components';
import Loader from 'components/loaders/Loader';
import PhoneNumberFormat from 'lib/phoneNumberFormat';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';

// Component styles
import styles from './styles';

import StaffDefaultAvatar from 'assets/images/staff_default_avatar.svg';

const mapStateToProps = (state) => ({
  currentRoleId: state.auth.currentUser.companies[0].roleId,
  roles: state.staff.roles,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentStaff: (data) => {
    dispatch(setCurrentStaff(data));
  },
  getRoles: () => {
    dispatch(getRoles());
  },
  clearStaffs: () => {
    dispatch(clearStaffs());
  },
  setStaffs: (staffs) => {
    dispatch(setStaffs(staffs));
  },
  updateStaff: (id, form) => dispatch(updateStaff(id, form)),
  deleteStaff: (id) => dispatch(deleteStaff(id)),
  reinviteStaff: (id) => dispatch(reinviteStaff(id)),
  getStaffs: (params) => dispatch(getStaffs(params)),
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: (props) => <DeleteOutline {...props} />,
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Email: forwardRef((props, ref) => <Email {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <SaveOutlined {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ToggleOff: (props) => <ToggleOff {...props} />,
  ToggleOn: (props) => <ToggleOn {...props} />,
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function StaffsTable(props) {
  const {
    classes,
    className,
    clearStaffs,
    currentRoleId,
    deleteStaff,
    getRoles,
    history,
    roles,
    setStaffs,
    updateStaff,
    reinviteStaff,
    getStaffs,
    translate,
    tableRef,
  } = props;
  const [roleNames, setRoleNames] = useState({});
  const [includingArchived, setIncludingArchived] = useState(false);
  const isOwner = currentRoleId === 'OWNER';

  useEffect(() => {
    getRoles();

    if (roles) {
      const newRoleNames = getRoleNames(roles);
      setRoleNames(newRoleNames);
    }

    return () => clearStaffs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoleNames = (roles) =>
    roles.reduce((newList, role) => {
      newList[role.id] = role.name;
      return newList;
    }, {});

  const rootClassName = classNames(classes.root, className);

  function handleToggleArchivedStaffCheckbox(event) {
    setIncludingArchived(event.target.checked);
    // Should go back the page 0 when user toggle including archived staffs.
    refreshStaffList({ page: 0 });
  }

  function refreshStaffList(option = {}) {
    option = { includingArchived, ...option };
    tableRef.current && tableRef.current.onQueryChange(option);
  }

  async function changeArchiveStatus(id, status) {
    try {
      await updateStaff(id, { staffInfo: { archived: status } });
      refreshStaffList();
    } catch (error) {
      showErrorMessage(error);
    }
  }

  async function reinviteStaffByEmail(id) {
    try {
      await reinviteStaff(id);
      showSuccessMessage(translate('reinviteSucess'));
    } catch (error) {
      showErrorMessage(error);
    }
  }
  const columns = [
    {
      field: 'avatar',
      title: translate('picture'),
      sorting: false,
      render: (rowData) => {
        return (
          <Avatar
            alt={rowData.fullName}
            src={rowData.avatar || StaffDefaultAvatar}
            style={{ margin: 0 }}
          />
        );
      },
      filtering: false,
    },
    {
      field: 'firstName',
      title: translate('firstName'),
    },
    {
      field: 'lastName',
      title: translate('lastName'),
    },
    {
      field: 'email',
      title: translate('email'),
    },
    {
      field: 'phoneNumber',
      title: translate('phone'),
      render: (rowData) => {
        if (!rowData.phoneNumber || rowData.phoneNumber.length === 0) {
          return '';
        }
        return `${rowData.countryPhoneCode} ${PhoneNumberFormat(
          rowData.phoneNumber
        )}`;
      },
    },
    {
      field: 'roleId',
      title: translate('role'),
      filtering: false,
      render: (rowData) => roleNames[rowData.roleId],
    },
    {
      field: 'categories',
      title: translate('Common:category'),
      filtering: false,
      sorting: false,
      render: (rowData) => {
        if (!Array.isArray(rowData.categories)) return null;
        return (
          <Typography style={{ maxWidth: 220, wordBreak: 'break-all' }}>
            {rowData.categories.map((category, index) => (
              <Typography style={{ marginRight: 4 }} component="span">
                {category.name}
                {index + 1 !== rowData.categories.length && <>,</>}
              </Typography>
            ))}
          </Typography>
        );
      },
    },
  ];

  const options = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    actionsColumnIndex: -1,
    sorting: true,
    search: true,
    debounceInterval: 1000,
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(
    false
  );
  const [deletedStaffId, setDeletedStaffId] = React.useState(null);
  const confirmBeforeDeletion = (id) => {
    setShowDeleteConfirmation(true);
    setDeletedStaffId(id);
  };
  const handleCancel = () => {
    setShowDeleteConfirmation(false);
  };

  async function handleOk() {
    try {
      const response = await deleteStaff(deletedStaffId);
      if (response.status === 200) {
        refreshStaffList();
        setShowDeleteConfirmation(false);
      } else {
        showErrorMessage(response.data.message);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  async function fetchStaffs(query) {
    const { pageSize, orderBy, orderDirection, search, categoryIds } = query;
    let { page } = query;

    const sortParams = {};
    if (orderBy && orderDirection) {
      sortParams[`$sort[${orderBy.field}]`] = orderDirection === 'asc' ? 1 : -1;
    }
    try {
      let response = await getStaffs({
        // have to check with BE : 'STAFF_MANAGEMENT|ALL'
        $scope: 'STAFF_MANAGEMENT|VIEW',
        $limit: pageSize,
        $skip: pageSize * page,
        $q: search,
        includingArchived,
        categoryIds,
        ...sortParams,
      });
      let staffs = response.data.data || [];
      // If staff list for current page is empty, then we need go back 1 step and try to fetch data again.
      if (staffs.length === 0 && page > 0) {
        page -= 1;
        response = await getStaffs({
          $scope: 'STAFF_MANAGEMENT|VIEW',
          $limit: pageSize,
          $skip: pageSize * page,
          $q: search,
          includingArchived,
          ...sortParams,
        });
        staffs = response.data.data || [];
      }
      setStaffs(staffs);
      return Promise.resolve({
        data: staffs,
        page,
        totalCount: response.data.total,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <MaterialTable
          title={null}
          tableRef={tableRef}
          columns={columns}
          icons={tableIcons}
          options={options}
          onRowClick={(event, rowData) => {
            if (!isOwner && rowData.roleId === 'OWNER') {
            } else {
              window.open(`/teams/${rowData.id}/edit`, '_blank');
            }
          }}
          data={(query) => fetchStaffs(query)}
          actions={[
            (rowData) => ({
              icon: tableIcons.Edit,
              tooltip: translate('editUser'),
              onClick: (event, rowData) => {
                history.push(`/teams/${rowData.id}/edit`);
              },
              disabled: !isOwner && rowData.roleId === 'OWNER',
            }),
            (rowData) =>
              rowData.archived && {
                icon: tableIcons.ToggleOn,
                iconProps: { className: classes.iconGreen },
                tooltip: translate('activate'),
                onClick: (event, rowData) =>
                  changeArchiveStatus(rowData.id, !rowData.archived),
              },
            (rowData) =>
              rowData.roleId !== 'OWNER' &&
              !rowData.archived && {
                icon: tableIcons.ToggleOff,
                iconProps: { className: classes.iconRed },
                tooltip: translate('deactivate'),
                onClick: (event, rowData) =>
                  changeArchiveStatus(rowData.id, !rowData.archived),
              },
            (rowData) =>
              rowData.roleId !== 'OWNER' && {
                icon: tableIcons.Delete,
                iconProps: { className: classes.iconRed },
                tooltip: translate('delete'),
                onClick: (event, rowData) => confirmBeforeDeletion(rowData.id),
              },
            (rowData) =>
              rowData.roleId !== 'OWNER' &&
              !rowData.acceptedAt && {
                icon: tableIcons.Email,
                iconProps: {},
                tooltip: translate('reinvite'),
                onClick: (event, rowData) => reinviteStaffByEmail(rowData.id),
              },
          ]}
          components={{
            OverlayLoading: (props) => <Loader height={50} width={50} />,
            Toolbar: (props) => (
              <>
                <Grid container direction="row">
                  <Grid
                    item
                    container
                    justify="flex-start"
                    alignItems="center"
                    md={6}
                    sm={12}
                    className={classes.archivesInclusiveCheckbox}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includingArchived}
                          onChange={handleToggleArchivedStaffCheckbox}
                          value="includingArchived"
                        />
                      }
                      label={translate('showDeactivated')}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    justify="flex-end"
                    alignItems="center"
                    md={6}
                    sm={12}
                  >
                    <MTableToolbar {...props} />
                  </Grid>
                </Grid>

                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  maxWidth="xs"
                  aria-labelledby="confirmation-dialog-title"
                  open={showDeleteConfirmation}
                >
                  <DialogTitle id="confirmation-dialog-title">
                    {translate('askConfirmationBeforeDelete')}
                  </DialogTitle>

                  <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                      {translate('Common:cancel')}
                    </Button>
                    <Button onClick={handleOk} color="primary">
                      {translate('Common:ok')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ),
          }}
          localization={{
            toolbar: {
              searchTooltip: translate('Common:search'),
              searchPlaceholder: translate('Common:search'),
            },
            pagination: {
              labelRowsSelect: translate('Common:rows'),
              labelDisplayedRows: ` {from}-{to} ${translate(
                'Common:of'
              )} {count}`,
              firstTooltip: translate('Common:firstPage'),
              previousTooltip: translate('Common:previousPage'),
              nextTooltip: translate('Common:nextPage'),
              lastTooltip: translate('Common:lastPage'),
            },
            header: {
              actions: translate('Common:actions'),
            },
          }}
        />
      </PortletContent>
    </Portlet>
  );
}

StaffsTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  staffs: PropTypes.array.isRequired,
};

StaffsTable.defaultProps = {
  staffs: [],
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(StaffsTable);

import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Grid,
  Typography,
} from '@material-ui/core';

// Component styles
import PaymentFormStyle from './styles';
import TextInput from 'components/inputs/TextInput';
import { DatePicker } from 'components/inputs';

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

const FormRow = props => {
  const { label, name, select, selectOptions, timeDate = false } = props;
  return (
    <Grid container direction='row' alignItems="center">
      <Grid item xs={5}>
        <Typography>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {
          select && selectOptions ?
            <Field
              name={name}
              component={TextInput}
              margin="dense"
              required
              variant="outlined"
              select
              SelectProps={{ native: true }}
            >
              {/* <option value=''></option> */}
              {
                selectOptions && selectOptions.length > 0 ?
                  selectOptions.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  )) :
                  <option key='' value='' />
              }
            </Field> :
            <Field
              name={name}
              component={timeDate ? DatePicker : TextInput}
              margin="dense"
              required
              variant="outlined"
            />

        }
      </Grid>
    </Grid>
  );
};

function PaymentForm(props) {
  const {
    appliedToOptions,
    paymentMethod,
    translate,
  } = props;

  return (
    <Grid>
      <FormRow
        label={translate('Client:paymentMethod')}
        name="paymentMethod"
        select
        selectOptions={paymentMethod}
      />
      <FormRow
        label={translate('Client:amount')}
        name="amount"
      />
      <FormRow
        label={translate('Client:appliedTo')}
        name="appliedTo"
        select
        selectOptions={appliedToOptions}
      />
      <FormRow
        label={translate('Client:paymentDate')}
        name="paymentDate"
        timeDate
      />
      <FormRow
        label={translate('Client:details')}
        name="notes"
      />
    </Grid>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(PaymentFormStyle)
)(PaymentForm);
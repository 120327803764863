import palette from 'common/theme/palette';

export default theme => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    height: '90vh',
  },
  content: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  headerRow: {
    background: theme.palette.timesheet.headerColor,
    '& th': {
      fontSize: 15,
      textAlign: 'center',
    }
  },
  tableContainer: {
    height: '90vh'
  },
  gridContainer: {
    height: '90vh',
    boxShadow: 'none'
  },
  searchTextBox: {
    width: '100%',
    background: 'rgba(0, 0, 0, 0.04)',
  },
  dateRow: {
    background: theme.palette.timesheet.dateRow,
    color: theme.palette.common.white,
  },
  visitRow: {
    background: theme.palette.timesheet.light,
    height: 'unset',
  },
  tableCell: {
    borderRight: `1px solid ${theme.palette.border}`,
    padding: '9px 24px 9px 24px !important',
    fontSize: 15,
  },
  headerCell: {
    borderRight: `1px solid ${theme.palette.common.white}`
  },
  dateRowText: {
    padding: '0 10px 0 3px',
    color: theme.palette.common.white,
    height: 26,
    lineHeight: '26px',
    fontSize: 15,
  },
  timeValue: {
    padding: '0 5px',
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    height: 26,
    lineHeight: '26px',
    borderRadius: '5px'
  },
  underlineText: {
    textDecoration: 'underline',
    color: theme.palette.text.blue,
    cursor: 'pointer'
  },
  staffSelected: {
    backgroundColor: 'rgba(100, 144, 245, 0.12) !important',
    borderRadius: 4,
  },
  listStaffTitle: {
    padding: '7px 10px 12px 10px',
    color: '#808080',
    fontWeight: 'bolder'
  },
  listStaff: {
    maxHeight: '90vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 7,
  },
  searchBox: {
    borderRadius: 10,
    margin: '3% 0',
    background: 'rgba(0, 0, 0, 0.04)',
    '& .MuiFilledInput-underline': {
      minHeight: 50,
      borderRadius: 4,
      '&:before, &:after': {
        borderBottom: 0
      },
      '& .MuiInputAdornment-root': {
        marginTop: 0
      }
    },
    '& input': {
      // fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 20,
      letterSpacing: 0.25,
      padding: 0
    }
  },
  paperStyle: {
    position: 'relative',
    overflow: 'scroll',
    maxHeight: 'calc(100vh - 210px)',
  },
  listItem: {
    padding: '9px 18px',
    cursor: 'pointer',
    paddingLeft: 7,
    '&:hover': {
      backgroundColor: 'rgba(18, 22, 27, 0.08)',
      borderRadius: 4,
    }
  },
  listItemIcon: {
    minWidth: 24
  },
  itemText: {
    '& .MuiListItemText-primary': {
      color: theme.palette.common.dark,
      fontSize: 15,
    },
  },
  portlet: {
    height: '90vh',
    boxShadow: 'none',
  },
  portletContent: {
    textAlign: 'left',
    font: 'Italic 20px/23px "DM Sans"',
    letterSpacing: 0,
    color: '#BCBCBC',
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  preSelectImg: {
    margin: theme.spacing(2)
  },
  filterBar: {
    textAlign: 'left',
    fontSize: 17,
    marginTop: -6,
    paddingBottom: 9,
    color: theme.palette.calendar.blue,
    '& span': {
      verticalAlign: 'middle',
    },
    '& .MuiTextField-root': {
      verticalAlign: 'middle',
    }
  },
  radioLabel: {
    marginRight: 11,
  },
  inputRoot: {
    marginRight: 11,
    '& input': {
      width: 90,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0,
      '& .MuiOutlinedInput-notchedOutline': {
        padding: 0,
      },
      '& .MuiOutlinedInput-inputAdornedEnd': {
        paddingLeft: 8,
      }
    },
    '& .MuiInputAdornment-positionEnd': {
      margin: 0,
    }
  },
  inputAdornment: {
    position: 'end',
    '& .MuiIconButton-root': {
      padding: '10px 10px 10px 5px',
    }
  },
  totalHour: {
    paddingTop: 16,
    textAlign: 'right',
    '& .MuiPaper-root': {
      display: 'inline-flex',
      backgroundColor: palette.timesheet.dateRow,
      height: 38,
      padding: '0 16px',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        marginRight: 13,
        color: palette.text.white,
        fontSize: 15,
      },
      '& div': {
        fontWeight: 'bold',
        backgroundColor: palette.common.white,
        padding: '3px 33px 4px 33px',
        lineHeight: '1em',
        fontSize: 18,
      },
    }
  },
  defaultWidth: {
    width: '50%',
    height: '50%',
  }
});

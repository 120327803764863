export default {
  workSettings:	'Work Settings',
  defaultInvoiceSetting: 'Default Invoice Setting',
  defaultInvoiceSubject: 'Invoice Subject',
  defaultInvoiceNote: 'Invoice Note and/or Payment Instructions',
  defaultPaymentDue: 'Payment Due',
  defaultQuoteValidity: 'Payment Term',
  defaultJobSetting: 'Default Job Setting',
  defaultJobTitle: 'Job Title',
  defaultQuoteSetting: 'Default Quote Setting',
  defaultQuoteSubject: 'Quote Subject',
  defaultQuoteNote: 'Quote Note',
  updateQuoteSetting: 'Update Quote Setting',
  updateJobSetting: 'Update Job Setting',
  updateInvoiceSetting: 'Update Invoice Setting',
  updatedInvoiceSettingSuccessfully: 'Invoice Setting Updated Successfully',
  updatedJobSettingSuccessfully: 'Job Setting Updated Successfully',
  updatedQuoteSettingSuccessfully: 'Quote Setting Updated Successfully',
  day: 'Day(s)'
};
import React, { useEffect, useState } from 'react';

// Redux
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Modal from './modal';
import EditModal from './editModal';
import { useDispatch } from 'react-redux';
import { getVisitInCalendar } from 'redux/visit';
import { BackdropCustom, useBackdrop } from 'lib/loading';
import { showErrorMessage } from 'lib/notifier';
import { setConfirmReviewButton, setJobReviewed } from 'redux/jobVerification';
import { STATUS_CONFIRM_BUTTON } from 'pages/Dashboard/views/Jobs/JobDetails/components/ConfirmReviewModal/constants';
import { useCheckJobReviewed } from 'pages/Dashboard/views/Jobs/JobDetails/components/ConfirmReviewModal/hook';

const VisitDetailsModal = (props) => {
  const { handleCloseVisitDetails, showJobDetails, visitId, isCalendarPage } = props;
  const [visitDetails, setVisitDetails] = useState();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation('Job');
  const { handleCheckJobReviewed } = useCheckJobReviewed();

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [open, withLoading] = useBackdrop();
  const handleOpenEditModal = () => handleCheckJobReviewed(() => {
    handleCloseVisitDetails && handleCloseVisitDetails();
    setIsOpenEditModal(true);
  });
  const fetchData = async () => {
    const result = await withLoading(
      dispatch(getVisitInCalendar({ id: visitId, isCalendarPage })).catch((e) => { showErrorMessage(e); return e; })
    );
    setVisitDetails(result?.data);
    dispatch(setConfirmReviewButton(STATUS_CONFIRM_BUTTON.OPEN_JOB_ONLY));
    dispatch(setJobReviewed(result?.data.job.verified));
  };
  useEffect(()=>{
    if(visitId && showJobDetails) {
      fetchData();
    }
  },[visitId,showJobDetails]);

  if (isOpenEditModal)
    return (
      <>
        <BackdropCustom open={open} style={{ zIndex:'9999' }} />
        <EditModal
          {...props}
          translate={translate}
          isOpenEditModal={isOpenEditModal}
          setIsOpenEditModal={setIsOpenEditModal}
          visitDetails={visitDetails}
        />
      </>
    );

  return (
    <>
      <Modal
        {...props}
        translate={translate}
        handleOpenEditModal={handleOpenEditModal}
        visitDetails={visitDetails}
        fetchData={fetchData}
        handleCheckJobReviewed={handleCheckJobReviewed}
      />
      <BackdropCustom open={open} style={{ zIndex:'9999' }} />
    </>
  );
};

export default withRouter(VisitDetailsModal);

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
// Material components
import { Grid, Typography, Button } from '@material-ui/core';
import tagsIcon from 'assets/images/tags.svg';
import styles from '../styles.module.scss';
import AsyncCreatableSelect from 'react-select/async-creatable';
// Component styles
import { LoadingModal } from 'pages/Dashboard/components';
import { getTags } from 'redux/tag';
import { updateMediaDetails } from 'redux/media';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    return new Promise((rs) => {
      const later = async () => {
        clearTimeout(timeout);
        const resp = await func(...args).catch(() => rs([]));
        rs(resp);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    });
  };
};

const EditTag = ({
  tags,
  getTags,
  translate,
  updateMediaDetails,
  setMediaDetails,
  currentMediaSelected,
  setIsEditTag,
}) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    const fetchTags = async () => {
      const result = await getTags().catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
      const tagList = result.data.data;
      const formattedList = tagList.map((tag) => ({
        value: tag.text,
        label: tag.text,
      }));
      setOptions(formattedList);
    };
    setIsLoading(true);
    fetchTags();
    setIsLoading(false);
  }, []);
  useEffect(() => {
    if (tags && tags.length) {
      const selectedTags = tags.map((tag) => ({
        value: tag.text,
        label: tag.text,
      }));
      setSelectedOption([...selectedOption, ...selectedTags]);
    }
  }, [tags]);
  const handleChange = (selected) => {
    setIsDirty(true);
    setSelectedOption(selected || []);
  };
  const handleUpdateTags = async () => {
    if (!isDirty) return setIsEditTag(false);
    const tagsPayload = selectedOption.map((tag) => tag.value);
    const result = await updateMediaDetails(currentMediaSelected.id, {
      tags: tagsPayload,
    }).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    showSuccessMessage(result.data.message);
    setMediaDetails(result.data && result.data.data);
    setIsEditTag(false);
  };

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const result = await getTags({ $q: inputValue }).catch((e) => ({ e }));
        if (result.e) {
          showErrorMessage(result.e);
          return result.e;
        }
        const formattedList = result.data.data.map((tag) => ({
          value: tag.text,
          label: tag.text,
        }));
        resolve(formattedList);
      }, 1000);
    });
  };
  if (isLoading) return <LoadingModal open={isLoading} />;
  return (
    <Grid item>
      <Grid container justify="space-between" className={styles.mb_15}>
        <Grid item className={styles.tagContainer}>
          <img src={tagsIcon} alt="tag_icon" className="mr_10" />
          <Typography className={styles.evaluation}>
            {translate('tag')}
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => setIsEditTag(false)} color="secondary">
            <span className={styles.editTag}>
              {translate('Common:discard')}
            </span>
          </Button>
          <Button onClick={handleUpdateTags} color="secondary">
            <span className={styles.editTag}>{translate('Common:save')}</span>
          </Button>
        </Grid>
      </Grid>
      <Grid container className={styles.mb_15}>
        <Grid style={{ width: '100%' }}>
          <AsyncCreatableSelect
            isMulti
            closeMenuOnSelect={false}
            value={selectedOption}
            onChange={handleChange}
            defaultOptions={options}
            loadOptions={debounce(promiseOptions, 500)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getTags: (searchText) => dispatch(getTags(searchText)),
  updateMediaDetails: (id, data) => dispatch(updateMediaDetails(id, data)),
});

export default compose(connect(null, mapDispatchToProps))(EditTag);

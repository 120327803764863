import React from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { updateJobDetails, addJob } from 'redux/job';

// Material components
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grow,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon
} from '@material-ui/icons';

// shared form
import { JobForm } from 'pages/Dashboard/views/Jobs/components/forms';
import { modifyJobDataBeforeSubmission, JobValidation } from 'pages/Dashboard/views/Jobs/components/utils';
import { showErrorMessage } from 'lib/notifier';
// Component styles
import { JobDetailsFormStyle } from './styles';


const mapDispatchToProps = dispatch => ({
  updateJobDetails: (values) => dispatch(updateJobDetails(values)),
  addJob: (values) => dispatch(addJob(values))
});

const FormJobDetails = props => {
  const {
    classes,
    showJobDetailsForm,
    handleCloseJobForm,
    afterSaveJobDetails,
    translate
  } = props;
  const { t } = useTranslation('Job');

  function submitJobForm(values) {
    const errors = JobValidation(values);
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));
      throw new SubmissionError(errors);
    } else {
      values = modifyJobDataBeforeSubmission(values);

      if (values.id) {
        props.updateJobDetails(values).then(response => {
          afterSaveJobDetails(response.data.data);
        }, error => {
          showErrorMessage(error);
        });
      }

      else {
        props.addJob(values).then(response => {
          afterSaveJobDetails(response.data);
        }, error => {
          showErrorMessage(error);
        });
      }
    }
  }

  return (
    <Dialog
      maxWidth='lg'
      aria-labelledby="customized-dialog-title"
      open={showJobDetailsForm}
      transition={Grow}
    >
      <DialogTitle disableTypography className={classes.teamDialogTitle}>
        <Typography variant='h6' className={classes.teamDialogTitleText}>{translate('jobDetails')}</Typography>
        {showJobDetailsForm ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleCloseJobForm}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        <JobForm
          handleCancelJobForm={handleCloseJobForm}
          isForm
          onSubmit={values => submitJobForm(values)}
          translate={t}
        />
      </DialogContent>
    </Dialog>
  );

};

export default compose(
  withStyles(JobDetailsFormStyle),
  connect(null, mapDispatchToProps)
)(FormJobDetails);
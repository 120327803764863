import { GET } from 'lib/api';

export function getLocations(options={}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    options = Object.assign({
      limit: 500,
      skip: 0,
    }, options);

    const params = { params: options };

    return GET('/locations', accessToken, params).then((response) => {
      return Promise.resolve(response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };

}

export function getLocationDetails(userId, options={}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return GET(`/locations/${userId}`, accessToken, { params: options })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}


export function searchPlaces(textSearch, options={}) {

  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    options = Object.assign({
      $q: textSearch,
    }, options);

    return GET('/places', accessToken, { params: options })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}


export function getPlaceDetails(placeId, options={}) {

  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    options = Object.assign({
    }, options);

    return GET(`/places/${placeId}`, accessToken, { params: options })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}
import PopoverWithTopArrow from 'common/styles/PopoverWithTopArrow';

export default theme => ({
  moreIcon:{
    marginRight: theme.spacing(1),
  },
  sectionTitle: {
    color: theme.palette.text.primary,
    opacity: 0.7,
    paddingLeft: 4,
    paddingTop: 4,
    paddingBottom: 4,
  },
  popoverWithArrow: {
    ...PopoverWithTopArrow,
  },
});
import { CREATE_VISIT_ONLY_STATUS, STATUS_CONFIRM_BUTTON }
  from 'pages/Dashboard/views/Jobs/JobDetails/components/ConfirmReviewModal/constants';

export const UPDATE_CONFIRM_REVIEW_BUTTON = 'UPDATE_CONFIRM_REVIEW_BUTTON';
export const UPDATE_CONTENT_MODAL = 'UPDATE_CONTENT_MODAL';
export const SET_CONFIRM_REVIEW_MODAL = 'SET_CONFIRM_REVIEW_MODAL';
export const SET_OPEN_JOB = 'SET_OPEN_JOB';
export const SET_JOB_REVIEWED= 'SET_JOB_REVIEWED';

export const initialReviewJob = {
  buttonConfirmStatus: STATUS_CONFIRM_BUTTON.UNSCHEDULE,
  isOpenReviewModal: false,
  isOpenJob: CREATE_VISIT_ONLY_STATUS.CREATE_VISIT,
  isJobReviewed: false,
  contentModal: {
    title: '',
    message: '',
    isShowCurrentVisit: false,
    isShowCompleteDeleteAllVisits: false,
    isShowCancelDeleteAllVisits: false,
    isShowOpenJobOnly: false,
    isShowCreateVisitOnly: false,
  }
};

export function reviewJobReducer(state = initialReviewJob, action = {}) {
  switch (action.type) {
    case UPDATE_CONFIRM_REVIEW_BUTTON: {
      return { ...state, buttonConfirmStatus: action.confirmReviewButton };
    }
    case UPDATE_CONTENT_MODAL: {
      return { ...state, contentModal: action.contentModal };
    }
    case SET_CONFIRM_REVIEW_MODAL: {
      return { ...state, isOpenReviewModal: action.isOpenReviewModal };
    }
    case SET_OPEN_JOB: {
      return { ...state, isOpenJob: action.isOpenJob };
    }
    case SET_JOB_REVIEWED: {
      return { ...state, isJobReviewed: action.isJobReviewed };
    }
    default: {
      return state;
    }
  }
}

export function setContentModal(contentModal) {
  return { type: UPDATE_CONTENT_MODAL, contentModal };
}

export function setConfirmReviewButton(confirmReviewButton) {
  return { type: UPDATE_CONFIRM_REVIEW_BUTTON, confirmReviewButton };
}

export function setOpenConfirmReviewModal(isOpenReviewModal) {
  return { type: SET_CONFIRM_REVIEW_MODAL, isOpenReviewModal };
}

export function setOpenJob(isOpenJob) {
  return { type: SET_OPEN_JOB, isOpenJob };
}
export function setJobReviewed(isJobReviewed) {
  return { type: SET_JOB_REVIEWED, isJobReviewed };
}

export const getContentModal = (state) => state.jobVerification.contentModal;
export const getButtonConfirmStatus = (state) => state.jobVerification.buttonConfirmStatus;
export const getConfirmReviewModalStatus = (state) => state.jobVerification.isOpenReviewModal;
export const getIsOpenJob = (state) => state.jobVerification.isOpenJob;
export const getIsJobReviewed = (state) => state.jobVerification.isJobReviewed;
export default {
  dashboard:	'Dashboard',
  calendar:	'Calendar',
  tracking:	'Tracking',
  clients: 'Clients',
  customers: 'Customers',
  people: 'People',
  work: 'Work',
  jobs: 'Jobs',
  invoices:	'Invoices',
  quotes: 'Quotes',
  businessManagement: 'Business Management',
  team: 'Team',
  productsService: 'Products & Service',
  productsServices: 'Products & Services',
  businessSettings: 'Business Settings',
  closeSidebar: 'Close Sidebar',
  showSidebar: 'Show Sidebar',
  timesheet: 'Timesheet',
  workSettings: 'Work Settings',
  accountSettings: 'Account Settings',
  accountSetting: 'Account Setting',
  accountManagement: 'Account Management',
  accountInformation: 'Account Information',
  planBilling: 'Plan & Billing',
  powerBy: 'Powered By',
  categories: 'Category Tags',
  category: 'Category Tag',
  enterpriseAccount: 'Enterprise Account',
  hubs: 'Hubs',
  hub: 'Hub',
  requests: 'Requests',
  request: 'Request'
};
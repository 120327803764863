import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field } from 'redux-form';

// import component
import styles from './styles.module.scss';

const CustomerInformation = ({ translate, renderTypography }) => {
  return (
    <>
      <Grid container className={styles.customerInfo}>
        <Grid item className={styles.information}>
          <Grid container wrap="nowrap" className={styles.row}>
            <Typography variant="body1" className={styles.title}>
              {translate('customer')}
            </Typography>
            <Field
              style={{ width: '100%' }}
              name="customer"
              component={renderTypography}
              bold
              margin="dense"
            />
          </Grid>

          <Grid container wrap="nowrap" className={`p_10 ${styles.infoRow}`}>
            <Typography variant="body1" className={styles.title}>
              {translate('phoneNumber')}
            </Typography>
            <Field
              style={{ width: '100%' }}
              name="phoneNumber"
              component={renderTypography}
              margin="dense"
              bold
            />
          </Grid>
          <Grid container wrap="nowrap" className={styles.row}>
            <Typography variant="body1" className={styles.title}>
              {translate('address')}
            </Typography>
            <Field
              style={{ width: '100%' }}
              name="address"
              component={renderTypography}
              margin="dense"
            />
          </Grid>
        </Grid>
        <Grid item className={styles.dateRequest}>
          <Grid container wrap="nowrap" className={styles.row}>
            <Typography variant="body1" className={styles.title}>
              {translate('requestDate')}
            </Typography>
            <Field
              style={{ width: '100%' }}
              name="requestedDate"
              component={renderTypography}
              margin="dense"
              bold
            />
          </Grid>
          <Grid container wrap="nowrap" className={`p_10 ${styles.infoRow}`}>
            <Typography variant="body1" className={styles.title}>
              {translate('scheduleDate')}
            </Typography>
            <Field
              style={{ width: '100%' }}
              name="scheduledDate"
              component={renderTypography}
              margin="dense"
              bold
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={`${styles.jobDescription} mt_20`}>
        <Grid container item>
          <Typography className={styles.boldText}>
            {translate('jobDescription')}
          </Typography>
        </Grid>
        <Field
          style={{ width: '100%' }}
          name="description"
          component={renderTypography}
        />
      </Grid>
      <Grid container className={`${styles.jobDescription} mt_20`}>
        <Grid container item>
          <Typography className={styles.boldText}>
            {translate('actionTaken')}
          </Typography>
        </Grid>
        <Field
          style={{ width: '100%' }}
          name="actionTaken"
          component={renderTypography}
        />
      </Grid>
    </>
  );
};

export default CustomerInformation;

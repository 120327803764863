import React from 'react';

// Externals
import classNames from 'classnames';

// Material components
// Material helpers
import { Grid, Typography, withStyles } from '@material-ui/core';

// Customised components
import { DetailsTable, QuoteHeader } from '../utils';
import { getClientEmail, getClientPhoneObject } from 'lib/clientContact';
import { AddressDetails } from 'pages/Dashboard/components';
import { Link } from 'react-router-dom';

// Component styles
import { QuoteClientDetailsCardStyle } from './styles';

const QuoteClientDetailsCard = (props) => {
  const {
    classes,
    quote,
    quoteStatuses,
    handleClickArchive,
    handleClickStatus,
    handleEmailQuote,
    translate,
  } = props;

  const { client } = quote;
  const email = getClientEmail(client);
  const phoneNumber = getClientPhoneObject(client);
  const clientName =
    client && client.useCompanyName
      ? client.companyName
      : [client && client.firstName, client && client.lastName]
          .filter(Boolean)
          .join(' ') || translate('Client:clientHasNoName');
  return (
    <Grid container className={classNames(classes.header)}>
      {quote && Object.keys(quote).length > 0 && (
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.quoteHeader}
          >
            <QuoteHeader
              handleClickArchive={handleClickArchive}
              handleClickStatus={handleClickStatus}
              handleEmailQuote={handleEmailQuote}
              quote={quote}
              quoteStatuses={quoteStatuses}
              translate={translate}
            />
          </Grid>
          <Grid container direction="row" justify="space-between">
            <Grid
              item
              spacing={2}
              container
              xs={5}
              className={classes.borderContainer}
            >
              <Grid
                className={classes.containerStyle}
                container
                direction="column"
              >
                {/* // Quote details table */}
                <DetailsTable
                  archived={quote.archived}
                  quoteExpiryDate={quote.expiredAt}
                  quoteIssueDate={quote.createdAt}
                  quoteStatus={quote.status}
                  quoteStatuses={quoteStatuses}
                  translate={translate}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={7}
              spacing={2}
              className={classes.borderContainer}
            >
              <Grid
                className={classes.containerStyle}
                container
                direction="column"
              >
                <Typography variant="h3" className={classes.quoteDetailsTitle}>
                  {translate('Common:clientDetails')}
                </Typography>
                <Grid item container direction="row">
                  <Grid item md={3}>
                    <Typography className={classes.titleColor} variant="h5">
                      {translate('Common:name')}
                    </Typography>
                    <Link to={`/clients/${client.id}`}>
                      <Typography variant="h5" className={classes.clientName}>
                        {clientName}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item md={5}>
                    <AddressDetails
                      titleStyle={{
                        marginBottom: 0,
                        fontWeight: 'normal',
                        color: 'rgba(0, 0, 0, 0.5)',
                      }}
                      textStyle={{ color: 'black' }}
                      address={quote && quote.property}
                      translate={translate}
                      title={translate('billingAddress')}
                    />
                  </Grid>
                  <Grid item md={4} container>
                    {email ? (
                      <Grid md={12} item className={classes.emailContainer}>
                        <Typography
                          className={classes.titleColor}
                          ariant="body2"
                          variant="h5"
                        >
                          {translate('Common:email')}
                        </Typography>
                        <Typography className={classes.textDetails}>
                          {email}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item className={classes.emailContainer}>
                        <Typography className={classes.noContacts}>
                          {translate('Common:noEmailRecorded')}
                        </Typography>
                      </Grid>
                    )}
                    {phoneNumber && (
                      <Grid md={12} item>
                        <Typography
                          className={classes.titleColor}
                          ariant="body2"
                          variant="h5"
                        >
                          {translate('Common:phoneNumber')}
                        </Typography>
                        <Typography className={classes.textDetails}>
                          ({phoneNumber.countryPhoneCode}){' '}
                          {phoneNumber.phoneNumber}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* // Quote details box */}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(QuoteClientDetailsCardStyle)(QuoteClientDetailsCard);

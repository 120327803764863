import React, { useState } from 'react';
import {
  Typography,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import styles from '../mostRecent.module.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const MenuItems = ['newest', 'pending', 'inProgress',
  'completed', 'cancelled', 'completedAndClosed', 'cancelledAndClosed'];

const StatusFilterMenu = ({ t, filterJob, setFilterJob }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleClickItem = (item) => setFilterJob(item);

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Typography variant="h5">{t(filterJob || 'newest')}</Typography>
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        classes={{ paper: styles.menuPaper }}
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {MenuItems.map((item, index) => (
          <MenuItem
            disabled={item === filterJob}
            onClick={() => handleClickItem(item)}
            key={index}
          >
            {t(item)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StatusFilterMenu;

import { Typography, Box, SvgIcon, Button } from '@material-ui/core';
import React, { useState , useMemo } from 'react';
import { ReactComponent as MultipleSelectIcon } from 'assets/images/icons/multiple_select.svg';
import DownloadIcon from '@material-ui/icons/SystemUpdateAlt';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDetails,
  getIsShowConfirmModal,
  getMediaItems, getMediaSelected,
  getMediaSelectedCount,
  getMediaSelectedId, mediaActionsAdmin, setMediaList, setMediaSelectMultiple } from 'redux/media';
import { DownloadingNotification } from 'pages/Dashboard/views/Enterprise/Jobs/Details/components';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import { useTranslation } from 'react-i18next';
import VisitMenu from '../MediaDetails/components/VisitMenu';
import CheckVisitUpdateModal from './CheckVisitUpdateModal';
import { useBackdrop, BackdropCustom } from 'lib/loading';


const MultipleSelectSidebar = ({ job, fetchMedia, currentMediaSelected, fetchVisits, afterUpdateMedia }) => {
  const { t } = useTranslation('Enterprise');
  const { t: translate } = useTranslation('Job');
  const dispatch = useDispatch();
  const mediaNewList = useSelector(getMediaItems);
  const mediaSelected = useSelector(getMediaSelected);
  const mediaSelectedId = useSelector(getMediaSelectedId);
  const isShowConfirmModal = useSelector(getIsShowConfirmModal);
  const countMediaSelected = useSelector(getMediaSelectedCount);
  const [open, withLoading] = useBackdrop();

  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDisableEvent, setIsDisableEvent] = useState(true);

  const updateMediaListSelected = () => {
    return dispatch(setMediaList(currentMediaSelected.map(media => ({ ...media, isSelected: false }))));
  };

  useMemo(() => {
    if (countMediaSelected) {
      setIsDisableEvent(false);
    } else {
      setIsDisableEvent(true);
    }
  }, [countMediaSelected]);
  const handleClickCloseSelectMultiple = () => {
    dispatch(setMediaSelectMultiple(false));
  };
  const downloadFiles = async () => {
    const files = mediaSelected.map(media => media.id);
    setIsOpenDownloadModal(true);
    const data = {
      jobId: job.publicId,
      mediaIds: files,
      action: 'DOWNLOAD',
    };
    const result = await dispatch(mediaActionsAdmin({ data })).catch((e) => ({
      e,
    }));
    if (result.e) return showErrorMessage(result.e);
    if (result.url) window.open(result.url, '_blank');
  };

  const handleDeleteMedia = async () => {
    const result = await withLoading(dispatch(
      deleteDetails({
        mediaIds: mediaSelected.map(media => media.id)
      })
    ).catch((e) => ({ e })));
    if (result.e) return showErrorMessage(result.e);
    await Promise.all([fetchMedia(), fetchVisits(), afterUpdateMedia()]);
    updateMediaListSelected();
    showSuccessMessage(t('Common:completed'));
  };

  const handleClickAssignVisit = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <BackdropCustom open={open} />
      <DownloadingNotification
        t={t}
        isOpenDownloadModal={isOpenDownloadModal}
        setIsOpenDownloadModal={setIsOpenDownloadModal}
      />
      <Box
        bgcolor='background.gray'
        py={1.5}
        style={{
          position: 'relative'
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          pt={1.5}
          pb={3}
        >
          <SvgIcon
            component={MultipleSelectIcon}
          />
          <Typography>{translate('fileSelected', { numberFiles: countMediaSelected })}</Typography>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='flex-start'
          borderRadius="10px"
          mx={1.5}
          bgcolor="white"
        >
          <Button
            disabled={isDisableEvent}
            fullWidth
            style={{
              justifyContent: 'flex-start',
              padding: '12px',
              borderBottom: '1px solid #F0F0F0',
              backgroundColor: 'white',
            }}
            onClick={downloadFiles}
          >
            <DownloadIcon />
            <Box ml={0.5}>
              {t('download')}
            </Box>
          </Button>
          <Button
            id="basic-button"
            disabled={isDisableEvent}
            aria-controls={anchorEl ? 'basic-menu' : undefined}
            aria-haspopup="true"
            fullWidth
            style={{
              justifyContent: 'flex-start',
              padding: '12px',
              borderBottom: '1px solid #F0F0F0',
              backgroundColor: 'white',
            }}
            onClick={handleClickAssignVisit}
          >
            <InfoIcon />
            <Box ml={0.5}>
              {translate('assignToVisit')}
            </Box>

          </Button>
          <Button
            disabled={isDisableEvent}
            fullWidth
            style={{
              justifyContent: 'flex-start',
              padding: '12px',
              borderBottom: '1px solid #F0F0F0',
              backgroundColor: 'white',
            }}
            onClick={handleDeleteMedia}
          >
            <DeleteIcon />
            <Box ml={0.5}>
              {translate('delete')}
            </Box>
          </Button>
        </Box>
        <Box px={1.5} marginTop={1.5}>
          <Button
            fullWidth
            style={{
              padding: '12px',
              backgroundColor: 'white',
              justifyContent: 'flex-start',
            }}
            onClick={handleClickCloseSelectMultiple}
          >
            <ClearIcon />
            <Box ml={0.5}>
              {translate('discard')}
            </Box>
          </Button>
        </Box>

        {anchorEl && (
          <VisitMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            job={job}
            translate={translate}
            mediaIds={mediaSelectedId}
            mediaList={mediaNewList}
            dispatch={dispatch}
            isShowConfirmModal
          />
        )}
        <CheckVisitUpdateModal
          showConfirmModal={isShowConfirmModal}
          mediaList={mediaNewList}
          fetchMedia={fetchMedia}
          fetchVisits={fetchVisits}
        />
      </Box>
    </>
  );
};

export default MultipleSelectSidebar;
import React, { useState } from 'react';
import { withRouter, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Externals
import classNames from 'classnames';
import clsx from 'clsx';
// Redux
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { setIsFullSidebar } from 'redux/global';

// Material helpers
// Material components
import { withStyles , Drawer } from '@material-ui/core';

// Custom components
import { Sidebar, Topbar } from './components';

// Component styles
import styles from './styles';

import matchPath from 'lib/matchPath';

const Dashboard = props => {
  const { classes, width, title, childTitle, children, translate } = props;
  const isFull = useSelector((state) => state.global.isFullSidebar);
  const isMobile = ['xs', 'sm', 'md'].includes(width);
  const [isFullSideBar, setIsFullSideBar] = useState(isFull);
  const dispatch = useDispatch();
  const shiftTopbar = isFullSideBar && !isMobile;
  const shiftContent = isFullSideBar && !isMobile;

  const handleToggleOpen = () => {
    setIsFullSideBar(!isFullSideBar);
    dispatch(setIsFullSidebar(!isFullSideBar));
  };

  return (
    <>
      <Topbar
        className={classNames(classes.topbar, {
          [classes.topbarShift]: shiftTopbar
        })}
        title={title}
        childTitle={childTitle}
        translate={translate}
      />
      <Drawer
        anchor="left"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isFullSideBar,
          [classes.drawerClose]: !isFullSideBar,
        })}
        classes={{ paper: clsx(classes.sideBarBg, {
          [classes.drawerOpen]: isFullSideBar,
          [classes.drawerClose]: !isFullSideBar,
        }) }}
        open={isFullSideBar}
        variant={isMobile ? 'temporary' : 'permanent'}
      >
        <Sidebar
          isFullSideBar={isFullSideBar}
          onToggleSidebar={handleToggleOpen}
          className={classes.sidebar}
        />
      </Drawer>
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: shiftContent
        })}
      >
        {children}
      </main>
    </>
  );
};

const DashboardLayout = (props) => {
  const { routes, match, title, location, translationNameSpace, classes } = props;
  const { t } = useTranslation(translationNameSpace);
  const currentRoute = location.pathname;

  return (
    <> { routes &&
      routes.map((route, index) => {
        const comparePathResult = matchPath(currentRoute, { path: `${match.path}${route.path}` });
        if (comparePathResult && comparePathResult.isExact) {
          return (
            <Route
              key={index}
              exact
              path={`${match.path}${route.path}`}
              render={routeProps => (
                <Dashboard title={t(title)} childTitle={t(route.subtitle)} classes={classes} translate={t}>
                  <route.component {...routeProps} {...comparePathResult.params} translate={t} />
                </Dashboard>
              )}
            />
          );
        }
        return null;
      })}
    </>
  );
};

DashboardLayout.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  isLoading: state.global.isLoading,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withStyles(styles)
)(DashboardLayout);

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Custom components
import { ClientToolbar, ClientTable } from './components';
import { uploadClient, clearClient, getClients } from 'redux/client';
import { clearJob } from 'redux/job';
import { clearInvoice } from 'redux/invoice';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
// Component styles
import styles from './style';

class ClientList extends Component {
  constructor(props) {
    super(props);
    this.processClientImport = this.processClientImport.bind(this);
    this.handleExportClients = this.handleExportClients.bind(this);
  }

  componentDidMount() {
    this.props.clearClient();
    this.props.clearJob();
    this.props.clearInvoice();
  }

  processClientImport(file) {
    const { uploadClient } = this.props;
    const formData = new FormData();
    formData.append('file',file);
    uploadClient(formData).then(response => {
      if (response.status === 200) {
        showSuccessMessage(response.data.msg);
      }
    }, error => {
      showErrorMessage(error);
    });
  }

  handleExportClients() {
    const { getClients } = this.props;
    getClients(
      { limit: 5000,
        skip: 0,
        includingArchived: true,
        csv: 1
      }
    ).then((response) => {

      if (response.status === 200 && response.data.url) {
        window.location = response.data.url;
      }

    }, (error) => {
      showErrorMessage(error);
    });
  }

  render() {
    const { classes, translate, currentUser } = this.props;

    return (

      <div className={classes.root}>
        <ClientToolbar
          currentUser={currentUser}
          translate={translate}
          processClientImport={this.processClientImport}
          handleExportClients={this.handleExportClients}
        />

        <div className={classes.content}>
          <ClientTable translate={translate} />
        </div>
      </div>
    );
  }
}

ClientList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  clearClient: () => dispatch(clearClient()),
  getClients: (options) => dispatch(getClients(options )),
  uploadClient: (formData) => dispatch(uploadClient(formData)),
  clearJob: () => dispatch(clearJob()),
  clearInvoice: () => dispatch(clearInvoice()),
});

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ClientList);
import React from 'react';
import Select, { components } from 'react-select';
import { FormControl, InputLabel, FormHelperText, withStyles } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const styles = {
  formControl: {
    '& .select-label, & .label': {
      marginTop: '-29px',
      fontSize: '11px',
      zIndex: 99,
      backgroundColor: 'white',
      left: '5px',
      padding: '0 5px'
    },
    '& .select__menu': {
      zIndex: 999,
      textAlign: 'left',
    },
    '& .select__multi-value': {
      borderRadius: '15px',
      padding: '0 4px',
      height: 22
    },
    '& .select__multi-value__label': {
      lineHeight: '16px',
      paddingRight: '5px',
    },
    '& .select__multi-value__remove': {
      border: '1px solid #999',
      borderRadius: '50%',
      height: 16,
      width: 16,
      padding: 1,
      marginTop: 3
    }
  }
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator className="dropdown-indicator" {...props}>
      <ArrowDropDown />
    </components.DropdownIndicator>
  );
};

export default withStyles(styles)(props => {
  const { options, input, label, helperText, isSearchable, isClearable,
    isMulti, margin, classes, required, ...rest } = props;
  const { t } = useTranslation('Common');

  const selectOptions = options.map(item => {
    return {
      ...item,
      value: item.id || item.value,
      label: item.name || item.label || t(item.keyTranslate)
    };
  });

  function onChangeValue(selectedOption) {
    if (!selectedOption) {
      input.onChange(null);
    } else if (isMulti) {
      input.onChange(selectedOption.map(item => item.value));
    } else {
      input.onChange(selectedOption.value);
    }
  }

  function formatValue(getInput) {
    if (isMulti) {
      return selectOptions.filter((option) => getInput.value.includes(option.value));
    }
    return selectOptions.find((option) => getInput.value === option.value);

  }

  return (
    <FormControl
      margin={margin || 'normal'}
      fullWidth
      className={classes.formControl}
      error={props.meta.touched && props.meta.error}
    >
      <InputLabel className="select-label">{required ? `${label} *` : label}</InputLabel>
      <Select
        {...rest}
        value={formatValue(input)}
        // selected option is the object {value: *, label: * }
        onChange={(selectedOption) => onChangeValue(selectedOption)}
        // onBlur = {() => input.onBlur(input.value)}s
        components={{ DropdownIndicator }}
        isSearchable={isSearchable || true}
        isClearable={isClearable || false}
        label={label || 'Select Item'}
        isMulti={isMulti || false}
        options={selectOptions}
      />

      {
        helperText &&
        <FormHelperText className="select-helper-text">{helperText}</FormHelperText>
      }
    </FormControl>
  );
});


import React from 'react';

import { Typography, Box, Button } from '@material-ui/core';
import styles from '../../components.module.scss';
import CopySrc from 'assets/images/dashboard/copy.svg';
import { showSuccessMessage } from 'lib/notifier';


const renderLocation = ({ input, label, translate }) => {
  if (!input.value)
    return null;
  const { name, unitNumber, address } = input.value;
  const formattedAddress = unitNumber ? [unitNumber, address].join(', ') : address;
  const handleCopy = () => {
    navigator.clipboard.writeText(formattedAddress);
    showSuccessMessage(translate('Common:textCopied'));
  };
  return (
    <Box display="flex" flexDirection="column">
      {label && <Typography className={styles.label}>{label}</Typography>}
      <Box>
        <Typography className='font_bold'>{name}</Typography>
        <Typography>{unitNumber}</Typography>
        <Typography>{address}</Typography>
        <Button
          onClick={handleCopy}
          variant="contained"
          className={styles.btn_copy_address}
        >
          <img className="mr_5" src={CopySrc} alt="CopySrc" />
          {translate('copy')}
        </Button>
      </Box>
    </Box>
  );
};

export default renderLocation;

import { ValidationScheduleDatetime } from 'components/validations';

export default values => {
  let errors = { message: {} };

  if (!values.title) {
    errors = {
      ...errors,
      title: 'required',
      message: {
        ...errors.message,
        title: 'visitTitleRequired'
      }
    };
  }

  // Validate date and time for visit
  errors = ValidationScheduleDatetime(values, errors);

  return errors;
};

import React, { useEffect } from 'react';

import { reduxForm, Field, change } from 'redux-form';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import styles from '../../components.module.scss';

import { showErrorMessage } from 'lib/notifier';

import { fetchServiceReportList } from 'redux/job';
import { getDateTimeFormat } from 'lib/formatter';

const FORM_NAME = 'ServiceReportForm';

const RenderTeamTable = ({ input, translate }) => {
  if (!Array.isArray(input.value)) return null;

  const handleClick = (data) =>
    window.open(`/jobs/${data.jobId}/service-report/${data.id}/view`, '_blank');

  return (
    <Box mt={1} display="flex" flexDirection="column">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={styles.header_text}>
              {translate('reportNo')}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate('type')}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate('issuedAt')}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate('lastUpdated')}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate('status')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {input.value.map((row) => (
            <TableRow
              className={styles.table_hover}
              onClick={() => handleClick(row)}
              key={row.id}
            >
              <TableCell className={styles.table_content}>
                {row.reportNumber}
              </TableCell>
              <TableCell className={styles.table_content}>
                {translate(`${row.reportFor}_${row.reportType}`)}
              </TableCell>
              <TableCell className={styles.table_content}>
                {moment(row.requestedAt).format(getDateTimeFormat())}
              </TableCell>
              <TableCell className={styles.table_content}>
                {moment(row.updatedAt).format(getDateTimeFormat())}
              </TableCell>
              <TableCell className={styles.table_content}>
                {row.status}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const ServiceReport = (props) => {
  const { translate, visitDetails: { job } = {} } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!job || !job.publicId) return null;

    const query = {
      jobId: job.publicId,
      reportFors: ['JOB', 'VISIT'],
      limit: 100,
    };
    (async () => {
      const result = await dispatch(
        fetchServiceReportList(query)
      ).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
      dispatch(
        change(
          FORM_NAME,
          'serviceReport',
          (result.data && result.data.data) || []
        )
      );
    })();
  }, [job, dispatch]);

  return (
    <Box p={2}>
      <Typography variant="h3" className={styles.modal_title}>
        {translate('serviceReport')}
      </Typography>

      <Field
        name="serviceReport"
        component={RenderTeamTable}
        translate={translate}
      />
    </Box>
  );
};

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(ServiceReport);

export default theme => ({
  root: {},
  formField: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '360px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  fullTextField: {
    width: 480,
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  saveButton: {
    marginLeft: 10,
  }
});

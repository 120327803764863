import { Box } from '@material-ui/core';
import React from 'react';
import { StatusChip } from '..';
import ReviewChip from '../ReviewChip';
import './styles.scss';
import { JOB_STATUS_ENTERPRISE_DASHBOARD } from 'common/constant';


export const EnterpriseStatusChip = ({ groupStatusFilter, archived }) => {
  const getReviewStatus = () =>{
    const jobStatuses = [JOB_STATUS_ENTERPRISE_DASHBOARD.CANCELLED_AND_CLOSED,
      JOB_STATUS_ENTERPRISE_DASHBOARD.COMPLETED_AND_CLOSED];
    if (jobStatuses.includes(groupStatusFilter)) {
      return 'REVIEWED';
    }
    return 'review';
  };

  if (!groupStatusFilter) return null;
  return (
    <Box display="flex" alignItems="center">
      <StatusChip
        job
        status={groupStatusFilter}
        reviewStatus={getReviewStatus()}
        isForEnterprise
        archived={archived}
      />
      {
        getReviewStatus() === 'IN_REVIEW' && <ReviewChip />
      }
    </Box>
  );
};
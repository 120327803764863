import React from 'react';

import { Grid } from '@material-ui/core';
import TableList from './components/table';
import { useTranslation } from 'react-i18next';

const EnterpriseAccount = (props) => {
  const { t } = useTranslation('Enterprise');

  return (
    <Grid style={{ padding: 24 }}>
      <TableList {...props} t={t} />
    </Grid>
  );
};

export default EnterpriseAccount;

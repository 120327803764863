import React, { useState } from 'react';
import { Box, Button, Typography, Tooltip } from '@material-ui/core';
import styles from '../components.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { CategoryModal } from 'pages/Dashboard/components';
import { updateCategories } from 'redux/dashboard';


const CategorySelector = (props) => {
  const { selectedCategory = [], setSelectedCategory, t } = props;
  const dispatch = useDispatch();
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  const companyDetails = useSelector(
    (state) =>
      state.auth.currentUser.companies && state.auth.currentUser.companies[0]
  );

  const handleCloseSelectCategoryModal = () => setOpenCategoryModal(false);
  const handleOpenSelectCategoryModal = () => setOpenCategoryModal(true);

  const handleCategorySelection = (categories) => {
    setSelectedCategory(categories);
    dispatch(updateCategories(categories));
    handleCloseSelectCategoryModal();
  };

  const categoriesDisplay =
    (Array.isArray(selectedCategory) &&
    selectedCategory.map((category) => category.name)) || [];

  return (
    <>
      <Box display="flex" alignItems="center" ml={2}>
        <Typography
          className={`${styles.font_weight_700} ${styles.text_margin} ${styles.text_responsive}`}
        >
          {t('category').toUpperCase()}
        </Typography>
        <Tooltip title={categoriesDisplay.join(', ')}>
          <Button
            className={styles.btnSelectCategory}
            onClick={handleOpenSelectCategoryModal}
          >
            {categoriesDisplay.length ? (
              <>
                <Typography className={`${styles.textWrapper} ${styles.text_responsive}`}>
                  {categoriesDisplay.slice(0, 2).join(', ')}
                </Typography>
                <Box ml={1}>
                  <EditIcon />
                </Box>
              </>
            ) : (
              <>
                {t('selectCategory')}
                <Box ml={1}>
                  <AddIcon />
                </Box>
              </>
            )}
          </Button>
        </Tooltip>
      </Box>
      {openCategoryModal && (
        <CategoryModal
          open={openCategoryModal}
          handleCloseSelectCategoryModal={handleCloseSelectCategoryModal}
          translate={t}
          categories={selectedCategory}
          handleCategorySelection={handleCategorySelection}
          isHideManageCategoryTag
          enterpriseAccountId={companyDetails.id}
        />
      )}
    </>
  );
};

export default CategorySelector;

import React, { forwardRef } from 'react';

// Externals
import classNames from 'classnames';

// Material components
import {
  Button,
  ButtonGroup,
  MenuItem,
  MenuList,
  Typography,
  Divider,
  withStyles,
  Menu,
} from '@material-ui/core';

// Material icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = (theme) => ({
  moreIcon: {
    marginRight: theme.spacing(1),
  },
  sectionTitle: {
    color: theme.palette.text.primary,
    opacity: 0.7,
    paddingLeft: 4,
    paddingTop: 4,
    paddingBottom: 4,
  },
  popoverWithArrow: {
    '& .MuiPopover-paper': {
      padding: 8,
      minWidth: 180,
      marginTop: -10,
      overflowX: 'initial',
      overflowY: 'initial',
      borderColor: theme.palette.secondary.light,
      borderWidth: 1,
      borderStyle: 'solid',
      '&:before': {
        content: '""',
        position: 'absolute',
        right: 10,
        bottom: -9,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 8px 8px 8px',
        borderColor: `transparent transparent ${theme.palette.secondary.light} transparent`,
        zIndex: 9998,
        transform: 'rotate(180deg)',
      },
    },
  },
  btnStyle: {
    minWidth: 120,
    paddingLeft: 8,
    paddingRight: 0,
    borderRadius: 4,
  },
});

export const MenuSection = withStyles(styles)(
  forwardRef((props, ref) => {
    const {
      classes,
      handleClose,
      lastIndex,
      menuItems,
      selected,
      type,
      menuTitle
    } = props;
    return (
      <MenuList innerRef={ref}>
        {menuTitle && (
          <Typography disabled className={classes.sectionTitle}>
            {menuTitle}
          </Typography>
        )}
        {menuItems &&
          menuItems.map((item) => (
            <MenuItem
              key={item.label}
              disabled={!!selected && selected === item.id}
              onClick={() => {
                item.action();
                handleClose();
              }}
              type={type}
            >
              {item.label}
            </MenuItem>
          ))}
        {!lastIndex && <Divider variant="fullWidth" />}
      </MenuList>
    );
  })
);

export default withStyles(styles)((props) => {
  const { classes, type, title, action, menu, menuId } = props;
  const anchorRef = React.useRef(null);
  const [selectedIndex /* , setSelectedIndex */] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const { menu } = data;

  const handleClick = (event) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button type={type} onClick={() => action && action()}>
          {title}
        </Button>
        {menu && menu.length > 0 && (
          <Button
            color="primary"
            size="small"
            variant="contained"
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-controls={menuId}
            onClick={handleClick}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      {menu && menu.length > 0 && (
        <Menu
          id={menuId}
          elevation={1}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          className={classNames(classes.arrow, classes.popoverWithArrow)}
        >
          {menu &&
            menu.length > 0 &&
            menu.map((menuSection, index) => (
              <MenuSection
                handleClose={handleClose}
                lastIndex={index + 1 !== menuSection.menuItems.length}
                key={menuSection.title}
                selected={index === selectedIndex}
                type={type}
                menuTitle={menuSection.title}
                menuItems={menuSection.menuItems}
              />
            ))}
        </Menu>
      )}
    </>
  );
});

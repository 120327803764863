import palette from '../palette';

export default {
  root: {
    color: '#666666 !important',
    '&:hover:not($disabled)': {
      backgroundColor: palette.background.default,
    },
    '&:hover fieldset': {
      borderColor: `${palette.common.primary_yellow} !important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${palette.common.primary_yellow} !important`,
    },
  }
};

import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch, connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { getCategories } from 'redux/category';
import { compose } from 'redux';

// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grow,
  IconButton,
  ListItemIcon,
  ListItem,
  List,
  Grid,
  Collapse,
  Typography,
  Paper,
  Link,
  InputBase,
  CircularProgress,
  Backdrop,
  Box,
} from '@material-ui/core';

// Customised components
import LegacyCheckbox from './components/checkbox';

// Material icons
import {
  Folder as FolderIcon,
  FolderOpen as FolderOpenIcon,
  ExpandLess,
  Close as CloseIcon,
  ChevronRight as ChevronRightIcon,
  Search as SearchIcon,
  Launch as LaunchIcon,
} from '@material-ui/icons';

// Component styles
import styles from './styles.module.scss';
import { useLocation, useParams } from 'react-router';

const SEARCH_TIMEOUT = 800;
let timer = 0;
const FORM_NAME = 'jobCategoriesForm';
let selectedList = {};

// We should mofidy input categories as array to object to work on field with checkbox component
function formatCategories(categoryies) {
  if (!categoryies || categoryies.length === 0) return {};

  let result = {};
  categoryies.forEach((category) => {
    result = {
      ...result,
      [category.id]: {
        ...category,
        checked: true,
      },
    };
  });
  return result;
}

const formSelector = formValueSelector(FORM_NAME);

const mapStateToProps = (_state, ownProps) => {
  return {
    initialValues: {
      categories: formatCategories(ownProps.categories),
      errorComp: '',
    },
    selectedCategoryList: formSelector(_state, 'categories'),
  };
};

const CategoryRenderTemplate = (props) => {
  const {
    category,
    haveSubCategories = false,
    catIcon,
    updateCollapse,
    collapseOpened,
    translate,
    selectedCategoryList,
  } = props;

  return (
    <ListItem className={styles.categoryItem}>
      {haveSubCategories && (
        <div className={styles.collapseIconWrapper} onClick={updateCollapse}>
          {collapseOpened ? <ExpandLess /> : <ChevronRightIcon />}
        </div>
      )}
      <ListItemIcon> {catIcon} </ListItemIcon>
      <Field
        key={category.id}
        name={`categories.${category.id}`}
        component={LegacyCheckbox}
        label={category.name}
        labelPlacement="start"
        isDisabled={!category.accessible}
        color="primary"
        item={category}
        haveSubCategories={haveSubCategories}
        formName={FORM_NAME}
        labelClassName={
          !category.accessible
            ? styles.labelDisable
            : styles.labelJustifyContent
        }
        selectedCategoryList={selectedCategoryList}
        translate={translate}
      />
    </ListItem>
  );
};

let CategoryModal = (props) => {
  const {
    open,
    handleCloseSelectCategoryModal,
    translate,
    handleSubmit,
    handleCategorySelection,
    isHideManageCategoryTag = false,
    invalid,
    pristine,
    enterpriseAccountId,
    selectedCategoryList,
    isEnterprise,
    categories,
  } = props;
  const dispatch = useDispatch();
  const [collapseCatIds, setCollapseCatId] = useState([]);
  const [newCategories, setCategories] = useState([]);
  const [isSearchingCategory, setIsSearchingCategory] = useState(false);
  async function getCompanyCategories(options = {}) {
    setIsSearchingCategory(true);
    const result = await dispatch(getCategories(options));
    if (result.status === 200) {
      setCategories(result.data.data);
    }
    setIsSearchingCategory(false);
  }
  useEffect(() => {
    // TODO: will implement load more in next release
    getCompanyCategories({ limit: 100, enterpriseAccountId });
  }, []);

  // updateCollapseStatus handle collapse categories list
  const updateCollapseStatus = (catId) => {
    if (!catId) return;
    const index = collapseCatIds.findIndex((item) => item === catId);
    const shadowCollapseCatIds = collapseCatIds.slice();
    if (index === -1) {
      shadowCollapseCatIds.push(catId);
    } else {
      shadowCollapseCatIds.splice(index, 1);
    }
    setCollapseCatId(shadowCollapseCatIds);
  };

  function modifyValue(value) {
    const selectedCategories = [];
    const formValue = value.categories || {};
    const formValueArray = Object.keys(formValue);
    if (!formValueArray.length) return handleCategorySelection([]);

    formValueArray.forEach((item) => {
      const categoryDetails = formValue[item];
      if (!categoryDetails || !categoryDetails.checked) return false;
      selectedCategories.push({
        id: categoryDetails.id,
        name: categoryDetails.name,
        parentCategoryId: categoryDetails.parentCategoryId,
      });
    });

    return handleCategorySelection(selectedCategories);
  }

  const filterCategory = (textSearch) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      getCompanyCategories({ textSearch, limit: 100, enterpriseAccountId });
    }, SEARCH_TIMEOUT);
  };

  const changeCategoryValue = (value) =>
    dispatch(change(FORM_NAME, 'categories', value));

  const mapTreeCategoriesToCategoryObject = (categoryList) => {
    categoryList.forEach((category) => {
      selectedList = {
        ...selectedList,
        [category.id]: {
          ...category,
          checked: true,
        },
      };
      if (
        !Array.isArray(category.childrenCategories) ||
        !category.childrenCategories.length
      )
        return null;
      mapTreeCategoriesToCategoryObject(category.childrenCategories);
    });
  };

  const handleSelectAll = () => {
    mapTreeCategoriesToCategoryObject(newCategories);
    changeCategoryValue(selectedList);
  };

  const handleDeselect = () => changeCategoryValue({});

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      transition={Grow}
      classes={{
        paper: styles.categoryDialog,
        scrollPaper: styles.scrollPaperCustom,
      }}
    >
      <form className={styles.form} onSubmit={handleSubmit(modifyValue)}>
        {/** error comp is created to keep isInvalid to disabled button
         * collapse comp not mounted yet, so when error trigger it not update invalid state
         */}
        <Field name="errorComp" component={Typography} />
        <DialogTitle disableTypography className={styles.categoryDialogTitle}>
          <Typography variant="h6" className={styles.categoryDialogTitleText}>
            {translate('Category:selectCategory')}
          </Typography>
          {open ? (
            <IconButton
              aria-label="Close"
              className={styles.closeButton}
              onClick={handleCloseSelectCategoryModal}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent dividers className={styles.dialogContent}>
          {!isEnterprise ? (
            <Grid
              item
              container
              spacing={1}
              className={styles.searchBoxWrap}
              justify="center"
            >
              <Grid
                item
                md={isHideManageCategoryTag ? 12 : 8}
                sm={isHideManageCategoryTag ? 12 : 8}
              >
                <Paper className={styles.searchBox}>
                  <IconButton className={styles.iconButton} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    autoFocus
                    className={styles.inputSearch}
                    placeholder={translate('Common:searchCategory')}
                    inputProps={{ 'aria-label': 'search categories' }}
                    onChange={(e) => filterCategory(e.target.value)}
                  />
                </Paper>
              </Grid>
              {!isHideManageCategoryTag && (
                <Grid
                  item
                  md={4}
                  sm={4}
                  container
                  justify="flex-end"
                  alignContent="center"
                >
                  <Link
                    href="/category"
                    classes={{ root: styles.textPrimaryBtn }}
                    target="_blank"
                  >
                    <LaunchIcon />
                    {translate('Category:manageCategory')}
                  </Link>
                </Grid>
              )}
            </Grid>
          ) : null}
          <Grid item md={12} sm={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={handleDeselect}>
                {translate('Category:clearSelection')}
              </Button>
              <Button onClick={handleSelectAll}>
                {translate('Category:selectAll')}
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            justify="flex-start"
            style={{ position: 'relative' }}
          >
            {isSearchingCategory && (
              <Backdrop open={isSearchingCategory} className={styles.backdrop}>
                <CircularProgress />
              </Backdrop>
            )}
            <List className={styles.categories}>
              {newCategories.map((lv1Cat, index) => {
                const lv2Categories = lv1Cat.childrenCategories || [];
                const collapseOpenedLv1 = collapseCatIds.includes(lv1Cat.id);

                return (
                  <div key={index} className={styles.lv1CatWrapper}>
                    <CategoryRenderTemplate
                      translate={translate}
                      catIcon={<FolderIcon />}
                      category={lv1Cat}
                      collapseOpened={collapseOpenedLv1}
                      haveSubCategories={lv2Categories.length > 0}
                      updateCollapse={(_e) => updateCollapseStatus(lv1Cat.id)}
                      selectedCategoryList={selectedCategoryList}
                    />

                    <div className={styles.nestedLv1}>
                      {/* Layer 2 (Sub Categories) =========================================== */}
                      {lv2Categories.length > 0 &&
                        lv2Categories.map((lv2Cat, index2) => {
                          const lv3Categories = lv2Cat.childrenCategories || [];
                          const collapseOpenedLv2 = collapseCatIds.includes(
                            lv2Cat.id
                          );

                          return (
                            <Collapse
                              key={index2}
                              in={collapseOpenedLv1}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List className={styles.lv2CatWrapper}>
                                <CategoryRenderTemplate
                                  translate={translate}
                                  category={lv2Cat}
                                  catIcon={<FolderIcon />}
                                  collapseOpened={collapseOpenedLv2}
                                  haveSubCategories={lv3Categories.length > 0}
                                  updateCollapse={(_e) =>
                                    updateCollapseStatus(lv2Cat.id)
                                  }
                                  selectedCategoryList={selectedCategoryList}
                                />
                                <div className={styles.nestedLv2}>
                                  {/* Layer 3 (Sub sub-categories)  =========================================== */}
                                  {lv3Categories.length > 0 &&
                                    lv3Categories.map((lv3Cat, index3) => {
                                      return (
                                        <Collapse
                                          key={index3}
                                          in={collapseOpenedLv2}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <List
                                            className={styles.lv3CatWrapper}
                                          >
                                            <CategoryRenderTemplate
                                              translate={translate}
                                              category={lv3Cat}
                                              catIcon={<FolderOpenIcon />}
                                              selectedCategoryList={
                                                selectedCategoryList
                                              }
                                            />
                                          </List>
                                        </Collapse>
                                      );
                                    })}
                                </div>
                              </List>
                            </Collapse>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </List>
          </Grid>
        </DialogContent>
        <DialogActions className={styles.categoryDialogAction}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              size="small"
              className={
                pristine || invalid ? styles.btnDisabled : styles.buttonSubmit
              }
              type="submit"
              disabled={pristine || invalid}
            >
              {translate('Common:apply')}
            </Button>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const validate = (values, ownProps) => {
  const errors = {};
  // isHideManageCategoryTag come from dashboard
  const { isHideManageCategoryTag } = ownProps;
  if (!isHideManageCategoryTag) return errors;

  const selectedCategories = Object.keys(values.categories || {});
  const checkedCategories = selectedCategories.filter(
    (item) => values.categories[item].checked
  );

  if (!checkedCategories.length) errors.errorComp = 'chooseOneTagError';
  return errors;
};

CategoryModal = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: true,
  validate,
})(CategoryModal);

export default compose(connect(mapStateToProps, null))(CategoryModal);

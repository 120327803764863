import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import styles from './styles.module.scss';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import wmLogo from 'assets/images/timesheet/notes.png';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className={styles.tab_panel}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const VisitDetailTabs = (props = {}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { visitDetails, fullName, translate } = props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToClientDetails = () =>
    window.open(`/clients/${visitDetails && visitDetails.job && visitDetails.job.client && visitDetails.job.client.id}`, '_blank');

  const goToJobDetails = () =>
    window.open(`/jobs/${visitDetails && (visitDetails.job || {}).publicId}`, '_blank');

  return (
    <div className={classes.root}>
      <AppBar
        className={styles.appBarVisit}
        classes={{ root: styles.app_bar }}
        position="static"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{ indicator: classes.indicatorVist }}
        >
          <Tab className={styles.tabBarVisit} label={translate('Common:info')} />
          <Tab className={styles.tabBarVisit} label={translate('Common:client')} />
          <Tab className={styles.tabBarVisit} label={translate('Common:notes')} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{translate('Common:job')}</Typography>
          </Grid>
          <Grid item className={styles.visitDetails} xs={12}>
            <Grid item xs={2}>
              ID
            </Grid>
            <Grid item container justify='space-between' xs={10} onClick={goToJobDetails} style={{ cursor: 'pointer' }}>
              <Typography>{visitDetails && (visitDetails.job || {}).publicId}</Typography>
              <KeyboardArrowRightIcon />
            </Grid>
          </Grid>
          <Grid item className={styles.visitDetails} xs={12}>
            <Grid item xs={2}>
              {translate('Common:type')}
            </Grid>
            <Grid item xs={10}>
              On-off
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{translate('Common:clientDetails')}</Typography>
          </Grid>
          <Grid item className={styles.visitDetails} xs={12}>
            <Grid item xs={2}>
              {translate('Common:name')}
            </Grid>
            <Grid onClick={goToClientDetails} item container xs={10} justify='space-between' style={{ cursor: 'pointer' }}>
              <Typography>{fullName}</Typography>
              <KeyboardArrowRightIcon />
            </Grid>
          </Grid>
          <Grid item className={styles.visitDetails} xs={12}>
            <Grid item xs={2}>
              {translate('Common:email')}
            </Grid>
            <Grid item xs={10}>
              {visitDetails && visitDetails.job && visitDetails.job.client
                && visitDetails.job.client.email ?
                  <Typography>{visitDetails.job.client.email}</Typography>
                : <Typography>{translate('Common:noEmailRecorded')}</Typography>}
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        {visitDetails && visitDetails.notes ? (
          <Typography variant="h4">{visitDetails.notes}</Typography>
        ) : (
          <div className={styles.notes_container}>
            <img className={styles.img_notes} alt="note_image" src={wmLogo} />
            <Typography variant="h5">{translate('Timesheet:noNotes')}</Typography>
          </div>
        )}
      </TabPanel>
    </div>
  );
};

export default VisitDetailTabs;

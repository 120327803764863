import React, { useState } from 'react';
import { Card, Tab, Tabs, Box, Typography, Grid } from '@material-ui/core';

import { MediaTab, ServiceReport, VisitList } from './components';
import styles from './tabs.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={styles.borderTop}
      role='tabpanel'
      role='tabpanel'
      hidden={value !== index}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const JobDetailsTab = (props) => {
  const { t, jobDetails } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const renderLabel = (label, total) => {
    return (
      <Grid container>
        <Typography>{label}</Typography>
        <Typography className={styles.total}>{total || 0}</Typography>
      </Grid>
    );
  };

  const renderMediaLabel = (label, total) => {
    const getPhaseTotal = () => {
      const evaluationPhase = total.find((phase) => phase.media_phase === '0')  || { total: 0 };
      const afterJobPhase = total.find((phase) => phase.media_phase === '1')  || { total: 0 };
      const otherPhase = total.find((phase) => phase.media_phase === '2')  || { total: 0 };

      return `${evaluationPhase.total}/${afterJobPhase.total}/${otherPhase.total}`;
    };
    return (
      <Grid container>
        <Typography>{label}</Typography>
        <Typography className='tab-total'>
          {total && total.length > 0 ? getPhaseTotal(total) : '0/0/0'}
        </Typography>
      </Grid>
    );
  };
  return (
    <Box mt={1}>
      <Card>
        <Tabs
          classes={{ indicator: styles.indicator }}
          value={value}
          onChange={handleChange}
        >
          <Tab
            label={renderMediaLabel(t('mediaFile'), jobDetails.totalMedia)}
          />
          <Tab label={renderLabel(t('serviceReport'), jobDetails.createdSr)} />
          <Tab
            label={renderLabel(
              t('visits'),
              jobDetails.visits && jobDetails.visits.length
            )}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <MediaTab {...props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ServiceReport {...props} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <VisitList {...props} />
        </TabPanel>
      </Card>
    </Box>
  );
};

export default JobDetailsTab;

import React, { useState, useEffect } from 'react';

// Externals
import { Link, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change, formValueSelector, submit } from 'redux-form';
import { setModalVisibility, setIsSaving } from 'redux/quote';
import { saveClientDetails } from 'redux/client';
import { addProperty, getProperties } from 'redux/property';

// Material components
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

// Material icons
import {
  Add as AddIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

// Material helpers
import { withStyles } from '@material-ui/core';

// Customised components
import { CreateClientModal } from 'pages/Dashboard/views/Jobs/components/modals';
import { showErrorMessage } from 'lib/notifier';
import { SelectClientModal, SelectPropertyModal, CreatePropertyModal } from 'pages/Dashboard/components';

// Component styles
import styles from './styles/SelectClientStyle';

const quoteForm = formValueSelector('quoteForm');

const mapStateToProps = state => ({
  quote: state.form.quoteForm && state.form.quoteForm.values,
  modalVisibility: state.quote.modalVisibility,
  modalType: state.quote.modalType,
  selectedClient: quoteForm(state, 'client'),
  selectedProperty: quoteForm(state, 'property'),
});

const mapDispatchToProps = dispatch => ({
  addProperty: (values, callback) => dispatch(addProperty(values, callback)),
  getProperties: (clientId, callback) => dispatch(getProperties(clientId, callback)),
  saveClientDetails: (values, callback) => dispatch(saveClientDetails(values, callback)),
  setIsSaving: (value) => { dispatch(setIsSaving(value)); },
  setModalVisibility: (visibility, type) => dispatch(setModalVisibility(visibility, type)),
  setSelectedClient: (client) => dispatch(change('quoteForm', 'client', client)),
  setSelectedProperty: (property) => dispatch(change('quoteForm', 'property', property)),
  submitNewProperty: () => dispatch(submit('newProperty')),
  updateJobTitle: (title) => dispatch(change('quoteForm', 'jobTitle', title)),
});

function SelectClient(props) {
  const {
    addProperty,
    classes,
    quote,
    modalVisibility,
    modalType,
    saveClientDetails,
    selectedClient,
    setIsSaving,
    setSelectedClient,
    setSelectedProperty,
    setModalVisibility,
    submitNewProperty,
    translate,
    updateJobTitle
  } = props;
  const [openSelectClient, setOpenSelectClient] = useState(false);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [openSelectProperty, setOpenSelectProperty] = useState(false);
  const [openCreateProperty, setOpenCreateProperty] = useState(false);

  useEffect(() => {
    if (modalType === 'client') {
      setOpenSelectClient(modalVisibility);
    } else if (modalType === 'property') {
      setOpenSelectProperty(modalVisibility);
    };
    // props.resetQuoteForm();

  }, [modalVisibility, modalType]);

  function handleClick() {
    setOpenSelectClient(!openSelectClient);
    setModalVisibility(false, null);
  };

  function handleClose(type) {
    if (type === 'select') {
      setOpenSelectClient(false);
      setOpenSelectProperty(false);
      setModalVisibility(false, null);
      setIsSaving(false);
    } else if (type === 'create') {
      setOpenCreateClient(false);
      setOpenCreateProperty(false);
      setModalVisibility(false, null);
      setIsSaving(false);
    }
  };

  function handleClientSelect(client) {
    const { id, displayName, properties, emails, phoneNumbers } = client;

    const clientName = displayName;
    if (id && clientName) {
      // Update client name to job title once user chose client. Just only work for the first time
      let { jobTitle } = quote;
      jobTitle = jobTitle && jobTitle.replace(/{clientName}/gi, clientName);
      updateJobTitle(jobTitle);
      setSelectedClient({
        id,
        name: clientName,
        contactEmails: emails,
        contactPhones: phoneNumbers,
      });
    };

    if (properties && properties.length > 0) {
      if (properties.length < 2) {
        const property = properties[0];
        setSelectedProperty(property);
        setOpenSelectClient(false);
        setModalVisibility(false, null);
      } else if (properties.length > 1) {
        setOpenSelectProperty(true);
        setOpenSelectClient(false);
        setModalVisibility(false, null);
      };
    } else if (properties.length <= 0) {
      setOpenCreateProperty(true);
      setOpenSelectClient(false);
      setModalVisibility(false, null);
    };
  };

  function handleAddNewClient() {
    setOpenSelectClient(false);
    setOpenCreateClient(true);
    setModalVisibility(false, null);
  };

  function handlePropertySelect(property) {
    setSelectedProperty(property);
    setOpenSelectProperty(false);
    setModalVisibility(false, null);
  };

  function handleAddNewProperty() {
    setOpenSelectProperty(false);
    setOpenCreateProperty(true);
    setModalVisibility(false, null);
  };

  function prepareClientDataBeforeSave(values) {
    const { properties } = values;

    const isBillingAddress = properties.find(item => item.isBillingAddress);
    if (isBillingAddress) {
      delete isBillingAddress['isBillingAddress'];
      Object.assign(values, { billingAddress: isBillingAddress });
    }

    return values;
  };

  function handleSubmitNewClient(client) {
    const clientDetails = prepareClientDataBeforeSave(client);

    saveClientDetails(clientDetails, (response) => {
      if (response.status === 200) {
        setOpenCreateClient(false);
        handleClientSelect(response.data);
      } else {
        showErrorMessage(response.data.message);
      };
    });
  };

  function handleSubmitNewProperty(values) {
    const callback = (newProperty) => {
      setSelectedProperty(newProperty);
      setOpenCreateProperty(false);
      setOpenSelectProperty(false);
    };

    values.ownerId = selectedClient.id;

    addProperty(values, callback);
  };

  return (
    <>
      <Grid item xs={12} className={classes.jobClientDetail}>
        <Grid item container direction='column'>
          <Grid item container direction='row' className={classes.jobClientName}>
            {
              selectedClient && !!Object.keys(selectedClient).length ?
                <> { quote && quote.client && <>
                  <Typography variant='h1'>
                    <Trans i18nKey="Quote:quoteForClient" />
                  </Typography>
                  <Link to='#' className={classes.clientButton} onClick={handleClick}>
                    <Grid container direction='row'>
                      <Typography variant='h1' className={classes.clientNameText}>
                        {
                          quote.client.name
                        }
                      </Typography>
                      <EditIcon color='primary' className={classes.linkIcon} />
                    </Grid>
                  </Link>
                </> } </>
                :
                <Button className={classes.selectClientButton} onClick={handleClick}>
                  <AddIcon fontSize='large' className={classes.selectAddIcon} />
                  <Typography variant='h1' className={classes.clientNameText}>{translate('clientName')}</Typography>
                </Button>
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={5} alignItems='flex-end'>
        <Grid item container direction='column' />
      </Grid>
      {
        openSelectClient &&
        <SelectClientModal
          open={openSelectClient}
          handleClose={() => handleClose('select')}
          handleClientSelect={client => handleClientSelect(client)}
          handleAddNewClient={handleAddNewClient}
          translate={translate}
        />
      }
      {
        openCreateClient &&
        <CreateClientModal
          open={openCreateClient}
          handleClose={() => handleClose('create')}
          handleSubmit={(value) => handleSubmitNewClient(value)}
          handleCancelClientForm={() => handleClose('create')}
          translate={translate}
        />
      }
      {
        openSelectProperty &&
        <SelectPropertyModal
          open={openSelectProperty}
          selectedClient={selectedClient}
          handleClose={() => handleClose('select')}
          handlePropertySelect={property => handlePropertySelect(property)}
          handleAddNewProperty={handleAddNewProperty}
          translate={translate}
        />
      }
      {
        openCreateProperty &&
        <CreatePropertyModal
          open={openCreateProperty}
          selectedClient={selectedClient}
          handleClick={submitNewProperty}
          handleClose={() => handleClose('create')}
          onSubmit={handleSubmitNewProperty}
          translate={translate}
        />
      }
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SelectClient);
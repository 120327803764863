import React, { useState } from "react";
// Redux
import {
  Card,
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
  IconButton,
} from "@material-ui/core";
import { Field, FieldArray } from "redux-form";
import { TextInput } from "components/inputs";
import styles from "./styles/serviceReport.module.scss";
import AddIcon from "@material-ui/icons/Add";
import { JobMediaModal } from "../modals";
import DeleteCircle from "assets/images/service-report/deleteCircle.svg";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Grid className={styles.tabInsideWrapper}>{children}</Grid>
      )}
    </Grid>
  );
};

const renderJobImages = ({ fields, translate }) => {
  const renderImage = ({ input, index }) => {
    return (
      <Grid className={styles.imageWrapper}>
        <img
          className={styles.imageStyle}
          src={input.value}
          alt="media_image"
        />

        <Grid className={styles.toolsWrapper}>
          {/* <IconButton className="p_0 mr_5">
            <img src={EditCircle} alt="edit_circle" />
          </IconButton> */}
          <IconButton onClick={() => fields.remove(index)} className="p_0">
            <img src={DeleteCircle} alt="delete_circle" />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  return fields.map((field, index) => (
    <Grid key={index} className={styles.imageContainer}>
      <Field name={`${field}.mediaUrl`} index={index} component={renderImage} />
      <Field
        name={`${field}.caption`}
        component={TextInput}
        className="mt_10"
        placeholder={translate("addCaption")}
        variant="filled"
        margin="dense"
        classes={{ underline: styles.hideBorder }}
        multiline
        rows="3"
      />
    </Grid>
  ));
};

const JobImageCard = ({ translate }) => {
  const [tabValue, setTabValue] = useState(0);
  const [openJobMediaModal, setOpenJobMediaModal] = useState(false);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {openJobMediaModal && (
        <JobMediaModal
          stateOpen={openJobMediaModal}
          setStateOpen={setOpenJobMediaModal}
          phase={tabValue}
          translate={translate}
        />
      )}

      <Card className={`${styles.cardPadding} mt_25`}>
        <Grid
          className={styles.cardHeader}
          container
          wrap="nowrap"
          alignItems="center"
        >
          <Typography variant="h4" className="mr_20">
            {translate("jobImage")}
          </Typography>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label={translate("evaluation")} />
            <Tab label={translate("afterJob")} />
          </Tabs>
        </Grid>
        <Grid container justify="flex-end" className="mt_15">
          <Button
            onClick={() => setOpenJobMediaModal(true)}
            variant="contained"
            className={styles.borderBtn}
          >
            <AddIcon className={styles.color_primary} />
            <Typography variant="body1" className={styles.color_primary}>
              {translate("browseFromGallery")}
            </Typography>
          </Button>
        </Grid>
        <TabPanel value={tabValue} index={0} className={styles.tabPanel}>
          <FieldArray
            name="evaluation"
            component={renderJobImages}
            translate={translate}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1} className={styles.tabPanel}>
          <FieldArray
            name="afterJob"
            component={renderJobImages}
            translate={translate}
          />
        </TabPanel>
      </Card>
    </>
  );
};

export default JobImageCard;

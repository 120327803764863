import React from 'react';

// Externals
import classNames from 'classnames';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

// Material components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// Customised components
import { ClientForm } from 'pages/Dashboard/views/Clients/components';

// Component styles
import { CreateClientModalStyle } from './styles';

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  submitClientForm: () => dispatch(submit('clientForm')),
});

function CreateClientModal(props) {
  const {
    classes,
    open,
    handleClose,
    handleCancelClientForm,
    handleSubmit,
    submitClientForm,
    translate,
  } = props;

  return (
    <Dialog
      maxWidth='lg'
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle disableTypography className={classNames(classes.root, classes.clientDialogTitle)}>
        <Typography variant="h6">{translate('createClient')}</Typography>
        {open ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        <ClientForm
          isModal
          onSubmit={values => handleSubmit(values)}
          handleClick={submitClientForm}
          handleCancelClientForm={handleCancelClientForm}
          translate={translate}
        />
      </DialogContent>
    </Dialog>
  );
};

export default compose(
  withStyles(CreateClientModalStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(CreateClientModal);
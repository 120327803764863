export default theme => ({
  root: {},
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1000,
    left: 0,
    right: 0,
    maxHeight: 250,
    overflow: 'auto',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
  suggestItemTitle: {
    backgroundColor: theme.palette.text.secondary,
    fontSize: 14,
  },
  sectionTitle: {
    backgroundColor: 'lightGray',
    fontSize: 14,
  },
  suggestionTitle: {
    fontWeight: 500,
    fontSize: 14,
  },
  suggestionTitleHighlighted: {
    fontWeight: 600,
  },
  suggestionDescription: {
    color: 'gray'
  },
});
import React from 'react';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

// components
import { Grid, Typography } from '@material-ui/core';
import { StatusChip } from '../../../components';
import styles from '../../details.module.scss';
import AvatarDefault from 'assets/images/enterprise/avatar-default.svg';
import Countries from 'lib/countryList';

const countryList = new Countries();
const countryCodeOptions = countryList.countryCodes();
const FORM_NAME = 'enterpriseDetailsForm';

const renderImageLogo = ({ input }) => {
  return <img className={styles.avatar} src={input.value || AvatarDefault} alt="AvatarDefault" />;
};

const renderTextField = ({ noMargin, input, label }) => {
  if (!input.value) return null;
  return (
    <Grid className={!noMargin && 'mt_15'}>
      <Typography className={styles.label}>{label}</Typography>
      <Typography variant="h5" className={styles.content}>
        {input.value}
      </Typography>
    </Grid>
  );
};

const renderCountry = ({ input, label }) => {
  if (!input.value) return null;
  let selectedCountry = {};
  for (let i = 0; i < countryCodeOptions.length; i += 1) {
    const item = countryCodeOptions[i];
    if (item.id === input.value) {
      selectedCountry = item;
      break;
    }
  }

  return (
    <Grid className="mt_15">
      <Typography className={styles.label}>{label}</Typography>
      <Typography variant="h5" className={styles.content}>
        {selectedCountry.name}
      </Typography>
    </Grid>
  );
};

const renderCategories = ({ input, label }) => {
  if (!Array.isArray(input.value) || !input.value.length) return null;
  return (
    <Grid>
      <Typography className={styles.label}>{label}</Typography>
      {input.value.map((item) => (
        <Typography key={item.id} variant="h5" className={styles.content}>
          {item.name}
        </Typography>
      ))}
    </Grid>
  );
};

const renderStatusChip = ({ input, label }) => {
  return (
    <Grid className="mt_15">
      <Typography className={styles.label}>{label}</Typography>
      <StatusChip isActive={input.value} />
    </Grid>
  );
};

const EnterpriseForm = (props) => {
  const { t } = props;
  return (
    <>
      <Grid item md={2} className={styles.horizontalSpacing}>
        <Field name="avatar" component={renderImageLogo} />
      </Grid>
      <Grid item md={4} className={styles.horizontalSpacing}>
        <Field
          noMargin
          name="registeredName"
          component={renderTextField}
          label={t('registerName')}
        />
        <Field
          name="tradingName"
          component={renderTextField}
          label={t('tradingName')}
        />
        <Field
          name="accountStatus"
          component={renderStatusChip}
          label={t('accountStatus')}
        />
      </Grid>

      <Grid item md={4} className={styles.horizontalSpacing}>
        <Field
          noMargin
          name="address"
          component={renderTextField}
          label={t('addressRegister')}
        />
        <Field name="city" component={renderTextField} label={t('city')} />
        <Field name="state" component={renderTextField} label={t('state')} />
        <Field
          name="postalCode"
          component={renderTextField}
          label={t('postalCode')}
        />
        <Field
          name="country"
          component={renderCountry}
          label={t('Common:country')}
        />
      </Grid>
      <Grid item md={2}>
        <Field
          name="currentCategories"
          component={renderCategories}
          label={t('Category:category')}
        />
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { companyDetails } = ownProps;
  return {
    initialValues: {
      registeredName: companyDetails.name,
      tradingName: companyDetails.tradingName,
      address: companyDetails.address,
      address_line_2: companyDetails.addressLine2,
      city: companyDetails.city,
      state: companyDetails.state,
      postalCode: companyDetails.zipCode,
      country: companyDetails.countryCode,
      accountStatus: companyDetails.active || false,
      currentCategories: companyDetails.categories,
      avatar: companyDetails.profilePicture,
    },
  };
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })
)(EnterpriseForm);

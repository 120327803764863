import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { toggleFilterSidebar } from 'redux/global';
import { useDispatch, useSelector } from 'react-redux';
import { getJobs } from 'redux/job';

// Material components
import { withStyles, Button } from '@material-ui/core';

import payloadPreparation from '../components/utils/payloadPreparation';

// Component styles
import styles from './styles';
import filterSrc from 'assets/images/calendar/icon_filter.svg';
import downloadSrc from 'assets/images/icons/download.svg';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';

const JobsToolbar = (props)=> {
  const dispatch = useDispatch();
  const { classes, className, translate } = props;
  const rootClassName = classNames(classes.root, className);

  const isJobsFiltering = useSelector(state => state.global.isJobFiltering || false);
  const filters = useSelector(state => state.global.filterParams);

  const handleClickDownload = async () => {
    const payload = payloadPreparation({ ...filters, exportXLSX: 1 });
    const result = await dispatch(getJobs(payload)).catch(e =>  showErrorMessage(translate('Common:someThingWentWrong')))
   
    // if (result.e) return showErrorMessage(result.e);
    if(result?.data?.message) {
      return showSuccessMessage(result?.data?.message)
      // return window.open(result.data.file, '_blank');
    } else  showErrorMessage(translate('Common:someThingWentWrong'));    
  };

  return (
    <div className={rootClassName}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          size="medium"
          variant="contained"
          style={{ border: 'none', marginRight: 15 }}
          onClick={handleClickDownload}
          className={classNames(
            'toolbar-navigation-button',
            classes.textStyle,
            classes.backgroundBtn,
            classes.shadowNone
          )}
        >
          <img className={classes.imgFilter} src={downloadSrc} alt="downloadSrc" />
          {translate('Common:export')}
        </Button>

        {/* btn toggle filer menu */}
        <Button
          size="medium"
          variant="contained"
          style={{ border: 'none', marginRight: 15 }}
          onClick={() => dispatch(toggleFilterSidebar(true))}
          className={classNames(
            'toolbar-navigation-button',
            classes.textStyle,
            classes.backgroundBtn,
            classes.shadowNone
          )}
        >
          <img className={classes.imgFilter} src={filterSrc} alt="icon_filter" />
          {translate('Common:filter')}: {isJobsFiltering ? translate('Common:on') : translate('Common:off')}
        </Button>

        <Link to='/jobs/new'>
          <Button
            color="primary"
            size="small"
            variant="contained"
          >
            {translate('addJob')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

JobsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JobsToolbar);

import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

// Material helpers
import { withStyles, Grid, Button } from '@material-ui/core';

// Material components
import {
  Dialog,
  IconButton,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';


// Component styles
import { SendNotificationFormStyles } from './styles';
// Shared components
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
} from 'pages/Dashboard/components';
import { TextInput } from 'components/inputs';

const mapStateToProps = state => ({
  initialValues: {
    title: `Message from ${state.auth.currentUser && [state.auth.currentUser.firstName, state.auth.currentUser.lastName].filter(e => e).join(' ')}`,
    message: null
  }
});

const mapDispatchToProps = dispatch => ({
});

function SendNotificationForm(props) {
  const {
    classes,
    open,
    handleClose,
    handleSubmit,
    translate,
  } = props;

  return (
    <Dialog
      maxWidth='lg'
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Portlet className={classes.dialogWrap}>
        <PortletHeader className="card-header">
          <PortletLabel title={translate('Common:sendNotification')} />
          {open ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </PortletHeader>



        <PortletContent className="overview-content">
          <form onSubmit={handleSubmit}>
            <div className={classes.formContainer}>
              <div className={classes.row}>
                <div className={classes.fieldLabel}>{translate('Common:title')}</div>
                <div className={classes.fieldWrapper}>
                  <Field
                    name="title"
                    component={TextInput}
                    variant='outlined'
                    margin='dense'
                  />
                </div>
              </div>

              <div className={classes.row}>
                <div className={classes.fieldLabel}>{translate('Common:message')}</div>
                <div className={classes.fieldWrapper}>
                  <Field
                    name="message"
                    component={TextInput}
                    variant='outlined'
                    margin='dense'
                    multiline
                    rows="5"
                  />
                </div>
              </div>

              <div className={classes.row}>
                <Grid container justify='flex-end'>
                  <Button type='submit' color='primary' variant='contained'>{translate('Common:send')}</Button>
                </Grid>
              </div>
            </div>
          </form>
        </PortletContent>
      </Portlet>

    </Dialog>
  );
};

export default compose(
  withStyles(SendNotificationFormStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(reduxForm({ form: 'workerSmsForm', destroyOnUnmount: true })(SendNotificationForm));

export default {
  // common && crumbs
  addInvoice:	'+ New Invoice',
  invoices:	'Invoices',
  invoiceDetails:	'Invoice Details',
  editInvoice:	'Edit Invoice',
  invoice:	'Invoice',

  // invoices table
  status:	'Status',
  due:	'Due On',
  created:	'Created On',
  number:	'Number',
  subject:	'Subject',
  title: 'Title',
  client:	'Client',
  price:	'Price',
  schedule:	'Schedule',
  search:	'Search',
  showArchived:	'Show Archived',
  rows:	'rows',
  of:	'of',
  createdBy: 'Created By',

  // invoice toolbar menu
  sendAs:	'Send as...',
  collectSignature:	'Collect signature',
  downloadPdf:	'Download PDF',
  print:	'Print',
  edit: 'Edit',

  // DETAILS
  backTo:	'Back to:',
  markAs:	'Mark as...',
  archive:	'Archive',
  unarchive:	'Unarchive',
  collectPayment:	'Collect payment',
  recordPayment: 'Record Payment',
  emailInvoice:	'Email invoice',
  billingAddress:	'Billing Address',
  contactDetails:	'Contact details',
  clientDetails: 'Client Details',
  invoiceNumber:	'Invoice Number',
  issued:	'Issued',
  clientNoName:	'Client has no name.',
  jobs: 'jobs',
  oneJob: '1 job',
  notSentYet: 'Not sent yet',
  notSetYet: 'Not set yet',
  signature: 'Signature',
  signedAt: 'Date',

  // product/service
  productService:	'Product/Service',
  quantity:	'Quantity',
  unitPrice:	'Unit Price (excl. tax)',
  taxRate:	'Tax Rate (%)',
  total:	'Total',
  beforeTax:	'Before \n tax',
  subtotal:	'Subtotal',
  tax:	'Tax',
  totalDue:	'Total Due',
  invoiceBalance:	'Invoice balance',
  accountBalance:	'Account balance',
  discount:	'Discount',


  // INVOICE FORM
  newInvoice:	'New Invoice',
  whichClient:	'Which client would you create this for?',
  searchClient:	'Search Client',
  saveInvoice:	'Save Invoice',
  saveAnd:	'Save and...',
  invoiceFor:	'Invoice for',
  invoiceSubject:	'Invoice subject',
  inputInvoiceSubject:	'Input invoice subject here',
  contactNo:	'Contact No',
  issuedAt:	'Issued at',
  paymentDue:	'Payment due',
  dueDate: 'Due date',
  nextStep: 'Next Step',
  selectJob: 'Select the jobs you want to invoice',
  noJobForSelection: 'There is no job available for this client',
  thankYou: 'Thank you for your business. Please contact us with any questions regarding this Quote.',
  noNote: 'No notes recorded.',
  note: 'Note',
  // line items
  lineItems:	'Line Items',
  serviceProduct:	'Service / Product',
  qty:	'Qty.',
  totalBeforeTax:	'Total (Before tax)',
  item:	'Item',
  addLineItem:	'+ Add Line Item',
  clientMessage:	'Client Message',
  totalPrice: 'Total Price',

  internalNotes:	'Internal notes',
  noteDetails:	'Note details',
  sendingEmail: 'Sending invoice email...',
  sendingSMS: 'Sending invoice sms...',
  sentEmail: 'Invoice emailed',
  sendingPaymentEmail: 'Sending payment email...',
  sentPaymentEmail: 'Payment emailed',
  sentSMS: 'Invoice sms sent!',
  exportInvoices: 'Export Invoices',
  issuedAtTo: 'To:',
  issuedAtFrom: 'From:',
  preparePrinting: 'Prepare for printing...',
  account: 'Account',
  createdOn: 'Created On',
  dueOn: 'Due On',
  taxArtical23: 'Withholding Tax article 23 (PPh 23)',
  totalDueAfterTax: 'Total Due (Net after Tax)',
  totalIncludeTax: 'Total (Include tax)',
  subTotalIncludeTax: 'Subtotal (Include tax)'
};

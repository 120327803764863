export default {
  // common && crumbs
  timesheet: 'บันทึกการทำงาน',
  clockIn: 'เข้างาน',
  clockOut: 'ออกงาน',
  viewDetails: 'ดูรายละเอียด',
  jobID: 'รหัสงาน',
  jobTitle: 'หัวข้องาน',
  timesheetHours: 'Timesheet Hours (hh:mm)',
  rating: 'คะแนน',
  visitStartTime: 'เวลาเริ่มงาน',
  endTime: 'เวลาจบงาน',
  startDate: 'วันที่เริ่มงาน',
  endDate: 'วันที่จบงาน',
  totalHours: 'จำนวนชั่วโมงทั้งหมด',
  status:	'Status',
  title: 'หัวข้อ',
  staffList: 'ลิสต์พนักงาน',
  selectStaffToSeeTimesheet: 'กรุณาเลือกพนักงานเพื่อดูบันทึกการเข้างาน',
  lastMonth: 'เดือนก่อน',
  thisMonth: 'เดือนนี้',
  noTimesheetFound: 'ไม่พบบันทึกการเข้างาน',
  overview: 'Overview',
  clockinout: 'Clock In / Out',
  visits: 'Visits',
  showLateOnly: 'Show late only',
  filter: 'Filter',
  duration: 'Duration (hh:mm)',
  actions: 'รายการ',
  total: 'ทั้งหมด',
  add: 'Add',
  approve: 'Approve',
  decline: 'Decline',
  edit: 'Edit',
  delete: 'Delete',
  info: 'Counted from 00:00 to Clocked Out time',
  previousDay: 'Previous Day',
  nextDay: 'Next Day',
  exportTo: 'Export To',
  exports: 'Exports',
  jobId: 'Job ID',
  createClockInOut: 'Create new Clock In - Clock Out',
  editClockInOut: 'Edit Clock In - Clock Out',
  askConfirmationBeforeDelete: 'Do you want to delete ?',
  ontheway: 'On the way',
  arrived: 'Arrived',
  cancelled: 'Cancelled',
  start: 'Start',
  completed: 'Completed',
  outTimebeforInTime : 'Invalid date range',
  saveUpperCase: 'SAVE',
  createUpperCase: 'CREATE',
  editVisits: 'Edit Visit Timesheet',
  createVisits: 'Create new Visit Timesheet',
  noNotes: 'No Notes To Display',
  late: 'Late',
  overtime: 'Overtime',
  visitTitle: 'Visit Title',
  processEditing: 'Process Editing',
  processCreating: 'Process Creating',
  selectJob: 'Select Job’s Visits to create timesheet',
  noVisit: 'The job has no visit for the staff you selected',
  Hrs: 'Hrs',
  Mins: 'Mins',
  jobFor: 'Job For',
  createSuccess: 'Create Successfully',
  deleteSuccess: 'Delete Successfully',
  updateSuccess: 'Update Successfully',
  noTimeValid: 'Please Fill Time',
  timeNotCorrect: 'Filled times not correct'
};
import { useState } from 'react';

export const useHandleModal = () => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const setModal = (status) => {
    setOpen(status);
  };

  return { open, openModal, closeModal, setModal };
};

export const useHandleMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return { open, anchorEl, openMenu, closeMenu };
};
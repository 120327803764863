import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  ListItem,
  List,
  ListItemText,
  CircularProgress,
  ClickAwayListener,
  Typography,
  Box,
} from '@material-ui/core';
import { Field, change } from 'redux-form';
import { showErrorMessage } from 'lib/notifier';
import { searchPlaces, getPlaceDetails } from 'redux/location';

import styles from '../styles.module.scss';
import { TextInput } from 'components/inputs';
import GoogleSrc from 'assets/images/google.svg';

const SEARCH_TIMEOUT = 800;
let timer = 0;

function parsePlaceDetails(place) {

  let placeAddress = place.formatted_address;
  if (place.name && !place.formatted_address.includes(place.name)) {
    placeAddress = `${place.name}, ${place.formatted_address}`;
  }

  const location = {
    address: placeAddress,
    placeId: place.place_id,
    lat: place.geometry.location.lat,
    lng: place.geometry.location.lng,
    name: place.name,
  };
  const addressComponents = place.address_components;
  const floor = addressComponents.find((item) => item.types.includes('floor'));
  const room = addressComponents.find((item) => item.types.includes('room'));
  const city = addressComponents.find((item) =>
    item.types.includes('locality')
  );
  const postalCode = addressComponents.find((item) =>
    item.types.includes('postal_code')
  );
  const state = addressComponents.find((item) =>
    item.types.includes('administrative_area_level_1')
  );
  const country = addressComponents.find((item) =>
    item.types.includes('country')
  );
  location.unitNumber = [floor && floor.long_name, room && room.long_name]
    .filter(Boolean)
    .join(' ');
  location.city = (city && city.long_name) || '';
  location.postalCode = (postalCode && postalCode.long_name) || '';
  location.state = (state && state.long_name) || '';
  location.countryCode = (country && country.short_name) || '';
  return location;
}

const AddressInput = (props) => {
  const { placeSearchOptions, translate, noBorder, title, FORM_NAME, required } = props;
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState(null);
  const [isSearchingPlace, setIsSearchingPlace] = useState(false);

  function handleClickAway() {
    setSearchResults(null);
  }

  const handleSelectLocation = (details) => {
    const { address, city, postalCode, state, countryCode,
      placeId, lat, lng } = details;
    const name = FORM_NAME;
    dispatch(change(name, 'country', countryCode));
    dispatch(change(name, 'state', state));
    dispatch(change(name, 'address', address));
    dispatch(change(name, 'city', city));
    dispatch(change(name, 'lat', lat));
    dispatch(change(name, 'lng', lng));
    dispatch(change(name, 'zipCode', postalCode));
    dispatch(change(name, 'googlePlaceId', placeId));
  };

  async function chooseLocation(event, location) {
    event.preventDefault();
    const response = await dispatch(
      getPlaceDetails(location.place_id)
    ).catch((error) => ({ error }));
    if (response && response.error) return showErrorMessage(response.error);
    if (response && response.status !== 200)
      return showErrorMessage(
        response && response.data && response.data.message
      );
    const { result } = response.data;
    const locationDetail = parsePlaceDetails(result);
    handleSelectLocation(locationDetail);
    setSearchResults(null);
  }

  function addressSearching(event) {
    const textSearch = event.target.value;
    clearTimeout(timer);
    if (!textSearch) {
      setIsSearchingPlace(false);
      return setSearchResults(null);
    }
    setIsSearchingPlace(true);
    // eslint-disable-next-line no-return-assign
    return (timer = setTimeout(async () => {
      const response = await dispatch(
        searchPlaces(textSearch, placeSearchOptions)
      ).catch((error) => ({ error }));
      setIsSearchingPlace(false);
      if (response && response.error) return showErrorMessage(response.error);
      if (response && response.status !== 200)
        return showErrorMessage(response.data && response.data.message);
      const { predictions } = response.data;
      setSearchResults(predictions);
    }, SEARCH_TIMEOUT));
  }

  return (
    <Grid
      container
      className={
        noBorder ? styles.field_container_no_order : styles.field_container
      }
    >
      <Grid item md={3} sm={3} container alignItems="center">
        <Typography className={styles.label}>{title}</Typography>
        {required && <Typography className={styles.required}>{translate('Common:*')}</Typography>}
      </Grid>
      <Grid item md={9} sm={9} className="pl_5">
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          style={{ position: 'relative' }}
        >
          <Field
            name="address"
            component={TextInput}
            autoComplete="nope"
            margin="none"
            variant="outlined"
            required
            onChange={(e) => addressSearching(e)}
            InputProps={{
              endAdornment: isSearchingPlace && (
                <CircularProgress size={25} position="end" color="secondary" />
              ),
            }}
          />

          {searchResults && !!searchResults.length > 0 && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Grid
                container
                direction="column"
                className={styles.address_wrapper}
              >
                <List className={styles.search_results}>
                  {searchResults.map((item) => {
                    return (
                      <ListItem
                        key={item.place_id}
                        className={styles.item_address}
                        onClick={(e) => chooseLocation(e, item)}
                      >
                        <ListItemText
                          primary={
                            item.structured_formatting &&
                            item.structured_formatting.main_text
                          }
                          secondary={
                            item.structured_formatting &&
                            item.structured_formatting.secondary_text
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
                <Box
                  alignItem="center"
                  display="flex"
                  justifyContent="center"
                  p={1}
                >
                  <Typography variant="subtitle1">
                    {translate('Common:poweredBy')}
                  </Typography>
                  <img className="ml_5" src={GoogleSrc} alt="GoogleSrc" />
                </Box>
              </Grid>
            </ClickAwayListener>
          )}
          {Array.isArray(searchResults) && !searchResults.length && (
            <Grid container className={styles.address_wrapper}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <List className={styles.search_results}>
                  <ListItem>{translate('Location:noResultFound')}</ListItem>
                </List>
              </ClickAwayListener>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddressInput;

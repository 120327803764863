import React from 'react';

// Externals
import classNames from 'classnames';

// Material components
import { Grid, Typography } from '@material-ui/core';

// Material helpers
import { withStyles } from '@material-ui/core';

// Customised components
import { DetailsTable, InvoiceHeader } from '../utils';
import { AddressDetails } from 'pages/Dashboard/components';
import { Link } from 'react-router-dom';

// Component styles
import { InvoiceClientDetailsCardStyle } from './styles';

const InvoiceClientDetailsCard = (props) => {
  const {
    classes,
    invoice,
    invoiceStatuses,
    handleClickArchive,
    handleClickStatus,
    handleCollectPayment,
    handleEmailReceipt,
    handleEmailInvoice,
    translate,
  } = props;

  const contactPhoneNumber =
    invoice &&
    invoice.client &&
    invoice.client.contactPhones &&
    invoice.client.contactPhones.length &&
    invoice.client.contactPhones.find((phone) => phone.typeId === 'MAIN');

  return (
    <Grid container className={classNames(classes.header)}>
      {invoice && Object.keys(invoice).length > 0 && (
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.invoiceHeader}
          >
            <InvoiceHeader
              handleClickArchive={handleClickArchive}
              handleClickStatus={handleClickStatus}
              handleCollectPayment={handleCollectPayment}
              handleEmailReceipt={handleEmailReceipt}
              handleEmailInvoice={handleEmailInvoice}
              invoice={invoice}
              invoiceStatuses={invoiceStatuses}
              translate={translate}
            />
          </Grid>
          <Grid container direction="row" justify="space-between">
            {/* // Invoice details box */}
            <Grid
              item
              container
              className={[classes.invoiceDetail, classes.border].join(' ')}
              spacing={2}
              xs={5}
            >
              {/* <Grid item container direction='row' justify='flex-end' spacing={1} /> */}
              {/* // Invoice details table */}
              <DetailsTable
                archived={invoice.archived}
                invoiceDueDate={invoice.dueBy}
                invoiceId={invoice.id}
                invoiceIssueDate={invoice.issuedAt}
                invoiceStatus={invoice.statusId}
                invoiceStatuses={invoiceStatuses}
                jobIds={invoice.jobIds}
                translate={translate}
              />
            </Grid>
            <Grid
              item
              container
              xs={7}
              spacing={2}
              className={[classes.invoiceClientDetail, classes.border].join(
                ' '
              )}
            >
              <Grid container direction="column">
                <Typography variant="h4" className={classes.invoiceTitle}>
                  {translate('Common:clientDetails')}
                </Typography>
              </Grid>
              <Grid item container justify="space-between" direction="row">
                {invoice.client && invoice.client.displayName && (
                  <Grid item md={4}>
                    <Typography className={classes.titleColor} variant="h5">
                      {translate('Common:name')}
                    </Typography>
                    <Link to={`/clients/${invoice.client.id}`}>
                      <Typography variant="h5" className={classes.clientName}>
                        {invoice.client.displayName}
                      </Typography>
                    </Link>
                  </Grid>
                )}
                <Grid item md={4}>
                  <AddressDetails
                    titleStyle={{
                      marginBottom: 0,
                      fontWeight: 'normal',
                      color: 'rgba(0, 0, 0, 0.5)',
                    }}
                    textStyle={{ color: 'black' }}
                    address={invoice && invoice.client.billingAddress}
                    translate={translate}
                    title={translate('billingAddress')}
                  />
                </Grid>
                <Grid item md={4} container>
                  {invoice.client && invoice.client.email ? (
                    <Grid item md={12} className={classes.emailContainer}>
                      <Typography className={classes.titleColor} variant="h5">
                        {translate('Common:email')}
                      </Typography>
                      <Typography className={classes.textDetails}>
                        {invoice.client.email}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item className={classes.emailContainer}>
                      <Typography className={classes.noContacts}>
                        {translate('Common:noEmailRecorded')}
                      </Typography>
                    </Grid>
                  )}

                  { !!contactPhoneNumber && (
                    <Grid item md={12}>
                      <Typography className={classes.titleColor} variant="h5">
                        {translate('Common:phoneNumber')}
                      </Typography>
                      <Typography className={classes.textDetails}>
                        ({contactPhoneNumber.countryPhoneCode}){' '}
                        {contactPhoneNumber.phoneNumber}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(InvoiceClientDetailsCardStyle)(
  InvoiceClientDetailsCard
);

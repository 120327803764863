import React from 'react';
// Redux
import { Field } from 'redux-form';
import { Card, Grid, Typography } from '@material-ui/core';
import { TextInput, DateTimePicker } from 'components/inputs';

import styles from './styles/serviceReport.module.scss';

const JobTrackingCard = ({ translate }) => {
  return (
    <Card className="p_20 mt_20">
      <Grid container>
        <Typography variant="h4" className="mb_20">
          {translate('certificate')}
        </Typography>
      </Grid>
      <Grid container>
        <Field
          name="serviceBy"
          label={translate('serviceBy')}
          component={TextInput}
          variant="outlined"
          className={styles.w_30}
          margin="dense"
          required
        />
        <Field
          name="timeArrival"
          label={translate('timeArrival')}
          component={DateTimePicker}
          variant="outlined"
          className={`${styles.w_35} ${styles.textMargin}`}
          margin="dense"
        />
        <Field
          name="timeOfCompletion"
          label={translate('timeOfCompletion')}
          component={DateTimePicker}
          variant="outlined"
          style={{ marginRight: 0 }}
          className={styles.w_35}
          margin="dense"
        />
      </Grid>
    </Card>
  );
};

export default JobTrackingCard;

import {
  QUERY_STRING_DECODE,
  SIDE_BAR_FILTER_FORM_NAME,
} from "common/constant";
import { checkStatusIds } from "pages/Dashboard/helpers/checkValue";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { change } from "redux-form";
import { getCategoryWithParams } from "redux/category";
import { getHubWithParams } from "redux/hub";
import { getStaffs } from "redux/staff";
import { mapStatusValueToClasses } from "../StatusMultipleSelection";
import { convertDateInParams } from "pages/Dashboard/views/Jobs/JobList/components/JobsTable/utils";

export const useUpdateFilterSidebar = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation("Common");
  const updateFilterSidebarFromParams = async () => {
    const objSearchParams = convertDateInParams(
      queryString.parse(search, QUERY_STRING_DECODE)
    );
    if (objSearchParams.createdOnFrom) {
      dispatch(
        change(SIDE_BAR_FILTER_FORM_NAME, "createdOn", [
          objSearchParams.createdOnFrom,
          objSearchParams.createdOnTo,
        ])
      );
    }
    if (objSearchParams.scheduledOnFrom) {
      dispatch(
        change(SIDE_BAR_FILTER_FORM_NAME, "scheduledOn", [
          objSearchParams.scheduledOnFrom,
          objSearchParams.scheduledOnTo,
        ])
      );
    }
    if (objSearchParams.completionTimeFrom) {
      dispatch(
        change(SIDE_BAR_FILTER_FORM_NAME, "completionTime", [
          objSearchParams.completionTimeFrom,
          objSearchParams.completionTimeTo,
        ])
      );
    }
    // if (objSearchParams.jobCategoryIds?.length) {
    //   const params = { categoryIds: checkStatusIds(objSearchParams.jobCategoryIds) };
    //   const categories = await dispatch(getCategoryWithParams({ params }));
    //   dispatch(change(SIDE_BAR_FILTER_FORM_NAME, 'categories', categories.data?.data));
    //   dispatch(change(SIDE_BAR_FILTER_FORM_NAME, 'categoryIds', objSearchParams.jobCategoryIds));
    // }
    if (objSearchParams.staffIds?.length) {
      const params = { staffIds: checkStatusIds(objSearchParams.staffIds) };
      const staffs = await dispatch(getStaffs(params));
      const staffsUI = staffs.data?.data.map((value) => ({
        value: value.id,
        label: value.fullName,
      }));
      dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "staffs", staffsUI));
    }
    if (objSearchParams.hubIds?.length) {
      const params = { hubIds: checkStatusIds(objSearchParams.hubIds) };
      const hubs = await dispatch(getHubWithParams(params));
      const hubsUI = hubs.data?.map((value) => ({
        value: value.id,
        label: value.name,
      }));
      dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "hubs", hubsUI));
    }
    if (objSearchParams.statusIds?.length) {
      const getStatusIds = checkStatusIds(objSearchParams.statusIds);
      const statusValues = getStatusIds.map((value) => ({
        value,
        label: mapStatusValueToClasses[value],
      }));
      dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "status", statusValues));
    }
    if (objSearchParams.serviceReport) {
      dispatch(
        change(SIDE_BAR_FILTER_FORM_NAME, "serviceReport", [
          {
            label: translate("Job:available"),
            checked: objSearchParams.serviceReport?.includes("1"),
            value: 1,
          },
          {
            label: translate("Job:none"),
            checked: objSearchParams.serviceReport?.includes("0"),
            value: 0,
          },
        ])
      );
    }
    if (objSearchParams.lateVisit) {
      dispatch(
        change(SIDE_BAR_FILTER_FORM_NAME, "lateVisit", [
          {
            label: translate("Job:hasLateVisit"),
            checked: objSearchParams.lateVisit?.includes("1"),
            value: 1,
          },
          {
            label: translate("Job:hasNoVisit"),
            checked: objSearchParams.lateVisit?.includes("0"),
            value: 0,
          },
        ])
      );
    }
    if (objSearchParams.isItemsCollected) {
      dispatch(
        change(SIDE_BAR_FILTER_FORM_NAME, "isCollectedItems", [
          {
            label: translate("Job:collected"),
            checked: objSearchParams.isItemsCollected?.includes("1"),
            value: 1,
          },
          {
            label: translate("Job:notCollected"),
            checked: objSearchParams.isItemsCollected?.includes("0"),
            value: 0,
          },
        ])
      );
    }
    dispatch(
      change(
        SIDE_BAR_FILTER_FORM_NAME,
        "isVerified",
        objSearchParams.isVerified
      )
    );
  };
  return [updateFilterSidebarFromParams];
};

export const checkIsFiltering = (val, jobFiltering) => {
  const srCheckedList =
    val.serviceReport?.length &&
    val.serviceReport.filter((item) => item.checked);

  const lateVisitList =
    val.lateVisit?.length && val.lateVisit.filter((item) => item.checked);
  const collectedItems =
    val.isCollectedItems?.length &&
    val.isCollectedItems.filter((item) => item.checked);
  // match condition is mean is filtering
  let isFiltering =
    Number(val.isVerified) ||
    (Array.isArray(val.staffs) && val.staffs.length) ||
    (Array.isArray(val.status) && val.status.length) ||
    (Array.isArray(lateVisitList) && lateVisitList.length !== 2) ||
    (Array.isArray(srCheckedList) && srCheckedList.length !== 2) ||
    (Array.isArray(collectedItems) && collectedItems.length !== 2) ||
    (Array.isArray(val.hubs) && val.hubs.length) ||
    (Array.isArray(val.categoryIds) && val.categoryIds.length);
  // If filter is using for job, we need to check with created on and scheduled on.
  if (jobFiltering) {
    isFiltering =
      isFiltering || val.createdOn || val.scheduledOn || val.completionTime;
  }
  return isFiltering;
};

import React from 'react';

import { Typography, Box, Button } from '@material-ui/core';
import styles from '../../components.module.scss';
import CopySrc from 'assets/images/dashboard/copy.svg';
import { showSuccessMessage } from 'lib/notifier';

const renderCopyField = ({ input, label, translate }) => {
  if (!input.value) return null;

  const handleCopy = () => {
    navigator.clipboard.writeText(input.value);
    showSuccessMessage(translate('Dashboard:textCopied'));
  };
  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column">
        {label && <Typography className={styles.label}>{label}</Typography>}
        <Box>
          <Typography>{input.value}</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-end">
        <Button
          onClick={handleCopy}
          variant="contained"
          className={styles.btn_copy}
        >
          <img className="mr_5" src={CopySrc} alt="CopySrc" />
          {translate('copy')}
        </Button>
      </Box>
    </Box>
  );
};

export default renderCopyField;

import React, { forwardRef, createRef } from 'react';

// Externals
import classNames from 'classnames';
import MaterialTable, { MTableToolbar } from 'material-table';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getClients, } from 'redux/client';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
  ArrowUpward,
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveOutlined,
  SaveAlt,
  Search,
  ToggleOff,
  Visibility,
} from '@material-ui/icons';

// Customised components

// Component styles
import SelectClientModalStyle from './styles';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <SaveOutlined {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ToggleOff: forwardRef((props, ref) => <ToggleOff {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />)
};

const mapDispatchToProps = dispatch => ({
  getClients: (options) => dispatch(getClients(options)),
});

const RenderClientProps = props => {
  const { client: { displayName, properties, emails, phoneNumbers } = {}, translate } = props;
  let propertyInfo;

  if (!properties || !properties.length) {
    propertyInfo = translate('noPropertyRecorded');
  } else if (properties.length === 1) {
    propertyInfo = `1 ${translate('property')}`;
  } else {
    propertyInfo = `${properties.length} ${translate('property')}`;
  }

  const email = emails.length > 0 && emails.find(email => email.typeId === 'MAIN');
  const phoneNumber = phoneNumbers.length > 0 && phoneNumbers.find(phoneNumber => phoneNumber.typeId === 'MAIN');
  const clientName = displayName;

  return (
    <div>
      <Typography variant='h5'>
        {clientName}
      </Typography>
      {
        phoneNumber &&
        <Typography>{propertyInfo} {phoneNumber.phoneNumber}</Typography>
      }
      {
        email && <Typography>{email.email}</Typography>
      }
    </div>
  );
};

const SelectClientModal = (props) => {
  const {
    classes,
    open,
    handleClose,
    getClients,
    handleClientSelect,
    handleAddNewClient,
    translate,
  } = props;
  const rowsPerPage = 10;
  const includingArchived = false;

  const tableRef = createRef();

  const columns = [
    {
      field: 'client',
      title: translate('client'),
      render: rowData => {

        return (
          <RenderClientProps
            client={rowData}
            translate={translate}
          />
        );
      }
    },
  ];

  const options = {
    pageSize: rowsPerPage,
    pageSizeOptions: [10, 25, 50],
    search: true,
    debounceInterval: 1000
  };

  return (
    <Dialog
      maxWidth='lg'
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle disableTypography className={classNames(classes.root, classes.clientDialogTitle)}>
        <Typography variant="h6">{translate('Job:selectCreateClient')}</Typography>
        {open ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers className={classes.modalContent}>
        <Typography gutterBottom>
          {translate('Job:whichClient')}
        </Typography>
        <MaterialTable
          title={null}
          columns={columns}
          options={options}
          icons={tableIcons}
          tableRef={tableRef}
          data={query =>
            new Promise((resolve, reject) => {
              if (query.search === '') {
                return resolve({
                  data: [],
                  page: 0,
                  totalCount: 0
                });
              }
              let sortParams = {};
              if (query.orderBy) {
                sortParams = { field: query.orderBy['field'], orderDirection: query.orderDirection };
              }
              getClients(
                {
                  limit: query.pageSize,
                  skip: query.page * query.pageSize,
                  searchString: query.search,
                  sort: sortParams,
                  includingArchived
                }
              ).then((response) => {
                if (response.status === 200) {
                  const { data, total } = response.data;
                  resolve({
                    data,
                    page: query.page,
                    totalCount: total
                  });
                }

              }, (error) => {
                reject(error);
              });
            })
          }
          onRowClick={(event, rowData) => handleClientSelect(rowData)}
          components={{
            Toolbar: props => (
              <div>
                <Grid container direction='row'>
                  <Grid item container justify='center' alignItems='center' md={6} sm={12}>
                    <MTableToolbar {...props} />
                  </Grid>
                  <Grid item container justify='center' alignItems='center' md={1} sm={12}>
                    {translate('Common:or')}
                  </Grid>
                  <Grid item container justify='center' alignItems='center' md={5} sm={12}>
                    <Button onClick={handleAddNewClient}>{translate('Job:createClient')}</Button>
                  </Grid>
                </Grid>
              </div>
            ),
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default compose(
  withStyles(SelectClientModalStyle),
  connect(null, mapDispatchToProps),
)(SelectClientModal);

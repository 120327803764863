import React from 'react';

// Material components
import { withStyles, Popover, MenuList, MenuItem } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'pages/Dashboard/components';

// Material icons
import {
  Add
} from '@material-ui/icons';

import moment from 'moment';
// Component styles
import { PopupToAddNewEventStyle } from './styles';

const PopupToAddNewEvent = props => {
  const {
    classes,
    showNewEventPopup,
    handleCloseNewEventPopup,
    anchorPosition,
    selectedDate,
    openJobForm,
    translate
  } = props;

  return (
    <>
      <Popover
        id="rbc-selected"
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={showNewEventPopup}
        onClose={handleCloseNewEventPopup}
      >
        <Portlet className={classes.portletWrap}>
          <PortletHeader className={classes.portletHeader}>
            <PortletLabel
              className={classes.portletLabel}
              title={selectedDate && `${translate('addToDate')} ${moment(selectedDate).format('MMM DD, YYYY')}`}
            />
          </PortletHeader>
          <PortletContent className={classes.portletContent}>
            <MenuList className={classes.menuList}>
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  openJobForm();
                  handleCloseNewEventPopup();
                }}
              >
                <Add className={classes.iconStyle} />
                {translate('newJob')}
              </MenuItem>
            </MenuList>
          </PortletContent>
        </Portlet>
      </Popover>
    </>
  );

};

export default withStyles(PopupToAddNewEventStyle)(PopupToAddNewEvent);
import React, { useState } from 'react';
import {
  Card,
  IconButton,
  Popover,
  Grid,
  List,
  ListItem,
  Box,
  Typography,
} from '@material-ui/core';

import styles from '../../card.module.scss';
import moreSrc from 'assets/images/icons/more.svg';

import { NewVisitModal } from '../../../../../components/modals';
import { getStatusButtonReview } from '../../../ConfirmReviewModal/hook';
import { REVIEW_BUTTON_STATUS, STATUS_CONFIRM_BUTTON, VISIT_STATUS } from '../../../ConfirmReviewModal/constants';
import { useDispatch } from 'react-redux';
import { setConfirmReviewButton, setContentModal, setOpenConfirmReviewModal } from 'redux/jobVerification';

const MorePopover = (props) => {
  const {
    translate,
    handleGenerateInvoice,
    handleGenerateServiceReport,
    setIsOpenConfirmModal,
    setIsOpenConfirmDeleteModal,
    job
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenNewModal, setIsOpenNewModal] = useState(false);
  const dispatch = useDispatch();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClickNewVisit = () => {
    handlePopoverClose();
    const { verified, startVerifiedAt } = job;
    const status = getStatusButtonReview({ verified, startVerifiedAt });
    if (status === REVIEW_BUTTON_STATUS.REVIEWED) {
      dispatch(setConfirmReviewButton(STATUS_CONFIRM_BUTTON.OPEN_CREATE_VISIT));
      dispatch(setContentModal(VISIT_STATUS.CREATE_NEW_VISIT));
      dispatch(setOpenConfirmReviewModal(true));
    } else {
      setIsOpenNewModal(true);
    }
  };

  const open = Boolean(anchorEl);

  const handleClickGenerateInvoice = () => {
    handleGenerateInvoice && handleGenerateInvoice();
    handlePopoverClose();
  };

  const handleClickGenerateServiceReport = () => {
    handleGenerateServiceReport && handleGenerateServiceReport();
    handlePopoverClose();
  };

  const handleClick = () => {
    setIsOpenConfirmModal(true);
    handlePopoverClose();
  };

  const handleClickDeletJob = () => {
    setIsOpenConfirmDeleteModal(true);
    handlePopoverClose();
  };

  return (
    <Grid>
      {isOpenNewModal && (
        <NewVisitModal
          {...props}
          isOpen={isOpenNewModal}
          setIsOpenEditModal={setIsOpenNewModal}
        />
      )}
      <IconButton
        onClick={handlePopoverOpen}
        className={`${styles.icon_btn} mr_5`}
      >
        <img src={moreSrc} alt="moreSrc" />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Card>
          <List>
            <ListItem
              button
              onClick={handleClickGenerateInvoice}
              className={styles.border_bottom}
            >
              {translate('generateInvoice')}
            </ListItem>
            <ListItem
              button
              onClick={handleClickNewVisit}
              className={styles.border_bottom}
            >
              {translate('createNewVisit')}
            </ListItem>

            <Box px={2} py={1} className={styles.border_bottom}>
              <Typography>{translate('generateSRFrom')}</Typography>
              <ListItem button onClick={handleClickGenerateServiceReport}>
                {translate('Visit')}
              </ListItem>
            </Box>

            <ListItem className={styles.border_bottom} button onClick={handleClick}>
              {translate('Common:archive')}
            </ListItem>

            <ListItem button className={styles.deleteBtn} onClick={handleClickDeletJob}>
              {translate('deleteJob')}
            </ListItem>
          </List>
        </Card>
      </Popover>
    </Grid>
  );
};

export default MorePopover;

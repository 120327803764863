import React from "react";

// Externals
import { Link } from "react-router-dom";

// Material components
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";

// Component styles
import { QuoteHeaderStyles } from "./styles";
import { QUOTE_ALLOW_STATUSES } from "common/constant";

const QuoteHeader = withStyles(QuoteHeaderStyles)((props) => {
  const {
    classes,
    handleClickArchive,
    handleClickStatus,
    handleEmailQuote,
    quote,
    quoteStatuses,
    translate,
  } = props;
  const { id, status } = quote;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const allowedStatuses = Object.values(QUOTE_ALLOW_STATUSES);

  return (
    <>
      <Grid item md={5} sm={12}>
        <Grid item container direction="column">
          <Typography variant="h2" className={classes.clientName}>
            {quote && quote.jobTitle}
          </Typography>
          <Typography variant="h4" className={classes.jobTitle}>
            ID: {quote && quote.id}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="flex-end"
        spacing={1}
        md={7}
        sm={12}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={
              ![
                QUOTE_ALLOW_STATUSES.APPROVED,
                QUOTE_ALLOW_STATUSES.AWAITING_RESPONSE,
                QUOTE_ALLOW_STATUSES.ACCEPTED,
                QUOTE_ALLOW_STATUSES.CONVERTED,
              ].includes(status)
            }
            className={classes.mainActionButton}
            onClick={handleEmailQuote}
          >
            {translate("emailQuote")}
          </Button>
        </Grid>
        <Grid item>
          {quote.ableToEdit ? (
            <Link to={`/quotes/${id}/edit`}>
              <Button variant="contained" color="primary">
                {translate("Common:edit")}
              </Button>
            </Link>
          ) : (
            <Button variant="contained" color="primary" disabled>
              {translate("Common:edit")}
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            aria-controls="status-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            {translate("Common:markAs")}
          </Button>
          <Menu
            id="status-menu"
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.popoverWithArrow}
          >
            {quoteStatuses &&
              quoteStatuses.map((item) => {
                if (allowedStatuses.includes(item.id)) {
                  return (
                    <MenuItem
                      key={item.id}
                      disabled={
                        status === item.id ||
                        !quote.nextValidStatus.includes(item.id)
                      }
                      onClick={(e) => {
                        handleClickStatus(e, item.id);
                        setAnchorEl(null);
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  );
                }
                return null;
              })}
          </Menu>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickArchive}
          >
            {quote.status === "ARCHIVED"
              ? translate("Common:unarchive")
              : translate("Common:archive")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
});

export default QuoteHeader;

import React, { useRef, useState } from 'react';
import moment from 'moment';

// redux
import { reinviteStaff, getStaffs } from 'redux/staff';
import { useDispatch } from 'react-redux';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Button, Grid, Typography, Box } from '@material-ui/core';
import { StatusChip } from '../../../components';
import {
  ConfirmDeleteModal,
  UserDetailsModal,
  UserDetailsCreateModal,
  UserDetailsEditModal,
} from '../modal';

import styles from '../../details.module.scss';
import { Add as AddIcon } from '@material-ui/icons';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import contactPersonSrc from 'assets/images/enterprise/contact-person.png';
import sendSrc from 'assets/images/enterprise/send.svg';
import deleteSrc from 'assets/images/enterprise/trash.svg';
import editSrc from 'assets/images/enterprise/edit.svg';
import { getDateTimeFormat } from 'lib/formatter';

const options = {
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  actionsColumnIndex: -1,
  sorting: true,
  search: true,
  debounceInterval: 1000,
  draggable: false,
};

const renderColumn = ({ t }) => [
  {
    field: 'isContactPerson',
    title: '',
    width: '4%',
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!rowData.isContactPerson) return null;
      return <img src={contactPersonSrc} alt="contactPersonSrc" />;
    },
  },
  {
    field: 'archived',
    title: t('status'),
    sorting: true,
    filtering: false,
    render: (rowData) => {
      return <StatusChip isActive={!rowData.archived} />;
    },
  },
  {
    field: 'id',
    title: t('userId'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      return <Typography>{rowData.id}</Typography>;
    },
  },
  {
    field: 'firstName',
    title: t('Common:firstName'),
    sorting: true,
    filtering: false,
    render: (rowData) => {
      if (!rowData.firstName) return <Typography>-</Typography>;
      return <Typography>{rowData.firstName}</Typography>;
    },
  },
  {
    field: 'lastName',
    title: t('Common:lastName'),
    sorting: true,
    filtering: false,
    render: (rowData) => {
      if (!rowData.lastName) return <Typography>-</Typography>;
      return <Typography>{rowData.lastName}</Typography>;
    },
  },
  {
    field: 'roleId',
    title: t('Common:role'),
    sorting: true,
    filtering: false,
    render: (rowData) => {
      if (!rowData.roleId) return <Typography>-</Typography>;
      return <Typography>{rowData.roleId}</Typography>;
    },
  },
  {
    field: 'contact',
    title: t('contact'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!rowData.email && !rowData.phoneNumber)
        return <Typography>-</Typography>;
      return (
        <Box display="flex" flexDirection="column">
          <Grid>
            <Typography>{rowData.email}</Typography>
          </Grid>
          <Grid>
            <Typography className={styles.phoneNumber}>
              {rowData.countryPhoneCode}
              {rowData.phoneNumber}
            </Typography>
          </Grid>
        </Box>
      );
    },
  },
  {
    field: 'joinedDate',
    title: t('dateJoined'),
    sorting: true,
    filtering: false,
    render: (rowData) => {
      const dateJoined = rowData.joinedDate || rowData.createdAt;
      if (!dateJoined) return <Typography>-</Typography>;
      return (
        <Typography>
          {moment(dateJoined).format(t('Job:dateFormat'))}
        </Typography>
      );
    },
  },
];

const Table = ({ t, companyDetails }) => {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const columns = renderColumn({ t });

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [openNewUserDetailsModal, setOpenNewUserDetailsModal] = useState(false);
  const [openEditUserDetailsModal, setOpenEditUserDetailsModal] = useState(
    false
  );
  const [selectedStaff, setSelectedStaff] = useState({});

  const refetchTable = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const fetchCompany = async (query) => {
    const { page, pageSize, orderBy, orderDirection, search } = query;
    let sortParams = { '$sort[archived]': 1 };
    if (orderBy) {
      const sortOrder = orderDirection === 'asc' ? 1 : -1;
      const sortKey = `$sort[${orderBy['field']}]`;
      sortParams = { [sortKey]: sortOrder };
    }

    const params = {
      $limit: pageSize,
      $skip: page * pageSize,
      $scope: 'STAFF_MANAGEMENT',
      includingArchived: true,
      enterpriseAccountId: companyDetails.id,
      ...sortParams,
      ...(search ? { $q: search } : {}),
    };

    const response = await dispatch(getStaffs(params)).catch((e) => ({ e }));
    if (response.e) {
      showErrorMessage(response.e);
      return Promise.reject(response.e);
    }
    return Promise.resolve({
      data: response.data.data,
      page,
      totalCount: response.data.total,
    });
  };

  const handleRowClick = (e, rowData) => {
    setSelectedStaff(rowData);
    setOpenUserDetailsModal(true);
  };

  const handleCreateNewAccount = () => setOpenNewUserDetailsModal(true);

  const handleReinvite = async (rowData) => {
    const response = await dispatch(
      reinviteStaff(rowData.id, companyDetails.id)
    ).catch((e) => ({
      e,
    }));
    if (response.e) return showErrorMessage(t('sendError'));
    showSuccessMessage(
      t('sendSuccess', {
        datetime: moment().add(1, 'd').format(getDateTimeFormat()),
      })
    );
  };

  const handleEdit = (e, rowData) => {
    setSelectedStaff(rowData);
    setOpenEditUserDetailsModal(true);
  };

  const handleDelete = (e, rowData) => {
    setSelectedStaff(rowData);
    setOpenConfirmModal(true);
  };

  const handleCloseConfirm = () => setOpenConfirmModal(false);

  const handleCloseDetails = () => setOpenUserDetailsModal(false);

  const handleCloseDetailsCreate = () => setOpenNewUserDetailsModal(false);

  const handleCloseDetailsEdit = () => setOpenEditUserDetailsModal(false);

  const handleOpenEditUserDetails = () => {
    handleCloseDetails();
    setOpenEditUserDetailsModal(true);
  };

  return (
    <>
      <MaterialTable
        style={{
          display: 'grid',
          gridTemplateColums: '1fr',
          gridTemplateRows: 'auto 1fr auto',
          height: 'calc(100vh - 112px)',
        }}
        title={null}
        columns={columns}
        options={options}
        tableRef={tableRef}
        data={fetchCompany}
        onRowClick={handleRowClick}
        actions={[
          (rowData) => {
            if (rowData.roleId && rowData.roleId === 'OWNER') return null;
            return {
              icon: () => <img src={deleteSrc} alt="delete" />,
              onClick: handleDelete,
            };
          },
          {
            icon: () => <img src={editSrc} alt="edit" />,
            onClick: handleEdit,
          },
          (rowData) => {
            if (rowData.archived || !rowData.id || rowData.acceptedAt) return null;
            return {
              icon: () => <img src={sendSrc} alt="reinvite" />,
              tooltip: t('resendInvitation'),
              onClick: () => handleReinvite(rowData),
            };
          },
        ]}
        components={{
          Toolbar: (props) => (
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={styles.enterpriseUser}
            >
              <Typography variant="body1" className={styles.boldHeader}>
                {t('enterpriseUsers')}
              </Typography>
              <Grid item className={styles.searchWrapper}>
                <MTableToolbar {...props} />
                <Grid item className={styles.spacingRight}>
                  <Button
                    onClick={handleCreateNewAccount}
                    className={styles.btnNew}
                    variant="contained"
                  >
                    <AddIcon style={{ color: '#0075FE' }} />
                    <Typography className={styles.newUserBtn}>
                      {t('newUser')}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ),
        }}
      />
      <ConfirmDeleteModal
        openConfirmModal={openConfirmModal}
        t={t}
        handleClose={handleCloseConfirm}
        selectedStaff={selectedStaff}
        refetchTable={refetchTable}
        companyDetails={companyDetails}
      />
      {openUserDetailsModal && (
        <UserDetailsModal
          t={t}
          handleOpenEditUserDetail={handleOpenEditUserDetails}
          handleClose={handleCloseDetails}
          openUserDetailsModal={openUserDetailsModal}
          selectedStaff={selectedStaff}
          companyDetails={companyDetails}
        />
      )}

      {openNewUserDetailsModal && (
        <UserDetailsCreateModal
          t={t}
          handleClose={handleCloseDetailsCreate}
          openNewUserDetailsModal={openNewUserDetailsModal}
          companyDetails={companyDetails}
          refetchTable={refetchTable}
        />
      )}
      {openEditUserDetailsModal && (
        <UserDetailsEditModal
          t={t}
          handleClose={handleCloseDetailsEdit}
          openEditUserDetailsModal={openEditUserDetailsModal}
          selectedStaff={selectedStaff}
          companyDetails={companyDetails}
          refetchTable={refetchTable}
        />
      )}
    </>
  );
};

export default Table;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAnnouncements } from 'redux/dashboard';
import moment from 'moment';
import { Box, Typography, Card, Dialog, IconButton } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Skeleton } from '@material-ui/lab';
import { showErrorMessage } from 'lib/notifier';
import Item from './item';
import CloseIcon from '@material-ui/icons/Close';
import styles from './announcement.module.scss';

import noAnnouncementsSrc from 'assets/images/dashboard/no-annoucements.svg';
import { getDateTimeFormat } from 'lib/formatter';

const Announcements = ({ t, isParentLoading, latestUpdate }) => {
  const [currentTime, setCurrentTime] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
  const [isOpenModalDetails, setIsOpenModalDetails] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000 * 30);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isParentLoading) return;
    (async () => {
      setIsLoading(true);
      const response = await dispatch(getAnnouncements());
      setIsLoading(false);
      if (response.e) return showErrorMessage(response.e);
      setAnnouncements(response.data);
    })();
  }, [isParentLoading, latestUpdate]);

  const handleClickAnnouncements = (announcementDetails) => {
    setIsOpenModalDetails(true);
    setSelectedAnnouncement(announcementDetails);
  };

  const handleClose = () => setIsOpenModalDetails(false);

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Box mr={1}>
          <AccessTimeIcon style={{ width: 12 }} />
        </Box>
        <Typography variant="subtitle1" style={{ fontSize: 12 }}>
          {t('currentTime', { time: currentTime.format(getDateTimeFormat()) })}
        </Typography>
      </Box>
      <Dialog
        open={isOpenModalDetails}
        onClose={handleClose}
        classes={{ paper: styles.customizeDialog }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          p={3}
          alignItems="center"
          className={styles.announcementBorderBottom}
        >
          <Typography variant="h5">{t('announcementDetails')}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box p={3} display="flex" flexDirection="column">
          <Typography variant="h5">{selectedAnnouncement.title}</Typography>
          <Box mt={2}>
            <Typography>{selectedAnnouncement.content}</Typography>
          </Box>
        </Box>
      </Dialog>

      {isParentLoading || isLoading ? (
        <Skeleton style={{ transform: 'unset' }} height={527} />
      ) : (
        <Card className={styles.announcementsCard}>
          <Box p={2} className={styles.announcementBorderBottom}>
            <Typography variant="h5">{t('announcements')}</Typography>
          </Box>

          {Array.isArray(announcements) && announcements.length > 0 ? (
            <Box
              p={2}
              display="flex"
              flexDirection="column"
              className={styles.announcementBody}
            >
              {announcements.map((announcement, key) => (
                <Box
                  key={key}
                  mt={1}
                  onClick={() => handleClickAnnouncements(announcement)}
                >
                  <Item t={t} item={announcement} />
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              className={styles.noAnnouncementsWrapper}
            >
              <img src={noAnnouncementsSrc} alt="noAnnouncementsSrc" />
              <Typography align="center">{t('noAnnouncements')}</Typography>
              <Typography align="center" className={styles.noContent}>
                {t('noAnnouncementsDescription')}
              </Typography>
            </Box>
          )}
        </Card>
      )}
    </>
  );
};

export default Announcements;

import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// Material components
import { Topbar } from './components';

// Component styles
import styles from './styles';

import matchPath from 'lib/matchPath';

const Tracking = props => {
  const { classes, backUrl, title, childTitle, children, translate } = props;
  return (
    <>
      <Topbar
        className={classNames.topbar}
        title={title}
        childTitle={childTitle}
        translate={translate}
        backUrl={backUrl}
      />

      <main className={classes.content}>
        {children}
      </main>
    </>
  );
};

function TrackingLayout(props) {
  const { routes, match, title, location, t, classes } = props;
  const currentRoute = location.pathname;

  return (
    <>
      {
        routes &&
        routes.map((route, index) => {
          const comparePathResult = matchPath(currentRoute, { path: `${match.path}${route.path}` });
          if (comparePathResult && comparePathResult.isExact) {
            let backUrl = '/calendar';
            if (comparePathResult.path === '/gps/:userId/details') {
              backUrl = '/gps';
            }
            return (
              <Route
                key={index}
                exact
                path={`${match.path}${route.path}`}
                render={routeProps => (
                  <Tracking
                    title={t(title)}
                    childTitle={t(route.subtitle)}
                    backUrl={backUrl}
                    classes={classes}
                    translate={t}
                  >
                    <route.component {...routeProps} {...comparePathResult.params} translate={t} />
                  </Tracking>
                )}
              />
            );
          }
          return null;
        })
      }
    </>
  );
};

TrackingLayout.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isLoading: state.global.isLoading,
});

export default compose(
  withTranslation('Tracking'),
  connect(mapStateToProps, null),
  withRouter,
  withStyles(styles)
)(TrackingLayout);

export default (theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
  },
  btnCategory: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    marginRight: theme.spacing(2),
    maxWidth: 220,
    textAlign: 'left'
  },
  clearFilter: {
    color: '#EB4B4F',
    marginRight: theme.spacing(2),
  },
});

export default theme => ({
  root: {},
  formField: {
    margin: 0
  },
  fullTextField: {
    width: 480,
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  sectionTitle: {
    marginTop: 10,
    paddingLeft: 24,
    paddingBottom: 20
  },
  section: {
    margin: theme.spacing(3),
    marginTop: 0
  },
  textField: {
    width: '360px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
  addressFormBG: {
    backgroundColor: theme.palette.common.white,
    padding: '2px 10px',
    borderRadius: 5,
    border: `1px solid ${theme.palette.border}`,
    marginTop: 6,
    position: 'relative'
  },
  billingAddressTitle: {
    marginTop: 20,
    marginBottom: 10
  },
  deleteButton: {
    position: 'absolute',
    right: -15,
    bottom: 0
  },
  addBtn: {
    margin: '10px 0 10px -5px',
    paddingLeft: 0
  },
  propertyWrap: {
    position: 'relative'
  }
});

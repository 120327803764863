import React from 'react';
// Redux
import { Field } from 'redux-form';
import { Card, Grid, Typography } from '@material-ui/core';
import { TextInput, DatePicker } from 'components/inputs';

import styles from './styles/serviceReport.module.scss';

const CustomerInformationCard = ({ translate, reportType }) => {
  return (
    <Card
      className={styles.cardPadding}
      style={reportType !== 'FULL' ? { minHeight: 'unset' } : null}
    >
      <Typography variant="h4" className="mb_20">
        {translate('generalInfo')}
      </Typography>
      <Grid className={styles.informationContainer}>
        <Grid className={styles.userInformation}>
          <Field
            name="customer"
            label={translate('customer')}
            component={TextInput}
            variant="outlined"
            className={styles.w_49}
            margin="dense"
            required
          />
          <Field
            name="phoneNumber"
            label={translate('phoneNumber')}
            component={TextInput}
            variant="outlined"
            className={`${styles.w_49} ${styles.textMargin}`}
            margin="dense"
            required
          />
          <Field
            name="address"
            label={translate('address')}
            component={TextInput}
            variant="outlined"
            margin="dense"
            required
          />
        </Grid>
        <Grid className={styles.dateContainer}>
          <Field
            style={{ width: '100%' }}
            name="scheduleDate"
            label={translate('scheduleDate')}
            component={DatePicker}
            variant="outlined"
            margin="dense"
            required
          />
          <Field
            style={{ width: '100%' }}
            name="requestDate"
            label={translate('requestDate')}
            component={DatePicker}
            variant="outlined"
            margin="dense"
            required
          />
        </Grid>
      </Grid>

      <Field
        name="jobDescription"
        label={translate('jobDescription')}
        component={TextInput}
        variant="filled"
        margin="dense"
        inputClass={styles.hideBorder}
        multiline
        rows="5"
      />
      <Field
        name="actionTaken"
        label={translate('actionTaken')}
        component={TextInput}
        variant="filled"
        margin="dense"
        inputClass={styles.hideBorder}
        multiline
        rows="5"
      />
    </Card>
  );
};

export default CustomerInformationCard;

export default theme => ({
  root: {
    padding: theme.spacing(4),
  },
  header: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.white,
  },
  body: {
    padding: theme.spacing(4)
  },
  quoteMessage: {
    borderTop: '1px solid #C4C4C4',
    borderBottom: '1px solid #C4C4C4',
    padding: '16px 0'
  },
  note: {
    fontWeight: 'Bold',
    fontSize: '14px',
    lineHeight: '24px'
  }
});

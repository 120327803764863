export default theme => ({
  root: {},
  cardHeader: {
    backgroundColor: theme.palette.background.muted2
  },
  titleDate: {
    marginBottom: theme.spacing(1),
  },
  startTime: {
    marginBottom: theme.spacing(1),
  }
});
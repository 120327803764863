import React from 'react';

import { Typography, Box } from '@material-ui/core';
import styles from '../../components.module.scss';

const renderField = ({ input, label }) => {
  if (!input.value) return null;
  return (
    <Box display="flex" flexDirection="column">
      {label && <Typography className={styles.label}>{label}</Typography>}
      <Box>
        <Typography>{input.value}</Typography>
      </Box>
    </Box>
  );
};

export default renderField;

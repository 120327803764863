import React from 'react';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// Component styles
import styles from './styles';

function ConfirmDeleteModal(props) {
  const {
    classes,
    open,
    handleClose,
    handleConfirmDelete,
    translate,
    dialogTitle,
    dialogBodyText,
    children
  } = props;

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      className={classes.modalBox}
    >
      <DialogTitle className={classes.modalTitle} disableTypography>
        <Typography className={classes.titleText}>
          {dialogTitle}
        </Typography>
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <div className={classes.modalBody}>
          {/* dialogBodyText is basic text */}
          {dialogBodyText}
          {/* children is for complex UI */}
          {children}
        </div>
      </DialogContent>
      <DialogActions disableSpacing className={classes.modalFooter}>
        <Button onClick={handleClose} color="secondary">
          {translate('Common:cancel')}
        </Button>
        <Button
          className={classes.deleteButton}
          variant='contained'
          onClick={handleConfirmDelete}
        >
          {translate('Common:delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(ConfirmDeleteModal);

export default theme => ({
  root: {},
  requestClientName: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  client: {
    marginRight: theme.spacing(2),
    color: '#479ECF',
  },
  clientButton: {
    display: 'flex',
    flexFlow: 'row wrap',
    verticalAlign: 'bottom',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  selectClientButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  linkIcon: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    verticalAlign: 'center',
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  selectAddIcon: {
    color: theme.palette.primary.main,
  },
  clientNameText: {
    color: theme.palette.text.secondary,
    marginLeft: 15,
    marginRight: 15,
    verticalAlign: 'middle',
    alignItems: 'center',
    alignSelf: 'center',
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  addButton: {
    marginBottom: 2
  },
  addAddress: {
    marginTop: 15,
  },
  addText: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    '&:hover': theme.palette.primary.main
  },
  changeAddress: {
    marginTop: 15,
    '& p': {
      color: '#8AC788',
    }
  },
  viewDetailsBtn: {
    background: '#F0F0F0',
    borderRadius: 8,
    boxShadow: 'unset',
    marginLeft: 8
  }
});

import { white } from 'common/colors';

export default theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0
  },
  content: {
    paddingTop: theme.spacing(2)
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
  },
  headerTitle: {
    paddingTop: 10,
    textTransform: 'uppercase',
    fontWeight: 900,
  },
  reminderPortlet: {
    padding: 10,
    borderLeft: 'none',
    borderTopLeftRadius: 0,
    height: '100%',
    borderBottomLeftRadius: 0,
    justifyContent:'space-between'
  },
  reminderPortletHeader: {
    borderBottom: 'none',
    paddingLeft: 0
  },
  headerText: {
    fontWeight: 600,
    color: theme.palette.text.gray
  },
  spacing: {
    margin: '10px 0'
  },
  borderBottom: {
    paddingBottom: '10px !important',
  },
  reminderPortletLabel: {
    '& .MuiTypography-h5' : {
      color: white,
      textTransform: 'uppercase',
    }
  },
  porletUnscheduledEvent: {
    minHeight: '150px'
  },
  porletUnassignedEvent: {
    minHeight: '150px'
  },
  listItem: {
    padding: '2px 0',
    cursor: 'pointer',
  },
  portletContent: {
    padding: 0,
    height: '30vh'
  },
  listItemIcon: {
    minWidth: 24
  },
  sectionTitle: {
    fontWeight: 'bold',
    padding: 5,
    backgroundColor: theme.palette.background.muted2,
  },
  itemText: {
    '& .MuiListItemText-primary': {
      color: '#3A4457',
    }
  },
  listControl: {
    minHeight: 150,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: 0,
    paddingLeft: 5,
    maxHeight: '80%'
  },

  lateEvent: {
    color: theme.palette.calendar.lateVisit.color,
    backgroundColor: theme.palette.calendar.lateVisit.backgroundColor,
    borderLeft: `3px solid ${theme.palette.calendar.lateVisit.borderColor}`,
    padding: '0 2px',
    display: 'flex',
    marginBottom: 1,
    height: '100%'
  },
  unassignedEvent: {
    color: theme.palette.calendar.unassigned.color,
    backgroundColor: theme.palette.calendar.unassigned.backgroundColor,
    borderLeft: `3px solid ${theme.palette.calendar.unassigned.borderColor}`,
    padding: '0 2px',
    display: 'flex',
    marginBottom: 1,
    height: '100%'
  },
  onStandbyEvent: {
    color: theme.palette.calendar.onStandby.color,
    backgroundColor: theme.palette.calendar.onStandby.backgroundColor,
    borderLeft: `3px solid ${theme.palette.calendar.onStandby.borderColor}`,
    padding: '0 2px',
    display: 'flex',
    marginBottom: 1,
    height: '100%'
  },
  onTheWayEvent: {
    color: theme.palette.calendar.onTheWay.color,
    backgroundColor: theme.palette.calendar.onTheWay.backgroundColor,
    borderLeft: `3px solid ${theme.palette.calendar.onTheWay.borderColor}`,
    padding: '0 2px',
    display: 'flex',
    marginBottom: 1,
    height: '100%'
  },
  arrivedEvent: {
    color: theme.palette.calendar.arrived.color,
    backgroundColor: theme.palette.calendar.arrived.backgroundColor,
    borderLeft: `3px solid ${theme.palette.calendar.arrived.borderColor}`,
    padding: '0 2px',
    display: 'flex',
    marginBottom: 1,
    height: '100%'
  },
  startedEvent: {
    color: theme.palette.calendar.started.color,
    backgroundColor: theme.palette.calendar.started.backgroundColor,
    borderLeft: `3px solid ${theme.palette.calendar.started.borderColor}`,
    padding: '0 2px',
    display: 'flex',
    marginBottom: 1,
    height: '100%'
  },
  completedEvent: {
    color: theme.palette.calendar.completed.color,
    backgroundColor: theme.palette.calendar.completed.backgroundColor,
    borderLeft: `3px solid ${theme.palette.calendar.completed.borderColor}`,
    padding: '0 2px',
    display: 'flex',
    marginBottom: 1,
    height: '100%'
  },
  cancelledEvent: {
    color: theme.palette.calendar.cancelled.color,
    backgroundColor: theme.palette.calendar.cancelled.backgroundColor,
    borderLeft: `3px solid ${theme.palette.calendar.cancelled.borderColor}`,
    padding: '0 2px',
    display: 'flex',
    marginBottom: 1,
    height: '100%'
  },
  eventContent: {
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis'
  },
  eventTitle: {
    wordBreak: 'break-word',
    fontSize: '10px',
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis'
  },
  eventTime: {
    fontSize: '8px'
  },
  scheduleColor: {
    color: '#ED5A66'
  },
  assignedColor: {
    color: '#FF770B'
  },
  borderTop: {
    borderTop: '1px solid #D9D9D9'
  }
});

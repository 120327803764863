// Actions
const SET_SUBSCRIPTION_WARMING = 'SET_SUBSCRIPTION_WARMING';
const CLEAR_SUBSCRIPTION_WARMING = 'CLEAR_SUBSCRIPTION_WARMING';

const initialState = {
  data: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SUBSCRIPTION_WARMING:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        }
      };

    case CLEAR_SUBSCRIPTION_WARMING:
      return {
        ...state,
        data: null
      };

    default: return state;
  }
}

// Action Creators
export function setSubscriptionWarming(data) {
  return { type: SET_SUBSCRIPTION_WARMING, data };
}

export function clearSubscriptionWarming() {
  return { type: CLEAR_SUBSCRIPTION_WARMING };
}

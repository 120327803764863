import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Component styles
import styles from './styles';

function ProductsHeader(props) {
  const { classes, className, translate } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Typography variant='h2' className={classes.pageTitle}>
        {translate('productsServices')}
      </Typography>
      <Typography variant='h5'>
        {translate('productsServicesDesc')}
      </Typography>
    </div>
  );
};

ProductsHeader.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array
};

ProductsHeader.defaultProps = {
  selectedUsers: []
};

export default withStyles(styles)(ProductsHeader);

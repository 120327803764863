import React, { useState, useEffect } from 'react';
import { FormControlLabel, Grid, Button } from '@material-ui/core';
import { TimePicker, Checkbox } from 'components/inputs';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import styles from './styles.module.scss';

const DAYOFWEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
const startTime = new Date();
startTime.setHours('09', '00');
const endTime = new Date();
endTime.setHours('18', '00');

const workingHoursDefault = DAYOFWEEK.map((item) => {
  let working = true;
  if (['sunday', 'saturday'].includes(item)) working = false;
  return {
    day: item,
    working,
    startTime: working ? startTime : null,
    endTime: working ? endTime : null,
  };
});

const DayBusinessHour = (props) => {
  const { field, value, translate, styles } = props;
  const [isDisable, setIsDisable] = useState(null);

  useEffect(() => {
    setIsDisable(!value.working);
  }, [value.working]);

  const handleChangeWorkingState = (event) => {
    setIsDisable(!event.target.checked);
  };
  return (
    <>
      <Grid container direction="row" justify="center" spacing={2}>
        <Grid item lg={2} md={3} sm={4}>
          <FormControlLabel
            className={styles.checkboxAndLabel}
            control={
              <Field
                component={Checkbox}
                name={`${field}.working`}
                onChange={(event) => handleChangeWorkingState(event)}
              />
            }
            label={translate(`Common:${value.day}`)}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4}>
          <Field
            name={`${field}.startTime`}
            component={TimePicker}
            placeholder="08:30 AM"
            label={translate('CompanySetting:startTime')}
            minDate={null}
            disabled={isDisable}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4}>
          <Field
            name={`${field}.endTime`}
            component={TimePicker}
            placeholder="06:00 PM"
            label={translate('CompanySetting:endTime')}
            minDate={null}
            disabled={isDisable}
          />
        </Grid>
      </Grid>
    </>
  );
};

const BusinessHours = (props) => {
  const { styles, fields, translate } = props;
  return (
    <>
      {fields.map((field, index) => {
        return (
          <DayBusinessHour
            key={index}
            index={index}
            field={field}
            value={fields.get(index)}
            translate={translate}
            styles={styles}
          />
        );
      })}
    </>
  );
};

let BusinessHoursPage = (props) => {
  const { translate, pristine, invalid, submitting, handleSubmit } = props;
  return (
    <div className={styles.companySetting}>
      <form className="form-body" onSubmit={handleSubmit}>
        <div className={styles.formTitle}>
          <div className={styles.title}>
            <h2>{translate('CompanySetting:businessHours')}</h2>
          </div>
          <div className={styles.submitBtnWrap}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              size="large"
              className={styles.btnStyle}
              disabled={pristine || submitting || invalid}
            >
              {translate('CompanySetting:saveChange')}
            </Button>
          </div>
        </div>
        <Grid item container spacing={2} justify="center">
          <FieldArray
            name="workingHours"
            component={BusinessHours}
            styles={styles}
            translate={translate}
          />
        </Grid>
      </form>
    </div>
  );
};

BusinessHoursPage = reduxForm({
  form: 'WorkingHoursForm',
  enableReinitialize: true,
})(BusinessHoursPage);

const mapStateToProps = (state, ownProps) => {
  const workingHours =
    (ownProps.companyDetails && ownProps.companyDetails.workingHours) ||
    workingHoursDefault;
  return {
    initialValues: {
      id: state.company.details.id || null,
      workingHours,
    },
  };
};

export default compose(connect(mapStateToProps, null))(BusinessHoursPage);

import React from "react";

// Redux
import { compose } from "redux";

// Material components
import { Button, Grid, withStyles } from "@material-ui/core";

const UploadIcon = require("assets/images/upload-icon.svg");

const styles = () => ({
  cleanerImage: {
    marginRight: "10px !important",
    width: 20,
    height: 20,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "0px",
  },
  btnUpload: {
    background: "#479ECF",
    color: "#101010",
    boxShadow: "none",
    "&:hover": {
      background: "#479ECF",
    },
  },
});

const FilesUploadInput = (props) => {
  const { classes, onSubmit } = props;

  return (
    <Grid container direction="column">
      <label className={classes.btnContainer} htmlFor="upload-photo">
        <input
          style={{ display: "none" }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          onChange={(e) => onSubmit(e.target.files)}
          multiple
        />
        <Button
          color="primary"
          className={classes.btnUpload}
          variant="contained"
          component="span"
        >
          <img
            src={UploadIcon}
            className={classes.cleanerImage}
            alt="upload_icon"
          />
          Upload
        </Button>
      </label>
    </Grid>
  );
};

export default compose(withStyles(styles))(FilesUploadInput);

import React from 'react';
import ItemOnly from '../cards/ItemOnly';
import ServiceOnly from '../cards/ServiceOnly';
import ServicesCard from '../cards/ServicesCard';

const components = [ServicesCard, ServiceOnly, ItemOnly];

const ItemForm = (props) => {
  const { selectedButton = 0 } = props;

  const Comp = components[selectedButton];
  if (!Comp) return null;

  return <Comp {...props} />;
};

export default ItemForm;

import React from 'react';
// Redux
import { Card, Grid, Typography, Button, IconButton } from '@material-ui/core';
import { Field, FieldArray, change, formValueSelector } from 'redux-form';
import { TextInput } from 'components/inputs';
import { connect } from 'react-redux';
import { Portlet, PortletLabel } from 'pages/Dashboard/components';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import deleteSrc from 'assets/images/delete.svg';
import styles from './styles/serviceReport.module.scss';
import { SERVICE_REPORT_FORM_NAME } from 'common/constant';

const serviceReportFormValue = formValueSelector(SERVICE_REPORT_FORM_NAME);

const renderDeviceInfo = ({ fields, translate, serviceTypeOptions }) => {
  return fields.map((field, index) => {
    return (
      <Grid container wrap="nowrap" key={index}>
        <Grid item className={styles.w_30}>
          <Field
            name={`${field}.modelNumber`}
            label={translate('modelNumber')}
            component={TextInput}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item className={styles.w_30}>
          <Field
            name={`${field}.serialNumber`}
            label={translate('serialNumber')}
            component={TextInput}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item className={styles.w_30}>
          <Field
            name={`${field}.serviceType`}
            label={translate('serviceType')}
            component={TextInput}
            SelectProps={{ native: true }}
            variant="outlined"
            margin="dense"
            select
          >
            {serviceTypeOptions.map((option, id) => (
              <option key={id} value={option}>
                {option}
              </option>
            ))}
          </Field>
        </Grid>
        {index !== 0 && (
          <IconButton onClick={() => fields.remove(index)}>
            <img src={deleteSrc} alt="delete_icon" />
          </IconButton>
        )}
      </Grid>
    );
  });
};

const DeviceInfoCard = ({ translate, deviceInfo, changeField }) => {
  const serviceTypeOptions = [
    translate('serviceTypeSiteRepair'),
    translate('serviceTypeInHouseRepair'),
    translate('serviceTypeFollowUp'),
    translate('serviceTypeMaintenance'),
    translate('serviceTypeDiagnostic'),
    translate('serviceTypeInstallation'),
  ];

  const defaultDeviceInfo = {
    modelNumber: '',
    serialNumber: '',
    serviceType: serviceTypeOptions[0],
  };

  const handleAddDeviceInfo = () => {
    changeField('deviceInfo', [...deviceInfo, defaultDeviceInfo]);
  };

  return (
    <Card className={`${styles.cardPadding} mt_25`}>
      <Typography variant="h4" className="mb_20">
        {translate('deviceInfo')}
      </Typography>
      <Portlet className="p_20">
        <Grid container justify="flex-end">
          <Button onClick={handleAddDeviceInfo}>
            <AddCircleIcon className={styles.iconAdd} />
            <Typography className={styles.newIcon} variant="body1">
              {translate('Common:new')}
            </Typography>
          </Button>
        </Grid>
        <Grid container>
          <Grid item className={styles.w_30}>
            <PortletLabel variant="h6" title={translate('modelNumber')} />
          </Grid>
          <Grid item className={styles.w_30}>
            <PortletLabel variant="h6" title={translate('serialNumber')} />
          </Grid>
          <Grid item className={styles.w_40}>
            <PortletLabel variant="h6" title={translate('serviceType')} />
          </Grid>
        </Grid>
        <FieldArray
          name="deviceInfo"
          component={renderDeviceInfo}
          translate={translate}
          serviceTypeOptions={serviceTypeOptions}
        />
      </Portlet>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceInfo: serviceReportFormValue(state, 'deviceInfo'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) =>
    dispatch(change(SERVICE_REPORT_FORM_NAME, field, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInfoCard);

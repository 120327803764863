import React from 'react';
import { useTranslation } from 'react-i18next';

// Material components
import TextField from '@material-ui/core/TextField';

// Material helpers
import { withStyles } from '@material-ui/core';

// Component styles
const styles = {
  root: {
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0,
      '& .MuiOutlinedInput-notchedOutline': {
        padding: 0,
      },
      '& .MuiOutlinedInput-inputAdornedEnd': {
        paddingLeft: 8,
      },
    },
    '& .MuiInputAdornment-positionEnd': {
      margin: 0,
    },
  },
  inputAdornment: {
    position: 'end',
    '& .MuiIconButton-root': {
      padding: '10px 10px 10px 5px',
    },
  },
};

export default withStyles(styles)((props) => {
  const {
    classes,
    input,
    label,
    meta: { touched, error } = {},
    helperText,
    ...rest
  } = props;
  const { t } = useTranslation('Error');
  function handleDateChange(value) {
    input.onChange(value);
  }

  if (rest.minDate === null) {
    rest.minDate = undefined;
  }

  return (
    <TextField
      id="datetime-local"
      type="datetime-local"
      InputLabelProps={{
        shrink: true,
      }}
      className={classes.root}
      margin="dense"
      label={label}
      value={(input && input.value) || ''}
      onChange={(value) => handleDateChange(value)}
      error={touched && error !== undefined}
      helperText={touched ? t(error) || helperText : helperText}
      {...rest}
    />
  );
});

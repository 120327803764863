import React from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { showSuccessMessage } from 'lib/notifier';
import { useTranslation } from 'react-i18next';

const RenderField = ({
  input,
  label,
  valueVariant = 'body1',
  customStyle,
  flexDirection = 'column',
  labelStyle,
  isShowCopyBtn = false,
}) => {
  const { t: translate } = useTranslation();
  if (!input.value) return null;

  const handleCopy = () => {
    navigator.clipboard.writeText(input.value);
    showSuccessMessage(translate('Dashboard:textCopied'));
  };

  if (flexDirection === 'row') {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box width="30%" className="mr_5">
          {label && <Typography className={labelStyle}>{label}</Typography>}
        </Box>

        <Box width="70%" display="flex" flexDirection="row" alignItems="center" justifyContent='space-between'>
          <Typography className={customStyle} variant={valueVariant}>
            {input.value}
          </Typography>

          {isShowCopyBtn && (
            <Button onClick={handleCopy} style={{ color: '#0075FE' }}>
              {translate('copy')}
            </Button>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="column">
      {label && <Typography className={labelStyle}>{label}</Typography>}

      <Typography className={customStyle} variant={valueVariant}>
        {input.value}
      </Typography>
    </Box>
  );
};

export default RenderField;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import deleteIcon from 'assets/images/delete.svg';
import { deleteDetails } from 'redux/media';

const DeleteMediaDetails = (props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { deleteDetail, mediaDetails, changeModalState, fetchMedia, fetchVisits, afterUpdateMedia } = props;
  const { t } = useTranslation('Common');
  const handleConfirmedDelete = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    const response = await deleteDetail({ mediaIds: [mediaDetails.id] }).catch((e) => ({ e }));
    if (response.e) return showErrorMessage(response.e);
    await Promise.all([afterUpdateMedia(), fetchMedia(), fetchVisits()]);
    setIsLoading(false);
    changeModalState(false);
    showSuccessMessage(t('completed'));
  };
  const ConfirmModal = () => {
    return (
      <Dialog aria-labelledby="customized-dialog-title" open={showConfirmModal}>
        <DialogTitle disableTypography>
          <Typography variant='h4'>
            <Trans i18nKey="Job:confirmDelete" />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Trans i18nKey="Job:confirmDeleteContent">
              {mediaDetails && mediaDetails.id.toString()}
            </Trans>
          </Typography>
        </DialogContent>
        <DialogActions disableSpacing>
          <Button onClick={() => setShowConfirmModal(false)} color="secondary">
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={() => handleConfirmedDelete()}>
            {t('uppercaseDelete')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <>
      <IconButton disabled={isLoading} onClick={() => setShowConfirmModal(true)}>
        <img src={deleteIcon} alt="delete-icon" />
      </IconButton>
      <ConfirmModal open={showConfirmModal} handleClose={setShowConfirmModal} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteDetail: (params) => dispatch(deleteDetails(params)),
});

export default connect(null, mapDispatchToProps)(DeleteMediaDetails);

import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { Header, Footer } from 'components/publicComponents';


const FeaturePage = props => {
  const { geoCountry, t } = props;
  return (
    <div>
      <Header translate={t} secondaryPage />

      {/* <!-- breadcrumb start--> */}
      <section className="breadcrumb breadcrumb_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb_iner text-center">
                <div className="breadcrumb_iner_item">
                  <h2>{t('productFeatures')}</h2>
                  {/* <h5>{translate('productFeatures')} <span></span> {translate('productFeatures')}</h5> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- breadcrumb start--> */}

      {/* <!--::use sasu part end::--> */}
      <section className="use_sasu padding_top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="single_feature">
                <div className="single_feature_part">
                  <img src="img/icon/feature_icon_1.png" alt="" />
                  <h3>{t('beforeJob')}</h3>
                  <ul>
                    <li>{t('staffScheduling')}</li>
                    <li>{t('jobEstimates')}</li>
                    <li>{t('jobAssignment')}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single_feature">
                <div className="single_feature_part">
                  <img src="img/icon/feature_icon_2.png" alt="" />
                  <h3>{t('duringTheJob')}</h3>
                  <ul>
                    <li>{t('onMyWay')}</li>
                    <li>{t('gpsTracking')}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single_feature">
                <div className="single_feature_part">
                  <img src="img/icon/feature_icon_3.png" alt="" />
                  <h3>{t('afterJob')}</h3>
                  <ul>
                    <li>{t('eInvoicing')}</li>
                    <li>{t('followUpMarketing')}</li>
                    <li>{t('staffTimesheets')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="img/animate_icon/Ellipse_1.png" alt="" className="feature_icon_1 custom-animation1" />
      </section>
      {/* <!--::use sasu part end::--> */}

      {/* <!--::about_us part1 start::--> */}
      <section className="about_us section_padding">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-5">
              <div className="about_us_text">
                <h2>{t('organiseTeam')}</h2>
                <p>{t('organiseTeamReason')}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="learning_img">
                <img src="img/about_img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <img src="img/animate_icon/Ellipse_4.png" alt="" className="feature_icon_1 custom-animation1" />
      </section>
      {/* <!--::about_us part1 end::--> */}

      {/* <!--::about_us part2 start::--> */}
      <section className="about_us right_time">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="learning_img">
                <img src="img/about_img_1.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="about_us_text">
                <h2>{t('impressCustomers')}</h2>
                <p>{t('impressCustomersReason')}</p>
              </div>
            </div>
          </div>
        </div>
        <img src="img/animate_icon/Ellipse_5.png" alt="" className="feature_icon_2 custom-animation2" />
      </section>
      {/* <!--::about_us part2 end::--> */}

      {/* <!--::about_us part3 start::--> */}
      <section className="about_us section_padding">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-5">
              <div className="about_us_text">
                <h2>{t('receivePayments')}</h2>
                <p>{t('receivePaymentsReason')}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="learning_img">
                <img src="img/about_img_2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <img src="img/animate_icon/Ellipse_4.png" alt="" className="feature_icon_1 custom-animation1" />
      </section>
      {/* <!--::about_us part3 end::--> */}

      {/* <!--::about_us part4 start::--> */}
      <section className="about_us right_time">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="learning_img">
                <img src="img/about_img_3.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="about_us_text">
                <h2>{t('powerfulMarketingTools')}</h2>
                <p>{t('marketingToolsReason')}</p>
              </div>
            </div>
          </div>
        </div>
        <img src="img/animate_icon/Ellipse_5.png" alt="" className="feature_icon_2 custom-animation2" />
      </section>
      {/* <!--::about_us part4 end::--> */}

      {/* <!--::about_us part5 start::--> */}
      <section className="about_us section_padding">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-5">
              <div className="about_us_text">
                <h2>{t('moreJobs')}</h2>
                <p>{t('moreJobsReason')}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="learning_img">
                <img src="img/about_img_4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <img src="img/animate_icon/Ellipse_4.png" alt="" className="feature_icon_1 custom-animation1" />
      </section>
      {/* <!--::about_us part5 end::--> */}

      {/* <!--::sign_up part start::--> */}
      <section className="review_part padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 mx-auto">
              <div className="item carousel_body_text review_part_text text-center">
                <h2>{t('signUpNow')}</h2>
                <p>{t('signUpReason')}</p>
                <Link to="sign-up" className="btn_2">{t('getStarted')}</Link>
              </div>
            </div>
          </div>
        </div>
        <img src="img/animate_icon/Ellipse_4.png" alt="" className="feature_icon_2 custom-animation2" />
      </section>
      {/* <!--::sign_up part end::--> */}

      <Footer translate={t} country={geoCountry} />
    </div>
  );
};

const mapStateToProps = state => ({
  geoCountry: state.auth.geoCountry,
});

export default connect(mapStateToProps, null)(withRouter(FeaturePage));
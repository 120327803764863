import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// Redux
import { Field, reduxForm } from 'redux-form';
// shared component
import { Portlet, PortletContent, PortletHeader, PortletLabel } from 'pages/Dashboard/components';
import { TextInput } from 'components/inputs';
// Material component
import { Grid, Button, InputAdornment } from '@material-ui/core';

let InvoiceSettingForm = props => {
  const { translate, handleSubmit, styles } = props;

  return (
    <Portlet className={styles.section}>
      <PortletHeader className={styles.cardHeader}>
        <PortletLabel className={styles.cardLabel} title={translate('WorkSetting:defaultInvoiceSetting')} />
      </PortletHeader>
      <PortletContent className={styles.contentPortlet}>
        <form className='form-body' onSubmit={handleSubmit}>
          <Grid item container spacing={2} justify='flex-start'>
            {/* <Grid item xs={12} sm={12} md={12}>
              <Field
                name='defaultSubject'
                component={TextInput}
                margin='normal'
                label={translate('WorkSetting:defaultInvoiceSubject')}
                type='text'
                variant='outlined'
              />
            </Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              <Field
                name='defaultNote'
                component={TextInput}
                margin='normal'
                label={translate('WorkSetting:defaultInvoiceNote')}
                type='text'
                variant='outlined'
                multiline
                rows="4"
              />
            </Grid>

            <Grid item xs={2} sm={2} md={2}>
              <Field
                name="defaultPaymentDue"
                component={TextInput}
                label={translate('WorkSetting:defaultPaymentDue')}
                margin="normal"
                variant='outlined'
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">{translate('WorkSetting:day')}</InputAdornment>,
                  inputProps: { min: 1 }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant='contained'
                color='secondary'
                type='submit'
              >
                {translate('WorkSetting:updateInvoiceSetting')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </PortletContent>
    </Portlet>
  );
};


const mapStateToProps = (state, props) => {
  return ({
    initialValues: {
      defaultSubject: props.invoiceSetting && props.invoiceSetting.defaultSubject,
      defaultNote: props.invoiceSetting && props.invoiceSetting.defaultNote,
      defaultPaymentDue: props.invoiceSetting && props.invoiceSetting.defaultPaymentDue,
    }
  });
};

InvoiceSettingForm = reduxForm({
  form: 'InvoiceSettingForm',
  enableReinitialize: true
})(InvoiceSettingForm);

export default compose(
  connect(mapStateToProps, null),
)(InvoiceSettingForm);
export default {
  personalDetails: 'ข้อมูลส่วนตัว',
  passwordAndSecurity: 'รหัสผ่านและความปลอดภัย',
  changePassword: 'เปลี่ยนรหัสผ่าน',
  password: 'รหัสผ่านใหม่',
  repeatPassword: 'ใส่รหัสผ่านใหม่อีกครั้ง',
  updatePasswordSuccessfully: 'อัปเดตรหัสผ่านเรียบร้อย',
  passwordConfirmationNotMatch: 'รหัสผ่านยืนยันไม่ตรงกับรหัสผ่านใหม่',
  extraText: 'ใช้รหัสผ่านที่แตกต่างจากที่อื่นเพื่อป้องกันบัญชีของคุณ',
  currentPassword: 'รหัสผ่านปัจจุบัน',
  preference: 'การตั้งค่า',
  preferredLanguage: 'ภาษาหลัก',
  savePreferences: 'บันทึกการตั้งค่า',
  role: 'ตำแหน่ง',
  firstName: 'ชื่อ',
  lastName: 'นามสกุล',
  contactPhoneNumber: 'เบอร์โทรศัพท์',
  email: 'อีเมล',
  address: 'ที่อยู่',
  updateLanguageSuccessfully: 'อัปเดตภาษาเรียบร้อย',
  change: 'เปลี่ยน',
  cancel: 'ยกเลิก',
  needCurrentPassword: 'ใส่รหัสผ่านปัจจุบันหรือเปลี่ยนรหัสผ่านใหม่',
  newPasswordHasToBeDifferentOldPassword: 'รหัสผ่านใหม่จำเป็นต้องไม่ซ้ำกับรหัสผ่านปัจจุบัน',
  pleaseEnterNewPassword: 'กรุณาใส่รหัสผ่านใหม่'
};

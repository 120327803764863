import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

// Material helpers
import { withStyles } from '@material-ui/core';

// Custom components
import { InvoiceToolbar, InvoiceListTable } from '../components/utils';

import { getInvoices } from 'redux/invoice';

// Component styles
import styles from './style';

import { showErrorMessage } from 'lib/notifier';

import moment from 'moment';

const InvoiceList = props => {
  const { classes, history, routes, match, translate } = props;
  const [includingArchived, setIncludingArchived] = useState(false);
  const [issuedAtFilter, setIssuedAtFilter] = useState('ALL');
  const [issuedAtFrom, setIssuedAtFrom] = useState(null);
  const [issuedAtTo, setIssuedAtTo] = useState(null);
  let searchKeyword = '';
  const filterDateOptions = [
    { id: 'ALL', name: translate('Common:all') },
    { id: 'LAST_30_DAYS', name: translate('Common:last30Days') },
    { id: 'THIS_MONTH', name: translate('Common:thisMonth') },
    { id: 'LAST_MONTH', name: translate('Common:lastMonth') },
    { id: 'THIS_YEAR', name: translate('Common:thisYear') },
    { id: 'CUSTOM', name: translate('Common:custom') }
  ];

  const handleNewInvoiceClick = () => {
    history.push('/invoices/new');
  };

  const handleExportInvoices = () => {
    const { timeZone } = props;

    fetchInvoices({
      exportXlsx: true,
      timeZone,
      search: searchKeyword
    }).then((response) => {
      if (response.status === 200 && response.data.url) {
        window.location = response.data.url;
      }
    }, (error) => {
      showErrorMessage(error);
    });
  };

  const getFilterIssuedAt = () => {
    const filterDueDayOptions = {};
    switch (issuedAtFilter) {
      case 'LAST_30_DAYS':
        filterDueDayOptions['issuedAt[$lte]'] = moment().endOf('day').format();
        filterDueDayOptions['issuedAt[$gte]'] = moment().add(-30, 'days').startOf('day').format();
        return filterDueDayOptions;

      case 'THIS_MONTH':
        filterDueDayOptions['issuedAt[$lte]'] = moment().endOf('month').endOf('day').format();
        filterDueDayOptions['issuedAt[$gte]'] = moment().startOf('month').startOf('day').format();
        return filterDueDayOptions;

      case 'LAST_MONTH':
        filterDueDayOptions['issuedAt[$lte]'] = moment().subtract(1, 'months').endOf('month').endOf('day').format();
        filterDueDayOptions['issuedAt[$gte]'] = moment().subtract(1, 'months').startOf('month').startOf('day').format();
        return filterDueDayOptions;

      case 'THIS_YEAR':
        filterDueDayOptions['issuedAt[$lte]'] = moment().endOf('year').endOf('day').format();
        filterDueDayOptions['issuedAt[$gte]'] = moment().startOf('year').startOf('day').format();
        return filterDueDayOptions;

      case 'CUSTOM':
        if (issuedAtTo) {
          filterDueDayOptions['issuedAt[$lte]'] = moment(issuedAtTo).endOf('day').format();
        }
        if (issuedAtFrom) {
          filterDueDayOptions['issuedAt[$gte]'] = moment(issuedAtFrom).startOf('day').format();
        }
        return filterDueDayOptions;

      default: return filterDueDayOptions;
    }
  };

  const fetchInvoices = params => {
    const { getInvoices } = props;
    return new Promise((resolve, reject) => {
      const { skip, limit, pageSize, page, orderBy, orderDirection, search, exportXlsx, timeZone } = params;
      if (search !== searchKeyword) {
        searchKeyword = search;
      }
      const sortParams = {};
      if (orderBy && orderDirection) {
        sortParams[`$sort[${orderBy.field}]`] = orderDirection === 'asc' ? 1 : -1;
      };

      const paramsOption = {
        $limit: limit || pageSize,
        $skip: skip === 0 ? 0 : (skip || pageSize * page || 0),
        $q: search,
        includingArchived,
        exportXlsx,
        timeZone,
        ...sortParams
      };

      const IssuedAtOptions = getFilterIssuedAt();
      Object.assign(paramsOption, IssuedAtOptions);
      getInvoices({
        params: paramsOption
      }).then((response) => {
        if (exportXlsx) return resolve(response);

        resolve({
          data: response.data.data,
          page,
          totalCount: parseInt(response.data.total)
        });
      }, (error) => {
        showErrorMessage(error);
        reject(error);
      });
    });
  };

  return (
    <div className={classes.root}>
      <InvoiceToolbar
        listPage
        routes={routes}
        match={match}
        handleClickNew={handleNewInvoiceClick}
        handleExportInvoices={handleExportInvoices}
        translate={translate}
      />

      <div className={classes.content}>
        <InvoiceListTable
          translate={translate}
          fetchInvoices={fetchInvoices}
          includingArchived={includingArchived}
          setIncludingArchived={setIncludingArchived}
          filterDateOptions={filterDateOptions}
          issuedAtFilter={issuedAtFilter}
          setIssuedAtFilter={setIssuedAtFilter}
          issuedAtFrom={issuedAtFrom}
          setIssuedAtFrom={setIssuedAtFrom}
          issuedAtTo={issuedAtTo}
          setIssuedAtTo={setIssuedAtTo}
        />
      </div>
    </div>
  );
};

InvoiceList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  timeZone: state.auth.currentUser.timeZone
});

const mapDispatchToProps = dispatch => ({
  getInvoices: (options) => dispatch(getInvoices(options)),
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(InvoiceList);

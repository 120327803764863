import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const wmLogo = require('assets/images/logo/logo.png');
const userAvatar = require('assets/images/greeting/user-avatar.png');
const assignmentIcon = require('assets/images/greeting/assignment-icon.png');
const scheduleIcon = require('assets/images/greeting/schedule-icon.png');
const completingIcon = require('assets/images/greeting/completing-icon.png');
const gettingPaidIcon = require('assets/images/greeting/getting-paid-icon.png');



export default class GreetingPage extends React.Component {
  render() {
    return (
      <div className='greeting-page'>
        <div className='header-logo'>
          <img className='logo' src={wmLogo} alt='header-logo' />
        </div>

        <div className='page-title'>

          <div className='congratulation-text'>
            congratulations audrey! <br /> you've taken the first step <br /> to a better business.
          </div>
          <div className='discription'> To learn how WorkPulse powers your sales, operators, and customer service,
            <br /> take our 3 minutes tour.
          </div>
        </div>

        <div className='user-exps'>
          <img className='user-exp' src={userAvatar} alt='user-avatar' />
          <div className='description'>
            Meet Audrey. Let's us Audrey as an example of a <br /> person requesting for a job with your business.
          </div>
        </div>

        <div className='job-steps'>
          <ul className='progress-indicator '>
            <li className='step'>
              <div className='assignment-icon'>
                <img src={assignmentIcon} alt='' />
              </div>
              <div className='text'>Getting New Job</div>
            </li>
            <li className='step'>
              <div className='scheduling-icon'>
                <img src={scheduleIcon} alt='' />
              </div>
              <div className='text'>Scheduling Job</div>
            </li>
            <li className='step'>
              <div className='completing-icon'>
                <img src={completingIcon} alt='' />
              </div>
              <div className='text'> Completing Work</div>
            </li>
            <li className='step'>
              <div className='getting-paid-icon'>
                <img src={gettingPaidIcon} alt='' />
              </div>
              <div className='text'>Getting Paid</div>
            </li>
          </ul>
        </div>

        <div className='start-the-tour'>
          <Link className='redirect-to-tour' to='/tour'><Button className='start-the-tour-btn' variant='contained'>Start the tour</Button></Link>
        </div>
        <a className='skip-the-tour' href='#skip'>skip tour for now</a>
      </div>
    );
  }
}
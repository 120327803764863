import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import Paper from '../Paper';

// Component styles
const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  jobLabel: {
    borderTop: `5px solid ${theme.palette.primary.main}`,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
  invoiceLabel: {
    borderTop: `5px solid ${theme.palette.primary.main}`,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  }
});

const Portlet = props => {
  const { classes, className, jobLabel, invoiceLabel, children, ...rest } = props;

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.jobLabel]: jobLabel,
      [classes.invoiceLabel]: invoiceLabel
    },
    className
  );

  return (
    <Paper
      {...rest}
      className={rootClassName}
      elevation={0}
      outlined
      squared={false}
    >
      {children}
    </Paper>
  );
};

Portlet.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Portlet);

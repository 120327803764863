import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Card,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { jobActions } from "redux/job";

import { Field, Fields } from "redux-form";
import { RenderField } from "components/view";
import { StatusChip } from "pages/Dashboard/components";

import styles from "../card.module.scss";

import editSrc from "assets/images/icons/edit.svg";

import { MorePopover } from "./components";
import { showErrorMessage, showSuccessMessage } from "lib/notifier";
import { HandleReviewModal } from "../../ConfirmReviewModal";
import ReviewChip from "pages/Dashboard/components/ReviewChip";

const RenderStatusChip = ({
  statusId,
  verified,
  startVerifiedAt,
  label,
  labelStyle,
}) => {
  const getReviewStatus = () => {
    if (verified?.input.value) return "REVIEWED";
    return startVerifiedAt?.input.value ? "IN_REVIEW" : "";
  };

  if (!statusId?.input.value) return null;
  return (
    <Box display="flex" flexDirection="row">
      <Box width="30%" className="mr_5">
        {label && <Typography className={labelStyle}>{label}</Typography>}
      </Box>
      <Box width="60%" display="flex" flexDirection="row" alignItems="center">
        <StatusChip
          statusFor="job"
          status={statusId?.input.value}
          reviewStatus={getReviewStatus()}
        />
        {getReviewStatus() === "IN_REVIEW" && (
          <ReviewChip status={getReviewStatus()} />
        )}
      </Box>
    </Box>
  );
};

const AboutJob = (props) => {
  const {
    translate,
    history,
    job,
    fetchJobDetails,
    afterSaveVisitDetails,
  } = props;
  const dispatch = useDispatch();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(
    false
  );

  const handleClickEdit = () => {
    const {
      location: { pathname },
    } = history;
    history.push(`${pathname}/edit`);
  };

  const handleCloseModal = () => setIsOpenConfirmModal(false);
  const handleOpenModal = () => setIsOpenConfirmModal(true);
  const handleConfirm = async () => {
    const action = job.archived ? "UNARCHIVE_JOB" : "ARCHIVE_JOB";
    const result = await dispatch(
      jobActions(job.publicId, action)
    ).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    fetchJobDetails();
    handleCloseModal();
  };

  const handleCloseDeleteModal = () => setIsOpenConfirmDeleteModal(false);
  const handleConfirmDelete = async () => {
    const action = "DELETE_JOB";
    const result = await dispatch(
      jobActions(job.publicId, action)
    ).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    showSuccessMessage(translate("deleteJobSuccessfully"));
    history.push("/jobs");
  };

  return (
    <Card>
      <Dialog
        open={isOpenConfirmDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h4" className="mb_5">
            {translate("deleteThisJob")}
          </Typography>
          {translate("deleteThisJobDescription")}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} variant="contained">
            {translate("Common:close")}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            className={styles.confirm_archived}
            variant="contained"
            autoFocus
          >
            {translate("Common:delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenConfirmModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h4" className="mb_5">
            {translate(job.archived ? "unarchivedThisJob" : "archivedThisJob")}
          </Typography>
          {translate(
            job.archived
              ? "unarchivedThisJobDescription"
              : "archivedThisJobDescription"
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained">
            {translate("Common:close")}
          </Button>
          <Button
            onClick={handleConfirm}
            className={styles.confirm_archived}
            variant="contained"
            autoFocus
          >
            {translate(job.archived ? "Common:unarchive" : "Common:archive")}
          </Button>
        </DialogActions>
      </Dialog>
      <Box p={2}>
        <Typography className={`${styles.grey_color} font_bold`}>
          {translate("Enterprise:aboutThisJob")}
        </Typography>

        <Box mt={2}>
          <Field
            name="title"
            component={RenderField}
            label={translate("jobTitle")}
            flexDirection="row"
            labelStyle={styles.grey_color}
          />
        </Box>
        <Box mt={2}>
          <Field
            name="publicId"
            component={RenderField}
            label={translate("jobId")}
            flexDirection="row"
            labelStyle={styles.grey_color}
            isShowCopyBtn
          />{" "}
        </Box>
        <Box mt={2}>
          <Field
            name="description"
            component={RenderField}
            label={translate("description")}
            flexDirection="row"
            labelStyle={styles.grey_color}
          />
        </Box>

        <Box mt={2}>
          <Field
            name="orderId"
            component={RenderField}
            label={translate("orderId")}
            flexDirection="row"
            labelStyle={styles.grey_color}
            isShowCopyBtn
          />
        </Box>

        <Box mt={2}>
          <Fields
            names={["statusId", "verified", "startVerifiedAt"]}
            component={RenderStatusChip}
            label={translate("status")}
            flexDirection="row"
            labelStyle={styles.grey_color}
          />{" "}
        </Box>

        <Box mt={2}>
          <Field
            name="startOn"
            label={translate("startOn")}
            component={RenderField}
            flexDirection="row"
            labelStyle={styles.grey_color}
          />
        </Box>
        <Box mt={2}>
          <Field
            name="endOn"
            label={translate("endOn")}
            component={RenderField}
            flexDirection="row"
            labelStyle={styles.grey_color}
          />
        </Box>

        <Box mt={2}>
          <Field
            name="deliveryNote"
            label={translate("deliveryNote")}
            component={RenderField}
            flexDirection="row"
            labelStyle={styles.grey_color}
          />
        </Box>

        <Box mt={2} display="flex">
          {job.archived ? (
            <Button
              onClick={handleOpenModal}
              fullWidth
              variant="contained"
              className={`${styles.btn_details}`}
            >
              {translate("unarchive")}
            </Button>
          ) : (
            <>
              <Box style={{ flex: 1 }} className="mr_5">
                <HandleReviewModal
                  job={job}
                  refetchJobDetail={fetchJobDetails}
                  afterSaveVisitDetails={afterSaveVisitDetails}
                />
              </Box>
              <IconButton
                onClick={handleClickEdit}
                className={`${styles.icon_btn} mr_5`}
              >
                <img src={editSrc} alt="editSrc" />
              </IconButton>
              <MorePopover
                {...props}
                translate={translate}
                setIsOpenConfirmModal={setIsOpenConfirmModal}
                setIsOpenConfirmDeleteModal={setIsOpenConfirmDeleteModal}
              />
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default withRouter(AboutJob);

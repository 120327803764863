import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Externals
import PropTypes from 'prop-types';
import queryString from 'query-string';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared layouts
import WorkerTrackingDetails from './WorkerTrackingDetails';

// Component styles
import styles from './style';

function ClientTracking(props) {
  const { history, location, t } = props;
  const params = queryString.parse(location.search);
  if (params && params.token && params.userId) {
    return (
      <div>
        <WorkerTrackingDetails token={params.token} userId={params.userId} translate={t} />
      </div>
    );
  }
  history.push('/');

};

ClientTracking.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isLoading: state.global.isLoading,
});

const mapDispatchToProps = () => ({
});

export default compose(
  withTranslation('Tracking'),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(ClientTracking);

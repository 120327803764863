export default {
  requiredEmail: 'Email required',
  required: 'Required',
  chooseUserRole: 'Please select a role for the user.',
  inputJobTitle: 'Please input job title',
  noClientSelected: 'No client selected',
  noPropertySelected: 'No property selected',
  clientNotFound: 'Client was not found',
  propertyNotFound: 'Property was not found',
  setStartTime: 'Please set start time.',
  setEndTime: 'Please set end time.',
  setStartDate: 'Please set start date.',
  setEndDate: 'Please set end date.',
  invalid: 'Invalid value',
  invalidStartDate: 'Invalid start date',
  invalidEndDate: 'Invalid end date',
  invalidStartTime: 'Invalid start time',
  invalidEndTime: 'Invalid end time',
  invalidEmail: 'Invalid Email',
  invalidDiscount: 'Invalid discount',
  invalidDeposit: 'Invalid deposit',
  endTimeAfterStartTime: 'End time must be after start time',
  endDateAfterStartDate: 'Can not set end date before start date',
  invoiceSubject: 'Please enter the subject for the invoice.',
  setItemName: 'Set item name',
  positiveQuantity: 'Quantity must be larger than zero',
  positiveUnitCost: 'Unit cost must be larger than zero',
  positiveTotal: 'Total must be larger than zero',
  productName: 'Set product name',
  firstNameRequired: 'First name required',
  lastNameRequired: 'Last name required',
  emailRequired: 'Email required',
  passwordRequired: 'Password required',
  confirmPasswordRequired: 'Password confirmation required',
  countryPhoneCodeRequired: 'Country code required',
  companyRequired: 'Company or organization name required',
  messageRequired: 'Message required',
  mobileNumberRequired: 'Mobile number required',
  phoneNumberRequired: 'Mobile number required',
  addressRequired: 'Address required',
  cityRequired: 'City required',
  roleRequired: 'Role required',
  countryCodeRequired: 'Country required',
  zipCodeRequired: 'Postal code required',
  hintPassword: 'Password must be 8 characters and contain at least one letter, one number and no space.',
  organisationNameRequired: 'Organisation\'s name required.',
  organisationEmailRequired: 'Organisation\'s contact email required',
  organisationMobileNumberRequired: 'Organisation\'s contact mobile number required',
  passwordConfirmationNotMatch: 'The password confirmation was not match with password',
  visitTitleRequired: 'Please input the visit title.',
  taxNameRequired: 'Tax name is required',
  taxNumberRequired: 'Tax number is required',
  // Quote
  inputQuoteTitle: 'Please input quote title',
  selectClient: 'Please select or create client',
  taxRateNameRequired: 'Tax Rate Name is required',
  taxComponentNameRequired: 'Tax Component Title is required',
  taxRatePercentageRequired: 'Tax Rate Percentage is required',
  workingStartTimeRequired: 'Working start time is required',
  workingEndTimeRequired: 'Working end time is required',
  timeWrongFormat: 'The time format is wrong',
  taxRateIdsRequired: 'Tax Component must include at least one tax rate'
};
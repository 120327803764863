import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import styles from './styles.module.css';
import './stripe.css';

import CardSection from './CardSection';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PATCH } from 'lib/api';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';

function InjectedPaymentForm(props) {
  const { currentUser, accessToken, getPayment, setLoading, closePaymentMethodDialog,
    closeSubscriptionPlanModal, translate } = props;
  async function handleSubmit(event) {
    event.preventDefault();
    const cardElement = props.elements.getElement('card');
    setLoading(true);

    // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
    const stripeRes = await props.stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: currentUser.email,
        phone: currentUser.countryPhoneCode + currentUser.phoneNumber,
        name: currentUser.firstName + currentUser.lastName,
      },
    });
    const { paymentMethod, error } = stripeRes;
    if (error) {
      showErrorMessage(error);
      setLoading(false);
      return;
    }
    console.log('Received Stripe PaymentMethod:', paymentMethod);
    const response = await PATCH('/subscription-payment', accessToken, { data: {
      stripePaymentMethodId: paymentMethod.id
    } });
    console.log(response);
    await getPayment();
    showSuccessMessage(translate('paymentMethodSaved'));
    closePaymentMethodDialog();
    closeSubscriptionPlanModal();
    setLoading(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <form onSubmit={handleSubmit}>
          <CardSection />
          <button type="submit" className={styles.button}>Save Card</button>
        </form>
      </div>
    </div>
  );
}

const mapState = (state) => ({
  currentUser: state.auth.currentUser,
  accessToken: state.auth.accessToken
});

export default compose(
  injectStripe,
  connect(mapState, () => ({})),
)(InjectedPaymentForm);

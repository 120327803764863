import React from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Custom components
import { RequestsToolbar, RequestsTable } from './components';

// Component styles
import styles from './style';

const RequestList = (props) => {
  const { classes, translate } = props;

  return (
    <div className={classes.root}>
      <RequestsToolbar listPage translate={translate} />
      <div className={classes.content}>
        <RequestsTable translate={translate} />
      </div>
    </div>
  );
};

RequestList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RequestList);

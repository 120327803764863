import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, Field, formValueSelector } from 'redux-form';

// Material components
import {
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';

// Shared components
import { TextInput } from 'components/inputs';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter,
} from 'pages/Dashboard/components';
import { ProductInputsForm } from '../forms';

// Component styles
import { ServiceProductCardStyle } from './styles';
import { formatPrice } from 'lib/formatter';

const invoiceForm = formValueSelector('invoiceForm');

const mapStateToProps = state => ({
  currencyCode: state.auth.currentUser.companies[0].currency,
  totalPrice: invoiceForm(state, 'total'),
  subtotal: invoiceForm(state, 'subtotal'),
  taxAmount: invoiceForm(state, 'tax'),
  discountAmount: invoiceForm(state, 'discount'),
  withholdingTax23: invoiceForm(state, 'withholdingTax23'),
  subTotalIncludeTax: invoiceForm(state, 'subTotalIncludeTax'),
  totalAfterTax23: invoiceForm(state, 'totalAfterTax23'),
  discountTypeOptions: [
    {
      name: '%',
      value: '%'
    },
    {
      name: state.auth.currentUser.companies[0].currency,
      value: state.auth.currentUser.companies[0].currency
    }
  ]
});

function ServiceProductCardForIndo(props) {
  const { classes, currencyCode, subTotalIncludeTax, totalAfterTax23,
    discountAmount, withholdingTax23,
    discountTypeOptions, translate } = props;

  return (
    <Portlet>
      <PortletHeader className={classes.cardHeader}>
        <PortletLabel title={translate('lineItems')} />
      </PortletHeader>
      <PortletHeader className={classes.secondaryHeader}>
        <Grid container direction='row' spacing={2}>
          <Grid item md={4} sm={12}>
            <PortletLabel title={translate('serviceProduct')} />
          </Grid>
          <Grid item md={2} sm={12}>
            <PortletLabel title={translate('qty')} />
          </Grid>
          <Grid item md={3} sm={12}>
            <PortletLabel title={translate('unitPrice')} />
          </Grid>
          <Grid item md={3} sm={12}>
            <PortletLabel title={translate('totalIncludeTax')} />
          </Grid>
        </Grid>
      </PortletHeader>
      <PortletContent showOverflow>
        <FieldArray
          name='items'
          component={ProductInputsForm}
          currencyCode={currencyCode}
          translate={translate}
        />

      </PortletContent>
      <PortletFooter className={classes.cardFooter}>
        <Grid container direction='row' spacing={2}>
          <Grid item container md={6} sm={6}>
            <Field
              name='clientMessage'
              component={TextInput}
              margin="none"
              variant="outlined"
              label={translate('clientMessage')}
              className={classes.textField}
              multiline
              rows="5"
            />
          </Grid>

          <Grid item container md={6} sm={6}>

            <Grid container direction='row' className={classes.alignItems}>
              <Grid item md={9} sm={9}>
                <PortletLabel title={translate('subTotalIncludeTax')} />
              </Grid>
              <Grid item md={3} sm={3} className={classes.totalPrice}>
                <Typography>
                  {formatPrice(subTotalIncludeTax, currencyCode)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction='row' className={classes.alignItems}>
              <Grid item md={4} sm={6}>
                <PortletLabel title={translate('discount')} />
              </Grid>

              <Grid item md={3} sm={6}>
                <Field
                  name='discountValue'
                  component={TextInput}
                  margin="none"
                  variant="outlined"
                  className={classes.textField}
                  type="number"
                  inputProps={{
                    min: 0,
                    step: '.01'
                  }}
                />
              </Grid>
              <Grid item md={2} sm={6}>

                <Field
                  name='discountType'
                  component={TextInput}
                  select
                  SelectProps={{ native: true }}
                  margin="none"
                  variant="outlined"
                  className={classes.selectField}
                >
                  {discountTypeOptions &&
                    discountTypeOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                </Field>
              </Grid>
              <Grid item md={3} sm={6} className={classes.totalPrice}>
                <Typography>
                  {formatPrice(discountAmount, currencyCode)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction='row' className={classes.alignItems}>
              <Grid item md={9} sm={6}>
                <PortletLabel title={translate('taxArtical23')} />
              </Grid>
              <Grid item md={3} sm={6} className={classes.totalPrice}>
                <Typography className={classes.totalPriceText}>
                  {formatPrice(withholdingTax23, currencyCode)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction='row' className={classes.alignItems}>
              <Grid item md={9} sm={6}>
                <PortletLabel title={translate('totalDueAfterTax')} />
              </Grid>
              <Grid item md={3} sm={6} className={classes.totalPrice}>
                <Typography className={classes.totalPriceText}>
                  {formatPrice(totalAfterTax23, currencyCode)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </PortletFooter>
    </Portlet>
  );
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(ServiceProductCardStyle)
)(ServiceProductCardForIndo);

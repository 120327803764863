import React from 'react';
// Redux
// Material component
import { Grid, Avatar } from '@material-ui/core';
import StaffDefaultAvatar from 'assets/images/staff_default_avatar.svg';

const AccountDetails = props => {
  const { translate, handleSubmit, styles, accountInfo } = props;
  return (
    <div className={styles.section}>
      <div className="language-form-wrap">
        <form className='form-body' onSubmit={handleSubmit}>
          <Grid item container spacing={1} direction='row' justify='space-between'>
            <Grid item xs={4} sm={2} md={2}>
              <Avatar className={styles.avatar} src={(accountInfo || {}).avatar || StaffDefaultAvatar} />
            </Grid>
            <Grid container spacing={2} item xs={8} sm={10} md={10}>
              <Grid item xs={4} sm={4} md={4}>
                <div>
                  {translate('AccountSetting:role')}
                </div>
                <div className={styles.fieldValue}>
                  {accountInfo.roleName}
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <div>
                  {translate('AccountSetting:firstName')}
                </div>
                <div className={styles.fieldValue}>
                  {accountInfo.firstName}
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <div>
                  {translate('AccountSetting:lastName')}
                </div>
                <div className={styles.fieldValue}>
                  {accountInfo.lastName}
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <div>
                  {translate('AccountSetting:contactPhoneNumber')}
                </div>
                <div className={styles.fieldValue}>
                  {[accountInfo.countryPhoneCode, accountInfo.phoneNumber].filter(Boolean).join(' ')}
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <div>
                  {translate('AccountSetting:email')}
                </div>
                <div className={styles.fieldValue}>
                  {accountInfo.email}
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <div>
                  {translate('AccountSetting:address')}
                </div>
                <div className={styles.fieldValue}>
                  {accountInfo.address}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default AccountDetails;
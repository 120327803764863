import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box } from '@material-ui/core';
import styles from './serviceReport.module.scss';
import { useDispatch } from 'react-redux';
import { serviceReportActions } from 'redux/enterprise';
import { showErrorMessage } from 'lib/notifier';

import VisitOnFilter from '../visitOn';
import ServiceReportTypeFilter from '../SRType';
import ServiceReportTable from './table';

const GET_FILTER_OPTIONS = 'GET_FILTER_OPTIONS';

const Media = (props) => {
  const { jobPublicId } = props;
  const [typeFilter, setTypeFilter] = useState(['JOB', 'VISIT']);
  const [visitOn, setVisitOn] = useState([]);
  const [selectedVisitIds, setSelectedVisitIds] = useState([]);
  const dispatch = useDispatch();
  const tableRef = useRef();

  useEffect(() => {
    (async () => {
      const data = {
        jobId: jobPublicId,
        action: GET_FILTER_OPTIONS,
      };
      const result = await dispatch(serviceReportActions({ data })).catch(
        (e) => ({
          e,
        })
      );
      if (result.e) return showErrorMessage(result.e);
      const { visitOn: resultVisitOn } = result;

      setVisitOn(resultVisitOn);
      setSelectedVisitIds(resultVisitOn.map(visit => visit.visitId));
    })();
  }, [dispatch, serviceReportActions]);

  useEffect(() => {
    (() => {
      if (
        !Array.isArray(typeFilter) ||
        !typeFilter.length
      )
        return false;

      tableRef.current &&
        tableRef.current.onQueryChange({ typeFilter, selectedVisitIds, page: 0 });
    })();
  }, [tableRef, typeFilter, selectedVisitIds]);

  return (
    <Grid container>
      <Grid item sm={3} md={2} className={styles.filter_border}>
        <Box p={2} className={styles.border_bottom}>
          <ServiceReportTypeFilter
            {...props}
            typeFilter={typeFilter}
            setTypeFilter={setTypeFilter}
          />
        </Box>
        <Box p={2}>
          <VisitOnFilter
            {...props}
            visitOn={visitOn}
            selectedVisitIds={selectedVisitIds}
            setSelectedVisitIds={setSelectedVisitIds}
          />
        </Box>
      </Grid>
      <Grid item sm={9} md={10}>
        <ServiceReportTable {...props} tableRef={tableRef} />
      </Grid>
    </Grid>
  );
};

export default Media;

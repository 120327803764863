import React, { useEffect } from 'react';

import { reduxForm, Field, change } from 'redux-form';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import styles from '../../components.module.scss';

import { showErrorMessage } from 'lib/notifier';

import { getAssignedTeam } from 'redux/job';
import { useTeamStatus } from 'pages/Dashboard/helpers/getTeamStatus';

const FORM_NAME = 'TeamForm';

const RenderTeamTable = ({ input, translate }) => {
  const [getStatus] = useTeamStatus();
  if (!Array.isArray(input.value)) return null;
  return (
    <Box mt={1} display="flex" flexDirection="column">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={styles.header_text}>
              {translate('name')}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate('email')}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate('phone')}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate('address')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {input.value.map((row) => (
            <TableRow>
              <TableCell className={styles.table_content}>
                {row.fullName} {getStatus(row.isDeleted, row.isArchived)}
              </TableCell>
              <TableCell className={styles.table_content}>
                {row.email}
              </TableCell>
              <TableCell className={styles.table_content}>
                {[row.countryPhoneCode, row.phoneNumber]
                  .filter(Boolean)
                  .join('')}
              </TableCell>
              <TableCell className={styles.table_content}>
                {row.address}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const Team = (props) => {
  const { translate, visitDetails: { job } = {} } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!job || !job.publicId) return null;
    (async () => {
      const result = await dispatch(
        getAssignedTeam({ jobId: job.publicId, $limit: 30 })
      ).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
      dispatch(change(FORM_NAME, 'team', result.data));
    })();
  }, [job, dispatch]);

  return (
    <Box p={2}>
      <Typography variant="h3" className={styles.modal_title}>
        {translate('team')}
      </Typography>

      <Field name="team" component={RenderTeamTable} translate={translate} />
    </Box>
  );
};

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(Team);

import React, { useState, useCallback, useEffect } from 'react';

import {
  Grid,
  Box,
  Button,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import SelectMultipleIcon from '@material-ui/icons/DoneAll';
import { FilesUploadInput } from 'components/inputs';
import { MediaDetails } from '../index';
import styles from './styles/styles.module.scss';
import dragIconSrc from 'assets/images/dragfiles.svg';
import MediaFileItem from './MediaFileItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeStatusSelectedItems,
  getMediaItems,
  getMediaSelect,
  setMediaList,
  setMediaSelectMultiple
} from 'redux/media';

export default ({
  mediaInput = [],
  translate,
  uploadMediaFiles,
  fetchMedia,
  setSelectedPhrase,
  selectedPhrase = 0,
  fetchVisits,
  afterUpdateMedia
}) => {
  const dispatch = useDispatch();
  const isSelectedMultiple = useSelector(getMediaSelect);
  const mediaList = useSelector(getMediaItems);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [currentMediaSelected, setCurrentMediaSelected] = useState('');
  const onDrop = useCallback(
    (acceptedFiles) => uploadMediaFiles(acceptedFiles),
    [selectedPhrase]
  );
  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    onDrop,
  });
  const handleClickPhaseIndex = (index) => {
    return () => {
      if (index !== selectedPhrase) {
        setSelectedPhrase(index);
      }
    };
  };

  const changeCheckbox = (mediaId) => {
    dispatch(changeStatusSelectedItems(mediaId));
  };

  const handleClickSelectMultiple = () => {
    dispatch(setMediaSelectMultiple(true));
  };

  const selectAllMediaItems = () => {
    dispatch(setMediaList(mediaInput.map(media => ({ ...media, isSelected: true }))));
  };
  const unselectAllMediaItems = () => {
    dispatch(setMediaList(mediaInput.map(media => ({ ...media, isSelected: false }))));
  };

  useEffect(() => {
    dispatch(setMediaList(mediaInput.map(media => ({ ...media, isSelected: false }))));
  },[JSON.stringify(mediaInput)]);

  const WhiteBackgroundCheckbox = withStyles(() => ({
    root: {
      color: 'red',
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: -1
      }
    },
    checked: {}
  }))(Checkbox);

  return (
    <Box p={2} className={styles.h100}>
      <Grid container direction="row">
        <Grid item xs={8} container>
          {['evaluation', 'afterJob', 'others'].map((phase, index) => (
            <Grid style={{ marginRight: 12 }} key={index}>
              <Button
                variant="contained"
                onClick={handleClickPhaseIndex(index)}
                className={selectedPhrase === index ? styles.selected_btn : styles.unselect_btn}
              >
                {translate(phase)}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          xs={4}
          container
          alignItems="center"
          justify="flex-end"
          direction="row"
        >
          {isSelectedMultiple ?
            <>
              <Button
                style={{
                  color: '#0075FE',
                  marginRight: '12px'
                }}
                onClick={selectAllMediaItems}
              >
                {translate('Enterprise:selectAll')}
              </Button>
              <Button
                style={{
                  color: '#0075FE'
                }}
                onClick={unselectAllMediaItems}
              >
                {translate('Enterprise:deselectAll')}
              </Button>
            </>
            :
            <>
              <Button
                display="flex"
                onClick={handleClickSelectMultiple}
                style={{
                  marginRight: '12px'
                }}
              >
                <Box mr={0.5}>
                  <SelectMultipleIcon />
                </Box>
                {translate('select')}
              </Button>
              <Box>
                <FilesUploadInput onSubmit={uploadMediaFiles} />
              </Box>
            </> }
        </Grid>
      </Grid>
      <Grid container className={styles.container}>
        <Grid {...getRootProps({ className: styles.dropZone })}>
          <input {...getInputProps()} />
          {mediaList && !mediaList.length ? (
            <Grid
              container
              justify="center"
              direction="column"
              className={styles.iconDragContainer}
            >
              <img src={dragIconSrc} alt="drag_file" />
              <p>{translate('dropHere')}</p>
            </Grid>
          ) : (
            <Box display="flex" flexWrap="wrap">
              {mediaList.map((media, index) => (
                <Grid key={index} className={styles.itemImg}>
                  {isSelectedMultiple ?
                    <Grid
                      container
                      item
                      direction="column"
                      justify="center"
                      alignItems="center"
                      style={{
                        height: '100%',
                        backgroundColor: '#F2F2F2',
                        position: 'relative',
                        zIndex: 1,
                      }}
                    >
                      <MediaFileItem media={media} />
                      <Box
                        bottom={10}
                        left={10}
                        style={{ position: 'absolute', zIndex: 2 }}
                      >
                        <WhiteBackgroundCheckbox
                          checked={media.isSelected}
                          style={{
                            color: '#0075FE',
                            padding: '0px'
                          }}
                        />
                      </Box>
                      <Box
                        onClick={() => changeCheckbox(media.id)}
                        style={{
                          position: 'absolute',
                          height: '100%',
                          width: '100%',
                          zIndex: 3,
                          border: media.isSelected ? '3px solid #0075FE' : '',
                        }}
                      />
                    </Grid>
                    :
                    <Grid
                      container
                      item
                      direction="column"
                      justify="center"
                      alignItems="center"
                      onClick={() => {
                        setCurrentMediaSelected(media);
                        setShowDetailsModal(true);
                      }}
                      style={{
                        backgroundColor: '#F2F2F2',
                      }}
                    >
                      <MediaFileItem media={media} />
                    </Grid>}
                </Grid>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
      {showDetailsModal && (
        <MediaDetails
          translate={translate}
          fetchVisits={fetchVisits}
          fetchMedia={fetchMedia}
          open={showDetailsModal}
          changeModalState={setShowDetailsModal}
          currentMediaSelected={currentMediaSelected}
          afterUpdateMedia={afterUpdateMedia}
        />
      )}
    </Box>
  );
};

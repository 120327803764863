import axios from 'axios';
import i18n from 'i18next';
import Cookie from 'js-cookie';
import moment from 'moment-timezone';
import { HEADER_PENDING_PAYMENT } from 'common/constant';
import { showSubscriptionMarmingMessage } from './notifier';
import { clearSubscriptionWarming, setSubscriptionWarming } from 'redux/subscriptionWarming';

const { process: _process } = global || window;
const client = axios.create();
client.defaults.baseURL = _process.env.REACT_APP_BASE_API;
client.defaults.headers.common['x-api-key'] = _process.env.REACT_APP_API_KEY;
client.defaults.headers.common['Accept'] = 'application/json';

// Ensure companyId is set for every api if it is.
client.interceptors.request.use((config) => {
  const { store } = require('services/store');
  const { currentUser } = store.getState().auth;
  // TODO: Will update it later by using current company. Waiting for WM-904
  const companyId = currentUser && currentUser.companies && (currentUser.companies[0] || {}).id;
  if (companyId) {
    config.headers = {
      'company-id': companyId,
      ...config.headers
    };
  }
  return config;

}, (error) => {
  return Promise.reject(error);
});

client.interceptors.response.use(response => {
  const { store } = require('services/store');
  if (response.headers[HEADER_PENDING_PAYMENT]) {
    const closedCallback = () => {
      store.dispatch(setSubscriptionWarming({ closedAt: moment.utc() }));
    };

    const subscriptionWarming = store.getState().subscriptionWarming.data;

    if (!subscriptionWarming) {
      store.dispatch(setSubscriptionWarming({
        message: decodeURIComponent(response.headers[HEADER_PENDING_PAYMENT]),
      }));
      showSubscriptionMarmingMessage({
        message: decodeURIComponent(response.headers[HEADER_PENDING_PAYMENT]),
        autoHideDuration: 100000,
        closedCallback
      });
    } else if (!subscriptionWarming.closedAt ||
      moment(subscriptionWarming.closedAt).add(1, 'hour').isBefore(moment()))
    {
      showSubscriptionMarmingMessage({
        message: decodeURIComponent(response.headers[HEADER_PENDING_PAYMENT]),
        autoHideDuration: 100000,
        closedCallback
      });
    }

  } else {
    store.dispatch(clearSubscriptionWarming());
  }
  return response;
}, (error) => {
  const { status } = error.response || {};
  const { store } = require('services/store');
  if (status === 402) {
    Cookie.set('paymentWarning', error.response.data.message);
    // eslint-disable-next-line global-require
    const { currentUser } = store.getState().auth;
    const userRoleId = ((currentUser && currentUser.companies && currentUser.companies[0]) || {}).roleId;
    if (userRoleId === 'OWNER' || userRoleId === 'ADMIN') {
      if (!window.location.href.includes('/subscription')) {
        window.location.href = '/subscription';
      }
    } else if (!window.location.href.includes('/subscription/pending')) {
      window.location.href = '/subscription/pending';
    }
  } else if (status === 401) {
    const { signOutUser } = require('api/authenticationApi');

    signOutUser();
    if (!window.location.href.includes('/log-in')) {
      window.location.href = '/log-in';
    }
  } else if (status === 505) {
    // 505 is company account is deactivate.
    if (!window.location.href.includes('company/deactivated') && !window.location.href.includes('/log-in')) {
      window.location.href = '/company/deactivated';
    }
  }
  return Promise.reject(error);
});

export function AuthorizedAPI(token) {
  if (token) client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  client.defaults.headers.common['x-locale'] = i18n.language;

  return client;
};

export function GET(url, token, data) {
  const options = {
    method: 'GET',
    url,
    ...data
  };

  return AuthorizedAPI(token)(options);
};

export function POST(url, token, data) {
  const options = {
    method: 'POST',
    url,
    ...data
  };

  return AuthorizedAPI(token)(options);
};

export function PATCH(url, token, data) {
  const options = {
    method: 'PATCH',
    url,
    ...data
  };

  return AuthorizedAPI(token)(options);
};

export function PUT(url, token, data) {
  const options = {
    method: 'PUT',
    url,
    ...data
  };

  return AuthorizedAPI(token)(options);
};

export function DELETE(url, token, data) {
  const options = {
    method: 'DELETE',
    url,
    ...data
  };

  return AuthorizedAPI(token)(options);
};

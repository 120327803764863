import React from 'react';

// Externals
import classNames from 'classnames';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// Material components
import { Box, Chip, withStyles } from '@material-ui/core';

// Component styles
import styles from './styles';
import { CheckCircle } from '@material-ui/icons';
import {
  JOB_STATUS_ENTERPRISE,
  JOB_STATUS_ENTERPRISE_DASHBOARD,
} from 'common/constant';

const mapStateToProps = (state) => ({
  jobStatuses: state.config.configs.jobStatuses,
  invoiceStatuses: state.config.configs.invoiceStatuses,
  quoteStatuses: state.config.configs.quoteStatuses,
  jobExternalStatuses: state.config.configs.jobExternalStatuses,
});

const StatusChip = (props) => {
  const {
    classes,
    jobStatuses,
    invoiceStatuses,
    quoteStatuses,
    jobExternalStatuses,
    isForEnterprise,
    archived,
    className,
    statusFor,
    job = false,
    invoice = false,
    quote = false,
    status,
    customClassName,
    reviewStatus,
    tableChip = false,
  } = props;

  const chipClassName = classNames(
    classes.chip,
    {
      [classes.draft]: !archived && status === 'DRAFT',
      [classes.sent]: !archived && status === 'SENT',
      [classes.paid]: !archived && status === 'PAID',
      [classes.badDebt]: !archived && status === 'BAD_DEBT',
      [classes.lateVisit]: !archived && status === 'LATE_VISIT',
      [classes.unscheduled]: !archived && status === 'UNSCHEDULED',
      [classes.unassigned]:
        (!archived && status === 'UNASSIGNED') ||
        status === JOB_STATUS_ENTERPRISE_DASHBOARD.PENDING,
      [classes.onStandby]: !archived && status === 'ON_STANDBY',
      [classes.onTheWay]:
        (!archived && ['ON_THE_WAY', 'AWAITING_RESPONSE'].includes(status)) ||
        status === JOB_STATUS_ENTERPRISE_DASHBOARD.IN_PROGRESS,
      [classes.arrived]: !archived && status === 'ARRIVED',
      [classes.started]: !archived && ['STARTED', 'APPROVED'].includes(status),
      [classes.completed]:
        (!archived && ['COMPLETED'].includes(status)) ||
        status === JOB_STATUS_ENTERPRISE_DASHBOARD.COMPLETED ||
        status === JOB_STATUS_ENTERPRISE_DASHBOARD.COMPLETED_AND_CLOSED,
      [classes.archived]:
        archived ||
        status === 'ARCHIVED' ||
        status === JOB_STATUS_ENTERPRISE_DASHBOARD.ARCHIVED,
      [classes.accepted]: !archived && status === 'ACCEPTED',
      [classes.awaitingPayment]: !archived && status === 'AWAITING_PAYMENT',
      [classes.awaitingApproval]: !archived && status === 'AWAITING_APPROVAL',
      [classes.converted]: !archived && status === 'CONVERTED',
      [classes.changesRequested]: !archived && status === 'CHANGES_REQUESTED',
      [classes.reviewed]: !archived && reviewStatus === 'REVIEWED',
      [classes.cancelled]:
        (!archived && status === 'CANCELLED') ||
        status === JOB_STATUS_ENTERPRISE_DASHBOARD.CANCELLED ||
        status === JOB_STATUS_ENTERPRISE_DASHBOARD.CANCELLED_AND_CLOSED,
      [classes.aboutJob]: reviewStatus || tableChip,
      className,
    },
    customClassName
  );

  const getStatusNameExternal = (statusId) => {
    return jobExternalStatuses.find((key) => {
      return JOB_STATUS_ENTERPRISE_DASHBOARD[key.id] === statusId;
    });
  };
  const getStatusForEnterprise = (statusId) => {
    const statusName = getStatusNameExternal(statusId);
    if (statusName.id === JOB_STATUS_ENTERPRISE.CANCELLED_AND_CLOSED) {
      return getStatusNameExternal(JOB_STATUS_ENTERPRISE_DASHBOARD.CANCELLED)
        .name;
    }
    if (statusName.id === JOB_STATUS_ENTERPRISE.COMPLETED_AND_CLOSED) {
      return getStatusNameExternal(JOB_STATUS_ENTERPRISE_DASHBOARD.COMPLETED)
        .name;
    }
    return statusName ? statusName.name : statusId;
  };

  function getStatus() {
    let statuses = [];
    if (statusFor === 'job' || job) {
      if (isForEnterprise) {
        return getStatusForEnterprise(status);
      }
      statuses = jobStatuses;
    } else if (invoice) {
      statuses = invoiceStatuses;
    } else if (quote) {
      statuses = quoteStatuses;
    }

    if (archived) {
      return 'Archived';
    }

    const statusName = statuses.find((item) => item.id === status);
    if (statusName) {
      return statusName.name;
    }

    if (status === 'CANCELLED') {
      return 'Cancelled';
    }

    return status;
  }

  return (
    <Chip
      size="small"
      label={
        reviewStatus === 'REVIEWED' ? (
          <Box>
            {getStatus()}
            <CheckCircle className={classes.reviewStyle} />
          </Box>
        ) : (
          getStatus()
        )
      }
      className={chipClassName}
    />
  );
};

export default compose(
  withStyles(styles, { index: 1 }),
  connect(mapStateToProps, null)
)(StatusChip);

// React/Redux component
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

// Styles
import styles from './styles.module.css';
import { Grid } from '@material-ui/core';
// Child component
import { InvoiceSettingForm, QuoteSettingForm } from './components';

import { updateInvoiceSetting, getSettings, updateJobSetting, updateQuoteSetting } from 'redux/workSetting';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';

const WorkSettings = props => {
  const { translate, getSettings,
    updateInvoiceSetting, updateQuoteSetting } = props;

  const [invoiceSetting, setInvoiceSetting] = useState(null);
  // const [jobSetting, setJobSetting] = useState(null);
  const [quoteSetting, setQuoteSetting] = useState(null);

  useEffect(() => {
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchSettings() {
    try {
      const invoiceSettingRes = await getSettings();
      if (invoiceSettingRes.status === 200) {
        const { data: {
          invoiceSetting,
          // jobSetting,
          quoteSetting
         } } = invoiceSettingRes;
        setInvoiceSetting(invoiceSetting);
        // setJobSetting(jobSetting);
        setQuoteSetting(quoteSetting);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  async function handleSaveInvoiceSetting(invoiceSettingValues) {
    invoiceSettingValues = Object.assign(invoiceSetting, invoiceSettingValues);
    let response;
    try {
      response = await updateInvoiceSetting(invoiceSettingValues);
      if (response.status === 200) {
        showSuccessMessage(translate('WorkSetting:updatedInvoiceSettingSuccessfully'));
        setInvoiceSetting(response.data);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  // async function handleSaveJobSetting(jobSettingValues) {
  //   jobSettingValues = Object.assign(jobSetting, jobSettingValues);
  //   let response;
  //   try {
  //     response = await updateJobSetting(jobSettingValues);
  //     if (response.status === 200) {
  //       showSuccessMessage(translate('WorkSetting:updatedJobSettingSuccessfully'));
  //       setJobSetting(response.data);
  //     }
  //   } catch (error) {
  //     showErrorMessage(error);
  //   }
  // }

  async function handleSaveQuoteSetting(quoteSettingValues) {
    quoteSettingValues = Object.assign(quoteSetting, quoteSettingValues);
    let response;
    try {
      response = await updateQuoteSetting(quoteSettingValues);
      if (response.status === 200) {
        showSuccessMessage(translate('WorkSetting:updatedQuoteSettingSuccessfully'));
        setQuoteSetting(response.data);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  return (
    <div className={styles.workSetting}>
      <Grid container justify='center'>
        <Grid item xl={10} lg={10} md={12} xs={12}>

          <InvoiceSettingForm
            translate={translate}
            styles={styles}
            onSubmit={handleSaveInvoiceSetting}
            invoiceSetting={invoiceSetting}
          />

          {/* <JobSettingForm
            translate={translate}
            styles={styles}
            onSubmit={handleSaveJobSetting}
            jobSetting={jobSetting}
          /> */}

          <QuoteSettingForm
            translate={translate}
            styles={styles}
            onSubmit={handleSaveQuoteSetting}
            quoteSetting={quoteSetting}
          />


        </Grid>
      </Grid>
    </div>
  );
};

const mapDisPatchToProps = dispatch => ({
  getSettings: () => dispatch(getSettings()),
  updateInvoiceSetting: (data) => dispatch(updateInvoiceSetting(data)),
  updateJobSetting: (data) => dispatch(updateJobSetting(data)),
  updateQuoteSetting: (data) => dispatch(updateQuoteSetting(data)),
});

export default compose(
  connect(null, mapDisPatchToProps),
  withRouter,
)(WorkSettings);

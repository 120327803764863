export default (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  formField: {
    margin: theme.spacing(3),
  },
  textField: {
    width: '360px',
    maxWidth: '100%',
    marginRight: theme.spacing(3),
  },
  fullTextField: {
    width: 480,
    maxWidth: '100%',
    marginRight: theme.spacing(3),
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  role: {
    height: '100%',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'left',
    '&:not(:first-of-type)': {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.muted,
  },
  selectedRole: {
    backgroundColor: theme.palette.background.paper,
  },
  roleDetails: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
  roleTitle: {},
  roleDescription: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  roleSelectBtn: {
    marginTop: 'auto',
  },
  profile: {
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  avatar: {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '110px',
    width: '110px',
    flexShrink: 0,
    flexGrow: 0,
  },
  avatarIcon: {
    fontSize: 32,
  },
  countryPhoneInput: {
    display: 'flex',
    flexDirection: 'row',
  },
  fileInput: {
    display: 'none',
  },
  uploadButton: {
    marginTop: 15,
  },
  genderOption: {
    minHeight: 114,
  },
  locationSuggestion: {
    zIndex: 10,
    borderRadius: 4,
    border: '1px solid #E2E2E2',
    boxShadow: '0 10px 30px 0 rgb(82 63 105 / 8%)',
    position: 'absolute',
    background: 'white',
  },
  addressContainer: {
    position: 'relative',
  },
  itemAddress: {
    cursor: 'pointer',
    '&:hover': {
      background: '#e2e2e2',
    },
  },
  poweredBy: {
    color: '#A2A2A2',
  },
  searchResults: {
    maxHeight: 200,
    overflowY: 'auto',
  },
});

import React from 'react';

// Material components

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'pages/Dashboard/components';
import InternalNotesForm from '../forms/InternalNotesForm';

// Component styles
import { InternalNotesCardStyle } from './styles';

function InternalNotesCard(props) {
  const { classes, translate } = props;

  return (
    <Portlet>
      <PortletHeader className={classes.cardHeader}>
        <PortletLabel title={translate('internalNotes')} />
      </PortletHeader>
      <PortletContent>
        <InternalNotesForm translate={translate} />
      </PortletContent>
    </Portlet>
  );
};

export default withStyles(InternalNotesCardStyle)(InternalNotesCard);
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Card, Box, Grid, Typography } from '@material-ui/core';
import { reduxForm, Field, Fields } from 'redux-form';

import styles from '../styles.module.scss';
import Countries from 'lib/countryList';
import { SelectBox, TextInput } from 'components/inputs';
import AddressInput from './renderAddressInput';
import GoogleMapReact from 'google-map-react';

const FORM_NAME = 'warehouseManagement';
const countryCodeOptions = new Countries().countryCodes();

const countryList = new Countries();

const propertyMarker = require('assets/images/marker/map-marker.svg');

const __process = (typeof global !== 'undefined' ? global : window).process;

const getSearchOptions = ({ auth = {} }) => {
  const { currentUser } = auth;
  let language = 'en';
  let placeSearchOptions = {
    language,
  };
  // pick first company in list for country code
  const companies = (currentUser && currentUser.companies) || [];
  if (
    Array.isArray(companies) &&
    companies.length > 0 &&
    companies[0].countryCode
  ) {
    const { countryCode } = companies[0];
    // get language from user first, if not have get from countrycode
    language =
      currentUser && currentUser.language
        ? currentUser.language
        : countryList.getLanguageCodeByCountryCode(countryCode);
    placeSearchOptions = {
      ...placeSearchOptions,
      language,
      components: `country:${countryCode}`,
    };
  }
  return placeSearchOptions;
};

const renderTextInput = (props) => {
  const { title, noBorder, required, translate } = props;
  return (
    <Grid
      container
      className={
        noBorder ? styles.field_container_no_order : styles.field_container
      }
    >
      <Grid item md={3} sm={3} container alignItems="center">
        <Typography className={styles.label}>{title}</Typography>
        {required && (
          <Typography className={styles.required}>
            {translate('Common:*')}
          </Typography>
        )}
      </Grid>
      <Grid item md={9} sm={9} className="pl_5">
        <TextInput {...props} variant="outlined" margin="none" />
      </Grid>
    </Grid>
  );
};

const renderMap = (props) => {
  const { lat, lng, title } = props;

  if (!lat.input.value || !lng.input.value) return null;
  return (
    <Grid container className={styles.field_container}>
      <Grid item md={12} sm={12}>
        <Typography className={styles.label}>{title}</Typography>
      </Grid>
      <Grid item container md={12} sm={12} className={styles.map_wrapper}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: __process.env.REACT_APP_GG_MAP_API_KEY,
            language: 'en',
          }}
          yesIWantToUseGoogleMapApiInternals
          center={{
            lat: parseFloat(lat.input.value),
            lng: parseFloat(lng.input.value),
          }}
          defaultZoom={16}
        >
          <img
            src={propertyMarker}
            alt="propertyMarker"
            lat={parseFloat(lat.input.value)}
            lng={parseFloat(lng.input.value)}
          />
        </GoogleMapReact>
      </Grid>
    </Grid>
  );
};

const renderSelection = (props) => {
  const { title, input, noBorder, required, translate } = props;
  if (!input.value) return null;
  return (
    <Grid
      container
      className={
        noBorder ? styles.field_container_no_order : styles.field_container
      }
    >
      <Grid item md={3} sm={3} container alignItems="center">
        <Typography className={styles.label}>{title}</Typography>
        {required && (
          <Typography className={styles.required}>
            {translate('Common:*')}
          </Typography>
        )}
      </Grid>
      <Grid item md={9} sm={9} className="pl_5">
        <SelectBox {...props} variant="outlined" />
      </Grid>
    </Grid>
  );
};

const Form = (props) => {
  const { translate, handleSubmit, placeSearchOptions } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Card className="mt_10">
        <Box p={3}>
          <Grid container justify="space-between" spacing={3}>
            <Grid item md={6} sm={12}>
              <Field
                translate={translate}
                noBorder
                name="name"
                component={renderTextInput}
                title={translate('name')}
              />

              <AddressInput
                translate={translate}
                placeSearchOptions={placeSearchOptions}
                title={translate('address')}
                FORM_NAME={FORM_NAME}
                required
              />
              <Field
                translate={translate}
                name="unitNumber"
                component={renderTextInput}
                title={translate('unitNumber')}
              />
              <Field
                translate={translate}
                name="city"
                component={renderTextInput}
                title={translate('city')}
                required
              />
              <Field
                translate={translate}
                name="zipCode"
                component={renderTextInput}
                title={translate('postalCode')}
                required
              />
              <Field
                name="state"
                component={renderTextInput}
                title={translate('state')}
                translate={translate}
              />
              <Field
                translate={translate}
                name="countryCode"
                component={renderSelection}
                title={translate('country')}
                options={countryCodeOptions}
                margin="none"
                required
              />
              <Field
                name="lat"
                component={renderTextInput}
                translate={translate}
                title={translate('lat')}
                required
              />
              <Field
                name="lng"
                component={renderTextInput}
                translate={translate}
                title={translate('lng')}
                required
              />

              <Fields
                names={['lng', 'lat']}
                component={renderMap}
                translate={translate}
                title={translate('map')}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Field
                translate={translate}
                noBorder
                name="note"
                component={renderTextInput}
                title={translate('note')}
              />
              <Field
                translate={translate}
                name="description"
                component={renderTextInput}
                title={translate('description')}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const warehouseDetails = ownProps.warehouseDetails || {};
  return {
    initialValues: {
      name: warehouseDetails.name,
      address: warehouseDetails.address,
      unitNumber: warehouseDetails.unitNumber,
      city: warehouseDetails.city,
      zipCode: warehouseDetails.zipCode,
      state: warehouseDetails.city,
      countryCode: warehouseDetails.countryCode || 'MY',
      lat: warehouseDetails.lat,
      lng: warehouseDetails.lng,
      note: warehouseDetails.note,
      description: warehouseDetails.description,
    },
    placeSearchOptions: getSearchOptions(state),
  };
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })
)(Form);

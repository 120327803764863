import React, { useEffect, useState } from 'react';
import QuoteDetailForm from '../forms/QuoteDetailForm';
import { Grid, withStyles } from '@material-ui/core';
import { clearQuote, addQuote, emailQuote } from 'redux/quote';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import styles from './styles';
import { connect } from 'react-redux';
import { getProducts } from 'redux/product';
import { showInfoMessage, showErrorMessage } from 'lib/notifier';
import checkError from 'pages/Dashboard/views/Quotes/validation/checkError';
import { QuoteToolbar } from 'pages/Dashboard/views/Quotes/components/utils';

const mapDispatch= (dispatch, ownProps) => ({
  clearQuote: () => dispatch(clearQuote()),
  addQuote: quoteData => dispatch(addQuote(quoteData)),
  getProducts: (type) => { dispatch(getProducts(type)); },
  emailQuote: (quoteId, email) => {
    showInfoMessage(ownProps.translate('Common:sendingEmail'));
    dispatch(emailQuote(quoteId, email));
  },

});

function NewQuote(props) {
  const { translate, classes, getProducts } = props;

  const [submitAction, setSubmitAction] = useState(null);
  const [submitActionValue, setSubmitActionValue] = useState(null);

  useEffect(() => {
    getProducts('products');
    getProducts('services');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitQuote(quoteValues) {
    const { addQuote, history, emailQuote } = props;
    addQuote(quoteValues).then(response => {
      const quoteId = response && response.data && response.data.id;
      if (response.status === 200) {
        switch(submitAction) {
          case 'convertToJob':
            setSubmitAction();
            history.push(`/jobs/new/${quoteId}`);
            break;
          case 'email':
            emailQuote(quoteId, submitActionValue.email);
            setSubmitAction();
            history.push(`/quotes/${quoteId}`);
            break;
          default:
            history.push(`/quotes/${quoteId}`);
        }
      } else {
        showErrorMessage(response.data.message);
      }
    }, error => {
      showErrorMessage(error);
    });
  };

  function handleValidateAndSubmit(values) {
    console.log('valuesvalues', values)
    checkError(values, translate);
    submitQuote(values);
  }

  function handleCancel() {
    const { history, clearQuote } = props;
    clearQuote();
    history.goBack();
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify='center'>
        <Grid xs={12} item>
          <QuoteToolbar translate={translate} />
        </Grid>
        <Grid xs={12} item>
          <QuoteDetailForm
            onSubmit={handleValidateAndSubmit}
            translate={translate}
            setSubmitAction={setSubmitAction}
            setSubmitActionValue={setSubmitActionValue}
            handleCancel={handleCancel}
          />
        </Grid>
      </Grid>
    </div>
  );
}

NewQuote.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(null, mapDispatch)
)(NewQuote);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import CompanyDetails from '../pages/CompanyDetails';
import { withTranslation } from 'react-i18next';
import { getConfigs, clearConfigs } from '../redux/config';
import { getIsEnterpriseUser } from 'redux/auth';
import i18n from 'i18next';
import AllRoutes from './AllRoutes';
import { showErrorMessage } from 'lib/notifier';

const mapStateToProps = state => ({
  isSignIn: state.auth.isSignIn,
  companySetupCompleted: state.auth.companySetupCompleted,
  companyPermissions: (state.auth && state.auth.currentCompanyPermissions) || [],
  isEnterpriseUser: state.auth.isEnterpriseUser
});

const mapDispatchToProps = dispatch => ({
  getConfigsDispatch: () => dispatch(getConfigs()),
  clearConfigs: () => dispatch(clearConfigs()),
  getCheckEnterpriseUser: () => dispatch(getIsEnterpriseUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter((props) => {
  const { isSignIn, companySetupCompleted, getConfigsDispatch,
    companyPermissions, isEnterpriseUser, getCheckEnterpriseUser } = props;
  async function fetchConfigs() {
    try {
      const response = await getConfigsDispatch();
      if (response.status === 200 && response.data.language) {
        i18n.changeLanguage(response.data.language);
      }
    } catch (error) {
      showErrorMessage(error);
      clearConfigs();
    }
  }

  useEffect(() => {
    fetchConfigs();
    return () => clearConfigs();
  }, []);

  useEffect(() => {
    if (isSignIn && isEnterpriseUser == null) {
      getCheckEnterpriseUser();
    }
  }, []);

  // Not logged in yet.
  if (!isSignIn) return <PublicRoutes />;

  // Finished company setting up.
  if (companySetupCompleted) return <AllRoutes companyPermissions={companyPermissions} />;

  // Have not completed company setting, ask them to continue to set up.
  const CompanyDetailsComponent = withTranslation('CompanySetting')(CompanyDetails);
  return (
    <Switch>
      <Route exact path='/company-details' component={CompanyDetailsComponent} />
      <Redirect to="/company-details" />
    </Switch>
  );
}));

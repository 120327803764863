import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const propertyMarker = require('assets/images/marker/property-marker.svg');
const pinkMarker = require('assets/images/marker/pink-marker.svg');
const cyanMarker = require('assets/images/marker/cyan-marker.svg');
const darkYellowMarker = require('assets/images/marker/dark-yellow-marker.svg');
const purpleMarker = require('assets/images/marker/purple-marker.svg');
const blueMarker = require('assets/images/marker/blue-marker.svg');

// eslint-disable-next-line no-underscore-dangle
const __process = (typeof global !== 'undefined' ? global : window).process;

const workerIcon = status => {
  let icon;

  switch (status) {
    case 'ON_STANDBY':
      icon = darkYellowMarker;
      break;

    case 'ON_THE_WAY':
      icon = pinkMarker;
      break;

    case 'ARRIVED':
      icon = purpleMarker;
      break;

    case 'STARTED':
      icon = cyanMarker;
      break;

    case 'COMPLETED':
      icon = blueMarker;
      break;

    default:
      icon = pinkMarker;
  }
  return icon;
};

const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place) => {
    bounds.extend(new maps.LatLng(
      parseFloat(place.lat),
      parseFloat(place.lng),
    ));
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};

const fitBounds = (map, maps, places, zoom = 16) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);

  const zoomChangeBoundsListener =
    maps.event.addListenerOnce(map, 'bounds_changed', () => {
      if ( map.getZoom() > zoom ) {
        map.setZoom(zoom); // set zoom to default value
      }
    });

  setTimeout(() => { maps.event.removeListener(zoomChangeBoundsListener); }, 2000);
};

class DirectionMap extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    zoom: 16,
    defaultCenter: [3.16547, 101.610296],
  };

  constructor(props) {
    super(props);
    this.makeMarker = this.makeMarker.bind(this);
  }

  makeMarker(maps, position, icon, title, map) {
    // eslint-disable-next-line no-new
    new maps.Marker({
      position,
      map,
      icon,
      title,
    });
  }

  parseCordinates(codinate) {
    if (!codinate || !codinate.lat || !codinate.lng) return null ;
    return { lat: parseFloat(codinate.lat), lng: parseFloat(codinate.lng) };
  }

  apiIsLoaded(map, maps) {
    const { workerDetails = {}, zoom } = this.props;
    const { userLocation, currentJob: { job: jobDetails } = {} } = workerDetails;
    if (!userLocation) return;

    const currentLocation = this.parseCordinates(userLocation);
    let jobLocation;
    let destinationLocation;

    if (jobDetails) {
      jobLocation = jobDetails && this.parseCordinates(jobDetails.jobLocation);
      destinationLocation = jobDetails && jobDetails.property && this.parseCordinates({
        lat: jobDetails.property.lat,
        lng: jobDetails.property.lng
      });
    }

    if (jobLocation && destinationLocation) {
      const waypoints = [{
        location: currentLocation,
        stopover: false,
      }];

      const directionsService = new maps.DirectionsService();
      const directionsDisplay = new maps.DirectionsRenderer();

      directionsDisplay.setMap(map);
      // Remove default marker from google map with suppressMarkers
      directionsDisplay.setOptions( { suppressMarkers: true } );

      directionsService.route({
        travelMode: 'DRIVING',
        origin: jobLocation,
        destination: destinationLocation,
        waypoints,
      }, (DirectionsResult, DirectionsStatus) => {
        this.updateETA(DirectionsResult);
        if (DirectionsStatus === 'OK') {
          directionsDisplay.setDirections(DirectionsResult);
          const leg = DirectionsResult.routes[ 0 ].legs[ 0 ];
          this.makeMarker(maps, leg.end_location, propertyMarker, 'destination', map );
        }
      });
    }

    // Set icon for worker's current location
    this.makeMarker(
      maps,
      currentLocation,
      workerIcon(workerDetails.nextJob && workerDetails.nextJob.status),
      'Current Position',
      map
    );
    const locations = [jobLocation, currentLocation, destinationLocation].filter(Boolean);
    if (locations.length > 0) {
      fitBounds(map, maps, locations, zoom);
    }
  };

  updateETA(directionsResult) {
    const { handleUpdateETAFromMap } = this.props;
    if (directionsResult && directionsResult.routes[0] &&
      directionsResult.routes[0].legs[0] &&
      directionsResult.routes[0].legs[0].duration &&
      directionsResult.routes[0].legs[0].duration.value) {
      handleUpdateETAFromMap(directionsResult.routes[0].legs[0].duration.value);
    }
  }


  render() {
    const { workerDetails } = this.props;
    const center = workerDetails && workerDetails.userLocation
      ? Object.values(this.parseCordinates(workerDetails.userLocation)) : this.props.defaultCenter;
    return (
      <>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: __process.env.REACT_APP_GG_MAP_API_KEY,
            language: 'en'
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={center}
          defaultZoom={this.props.zoom}
          onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
        />
      </>
    );
  }
}

export default DirectionMap;

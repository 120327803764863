import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Button,
  withStyles,
  DialogContent,
  Tooltip,
  Box,
  Typography,
  Grid
} from '@material-ui/core';
import VisitDetails from '../VisitDetails';
import './index.scss';
import { showErrorMessage } from 'lib/notifier';
import {
  Portlet,
  PortletContent,
  VisitForm,
  StatusChip,
} from 'pages/Dashboard/components';
import moment from 'moment';
import lateIcon from 'assets/images/icons/late.png';
import AddIcon from '@material-ui/icons/Add';

import { NewVisitModal } from '../../../components/modals';
import { getDateTimeFormat } from 'lib/formatter';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { setConfirmReviewButton, setContentModal, setOpenConfirmReviewModal } from 'redux/jobVerification';
import { REVIEW_BUTTON_STATUS, STATUS_CONFIRM_BUTTON, VISIT_STATUS } from '../ConfirmReviewModal/constants';
import { getStatusButtonReview } from '../ConfirmReviewModal/hook';

const styles = (theme) => ({
  root: {},
  cardHeader: {
    backgroundColor: theme.palette.background.muted2,
  },
  assignButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
});

class VisitList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openVisitDetails: false,
      visitDetails: {},
      openVisitForm: false,
      visits: [],
      isOpenNewModal: false,
    };
    this.updateVisitList = this.updateVisitList.bind(this);
    this.handleClickNewVisit = this.handleClickNewVisit.bind(this);
  }

  updateVisitList = async () => {
    const visits = await this.props.fetchVisitList();
    this.setState({ visits });
  };

  componentDidMount = () => {
    this.updateVisitList();
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.needUpdateVisits !== prevProps.needUpdateVisits &&
      this.props.needUpdateVisits
    ) {
      // mark needUpdate to false
      this.updateVisitList();
      this.props.flagUpdateAlready(false);
    }
    return null;
  }

  handleClickRow(event, id) {
    this.handleOpenVisitDetails(id);
  }

  handleOpenVisitDetails = (id) => {
    const { getVisit, handleSelectVisit } = this.props;
    getVisit(id).then(
      (response) => {
        // this.setState({ visitDetails: response.data, openVisitDetails: true });
        handleSelectVisit && handleSelectVisit(response.data);
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  };

  handleOpenVisitForm = (visitDetails = { job: this.props.job }) => {
    this.setState({
      visitDetails,
      openVisitForm: true,
      openVisitDetails: false,
    });
  };

  handleCloseVisitDetails = () => {
    this.setState({ visitDetails: {}, openVisitDetails: false });
  };

  handleCloseVisitForm = () => {
    this.setState({ visitDetails: {}, openVisitForm: false });
  };

  handleCloseAllModal() {
    this.setState({
      visitDetails: {},
      openVisitForm: false,
      openVisitDetails: false,
    });
  }

  handleClickNewVisit() {
    const { verified, startVerifiedAt } = this.props.job;
    const status = getStatusButtonReview({ verified, startVerifiedAt });
    if (status === REVIEW_BUTTON_STATUS.REVIEWED) {
      this.props.setContentReviewJobModal(VISIT_STATUS.CREATE_NEW_VISIT);
      this.props.setConfirmReviewModalStatus(true);
      this.props.setConfirmReviewJobButton(STATUS_CONFIRM_BUTTON.OPEN_CREATE_VISIT);
    } else {
      this.setState({ isOpenNewModal: true });
    }
  }

  render() {
    const { jobStatuses, translate } = this.props;
    const { isOpenNewModal } = this.state;
    const setIsOpenEditModal = (newState) =>
      this.setState({ isOpenNewModal: newState });
    return (
      <>
        {isOpenNewModal && (
          <NewVisitModal
            {...this.props}
            isOpen={this.state.isOpenNewModal}
            setIsOpenEditModal={setIsOpenEditModal}
          />
        )}
        <Box display="flex" px={2} py={1} justifyContent="flex-end">
          <Button
            variant="contained"
            className="save_btn"
            onClick={this.handleClickNewVisit}
          >
            <AddIcon />
            {translate('newVisit')}
          </Button>
        </Box>
        <Portlet>
          <PortletContent noPadding>
            <Paper>
              <Table className="list-visit">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="left">{translate('number')}</TableCell>
                    <TableCell align="left">{translate('title')}</TableCell>
                    <TableCell align="left">
                      {translate('description')}
                    </TableCell>
                    <TableCell align="left">{translate('startAt')}</TableCell>
                    <TableCell align="left">{translate('endAt')}</TableCell>
                    <TableCell align="left">
                      {translate('assignedTo')}
                    </TableCell>
                    <TableCell align="left">{translate('status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.visits &&
                    this.state.visits.map((row, index) => {
                      const teamMember =
                        (Array.isArray(row.team) &&
                          row.team.map((member) => member.fullName)) ||
                        [];
                      return (
                        <TableRow
                          key={index}
                          onClick={(event) =>
                            this.handleClickRow(event, row.id)}
                          className="visit-row"
                        >
                          <TableCell>
                            {row.isLateVisit && (
                              <Tooltip title={translate('Job:lateVisit')}>
                                <img
                                  style={{
                                    minHeight: 24,
                                    minWidth: 24,
                                    marginLeft: 8,
                                  }}
                                  src={lateIcon}
                                  alt="lateIcon"
                                />
                              </Tooltip>
                            )}{' '}
                          </TableCell>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">{row.title}</TableCell>
                          <TableCell align="left">
                            <Tooltip title={row.description}>
                              <Typography>{row.description}</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="left">
                            {row.start
                              ? moment(row.start).format(
                                getDateTimeFormat()
                              )
                              : ''}
                          </TableCell>
                          <TableCell align="left">
                            {row.end
                              ? moment(row.end).format(
                                getDateTimeFormat()
                              )
                              : ''}
                          </TableCell>
                          <TableCell align="left">
                            <Grid container alignItems="center">
                              <Typography>
                                {teamMember.splice(0, 1).join(' ')}
                              </Typography>
                              {teamMember.length > 0 && (
                                <Tooltip title={teamMember.join(', ')}>
                                  <Typography className="team-count">+{teamMember.length}</Typography>
                                </Tooltip>
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <StatusChip
                              job
                              status={row.statusId}
                              archived={row.archived}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Paper>
          </PortletContent>
        </Portlet>

        {/* Modal for visit details */}
        <Modal open={this.state.openVisitDetails} disablePortal>
          <DialogContent className="dialog-visit-details-content">
            <VisitDetails
              canEdit
              visitDetails={this.state.visitDetails}
              jobStatuses={jobStatuses}
              closeVisitDetails={this.handleCloseVisitDetails}
              handleOpenVisitForm={this.handleOpenVisitForm}
              updateVisitList={this.updateVisitList}
              translate={translate}
            />
          </DialogContent>
        </Modal>

        {/* Modal for visit form */}
        <Modal disablePortal open={this.state.openVisitForm}>
          <DialogContent>
            <VisitForm
              visitDetails={this.state.visitDetails}
              afterSavedVisit={this.updateVisitList}
              handleCloseVisitForm={this.handleCloseVisitForm}
              translate={translate}
            />
          </DialogContent>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  setConfirmReviewModalStatus: (status) => dispatch(setOpenConfirmReviewModal(status)),
  setContentReviewJobModal: (contentModal) => dispatch(setContentModal(contentModal)),
  setConfirmReviewJobButton: (status) => dispatch(setConfirmReviewButton(status)),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(VisitList);

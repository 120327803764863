export default theme => ({
  root: {},
  header: {},
  invoiceClientDetail:{},
  invoiceDetail: {
    // marginRight: theme.spacing(4),
    borderRadius: 6,
    backgroundColor: 'white',
    padding: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  invoiceTitle: {
    fontWeight: 600,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
  },
  invoiceHeader: {
    marginBottom: 20,
  },
  border: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 12,
    padding: 16
  },
  title: {
    fontWeight: 600,
    marginBottom: 10,
  },
  clientName: {
    color: '#305BF1',
  },
  titleColor: {
    color: theme.palette.common.muted,
    fontWeight: 500
  },
  emailContainer: {
    marginBottom: 8
  }
});

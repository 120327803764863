export default theme => ({
  productName: {
    marginTop: theme.spacing(1),
  },
  productDesc: {
    margin: 0,
  },
  items_wrapper: {
    borderTop: '1px solid #F0F0F0',
    borderBottom: '1px solid #F0F0F0',
    padding: 21
  },
  card_padding: {
    padding: '8px 21px'
  },
  flex: {
    flex: 1
  },
  total: {
    color: '#A2A2A2',
    marginRight: 5
  },
  service_label: {
    minWidth: 150
  },
  btn_text: {
    color: '#0075FE'
  }
});
import React, { Component } from 'react';
import moment from 'moment';
import {
  // Material components
  Grid,
  Tabs,
  Tab,
  Box,
  // Material helpers
  withStyles,
  Typography,
  Card,
} from '@material-ui/core';
// Custom components
import {
  ItemList,
  VisitList,
  Invoices,
  Notes,
  JobTeam,
  MediaFile,
  ServiceReport,
  ServiceReportModal,
  AboutJob,
  ClientDetails,
  CurrentVisitCard,
  VisitDetailsModal,
  HubDetails,
} from './components';
import { CategoryTags } from 'pages/Dashboard/components';

import { reduxForm, Field } from 'redux-form';

// Redux
import { connect } from 'react-redux';

import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import './index.scss';
import {
  getJob,
  updateJobDetails,
  setIsSaving,
  setCurrentJob,
  setVisitIdToGenerateReport,
  jobActions,
} from 'redux/job';
import { setConfirmReviewButton, setJobReviewed } from 'redux/jobVerification';
import { getVisit, getVisits } from 'redux/visit';
import { getClientDetails } from 'redux/client';
import { initInvoiceFormData } from 'redux/invoice';

import { showErrorMessage } from 'lib/notifier';
import { getAccessibleView } from 'lib/permissions';
import { VIEWS_NAME } from 'common/constant';

import styles from './styles';
import { getDateTimeFormat } from 'lib/formatter';
import { STATUS_CONFIRM_BUTTON } from './components/ConfirmReviewModal/constants';

const mapStatusIdToAction = {
  COMPLETED: 'COMPLETE_JOB',
  CANCELLED: 'CANCEL_JOB',
};

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes && classes.borderNone}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
class JobDetails extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      job: {},
      isSubmittingBtn: false,
      anchorStatusMenuEl: null,
      tabsIndex: 0,
      openVisitModalServiceReport: false,
      needUpdateVisits: false,
      isShowingAccountingFeature: false,
      isShowVisitDetailsModal: false,
      selectedVisit: {},
    };

    this.fetchJobDetails = this.fetchJobDetails.bind(this);
    this.toggleArchiveJob = this.toggleArchiveJob.bind(this);
    this.handleClickStatusDropdown = this.handleClickStatusDropdown.bind(this);
    this.handleCloseStatusDropdownMenu = this.handleCloseStatusDropdownMenu.bind(
      this
    );
    this.fetchJobStatus = this.fetchJobStatus.bind(this);
    this.updateJobStatus = this.updateJobStatus.bind(this);
    this.handleGenerateInvoice = this.handleGenerateInvoice.bind(this);
    this.closeVisitModalServiceReport = this.closeVisitModalServiceReport.bind(
      this
    );
    this.handleGenerateServiceReport = this.handleGenerateServiceReport.bind(
      this
    );
    this.pushToPageGenerateReport = this.pushToPageGenerateReport.bind(this);
    this.flagUpdateAlready = this.flagUpdateAlready.bind(this);
    this.handleCloseVisitPopover = this.handleCloseVisitPopover.bind(this);
    this.setSelectedVisitDetails = this.setSelectedVisitDetails.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchJobDetails();
    this.props.setIsSaving(false);

    // check permission showing accounting feature
    const isShowAccountingFeature = getAccessibleView(VIEWS_NAME.invoices);
    this.setState({ isShowingAccountingFeature: isShowAccountingFeature });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClickStatusDropdown(event) {
    this.setState({ anchorStatusMenuEl: event.currentTarget });
  }

  handleCloseStatusDropdownMenu() {
    this.setState({ anchorStatusMenuEl: null });
  }

  handleCloseVisitPopover() {
    this.setState({ isShowVisitDetailsModal: false });
  }

  async handleGenerateInvoice() {
    const { history, getClientDetails, initInvoiceFormData } = this.props;
    const { job } = this.state;
    const jobIds = [job.publicId];
    let client;
    try {
      const response = await getClientDetails(job.client.id);
      if (response.status === 200) {
        client = response.data;
        const initializeInvoiceRes = await initInvoiceFormData(jobIds, client);
        if (initializeInvoiceRes === 'ok') {
          history.push('/invoices/new');
        }
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  async handleGenerateServiceReport(selectedType = 'visit') {
    if (selectedType === 'visit') {
      return this.setState({ openVisitModalServiceReport: true });
    }
    this.props.setVisitIdToGenerateReportDispatch('');
    this.pushToPageGenerateReport();
  }

  setSelectedVisitDetails(newSelectedVisit) {
    this.setState({ selectedVisit: newSelectedVisit });
  }

  fetchVisitList = async () => {
    // this function being used in visitList
    // careful while delete this function
    const { jobId, getVisitsDispatch } = this.props;
    const result = await getVisitsDispatch({ jobId }).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    return result.data.data || [];
  };

  fetchJobDetails() {
    const {
      jobId,
      getJob,
      setCurrentJobDispatch,
      setJobReviewedDispatch,
      setConfirmReviewButtonDispatch,
    } = this.props;
    return getJob(jobId).then(
      (response) => {
        if (response.status === 200 && this._isMounted) {
          this.setState({
            job: response.data,
          });
          setCurrentJobDispatch(response.data);
          setConfirmReviewButtonDispatch();
          setJobReviewedDispatch(response.data?.verified);
        }
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  }

  toggleArchiveJob(_event) {
    const { jobId, jobActionsDispatch } = this.props;
    const { job } = this.state;
    const action = job.archived ? 'UNARCHIVE_JOB' : 'ARCHIVE_JOB';

    this.setState({ isSubmittingBtn: true });
    jobActionsDispatch(jobId, action).then(
      (response) => {
        this.setState({ isSubmittingBtn: false, job: response });
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  }

  updateJobStatus(statusId) {
    const { jobId, jobActionsDispatch } = this.props;
    const action = mapStatusIdToAction[statusId];

    this.setState({ isSubmittingBtn: true });
    jobActionsDispatch(jobId, action).then(
      () => {
        this.fetchJobDetails();
        this.setState({
          isSubmittingBtn: false,
          anchorStatusMenuEl: null,
        });
        this.flagUpdateAlready(true);
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  }

  fetchJobStatus(statusId) {
    const { jobStatuses } = this.props;
    const jobItem = jobStatuses.find((item) => item.id === statusId);
    return jobItem ? jobItem.name : statusId;
  }

  pushToPageGenerateReport() {
    this.props.history.push(`${this.state.job.publicId}/service-report/new`);
  }

  closeVisitModalServiceReport() {
    this.setState({ openVisitModalServiceReport: false });
  }

  flagUpdateAlready(status) {
    this.setState({ needUpdateVisits: status });
  }

  render() {
    const { classes, history, translate, jobStatuses } = this.props;
    const {
      job,
      tabsIndex,
      openVisitModalServiceReport,
      needUpdateVisits,
      isShowingAccountingFeature,
      isShowVisitDetailsModal,
      selectedVisit,
    } = this.state;
    const handleCreateTeam = () => history.push(`${job.publicId}/edit`);
    const handleChange = (event, newValue) =>
      this.setState({ tabsIndex: newValue });

    const handleSelectVisit = (visitDetails) =>
      this.setState({
        isShowVisitDetailsModal: true,
        selectedVisit: visitDetails,
      });

    const callbackDoAction = () => {
      this.fetchJobDetails();
      this.setState({
        isShowVisitDetailsModal: false,
        selectedVisit: {},
        needUpdateVisits: true,
      });
    };

    const callbackUpdateStatusId = () => {
      this.fetchJobDetails();
      this.setState({ needUpdateVisits: true });
    };
    const renderTabContent = () => {
      const mapIndexToComponent = [
        ItemList,
        MediaFile,
        ServiceReport,
        VisitList,
        JobTeam,
        Notes,
      ];
      // prepare props pass to component
      const mapIndexToProps = [
        {
          customStyle: classes.borderNone,
          jobItems: job.items,
          totals: job.totals,
          currency: job.currency,
          job,
          fetchJobDetails: this.fetchJobDetails,
        },
        {
          fetchVisitList: this.fetchVisitList,
          afterUpdateMedia: callbackDoAction,
        },
        {},
        {
          jobStatuses,
          getVisit: this.props.getVisit,
          fetchVisitList: this.fetchVisitList,
          needUpdateVisits,
          flagUpdateAlready: this.flagUpdateAlready,
          handleSelectVisit,
          job,
          afterSaveVisitDetails: callbackDoAction,
        },
        {
          handleCreateTeam,
          teams: job && job.team,
        },
        {
          notes: job.notes,
        },
      ];

      if (isShowingAccountingFeature) {
        // if allow show invoice, add to array
        mapIndexToComponent.splice(5, 0, Invoices);
        mapIndexToProps.splice(5, 0, {
          currency: job.currency,
          invoiceStatuses: jobStatuses,
          jobId: job.publicId,
        });
      }

      const Comp = mapIndexToComponent[tabsIndex];
      if (!Comp) return null;

      const customProps = {
        ...mapIndexToProps[tabsIndex],
        translate,
        job,
        jobId: job.publicId,
      };

      return (
        <TabPanel classes={classes}>
          <Comp {...customProps} />
        </TabPanel>
      );
    };

    const renderLabel = (label, total) => {
      return (
        <Grid container>
          <Typography>{label}</Typography>
          <Typography className="tab-total">{total || 0}</Typography>
        </Grid>
      );
    };

    const renderMediaLabel = (label, total) => {
      const getPhaseTotal = () => {
        const evaluationPhase = total.find((phase) => phase.media_phase === '0')  || { total: 0 };
        const afterJobPhase = total.find((phase) => phase.media_phase === '1') || { total: 0 };
        const otherPhase = total.find((phase) => phase.media_phase === '2') || { total: 0 };
        
        return `${evaluationPhase.total}/${afterJobPhase.total}/${otherPhase.total}`;
      };
      return (
        <Grid container>
          <Typography>{label}</Typography>
          <Typography className="tab-total">
            {total && total.length > 0 ? getPhaseTotal() : '0/0/0'}
          </Typography>
        </Grid>
      );
    };

    return (
      <div className={classes.root}>
        <VisitDetailsModal
          showJobDetails={isShowVisitDetailsModal}
          handleCloseVisitDetails={this.handleCloseVisitPopover}
          visitDetails={selectedVisit}
          callbackAfterMarkJobComplete={callbackDoAction}
          callbackAfterDeleteVisit={callbackDoAction}
          callbackUpdateStatusId={callbackUpdateStatusId}
          afterSaveVisitDetails={callbackDoAction}
          translate={translate}
          visitId={selectedVisit && selectedVisit.id}
          setSelectedVisitDetails={this.setSelectedVisitDetails}
        />
        <ServiceReportModal
          openState={openVisitModalServiceReport}
          handleCloseModal={this.closeVisitModalServiceReport}
          translate={translate}
          pushToReportPage={this.pushToPageGenerateReport}
          fetchVisitList={this.fetchVisitList}
        />
        <Box display="flex">
          <Typography>{translate('Common:backTo')}</Typography>
          <Link className="ml_5 font_bold" to="/jobs">
            {translate('jobs')}
          </Link>
        </Box>
        {job && Object.keys(job).length > 0 && (
          <Grid container className="mt_10" spacing={1}>
            <Grid item md={9} sm={7}>
              <CurrentVisitCard
                translate={translate}
                job={job}
                updateJobStatus={this.updateJobStatus}
                handleSelectVisit={handleSelectVisit}
              />

              <Card className={classes.card}>
                <Tabs
                  classes={{
                    indicator: classes.indicator,
                    root: classes.bottom_border,
                  }}
                  value={tabsIndex}
                  onChange={handleChange}
                >
                  <Tab label={translate('items')} />
                  <Tab
                    label={renderMediaLabel(
                      translate('mediaFile'),
                      job.totalMedia
                    )}
                  />
                  <Tab
                    label={renderLabel(
                      translate('serviceReport'),
                      job.createdSr
                    )}
                  />
                  <Tab
                    label={renderLabel(translate('visits'), job.totalVisit)}
                  />
                  <Tab
                    label={renderLabel(
                      translate('assignedTeam'),
                      job.team && job.team.length
                    )}
                  />
                  {isShowingAccountingFeature && (
                    <Tab label={translate('billing')} />
                  )}
                  <Tab label={translate('Common:notes')} />
                </Tabs>
                {renderTabContent()}
              </Card>
            </Grid>
            <Grid item md={3} sm={5}>
              <AboutJob
                translate={translate}
                updateJobStatus={this.updateJobStatus}
                handleGenerateInvoice={this.handleGenerateInvoice}
                handleGenerateServiceReport={this.handleGenerateServiceReport}
                job={job}
                afterSaveVisitDetails={callbackDoAction}
                toggleArchiveJob={this.toggleArchiveJob}
                fetchJobDetails={this.fetchJobDetails}
              />

              <Field name="hub" component={HubDetails} translate={translate} />
              <Box mt={1}>
                <Field
                  name="sortCategories"
                  component={CategoryTags}
                  translate={translate}
                />
              </Box>
              <Box mt={1}>
                <ClientDetails jobDetails={job} translate={translate} />
              </Box>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { job } = state.job || {};

  const {
    title,
    publicId,
    property,
    statusId,
    jobSchedule: { start, end } = {},
    client: { displayName, email, phoneNumber } = {},
    hub,
    orderId,
    deliveryNote,
    sortCategories,
    verified,
    startVerifiedAt,
    description,
  } = job || {};
  return {
    job,
    jobStatuses: state.config.configs.jobStatuses,
    initialValues: {
      title,
      publicId,
      statusId,
      startOn: start && moment(start).format(getDateTimeFormat()),
      endOn: end && moment(end).format(getDateTimeFormat()),
      property,
      clientName: displayName,
      clientEmail: email,
      clientPhoneNumber: phoneNumber,
      hub,
      deliveryNote,
      orderId,
      sortCategories,
      verified,
      startVerifiedAt,
      description,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  getJob: (jobId) => dispatch(getJob(jobId)),
  setIsSaving: () => dispatch(setIsSaving()),
  updateJobDetails: (data) => dispatch(updateJobDetails(data)),
  getVisit: (id) => dispatch(getVisit(id)),
  initInvoiceFormData: (jobIds, client) =>
    dispatch(initInvoiceFormData(jobIds, client)),
  getClientDetails: (clientId) => dispatch(getClientDetails(clientId)),
  setCurrentJobDispatch: (jobDetails) => dispatch(setCurrentJob(jobDetails)),
  setVisitIdToGenerateReportDispatch: (id) =>
    dispatch(setVisitIdToGenerateReport(id)),
  getVisitsDispatch: (jobId) => dispatch(getVisits(jobId)),
  jobActionsDispatch: (jobId, action) => dispatch(jobActions(jobId, action)),
  setJobReviewedDispatch: (status) => dispatch(setJobReviewed(status)),
  setConfirmReviewButtonDispatch: () =>
    dispatch(setConfirmReviewButton(STATUS_CONFIRM_BUTTON.OPEN_JOB_ONLY)),
});

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'jobDetails',
    enableReinitialize: true,
  })
)(JobDetails);

import React from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import styles from './components.module.scss';

const LIST_FILE_TYPE = [
  {
    label: 'jobReport',
    key: 'JOB',
  },
  {
    label: 'visitReport',
    key: 'VISIT',
  },
];

const FilterByType = ({ t, typeFilter, setTypeFilter }) => {

  const handleChangeFilter = (value) => {
    if (!typeFilter.includes(value)) return setTypeFilter([...typeFilter, value]);
    const deepCloneType = [...typeFilter];
    deepCloneType.splice(typeFilter.indexOf(value), 1);
    setTypeFilter(deepCloneType);
  };

  return (
    <>
      <Typography>{t('type')}</Typography>

      <Box mt={2}>
        {LIST_FILE_TYPE.map((type, index) => (
          <Grid item key={index} className="mt_8">
            <FormControlLabel
              key={index}
              onChange={() => handleChangeFilter(type.key)}
              control={
                <Checkbox
                  checked={typeFilter.includes(type.key)}
                  name={type.key}
                  color="primary"
                  className={styles.checkBox_style}
                  classes={{
                    checked: styles.checked_state,
                  }}
                />
              }
              label={t(type.label)}
              classes={{
                root: styles.checkbox_root,
                label: styles.checkbox_label,
              }}
            />
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default FilterByType;

import React, { useState } from 'react';

// Redux
import { connect, useDispatch } from 'react-redux';
import { reduxForm, change } from 'redux-form';

// Material helpers
import {
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import styles from '../../styles.module.scss';
import { VisitDetailsForm, VisitTimeStepperFields } from '../index';
import StatusBtn from '../button/StatusBtn';
import { STATUSES, PROGRESS_TIMESTAMP_ADJUSTMENT } from 'common/constant';
import { VisitTimeAdjustmentModal } from 'pages/Dashboard/components';
import moment from 'moment';
import { showErrorMessage } from 'lib/notifier';
import { updateVisitByAction } from 'redux/visit';

import {
  Check as CheckIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons';

import { prepareFormValue } from '../utils';

import trashIcon from 'assets/images/icons/u_trash.svg';
import editIcon from 'assets/images/icons/edit.svg';
import lateIcon from 'assets/images/icons/late.png';
import { useCheckJobReviewed } from 'pages/Dashboard/views/Jobs/JobDetails/components/ConfirmReviewModal/hook';

const STATUES_HIDE_BTN = [STATUSES.COMPLETED, STATUSES.CANCELLED];
const SHOW_EDIT_BTN_ROLES = ['OWNER', 'ADMIN'];

const FORM_NAME = 'visitDetailsForm';

let VisitDetails = (props) => {
  const {
    translate,
    handleOpenEditModal,
    handleClickDeleteVisit,
    handleUpdateStatus,
    visitDetails,
    setIsUpdateTimestamp,
    setSelectedVisitDetails,
    userRole,
    fetchData
  } = props;

  const dispatch = useDispatch();

  const [isOpenEditVisitTimeModal, setIsOpenEditVisitTimeModal] = useState(
    false
  );
  const { handleCheckJobReviewed } = useCheckJobReviewed();
  const onEditProgressTrackerClick = () => handleCheckJobReviewed(() =>setIsOpenEditVisitTimeModal(true));

  const updateFormValue = (data) => {
    const { onTheWayAt, arrivedAt, startedAt, completedAt, cancelledAt, statusId } = data;

    const newData = {
      ...visitDetails,
      onTheWayAt,
      arrivedAt,
      startedAt,
      completedAt,
      cancelledAt,
      statusId,
    };

    setSelectedVisitDetails && setSelectedVisitDetails(newData);
  };

  const handleCheckPrevPhaseTime = (newStatus) =>{
    const { onTheWayAt, arrivedAt, startedAt } = visitDetails;
    const currentTime = moment();
    const prevPhaseTime = startedAt || arrivedAt || onTheWayAt;
    if(currentTime.isAfter(moment(prevPhaseTime))) {
      handleUpdateStatus && handleUpdateStatus(newStatus);
    }
    else{
      showErrorMessage(translate('Job:incorrectPrevTime'));
    }
  };

  const handleSubmit = async (value) => {
    const steps = ['ontheway', 'arrived', 'start', 'completed', 'cancelled'];
    const dateList = steps.map((step) => {
      const visitDate = value[`${step}_visitDate`];
      const visitTime = value[`${step}_visitTime`];
      if (visitDate && visitTime) {
        const fullDatetime =
          moment(visitDate).format('MM/DD/YYYY ') +
          moment(visitTime).format('HH:mm');
        return { key: step, value: fullDatetime };
      }
      return true;
    });
    const check = dateList.find((dateTime)=> moment().isBefore(moment(dateTime.value)));
    const originalList = dateList.filter((item) => {
      return item.key;
    });
    if (!originalList.length)
      return showErrorMessage(translate('Timesheet:noTimeValid'));
    const sortedList = [...originalList].sort(
      (a, b) => moment(a.value) - moment(b.value)
    );
    if (JSON.stringify(originalList) !== JSON.stringify(sortedList) || check) {
      return showErrorMessage(translate('Timesheet:timeNotCorrect'));
    }

    let payload = {
      visitId: visitDetails.id,
      action: PROGRESS_TIMESTAMP_ADJUSTMENT,
    };
    const statusToPayload = {
      ontheway: 'onTheWayAt',
      arrived: 'arrivedAt',
      start: 'startedAt',
      completed: 'completedAt',
      cancelled: 'cancelledAt',
    };
    originalList.forEach((item) => {
      payload = { ...payload, [statusToPayload[item.key]]: moment(item.value) };
    });

    const result = await dispatch(updateVisitByAction(payload)).catch((e) => ({
      e,
    }));

    if (result.e) return showErrorMessage(result.e);

    setIsOpenEditVisitTimeModal(false);
    setIsUpdateTimestamp(true);

    updateFormValue(result.data);
    fetchData();
  };

  const isShowBtn = SHOW_EDIT_BTN_ROLES.includes(userRole);
  return (
    <>
      {isOpenEditVisitTimeModal && (
        <VisitTimeAdjustmentModal
          onSubmit={handleSubmit}
          visitData={visitDetails}
          setShowAddModal={setIsOpenEditVisitTimeModal}
          translate={translate}
          visitDetails={visitDetails}
        />
      )}
      <Box
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" className={styles.header_title}>
          {translate('visitDetails')}
        </Typography>
      </Box>

      <Box className={styles.border_vertical}>
        <Grid container>
          <Box px={2} py={1} className={styles.border_right}>
            {!STATUES_HIDE_BTN.includes(
              visitDetails && visitDetails.statusId
            ) && (
              <>
                <Button
                  onClick={() => handleCheckPrevPhaseTime(STATUSES.COMPLETED)}
                  className={styles.btn_complete}
                  variant="contained"
                >
                  <CheckIcon className="mr_5" />
                  {translate('complete')}
                </Button>
                <Button
                  onClick={() => handleUpdateStatus(STATUSES.CANCELLED)}
                  variant="contained"
                  className={`${styles.btn_cancel} ml_5 mr_5`}
                >
                  <HighlightOffIcon className="mr_5" />
                  {translate('cancel')}
                </Button>
              </>
            )}
            <Tooltip title={translate('edit')}>
              <IconButton
                onClick={handleOpenEditModal}
                className={styles.icon_btn}
              >
                <img src={editIcon} alt="editIcon" />
              </IconButton>
            </Tooltip>
            <Tooltip title={translate('delete')}>
              <IconButton
                onClick={handleClickDeleteVisit}
                className={styles.icon_btn}
              >
                <img src={trashIcon} alt="trashIcon" />
              </IconButton>
            </Tooltip>
          </Box>

          <Box px={2} py={1} display="flex" flexWrap="noWrap">
            {visitDetails && visitDetails.isLateVisit && (
              <Grid container alignItems="center" className="mr_5">
                <Tooltip title={translate('Job:lateVisit')}>
                  <img
                    style={{ minHeight: 24, minWidth: 24 }}
                    src={lateIcon}
                    alt="lateIcon"
                  />
                </Tooltip>
                <Typography className={styles.late_visit_text}>{translate('Job:lateVisit')}</Typography>
              </Grid>
            )}
            <Typography>
              <StatusBtn
                {...props}
                translate={translate}
                handleUpdateStatus={handleUpdateStatus}
              />
            </Typography>
          </Box>
        </Grid>
      </Box>

      <Grid
        container
        style={{ height: 'calc(100% - 114px)', overflowY: 'auto' }}
      >
        <Grid item md={8} sm={8} className={styles.border_right}>
          <Box p={2}>
            <VisitDetailsForm {...props} translate={translate} />
          </Box>
        </Grid>
        <Grid item sm={4} md={4}>
          <Box p={2}>
            <Typography className={styles.tracker_title}>
              {translate('progressTracker')}
            </Typography>
            {isShowBtn && (
              <Button
                onClick={onEditProgressTrackerClick}
                variant="contained"
                className="mt_10 mb_10"
                style={{
                  background: '#F0F0F0',
                  borderRadius: 4,
                  boxShadow: 'unset',
                }}
              >
                <img src={editIcon} alt="edit_icon" className="mr_5" />
                <Typography>{translate('Common:edit')}</Typography>
              </Button>
            )}

            <Grid className="mt_5">
              <VisitTimeStepperFields translate={translate} />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { translate } = ownProps;
  const visitDetails = ownProps.visitDetails || {};
  const userRole = (state.auth.currentUser.companies[0] || {}).roleId;

  const data = prepareFormValue(visitDetails, translate);
  return {
    initialValues: {
      ...data,
    },
    userRole,
  };
};

VisitDetails = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(VisitDetails);

export default connect(mapStateToProps, null)(VisitDetails);

export default (theme) => ({
  root: {
    height: '60vh',
    minHeight: 500,
    width: '70vw',
    maxWidth: 600,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  noneAssignText: {
    fontWeight: 400,
    marginBottom: 30,
  },
  inputSearch: {
    width: '90%',
  },
  contentWrapper: {
    overflowY: 'hidden',
  },
  inputSearchBox: {
    background: 'rgba(0,0,0,.05)',
    borderRadius: 4,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    marginBottom: 16,
  },
  selectItemsWrapper: {
    height: 'calc(100% - 46px)',
    width: '100%',
    overflowY: 'auto',
    display: 'block'
  },
  submitBtn: {
    background: '#479ECF',
    marginLeft: 8,
    boxShadow: 'unset',
    '&:hover': {
      background: '#479ECF',
    },
  },
  checkboxState: {
    color: '#479ECF !important',
  },
  topSpacing: {
    marginTop: 8
  },
  noPadding: {
    paddingTop: 0
  },
  title: {
    color: '#666666'
  }
});

export default (theme) => ({
  root: {
    height: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.background.muted2,
  },
  assignButton: {
    color: '#0075FE',
    borderColor: theme.palette.primary.main,
  },
  teamChip: {
    margin: 2,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.muted2,
  },
  noneAssignText: {
    fontWeight: 400,
  },
});

import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Field, FieldArray } from 'redux-form';

// import component
import styles from './styles.module.scss';

const JobImages = ({ translate, evaluation, afterJob }) => {
  if (
    (!evaluation || evaluation.length === 0) &&
    (!afterJob || afterJob.length === 0)
  )
    return null;
  const renderJobImages = ({ fields }) => {
    const renderImage = ({ input }) => {
      return (
        <Grid className={styles.imageWrapper}>
          <img
            className={styles.imageStyle}
            src={input.value}
            alt="media_image"
          />
        </Grid>
      );
    };

    const renderCaption = ({ input }) => {
      return (
        <Typography className={styles.caption}>
          {input.value}
        </Typography>
      );
    };

    return fields.map((field, index) => (
      <Grid key={index} className={styles.imageContainer}>
        <Field name={`${field}.mediaUrl`} component={renderImage} />
        <Field
          name={`${field}.caption`}
          component={renderCaption}
        />
      </Grid>
    ));
  };

  return (
    <Grid className={`mt_20 ${styles.jobImageContainer}`}>
      <Typography variant="h5" className={`${styles.boldText} page-break-before `}>
        {translate('jobImages')}
      </Typography>
      {(evaluation || evaluation.length === 0) && (
        <>
          <Grid item container className="mt_15">
            <Typography variant="h3">{translate('evaluation')}</Typography>
          </Grid>
          <Grid item className="page-break-inside">
            <FieldArray
              name="evaluation"
              component={renderJobImages}
              translate={translate}
            />
          </Grid>
        </>
      )}

      {(afterJob || afterJob.length === 0) && (
        <Grid className="page-break-inside">
          <Grid item container className="mt_15">
            <Typography variant="h3">{translate('afterJob')}</Typography>
          </Grid>
          <Grid item>
            <FieldArray
              name="afterJob"
              component={renderJobImages}
              translate={translate}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default JobImages;

import React, { useEffect } from 'react';

// Externals
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Redux
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { initialize, SubmissionError } from 'redux-form';
import { clearRequest } from 'redux/request';
import {
  getEnterpriseRequestDetails,
  updateEnterpriseRequestDetails,
} from 'redux/enterprise';

// Material components
import { Grid, withStyles } from '@material-ui/core';

// Custom components
import {
  RequestValidation,
  modifyRequestDataBeforeSubmission,
  initializeRequestData,
} from '../components/utils';
import { RequestForm } from '../components/forms';
import { showErrorMessage } from 'lib/notifier';
// Component styles
import styles from './style';

const mapStateToProps = (state) => ({
  clientName:
    state.request.request &&
    state.request.request.selectedClient &&
    state.request.request.selectedClient.name,
});

const UpdateRequest = (props) => {
  const {
    classes,
    history,
    match: {
      params: { requestId },
    },
    translate,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    // fetchRequestDetails onMount
    dispatch(getEnterpriseRequestDetails(requestId)).then((rs) => {
      dispatch(initialize('requestForm', initializeRequestData(rs.data)));
    });
  }, []);

  function handleCancelRequestForm() {
    dispatch(clearRequest());
    history.push('/enterprise-requests');
  }

  function submitRequestForm(values) {
    const errors = RequestValidation(values);
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message)
          .map((msg) => translate(`Error:${msg}`))
          .join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors)
          .map((msg) => translate(`Error:${msg}`))
          .join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));

      throw new SubmissionError(errors);
    } else {
      values = modifyRequestDataBeforeSubmission(values);
      dispatch(updateEnterpriseRequestDetails(values)).then(
        (response) => {
          dispatch(clearRequest());
          history.push(`/enterprise-requests/${requestId}`);
        },
        (error) => {
          showErrorMessage(error);
        }
      );
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify="center">
        {/* <Grid
          item
          md={12}
          lg={12}
          xl={10}
          xs={12}
        >
          <RequestsToolbar clientName={clientName} match={props.match} translate={translate} />
        </Grid> */}
        <Grid item md={12} lg={12} xl={10} xs={12}>
          <RequestForm
            handleCancelRequestForm={handleCancelRequestForm}
            isEditForm
            onSubmit={(values) => submitRequestForm(values)}
            translate={translate}
          />
        </Grid>
      </Grid>
    </div>
  );
};

UpdateRequest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, null)
)(UpdateRequest);

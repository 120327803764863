import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import styles from './announcement.module.scss';

import documentSrc from 'assets/images/dashboard/document.svg';

const AnnouncementsItem = ({ item = {} }) => (
  <Grid container spacing={1}>
    <Grid item sm={3} md={2}>
      <img src={documentSrc} alt="documentSrc" />
    </Grid>
    <Grid item sm={9} md={10}>
      <Typography variant="h5">{item.title}</Typography>
      <Typography className={styles.contentBody}>{item.content}</Typography>
      {item.updatedAt && (
        <Typography variant="subtitle1" className={styles.timeSubtitle}>
          {moment(item.updatedAt).fromNow()}
        </Typography>
      )}
    </Grid>
  </Grid>
);

export default AnnouncementsItem;

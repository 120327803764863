import React from 'react';

import './index.scss';

class Quotes extends React.Component {

  render() {
    return(
      <div>This is for quotes</div>
    );
  }
}

export default Quotes;
import React from 'react';

// Redux
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { updateVisitByAction } from 'redux/visit';
import { TeamCard } from '../cards';
import { showErrorMessage } from 'lib/notifier';
import { REASSIGN_TEAM } from 'common/constant';

// Component styles
const mapStateToProps = (state, ownProps) => {
  const team = Object.fromEntries(
    (ownProps.visitDetails &&
      ownProps.visitDetails.team &&
      ownProps.visitDetails.team.map((item) => [item.id, true])) ||
      []
  );
  return {
    initialValues: {
      id: (ownProps.visitDetails && ownProps.visitDetails.id) || null,
      team,
    },
    staffs: state.staff.staffs,
    assignees:
      (state.form.visitForm &&
        state.form.visitForm.values &&
        state.form.visitForm.values.team) ||
      [],
    staffDetails: (state.form.newStaff && state.form.newStaff.values) || {},
    visitDetailsFormValue: state.form.visitForm && state.form.visitForm.values,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearTeam: () => {
    dispatch(change('visitForm', 'team', null));
  },
  updateTeam: (team) => {
    dispatch(change('visitForm', 'team', team));
  },
  updateVisitByActionDispatch: (values) => dispatch(updateVisitByAction(values)),
});

const TeamTab = (props) => {
  const {
    assignees,
    translate,
    clearTeam,
    visitDetails,
    afterAssignedTeam,
    updateVisitByActionDispatch
  } = props;
  const formatTeam = (team) => {
    if (Object.keys(team || {}).length < 1) {
      return;
    }

    const items = [];

    Object.keys(team).forEach((member) => {
      if (team[member]) {
        items.push({ id: member });
      }
    });

    return items;
  };
  const updateAssignTeam = (values) => {
    updateVisitByActionDispatch(values).then(
      (response) => {
        if (response.status === 200) {
          const { job, ...remainData } = response.data;
          afterAssignedTeam(remainData);
        }
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  };

  const updateTeam = (team) => {
    props.updateTeam(team);

    const formattedTeam = formatTeam(team) || [];

    const values = {
      visitId: visitDetails.id,
      team: formattedTeam,
      action: REASSIGN_TEAM
    };
    updateAssignTeam(values);
  };

  const handleConfirmTeam = () => {
    const { visitDetailsFormValue } = props;

    const formattedTeam = formatTeam(visitDetailsFormValue.team) || [];

    const values = {
      visitId: visitDetails.id,
      team: formattedTeam,
      action: REASSIGN_TEAM
    };

    updateAssignTeam(values);
  };
  return (
    <form>
      <TeamCard
        assignees={assignees}
        saveTeamAfterChosen
        clearTeam={clearTeam}
        translate={translate}
        updateTeam={updateTeam}
        handleConfirmTeam={handleConfirmTeam}
        visitDetails={visitDetails}
      />
    </form>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamTab);

import React from 'react';
import { Box, Card, Typography, Button } from '@material-ui/core';
import { Field } from 'redux-form';
import styles from '../card.module.scss';
import { showSuccessMessage } from 'lib/notifier';

const renderField = ({ input, label }) => {
  if (!input.value) return null;
  return (
    <Box display="flex">
      {label && (
        <Box mr={2} width="25%">
          <Typography className={styles.grey_color}>{label}</Typography>
        </Box>
      )}
      <Typography>{input.value}</Typography>
    </Box>
  );
};

const renderCopyField = ({ input, label, t }) => {
  if (!input.value) return null;

  const handleCopy = () => {
    navigator.clipboard.writeText(input.value);
    showSuccessMessage(t('Dashboard:textCopied'));
  };
  return (
    <Box display="flex" alignItems="center">
      {label && (
        <Box mr={2} width="25%">
          <Typography className={styles.grey_color}>{label}</Typography>
        </Box>
      )}
      <Typography>{input.value}</Typography>
      <Button onClick={handleCopy} variant="text" style={{ color: '#0075FE' }}>
        {t('copy')}
      </Button>
    </Box>
  );
};

const AboutJob = ({ t }) => {
  return (
    <Card className={styles.card}>
      <Box p={2}>
        <Typography className={`${styles.grey_color} font_bold`}>
          {t('aboutThisJob')}
        </Typography>

        <Box mt={2} display="flex" flexDirection="column">
          <Field name="title" label={t('jobTitle')} component={renderField} />
          <Box mt={2}>
            <Field
              name="jobId"
              label={t('jobId')}
              t={t}
              component={renderCopyField}
            />
          </Box>
          <Box mt={2}>
            <Field
              name="startOn"
              label={t('startOn')}
              component={renderField}
            />
          </Box>
          <Box mt={2}>
            <Field name="endOn" label={t('endOn')} component={renderField} />
          </Box>
          <Box mt={2}>
            <Field name="isCollected" label={t('Job:collectedStatus')} component={renderField} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default AboutJob;

import React from 'react';

// Material components
import { Dialog, DialogContent } from '@material-ui/core';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import Loader from 'components/loaders/Loader';

const styles = theme => ({
});

export default withStyles(styles)(props => {
  const { classes, handleClose, open } = props;

  return (
    <Dialog
      aria-labelledby="loading-modal"
      className={classes.modalBox}
      onClose={handleClose}
      open={open}
    >
      <DialogContent>
        <Loader />
      </DialogContent>
    </Dialog>
  );
});
import React from 'react';

// Material components
import {
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';

// Component styles
import styles from './styles';

export default withStyles(styles)((props) => {
  const {
    classes,
    contactEmail,
    contactPhoneNumber,
    translate,
  } = props;

  return (
    <>
      <Typography variant='h6' className={classes.title}>{translate('Common:clientDetails')}</Typography>
      <Grid item container direction='column' spacing={1}>
        {
          (contactEmail || contactPhoneNumber) ?
            <>
              {
                contactEmail ?
                  <Grid item>
                    <Typography variant='body2'>{translate('Common:email')}</Typography>
                    <Typography className={classes.textDetails}>{contactEmail}</Typography>
                  </Grid>
                  : <Grid item>
                    <Typography className={classes.noContacts}>
                      {translate('Common:noEmailRecorded')}
                    </Typography>
                  </Grid>
              }
              {
                contactPhoneNumber ?
                  <Grid item>
                    <Typography variant='body2'>{translate('Common:contactNo')}</Typography>
                    <Typography className={classes.textDetails}>{contactPhoneNumber}</Typography>
                  </Grid>
                  : <Grid>
                    <Typography className={classes.noContacts}>
                      {translate('Common:noPhoneRecorded')}
                    </Typography>
                  </Grid>
              }
            </>
            : <Grid item>
              <Typography className={classes.noContacts}>
                {translate('Common:noEmailPhoneRecorded')}
              </Typography>
            </Grid>
        }
      </Grid>
    </>
  );
});
import React from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import styles from './components.module.scss';

const LIST_FILE_TYPE = ['Photo', 'Video', 'Files'];

const FilterByType = ({ t, mediaTypes, setMediaTypes }) => {

  const handleChangeFilter = (index) => {
    if (!mediaTypes.includes(index)) return setMediaTypes([...mediaTypes, index]);
    const deepCloneType = [...mediaTypes];
    deepCloneType.splice(mediaTypes.indexOf(index), 1);
    setMediaTypes(deepCloneType);
  };

  return (
    <>
      <Typography>{t('filterByType')}</Typography>

      <Box mt={2}>
        {LIST_FILE_TYPE.map((type, index) => (
          <Grid item key={index} className="mt_8">
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={mediaTypes.includes(index)}
                  onChange={() => handleChangeFilter(index)}
                  name={type}
                  color="primary"
                  className={styles.checkBox_style}
                  classes={{
                    checked: styles.checked_state,
                  }}
                />
              }
              label={t(type)}
              classes={{
                root: styles.checkbox_root,
                label: styles.checkbox_label,
              }}
            />
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default FilterByType;

import React, { forwardRef } from 'react';

// Externals
import classNames from 'classnames';

// Material components
import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  Divider,
  withStyles,
  Grid,
} from '@material-ui/core';

import { KeyboardArrowDown as DownArrow } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';

// Component styles
const styles = (theme) => ({
  moreIcon: {
    marginRight: theme.spacing(1),
  },
  sectionTitle: {
    color: theme.palette.text.primary,
    opacity: 0.7,
    paddingLeft: 4,
    paddingTop: 4,
    paddingBottom: 4,
  },
  popoverWithArrow: {
    '& .MuiPopover-paper': {
      padding: '16px 20px',
      minWidth: 180,
      marginTop: 10,
      overflowX: 'initial',
      overflowY: 'initial',
      borderColor: '#e2e2e2',
      borderWidth: 1,
      borderStyle: 'solid',
      boxShadow: '0px 2px 3px 0px rgb(0 0 0 / 20%)',
      '&:before': {
        content: '""',
        position: 'absolute',
        right: 10,
        top: -9,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 8px 8px 8px',
        borderColor: 'transparent',
        zIndex: 9998,
      },
    },
  },
  btnStyle: {
    minWidth: 120,
    paddingLeft: 8,
    paddingRight: 0,
    borderRadius: 4,
  },
  padding: {
    paddingLeft: 16,
  },
});

const MenuSection = withStyles(styles)(
  forwardRef((props, ref) => {
    const {
      classes,
      handleClickStatus,
      handleClose,
      lastIndex,
      itemStatus,
      sectionItem,
      selected,
    } = props;
    return (
      <MenuList innerRef={ref}>
        {sectionItem && !!sectionItem.title && (
          <Typography disabled className={classes.sectionTitle}>
            {sectionItem.title}
          </Typography>
        )}
        {sectionItem &&
          !!sectionItem.menuItems &&
          sectionItem.menuItems.map((item) => (
            <MenuItem
              key={item.label}
              disabled={
                item.disabled || (!!selected && item.id && selected === item.id)
              }
              onClick={() => {
                item.action();
                handleClose();
              }}
              className={item.padding ? classes.padding : null}
            >
              {item.iconSrc && (
                <img src={item.iconSrc} className="mr_10" alt="iconSrc" />
              )}
              {item.label}
            </MenuItem>
          ))}
        {sectionItem &&
          !!sectionItem.status &&
          sectionItem.status.map((status) => {
            if (sectionItem.allowedStatuses.includes(status.id)) {
              return (
                <MenuItem
                  key={status.id}
                  disabled={itemStatus === status.id}
                  className={classes.padding}
                  onClick={(e) => {
                    handleClickStatus(e, status.id);
                    handleClose();
                  }}
                >
                  {status.name}
                </MenuItem>
              );
            }
            return null;
          })}
        {!lastIndex && <Divider variant="fullWidth" />}
      </MenuList>
    );
  })
);

// PopupMenu props

// menuData
// menuData = [
//   {
//     title: "Section Title",
//     menuItems: [
//       {
//         label: "Menu Item A",
//         action: menuItemOnClickFunction,
//       },
//       for status change
//       {
//         label: "Menu Item A",
//         status: objectOfStatuses,
//         allowedStatuses: arrayOfAllowedStatuses,
//       },
//     ]
//   }
// ]

const PopupMenu = (props) => {
  const {
    classes,
    id,
    status,
    handleClickStatus,
    menuData,
    btnTitle,
    btnIcon = false,
    selected,
    isIconFirst,
    textStyle,
    btnCustomizeStyle,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  return (
    <>
      <Button
        aria-controls={id}
        aria-haspopup="true"
        color="secondary"
        onClick={handleClick}
        // size={btnTitle? 'small' : 'medium'}
        variant="contained"
        style={textStyle}
        className={`${classes.btnStyle} ${btnCustomizeStyle}`}
      >
        {btnTitle ? (
          btnIcon ? (
            isIconFirst ? (
              <div>
                {btnIcon && <AddIcon style={{ marginRight: 8 }} />}
                <span style={textStyle}>{btnTitle}</span>
              </div>
            ) : (
              <Grid container direction="row" justify="space-between">
                {btnTitle}
                {btnIcon && <DownArrow />}
              </Grid>
            )
          ) : (
            <span>{btnTitle}</span>
          )
        ) : (
          <span>{btnTitle}</span>
        )}
      </Button>
      <Menu
        id={id}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        className={classNames(classes.arrow, classes.popoverWithArrow)}
      >
        {menuData &&
          menuData.length > 0 &&
          menuData.map((section, index) => (
            <MenuSection
              handleClickStatus={handleClickStatus}
              handleClose={handleClose}
              lastIndex={index + 1 === menuData.length}
              itemStatus={status}
              key={index}
              sectionItem={section}
              selected={selected}
            />
          ))}
      </Menu>
    </>
  );
};

export default withStyles(styles)(PopupMenu);

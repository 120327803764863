import React, { useState } from 'react';

// Redux
import { useTranslation } from 'react-i18next';

import Modal from './modal';
import EditModal from './editModal';

const VisitDetailsModal = (props) => {
  const { handleCloseVisitDetails } = props;
  const { t: translate } = useTranslation('Job');

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const handleOpenEditModal = () => {
    handleCloseVisitDetails && handleCloseVisitDetails();
    setIsOpenEditModal(true);
  };

  if (isOpenEditModal)
    return (
      <EditModal
        {...props}
        translate={translate}
        isOpenEditModal={isOpenEditModal}
        setIsOpenEditModal={setIsOpenEditModal}
      />
    );

  return (
    <Modal
      {...props}
      translate={translate}
      handleOpenEditModal={handleOpenEditModal}
    />
  );
};

export default VisitDetailsModal;

export default theme => ({
  root: {},
  tableRow: {
    height: '64px'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  archivesInclusiveCheckbox: {
    paddingLeft: theme.spacing(2)
  },

  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    cursor: 'pointer'
  },
  chip: {
    fontWeight: 600,
  },
  lateVisit: {
    backgroundColor: theme.palette.chip.lateVisit.light,
    color: theme.palette.chip.lateVisit.dark,
  },
  unscheduled: {
    backgroundColor: theme.palette.chip.unscheduled.light,
    color: theme.palette.chip.unscheduled.dark,
  },
  unassigned: {
    backgroundColor: theme.palette.chip.unassigned.light,
    color: theme.palette.chip.unassigned.dark,
  },
  onStandby: {
    backgroundColor: theme.palette.chip.onStandby.light,
    color: theme.palette.chip.onStandby.dark,
  },
  onTheWay: {
    backgroundColor: theme.palette.chip.onTheWay.light,
    color: theme.palette.chip.onTheWay.dark,
  },
  arrived: {
    backgroundColor: theme.palette.chip.arrived.light,
    color: theme.palette.chip.arrived.dark,
  },
  started: {
    backgroundColor: theme.palette.chip.started.light,
    color: theme.palette.chip.started.dark,
  },
  completed: {
    backgroundColor: theme.palette.chip.completed.light,
    color: theme.palette.chip.completed.dark,
  },
  abandoned: {
    backgroundColor: theme.palette.chip.abandoned.light,
    color: theme.palette.chip.abandoned.dark,
  },
  archived: {
    backgroundColor: theme.palette.chip.archived.light,
    color: theme.palette.chip.archived.dark,
  },
  filterDropdownWrap: {
    width: '100%',
    paddingTop: 5,
    margin: 0
  },
  filterDropdown: {
    height: 40,
    margin: 0,
    '& .MuiSelect-selectMenu': {
      padding: 10
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    }
  },
});

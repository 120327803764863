import React, { Component } from 'react';
import {
  Grid,
  Tabs,
  Tab,
  Box,
  withStyles,
  Typography,
  Card,
} from '@material-ui/core';
import {
  Notes,
  AboutRequest,
  ClientDetails,
  HubDetails,
  ItemList,
} from './components';
import { CategoryTags } from 'pages/Dashboard/components';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import './index.scss';
import { setIsSaving, setCurrentRequest } from 'redux/request';
import {
  getEnterpriseRequestDetails,
  updateEnterpriseRequestDetails,
} from 'redux/enterprise';
import { getClientDetails } from 'redux/client';

import { showErrorMessage } from 'lib/notifier';

import styles from './styles';

const mapStatusIdToAction = {
  COMPLETED: 'COMPLETE_REQUEST',
  CANCELLED: 'CANCEL_REQUEST',
};

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes && classes.borderNone}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
class RequestDetails extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      request: {},
      isSubmittingBtn: false,
      anchorStatusMenuEl: null,
      tabsIndex: 0,
    };

    this.fetchRequestDetails = this.fetchRequestDetails.bind(this);
    this.handleClickStatusDropdown = this.handleClickStatusDropdown.bind(this);
    this.handleCloseStatusDropdownMenu = this.handleCloseStatusDropdownMenu.bind(
      this
    );
    this.fetchRequestStatus = this.fetchRequestStatus.bind(this);
    this.updateRequestStatus = this.updateRequestStatus.bind(this);
    this.flagUpdateAlready = this.flagUpdateAlready.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchRequestDetails();
    this.props.setIsSaving(false);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClickStatusDropdown(event) {
    this.setState({ anchorStatusMenuEl: event.currentTarget });
  }

  handleCloseStatusDropdownMenu() {
    this.setState({ anchorStatusMenuEl: null });
  }

  fetchRequestDetails() {
    const { requestId, getRequest, setCurrentRequestDispatch } = this.props;
    return getRequest(requestId).then(
      (response) => {
        if (response.status === 200 && this._isMounted) {
          this.setState({
            request: response.data,
          });
          setCurrentRequestDispatch(response.data);
        }
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  }

  updateRequestStatus(statusId) {
    const { requestId, requestActionsDispatch } = this.props;
    const action = mapStatusIdToAction[statusId];

    this.setState({ isSubmittingBtn: true });
    requestActionsDispatch(requestId, action).then(
      () => {
        this.fetchRequestDetails();
        this.setState({
          isSubmittingBtn: false,
          anchorStatusMenuEl: null,
        });
        this.flagUpdateAlready(true);
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  }

  fetchRequestStatus(statusId) {
    const { requestStatuses } = this.props;
    const requestItem = requestStatuses.find((item) => item.id === statusId);
    return requestItem ? requestItem.name : statusId;
  }

  flagUpdateAlready(status) {
    this.setState({ needUpdateVisits: status });
  }

  render() {
    const { classes, history, translate, requestStatuses } = this.props;
    const { request, tabsIndex } = this.state;
    const handleEditClicked = () => history.push(`${request.publicId}/edit`);
    const handleChange = (event, newValue) =>
      this.setState({ tabsIndex: newValue });

    const callbackDoAction = () => {
      this.fetchRequestDetails();
      this.setState({
        isShowVisitDetailsModal: false,
        selectedVisit: {},
        needUpdateVisits: true,
      });
    };

    const callbackUpdateStatusId = () => {
      this.fetchRequestDetails();
    };
    const renderTabContent = () => {
      const mapIndexToComponent = [ItemList, Notes];
      // prepare props pass to component
      const mapIndexToProps = [
        {
          customStyle: classes.borderNone,
          requestItems: request.items,
          totals: request.totals,
          currency: request.currency,
          request,
          fetchRequestDetails: this.fetchRequestDetails,
        },
        {
          notes: request.notes,
        },
      ];

      const Comp = mapIndexToComponent[tabsIndex];
      if (!Comp) return null;

      const customProps = {
        ...mapIndexToProps[tabsIndex],
        translate,
        request,
        requestId: request.publicId,
      };
      return (
        <TabPanel classes={classes}>
          <Comp {...customProps} />
        </TabPanel>
      );
    };

    return (
      <div className={classes.root}>
        <Box display="flex">
          <Typography>{translate('Common:backTo')}</Typography>
          <Link className="ml_5 font_bold" to="/enterprise-requests">
            {translate('requests')}
          </Link>
        </Box>
        {request && Object.keys(request).length > 0 && (
          <Grid container className="mt_10" spacing={1}>
            <Grid item md={7} sm={7}>
              <Card className={classes.card}>
                <Tabs
                  classes={{
                    indicator: classes.indicator,
                    root: classes.bottom_border,
                  }}
                  value={tabsIndex}
                  onChange={handleChange}
                >
                  <Tab label={translate('items')} />
                  <Tab label={translate('Common:notes')} />
                </Tabs>
                {renderTabContent()}
              </Card>
            </Grid>

            <Grid item md={5} sm={5}>
              <AboutRequest
                translate={translate}
                updateRequestStatus={this.updateRequestStatus}
                request={request}
                afterSaveVisitDetails={callbackDoAction}
                fetchRequestDetails={this.fetchRequestDetails}
              />

              <Field name="hub" component={HubDetails} translate={translate} />
              <Box mt={1}>
                <Field
                  name="categories"
                  component={CategoryTags}
                  translate={translate}
                />
              </Box>
              <Box mt={1}>
                <ClientDetails requestDetails={request} translate={translate} />
              </Box>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { request } = state.request || {};

  const {
    title,
    publicId,
    property,
    statusId,
    client: { displayName, email, phoneNumber } = {},
    hub,
    orderId,
    categories,
    verified,
    startVerifiedAt,
    description,
  } = request || {};
  return {
    request,
    requestStatuses: state.config.configs.requestStatuses,
    initialValues: {
      title,
      publicId,
      statusId,
      property,
      clientName: displayName,
      clientEmail: email,
      clientPhoneNumber: phoneNumber,
      hub,
      orderId,
      categories,
      verified,
      startVerifiedAt,
      description,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRequest: (requestId) => dispatch(getEnterpriseRequestDetails(requestId)),
  setIsSaving: () => dispatch(setIsSaving()),
  updateRequestDetails: (data) =>
    dispatch(updateEnterpriseRequestDetails(data)),
  getClientDetails: (clientId) => dispatch(getClientDetails(clientId)),
  setCurrentRequestDispatch: (requestDetails) =>
    dispatch(setCurrentRequest(requestDetails)),
});

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'requestDetails',
    enableReinitialize: true,
  })
)(RequestDetails);

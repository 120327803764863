import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import matchPath from 'lib/matchPath';
import { GET } from 'lib/api';

function ShortUrlPage(props) {
  const pathParams = matchPath(props.location.pathname, { path: '/s/:id' });
  const { params: { id } } = pathParams;


  useEffect(() => {
    async function getUrl() {
      const response = await GET(`/short-url/${id}`);
      window.location.href = response.data && response.data['url'] ? response.data['url'] : '/not-found';
    }

    getUrl();
  }, [id]);

  return (<div>Loading...</div>);
};

export default withRouter(ShortUrlPage);

import React, { useRef, useState } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { getEntepriseProduct } from 'redux/enterprise';
import { formatPrice } from 'lib/formatter';

// redux
import { useDispatch } from 'react-redux';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Add as AddIcon } from '@material-ui/icons';
import { showErrorMessage } from 'lib/notifier';
import styles from '../../details.module.scss';

import ServiceModal from './modal';

const options = {
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  actionsColumnIndex: -1,
  sorting: true,
  search: true,
  debounceInterval: 1000,
  draggable: false,
};

const renderColumn = ({ t }) => [
  {
    field: 'product',
    title: t('product'),
    render: (rowData) => {
      return <Typography>{rowData.name}</Typography>;
    },
    sorting: false,
  },
  {
    field: 'description',
    title: t('description'),
    render: (rowData) => {
      return <Typography>{rowData.description}</Typography>;
    },
    sorting: false,
  },
  {
    field: 'model',
    title: t('model'),
    render: (rowData) => {
      return <Typography>{rowData.model}</Typography>;
    },
    sorting: false,
  },
  {
    field: 'unitPrice',
    title: t('unitPrice'),
    render: (rowData) => {
      return (
        <Typography>
          {formatPrice(rowData.unitPrice, rowData.currency)}
        </Typography>
      );
    },
    sorting: false,
  },
  {
    field: 'tax',
    title: t('tax'),
    render: (rowData) => {
      return (
        <Typography>
          {(rowData.taxComponent && rowData.taxComponent.name) || 'N/A'}
        </Typography>
      );
    },
    sorting: false,
  },
];

const EnterpriseProducts = (props) => {
  const {
    t,
    match: { params: urlQuery },
  } = props;
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const tableRef = useRef();

  const columns = renderColumn({ t });

  const fetchProduct = async (query) => {
    const { page, pageSize, search } = query;
    const params = {
      $limit: pageSize,
      $skip: page * pageSize,
      ...(search && { $q: search }),
      typeId: 'PRODUCT',
      enterpriseId: urlQuery.enterpriseId,
    };
    const response = await dispatch(getEntepriseProduct({ params })).catch(
      (e) => ({
        e,
      })
    );
    if (response.e) {
      showErrorMessage(response.e);
      return Promise.reject(response);
    }
    return Promise.resolve({
      data: response.data,
      page,
      totalCount: response.total,
    });
  };

  const handleRowClick = (event, rowData) => {
    setIsOpenModal(true);
    setSelectedRow(rowData);
  };

  const handleOpenNewService = () => setIsOpenModal(true);

  return (
    <>
      {isOpenModal && (
        <ServiceModal
          t={t}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setIsOpenModal={setIsOpenModal}
          enterpriseId={urlQuery.enterpriseId}
          tableRef={tableRef}
        />
      )}
      <MaterialTable
        title={null}
        columns={columns}
        options={options}
        tableRef={tableRef}
        data={fetchProduct}
        onRowClick={handleRowClick}
        components={{
          Toolbar: (props) => (
            <Grid container alignItems="center" justify="flex-end">
              <MTableToolbar {...props} />
              <Grid className="mr_5">
                <Button
                  onClick={handleOpenNewService}
                  className={styles.btn_add_service}
                >
                  <AddIcon />
                  {t('newProduct')}
                </Button>
              </Grid>
            </Grid>
          ),
        }}
      />
    </>
  );
};

export default EnterpriseProducts;

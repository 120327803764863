import React from 'react';

// Externals
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getLocationDetails } from 'redux/location';

// Material helpers
// Material components
import { Grid, Avatar, Button, withStyles } from '@material-ui/core';

// Material Icons
import { Person, Phone } from '@material-ui/icons';

// shared component
import { DirectionMap } from 'components/gpsTracking';
import { showErrorMessage } from 'lib/notifier';

import StaffDefaultAvatar from 'assets/images/staff_default_avatar.svg';

// Component styles
import styles from './styles';
import { getTimeFormat } from 'lib/formatter';

class WorkerTrackingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workerDetails: null,
      estimateOfTimeArrival: null,
    };
  }

  componentDidMount() {
    this.fetchWorkerDetails(this.props.userId);
  }


  handleUpdateETAFromMap(timeInSecond) {
    let date = null;
    if (timeInSecond) {
      date = new Date();
      date.setSeconds(date.getSeconds() + parseInt(timeInSecond));
    }
    this.setState({ estimateOfTimeArrival: date });
  }

  clientName() {
    const { workerDetails: { currentJob: { job: { client: { displayName } = {} } = {} } = {} } = {} } = this.state;
    return displayName;
  }

  fetchWorkerDetails(workerId) {
    const { getLocationDetailsFunction, history, token } = this.props;
    const options = {
      token
    };
    getLocationDetailsFunction(workerId, options).then(response => {
      const { data } = response;

      if (data.currentJob) {
        data.currentJob.jobLocation = data.currentJob && data.currentJob.jobLocation;
      }
      this.setState({ workerDetails: data });
    }, error => {
      showErrorMessage(error);
      history.push('/');
    });
  }

  render() {
    const { classes, translate } = this.props;
    const { workerDetails, estimateOfTimeArrival } = this.state;

    return (
      <div className={classes.root}>
        <Grid
          container
          justify="center"
        >
          <Grid
            item
            lg={3}
            xs={12}
          >
            <div className={classes.workerDetailsSection}>
              <Grid container spacing={2} className={classes.detailsWrap}>
                <Grid item lg={12} xs={12}>
                  <div className={classes.rowDetails}>
                    <div className={classes.workerAvatar}>
                      <Avatar className={classes.avatar} src={(workerDetails || {}).avatar || StaffDefaultAvatar} />
                    </div>
                    <div className={classes.statusButtonWrap}>
                      { workerDetails && workerDetails.currentJob && workerDetails.currentJob.status &&
                        <Button className={classes.statusButton}>{workerDetails.currentJob.status}</Button>}
                    </div>
                  </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div className={classes.rowDetails}>
                    <div className={classes.textField}>
                      <Person className={classes.valueIcon} />
                      <div className={classes.text}>
                        <div className={classes.value}>
                          {workerDetails && workerDetails.fullName}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.rowDetails}>
                    <div className={classes.textField}>
                      <Phone className={classes.valueIcon} />
                      <div className={classes.text}>
                        <div className={classes.value}>
                          {workerDetails && workerDetails.phoneNumber}
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    workerDetails && workerDetails.currentJob &&
                    <>
                      <div className={classes.rowDetails}>
                        <div className={classes.text}>
                          <div className={classnames(classes.label, classes.sectionTitle)}>
                            {translate('WorkerTracking:currentJob')}
                          </div>
                        </div>
                      </div>

                      <div className={classes.rowDetails}>
                        <Grid container spacing={1}>
                          {/* JOB Id */}
                          <Grid item lg={6} xs={6}>
                            <div className={classes.textField}>
                              <div className={classes.text}>
                                <div className={classes.label}>{translate('WorkerTracking:jobID')}:</div>
                                <div className={classes.value}>
                                  {workerDetails && workerDetails.currentJob && workerDetails.currentJob.job.publicId }
                                </div>
                              </div>
                            </div>
                          </Grid>

                          {/* Worker ETA */}
                          <Grid item lg={6} xs={6}>
                            <div className={classes.textField}>
                              <div className={classes.text}>
                                <div className={classes.label}>
                                  {translate('WorkerTracking:estimateTimeOfArrival')}:</div>
                                <div className={classes.value}>
                                  {estimateOfTimeArrival ? moment(estimateOfTimeArrival).format(getTimeFormat()) : ''}
                                </div>
                              </div>
                            </div>

                          </Grid>
                        </Grid>
                      </div>

                      {/* JOB Client */}
                      <div className={classes.rowDetails}>
                        <div className={classes.textField}>
                          <div className={classes.text}>
                            <div className={classes.label}>{translate('WorkerTracking:clientName')}:</div>
                            <div className={classes.value}>{this.clientName()}</div>
                          </div>
                        </div>
                      </div>

                      {/* JOB Title */}
                      <div className={classes.rowDetails}>
                        <div className={classes.textField}>
                          <div className={classes.text}>
                            <div className={classes.label}>{translate('WorkerTracking:jobTitle')}:</div>
                            <div className={classes.value}>
                              { workerDetails && workerDetails.currentJob && workerDetails.currentJob.title }
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* JOB Schedule */}
                      <div className={classes.rowDetails}>
                        <div className={classes.textField}>
                          <div className={classes.text}>
                            <div className={classes.label}>{translate('WorkerTracking:jobSchedule')}:</div>
                            <div className={classes.value}>
                              {
                                workerDetails &&
                                `${ moment( workerDetails.currentJob.start).format(getTimeFormat())}
                                -
                                ${moment( workerDetails.currentJob.end).format(getTimeFormat())}`
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* JOB Address */}
                      <div className={classes.rowDetails}>
                        <div className={classes.textField}>
                          <div className={classes.text}>
                            <div className={classes.label}>{translate('WorkerTracking:propertyAddress')}:</div>
                            <div className={classes.value}> {
                              workerDetails && workerDetails.currentJob &&
                              workerDetails.currentJob.job &&
                              workerDetails.currentJob.job.property &&
                              [ workerDetails.currentJob.job.property.unitNumber,
                                workerDetails.currentJob.job.property.address,
                                workerDetails.currentJob.job.property.city,
                                workerDetails.currentJob.job.property.state
                              ].filter(e => e)
                                .join(', ')
                            }</div>
                          </div>
                        </div>
                      </div>
                    </>
                  }

                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            lg={9}
            xs={12}
          >
            <div className={classes.content} style={{ height: '100vh', width: '100%' }}>
              {
                workerDetails && workerDetails.userLocation &&
                <DirectionMap
                  workerDetails={workerDetails}
                  handleUpdateETAFromMap={value => this.handleUpdateETAFromMap(value)}
                />
              }
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

WorkerTrackingDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  getLocationDetailsFunction: (userId, options) => dispatch(getLocationDetails(userId, options)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(WorkerTrackingDetails);
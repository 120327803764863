import React, { useRef, useEffect, useState } from "react";
import { JobTable } from "./components/table";
import { FilterHeader } from "./components/filter";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "redux/dashboard";
import { showErrorMessage } from "lib/notifier";
import { getCategories } from "redux/category";

// Shared components
import { FilterSidebar } from "pages/Dashboard/components";
import { setIsJobsFiltering } from "redux/global";
import {
  QUERY_STRING_ENCODE,
  SIDE_BAR_FILTER_FORM_NAME,
} from "common/constant";
import { change } from "redux-form";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import payloadPreparation from "pages/Dashboard/views/Jobs/JobList/components/components/utils/payloadPreparation";

const JOB_STATUSES = [
  { value: "PENDING", label: "PENDING" },
  { value: "IN_PROGRESS", label: "IN_PROGRESS" },
  { value: "COMPLETED", label: "COMPLETED" },
  { value: "CANCELLED", label: "CANCELLED" },
  { value: "ARCHIVED", label: "ARCHIVED" },
];

const CLIENT_TAG = "ENTERPRISE_PARTNER";

const today = new Date();
const fromDate = today.setDate(today.getDate() - 20);
const today2 = new Date();
const toDate = today2.setDate(today2.getDate() + 10);
const JOB_SCHEDULED_ON = [fromDate, toDate];

const JobList = (props) => {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation("Enterprise");
  const [isLoading, setIsLoading] = useState(false);
  const [enterpriseAccountId, setEnterpriseAccountId] = useState("");
  const history = useHistory();
  const location = useLocation();

  const companyDetails = useSelector(
    (state) =>
      state.auth.currentUser.companies && state.auth.currentUser.companies[0]
  );

  const onSaveToParams = (values) => {
    const { sort, from, to, skip, ...urlSearch } = values;
    const stringified = queryString.stringify(
      payloadPreparation(urlSearch),
      QUERY_STRING_ENCODE
    );
    history.replace({ ...location, search: stringified });
  };

  const onSaveFilters = (values) => {
    onSaveToParams(values);
    tableRef.current && tableRef.current.onQueryChange({ ...values, page: 0 });
  };

  const updateFilterStatus = (status) => {
    dispatch(setIsJobsFiltering(status));
  };

  useEffect(() => {
    if (companyDetails.tag !== CLIENT_TAG.toLowerCase()) return;
    setEnterpriseAccountId(companyDetails.id);
  }, [companyDetails]);

  async function getCompanyCategories(options = {}) {
    const result = await dispatch(getCategories(options));
    if (result.status === 200) {
      const params = new URLSearchParams(location.search);
      let newList = {};
      const mapTreeCategories = (categoryList) => {
        categoryList.forEach((category) => {
          newList = {
            ...newList,
            [category.id]: {
              ...category,
              checked: true,
            },
          };
          if (
            !Array.isArray(category.childrenCategories) ||
            !category.childrenCategories.length
          )
            return null;
          mapTreeCategories(category.childrenCategories);
        });
      };
      mapTreeCategories(result.data.data);
      const listParams = params.getAll("jobCategoryIds[]");
      let newListParams = [];
      Object.values(newList).forEach((val) => {
        if (listParams.findIndex((elm) => parseInt(elm) === val.id) > -1) {
          newListParams = [
            ...newListParams,
            {
              id: val?.id,
              name: val?.name,
              parentCategoryId: val?.parentCategoryId
                ? val?.parentCategoryId
                : null,
            },
          ];
        }
      });
      tableRef.current &&
        tableRef.current.onQueryChange({
          categoryIds: newListParams.map((item) => item.id),
          page: 0,
        });
    }
  }

  useEffect(() => {
    // TODO: will implement load more in next release
    getCompanyCategories({ limit: 100, enterpriseAccountId });
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     setIsLoading(true);
  //     const response = await dispatch(getUserDetails());
  //     setIsLoading(false);
  //     if (response.e) return showErrorMessage(response.e);
  //     const defaultCategory = response.categories || [];
  //     // dispatch(change(SIDE_BAR_FILTER_FORM_NAME, 'categories', defaultCategory));
  //     tableRef.current &&
  //       tableRef.current.onQueryChange({
  //         categoryIds: defaultCategory.map((item) => item.id),
  //         page: 0,
  //       });
  //   })();
  // }, []);

  return (
    <>
      <FilterSidebar
        jobFiltering
        isHideSR
        isHideWorker
        isHideManageCategoryTag
        isHideHub
        isEnterprise
        statues={JOB_STATUSES}
        defaultJobScheduledOn={JOB_SCHEDULED_ON}
        onSubmit={onSaveFilters}
        showFilterCollectedItems
        updateFilterStatus={updateFilterStatus}
        enterpriseAccountId={enterpriseAccountId}
      />
      <Box p={2}>
        <FilterHeader tableRef={tableRef} translate={translate} />
        <JobTable
          {...props}
          tableRef={tableRef}
          translate={translate}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};

export default JobList;

import { PATCH, GET } from 'lib/api';

// For Invoice setting
export function updateUser(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    try {
      const response = await PATCH('/me', accessToken, {
        data: {
          currentPassword: data.currentPassword,
          password: data.password,
          language: data.language,
          timeFormat: data.timeFormat,
        }
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}

export function accountDetails(passwordData) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    try {
      const response = await GET('/me', accessToken );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}

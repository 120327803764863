export default (values) => {
  let errors = { message: {} };
  const itemErrors = [];

  // if (!values.subject) {
  //   errors = {
  //     ...errors,
  //     subject: 'required',
  //     message: {
  //       ...errors.message,
  //       subject: 'invoiceSubject',
  //     }
  //   };
  // }

  if (values.products && values.products.length) {
    values.products.forEach((item, itemIndex) => {
      itemErrors[itemIndex] = {};
      if (!item.name) {
        itemErrors[itemIndex] = {
          ...itemErrors[itemIndex],
          name: 'required',
          message: {
            ...itemErrors[itemIndex].message,
            name: 'setItemName',
          },
        };
      }
      if (!item.quantity || item.quantity <= 0) {
        itemErrors[itemIndex] = {
          ...itemErrors[itemIndex],
          quantity: 'positiveQuantity',
          message: {
            ...itemErrors[itemIndex].message,
            quantity: 'positiveQuantity',
          },
        };
      }

      if (!item.unitPrice || item.unitPrice < 0) {
        itemErrors[itemIndex] = {
          ...itemErrors[itemIndex],
          unitPrice: 'positiveUnitCost',
          message: {
            ...itemErrors[itemIndex].message,
            unitPrice: 'positiveUnitCost',
          },
        };
      }

      if (!item.total || item.total < 0) {
        itemErrors[itemIndex] = {
          ...itemErrors[itemIndex],
          total: 'positiveTotal',
          message: {
            ...itemErrors[itemIndex].message,
            total: 'positiveTotal',
          },
        };
      }
    });
  }
  if (!values.discountValue && values.discountValue !== 0 ) {
    errors = {
      ...errors,
      discountValue: 'required',
      message: {
        ...errors.message,
        discountValue: 'required',
      },
    };
  }

  if (
    values.discountType === '%' &&
    parseFloat(values.discountValue || 0) > 100
  ) {
    errors = {
      ...errors,
      discountValue: 'invalidDiscount',
      message: {
        ...errors.message,
        discountValue: 'invalidDiscount',
      },
    };
  }

  if (itemErrors.length > 0) {
    errors.items = itemErrors;
  }
  return errors;
};

import React from 'react';

import {
  Typography,
  Stepper,
  StepLabel,
  StepConnector,
  Step,
} from '@material-ui/core';

import { Fields } from 'redux-form';

import styles from '../components.module.scss';

const renderFields = (props) => {
  const { names, translate } = props;
  return (
    <>
      <Stepper
        classes={{ root: styles.stepper }}
        className={styles.stepper_limit}
        connector={
          <StepConnector classes={{ root: styles.connector_styles }} />
        }
        orientation="vertical"
      >
        {names.map((label, index) => {
          const { value } = props[label].input;
          if (!value) return null;
          const labelProps = {};
          labelProps.optional = (
            <Typography variant="subtitle1" className={styles.date_time_text}>
              {value}
            </Typography>
          );
          return (
            <Step key={index}>
              <StepLabel
                className={styles.label_stepper}
                classes={{
                  label: styles.labelText,
                }}
                StepIconProps={{
                  classes: { root: styles.icon },
                }}
                {...labelProps}
              >
                {translate(`Timesheet:${label}`)}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};

const VisitTimeStepperFields = (props) => {
  const { translate } = props;
  const steps = ['ontheway', 'arrived', 'start', 'completed', 'cancelled'];

  return (
    <Fields names={steps} component={renderFields} translate={translate} />
  );
};

export default VisitTimeStepperFields;

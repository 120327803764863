import React, { useState, useRef } from 'react';
import {
  Paper,
  Modal,
  withStyles,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import VisitDetails from '../VisitDetails';
import { showErrorMessage } from 'lib/notifier';
import { getVisits, getVisit } from 'redux/visit';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  VisitForm,
  StatusChip,
} from 'pages/Dashboard/components';
import moment from 'moment';
import styles from './styles';
import MaterialTable from 'material-table';
import { getDateTimeFormat } from 'lib/formatter';

const options = {
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  actionsColumnIndex: -1,
  sorting: true,
  search: false,
  draggable: false,
  toolbar: false,
};

const renderColumns = (translate) => [
  {
    field: 'title',
    title: translate('Common:title'),
    sorting: false,
    filtering: false,
  },
  {
    field: 'start',
    title: translate('Job:startAt'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!rowData.start) return null;
      return (
        <Typography variant="body1">
          {moment(rowData.start).format(getDateTimeFormat())}
        </Typography>
      );
    },
  },
  {
    field: 'end',
    title: translate('Job:endAt'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!rowData.end) return null;
      return (
        <Typography variant="body1">
          {moment(rowData.end).format(getDateTimeFormat())}
        </Typography>
      );
    },
  },
  {
    field: 'team',
    title: translate('Job:assignedTo'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!Array.isArray(rowData.team)) return null;
      return (
        <Typography variant="body1">
          {rowData.team.map((member) => member.fullName).join(', ')}
        </Typography>
      );
    },
  },
  {
    field: 'statusId',
    title: translate('Job:status'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!rowData.statusId) return null;
      return <StatusChip job status={rowData.statusId} />;
    },
  },
];

const ClientSchedule = (props) => {
  const { clientDetails, statuses, classes, translate } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const [openVisitDetails, setOpenVisitDetails] = useState(false);
  const [visitDetails, setVisitDetails] = useState({});
  const [openVisitForm, setOpenVisitForm] = useState(false);
  const columns = renderColumns(translate);

  const fetchVisits = async (query) => {
    const { page, pageSize } = query;
    // let sortParams = { '$sort[archived]': 1 };
    // if (orderBy) {
    //   const sortOrder = orderDirection === 'asc' ? 1 : -1;
    //   const sortKey = `$sort[${orderBy['field']}]`;
    //   sortParams = { [sortKey]: sortOrder };
    // }

    const params = {
      limit: pageSize,
      skip: page * pageSize,
      clientId: clientDetails.id,
    };
    const response = await dispatch(getVisits(params)).catch((e) => ({ e }));
    if (response.e) {
      showErrorMessage(response.e);
      return Promise.reject(response.e);
    }
    return Promise.resolve({
      data: response.data.data,
      page,
      totalCount: response.data.total,
    });
  };

  const handleClickRow = async (event, rowData) => {
    const result = await dispatch(getVisit(rowData.id)).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    setVisitDetails(result.data);
    setOpenVisitDetails(true);
  };

  const handleOpenVisitForm = (details = {}) => {
    setVisitDetails(details);
    setOpenVisitDetails(false);
    setOpenVisitForm(true);
  };

  const handleCloseVisitDetails = () => {
    setOpenVisitDetails(false);
    setVisitDetails({});
  };

  const handleCloseVisitForm = () => {
    setOpenVisitForm(false);
    handleCloseVisitDetails();
  };

  const updateClientSchedules = () => {
    tableRef.current.onQueryChange();
  };

  return (
    <div className="list-visit">
      <div className="list-visit-wraper">
        <Portlet className={classes.visitListPortlet}>
          <PortletHeader className={classes.cardHeader}>
            <PortletLabel title={translate('Job:schedule')} />
          </PortletHeader>
          <PortletContent className={classes.visitTableContent}>
            <Paper className={classes.paperStyle}>
              <MaterialTable
                title={null}
                columns={columns}
                options={options}
                tableRef={tableRef}
                data={fetchVisits}
                onRowClick={handleClickRow}
              />
            </Paper>
          </PortletContent>
        </Portlet>
      </div>

      {/* Modal for visit details */}
      <Modal open={openVisitDetails} disablePortal>
        <DialogContent>
          <VisitDetails
            visitDetails={visitDetails}
            statuses={statuses}
            closeVisitDetails={handleCloseVisitDetails}
            handleOpenVisitForm={handleOpenVisitForm}
            updateVisitList={updateClientSchedules}
            translate={translate}
          />
        </DialogContent>
      </Modal>

      {/* Modal for visit form */}
      {openVisitForm && (
        <Modal disablePortal open={openVisitForm}>
          <DialogContent>
            <VisitForm
              visitDetails={visitDetails}
              afterSavedVisit={updateClientSchedules}
              handleCloseVisitForm={handleCloseVisitForm}
              translate={translate}
            />
          </DialogContent>
        </Modal>
      )}
    </div>
  );
};

export default withStyles(styles)(ClientSchedule);

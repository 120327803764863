import React from 'react';
import { useTranslation } from 'react-i18next';

export function getTeamStatus(deleted, archived, translate) {
  if (deleted) return translate('deleted');
  return archived ? translate('deactivated') : '';
}

export function useTeamStatus() {
  const { t } = useTranslation('Job');
  const getStatus = (deleted, archived) => {
    if (deleted) return t('deleted');
    return archived ? t('deactivated') : '';
  };
  return [getStatus];
}

export function withTeamStatusHook(Component) {
  return function WrappedComponent(props) {
    const [getStatus] = useTeamStatus();
    return <Component {...props} getStatus={getStatus} />;
  };
}

import React from 'react';
import { TextInput , DatePicker, TimePicker } from 'components/inputs';
import { Button, Grid, withStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm , change } from 'redux-form';
import './index.scss';

import Countries from 'lib/countryList';
// Shared components
import TeamCard from 'pages/Dashboard/components/TeamCard';
import Portlet from 'pages/Dashboard/components/Portlet';
import PortletHeader from 'pages/Dashboard/components/PortletHeader';
import PortletLabel from 'pages/Dashboard/components/PortletLabel';
import PortletContent from 'pages/Dashboard/components/PortletContent';
// Component styles
import styles from './styles';

import imgSrc from 'assets/images/icons/blue_details.svg';

let VisitFormComponent = props => {

  const { handleSubmit, classes, updateTeam, clearTeam,
    handleCloseVisitForm, assignees, translate, visitDetails
  } = props;

  if (!visitDetails || !visitDetails.job) return null;
  const { job: { publicId: jobId, property, client } } = visitDetails;
  const clientName = client.displayName;

  const propertyAddressExtra = [
    property.city, property.state, property.zipCode,
    new Countries().countryName(property.countryCode)
  ].filter(Boolean).join(', ');

  const handleOpenJob = () => window.open(`/jobs/${jobId}`, '_blank');
  const handleOpenClient = () => window.open(`/clients/${client.id}`, '_blank');

  return (
    <form
      noValidate
      autoComplete="off"
      className="visit-form mt_20"
      onSubmit={handleSubmit}
    >
      <Grid item container direction='row'>
        <Grid item xl={8} lg={8} md={8} sm={3} xs={12} className="visit-title">
          <Field
            name='title'
            component={TextInput}
            label={translate('Job:visitTitle')}
            margin="dense"
            required
            variant="outlined"
          />

          <Field
            name='description'
            component={TextInput}
            label={translate('Common:description')}
            margin="dense"
            variant="outlined"
            multiline
            rows="5"
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={3} xs={12} className="job-details">
          <div className="job-details-content">
            <h4 className={classes.job_title}>{translate('Job:jobDetails')}</h4>
            <div className="item">
              <div className="title">{translate('Job:jobId')}</div>
              <Grid container onClick={handleOpenJob} className={classes.cursor}>
                <div className={classes.textStyle}>{jobId}</div>
                <img src={imgSrc} alt="imgSrc" className="ml_5" />
              </Grid>
            </div>
            <div className="item">
              <div className="title">{translate('Common:client')}</div>
              <Grid container onClick={handleOpenClient} className={classes.cursor}>
                <div className={classes.textStyle}>{ client && clientName }</div>
                <img src={imgSrc} alt="imgSrc" className="ml_5" />
              </Grid>
            </div>
            <div className="item">
              <div className="title">{translate('Common:address')}</div>
              <div className={classes.textStyle}>
                <p> { property && property.address } </p>
                <p> { property && propertyAddressExtra } </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item container direction='row' className="mt_30">
        <Grid item container xl={8} lg={8} md={8} sm={12} xs={12} className="visit-schedule">
          <Portlet className={classes.visitSchedule}>
            <PortletHeader className={classes.cardHeader}>
              <PortletLabel title={translate('Job:schedule')} />
            </PortletHeader>
            <PortletContent>
              <Grid item container direction='column'>
                <Typography className={classes.schedule_start}>
                  {translate('Job:startTime')}
                </Typography>
              </Grid>
              <Grid item container direction='row' spacing={1}>
                <Grid item container direction='row' xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Field
                    name='startDate'
                    component={DatePicker}
                    label={translate('Job:startDate')}
                  />
                </Grid>
                <Grid item container direction='row' xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Field
                    name='startTime'
                    component={TimePicker}
                    label={translate('Job:startTime')}
                  />
                </Grid>
              </Grid>
              <Grid item container direction='column'>
                <Typography className={classes.schedule_start}>
                  {translate('Job:endTime')}
                </Typography>
              </Grid>
              <Grid item container direction='row' spacing={1}>
                <Grid item container direction='row' xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Field
                    name='endDate'
                    component={DatePicker}
                    label={translate('Job:endDate')}
                  />
                </Grid>
                <Grid item container direction='row' xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Field
                    name='endTime'
                    component={TimePicker}
                    label={translate('Job:endTime')}
                  />
                </Grid>
              </Grid>
            </PortletContent>
          </Portlet>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="team-assigning">
          <TeamCard
            assignees={assignees}
            clearTeam={clearTeam}
            translate={translate}
            updateTeam={updateTeam}
          />
        </Grid>
      </Grid>
      <Grid item container direction='row' className="form-action">
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="visit-form-footer" alignContent='flex-end' justify='flex-end'>

          <Button color="secondary" onClick={handleCloseVisitForm}>{translate('Common:cancel')}</Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
          >
            {translate('Common:save')}
          </Button>

        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  return ({
    initialValues: {
      jobId: (ownProps.visitDetails && ownProps.visitDetails.job && ownProps.visitDetails.job.publicId) || null,
      id: (ownProps.visitDetails && ownProps.visitDetails.id) || null,
      startDate: (ownProps.visitDetails && ownProps.visitDetails.start) || null,
      endDate: (ownProps.visitDetails && ownProps.visitDetails.end) || null,
      startTime: (ownProps.visitDetails && ownProps.visitDetails.start) || null,
      endTime: (ownProps.visitDetails && ownProps.visitDetails.end) || null,
      title: (ownProps.visitDetails && ownProps.visitDetails.title) || null,
      virtual: (ownProps.visitDetails && ownProps.visitDetails.virtual) || false,
      description: (ownProps.visitDetails && ownProps.visitDetails.description) || null,
      team:  Object.fromEntries((
        ownProps.visitDetails &&
        ownProps.visitDetails.team &&
        ownProps.visitDetails.team.map(item => [item.id, true])
      ) || []),
    },
    staffs: state.staff.staffs,
    assignees: (state.form.visitForm && state.form.visitForm.values && state.form.visitForm.values.team) || []
  });
};

const mapDispatchToProps = dispatch => ({
  updateTeam: (team) => { dispatch(change('visitForm', 'team', team)); },
  clearTeam: () => { dispatch(change('visitForm', 'team', null)); },
});

VisitFormComponent = reduxForm({
  form: 'visitForm',
  enableReinitialize: true
})(VisitFormComponent);


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(VisitFormComponent);

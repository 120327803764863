import React, { useState } from 'react';
import { Typography, Grid, Box, ButtonGroup, Button } from '@material-ui/core';
import { ItemsForm } from './components';

import styles from './styles.module.scss';

const ItemList = (props) => {
  const { translate, request } = props;
  const [selectedButton, setSelectedButton] = useState(0);

  const handleClickBtn = (number = 0) => setSelectedButton(number);

  return (
    <>
      <Box p={3}>
        <Grid container justify='space-between'>
          <Box display='flex'>
            <Box display='flex' alignItems='center'>
              <Typography className='mr_10'>{translate('viewAs')}</Typography>

              <ButtonGroup
                variant='contained'
                aria-label='contained primary button group'
                classes={{ root: styles.btn_group }}
              >
                <Button
                  onClick={() => handleClickBtn(0)}
                  disabled={selectedButton === 0}
                >
                  <Typography>{translate('serviceCard')}</Typography>
                </Button>
                <Button
                  onClick={() => handleClickBtn(1)}
                  disabled={selectedButton === 1}
                >
                  <Typography>{translate('serviceOnly')}</Typography>
                </Button>
                <Button
                  onClick={() => handleClickBtn(2)}
                  disabled={selectedButton === 2}
                >
                  <Typography>{translate('itemOnly')}</Typography>
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Grid>

        <ItemsForm
          translate={translate}
          itemDetails={request.items}
          selectedButton={selectedButton}
        />
      </Box>
    </>
  );
};

export default ItemList;

import React from 'react';
import { connect } from 'react-redux';
import { Container, Button, Grid } from '@material-ui/core';
import { reduxForm, Field, initialize } from 'redux-form';
import { showErrorMessage } from 'lib/notifier';
import { TextInput, ReactSelect } from 'components/inputs';
// Redux
import { companyDetailsUpdate, getCompanyDetails, updateCompanyDetailForm } from 'redux/company';
import './index.scss';
// css

const wmLogo = require('assets/images/logo/logo.png');

const validate = values => {
  const errors = { message: {} };
  const requiredFields = [
    'companySizeId', 'startingYear',
    'userRoleInCompany', 'leadSourceId', 'industries'

  ]; // All fields is optional for now. If any fields is required, just add it to here.
  requiredFields.forEach(field => {
    if (!values[field] || (Array.isArray(values[field]) && values[field].length === 0)) {
      errors[field] = 'required';
      errors.message[field] = 'required';
    }
  });

  return errors;
};

const ORGANISATION_ROLES = [
  { value: 'founder_ceo', keyTranslate: 'founder_ceo' },
  { value: 'director', keyTranslate: 'director' },
  { value: 'senior_manager', keyTranslate: 'senior_manager' },
  { value: 'manager', keyTranslate: 'manager' },
  { value: 'supervisor', keyTranslate: 'supervisor' },
  { value: 'associate_or_xecutive', keyTranslate: 'associate_or_xecutive' },
  { value: 'other_employee', keyTranslate: 'other_employee' },
];

const yearOptions = () => {
  const currentYear = new Date().getFullYear();
  return Array.from(
    { length: 120 },
    (_, i) => Object.assign({}, { value: currentYear + (i * -1), label: currentYear + (i * -1) })
  );
};

class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    await this.fetchCompanyDetails();
  }

  async fetchCompanyDetails() {
    const { getCompanyDetails } = this.props;
    try {
      const res = await getCompanyDetails();
      await this.props.initForm(updateCompanyDetailForm(res.data));
    } catch (error) {
      showErrorMessage(error);
    }
  }

  async handleSubmit() {
    const { companyInfo, companyDetailsUpdate, history } = this.props;
    try {
      const response = await companyDetailsUpdate(companyInfo);
      if (response.status === 200) {
        history.push('/calendar');
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  render() {
    const { invalid, submitting, t } = this.props;
    return (
      <Container className="company-details-page" style={{ padding: 0 }}>
        <div className="header-logo">
          <img className="logo" src={wmLogo} alt="header-logo" />
        </div>
        <div className="page-content" style={styles.container}>
          <div className="title-wraper">
            <h2 className="title">{t('companySettingTitle')}</h2>
            <p className="description">{t('companySettingDescription')}</p>
          </div>
          <form className="form-body">
            <Grid item container>
              <Grid item xs={12} sm={6}>
                <Field
                  name="startingYear"
                  component={ReactSelect}
                  label={t('startingYear')}
                  margin="normal"
                  options={yearOptions()}
                  className="selector"
                  classNamePrefix="select"
                  placeholder=""
                />

              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name="userRoleInCompany"
                  component={ReactSelect}
                  label={t('userRoleInCompany')}
                  margin="normal"
                  options={ORGANISATION_ROLES}
                  className="selector"
                  classNamePrefix="select"
                  placeholder=""
                  // isMulti={true}
                />

              </Grid>

              <Grid item xs={12} sm={6}>

                <Field
                  name="leadSourceId"
                  component={ReactSelect}
                  label={t('leadSourceId')}
                  margin="normal"
                  options={this.props.leadSourceOptions}
                  className="selector"
                  classNamePrefix="select"
                  placeholder=""
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name="companySizeId"
                  component={ReactSelect}
                  label="Company size"
                  margin="normal"
                  options={this.props.companySizeOptions}
                  className="selector"
                  classNamePrefix="select"
                  placeholder=""
                  helperText={t('companySizeId')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name="industries"
                  component={ReactSelect}
                  label={t('selectIndustry')}
                  margin="normal"
                  options={this.props.industryOptions}
                  className="selector"
                  classNamePrefix="select"
                  placeholder=""
                  helperText={t('selectionIndustryHelperText')}
                  isMulti
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name="extraForIndustry"
                  component={TextInput}
                  label={t('otherIndustry')}
                  type="text"
                  margin="normal"
                  helperText={t('otherIndustryHelperText')}
                  className="extra-industry"
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </form>
          <div style={styles.submitButton}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              style={styles.btnStyle}
              disabled={submitting || invalid}
              onClick={this.handleSubmit}
              className="medium-btn blue-btn"
            >
              {t('saveAndFinish')}
            </Button>
          </div>
        </div>

      </Container>
    );
  }
}

const styles = {

  container: {
    width: '100%',
    maxWidth: 800,
    margin: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  submitButton: {
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 50,
    marginBottom: 20,
  },
  btnStyle: {
    textTransform: 'none',
    alignSelf: 'center',
  }

};

const mapStateToProps = state => {
  return ({
    companyInfo: state.form.detailsForm && state.form.detailsForm.values,
    companySizeOptions: state.config.configs.companySizes.map(item => {
      return {
        value: item.id,
        label: item.name
      };
    }),
    industryOptions: state.config.configs.industries.map(item => {
      return {
        value: item.id,
        label: item.name
      };
    }),
    leadSourceOptions: state.config.configs.leadSources.map(item => {
      return {
        value: item.id,
        label: item.name
      };
    })
  });
};

const mapDispatchToProps = (dispatch) => ({
  companyDetailsUpdate: (companyInfo) => dispatch(companyDetailsUpdate(companyInfo)),
  getCompanyDetails: () => dispatch(getCompanyDetails()),
  initForm: (data) => { dispatch(initialize('detailsForm', data)); },
});

CompanyDetails = reduxForm({ form: 'detailsForm', validate })(CompanyDetails);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);

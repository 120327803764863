import React, { useEffect } from 'react';
// Redux
import {
  Card,
  Grid,
  Typography,
  Button,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Field, FieldArray, change, formValueSelector } from 'redux-form';
import { TextInput } from 'components/inputs';
import { connect } from 'react-redux';
import { getProducts } from 'redux/product';
import { Portlet } from 'pages/Dashboard/components';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import deleteSrc from 'assets/images/delete.svg';
import styles from './styles/serviceReport.module.scss';
import { ServiceProductInput } from '../inputs';
import { SERVICE_REPORT_FORM_NAME } from 'common/constant';

const serviceReportFormValue = formValueSelector(SERVICE_REPORT_FORM_NAME);
const defaultServiceProvide = {
  name: '',
  description: '',
  quantity: 1,
  unitPrice: '0.00',
  total: '0.00',
};

const renderServiceProvide = ({
  fields,
  translate,
  groupedOptions,
  currency,
}) => {
  return fields.map((field, index) => {
    return (
      <Grid container wrap="nowrap" key={index}>
        <Grid item className={`${styles.serviceNo} pt_10`}>
          <Typography>#{index + 1}</Typography>
        </Grid>
        <Grid item className={styles.provideProduct}>
          <Field
            name={`${field}.name`}
            label={translate('title')}
            component={ServiceProductInput}
            variant="outlined"
            className={styles.productName}
            index={index}
            translate={translate}
            margin="dense"
            formName={SERVICE_REPORT_FORM_NAME}
            options={groupedOptions}
          />
        </Grid>
        <Grid item className={styles.descriptionStyle}>
          <Field
            name={`${field}.description`}
            label={translate('description')}
            component={TextInput}
            variant="outlined"
            margin="dense"
            multiline
            row="2"
          />
        </Grid>
        <Grid item className={styles.quantity}>
          <Field
            name={`${field}.quantity`}
            component={TextInput}
            variant="outlined"
            margin="dense"
            type="number"
          />
        </Grid>
        <Grid item className={styles.unitPrice}>
          <Field
            name={`${field}.unitPrice`}
            component={TextInput}
            variant="outlined"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
              inputProps: { min: 0, step: '.01' },
            }}
          />
        </Grid>
        <Grid item className={styles.w_10}>
          <Field
            name={`${field}.total`}
            component={TextInput}
            variant="outlined"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
              readOnly: true,
            }}
          />
        </Grid>
        {index !== 0 && (
          <Grid>
            <IconButton onClick={() => fields.remove(index)}>
              <img src={deleteSrc} alt="delete_icon" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  });
};

const ServiceProvideCard = ({
  translate,
  serviceProvide,
  changeField,
  products,
  services,
  getProductsDispatch,
  currency = '$'
}) => {
  const handleAddDeviceInfo = () => {
    changeField('products', [...serviceProvide, defaultServiceProvide]);
  };

  useEffect(() => {
    getProductsDispatch('products');
    getProductsDispatch('services');
  }, [getProductsDispatch]);

  const servicesArray = Object.keys(services).map((service) => {
    const item = services[service];
    const data = {};
    data.label = item.name;
    data.value = item;
    return data;
  });

  const productsArray = Object.keys(products).map((product) => {
    const item = products[product];
    const data = {};
    data.label = item.name;
    data.value = item;
    return data;
  });

  const groupedOptions = [
    {
      title: translate('Common:services'),
      suggestions: servicesArray,
    },
    {
      title: translate('Common:products'),
      suggestions: productsArray,
    },
  ];

  return (
    <Card className={`${styles.cardPadding} mt_25`} style={{ overflow: 'unset' }}>
      <Typography variant="h4" className="mb_20">
        {translate('serviceProvide')}
      </Typography>
      <Portlet className="p_20">
        <Grid container justify="space-between">
          <Typography variant="h5">{translate('serviceProvide')}</Typography>
          <Button onClick={handleAddDeviceInfo}>
            <AddCircleIcon className={styles.iconAdd} />
            <Typography className={styles.newIcon} variant="body1">
              {translate('Common:new')}
            </Typography>
          </Button>
        </Grid>
        <Grid container wrap="nowrap">
          <Grid item className={styles.serviceNo}>
            <Typography>{translate('itemNo')}</Typography>
          </Grid>
          <Grid item className={styles.provideProduct}>
            <Typography>{translate('title')}</Typography>
          </Grid>
          <Grid item className={styles.descriptionStyle}>
            <Typography>{translate('description')}</Typography>
          </Grid>
          <Grid item className={styles.quantity}>
            <Typography>{translate('qty')}</Typography>
          </Grid>
          <Grid item className={styles.unitPrice}>
            <Typography>{translate('unitPriceServiceReport')}</Typography>
          </Grid>
          <Grid item className={styles.unitPrice}>
            <Typography>{translate('total')}</Typography>
          </Grid>
        </Grid>
        <FieldArray
          name="products"
          component={renderServiceProvide}
          translate={translate}
          groupedOptions={groupedOptions}
          currency={currency}
        />
      </Portlet>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    serviceProvide: serviceReportFormValue(state, 'products'),
    products: state.product.products || [],
    services: state.product.services || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) =>
    dispatch(change(SERVICE_REPORT_FORM_NAME, field, value)),
  getProductsDispatch: (type) => {
    dispatch(getProducts(type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvideCard);

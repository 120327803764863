import React, { Component } from 'react';

import { Table, Button, TableBody, TableCell, TableHead, TableRow, Paper, withStyles, Grid, Typography } from '@material-ui/core';
import {
  Portlet,
  PortletContent,
} from 'pages/Dashboard/components';
import Countries from 'lib/countryList';
import PhoneNumberFormat from 'lib/phoneNumberFormat';
import { withTeamStatusHook } from 'pages/Dashboard/helpers/getTeamStatus';
import { compose } from 'redux';

const styles = theme => ({
  root: {},
  cardHeader: {
    backgroundColor: theme.palette.background.muted2
  },
  portlet: {
    border: 'none'
  },
  visitTableContent: {
    padding: 0
  },
  assignButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  emptyList: {
    textAlign: 'left',
    verticalAlign: 'middle',
  },
});

class JobTeam extends Component {

  render() {
    const { teams, classes, translate, handleCreateTeam } = this.props;

    return (
      <Portlet>
        <PortletContent noPadding>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{translate('Common:name')}</TableCell>
                  <TableCell align="left">{translate('Common:email')}</TableCell>
                  <TableCell align="left">{translate('Common:phone')}</TableCell>
                  <TableCell align="left">{translate('Common:address')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams && teams.length > 0 ?
                  teams.map((row, index) => {
                    return <TableRow key={index}>
                      <TableCell align="left">
                        {row.fullName}
                        {this.props.getStatus(row.deleted, row.archived)}
                      </TableCell>
                      <TableCell style={{ minWidth: 150 }} align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.countryPhoneCode} {PhoneNumberFormat(row.phoneNumber)} </TableCell>
                      <TableCell style={{ minWidth: 150 }} align="left">
                        <div className="address"> {row.address}</div>
                        <div className="address">
                          {
                            [ row.city,
                              row.state,
                              row.zipCode,
                              new Countries().countryName(row.countryCode)
                            ].filter(e => e)
                              .join(', ')
                          }
                        </div>
                      </TableCell>
                    </TableRow>;
                  })
                  : <TableRow
                      className={classes.rowEmpty}
                  >
                    <TableCell colSpan={5} className={classes.emptyList}>
                      <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Typography>
                          No team assigned.
                        </Typography>
                        <Button color="primary" className={classes.scheduleJobButton} onClick={handleCreateTeam}>
                          Assign a team now!
                        </Button>
                      </Grid>
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </Paper>
        </PortletContent>
      </Portlet>
    );
  }
}

export default
compose(
  withStyles(styles),
  withTeamStatusHook
)(JobTeam);

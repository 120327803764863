import { setIsLoading } from 'redux/global';
import { POST, GET, PATCH } from 'lib/api';

const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT';
const SET_CLEAR_CLIENT = 'SET_CLEAR_CLIENT';
const CLIENT_ROUTE = '/v2/clients';

const initialState = {
  client: {}
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_CLIENT:
      return {
        ...state,
        client: {
          ...state.client,
          ...action.details
        }
      };

    case SET_CLEAR_CLIENT:
      return {
        ...state,
        client: {}
      };

    default: return state;
  }
}

// Action Creators
export function setCurrentClient(details) {
  return { type: SET_CURRENT_CLIENT, details };
}

export function clearClient() {
  return { type: SET_CLEAR_CLIENT };
}

export function saveClientDetails(clientDetails, callback) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    dispatch(setIsLoading(true));
    POST(CLIENT_ROUTE, accessToken, { data: clientDetails }).then((response) => {
      dispatch(setIsLoading(false));
      !!callback && callback(response);
    }, (error) => {
      dispatch(setIsLoading(false));
      callback(error.response);
    }).catch(err => {
      callback(err);
      dispatch(setIsLoading(false));
    });
  };
}

export function updateClientDetails(clientDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    dispatch(setIsLoading(true));
    return PATCH(`${CLIENT_ROUTE}/${clientDetails.id}`, accessToken, { data: clientDetails })
      .then((response) => {
        dispatch(setIsLoading(false));
        return Promise.resolve(response);
      }, (error) => {
        dispatch(setIsLoading(false));
        return Promise.reject(error.response);
      }).catch(err => {
        dispatch(setIsLoading(false));
        return Promise.reject(err);
      });
  };
}

export function getClients(options = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const queryParams = {};

    if (options.includingArchived) {
      queryParams['includingArchived'] = options.includingArchived;
    }

    if (options.limit) {
      queryParams['$limit'] = options.limit;
    }

    if (options.skip) {
      queryParams['$skip'] = options.skip;
    }

    if (options.searchString) {
      queryParams['$q'] = options.searchString;
    }

    if (options.sort && options.sort.field) {
      queryParams[`$sort[${options.sort.field}]`] = options.sort.orderDirection === 'asc' ? 1 : -1;
    }

    if (options.csv) {
      queryParams['csv'] = options.csv;
    }

    if (options.scope) {
      queryParams['$scope'] = options.scope;
    }

    const params = { params: queryParams };

    dispatch(setIsLoading(true));

    return GET(CLIENT_ROUTE, accessToken, params).then((response) => {
      dispatch(setIsLoading(false));
      return Promise.resolve(response);

    },(error) => {
      dispatch(setIsLoading(false));
      return Promise.reject(error.response);

    }).catch(err => {
      dispatch(setIsLoading(false));
      return Promise.reject(err);
    });
  };
}

export function getClientDetails(clientId) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return GET(`${CLIENT_ROUTE}/${clientId}`, accessToken)
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

export function uploadClient(formData) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const params = { type: 'CLIENT_IMPORT' };
    const data = { params, data: formData, config: { headers: {
      'content-type': 'multipart/form-data'
    } } };

    return POST('/upload', accessToken, data).then(response => {
      return Promise.resolve(response);
    }, error => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  Grid,
} from '@material-ui/core';
import styles from './styles.module.scss';
import { StatusChip } from 'pages/Dashboard/components';
import moment from 'moment';
import { setVisitIdToGenerateReport } from 'redux/job';
import { useDispatch } from 'react-redux';

const ServiceReportModal = (props) => {
  const dispatch = useDispatch();
  const { pushToReportPage, translate, openState, handleCloseModal, fetchVisitList } = props;
  const [visits, setVisits] = useState([]);
  const [selectedVisit, setSelectedVisit] = useState('');


  const handleClickVisit = (visit) => {
    setSelectedVisit(visit);
  };

  useEffect(() => {
    if (!openState) return;

    const fetchVisits = async() => {
      const result = await fetchVisitList();
      setVisits(result);
    };

    fetchVisits();
  }, [openState]);

  const handleClickCreate = () => {
    dispatch(setVisitIdToGenerateReport(selectedVisit));
    pushToReportPage();
  };

  return (
    <Dialog
      classes={{ paper: styles.visitDialog }}
      aria-labelledby="customized-dialog-title"
      open={openState}
    >
      <Typography className={styles.title} variant="h4">
        {translate('selectJob')}
      </Typography>
      <DialogContent className={styles.dialogContent}>
        <List>
          {visits &&
            visits.map((visit, key) => (
              <ListItem
                onClick={() => handleClickVisit(visit)}
                key={key}
                className={`${styles.row} ${
                  selectedVisit.id === visit.id ? styles.selectedRow : null
                }`}
              >
                <Grid>
                  <Typography className={styles.inheritColor} variant="h5">
                    {visit.title}
                  </Typography>
                  <Typography className={styles.inheritColor}>
                    {moment(visit.start).format('HH:mm, MMM DD, YYYY')}
                  </Typography>
                </Grid>
                <Grid>
                  <StatusChip job status={visit.statusId} />
                </Grid>
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions disableSpacing className={styles.action}>
        <Button onClick={handleCloseModal} className="mr_10">
          {translate('Common:cancel')}
        </Button>
        <Button
          disabled={(visits && visits.length === 0) || !selectedVisit}
          color="secondary"
          variant="contained"
          onClick={handleClickCreate}
        >
          {translate('Common:create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceReportModal;

import React, { useEffect, useState } from 'react';

// redux
import { getStaff } from 'redux/staff';
import { useDispatch } from 'react-redux';
import { showErrorMessage } from 'lib/notifier';

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  IconButton,
} from '@material-ui/core';
import UserDetailsForm from '../form/userDetails';
import { Close as CloseIcon } from '@material-ui/icons';
import editSrc from 'assets/images/enterprise/edit.svg';

import styles from '../../details.module.scss';

const UserDetails = ({
  t,
  openUserDetailsModal,
  handleClose,
  handleOpenEditUserDetail,
  selectedStaff = {},
  companyDetails = {}
}) => {
  const dispatch = useDispatch();
  const handleEdit = () => handleOpenEditUserDetail();

  const [staffDetails, setStaffDetails] = useState({});

  useEffect(() => {
    if (!selectedStaff.id) return;
    (async () => {
      const response = await dispatch(
        getStaff(selectedStaff.id, { enterpriseAccountId: companyDetails.id })
      ).catch((e) => ({ e }));
      if (response.e) return showErrorMessage(response.e);
      setStaffDetails(response.data);
    })();
  }, [selectedStaff]);

  return (
    <Dialog
      open={openUserDetailsModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: styles.dialogClass }}
    >
      <DialogTitle className={styles.borderBottom}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h4">{t('userInfo')}</Typography>
          <Box display="flex">
            <IconButton className="mr_10" onClick={handleEdit}>
              <img src={editSrc} alt="editSrc" />
            </IconButton>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </DialogTitle>
      <DialogContent className={styles.detailsContent}>
        <Box display="flex" flexDirection="row">
          <UserDetailsForm t={t} userDetails={staffDetails} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UserDetails;

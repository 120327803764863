/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import { getMedia } from 'redux/enterprise';
import { useDispatch } from 'react-redux';
import { MEDIA_TYPES } from 'common/constant';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import { Grid, Box, Icon } from '@material-ui/core';
import styles from './media.module.scss';

import checkedSrc from 'assets/images/icons/checked.svg';

import MediaDetailsModal from './MediaDetails';

const RenderMedia = (props) => {
  const {
    isSelectFile,
    handleSelectFile,
    jobPublicId,
    mediaTypes,
    selectedPhrase,
    setIsSelectAllMedia,
    setSelectedFiles,
    selectedFiles = [],
    selectedTags = [],
    selectedVisitIds = [],
    isSelectAllMedia = false,
    translate,
  } = props;

  const dispatch = useDispatch();
  const [medias, setMedias] = useState([]);
  const [isOpenMediaDetails, setIsOpenMediaDetails] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});

  useEffect(() => {
    if (!jobPublicId) return;
    const tagIds =
      (Array.isArray(selectedTags) && selectedTags.map((tag) => tag.id)) || [];
    (async () => {
      const params = {
        mediaTypes,
        mediaPhase: selectedPhrase,
        jobId: jobPublicId,
        tagIds,
        visitIds: (Array.isArray(selectedVisitIds) && selectedVisitIds) || '',
      };
      const { data } = await dispatch(getMedia({ params }));
      setMedias(data);
    })();
  }, [jobPublicId, selectedPhrase, mediaTypes, selectedTags, selectedVisitIds]);

  useEffect(() => {
    // button select all is outside of this component, so that have to base on useEffect
    if (isSelectAllMedia) {
      setSelectedFiles(medias.map((item) => item.id));
      setIsSelectAllMedia(false);
    }
  }, [isSelectAllMedia, medias]);

  const handleSelect = (media) => {
    if (!isSelectFile) {
      setSelectedMedia(media);
      return setIsOpenMediaDetails(true);
    }
    handleSelectFile && handleSelectFile(media);
  };

  const renderImg = (media) => {
    if (media.mediaType !== MEDIA_TYPES.PHOTO.toString()) return null;
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    return (
      <img alt="media" src={media.mediaUrl} className={styles.img_render} />
    );
  };

  const renderFile = (media) => {
    if (media.mediaType !== MEDIA_TYPES.FILE.toString()) return null;
    return (
      <Grid
        container
        item
        direction="column"
        justify="center"
        alignItems="center"
        className={styles.container}
      >
        <Grid item>
          <Icon style={{ fontSize: 30, height: '100%' }}>
            <img
              alt="media_file"
              // eslint-disable-next-line global-require
              src={require('assets/icons/file.svg')}
            />
          </Icon>
        </Grid>
        <Grid item>{media.fileName || 'Untitled'}</Grid>
      </Grid>
    );
  };

  const renderVideo = (media) => {
    if (media.mediaType !== MEDIA_TYPES.VIDEO.toString()) return null;
    return (
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        className={styles.video_container}
      >
        <Grid className={styles.video_wrapper} />
        <PlayCircleOutlineIcon
          style={{
            color: 'white',
            fontSize: '80',
            position: 'absolute',
          }}
        />
        <video
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        >
          <source src={media.mediaUrl} type="video/mp4" />
        </video>
      </Grid>
    );
  };

  return (
    <>
      {isOpenMediaDetails && (
        <MediaDetailsModal
          translate={translate}
          changeModalState={setIsOpenMediaDetails}
          currentMediaSelected={selectedMedia}
          jobPublicId={jobPublicId}
          open={isOpenMediaDetails}
        />
      )}
      <Box display="flex" flexWrap="wrap">
        {medias.map((media, index) => {
          const isSelectedMedia = selectedFiles.includes(media.id);
          return (
            <Grid
              style={{ cursor: 'pointer' }}
              key={index}
              onClick={() => handleSelect(media)}
              className={`${styles.itemImg} ${
                isSelectedMedia && styles.selectedBorder
              } ${media.mediaType === MEDIA_TYPES.FILE && styles.container}`}
            >
              {isSelectedMedia && (
                <Grid className={styles.absolute_checked}>
                  <img src={checkedSrc} alt="checkedSrc" />
                </Grid>
              )}
              {renderImg(media)}
              {renderFile(media)}
              {renderVideo(media)}
            </Grid>
          );
        })}
      </Box>
    </>
  );
};

export default RenderMedia;

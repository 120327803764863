export default (theme) => ({
  chip: {
    fontWeight: 600,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 4,
    backgroundColor: '#D3E5FF',
    color: '#205BCD',
    minWidth: 150,
  },
  invoiceChip: {
    backgroundColor: theme.palette.chip.invoice.light,
    color: theme.palette.chip.invoice.dark,
    borderRadius: 4,
  },
  lateVisit: {
    backgroundColor: theme.palette.chip.lateVisit.wm_bg,
    color: theme.palette.chip.lateVisit.wm_color,
    borderRadius: 4,
  },
  unscheduled: {
    backgroundColor: theme.palette.chip.unscheduled.wm_bg,
    color: theme.palette.chip.unscheduled.text,
    borderRadius: 4,
  },
  unassigned: {
    backgroundColor: theme.palette.chip.unassigned.wm_bg,
    color: theme.palette.chip.unassigned.text,
    borderRadius: 4,
  },
  onStandby: {
    backgroundColor: theme.palette.chip.onStandby.wm_bg,
    color: theme.palette.chip.onStandby.wm_color,
    borderRadius: 4,
  },
  onTheWay: {
    backgroundColor: theme.palette.invoice.onTheWay.wm_bg,
    color: theme.palette.invoice.onTheWay.wm_color,
    borderRadius: 4,
  },
  arrived: {
    backgroundColor: theme.palette.chip.arrived.wm_bg,
    color: theme.palette.chip.arrived.wm_color,
    borderRadius: 4,
  },
  started: {
    backgroundColor: theme.palette.chip.started.wm_bg,
    color: theme.palette.chip.started.wm_color,
    borderRadius: 4,
  },
  completed: {
    backgroundColor: theme.palette.chip.completed.wm_bg,
    color: theme.palette.chip.completed.wm_color,
    borderRadius: 4,
  },
  abandoned: {
    backgroundColor: theme.palette.chip.abandoned.wm_bg,
    color: theme.palette.chip.abandoned.wm_color,
    borderRadius: 4,
  },
  cancelled: {
    backgroundColor: theme.palette.chip.cancelled.wm_bg,
    color: theme.palette.chip.cancelled.wm_color,
    borderRadius: 4,
  },
  archived: {
    backgroundColor: theme.palette.invoice.archived.wm_bg,
    color: theme.palette.invoice.archived.wm_color,
    borderRadius: 4,
  },
  draft: {
    border: '1px solid #666666',
    backgroundColor: 'transparent',
    color: '#666666',
    borderRadius: 4,
  },
  sent: {
    backgroundColor: theme.palette.chip.started.dark,
    color: theme.palette.text.primary,
    borderRadius: 4,
  },
  paid: {
    backgroundColor: theme.palette.invoice.paid.wm_bg,
    color: theme.palette.invoice.paid.wm_color,
    borderRadius: 4,
  },
  badDebt: {
    backgroundColor: theme.palette.invoice.badDebt.wm_bg,
    color: theme.palette.invoice.badDebt.wm_color,
    borderRadius: 4,
  },
  accepted: {
    backgroundColor: theme.palette.chip.accepted.dark,
    color: theme.palette.text.white,
    borderRadius: 4,
  },
  awaitingPayment: {
    backgroundColor: theme.palette.invoice.awaitingPayment.wm_bg,
    color: theme.palette.invoice.awaitingPayment.wm_color,
    borderRadius: 4,
  },
  awaitingApproval: {
    backgroundColor: theme.palette.invoice.awaitingApproval.wm_bg,
    color: theme.palette.invoice.awaitingApproval.wm_color,
    borderRadius: 4,
  },
  converted: {
    backgroundColor: theme.palette.invoice.converted.wm_bg,
    color: theme.palette.invoice.converted.wm_color,
    borderRadius: 4,
  },
  changesRequested: {
    backgroundColor: theme.palette.invoice.changesRequested.wm_bg,
    color: theme.palette.invoice.changesRequested.wm_color,
    borderRadius: 4,
  },
  selectionProps: {
    borderTopRightRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
  },
  removeStyle: {
    borderTopLeftRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
  },
  reviewStyle: {
    color: '#0075FE',
    fontSize: '14px',
    margin: '3px 4px'
  },
  reviewed: {
    padding: '0px',
    borderRadius: '3px 10px 10px 3px',
    '& span':{
      paddingRight: 0,
    }
  },
  aboutJob:{
    minWidth: '90px',
    padding: 0,
    fontSize: '12px',
  },
});

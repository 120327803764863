import React from 'react';

// Material components
// Material helpers
import { Grid, Typography, withStyles, Box } from '@material-ui/core';

import { Link } from 'react-router-dom';

import {
  // ContactDetails,
  AddressDetails,
} from 'pages/Dashboard/components';

// Component styles
import { JobClientDetailsStyle } from './styles';
import { JobClientNameHeader, DetailsTable } from '../utils';

function JobClientDetailsCard(props) {
  const {
    allowedStatuses,
    classes,
    job,
    jobStatuses,
    translate,
    handleClickStatus,
    handleClickArchive,
  } = props;

  return (
    <Grid container>
      {job && Object.keys(job).length > 0 && (
        <>
          <Grid item container direction="row">
            <JobClientNameHeader
              job={job}
              jobStatuses={jobStatuses}
              translate={translate}
              handleClickStatus={handleClickStatus}
              handleClickArchive={handleClickArchive}
              allowedStatuses={allowedStatuses}
            />
          </Grid>
          {/* // Job details box */}
          <Grid container direction="row" justify="space-between">
            <Grid
              item
              container
              className={classes.borderContainer}
              spacing={1}
              xs={5}
            >
              <Grid item container direction="row">
                <DetailsTable
                  jobArchived={job.archived}
                  jobId={job.id}
                  jobStatus={job.statusId}
                  jobType="oneOff"
                  jobStart={job.jobSchedule && job.jobSchedule.start}
                  jobEnd={job.jobSchedule && job.jobSchedule.end}
                  jobBillingFrequency={undefined}
                  translate={translate}
                  categories={job.categories}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={7}
              spacing={1}
              className={classes.borderContainer}
            >
              <Grid item container direction="column">
                <Box mb={1}>
                  <Typography variant="h3" className={classes.jobDetailsTitle}>
                    {translate('Common:clientDetails')}
                  </Typography>
                </Box>
                <Grid item container justify="space-between" direction="row">
                  <Grid item md={3}>
                    {job && job.client && (
                      <Grid item md={12}>
                        <Typography className={classes.titleColor} variant="body1">
                          {translate('Common:name')}
                        </Typography>
                        <Link to={`/clients/${job.client.id}`}>
                          <Typography
                            variant="h6"
                            className={classes.clientName}
                          >
                            {job.client.displayName ||
                              translate('Client:clientHasNoName')}
                          </Typography>
                        </Link>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item md={5}>
                    <AddressDetails
                      titleStyle={{
                        fontWeight: 400,
                        marginBottom: 0,
                        color: 'rgba(0, 0, 0, 0.5)',
                        fontSize: 14
                      }}
                      textStyle={{ color: 'black' }}
                      address={job.property}
                      translate={translate}
                      title={translate('Common:propertyAddress')}
                    />
                  </Grid>
                  <Grid item md={4} style={{ paddingLeft: 8 }}>
                    {job.client && job.client.email ? (
                      <Grid item md={12} className={classes.emailContainer}>
                        <Typography
                          className={classes.titleColor}
                          ariant="body2"
                        >
                          {translate('Common:email')}
                        </Typography>
                        <Typography className={classes.textDetails}>
                          {job.client.email}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item className={classes.emailContainer}>
                        <Typography className={classes.noContacts}>
                          {translate('Common:noEmailRecorded')}
                        </Typography>
                      </Grid>
                    )}
                    {job.client && job.client.phoneNumber && (
                      <Grid item md={12}>
                        <Typography
                          className={classes.titleColor}
                          ariant="body2"
                        >
                          {translate('Common:phoneNumber')}
                        </Typography>
                        <Typography className={classes.textDetails}>
                          {job.client.phoneNumber}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default withStyles(JobClientDetailsStyle)(JobClientDetailsCard);

import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  Grid,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import TextInput from "components/inputs/TextInput";
import styles from "./resetPwdForm.module.scss";
import MediaQuery from "react-responsive";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Logo from "../../../../components/logo";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const imageBg = require("assets/images/office.jpg");
const imageBgMb = require("assets/images/office.jpg");

const mapStateToProps = (state) => ({
  initialValues: {
    password: null,
    confirmPassword: null,
  },
});

const validate = (values) => {
  const errors = { message: {} };
  const requiredFields = ["confirmPassword", "password"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "required";
      errors.message[field] = `${field}Required`;
    }
  });
  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "passwordConfirmationNotMatch";
  }

  if (
    values.password &&
    !/^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?!.*\s).*$/.test(values.password)
  ) {
    errors.password = "hintPassword";
    errors.message.password = "hintPassword";
  }
  return errors;
};

let ResetPasswordPage = (props) => {
  const { pristine, invalid, submitting, handleSubmit, translate } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    React.useState(false);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleClickShowConfirmationPassword() {
    setShowConfirmationPassword(!showConfirmationPassword);
  }

  return (
    // <Container>
    <Grid
      item
      container
      wrap="wrap"
      justify="center"
      spacing={0}
      className={styles.resetPwdContainer}
    >
      <Grid item xs={11} md={6} className={styles.leftPane}>
        <Grid
          className={styles.formContainer}
          container
          alignItems="center"
          direction="column"
          justify="center"
        >
          <Grid className={styles.w_50}>
            <Logo />
            <Grid item className={styles.textAlignLeft}>
              <Typography className={styles.signInTitle}>
                {translate("resetPassword")}
              </Typography>
              <Typography className={styles.signInSubtitle}>
                {translate("enterNewPassword")}
              </Typography>
            </Grid>
            <Grid item className={styles.signInForm}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div>
                  <Field
                    name="password"
                    component={TextInput}
                    label={translate("Common:newPassword")}
                    margin="normal"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                            tabIndex={-1}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      inputProps: { minLength: 8 },
                    }}
                  />
                </div>
                <div>
                  <Field
                    name="confirmPassword"
                    component={TextInput}
                    label={translate("Common:confirmNewPassword")}
                    margin="normal"
                    type={showConfirmationPassword ? "text" : "password"}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowConfirmationPassword}
                            tabIndex={-1}
                          >
                            {showConfirmationPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      inputProps: { minLength: 8 },
                    }}
                  />
                </div>
                <div className={styles.signInButton}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={pristine || submitting || invalid}
                    className={styles.btnStyle}
                  >
                    <span className={styles.btnLabel}>
                      {translate("Auth:savePassword")}
                    </span>
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MediaQuery query="(min-device-width: 768px)">
        <Grid item md={6} className={styles.rightPane}>
          <div className={styles.imageContainer}>
            <img
              src={imageBg}
              className={styles.cleanerImage}
              alt={translate("Common:WM")}
            />
          </div>
        </Grid>
      </MediaQuery>
      <MediaQuery query="(max-device-width: 768px)">
        <Grid item className={styles.rightPane}>
          <div className={styles.imageContainer}>
            <img
              src={imageBgMb}
              className={styles.cleanerImage}
              alt={translate("Common:WM")}
            />
          </div>
        </Grid>
      </MediaQuery>
    </Grid>
    // </Container>
  );
};

ResetPasswordPage = reduxForm({
  form: "resetPassword",
  validate,
})(ResetPasswordPage);

export default connect(mapStateToProps, null)(ResetPasswordPage);

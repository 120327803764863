export default theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  modalContent: {
    padding: theme.spacing(3),
  },
  clientDialogTitle: {
    // minWidth: 800,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  instructionText: {
    margin: theme.spacing(1),
  },
});

export default theme => ({
  root: {
  },
  dialogWrap: {
    minWidth: 300,
  },
  cardHeader: {
    textTransform: 'uppercase'
  },
  portletContent: {
    padding: 0
  },
  listItem: {
    padding: '0px 5px 0px 20px',
    borderBottom: `1px solid ${theme.palette.border}`,
    cursor: 'pointer',
    height: 44,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  listItemIcon: {
    justifyContent: 'flex-end'
  },
  itemText: {
    '$ .MuiListItemText-primary': {
      fontSize: 16
    }
  },
  arrowIcon: {
    fontSize: 44
  },
  noJobs: {
    padding: 20
  }
});
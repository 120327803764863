import React from 'react';
// Redux
import { Field, reduxForm } from 'redux-form';
// shared component
import { TextInput } from 'components/inputs';
// Material component
import { Grid, Button } from '@material-ui/core';

let PasswordForm = props => {
  const { translate, handleSubmit } = props;

  return (
    <div>
      <form className='form-body' onSubmit={handleSubmit}>
        <Grid item container spacing={2} direction='column' justify='flex-start'>
          <Grid item xs={3} sm={3} md={3}>
            <Field
              name='currentPassword'
              component={TextInput}
              margin='normal'
              label={translate('AccountSetting:currentPassword')}
              type="password"
              variant='outlined'
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3}>
            <Field
              name='password'
              component={TextInput}
              margin='normal'
              label={translate('AccountSetting:password')}
              type="password"
              variant='outlined'
            />
          </Grid>

          <Grid item xs={3} sm={3} md={3}>
            <Field
              name='confirmPassword'
              component={TextInput}
              margin='normal'
              label={translate('AccountSetting:repeatPassword')}
              type="password"
              variant='outlined'
            />
          </Grid>

          <Grid item xs={3} sm={3} md={3}>
            <Button
              variant='contained'
              color='secondary'
              type='submit'
            >
              {translate('AccountSetting:changePassword')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

PasswordForm = reduxForm({
  form: 'PasswordForm',
})(PasswordForm);

export default PasswordForm;
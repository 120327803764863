import React from 'react';
import { Field } from 'redux-form';
import {
  Button,
  Grid,
  Typography,
  Box,
  InputAdornment,
  Hidden,
} from '@material-ui/core';
import { Checkbox, TextInput } from 'components/inputs';
import { formatPrice } from 'lib/formatter';
import { ProductOnlyInput } from '.';
import styles from './styles/inventory.module.scss';

import addServiceIcon from 'assets/images/icons/blue_add_circle.svg';
import layerIcon from 'assets/images/icons/layers.svg';
import deleteIcon from 'assets/images/icons/u_trash.svg';

const renderSubtotal = ({ title, input, currency }) => {
  return (
    <Grid item sm={11} md={11}>
      <Grid container justify='flex-end' alignItems='center'>
        <Typography
          className='mr_5'
          style={{ fontWeight: 700, color: '#A2A2A2' }}
        >
          {title}:
        </Typography>
        <Typography className='font_bold'>
          {formatPrice(input.value, currency)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const InventoriesInput = ({
  fields,
  translate,
  currency,
  parentField,
  cardIndex,
  options,
}) => {
  const handleClickAddService = () => {
    fields.push({
      name: '',
      serialNumber: '',
      model: '',
      unitPrice: '0.00',
      quantity: 1,
      selfCollected: false,
    });
  };

  return (
    <>
      <Grid container alignItems='center' justify='space-between'>
        <Box display='flex' alignItems='center'>
          <img className='mr_5' src={layerIcon} alt='layerIcon' />
          <Typography variant='h5'>{translate('items')}</Typography>
        </Box>

        <Button onClick={handleClickAddService} className={styles.btn_add}>
          <img className='mr_5' src={addServiceIcon} alt='addServiceIcon' />
          {translate('addService')}
        </Button>
      </Grid>

      <Grid container className='mt_20' spacing={2}>
        <Grid item sm={4} md={4}>
          <Typography>{translate('itemName')}</Typography>
        </Grid>

        <Grid item sm={3} md={3}>
          <Typography>{translate('model')}</Typography>
        </Grid>

        <Grid item sm={2} md={2}>
          <Typography>{translate('serialNo')}</Typography>
        </Grid>

        <Hidden xsUp>
          <Grid item sm={0} md={0}>
            <Typography>{translate('unitPrice')}</Typography>
          </Grid>
        </Hidden>

        <Grid item sm={1} md={1}>
          <Typography>Quantity</Typography>
        </Grid>
        <Grid item sm={1} md={1}>
          <Typography>Self Collected</Typography>
        </Grid>

        <Hidden xsUp>
          <Grid item sm={0} md={0} container justify='flex-end'>
            <Typography>{translate('total')}</Typography>
          </Grid>
        </Hidden>
      </Grid>

      <Grid>
        {fields.map((member, index) => (
          <Grid container key={member} spacing={2}>
            <Grid item sm={4} md={4}>
              <Field
                name={`${member}.name`}
                placeholder={translate('Common:name')}
                component={ProductOnlyInput}
                variant='outlined'
                margin='dense'
                index={index}
                translate={translate}
                options={options}
                cardIndex={cardIndex}
              />
            </Grid>

            <Grid item sm={3} md={3}>
              <Field
                name={`${member}.model`}
                placeholder={translate('modelNumber')}
                component={TextInput}
                variant='outlined'
                margin='dense'
              />
            </Grid>

            <Grid item sm={2} md={2}>
              <Field
                name={`${member}.serialNumber`}
                placeholder={translate('serialNumber')}
                component={TextInput}
                variant='outlined'
                margin='dense'
              />{' '}
            </Grid>

            <Hidden xsUp>
              <Grid item sm={0} md={0}>
                <Field
                  name={`${member}.unitPrice`}
                  placeholder={translate('unitPrice')}
                  component={TextInput}
                  variant='outlined'
                  margin='dense'
                  type='number'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        {currency}
                      </InputAdornment>
                    ),
                    inputProps: { min: 0, step: '.01' },
                  }}
                />
              </Grid>
            </Hidden>

            <Grid item sm={1} md={1}>
              <Field
                name={`${member}.quantity`}
                placeholder='Qty'
                component={TextInput}
                variant='outlined'
                margin='dense'
                type='number'
              />
            </Grid>
            <Grid
              item
              container
              alignItems='center'
              justify='center'
              em
              sm={1}
              md={1}
            >
              <Field
                name={`${member}.selfCollected`}
                component={Checkbox}
                defaultValue={false}
              />
            </Grid>
            <Hidden xsUp>
              <Grid
                item
                sm={0}
                md={0}
                container
                alignItems='center'
                justify='flex-end'
              >
                <Field
                  name={`${member}.total`}
                  component={({ input }) => (
                    <Typography>
                      {formatPrice(input.value, currency)}
                    </Typography>
                  )}
                  variant='outlined'
                  margin='dense'
                />
              </Grid>
            </Hidden>

            {!!index && (
              <Grid item container alignItems='center' sm={1} md={1}>
                <Button onClick={() => fields.remove(index)}>
                  <img src={deleteIcon} alt='deleteIcon' />
                </Button>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>

      <Hidden xsUp>
        <Grid container className='mt_20'>
          <Grid item sm={8} md={8} />
          <Field
            name={`${parentField}.calculatedSubtotal`}
            component={renderSubtotal}
            title={translate('subtotal')}
            currency={currency}
          />
        </Grid>
      </Hidden>
    </>
  );
};

export default InventoriesInput;

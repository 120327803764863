import React, { useState } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Redux
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, change, formValueSelector } from 'redux-form';
// Material helpers
// Material components
import { Grid, FormLabel, withStyles } from '@material-ui/core';
// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  ImageCroppingModal,
  CategoryModal,
} from 'pages/Dashboard/components';

// Custom components
import { PermissionDetails } from '..';
import { StaffForm } from '../../forms';
// Component styles
import styles from './styles';
import { validate } from '../../utils';
import Countries from 'lib/countryList';

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

let StaffFormCard = (props) => {
  const {
    classes,
    className,
    location,
    handleSubmit,
    staff = {},
    translate,
    uploadProfilePictureAfterCropped,
    currentCategories
  } = props;

  const dispatch = useDispatch();
  const rootClassName = classNames(classes.root, className);
  const [croppingImageSrc, setCroppingImageSrc] = useState({});
  const [croppedImage, setCroppedImage] = useState(null);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  const croppedImageCallback = (resultCroppingImage) => {
    setCroppedImage(resultCroppingImage);
    if (uploadProfilePictureAfterCropped) {
      uploadProfilePictureAfterCropped(resultCroppingImage);
    }
    // If we are creating staff, so just add croppedImage to avatar to submit form
    if (!staff.id) dispatch(change('staffForm', 'avatar', resultCroppingImage));

    setCroppingImageSrc({});
  };

  const openCroppingImgModal = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageDataUrl = await readFile(file);
      setCroppingImageSrc({ file: imageDataUrl, open: true });
    }
  };

  const onCloseImageCroppingModal = () => {
    setCroppingImageSrc({});
  };

  const handleClickOnCategory = () => setOpenCategoryModal(true);

  const handleCloseSelectCategoryModal = () => setOpenCategoryModal(false);

  const handleCategorySelection = (data) => {
    dispatch(change('staffForm', 'categories', data));
    // convert to ids before submit
    if (Array.isArray(data) && data.length) {
      const categoryIds = data.map((category) => category.id);
      dispatch(change('staffForm', 'categoryIds', categoryIds));
    }
    handleCloseSelectCategoryModal(false);
  };

  return (
    <>
      {openCategoryModal && (
        <CategoryModal
          open={openCategoryModal}
          handleCloseSelectCategoryModal={handleCloseSelectCategoryModal}
          translate={translate}
          categories={currentCategories}
          handleCategorySelection={handleCategorySelection}
        />
      )}
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <FormLabel component="legend" className={classes.formLabel}>
          {translate('Common:compulsoryField')}
        </FormLabel>
        <Grid container spacing={4} justify="center">
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <Portlet className={rootClassName}>
              <PortletHeader>
                <PortletLabel title={translate('personalInfo')} />
              </PortletHeader>
              <PortletContent noPadding className={classes.formField}>
                <StaffForm
                  staffProfile={croppedImage}
                  translate={translate}
                  staff={staff}
                  openCroppingImgModal={openCroppingImgModal}
                  uploadProfilePictureAfterCropped={
                    uploadProfilePictureAfterCropped
                  }
                  handleClickOnCategory={handleClickOnCategory}
                />
              </PortletContent>
            </Portlet>
          </Grid>
          <Grid item lg={12} md={12} xl={9} xs={12}>
            <PermissionDetails
              currentRoute={location.pathname}
              translate={translate}
              staffId={(staff || {}).id}
            />
          </Grid>
        </Grid>
      </form>

      {croppingImageSrc.open && (
        <ImageCroppingModal
          open
          cropBtnText={
            staff.id ? translate('Staff:cropAndSave') : translate('Staff:crop')
          }
          translate={translate}
          image={croppingImageSrc.file}
          onClose={onCloseImageCroppingModal}
          croppedImageCallback={croppedImageCallback}
        />
      )}
    </>
  );
};

StaffFormCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const filterForm = formValueSelector('staffForm');

const mapStateToProps = (state, ownProps) => {
  const { staff = {} } = ownProps;
  return {
    initialValues: {
      avatar: staff.avatar || null,
      notes: staff.notes || null,
      firstName: staff.firstName || null,
      lastName: staff.lastName || null,
      genderId: staff.genderId || null,
      email: staff.email || null,
      phoneNumber: staff.phoneNumber || null,
      countryPhoneCode:
        staff.countryPhoneCode ||
        new Countries().getCountryPhoneCodeByCountryCode(
          (state.auth.currentUser.companies[0] || {}).countryCode
        ) ||
        (state.auth.currentUser.companies[0] || {}).countryPhoneCode ||
        state.auth.currentUser.countryPhoneCode ||
        null,
      reportingTo: staff.reportingTo || null,
      roleId: staff.roleId || null,
      address: staff.address || null,
      city: staff.city || null,
      state: staff.state || null,
      countryCode:
        staff.countryCode || state.auth.currentUser.countryCode || null,
      zipCode: staff.zipCode || null,
      categories: staff.categories || []
    },
    genderIds: state.config.configs.gender,
    currentCategories: filterForm(state, 'categories') || [],
  };
};

StaffFormCard = reduxForm({
  form: 'staffForm',
  validate,
  destroyOnUnmount: true,
})(StaffFormCard);

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  withStyles(styles)
)(StaffFormCard);

import { POST } from 'lib/api';
import { showErrorMessage } from 'lib/notifier';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS_REVIEW_JOB, REVIEW_BUTTON_STATUS, STATUS_CONFIRM_BUTTON, VISIT_STATUS } from './constants';
import {
  getIsJobReviewed,
  setConfirmReviewButton,
  setContentModal,
  setOpenConfirmReviewModal } from 'redux/jobVerification';
import { ALL_VISIT_STATUS } from 'common/constant';

export const getStatusButtonReview = ({ verified, startVerifiedAt }) => {
  if (verified) {
    return REVIEW_BUTTON_STATUS.REVIEWED;
  }
  if (startVerifiedAt) {
    return REVIEW_BUTTON_STATUS.IN_REVIEW;
  }
  return REVIEW_BUTTON_STATUS.NOT_STARTED;
};

export const useReviewJobApi = () => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const verifiedReviewJob = (data) => {
    return POST('/job-verify', accessToken, { data }).catch((e) => {
      showErrorMessage(e);
      return { e };
    });
  };
  const startReviewJob = (data) => {
    const newData = { ...data, action: ACTIONS_REVIEW_JOB.START_JOB_REVIEW };
    return verifiedReviewJob(newData);
  };
  const completeReviewJob = (data) => {
    const newData = { ...data, action: ACTIONS_REVIEW_JOB.MARK_REVIEW_AS_COMPLETED };
    return verifiedReviewJob(newData);
  };
  const cancelReviewJob = (data) => {
    const newData = { ...data, action: ACTIONS_REVIEW_JOB.MARK_REVIEW_AS_CANCELLED };
    return verifiedReviewJob(newData);
  };
  const completeCurrentVisit = (data) => {
    const newData = { ...data, action: ACTIONS_REVIEW_JOB.COMPLETED_AND_DELETED_PENDING_VISITS };
    return verifiedReviewJob(newData);
  };
  const cancelCurrentVisit = (data) => {
    const newData = { ...data, action: ACTIONS_REVIEW_JOB.CANCELLED_AND_DELETED_PENDING_VISITS };
    return verifiedReviewJob(newData);
  };
  const completeAndDeleteAllVisits = (data) => {
    const newData = { ...data, action: ACTIONS_REVIEW_JOB.COMPLETED_AND_DELETED_ALL_VISITS };
    return verifiedReviewJob(newData);
  };
  const cancelAndDeleteAllVisits = (data) => {
    const newData = { ...data, action: ACTIONS_REVIEW_JOB.CANCELLED_AND_DELETED_ALL_VISITS };
    return verifiedReviewJob(newData);
  };
  const openAndCreateVisit = (data) => {
    const newData = { ...data, action: ACTIONS_REVIEW_JOB.OPEN_JOB };
    return verifiedReviewJob(newData);
  };
  const openJobOnly = (data) => {
    const newData = { ...data, action: ACTIONS_REVIEW_JOB.OPEN_JOB };
    return verifiedReviewJob(newData);
  };

  const createVisitOnly = (data) => {
    const newData = { ...data, action: ACTIONS_REVIEW_JOB.CREATE_VISIT_ONLY };
    return verifiedReviewJob(newData);
  };

  return {
    startReviewJob,
    completeReviewJob,
    cancelReviewJob,
    completeCurrentVisit,
    cancelCurrentVisit,
    completeAndDeleteAllVisits,
    cancelAndDeleteAllVisits,
    openAndCreateVisit,
    openJobOnly,
    createVisitOnly
  };
};

export const useHandleReviewJob = ({ openModal, closeMenu,
  refetchJobDetail, afterSaveVisitDetails, withLoading }) => {
  const dispatch = useDispatch();
  const { completeReviewJob, cancelReviewJob } = useReviewJobApi();
  const visitDoneStatus = [ ALL_VISIT_STATUS.COMPLETED,
    ALL_VISIT_STATUS.CANCELLED];

  const completedReviewConfirm = ({ hasPendingVisit, currentVisitStatus, jobId }) => {
    if (hasPendingVisit) {
      dispatch(setContentModal(VISIT_STATUS.COMPLETED_CLEAN_VISIT));
      dispatch(setConfirmReviewButton(STATUS_CONFIRM_BUTTON.COMPLETED_CURRENT_VISIT));
      openModal();
    } else if (visitDoneStatus.includes(currentVisitStatus)) {
      withLoading(completeReviewJob({ jobId }).then(() => {
        refetchJobDetail();
        afterSaveVisitDetails();
      }));
    } else {
      dispatch(setContentModal(VISIT_STATUS.COMPLETED));
      dispatch(setConfirmReviewButton(STATUS_CONFIRM_BUTTON.COMPLETED));
      openModal();
    }
    closeMenu();
  };
  const cancelReviewConfirm = async ({ hasPendingVisit, currentVisitStatus, jobId }) => {
    if (hasPendingVisit) {
      dispatch(setContentModal(VISIT_STATUS.CANCELED_CLEAN_VISIT));
      dispatch(setConfirmReviewButton(STATUS_CONFIRM_BUTTON.CANCELED_CURRENT_VISIT));
      openModal();
    } else if (visitDoneStatus.includes(currentVisitStatus)) {
      withLoading(cancelReviewJob({ jobId }).then(() => {
        refetchJobDetail();
        afterSaveVisitDetails();
      }));
    } else {
      dispatch(setContentModal(VISIT_STATUS.CANCELED));
      dispatch(setConfirmReviewButton(STATUS_CONFIRM_BUTTON.CANCEL));
      openModal();
    }
    closeMenu();
  };

  return { completedReviewConfirm, cancelReviewConfirm };
};

export const useCheckJobReviewed = () => {
  const isJobReviewed = useSelector(getIsJobReviewed);
  const dispatch = useDispatch();

  const handleCheckJobReviewed = (callback) => {
    if (isJobReviewed) {
      dispatch(setContentModal(VISIT_STATUS.OPEN_JOB_ONLY));
      dispatch(setOpenConfirmReviewModal(true));
    } else {
      callback();
    }
  };
  return { handleCheckJobReviewed };
};
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Grid, Box } from '@material-ui/core';

import styles from './styles.module.scss';
import { formatPrice } from 'lib/formatter';

import layerIcon from 'assets/images/icons/layers.svg';
import gridIcon from 'assets/images/icons/grid.svg';

const ServiceCard = (props) => {
  const { translate, itemDetails } = props;
  const currency = useSelector(
    (state) => state.auth.currentUser.companies[0].currency
  );

  let total = 0;
  Array.isArray(itemDetails) &&
    itemDetails.forEach((item) => {
      const subTotal =
        parseFloat(item.total) +
        (item.inventories || []).reduce((prev, currentValue) => {
          if (!currentValue.total) return prev;

          return prev + parseFloat(currentValue.total);
        }, 0);
      total += subTotal;
    });
  return (
    <Box mt={3}>
      {Array.isArray(itemDetails) &&
        itemDetails.map((item, index) => {
          const subTotal =
            parseFloat(item.total) +
            (item.inventories || []).reduce((prev, currentValue) => {
              if (!currentValue.total) return prev;
              return parseFloat(prev) + parseFloat(currentValue.total);
            }, 0);
          return (
            <Grid
              key={`${item.jobId}_${index}`}
              container
              className={styles.card_wrapper}
            >
              <Grid item container className={styles.content}>
                <Grid item container>
                  <img className='mr_5' src={gridIcon} alt='grid_icon' />
                  <Typography className={`font_bold ${styles.title_color}`}>
                    {translate('service')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={styles.title} spacing={2}>
                <Grid item sm={4} md={4}>
                  <Typography>{translate('name')}</Typography>
                </Grid>

                <Grid item sm={7} md={7}>
                  <Typography>{translate('description')}</Typography>
                </Grid>

                {/* <Grid item sm={2} md={2}>
                  <Typography>{translate('unitPrice')}</Typography>
                </Grid> */}

                <Grid item sm={1} md={1}>
                  <Typography>Quantity</Typography>
                </Grid>

                {/* <Grid item sm={2} md={2} container justify="flex-end">
                  <Typography>{translate('total')}</Typography>
                </Grid> */}
              </Grid>

              <Grid container className={styles.table_content} spacing={2}>
                <Grid item sm={4} md={4}>
                  <Typography>{item.name}</Typography>
                </Grid>

                <Grid item sm={7} md={7}>
                  <Typography>{item.description}</Typography>
                </Grid>

                {/* <Grid item sm={2} md={2}>
                  <Typography>
                    {formatPrice(item.unitPrice, item.currency)}
                  </Typography>
                </Grid> */}

                <Grid item sm={1} md={1}>
                  <Typography>{item.quantity}</Typography>
                </Grid>

                {/* <Grid item sm={2} md={2} container justify='flex-end'>
                  <Typography>
                    {formatPrice(item.total, item.currency)}
                  </Typography>
                </Grid> */}
              </Grid>

              <Grid
                container
                className={`${styles.content} ${styles.border_top}`}
              >
                <Grid item container>
                  <img className='mr_5' src={layerIcon} alt='layer_icon' />
                  <Typography className={`font_bold ${styles.title_color}`}>
                    {translate('items')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={styles.title} spacing={2}>
                <Grid item sm={4} md={4}>
                  <Typography>{translate('name')}</Typography>
                </Grid>
                <Grid item sm={3} md={3}>
                  <Typography>{translate('model')}</Typography>
                </Grid>
                <Grid item sm={2} md={2}>
                  <Typography>{translate('serialNo')}</Typography>
                </Grid>
                <Grid item sm={1} md={1}>
                  <Typography>Quantity</Typography>
                </Grid>
                <Grid item sm={2} md={2}>
                  <Typography>Self Collected</Typography>
                </Grid>
              </Grid>

              {Array.isArray(item.inventories) &&
                item.inventories.map((inventory, number) => (
                  <Grid
                    container
                    key={`${inventory.name}_${number}`}
                    className={styles.title}
                    spacing={2}
                  >
                    <Grid item sm={4} md={4}>
                      <Typography>{inventory.name}</Typography>
                    </Grid>
                    <Grid item sm={3} md={3}>
                      <Typography>{inventory.model}</Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Typography>{inventory.serialNumber}</Typography>
                    </Grid>
                    <Grid item sm={1} md={1}>
                      <Typography>{inventory.quantity}</Typography>
                    </Grid>
                    <Grid item sm={2} md={2}>
                      <Typography>
                        {inventory.selfCollected ? 'Yes' : 'No'}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}

              {/* <Grid
                container
                className={`${styles.content} ${styles.border_top} ${styles.grey_background}`}
                justify='flex-end'
              >
                <Typography variant='h5' className={styles.color_grey}>
                  {translate('subtotal')}:
                </Typography>
                <Typography
                  variant='h5'
                  className={`ml_5 ${styles.color_dark_grey}`}
                >
                  {formatPrice(subTotal, item.currency)}
                </Typography>
              </Grid> */}
            </Grid>
          );
        })}

      {/* <Grid className='mt_10' container justify='flex-end'>
        <Typography variant='h4' className={styles.color_grey}>
          {translate('total')}:
        </Typography>
        <Typography variant='h4' className={`ml_5 ${styles.color_dark_grey}`}>
          {formatPrice(total, currency)}
        </Typography>
      </Grid> */}
    </Box>
  );
};

export default ServiceCard;

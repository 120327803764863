import React from 'react';
import styles from './tabItem.module.scss';
import { Field, Fields } from 'redux-form';

import { Typography, Box } from '@material-ui/core';
import RenderField from '../renderField';

const RenderFields = (props) => {
  const { label } = props;
  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography className={styles.formTitle}>{label}</Typography>
      {props.names.map((name, index) => (
        <Typography key={index} variant="h5" className={styles.normalText}>
          {props[name].input.value}
        </Typography>
      ))}
    </Box>
  );
};

const ClientDetails = ({ t }) => {
  return (
    <>
      <Typography variant="h4" className={styles.generalInfo}>
        {t('clientDetails')}
      </Typography>
      <Box display="flex" flexDirection="column">
        <Field
          label={t('name')}
          name="name"
          component={RenderField}
          t={t}
          isBoldText
        />
        <Fields
          label={t('contactInfo')}
          names={['contactPhone', 'contactEmail']}
          component={RenderFields}
          t={t}
        />
        <Field
          label={t('propertyAddress')}
          name="address"
          component={RenderField}
          t={t}
        />
      </Box>
    </>
  );
};

export default ClientDetails;

import React from 'react';

// Externals
import PropTypes from 'prop-types';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import {
  addInvoice,
  clearInvoice,
  setCurrentInvoice,
  initInvoiceFormData,
} from 'redux/invoice';
import { getProducts } from 'redux/product';
import { getJobs, getJobItems } from 'redux/job';

// Material components
import { withStyles, Button, Grid } from '@material-ui/core';

// Custom components
import { InvoiceToolbar } from '../components/utils';
import { ClientSelection, JobSelection } from '../components/cards';
import FormValidation from '../components/validation/formValidation';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  LoadingModal,
} from 'pages/Dashboard/components';

import { showErrorMessage, showInfoMessage } from 'lib/notifier';

// Component styles
import styles from './style';
import { InvoiceDetailsForm } from '../components/forms';

const mapDispatchToProps = (dispatch) => ({
  addInvoice: (value) => dispatch(addInvoice(value)),
  clearInvoice: () => dispatch(clearInvoice()),
  getJobs: (options = {}) => dispatch(getJobs(options)),
  getJobItems: (jobIds) => dispatch(getJobItems(jobIds)),
  getProducts: (type) => {
    dispatch(getProducts(type));
  },
  setCurrentInvoice: (InvoiceDetails) =>
    dispatch(setCurrentInvoice(InvoiceDetails)),
  initInvoiceFormData: (jobIds, client) =>
    dispatch(initInvoiceFormData(jobIds, client)),
});

const mapStateToProps = (state) => ({
  invoice: state.invoice.invoice,
  currentUserId: state.auth.currentUser.id,
});

class NewInvoice extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showStep1: !props.invoice || Object.keys(props.invoice).length === 0,
      showStep2:
        props.invoice &&
        Object.keys(props.invoice).length > 0 &&
        props.invoice.client.id,
      client: null,
      showJobList: false,
      jobList: [],
      selectedJobs: [],
      submitAction: null,
      submitActionValue: null,
      submitAndApproveInvoice: false,
      isLoading: false,
    };
    this.handleInvoiceSubmit = this.handleInvoiceSubmit.bind(this);
    this.handleCancelNewInvoice = this.handleCancelNewInvoice.bind(this);
    this.handleSelectJobs = this.handleSelectJobs.bind(this);
    this.handleSelectClient = this.handleSelectClient.bind(this);
    this.handleGenerateInvoice = this.handleGenerateInvoice.bind(this);
    this.validateAndCallSubmit = this.validateAndCallSubmit.bind(this);
    this.setSubmitAction = this.setSubmitAction.bind(this);
    this.setSubmitActionValue = this.setSubmitActionValue.bind(this);
    this.setConfirmStatus = this.setConfirmStatus.bind(this);
    this.handleCloseLoading = this.handleCloseLoading.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const { getProducts } = this.props;
    getProducts('products');
    getProducts('services');
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.clearInvoice();
  }

  setSubmitAction(value) {
    this.setState({ submitAction: value });
  }

  setSubmitActionValue(value) {
    this.setState({ submitActionValue: value });
  }

  setConfirmStatus(value) {
    this.setState({ submitAndApproveInvoice: value });
  }

  handleCloseLoading = () => this.setState({ isLoading: false });

  handleSelectClient(client) {
    const { getJobs } = this.props;

    this.setState({ showJobList: !!client, client });
    if (client) {
      getJobs({ clientId: client.id, limit: 100, skip: 0 }).then(
        (response) => {
          if (response.status === 200 && this._isMounted) {
            const { data } = response.data;
            this.setState({ jobList: data });
          } else {
            showErrorMessage(response.data.message);
          }
        },
        (error) => {
          showErrorMessage(error);
        }
      );
    } else {
      this.setState({ jobList: [] });
    }
  }

  async handleGenerateInvoice() {
    const { initInvoiceFormData } = this.props;
    const jobIds = this.state.selectedJobs.map((item) => item.publicId);
    const { client } = this.state;
    try {
      const res = await initInvoiceFormData(jobIds, client);
      if (res === 'ok') {
        this.setState({ showStep1: false, showStep2: true });
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  validateAndCallSubmit(values) {
    const { translate } = this.props;
    const errors = FormValidation(values);
    // TODO: check error here
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message)
          .map((msg) => translate(`Error:${msg}`))
          .join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors)
          .map((msg) => translate(`Error:${msg}`))
          .join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));
      throw new SubmissionError(errors);
    }

    this.handleInvoiceSubmit(values);
  }

  async handleInvoiceSubmit(invoiceValues) {
    const { addInvoice, history, translate } = this.props;
    const {
      submitAction,
      submitActionValue,
      submitAndApproveInvoice,
    } = this.state;
    const statusId = submitAndApproveInvoice ? 'APPROVED' : 'DRAFT';
    invoiceValues.statusId = statusId;
    let opts = { invoiceValues };
    if (submitAction === 'email') {
      opts = {
        ...opts,
        actionCallback: {
          isSendEmail: true,
          isSendSms: false,
          emails: [submitActionValue.email],
        },
      };
      showInfoMessage(translate('Invoice:sendingEmail'));
    } else if (submitAction === 'sms') {
      opts = {
        ...opts,
        actionCallback: {
          isSendEmail: false,
          isSendSms: true,
          phoneNumbers: [
            submitActionValue.countryPhoneCode + submitActionValue.phoneNumber,
          ],
        },
      };
      showInfoMessage(translate('Invoice:sendingSMS'));
    }
    this.setState({ isLoading: true });
    try {
      const response = await addInvoice(opts);
      this.setState({ isLoading: false });
      if (response.status === 200) {
        const invoiceId = response && response.data && response.data.id;
        if (!invoiceId) return;
        history.push(`/invoices/${invoiceId}`);
      } else {
        showErrorMessage(response.data.message);
      }
    } catch (error) {
      console.log('error', error);
      this.setState({ isLoading: false });
      showErrorMessage(error);
    }
  }

  handleSelectJobs(jobs) {
    this.setState({ selectedJobs: jobs });
  }

  handleCancelNewInvoice() {
    const { history, clearInvoice } = this.props;
    clearInvoice();
    history.goBack();
  }

  render() {
    const { classes, translate } = this.props;
    const {
      showStep1,
      showStep2,
      showJobList,
      jobList,
      selectedJobs,
      isLoading,
    } = this.state;

    return (
      <div className={classes.root}>
        <Grid container spacing={4} justify="center">
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <InvoiceToolbar translate={translate} />
          </Grid>

          <Grid item xl={12} lg={12} md={12} xs={12}>
            {showStep1 && (
              <Portlet className={classes.root}>
                <PortletHeader className={classes.cardHeader}>
                  <PortletLabel title={translate('newInvoice')} />
                </PortletHeader>
                <PortletContent>
                  <Grid item container md={12} sm={12} className="step-1">
                    <ClientSelection
                      handleSelectClient={this.handleSelectClient}
                      translate={translate}
                    />
                    {showJobList && (
                      <JobSelection
                        jobList={jobList}
                        handleSelectJobs={this.handleSelectJobs}
                        translate={translate}
                      />
                    )}
                    <Grid
                      item
                      container
                      md={12}
                      sm={12}
                      className={classes.actionButton}
                    >
                      <Button
                        color="secondary"
                        onClick={this.handleCancelNewInvoice}
                      >
                        {translate('Common:cancel')}
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleGenerateInvoice}
                        disabled={selectedJobs.length <= 0}
                      >
                        {translate('nextStep')}
                      </Button>
                    </Grid>
                  </Grid>
                </PortletContent>
              </Portlet>
            )}

            {showStep2 && (
              <InvoiceDetailsForm
                onSubmit={(values) => this.validateAndCallSubmit(values)}
                handleCancelInvoiceForm={this.handleCancelNewInvoice}
                translate={translate}
                setConfirmStatus={this.setConfirmStatus}
                setSubmitActionValue={this.setSubmitActionValue}
                setSubmitAction={this.setSubmitAction}
              />
            )}
          </Grid>
        </Grid>
        {isLoading && (
          <LoadingModal
            open={isLoading}
            handleClose={this.handleCloseLoading}
          />
        )}
      </div>
    );
  }
}

NewInvoice.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(NewInvoice);

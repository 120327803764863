import React from 'react';
import { Box, Card, Typography, Button, Grid } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Link, withRouter } from 'react-router-dom';
import styles from '../card.module.scss';

const HubDetails = (props) => {
  const { translate, input } = props;

  if (!input.value) return null;
  const { id, name, address, city, zipCode } = input.value;

  return (
    <Box mt={1}>
      <Card>
        <Box p={2}>
          <Typography className={`${styles.grey_color} font_bold`}>
            {translate('Hub:hubUpper')}
          </Typography>

          <Grid container className="mt_20">
            <Grid item sm={3} md={3}>
              <Typography className={styles.grey_color}>
                {translate('name')}
              </Typography>
            </Grid>
            <Grid item sm={9} md={9}>
              <Typography>{name}</Typography>
            </Grid>
          </Grid>

          <Grid container className="mt_5">
            <Grid item sm={3} md={3}>
              <Typography className={styles.grey_color}>
                {translate('address')}
              </Typography>
            </Grid>
            <Grid item sm={9} md={9}>
              <Typography>{address}</Typography>
            </Grid>
          </Grid>

          <Grid container className="mt_5">
            <Grid item sm={3} md={3}>
              <Typography className={styles.grey_color}>
                {translate('Locality')}
              </Typography>
            </Grid>
            <Grid item sm={9} md={9}>
              <Typography>
                {[city, zipCode].filter(Boolean).join(', ')}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={2}>
            <Link to={`/hub/${id}`} target="_blank">
              <Button
                fullWidth
                variant="contained"
                className={styles.btn_details}
              >
                <InfoOutlinedIcon className="mr_5" />
                {translate('Hub:details')}
              </Button>
            </Link>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default withRouter(HubDetails);

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as formReducer } from 'redux-form';
import AuthReducer from './auth';
import ClientReducer from './client';
import ConfigReducer from './config';
import GlobalReducer from './global';
import InvoiceReducer from './invoice';
import QuoteReducer from './quote';
import JobReducer from './job';
import RequestReducer from './request'; //Add by Sophie
import PaymentReducer from './payment';
import ProductReducer from './product';
import PropertyReducer from './property';
import SidebarReducer from './sidebar';
import StaffReducer from './staff';
import VisitReducer from './visit';
import history from '../services/history';
import companyReducer from './company';
import subscriptionWarmingReducer from './subscriptionWarming';
import mediaReducer from './media';
import enterpriseReducer from './enterprise';
import dashboardReducer from './dashboard';
import hubReducer from './hub';
import { reviewJobReducer } from './jobVerification';

const appReducer = combineReducers({
  router: connectRouter(history),
  auth: AuthReducer,
  client: ClientReducer,
  config: ConfigReducer,
  form: formReducer,
  global: GlobalReducer,
  invoice: InvoiceReducer,
  quote: QuoteReducer,
  job: JobReducer,
  request: RequestReducer, //Add by Sophie
  payment: PaymentReducer,
  product: ProductReducer,
  property: PropertyReducer,
  sidebar: SidebarReducer,
  staff: StaffReducer,
  visit: VisitReducer,
  company: companyReducer,
  subscriptionWarming: subscriptionWarmingReducer,
  media: mediaReducer,
  enterprise: enterpriseReducer,
  dashboard: dashboardReducer,
  hub: hubReducer,
  jobVerification: reviewJobReducer,
});

export default (state, action) => {
  if (action.type === 'RESET') {
    state = undefined;
  }

  return appReducer(state, action);
};

import React from 'react';

// Externals
import PropTypes from 'prop-types';

// Redux
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change } from 'redux-form';

// Material components
import { Avatar, Grid, withStyles } from '@material-ui/core';

// Material icons

// Customised components
import { TextInput, ButtonPicker, FileUploaderButton } from 'components/inputs';
import { CountryPhoneNumberInput, StaffCategory } from '../inputs';
import Countries from 'lib/countryList';
import StaffDefaultAvatar from 'assets/images/staff_default_avatar.svg';
// Component styles
import styles from './styles';

// Component address
import PropertyAddressForm from './AddressForm';

const countryPhoneCodeOptions = new Countries().countryPhoneCodes();

const StaffForm = (props) => {
  const {
    classes,
    staff = {},
    translate,
    openCroppingImgModal,
    staffProfile,
    handleClickOnCategory,
    formName
  } = props;

  const genderIds = useSelector(
    (state) => ((state.config || {}).configs || {}).gender || []
  );

  const dispatch = useDispatch();

  const updateStaffAddress = (property) => {
    const { address, countryCode, state, city, zipCode } = property;

    const name = formName || 'staffForm';
    dispatch(change(name, 'countryCode', countryCode));
    dispatch(change(name, 'state', state));
    dispatch(change(name, 'address', address));
    dispatch(change(name, 'city', city));
    dispatch(change(name, 'zipCode', zipCode));
  };

  const handleSelectLocation = (location = {}) => {
    const {
      address,
      unitNumber,
      city,
      postalCode,
      state,
      countryCode,
      lat,
      lng,
      placeId,
    } = location;
    const newProperty = {
      address,
      unitNumber,
      city,
      zipCode: postalCode,
      state,
      countryCode,
      lat,
      lng,
      placeId,
    };
    updateStaffAddress(newProperty);
  };

  const avatarUrl = staffProfile || staff.avatar;
  return (
    <>
      <Grid container spacing={4} className={classes.root}>
        <Grid item container md={2} className={classes.profile}>
          <Avatar className={classes.avatar} src={avatarUrl || StaffDefaultAvatar} />

          <Field
            buttonClass={classes.uploadButton}
            buttonLabel={translate('uploadPicture')}
            color="primary"
            component={FileUploaderButton}
            id="profile-picture-upload"
            inputClass={classes.fileInput}
            onChange={openCroppingImgModal}
            name="avatar"
            value={null}
          />
        </Grid>
        <Grid item container md={5} spacing={1}>
          <Grid item container direction="row" spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6}>
              <Field
                name="firstName"
                component={TextInput}
                label={translate('Common:firstName')}
                margin="dense"
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6}>
              <Field
                name="lastName"
                component={TextInput}
                label={translate('Common:lastName')}
                margin="dense"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12}>
              <Field
                name="email"
                component={TextInput}
                label={translate('Common:email')}
                margin="dense"
                required
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={2}>
            <CountryPhoneNumberInput
              selections={countryPhoneCodeOptions}
              className={classes.countryPhoneInput}
              t={translate}
            />
          </Grid>

          <Grid item container direction="row" spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12}>
              <StaffCategory t={translate} handleClickOnCategory={handleClickOnCategory} formName={formName || 'staffForm'} />
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="row"
            spacing={2}
            className={classes.genderOption}
            alignItems="flex-end"
          >
            <Grid item xl={12} lg={12} md={12} sm={12}>
              <Field
                component={ButtonPicker}
                data={genderIds}
                fullWidth
                groupLabel={translate('Common:gender')}
                name="genderId"
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container md={5} spacing={1}>
          <PropertyAddressForm
            translate={translate}
            handleSelectLocation={(location) => handleSelectLocation(location)}
          />
        </Grid>
      </Grid>
    </>
  );
};

StaffForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(StaffForm);

import React from 'react';

// Material helpers
import { Button, Grid, Chip } from '@material-ui/core';
import styles from './components.module.scss';

import SelectTeamMember from './SelectTeamMember';
import editSrc from 'assets/images/icons/blue_edit.svg';
import { getTeamStatus } from 'pages/Dashboard/helpers/getTeamStatus';

const renderTeamCard = (props) => {
  const { input, translate, setIsOpenSelectTeamModal, isOpenSelectTeamModal } = props;

  const handleDelete = (item) => {
    const newTeam = input.value.filter((value) => value.id !== item.id);
    input.onChange(newTeam);
  };

  const handleOpenTeamCardModal = () => setIsOpenSelectTeamModal(true);

  return (
    <Grid container>
      <Grid item sm={10} md={10}>
        {Array.isArray(input.value) &&
          input.value.map((item) => (
            <Chip
              classes={{ root: styles.team_chip, label: styles.label_chip }}
              key={item.id}
              label={item.fullName + getTeamStatus(item.isDeleted, item.isArchived, translate)}
              onDelete={() => handleDelete(item)}
              className="mr_5"
            />
          ))}
      </Grid>
      <Grid item container display="flex" justify="flex-end" sm={2} md={2}>
        <Button onClick={handleOpenTeamCardModal} className={styles.btn_edit}>
          <img src={editSrc} alt="editSrc" className="mr_5" />
          {translate('edit')}
        </Button>
      </Grid>
      {isOpenSelectTeamModal &&
      <SelectTeamMember {...props} selectedMemberList={input.value} /> }
    </Grid>
  );
};

export default renderTeamCard;
export default theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  modalContent: {
    padding: theme.spacing(3),
  },
  portletHeader: {
    backgroundColor: theme.palette.calendar.green
  },
  portletWrap: {
    padding: 0,
    width: 600
  },
  portletContent: {
    paddingTop: 0
  },

  section: {
    padding: '10px 0',

    '& .MuiGrid-item': {
      paddingLeft: 0
    }
  },

  clientName: {
    textTransform: 'uppercase',
    fontWeight: 900,
    paddingBottom: 10
  },
  jobDescription: {
    fontStyle: 'italic',
  },

  noteWrap: {
    border: `1px solid ${theme.palette.border}`,
    width: '100%',
    padding: '10px',
    lineHeight: '24px',
  },

  contentStyle: {
    paddingLeft: 35,
    lineHeight: '24px'
  },
  iconStyle: {
    color: theme.palette.secondary.main,
    width: 24,
    position: 'absolute'
  },
  btnStyles: {
    color: theme.palette.secondary.main,
    '& .MuiButton-label': {
      textTransform: 'uppercase',
    }
  },
  checkbox: {
    padding: 3,
  }
});
import React from 'react';

// Redux
import { Grid } from '@material-ui/core';
import { Field } from 'redux-form';

import { DatePicker, TimePicker } from 'components/inputs';

const renderDateAndTime = (props) => {
  const { names, disabled } = props;
  return (
    <Grid container>
      <Grid item sm={4} md={4}>
        <Field
          name={names[0]}
          component={DatePicker}
          margin="dense"
          required
          variant="outlined"
          disabled={disabled}
        />
      </Grid>
      <Grid item sm={4} md={4} className="ml_5">
        <Field
          name={names[1]}
          component={TimePicker}
          margin="dense"
          required
          variant="outlined"
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default renderDateAndTime;
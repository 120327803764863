import { GET, POST, PUT, PATCH, DELETE } from 'lib/api';

const initialState = {
  client: {},
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function fetchEnterpriseList(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET('/companies', accessToken, params).catch(
      (message) => ({
        message,
      })
    );
    if (result.message) return result;
    return result.data;
  };
}

export function getEnterpriseVisits(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      '/enterprise_account/visits',
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getEnterpriseServiceReport(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      '/enterprise_account/service-reports',
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getEnterpriseJobList(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      '/enterprise_account/jobs',
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

//Enterprise Request
const mapOptionsToPayload = [
  'includingArchived',
  'clientId',
  'statusIds',
  'staffIds',
  'scheduledOnFrom',
  'scheduledOnTo',
  'createdOnFrom',
  'createdOnTo',
  'requestCategoryIds',
  'clientId',
  'serviceReport',
  'lateVisit',
  'exportXLSX',
  'completionTimeFrom',
  'completionTimeTo',
  'hubIds',
  'isItemsCollected',
  'isVerified',
];

export function getEnterpriseRequestList(options = {}) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const queryParams = {};
    mapOptionsToPayload.forEach((name) => {
      if (options[name]) queryParams[name] = options[name];
    });

    if (options.limit) {
      queryParams['$limit'] = options.limit;
    }

    if (options.skip) {
      queryParams['$skip'] = options.skip;
    }

    if (options.searchString) {
      queryParams['$q'] = options.searchString;
    }

    if (options.sort && options.sort.field) {
      queryParams[`$sort[${options.sort.field}]`] =
        options.sort.orderDirection === 'asc' ? 1 : -1;
    }
    if (options.from) {
      queryParams['$from'] = options.from;
    }
    if (options.to) {
      queryParams['$to'] = options.to;
    }

    const params = { params: queryParams };
    return GET('/enterprise_account/requests', accessToken, params)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function getEnterpriseRequestDetails(requestId) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/enterprise_account/requests/${requestId}`, accessToken)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function addEnterpriseRequest(data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/enterprise_account/requests', accessToken, { data })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function updateEnterpriseRequestDetails(requestDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(
      `/enterprise_account/requests/${
        requestDetails.publicId || requestDetails.id
      }`,
      accessToken,
      { data: requestDetails }
    )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function deleteEnterpriseRequest(requestDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return DELETE(
      `/enterprise_account/requests/${
        requestDetails.publicId || requestDetails.id
      }`,
      accessToken
    )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function mediaActions(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await POST(
      '/enterprise_account/media-actions',
      accessToken,
      data
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function serviceReportActions(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await POST(
      '/enterprise_account/service-report-actions',
      accessToken,
      data
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getMedia(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      '/enterprise_account/medias',
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function downloadJobList(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await POST(
      '/enterprise_account/job_action',
      accessToken,
      data
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getEnterpriseProperties(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      '/enterprise_account/properties',
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getMediaDetails(jobId, params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      `/enterprise_account/medias/${jobId}`,
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getEntepriseProduct(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET('/products', accessToken, params).catch(
      (message) => ({
        e: message,
      })
    );
    if (result.e) return result;
    return result.data;
  };
}

export function addEntepriseProduct(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await POST('/products', accessToken, {
      data,
    }).catch((message) => ({
      e: message,
    }));

    if (result.e) return result;
    return result.data;
  };
}

export function updateEntepriseProduct(id, data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await PUT(`products/${id}`, accessToken, {
      data,
    }).catch((message) => ({
      e: message,
    }));

    if (result.e) return result;
    return result.data;
  };
}

export function deleteEntepriseProduct(id, params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await DELETE(`products/${id}`, accessToken, {
      params,
    }).catch((message) => ({
      e: message,
    }));

    if (result.e) return result;
    return result.data;
  };
}

export function getEntepriseRequestItems(requestIds, params = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const searchParams = { ...params, requestIds };

    return GET('/enterprise_account/request-items', accessToken, { params: searchParams })
      .then(
        (response) => {
          // dispatch(setRequestItems(response.data.data));
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

// export function requestActions(requestId, action) {
//   return (dispatch, getState) => {
//     const { accessToken } = getState().auth;
//     const { request } = getState().request;
//     const payload = {
//       action,
//       requestId,
//     };

//     return POST(REQUEST_ACTIONS, accessToken, { data: payload })
//       .then(
//         (response) => {
//           const updatedJob = { ...request, ...response.data };
//           return Promise.resolve(updatedJob);
//         },
//         (error) => {
//           return Promise.reject(error.response);
//         }
//       )
//       .catch((error) => {
//         return Promise.reject(error);
//       });
//   };
// }
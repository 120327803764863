export default {
  personalDetails: 'Personal Details',
  passwordAndSecurity: 'Password and Security',
  changePassword: 'Change Password',
  password: 'New Password',
  repeatPassword: 'Repeat New Password',
  updatePasswordSuccessfully: 'Updated password successfully',
  passwordConfirmationNotMatch: 'The password confirmation dit not match with password.',
  extraText: 'Choose a unique password to protect your account',
  currentPassword: 'Current Password',
  preference: 'Preference',
  preferredLanguage: 'Preferred Language',
  preferredTimeFormat: 'Preferred Time Format',
  savePreferences: 'Save Preferences',
  role: 'Role',
  firstName: 'First Name',
  lastName: 'Last Name',
  contactPhoneNumber: 'Contact Phone Number',
  email: 'Email',
  address: 'Address',
  updateLanguageSuccessfully: 'Updated language successfully',
  change: 'Change',
  cancel: 'Cancel',
  needCurrentPassword: 'You have to enter the current password to set new password.',
  newPasswordHasToBeDifferentOldPassword: 'The new password has to be different current password',
  pleaseEnterNewPassword: 'Please enter new password',
  pleaseRepeatNewPassword: 'Please repeat new password',
  time12h: '12 hours',
  time24h: '24 hours',
};
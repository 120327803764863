import React from 'react';

import {
  Typography,
  Popover,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { showErrorMessage } from 'lib/notifier';

const StatusPopover = ({
  statusAnchorEl,
  handleCloseStatusMenu,
  handleUpdateStatus,
  translate,
  visitDetails,
}) => {
  const { statusId, onTheWayAt, arrivedAt, startedAt, completedAt, cancelledAt } = visitDetails;
  const STATUSES = [
    { id: 'ON_STANDBY', name: 'On Standby', settedTime: null, priority: 0 },
    { id: 'ON_THE_WAY', name: 'On The Way', settedTime: moment(onTheWayAt), priority: 1 },
    { id: 'ARRIVED', name: 'Arrived', settedTime: arrivedAt && moment(arrivedAt), priority: 2 },
    { id: 'STARTED', name: 'Started', settedTime: startedAt && moment(startedAt), priority: 3 },
    { id: 'COMPLETED', name: 'Completed', settedTime: completedAt && moment(completedAt), priority: 4 },
    { id: 'CANCELLED', name: 'Cancelled', settedTime: cancelledAt && moment(cancelledAt), priority: 5 },
  ];

  const handleCompareTime = (newStatus, compareTime) => {
    if(compareTime) {
      showErrorMessage(translate('Job:incorrectPrevTime'));
    }
    else {
      handleUpdateStatus && handleUpdateStatus(newStatus);
    }
  };

  const handleClickStatus = (newStatus, priority) => {
    if(priority === 5) {
      handleUpdateStatus && handleUpdateStatus(newStatus);
    }
    else{
      const currentStatus = STATUSES.find((status)=>{ return status.id === statusId; });
      const currentInfor = currentStatus || STATUSES[0];
      if(priority <= currentInfor.priority || currentInfor.priority === 0) {
        const prevTimeInfor = STATUSES.filter((time)=>{ return time.priority < priority; });
        const compareTime = prevTimeInfor.find((time)=>{ return moment().isBefore(moment(time.settedTime)); });
        handleCompareTime && handleCompareTime(newStatus, compareTime);
      }
      else {
        const compareTime = moment().isBefore(moment(currentInfor.settedTime));
        handleCompareTime && handleCompareTime(newStatus, compareTime);
      }
    }
  };

  return (
    <Popover
      anchorEl={statusAnchorEl}
      open={Boolean(statusAnchorEl)}
      onClose={handleCloseStatusMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Typography component="div">
        <List component="nav" aria-label="visit actions">
          {STATUSES.map((item, index) => {
            return (
              <ListItem
                button
                key={index}
                onClick={() => handleClickStatus(item.id, item.priority)}
              >
                <ListItemText primary={item.name} />
              </ListItem>
            );
          })}
        </List>
      </Typography>
    </Popover>
  );
};

export default StatusPopover;

import './index.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export default function LandingPageHeader(props) {
  // eslint-disable-next-line react/prop-types
  const { isSignIn = false, secondaryPage = false, translate } = props;

  const headerType = classNames({
    main_menu: true,
    single_page_menu: secondaryPage
  });

  return (
    <header className={headerType}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to="/" className="navbar-brand main_logo">
                <img src="img/logo.png" alt="logo" />
              </Link>
              <Link to="/" className="navbar-brand single_page_logo">
                <img src="img/footer_logo.png" alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="menu_icon" />
              </button>

              <div className="collapse navbar-collapse main-menu-item" id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      {translate('home')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="features" className="nav-link">
                      {translate('features')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="pricing" className="nav-link">
                      {translate('pricing')}
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="pricing" className="nav-link">
                      {translate('blog')}
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link to="contact" className="nav-link">
                      {translate('contact')}
                    </Link>
                  </li>
                </ul>
              </div>
              {
                isSignIn ?
                  <Link to="calendar" className="d-none d-sm-block btn_1 home_page_btn">
                    {translate('Common:dashboard')}
                  </Link> :
                  <Link to="log-in" className="d-none d-sm-block btn_1 home_page_btn">
                    {translate('Common:login')}
                  </Link>
              }
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};
import React, { useEffect } from 'react';
import { COLORS } from 'common/colors';
import { Select, FormControl, InputLabel, MenuItem, Input, Checkbox, FormHelperText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StatusChip } from 'pages/Dashboard/components';

export function SelectBox(props) {
  const { t } = useTranslation('Error');
  const styles = {
    formControl: {
      textAlign: 'left'
    }
  };
  return (
    <FormControl
      error={props.meta.touched && props.meta.error}
      margin={props.margin || 'normal'}
      fullWidth
      style={styles.formControl}
      variant={props.variant}
    >
      {props.label && <InputLabel style={{ color: COLORS.blue }}>{props.label}</InputLabel> }
      <Select
        label={props.label}
        className="seclecter"
        name={props.input.name}
        onChange={props.input.onChange}
        value={props.input.value}
        inputProps={props.inputProps}
      >
        { props.options.map((item, i) => {
          return <MenuItem className={props.menuItemStyle} value={item.id} key={i} disabled={item.disabled}>
            {props.isVisitStatus ? <StatusChip job status={item.id} />: item.name}
          </MenuItem>;
        })}
      </Select>
      {((props.meta.touched && props.meta.error) || props.helperText) &&
        <FormHelperText>{(props.meta.touched && t(props.meta.error)) || props.helperText}</FormHelperText>}
    </FormControl>
  );
}

export function MultipleSelectBox(props) {
  const [values, setValues] = React.useState(props.selected || []);
  function handleChange(event) {
    setValues(event.target.value);
  }
  const styles = {
    formControl: {
      textAlign: 'left'
    }
  };
  useEffect(() => {
    // I dont know but we have to use below of code to make company info get new values from values
    props.input.onChange(values);
  });

  function displaySelectedName(selected, options) {
    return selected.sort().map((item) => {
      const option = options.find((element) => {
        return element.id === item;
      });
      return option ? option.name : '';
    }).join(', ');
  }

  return (

    <FormControl margin='normal' fullWidth style={styles.formControl}>
      <InputLabel htmlFor="select-multiple" style={{ color: COLORS.blue }}>{props.label}</InputLabel>
      <Select
        className="seclecter"
        multiple
        name={props.input.name}
        onChange={handleChange}
        value={values}
        renderValue={(selected) => { return displaySelectedName(selected, props.options); }}
        input={<Input id="select-multiple" />}
        inputProps={props.inputProps}
      >
        { props.options.map((item, i) => {
          return <MenuItem value={item.id} key={i}>
            <Checkbox checked={values.indexOf(item.id) > -1} />
            {item.name} </MenuItem>;
        }) }
      </Select>
      <FormHelperText>{(props.meta.touched && props.meta.error) || props.helperText}</FormHelperText>
    </FormControl>
  );
}

export default theme => ({
  root: {},
  header: {
    backgroundColor: theme.palette.background.muted,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  requestClientDetail:{},
  requestDetail: {
    marginLeft: theme.spacing(4),
    borderRadius: 6,
    backgroundColor: 'white',
    padding: theme.spacing(4),
  },
  requestTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  requestDescription: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  borderContainer: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    padding: theme.spacing(2)
  },
  requestDetailsTitle : {
    fontWeight: 600,
  },
  titleColor: {
    color: theme.palette.text.gray
  },
  clientName: {
    color: '#305BF1'
  },
  emailContainer: {
    marginBottom: 8
  }
});

import React from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from './styles';

const wmLogo = require('assets/images/logo/logo.png');

const HeaderIcon = () => (
  <Grid item className="header-logo">
    <a href="/" className="logo-cover">
      <img src={wmLogo} className="wm-logo" alt="WorkPulse" />
    </a>
  </Grid>
);

function SubscriptionAgreement(props) {
  const {
    classes
  } = props;
  return (
    <div className={classes.root}>
      <HeaderIcon />
      <div className={classes.pageTitle}>
        <Typography variant='h1'>User Subscription Agreement
        </Typography>
      </div>
      <Grid
        container
        spacing={4}
      >
        <div className={classes.pageContent}>
          <ol type="1">
            <li className={classes.termItem}>
              <strong>
                EARLY USER PRIVILEGES
              </strong>
              <p>1.1. Early User agrees to subscribe to the Product, on the plan selected above, for a minimum term of
                12
                months from the date that Product is made available to the Early User and to pay the subscription fees
                described below.</p>
              <p>1.2. In consideration for this commitment, FTS agrees to provide the Early User with discounted
                pricing as follows:</p>
              <p className={classes.childTerm}>1.2.1. Free usage of the Product for the initially subscribed number of
                users for a period of 1 (one)
                month</p>
              <p className={classes.childTerm}>1.2.2. A discount of 50% off published rates for the remainder of the
                initial term of subscription</p>
              <p>1.3. The parties understand the proposed pricing of the Product for 2020 and beyond will be as
                described
                in Schedule A but that FTS may revise this pricing at anytime prior to the official launch of
                the
                Product.</p>
              <p>1.4. The parties acknowledge that final software licensing terms will be finalised at a future date
                by
                FTS and the Early User agrees to comply with those terms, subject to the preferential pricing
                and
                term conditions detailed in this Agreement.</p>
              <p>1.5. As an early user of the Product, FTS may request feedback You for the purposes improving
                the
                product, and You agree to provide reasonable assistance in this regard.</p>
            </li>
            <li className={classes.termItem}>
              <strong>WARRANTIES</strong>
              <p>2.1. Early User agrees that the Product during its early release may not be have an incomplete level of
                performance, but will be enhanced by FTS during the term of the Agreement. The Product and
                documentation are provided “as is” without warranty of any kind, and FTS and its licensors
                disclaim
                all warranties, express, implied, or statutory, including without limitation any implied warranties of
                title, non-infringement of third party rights, merchantability, or fitness for a particular purpose. No
                oral or written advice or consultation given by FTS, its agents or employees will in any way give
                rise to a warranty. The entire risk arising out of the use or performance of the service remains with
                the
                Early User.</p>
              <p>2.2. FTS and its licensors shall not be liable for loss of use, lost profit, cost of cover, loss
                of
                data, business interruption, or any indirect, incidental, consequential, punitive, special, or exemplary
                damages arising out of or related to the service or this agreement, however caused and regardless of the
                form of action, whether in contract, tort (including negligence) strict liability, or otherwise, even if
                such parties have been advised of the possibility of such damages. In no event will FTS’s
                aggregate
                cumulative liability for any claims arising out of or related to this agreement exceed RM500.00 or the
                amount the Early User actually paid FTS under this agreement (if any, and whichever is the
                lesser).</p>
            </li>
            <li className={classes.termItem}>
              <strong>CONFIDENTIALITY</strong>
              <p>3.1. Confidential information under this Agreement shall mean information that is marked as
                “confidential”
                or which a party should reasonably know is the confidential information of the other party, including
                but
                not limited to business, technical, and financial information, and will include the contents and
                existence
                of this Agreement. With respect to such information the party to whom the information is disclosed and
                its
                employees, consultants and other agents shall:</p>
              <p className={classes.childTerm}>3.1.1. Hold the information in confidence and protect it in accordance
                with the security measures with
                which it protects its own proprietary information, which it does not wish to disclose, but in no event
                less than reasonable measures;</p>
              <p className={classes.childTerm}>3.1.2. Restricts disclosure of the information solely to those employees,
                consultants and other agents
                with a need to know basis who have signed written agreements containing obligations of confidentiality
                at
                least as restrictive as those set forth herein, and not disclose such information to any other
                person;</p>
              <p className={classes.childTerm}>3.1.3. Require its employees, consultants and other agents with access to
                the information to sign a
                written agreement containing obligations of confidentiality at least as restrictive as those set forth
                herein; and</p>
              <p className={classes.childTerm}>3.1.4. Use the information only in connection with the fulfilment of each
                party’s obligations under
                this
                Agreement, except as many otherwise by agreed to by the parties in writing.</p>
            </li>
            <li className={classes.termItem}>
              <strong>ASSIGNMENT</strong>
              <p>4.1. Neither party may assign this Contract, or any of the rights or obligations hereunder, without the
                other party’s prior written consent, except that either party has the right to assign this Contract to a
                successor in connection with a merger, acquisition, or sale of all or substantially all of a party’s
                assets. In the case of FTS establishing corporate entity to operate the Product, FTS will
                have the right to assign the Agreement at their absolute discretion.</p>
            </li>
            <li className={classes.termItem}>
              <strong>GOVERNING LAW</strong>
              <p>5.1. This Agreement shall be construed in accordance with the laws of Malaysia.</p>
            </li>
            <li className={classes.termItem}>
              <strong>SEVERABILITY:</strong>
              <p>6.1. The unenforceability or invalidity of any clause in this Agreement shall not have an impact on the
                enforceability or validity of any other clause. Any unenforceable or invalid clause shall be regarded as
                removed from this Agreement to the extent of its unenforceability and invalidity. Therefore, this
                Agreement shall be interpreted and enforced as if it did not contain the said clause to the extent of
                its
                unenforceability and invalidity.</p>
            </li>
            <li className={classes.termItem}>
              <strong>TERMINATION</strong>
              <p>7.1. Subject to the minimum term of this agreement, this Agreement may be terminated by either party
                with
                30 days prior written notice to the other.</p>

            </li>
          </ol>
        </div>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(SubscriptionAgreement);
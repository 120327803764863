import React from 'react';
import { Typography, Box } from '@material-ui/core';
import styles from './utils.module.scss';
import CopySrc from 'assets/images/dashboard/copy.svg';
import { showSuccessMessage } from 'lib/notifier';

const ButtonCopy = ({ t, jobId }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(jobId);
    showSuccessMessage(t('textCopied'));
  };
  return (
    <Box
      ml={1}
      p={2}
      display="flex"
      alignItems="center"
      onClick={handleCopy}
      className={styles.btnCopy}
    >
      <img src={CopySrc} alt="CopySrc" />
      <Typography>{t('copy')}</Typography>
    </Box>
  );
};

export default ButtonCopy;

import { setIsLoading } from 'redux/global';
import { GET } from 'lib/api';
import { TIME_FORMAT_12H_API } from 'common/constant';

// Actions
const SET_CONFIGS = 'SET_CONFIGS';
const SET_CLEAR_CONFIGS = 'SET_CLEAR_CONFIGS';
const SET_DATE_TIME_FORMAT = 'SET_DATE_TIME_FORMAT';

const initialState = {
  configs: {},
  dateTimeFormat: TIME_FORMAT_12H_API
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CONFIGS:
      return {
        ...state,
        configs: {
          ...state.configs,
          ...action.configs
        }
      };

    case SET_CLEAR_CONFIGS:
      return {
        ...state,
        configs: {}
      };
    case SET_DATE_TIME_FORMAT:
      return {
        ...state,
        dateTimeFormat: action.dateTimeFormat
      };

    default: return state;
  }
}

// Action Creators
export function setConfigs(configs) {
  return { type: SET_CONFIGS, configs };
}

export function clearConfigs() {
  return { type: SET_CLEAR_CONFIGS };
}

export function setDateTimeFormat(dateTimeFormat) {
  return { type: SET_DATE_TIME_FORMAT, dateTimeFormat };
}



export function getConfigs() {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    dispatch(setIsLoading(true));

    return GET('/configs', accessToken).then((response) => {
      dispatch(setIsLoading(false));
      dispatch(setConfigs(response.data));
      return Promise.resolve(response);
    },(error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      dispatch(setIsLoading(false));
      return Promise.reject(err);
    });
  };
};
import React, { createRef, forwardRef, useEffect } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getInvoices,
  clearInvoice,
  downloadPdf,
  getInvoice,
} from 'redux/invoice';

// Material components
import { withStyles, TextField, MenuItem, Checkbox, FormControlLabel, Grid } from '@material-ui/core';

// Material icons
import {
  ArrowUpward,
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ToggleOff,
  Visibility,
  ViewColumn,
  GetApp,
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletContent,
  StatusChip,
  LoadingModal,
} from 'pages/Dashboard/components';
import Loader from 'components/loaders/Loader';
import { DatePicker } from 'components/inputs';

// Component styles
import { InvoiceListTableStyles } from './styles';
import { formatPrice } from 'lib/formatter';

const mapStateToProps = (state) => ({
  invoiceStatuses: state.config.configs.invoiceStatuses,
});

const mapDispatchToProps = (dispatch) => ({
  clearInvoiceDispatch: () => dispatch(clearInvoice()),
  getInvoices: (params) => dispatch(getInvoices(params)),
  downloadPdf: (invoiceId, callback) =>
    dispatch(downloadPdf(invoiceId, callback)),
  getInvoice: (invoiceId) => dispatch(getInvoice(invoiceId)),
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Download: forwardRef((props, ref) => <GetApp {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ToggleOff: forwardRef((props, ref) => <ToggleOff {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
};

const cellStyle = {
  verticalAlign: 'top',
  minWidth: 150,
};

const InvoicesTable = (props) => {
  const {
    classes,
    className,
    clearInvoiceDispatch,
    translate,
    filterDateOptions,
    fetchInvoices,
    includingArchived,
    setIncludingArchived,
    issuedAtFilter,
    setIssuedAtFilter,
    issuedAtFrom,
    setIssuedAtFrom,
    issuedAtTo,
    setIssuedAtTo,
    // downloadPdf,
  } = props;
  const tableRef = createRef();
  const [showFilterDatePicker, setShowFilterDatePicker] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    clearInvoiceDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefreshInvoiceList = () => {
    tableRef.current && tableRef.current.onQueryChange({ page: 0 });
  };

  const handleRefreshTable = (event) => {
    setIncludingArchived(event.target.checked);
    handleRefreshInvoiceList();
  };

  const handleChangeDateFilter = (event) => {
    setIssuedAtFilter(event.target.value);
    if (event.target.value === 'CUSTOM') {
      setShowFilterDatePicker(true);
    } else {
      setShowFilterDatePicker(false);
      handleRefreshInvoiceList();
    }
  };

  const handleChangeIssuedAtTo = (date) => {
    if (date && date.isValid()) {
      setIssuedAtTo(date);
      handleRefreshInvoiceList();
    }
  };

  const handleChangeIssuedAtFrom = (date) => {
    if (date && date.isValid()) {
      setIssuedAtFrom(date);
      handleRefreshInvoiceList();
    }
  };

  const handleCloseLoading = () => setIsLoading(false);

  const rootClassName = classNames(classes.root, className);

  const options = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    actionsColumnIndex: -1,
    headerStyle: cellStyle,
    sorting: true,
    search: true,
    debounceInterval: 1000,
    draggable: false
  };

  const columns = [
    {
      field: 'statusId',
      title: translate('status'),
      cellStyle,
      sorting: true,
      filtering: false,
      // disable alphabet sort
      customSort: () => true,
      render: (rowData) => {
        return (
          <StatusChip
            invoice
            status={rowData.statusId}
            archived={rowData.archived}
          />
        );
      },
    },
    {
      field: 'number',
      title: translate('Common:number'),
      cellStyle,
      sorting: true,
      filtering: false,
      render: (rowData) => {
        return <span>{rowData.id}</span>;
      },
    },
    {
      field: 'createdAt',
      title: translate('createdOn'),
      cellStyle,
      sorting: true,
      filtering: false,
      render: (rowData) => {
        const createdAt =
          (rowData.createdAt &&
            moment(rowData.createdAt).format('MMM DD, YYYY')) ||
          'N/A';
        return <span>{createdAt}</span>;
      },
    },
    {
      field: 'dueBy',
      title: translate('dueOn'),
      cellStyle,
      sorting: true,
      filtering: false,
      render: (rowData) => {
        const dueDate =
          (rowData.dueBy && moment(rowData.dueBy).format('MMM DD, YYYY')) ||
          'N/A';

        return <span>{dueDate}</span>;
      },
    },
    {
      field: 'subject',
      title: translate('title'),
      cellStyle,
      sorting: true,
      filtering: false,
      render: (rowData) => {
        return <span>{rowData.subject}</span>;
      },
    },
    {
      field: 'firstName',
      title: translate('client'),
      cellStyle,
      sorting: true,
      filtering: false,
      render: (rowData) => {
        const { client: { displayName } } = rowData;
        return displayName;
      },
    },
    {
      field: 'price',
      title: translate('price'),
      cellStyle: { ...cellStyle, minWidth: 150 },
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return <strong>{formatPrice(rowData.total, rowData.currency)}</strong>;
      },
    },
    {
      field: 'issuedBy',
      title: translate('createdBy'),
      cellStyle: { ...cellStyle, minWidth: 150 },
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <>
          {rowData.issuedByUser
            ? `${rowData.issuedByUser.firstName} ${rowData.issuedByUser.lastName}`
            : ''}
        </>
      ),
    },
  ];

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <MaterialTable
          title={null}
          tableRef={tableRef}
          columns={columns}
          options={options}
          icons={tableIcons}
          data={(query) => fetchInvoices(query)}
          onRowClick={(event, rowData) => {
            window.open(`/invoices/${rowData.id}`, '_blank');
          }}
          components={{
            OverlayLoading: (props) => <Loader height={50} width={50} />,
            Toolbar: (props) => (
              <>
                <Grid container direction="row">
                  <Grid
                    item
                    container
                    justify="flex-start"
                    alignItems="center"
                    md={2}
                    sm={2}
                    className={classes.archivesInclusiveCheckbox}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includingArchived}
                          onChange={handleRefreshTable}
                          value="includingArchived"
                        />
                      }
                      label={translate('showArchived')}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    sm={6}
                    md={6}
                    alignItems="center"
                    justify="flex-start"
                    spacing={1}
                  >
                    <Grid item sm={4} md={4}>
                      <div className={classes.filterDropdownWrap}>
                        <TextField
                          id="outlined-select-currency"
                          select
                          label={translate('issuedAt')}
                          fullWidth
                          value={issuedAtFilter}
                          onChange={handleChangeDateFilter}
                          variant="outlined"
                          className={classes.filterDropdown}
                        >
                          {filterDateOptions &&
                            Object.keys(filterDateOptions).length > 0 &&
                            filterDateOptions.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </div>
                    </Grid>
                    {showFilterDatePicker ? (
                      <>
                        <Grid item sm={4} md={4}>
                          <DatePicker
                            variant="outlined"
                            margin="dense"
                            label={translate('issuedAtFrom')}
                            format="DD/MM/yyyy"
                            fullWidth
                            value={issuedAtFrom}
                            onChange={(value) =>
                              handleChangeIssuedAtFrom(value)}
                            placeholder="DD/MM/YYYY"
                          />
                        </Grid>
                        <Grid item sm={4} md={4}>
                          <DatePicker
                            variant="outlined"
                            margin="dense"
                            label={translate('issuedAtTo')}
                            format="DD/MM/yyyy"
                            value={issuedAtTo}
                            fullWidth
                            onChange={(value) => handleChangeIssuedAtTo(value)}
                            placeholder="DD/MM/YYYY"
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    container
                    justify="flex-end"
                    alignItems="center"
                    md={4}
                    sm={4}
                  >
                    <MTableToolbar {...props} />
                  </Grid>
                </Grid>
              </>
            ),
          }}
          localization={{
            toolbar: {
              searchTooltip: translate('Common:search'),
              searchPlaceholder: translate('Common:search'),
            },
            pagination: {
              labelRowsSelect: translate('Common:rows'),
              labelDisplayedRows: ` {from}-{to} ${translate(
                'Common:of'
              )} {count}`,
              firstTooltip: translate('Common:firstPage'),
              previousTooltip: translate('Common:previousPage'),
              nextTooltip: translate('Common:nextPage'),
              lastTooltip: translate('Common:lastPage'),
            },
            header: {
              actions: translate('Common:actions'),
            },
          }}
          actions={
            [
              // rowData => ({
              //   icon: tableIcons.Download,
              //   tooltip: translate('downloadPdf'),
              //   onClick: async (event, rowData) => {
              //     setIsLoading(true);
              //     window.open((await downloadPdf(rowData.id)).data.invoiceFile, '_blank');
              //     setIsLoading(false);
              //   },
              // }),
              // rowData => ({
              //   icon: tableIcons.Edit,
              //   tooltip: translate('edit'),
              //   onClick: async (event, rowData) => {
              //     // TODO: investigate why initialValue in form not update
              //     await getInvoice(rowData.id);
              //     history.push(`/invoices/${rowData.id}/edit`);
              //   },
              // })
            ]
          }
        />
        {isLoading && (
          <LoadingModal open={isLoading} handleClose={handleCloseLoading} />
        )}
      </PortletContent>
    </Portlet>
  );
};

InvoicesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(InvoiceListTableStyles)
)(InvoicesTable);

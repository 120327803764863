import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography, Card, Tooltip } from '@material-ui/core';
import styles from './categories.module.scss';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import { CategoryModal } from 'pages/Dashboard/components';
import { useDispatch } from 'react-redux';
import { updateCategories } from 'redux/dashboard';

const renderTag = (t, tags) => {
  const deepClone = [...tags];
  if (!Array.isArray(tags) || !tags.length > 0)
    return <Grid className={styles.tag}>{t('noTagAdded')}</Grid>;
  const showingTags = deepClone.splice(0, 3);
  return (
    <>
      {showingTags.map((tag, index) => (
        <Grid key={index} className={styles.tag}>
          {tag.name}
        </Grid>
      ))}
      {deepClone.length > 0 && (
        <Tooltip
          title={deepClone.map((item) => item.name).join(', ')}
          className={styles.cursor}
        >
          <Grid className={styles.tag}>+{deepClone.length}</Grid>
        </Tooltip>
      )}
    </>
  );
};

const Dashboard = (props) => {
  const { enterpriseAccountId, selectedCategories = [], setSelectedCategories = [], staffCategories = [] } = props;
  const { t } = useTranslation('Dashboard');
  const dispatch = useDispatch();
  const [openCategoryModal, setOpenCategoryModal] = useState(false);

  const handleClickCategory = () => setOpenCategoryModal(true);

  const handleCloseSelectCategoryModal = () => {
    setOpenCategoryModal(false);
  };

  const handleCategorySelection = (values) => {
    setSelectedCategories(values);
    dispatch(updateCategories(values));
    setOpenCategoryModal(false);
  };

  return (
    <>
      <Box mr={2}>
        <Card className={styles.card} onClick={handleClickCategory}>
          <Grid container>
            <Typography variant="body1" className={styles.titleFilter}>
              {t('categoryManage')}
            </Typography>
            <KeyboardArrowDownIcon />
          </Grid>
          <Box display="flex" mt={1}>
            {renderTag(t, selectedCategories)}
          </Box>
          {}
        </Card>
      </Box>
      {openCategoryModal && (
        <CategoryModal
          open={openCategoryModal}
          handleCloseSelectCategoryModal={handleCloseSelectCategoryModal}
          translate={t}
          categories={selectedCategories}
          isHideManageCategoryTag
          handleCategorySelection={handleCategorySelection}
          staffCategories={staffCategories}
          enterpriseAccountId={enterpriseAccountId}
        />
      )}
    </>
  );
};

export default Dashboard;

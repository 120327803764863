// import { setIsLoading } from '../redux/global';
import { POST, GET, PATCH } from 'lib/api';
import { getCompanyWorkSettings } from './company';
import { getJobItems } from './job';
import { OnlyDecimal } from 'components/converters';

// Actions
const SET_CURRENT_INVOICE = 'SET_CURRENT_INVOICE';
const SET_CLEAR_INVOICE = 'SET_CLEAR_INVOICE';
const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';

const initialState = {
  invoice: {},
  modalVisibility: false,
  modalType: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_INVOICE:
      return {
        ...state,
        invoice: {
          ...action.invoice,
        }
      };

    case SET_MODAL_VISIBILITY:
      return {
        ...state,
        modalVisibility: action.visibility,
        modalType: action.modalType,
      };

    case SET_CLEAR_INVOICE:
      return {
        ...state,
        invoice: {},
        modalVisibility: false,
        modalType: null,
      };

    default: return state;
  }
}

// Action Creators
export function setCurrentInvoice(invoice) {
  return { type: SET_CURRENT_INVOICE, invoice };
}

export function clearInvoice() {
  return { type: SET_CLEAR_INVOICE };
}

export function setModalVisibility(visibility, modalType) {
  return { type: SET_MODAL_VISIBILITY, visibility, modalType };
}

export function setSelectedClient(client) {
  return { type: SET_SELECTED_CLIENT, client };
}


// side effects, only as applicable
// e.g. thunks, epics, etc
export function getInvoice(id) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/invoices/${id}`, accessToken).then((response) => {
      if (response.status === 200) {
        dispatch(setCurrentInvoice(response.data));
      };
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function addInvoice(invoiceData) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return POST('invoices', accessToken, { data: invoiceData })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

export function getInvoices(params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    // TODO: term pass v2 url, will remove when ingrate v2 invoices
    return GET(params.url ? params.url : '/invoices', accessToken, params).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function updateInvoiceDetails(invoiceDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const invoiceId = invoiceDetails.invoiceValues ? invoiceDetails.invoiceValues.id : invoiceDetails.id;
    return PATCH(`/invoices/${invoiceId}`, accessToken, { data: invoiceDetails })
      .then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error.response);
      }).catch(err => {
        return Promise.reject(err);
      });
  };
}

export function sendInvoiceToClient(payload) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/invoice-action', accessToken, { data: payload })
      .then(response => {
        return Promise.resolve(response);
      },(error) => {
        return Promise.reject(error.response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
};

export function uploadSignature(invoiceId, formData) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const params = { type: 'INVOICE_SIGNATURE', id: invoiceId };
    const data = { params, data: formData };

    return POST('/upload', accessToken, data)
      .then(response => {
        return Promise.resolve(response);
      },(error) => {
        return Promise.reject(error.response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function downloadPdf(invoiceId) {
  return ( dispatch, getState) => {
    const { accessToken } = getState().auth;

    return GET(`/invoices/${invoiceId}`, accessToken, { params: { pdf: true } })
      .then(response => {
        return Promise.resolve(response);
      },(error) => {
        return Promise.reject(error.response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function initInvoiceFormData(jobIds, client) {
  return async (dispatch, getState) => {
    try {
      const response = await dispatch(getCompanyWorkSettings());
      await dispatch(getJobItems(jobIds));
      const { invoiceSetting } = response.data;
      const defaultSetting = getState().config.configs.invoiceSetting;
      const { paymentDues } = getState().config.configs;
      const { jobItems } = getState().job;
      let subtotal = 0;
      let invoiceTaxAmount = 0;
      const formatedItems = [];
      const invoiceDiscount = 0;
      let totalAmount = 0;

      jobItems &&
      jobItems.forEach(item => {
        formatedItems.push({
          name: item.name,
          currency: item.currency,
          unitPrice: item.unitPrice,
          total: item.total,
          quantity: item.quantity,
          description: item.description,
          taxRate: item.taxRate || {},
          taxComponent: item.taxComponent || {}
        });
        subtotal += parseFloat(item.total);
        let itemTaxPercentage = 0;
        if (item.taxRate) {
          itemTaxPercentage += item.taxRate.percentage || 0;
        }

        if (item.taxComponent) {
          itemTaxPercentage += (item.taxComponent.taxRates || []).reduce((sum, taxRate) => {
            return sum + parseFloat(taxRate.percentage || 0);
          }, 0);
        }

        const itemTaxAmount = parseFloat(item.total) * parseFloat(itemTaxPercentage) / 100;
        invoiceTaxAmount += itemTaxAmount;
      });

      totalAmount = parseFloat(subtotal) - parseFloat(invoiceDiscount) + parseFloat(invoiceTaxAmount);
      totalAmount = totalAmount >= 0 ? totalAmount : 0;

      const { useCompanyName, companyName, firstName, lastName } = client;
      const phoneNumbers = client.contactPhones || client.phoneNumbers;
      if (Array.isArray(phoneNumbers) && phoneNumbers.length) {
        const mainPhoneNumber = phoneNumbers.find(phone => phone.typeId === 'MAIN');
        client.countryPhoneCode = mainPhoneNumber.countryPhoneCode;
        client.unformattedPhoneNumber = mainPhoneNumber.phoneNumber;
        client.phoneNumber = client.countryPhoneCode + client.unformattedPhoneNumber;
      }

      const clientName = useCompanyName ? companyName : [firstName, lastName].filter(Boolean).join(' ');
      let defaultSubject = (invoiceSetting && invoiceSetting.defaultSubject) ||
        (defaultSetting && defaultSetting.defaultSubject);
      defaultSubject = defaultSubject && defaultSubject.replace(/{clientName}/gi, clientName);
      defaultSubject = defaultSubject && defaultSubject.replace(/{jobID}/gi, jobIds);

      const clientMessage = (invoiceSetting && invoiceSetting.defaultNote) ||
        (defaultSetting && defaultSetting.defaultNote);

      // Check and modify payment due and due by
      let paymentDue = (invoiceSetting && invoiceSetting.defaultPaymentDue) || 0;
      if (paymentDue < 0 ) paymentDue = 0;

      const dueBy = new Date();

      if (paymentDue > 0 ) {
        dueBy.setDate(dueBy.getDate() + paymentDue);
      }
      // If the default payment due is not in the paymentDues list, then force set it
      if (!paymentDues.map(item => item.id).includes(paymentDue)) {
        paymentDue = -1;
      }

      dispatch(setCurrentInvoice({
        client,
        jobIds,
        items: formatedItems,
        subtotal: OnlyDecimal(subtotal),
        tax: OnlyDecimal(invoiceTaxAmount),
        discount: OnlyDecimal(invoiceDiscount),
        total: OnlyDecimal(totalAmount),
        subject: defaultSubject,
        clientMessage,
        paymentDue,
        dueBy
      }));

      return Promise.resolve('ok');
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };
}
import React, { useState, useEffect } from "react";

// Externals
import { Link, withRouter } from "react-router-dom";
import { Trans } from "react-i18next";

// Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, change, formValueSelector, submit } from "redux-form";
import { setModalVisibility, setIsSaving } from "redux/job";
import { saveClientDetails } from "redux/client";
import { addProperty, getProperties, updateProperty } from "redux/property";

// Material components
// Material helpers
import { Button, Grid, Typography, withStyles, Box } from "@material-ui/core";

// Material icons
import { Edit as EditIcon, Add as AddIcon } from "@material-ui/icons";

// Customised components
import { TextInput } from "components/inputs";
import { CreateClientModal } from "../modals";
import {
  ContactDetails,
  AddressDetails,
  SelectClientModal,
  SelectPropertyModal,
  CreatePropertyModal,
  UpdatePropertyModal,
} from "pages/Dashboard/components";
import { showErrorMessage } from "lib/notifier";
import { HubInput } from "../inputs";

// Component styles
import { JobClientDetailsFormStyle } from "./styles";
import ExternalSrc from "assets/images/icons/details.svg";

const jobForm = formValueSelector("jobForm");

const mapStateToProps = (state) => ({
  job: state.form.jobForm && state.form.jobForm.values,
  modalVisibility: state.job.modalVisibility,
  modalType: state.job.modalType,
  selectedClient: jobForm(state, "selectedClient"),
  selectedProperty: jobForm(state, "selectedProperty"),
});

const mapDispatchToProps = (dispatch) => ({
  addProperty: (values, callback) => dispatch(addProperty(values, callback)),
  updateProperty: (propertyId, values, callback) =>
    dispatch(updateProperty(propertyId, values, callback)),
  getProperties: (clientId, callback) =>
    dispatch(getProperties(clientId, callback)),
  saveClientDetails: (values, callback) =>
    dispatch(saveClientDetails(values, callback)),
  setIsSaving: (value) => {
    dispatch(setIsSaving(value));
  },
  setModalVisibility: (visibility, type) =>
    dispatch(setModalVisibility(visibility, type)),
  setSelectedClient: (client) =>
    dispatch(change("jobForm", "selectedClient", client)),
  setSelectedProperty: (property) =>
    dispatch(change("jobForm", "selectedProperty", property)),
  updateJobTitle: (title) => dispatch(change("jobForm", "title", title)),
  submitNewProperty: () => dispatch(submit("newProperty")),
});

function JobClientDetailsForm(props) {
  const {
    addProperty,
    classes,
    getProperties,
    job,
    match,
    modalVisibility,
    modalType,
    saveClientDetails,
    selectedClient,
    selectedProperty,
    setIsSaving,
    setSelectedClient,
    setSelectedProperty,
    setModalVisibility,
    submitNewProperty,
    translate,
    updateProperty,
    updateJobTitle,
    fixedClient,
  } = props;
  const [openSelectClient, setOpenSelectClient] = useState(false);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [openSelectProperty, setOpenSelectProperty] = useState(false);
  const [openCreateProperty, setOpenCreateProperty] = useState(false);
  const [openUpdateProperty, setOpenUpdateProperty] = useState(false);

  useEffect(() => {
    if (modalType === "client") {
      setOpenSelectClient(modalVisibility);
    } else if (modalType === "property") {
      setOpenSelectProperty(modalVisibility);
    }
  }, [modalVisibility, modalType]);

  function handleSelectClient() {
    if (!fixedClient) {
      setOpenSelectClient(!openSelectClient);
      setModalVisibility(false, null);
    }
  }

  function handleClose(type) {
    if (type === "select") {
      setOpenSelectClient(false);
      setOpenSelectProperty(false);
      setModalVisibility(false, null);
      setIsSaving(false);
    } else if (type === "create") {
      setOpenCreateClient(false);
      setOpenCreateProperty(false);
      setModalVisibility(false, null);
      setIsSaving(false);
    } else if (type === "update") {
      setOpenUpdateProperty(false);
      setModalVisibility(false, null);
      setIsSaving(false);

      if (!(selectedProperty.lat && selectedProperty.lng)) {
        setSelectedProperty(null);
      }
    }
  }

  function handleClientSelect(client) {
    const {
      id,
      displayName,
      properties,
      emails,
      phoneNumbers,
      contactPhones,
      contactEmails,
    } = client;

    const clientEmail = contactEmails || emails;
    const clientPhone = contactPhones || phoneNumbers;

    const contactEmail =
      Array.isArray(clientEmail) &&
      clientEmail.length &&
      clientEmail.find((email) => email.typeId === "MAIN");
    const contactPhoneNumber =
      Array.isArray(clientPhone) &&
      clientPhone.length &&
      clientPhone.find((phoneNumber) => phoneNumber.typeId === "MAIN");

    const clientName = displayName;
    if (id && clientName) {
      // Update client name to job title once user chose client. Just only work for the first time
      let jobTitle = job.title;
      jobTitle = jobTitle && jobTitle.replace(/{clientName}/gi, clientName);
      updateJobTitle(jobTitle);
      setSelectedClient({
        id,
        name: clientName,
        email: (contactEmail && contactEmail.email) || null,
        phoneNumber:
          (contactPhoneNumber && contactPhoneNumber.phoneNumber) || null,
      });
    }

    if (properties && properties.length > 0) {
      if (properties.length < 2) {
        const property = properties[0];
        setSelectedProperty(property);
        setOpenSelectClient(false);
        setModalVisibility(false, null);

        if (property && !(property.lat && property.lng)) {
          setOpenUpdateProperty(true);
        }
      } else if (properties.length > 1) {
        setOpenSelectProperty(true);
        setOpenSelectClient(false);
        setModalVisibility(false, null);
      }
    } else if (properties.length <= 0) {
      setOpenCreateProperty(true);
      setOpenSelectClient(false);
      setModalVisibility(false, null);
    }
  }

  function handleAddNewClient() {
    setOpenSelectClient(false);
    setOpenCreateClient(true);
    setModalVisibility(false, null);
  }

  function handlePropertySelect(property) {
    setSelectedProperty(property);
    setOpenSelectProperty(false);
    setModalVisibility(false, null);

    if (property && !(property.lat && property.lng)) {
      setOpenUpdateProperty(true);
    }
  }

  function handleAddNewProperty() {
    setOpenSelectProperty(false);
    setOpenCreateProperty(true);
    setModalVisibility(false, null);
  }

  function handleSubmitNewClient(client) {
    const clientDetails = prepareClientDataBeforeSave(client);

    saveClientDetails(clientDetails, (response) => {
      if (response.status === 200) {
        setOpenCreateClient(false);
        handleClientSelect(response.data);
      } else {
        showErrorMessage(response.data.message);
      }
    });
  }

  function prepareClientDataBeforeSave(values) {
    const { properties } = values;

    const isBillingAddress = properties.find((item) => item.isBillingAddress);
    if (isBillingAddress) {
      delete isBillingAddress["isBillingAddress"];
      Object.assign(values, { billingAddress: isBillingAddress });
    }

    return values;
  }

  function handleChangeAddress() {
    function callback(data, fullResult) {
      // setProperties(data);  @hoan: 'properties' state is never used, why would we want to setProperties?

      if (fullResult.total < 2) {
        getProperties(selectedClient.id);
        // setOpenCreateProperty(true);
        setOpenSelectProperty(true);
      } else {
        setOpenSelectProperty(true);
      }
    }

    getProperties(selectedClient.id, callback);
  }

  function handleSubmitNewProperty(values) {
    const callback = (newProperty) => {
      setSelectedProperty(newProperty);
      setOpenCreateProperty(false);
      setOpenSelectProperty(false);
    };

    values.ownerId = selectedClient.id;

    addProperty(values, callback);
  }

  function handleSubmitUpdateProperty(values) {
    const callback = (newProperty) => {
      setSelectedProperty(newProperty);
      setOpenUpdateProperty(false);
      setOpenUpdateProperty(false);
    };

    values.ownerId = selectedClient.id;
    delete values.pk;
    delete values.tableData;

    updateProperty(values.id, values, callback);
  }

  const handleClickViewDetails = () => {
    window.open(`/clients/${selectedClient.id}`, "_blank");
  };

  return (
    <>
      <Grid item container direction="column">
        <Grid item container direction="row" className={classes.jobClientName}>
          <Typography className={classes.client} variant="h4">
            {translate("Common:client")}:
          </Typography>
          {selectedClient && !!Object.keys(selectedClient).length && (
            <>
              {match.path === "/jobs/:jobId/edit" && (
                <>
                  <Typography variant="h4">
                    <Trans i18nKey="Job:jobForClient">
                      {selectedClient.name}
                    </Trans>
                  </Typography>
                  <Button
                    size="medium"
                    variant="contained"
                    className={classes.viewDetailsBtn}
                    onClick={handleClickViewDetails}
                  >
                    <img
                      src={ExternalSrc}
                      alt="external_src"
                      className="mr_5"
                    />
                    <Typography variant="h5">
                      {translate("viewDetails")}
                    </Typography>
                  </Button>
                </>
              )}

              {match.path !== "/jobs/:jobId/edit" && (
                <>
                  <Button
                    color="primary"
                    size="medium"
                    href="#"
                    className={classes.clientButton}
                    onClick={handleSelectClient}
                  >
                    <Grid container direction="row">
                      {selectedClient.name}
                      {!fixedClient && (
                        <EditIcon
                          color="primary"
                          className={classes.linkIcon}
                        />
                      )}
                    </Grid>
                  </Button>
                  <Button
                    size="medium"
                    variant="contained"
                    className={classes.viewDetailsBtn}
                    onClick={handleClickViewDetails}
                  >
                    <img
                      src={ExternalSrc}
                      alt="external_src"
                      className="mr_5"
                    />
                    <Typography variant="h5">
                      {translate("viewDetails")}
                    </Typography>
                  </Button>
                </>
              )}
            </>
          )}

          {(!selectedClient || !Object.keys(selectedClient).length) && (
            <Button
              color="primary"
              size="medium"
              variant="contained"
              onClick={handleSelectClient}
            >
              <AddIcon fontSize="small" />
              {translate("Job:selectClient")}
            </Button>
          )}
        </Grid>
        {selectedClient && !!Object.keys(selectedClient).length && (
          <Grid
            item
            container
            direction="row"
            className={classes.jobClientDetail}
          >
            <Grid item container direction="column" md={6} sm={12}>
              {selectedProperty && !!Object.keys(selectedProperty).length ? (
                <>
                  <AddressDetails
                    address={selectedProperty}
                    translate={translate}
                    title={translate("Common:propertyAddress")}
                  />
                  <Link
                    href="#"
                    className={classes.changeAddress}
                    onClick={handleChangeAddress}
                  >
                    <Typography className={classes.changeText}>
                      {translate("Job:change")}
                    </Typography>
                  </Link>
                </>
              ) : (
                <Link
                  href="#"
                  className={classes.addAddress}
                  onClick={handleChangeAddress}
                >
                  <Typography className={classes.addText}>
                    {translate("Client:addPropertyAddress")}
                  </Typography>
                </Link>
              )}
            </Grid>
            <Grid item container direction="column" md={6} sm={12}>
              <ContactDetails
                contactEmail={selectedClient.email}
                contactPhoneNumber={selectedClient.phoneNumber}
                translate={translate}
              />
            </Grid>
          </Grid>
        )}
        <Field
          name="title"
          label={translate("Job:title")}
          component={TextInput}
          variant="outlined"
          margin="dense"
        />
        <Field
          name="orderId"
          label={translate("Job:orderId")}
          component={TextInput}
          variant="outlined"
          margin="dense"
        />
        <Box mt={2}>
          <Field
            name="dn"
            label={translate("Job:deliveryNote")}
            component={TextInput}
            variant="outlined"
            margin="dense"
          />
        </Box>
        <Field
          name="description"
          label={translate("Job:description")}
          component={TextInput}
          multiline
          rows="4"
          variant="outlined"
          margin="dense"
        />
        <Box mt={3}>
          <Typography variant="h5">{translate("Job:hubInfo")}</Typography>
          <Field
            name="hub"
            label={translate("Job:hubName")}
            component={HubInput}
            variant="outlined"
            margin="dense"
            translate={translate}
          />
        </Box>
      </Grid>
      {openSelectClient && (
        <SelectClientModal
          open={openSelectClient}
          handleClose={() => handleClose("select")}
          handleClientSelect={(client) => handleClientSelect(client)}
          handleAddNewClient={handleAddNewClient}
          translate={translate}
        />
      )}
      {openCreateClient && (
        <CreateClientModal
          open={openCreateClient}
          handleClose={() => handleClose("create")}
          handleClientSelect={handleClientSelect}
          handleSubmit={(value) => handleSubmitNewClient(value)}
          handleCancelClientForm={() => handleClose("create")}
          translate={translate}
        />
      )}
      {openSelectProperty && (
        <SelectPropertyModal
          open={openSelectProperty}
          selectedClient={selectedClient}
          handleClose={() => handleClose("select")}
          handlePropertySelect={(property) => handlePropertySelect(property)}
          handleAddNewProperty={handleAddNewProperty}
          translate={translate}
          form="job"
        />
      )}
      {openCreateProperty && (
        <CreatePropertyModal
          open={openCreateProperty}
          selectedClient={selectedClient}
          handleClick={submitNewProperty}
          handleClose={() => handleClose("create")}
          onSubmit={handleSubmitNewProperty}
          translate={translate}
        />
      )}
      {openUpdateProperty && (
        <UpdatePropertyModal
          open={openUpdateProperty}
          selectedClient={selectedClient}
          handleClick={submitNewProperty}
          handleClose={() => handleClose("update")}
          onSubmit={handleSubmitUpdateProperty}
          translate={translate}
        />
      )}
    </>
  );
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(JobClientDetailsFormStyle)
)(JobClientDetailsForm);

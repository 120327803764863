import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import {
  // Material components
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grow,
  IconButton,
  Typography,

  // Material helpers
  withStyles
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// Customised components
import PaymentForm from './PaymentForm';

// Component styles
import CreatePaymentModalStyle from './styles';

const newDate = new Date();
const paymentForm = formValueSelector('paymentForm');

const mapStateToProps = (state, props) => ({
  initialValues: {
    amount: state.invoice.invoice.invoiceBalance,
    chequeNumber: null,
    notes: `${props.translate('Common:paymentAppliedToInvoice')} ${state.invoice.invoice.id}`,
    invoiceId: state.invoice.invoice.id,
    paymentDate: newDate,
    paymentMethod: 'CASH',
    paymentType: 'INVOICE',
    reference: null,
  },
  formPaymentMethod: paymentForm(state, 'paymentMethod'),
});

const mapDispatchToProps = dispatch => ({
});

function CreatePaymentModal(props) {
  const {
    classes,
    open,
    formPaymentMethod,
    handleClose,
    handleSubmit,
    payment,
    paymentMethods,
    paymentTypes,
    submitting,
    translate,
    invalid
  } = props;

  const invoices = [];

  if (payment && payment.invoices && payment.invoices.length > 0) {
    payment.invoices.map(invoice => {
      invoice.name = `${translate('Common:invoice')} ${invoice.id}`;
      return invoices.push(invoice);
    });
  }
  const appliedToOptions = paymentTypes.filter(a => a.id === 'ACCOUNT').concat(invoices);

  return (
    <Dialog
      maxWidth='lg'
      aria-labelledby="customized-dialog-title"
      open={open}
      transition={Grow}
    >
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <DialogTitle disableTypography className={classes.teamDialogTitle}>
          <Typography variant='h4' className={classes.teamDialogTitleText}>{translate('Common:recordPayment')}</Typography>
          {open ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <PaymentForm
            appliedToOptions={appliedToOptions}
            newPayment
            paymentMethods={paymentMethods}
            formPaymentMethod={formPaymentMethod}
            translate={translate}
          />
        </DialogContent>
        <DialogActions className={classes.btnGroup}>
          <Button
            color="primary"
            onClick={handleClose}
          >
            {translate('Common:cancel')}
          </Button>
          <Button
            color="primary"
            disabled={submitting || invalid}
            variant="contained"
            size="small"
            type="submit"
          >
            {translate('Common:save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(CreatePaymentModalStyle)
)(reduxForm({ form: 'paymentForm', enableReinitialize: true, destroyOnUnmount: true })(CreatePaymentModal));

export default {
  planBilling: "แพลนและราคา",
  accountOverview: "ภาพรวมของบัญชี",
  activeSince: "เริ่มใช้งานตั้งแต่",
  accountStatus: "สถานะของบัญชี",
  active: "ใช้งานอยู่",
  inactive: "เลิกใช้งาน",
  currentPlan: "แพลนปัจจุบัน",
  trial: "Trial",
  pro: "PRO",
  trialWillEnd: "การทดลองใช้ฟรีในแพลน {{plan}} ของคุณจะหมดอายุใน {{time}} วัน",
  trialWasEnd:
    "Your trial for {{plan}} plan was end. Please complete the subscription to continue using WorkPulse",
  nextBillingDate: "วันวางบิลถัดไป",
  changePlan: "เปลี่ยนแพลน",
  deactivate: "ยกเลิก",
  activate: "Activate",
  deactivateAccount: "ยกเลิกบัญชี",
  deactivateTrial:
    "การทดลองใช้ฟรีของคุณจะหมดอายุโดยอัตโนมัติ ไม่จำเป็นต้องยกเลิกบัญชี",
  userNum: "ผู้ใช้งาน",
  onYourPlan: "สำหรับแพลนของคุณ",
  manageSubscription: "จัดการแพลนสมาชิก",
  selectPlan: "เลือกแพลนสมาชิก",
  plan: "แพลน",
  manageAddOns: "จัดการส่วนเสริม",
  billingCycle: "รอบการวางบิล",
  addOns: "ส่วนเสริม",
  additionalUsers: "ผู้ใช้งานเพิ่มเติม",
  teamManagement: "การบริหารจัดการทีมงาน",
  timesheet: "บันทึกการทำงาน",
  teamGPSTracking: "การติดตาม GPS ",
  marketing: "การตลาด",
  users: "ผู้ใช้งาน",
  total: "ทั้งหมด",
  taxInclusive: "รวมภาษี",
  proceedToCheckout: "ดำเนินการต่อ",
  addOnPricePerMonth: "{{currency}},{{price}} ต่อผู้ใช้งาน/เดือน",
  planIncluded: "แพลนนี้รวม",
  BASIC: "WorkPulse ไลท์",
  PLUS: "WorkPulse พลัส",
  PRO: "WorkPulse โปร",
  planFeature: "",
  submitting: "กำลังส่ง...",
  planIncludes: "แพลนนี้รวม",
  updateSubscription: "อัปเดตแพลนสมาชิก",
  pleaseInputCard: "กรุณาใส่ข้อมูลบัตร",
  subscriptionPlanSaved: "บันทึกแพลนสมาชิกแล้ว",
  paymentMethodSaved: "บันทึกข้อมูลการชำระเงินแล้ว",
  trialExpiredMessage:
    "ระยะเวลาทดลองใช้ฟรีจบแล้ว กรุณาสมัครสมาชิกเพื่อใช้ WorkPulse ต่อ",
  checkoutAgreementMessage:
    "ข้าพเจ้าตกลงยอมรับ <0>ข้อตกลงสมาชิก</0> และ <1>นโยบายความเป็นส่วนตัว</1> และเข้าใจว่าสมาชิกภาพของข้าพเจ้าเริ่มต้นทันที",
  subscriptionAgreement: "ข้อตกลงสมาชิก",
  privacyPolicy: "นโยบายความเป็นส่วนตัว",
  needMoreTime: "มีคำถามเพิ่มเติม?",
  contactCustomer: "ติดต่อฝ่ายบริการลูกค้า",
  FAQ: "คำถามพบบ่อย",
  learnMore: "เรียนรู้เพิ่มเติม",
  billingDetails: "รายละเอียดการชำระเงิน",
  cardNumber: "เลขบัตร",
  expiresOn: "หมดอายุเมื่อ",
  edit: "แก้ไข",
  addCard: "เพิ่มบัตร",
  subtotal: "รวม",
  creditCardRate: "ค่าธรรมเนียมบัตรเครดิต",
  logout: "Logout",
  month: "month",
  year: "year",
  today: "today",
  summary: "Summary",
  monthly: "Monthly",
  annual: "Annual",
  now: "now",
  yourCompanyWasDeactivated:
    "Your company account was deactivated. Please contact your admin or account owner to activate it",
};

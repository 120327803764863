export default {
  newCategory: 'New category tag',
  addSubCategory: 'Add sub-category tag',
  addSubSubCategory: 'Add sub-sub-category tag',
  categories: 'Category tags',
  deleteCategory: 'Delete "<0><0></0></0>" ?',
  sureToDeleteCategory: 'Are you sure you want to delete this category tag?',
  noticeForSubcategories:
    'All subcategory tags under this category tag will be deleted.',
  noticeForItemsAssociation:
    'Items associated to this category and its subcategory will not be deleted but they will no longer be associated to this category and its subcategory.',
  selectCategory: 'Select tag',
  noCategoryAdded: 'No category tag added',
  category: 'Category Tag',
  manageCategory: 'Manage category tags',
  clearSelection: 'Clear Selection',
  selectAll: 'Select all',
  selectDeselect: 'Select/Deselect items inside',
  invalidCategoryName: 'Invalid Category Name.',
};

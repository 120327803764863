import { GET } from 'lib/api';

// Actions
const SET_CALENDAR_MENU = 'SET_CALENDAR_MENU';
const SET_CLIENT_MENU = 'SET_CLIENT_MENU';
const SET_TEAM_MENU = 'SET_TEAM_MENU';
const SET_WORK_MENU = 'SET_WORK_MENU';
const SET_BUSINESS_MANAGEMENT_MENU = 'SET_BUSINESS_MANAGEMENT_MENU';
const SET_GPS_MENU = 'SET_GPS_MENU';
const SET_TIMESHEET_MENU = 'SET_TIMESHEET_MENU';
const SET_ACCOUNT_MENU = 'SET_ACCOUNT_MENU';
const SET_IS_UPDATE_SIDE_BAR = 'SET_IS_UPDATE_SIDE_BAR';

const initialState = {
  calendar: false,
  client: false,
  team: false,
  work: false,
  businessManagement: false,
  gps: false,
  timesheet: false,
  account: false,
  isUpdateSideBar: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CALENDAR_MENU:
      return {
        ...state,
        calendar: !state.calendar
      };

    case SET_CLIENT_MENU:
      return {
        ...state,
        client: !state.client
      };

    case SET_TEAM_MENU:
      return {
        ...state,
        team: !state.team
      };

    case SET_WORK_MENU:
      return {
        ...state,
        work: !state.work
      };

    case SET_BUSINESS_MANAGEMENT_MENU:
      return {
        ...state,
        businessManagement: !state.businessManagement
      };

    case SET_GPS_MENU:
      return {
        ...state,
        gps: !state.gps
      };

    case SET_TIMESHEET_MENU:
      return {
        ...state,
        timesheet: !state.timesheet
      };

    case SET_ACCOUNT_MENU:
      return {
        ...state,
        account: !state.account
      };
    case SET_IS_UPDATE_SIDE_BAR:
      return {
        ...state,
        isUpdateSideBar: action.isUpdateSideBar
      };

    default: return state;
  }
}

// Action Creators
export function setMenuStatus(menu) {
  switch(menu) {
    case 'calendar':
      return { type: SET_CALENDAR_MENU };
    case 'client':
      return { type: SET_CLIENT_MENU };
    case 'team':
      return { type: SET_TEAM_MENU };
    case 'work':
      return { type: SET_WORK_MENU };
    case 'businessManagement':
      return { type: SET_BUSINESS_MANAGEMENT_MENU };
    case 'gps':
      return { type: SET_GPS_MENU };
    case 'timesheet':
      return { type: SET_TIMESHEET_MENU };
    case 'account':
      return { type: SET_ACCOUNT_MENU };
    default:
      return {};
  }
}

export function setIsUpdateSideBar(check) {
  return {
    type: SET_IS_UPDATE_SIDE_BAR,
    isUpdateSideBar: check
  };
}

export function getUpdateSideBar() {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET('/company-permission-view', accessToken)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

// Selector
export const getIsUpdateSideBar = (state) => state.sidebar.isUpdateSideBar;

// React/Redux component
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reset, SubmissionError } from 'redux-form';

// Styles
import styles from './styles.module.css';
import { Grid, Button } from '@material-ui/core';
// Child component
import PasswordForm from './passwordForm';
import LanguageForm from './languageForm';
import AccountDetails from './accountDetails';
import { Portlet, PortletContent, PortletHeader, PortletLabel } from 'pages/Dashboard/components';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
import { updateUser, accountDetails } from 'redux/account';
import i18n from 'i18next';
import { setDateTimeFormat } from 'redux/config';

const AccountSettingForm = props => {
  const { translate, updateUser, accountDetails, resetPwdForm, updateDateTimeFormat } = props;
  const [accountInfo, setAccountInfo] = useState(null);
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  useEffect(() => {
    async function fetchAccountDetails() {
      try {
        const res = await accountDetails();
        if (res.status === 200) {
          setAccountInfo(res.data);
        }
      } catch (error) {
        showErrorMessage(error);
      }
    }
    fetchAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function validatePasswordForm(values) {
    let errors = { message: {} };

    if (!values.currentPassword) {
      errors = {
        ...errors,
        currentPassword: 'required',
        message: {
          ...errors.message,
          currentPassword: translate('AccountSetting:needCurrentPassword'),
        }
      };
    }

    if (!values.password) {
      errors = {
        ...errors,
        password: 'required',
        message: {
          ...errors.message,
          password: translate('AccountSetting:pleaseEnterNewPassword'),
        }
      };
    }

    if (!values.confirmPassword) {
      errors = {
        ...errors,
        confirmPassword: 'required',
        message: {
          ...errors.message,
          confirmPassword: translate('AccountSetting:pleaseRepeatNewPassword'),
        }
      };
    }

    // Show error message if user miss any field
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message).join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors).join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));
      throw new SubmissionError(errors);
    }

    // Check more if they fill all field alrd to make data is valid before submiting to BE.
    if (!(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/.test(values.password))) {
      errors = {
        ...errors,
        password: 'required',
      };
      showErrorMessage(translate('Auth:hintPassword'));
      throw new SubmissionError(errors);
    }

    if (values.password !== values.confirmPassword) {
      errors = {
        ...errors,
        confirmPassword: 'required'
      };

      showErrorMessage(translate('AccountSetting:passwordConfirmationNotMatch'));
      throw new SubmissionError(errors);
    }

    if (values.password === values.currentPassword) {
      errors = {
        ...errors,
        password: 'required',
      };
      showErrorMessage(translate('AccountSetting:newPasswordHasToBeDifferentOldPassword'));
      throw new SubmissionError(errors);
    }
  }

  async function handleUpdatePassword(values) {
    validatePasswordForm(values);

    try {
      const response = await updateUser(values);
      if (response.status === 200) {
        showSuccessMessage(translate('AccountSetting:updatePasswordSuccessfully'));
        resetPwdForm();
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  async function handleUpdateLanguage(values) {
    try {
      const response = await updateUser(values);
      if (response.status === 200) {
        showSuccessMessage(translate('AccountSetting:updateLanguageSuccessfully'));
        i18n.changeLanguage(values.language);
        updateDateTimeFormat(values.timeFormat);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  function togglePwdForm() {
    setShowPasswordForm(!showPasswordForm);
  }

  return (
    <div className={styles.accountSetting}>
      <Grid container justify='center'>
        <Grid item xl={10} lg={10} md={12} xs={12}>
          <Portlet>
            <PortletHeader className={styles.cardHeader}>
              <PortletLabel className={styles.cardLabel} title={translate('AccountSetting:personalDetails')} />
            </PortletHeader>
            <PortletContent className={styles.contentPortlet}>
              { accountInfo &&
                <AccountDetails
                  translate={translate}
                  styles={styles}
                  accountInfo={accountInfo}
                />}
              <LanguageForm
                translate={translate}
                styles={styles}
                accountInfo={accountInfo}
                onSubmit={handleUpdateLanguage}
              />
              <div className={styles.section}>
                <Grid container spacing={1} direction="row" justify='space-between'>
                  <Grid item xl={12} lg={12} md={12} xs={12}>
                    <div className={styles.sectionTitle}>
                      {translate('AccountSetting:passwordAndSecurity')}
                    </div>
                  </Grid>
                  <Grid item xl={10} lg={10} md={10} xs={10}>
                    <div className={styles.fieldTitle}>{translate('AccountSetting:changePassword')}</div>
                    <div className="extra-description">
                      {translate('AccountSetting:extraText')}
                    </div>
                  </Grid>
                  <Grid item xl={1} lg={1} md={1} xs={2} align-content='flex-end'>
                    { !showPasswordForm &&
                      <Button variant='outlined' onClick={togglePwdForm}>
                        {translate('AccountSetting:change')}
                      </Button>}
                    {
                      showPasswordForm &&
                      <Button variant='outlined' onClick={togglePwdForm}>
                        {translate('AccountSetting:cancel')}
                      </Button>
                    }
                  </Grid>
                </Grid>
                {
                  showPasswordForm &&
                  <Grid item xl={12} lg={12} md={12} xs={12}>
                    <PasswordForm
                      translate={translate}
                      styles={styles}
                      onSubmit={handleUpdatePassword}
                    />
                  </Grid>
                }
              </div>
            </PortletContent>
          </Portlet>
        </Grid>
      </Grid>
    </div>
  );
};


const mapDisPatchToProps = dispatch => ({
  updateUser: (data) => dispatch(updateUser(data)),
  accountDetails: () => dispatch(accountDetails()),
  resetPwdForm: () => dispatch(reset('PasswordForm')),
  updateDateTimeFormat: (dateTimeFormat) => dispatch(setDateTimeFormat(dateTimeFormat))
});

export default compose(
  connect(null, mapDisPatchToProps),
  withRouter,
)(AccountSettingForm);

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// Redux

import {
  // Field,
  reduxForm } from 'redux-form';
// shared component
import { Portlet, PortletContent, PortletHeader, PortletLabel } from 'pages/Dashboard/components';
// import { TextInput } from 'components/inputs';
// Material component
import { Grid, Button } from '@material-ui/core';

let JobSettingForm = props => {
  const { translate, handleSubmit, styles } = props;
  return (
    <Portlet className={styles.section}>
      <PortletHeader className={styles.cardHeader}>
        <PortletLabel className={styles.cardLabel} title={translate('WorkSetting:defaultJobSetting')} />
      </PortletHeader>
      <PortletContent className={styles.contentPortlet}>
        <form className='form-body' onSubmit={handleSubmit}>
          <Grid item container spacing={2} justify='flex-start'>
            {/* <Grid item xs={12} sm={12} md={12}>
              <Field
                name='defaultTitle'
                component={TextInput}
                margin='normal'
                label={translate('WorkSetting:defaultJobTitle')}
                type='text'
                variant='outlined'
              />
            </Grid> */}

            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant='contained'
                color='secondary'
                type='submit'
              >
                {translate('WorkSetting:updateJobSetting')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </PortletContent>
    </Portlet>
  );
};


const mapStateToProps = (state, props) => {
  return ({
    initialValues: {
      defaultTitle: props.jobSetting && props.jobSetting.defaultTitle,
    }
  });
};

JobSettingForm = reduxForm({
  form: 'JobSettingForm',
  enableReinitialize: true
})(JobSettingForm);

export default compose(
  connect(mapStateToProps, null),
)(JobSettingForm);
export function getClientEmail(client) {
  const email = client && client.contactEmails && client.contactEmails.length
    && client.contactEmails.find(mail => mail.typeId === 'MAIN');
  return (email && email.email) || '';
}

export function getClientPhone(client) {
  const phoneNumber = client && client.contactPhones && client.contactPhones.length
    && client.contactPhones.find(phone => phone.typeId === 'MAIN');
  return (phoneNumber && (phoneNumber.countryPhoneCode + phoneNumber.phoneNumber)) || '';
}

export function getClientPhoneObject(client) {
  const phoneNumber = client && client.contactPhones && client.contactPhones.length
    && client.contactPhones.find(phone => phone.typeId === 'MAIN');
  return {
    countryPhoneCode: (phoneNumber && phoneNumber.countryPhoneCode) || '',
    phoneNumber: (phoneNumber && phoneNumber.phoneNumber) || '',
  };
}
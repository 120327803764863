export default theme => ({
  root: {},
  cardHeader: {
    backgroundColor: theme.palette.background.muted2
  },
  secondaryHeader: {
    maxHeight: 45,
  },
  productNameDesc: {
    margin: 0,
  },
  assignButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  teamChip: {
    margin: 2,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.muted2,
  },
  noneAssignText: {
    fontWeight: 400,
  },
  totalPrice: {
    textAlign: 'right',
  },
  totalPriceText: {
    fontWeight: 600,
    fontSize: 16,
  },
  cardFooter: {
    backgroundColor: theme.palette.background.muted2
  },
});
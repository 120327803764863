import React, { useState } from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { clearJob, updateJobDetails } from 'redux/job';
// Material helpers
import { withStyles, List, ListItem, ListItemText, ListItemIcon, Dialog, IconButton } from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
  ArrowRight
} from '@material-ui/icons';


// Component styles
import { UnassignedJobListStyles } from './styles';
// Shared components
import {
  Portlet,
  PortletContent,
  PortletHeader,
  PortletLabel,
} from 'pages/Dashboard/components';
import JobDetailsModal from './jobDetailsModal';

const mapStateToProps = () => ({
  initialValues: {
    message: null
  }
});

const mapDispatchToProps = dispatch => ({
  updateJobDetails: (jobDetails) => dispatch(updateJobDetails(jobDetails)),
  clearJob: () => dispatch(clearJob()),
});

function UnassignedJobList(props) {

  const [currentJobVisit, setCurrentJobVisit ] = useState(false);
  const [anchorElDetailsPopup, setAnchorElDetailsPopup] = useState(null);
  const openJobDetailsModal = Boolean(anchorElDetailsPopup);

  const {
    classes,
    open,
    handleCloseUnassignedJobModal,
    translate,
    unAssignedJobVisits,
    workerDetails
  } = props;

  function handleClickJobVisit(e, visit) {
    setAnchorElDetailsPopup(null);
    const thisEvent = e.currentTarget;
    thisEvent.setAttribute('aria-controls', 'rbc-selected');

    setAnchorElDetailsPopup(thisEvent);
    setCurrentJobVisit(visit);
  }

  function handleCloseJobDetailsModal() {
    setAnchorElDetailsPopup(null);
    setCurrentJobVisit(null);
  }

  function afterAssignedTeam() {
    const jobIndex = unAssignedJobVisits.findIndex(element => element.id === currentJobVisit.id);
    unAssignedJobVisits.splice(jobIndex, 1);
    setCurrentJobVisit(null);
  }

  return (
    <>
      <Dialog
        maxWidth='lg'
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Portlet className={classes.dialogWrap}>
          <PortletHeader className={classes.cardHeader}>
            <PortletLabel title={translate('Job:unAssignedJobs')} />
            {open ? (
              <IconButton aria-label="Close" className={classes.closeButton} onClick={handleCloseUnassignedJobModal}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </PortletHeader>

          <PortletContent className={classes.portletContent}>
            <List className={classes.listControl}>
              {
                unAssignedJobVisits.length > 0 &&
                unAssignedJobVisits.map(item => {

                  return <ListItem
                    key={item.id}
                    className={classes.listItem}
                    onClick={e => handleClickJobVisit(e, item)}
                  >
                    <ListItemText
                      className={classes.itemText}
                      primary={`Job ID: ${item.job.publicId}`}
                    />
                    <ListItemIcon className={classes.listItemIcon}>
                      <ArrowRight className={classes.arrowIcon} />
                    </ListItemIcon>
                  </ListItem>;
                })
              }
              {
                unAssignedJobVisits.length === 0 &&
                <div className={classes.noJobs}>{translate('Job:noUnassignedJobs')}</div>
              }
            </List>
          </PortletContent>
        </Portlet>
      </Dialog>

      {
        currentJobVisit &&

          <JobDetailsModal
            anchorEl={anchorElDetailsPopup}
            showJobDetails={openJobDetailsModal}
            handleCloseJobDetails={handleCloseJobDetailsModal}
            currentJobVisit={currentJobVisit}
            afterAssignedTeam={afterAssignedTeam}
            translate={translate}
            workerDetails={workerDetails}
          />
      }
    </>

  );
};

export default compose(
  withStyles(UnassignedJobListStyles),
  connect(mapStateToProps, mapDispatchToProps)
)(reduxForm({ form: 'workerSmsForm', destroyOnUnmount: true })(UnassignedJobList));

import React from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@material-ui/core";
import styles from "./styles.module.scss";
import { connect } from "react-redux";
import { getVisit } from "redux/visit";
import { Field, reduxForm } from "redux-form";
import { TextInput } from "components/inputs";
import { moment } from "lib/app-moment";
import { VisitTimeStepperFields } from "pages/Dashboard/components";

const EditVisitModal = (props) => {
  const {
    translate,
    handleModalSubmit,
    handleSubmit,
    action,
    setShowAddModal,
    inputData,
  } = props;

  return (
    <Dialog classes={{ paper: styles.defaultWidth }} maxWidth="lg" open>
      <DialogTitle disableTypography>
        <Typography className={styles.title} variant="h6">
          {action === "CREATE"
            ? translate("Timesheet:createVisits")
            : translate("Timesheet:editVisits")}
        </Typography>
      </DialogTitle>
      <form
        className={styles.formVisit}
        onSubmit={handleSubmit((formValues) => {
          handleModalSubmit({ ...formValues, action: inputData.action });
        })}
      >
        <DialogContent className={styles.container} dividers>
          <Grid container direction="column">
            <Grid item>
              <Field
                className={styles.textBackgound}
                variant="filled"
                label={translate("Timesheet:visitTitle")}
                name="title"
                component={TextInput}
              />
            </Grid>
            <Grid item>
              <Typography className={styles.textTitle} variant="h6">
                {action === "CREATE"
                  ? translate("Timesheet:processCreating")
                  : translate("Timesheet:processEditing")}
              </Typography>
            </Grid>
            <VisitTimeStepperFields
              translate={translate}
              defaultVisit={inputData.visitData}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddModal(false)}>
            {translate("Timesheet:cancelUpperCase")}
          </Button>
          <Button
            className={styles.submitBtn}
            type="submit"
            variant="contained"
            color="primary"
          >
            {}
            {action === "CREATE"
              ? translate("Timesheet:createUpperCase")
              : translate("Timesheet:saveUpperCase")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVisit: (id) => dispatch(getVisit(id)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    inputData: { action, visitData },
    timeSelectedRow,
  } = ownProps;
  const {
    onTheWayAt,
    arrivedAt,
    startedAt,
    completedAt,
    cancelledAt,
  } = visitData;
  let otwDate = onTheWayAt;
  let arrivedDate = arrivedAt;
  let startDate = startedAt;
  let completedDate = completedAt;
  let cancelledDate = cancelledAt;
  if (action === "CREATE") {
    otwDate = moment(timeSelectedRow);
    arrivedDate = moment(timeSelectedRow);
    startDate = moment(timeSelectedRow);
    completedDate = moment(timeSelectedRow);
    cancelledDate = moment(timeSelectedRow);
  }
  return {
    initialValues: {
      title: visitData.title || null,
      ontheway_visitDate: otwDate,
      ontheway_visitTime: onTheWayAt,
      arrived_visitTime: arrivedAt,
      arrived_visitDate: arrivedDate,
      start_visitTime: startedAt,
      start_visitDate: startDate,
      completed_visitDate: completedDate,
      completed_visitTime: completedAt,
      cancelled_visitDate: cancelledDate,
      cancelled_visitTime: cancelledAt,
    },
    action,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "EditVisitModal",
    enableReinitialize: false,
    destroyOnUnmount: true,
  })(EditVisitModal)
);

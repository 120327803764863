export default {
  // common && crumbs
  newQuote: 'New Quote',
  quotes: 'Quotes',
  quote: 'Quote',
  quoteDetails: 'Quote Details',
  editQuote: 'Edit Quote',
  quoteForClient: 'Quote for',
  quoteTitle: 'Title',
  jobType: 'Job type',
  clientName: 'Client name',
  propertyAddress: 'Property Address',
  billingAddress: 'Property Address',
  contactDetails: 'Contact details',
  saveQuote: 'Save staffInvitation.jse',
  issuedAt: 'Issued at',
  expireAt: 'Expires at',
  validity: 'Validity',
  copyQuoteTo: 'Copy Quote To..',
  convertToJob: 'Convert to Job',
  noQuoteMatches: 'No quote matches',
  thankYou:
    'Thank you for your enquiry. Please contact us with any questions regarding this Quote.',
  note: 'Note',
  noNote: 'No notes recorded.',
  // line items
  lineItems: 'Line Items',
  serviceProduct: 'Service / Product',
  qty: 'Qty.',
  unitPrice: 'Unit Price (excl. tax)',
  taxRate: 'Tax rate (%)',
  totalBeforeTax: 'Total (Before tax)',
  totalIncludeTax: 'Total (Include tax)',
  item: 'Item',
  addLineItem: '+ Add Line Item',
  clientMessage: 'Client Message',
  subtotal: 'Subtotal',
  discount: 'Discount',
  tax: 'Tax',
  total: 'Total',
  totalPrice: 'Total Price',
  deposit: 'Deposit',
  client: 'Client',
  internalNotes: 'Internal notes',
  comment: 'Comments(For internal use only)',
  noteDetails: 'Note details',
  createdOn: 'Created On',
  emailQuote: 'Email quote',
  quantity: 'Quantity',
  productService: 'Product/Service',
  startDate: 'Start Date',
  endDate: 'End Date',
  sendAs: 'Send As',
  collectSignature: 'Collect Signature',
  downloadPdf: 'Download Pdf',
  print: 'Print',
  status: 'Status',
  dueDate: 'Due',
  quotePrice: 'Price',
  createdBy: 'Created By',

  // client modal
  selectCreateClient: 'Select or create a client',
  whichClient: 'Which client would you create this for?',
  createClient: 'Create new client',
  properties: 'Properties',
  noPropertyRecorded: 'No property recorded.',
  createNewClient: 'Create a new client',

  // property modal
  selectCreateProperty: 'Select or create a property',
  whichProperty: 'Which property would you like to use for this ?',
  property: 'Property',
  createProperty: 'Create Property',
  updateProperty: 'Update Property',
  createPropertyFor: 'Create new property for',
  updatePropertyFor: 'Update property for',
  createNewProperty: 'Create a new property',
  coordsRequired:
    'Property coordinates are required for GPS Tracking. Please enter the lat(latitude) and lng(longitude) for the property or search for the property again by re-entering the property address.',

  // quote actions
  quoteCopying: 'Quote copying...!',
  quoteCopied: 'Quote copied!',
  sendingEmail: 'Sending quote email to client ...',
  sendingSMS: 'Sending quote sms to client ...',
  sentEmail: 'quote emailed to client ',
  sentSMS: 'Sent quote sms',
  preparePrinting: 'Prepare for printing...',
  taxArtical23: 'Withholding Tax article 23 (PPh 23)',
  totalDueAfterTax: 'Total Due (Net after Tax)',
  subTotalIncludeTax: 'Subtotal (Include tax)',
};

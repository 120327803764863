import React, { useEffect } from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { clearClient } from 'redux/client';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Grow,
  IconButton,
  Typography,
} from '@material-ui/core';

// Material icons
import {
  Close as CloseIcon,
} from '@material-ui/icons';

// Customised components
import { TextInput, SelectBox } from 'components/inputs';
import Countries from 'lib/countryList';
import withStopPropagation from 'lib/stopPropagation';

// Component styles
import styles from './styles';


const countryPhoneCodeOptions = new Countries().countryPhoneCodes();

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    countryPhoneCode: (ownProps.clientPhone && ownProps.clientPhone.countryPhoneCode)
      || state.invoice.invoice.client.countryPhoneCode
      // TODO: Will update it later by using current company. Waiting for WM-904
      || new Countries().getCountryPhoneCodeByCountryCode((state.auth.currentUser.companies[0] || {}).countryCode)
      || (state.auth.currentUser.companies[0] || {}).countryPhoneCode
      || state.auth.currentUser.countryPhoneCode
      || new Countries().getCountryPhoneCodeByCountryCode(state.auth.geoCountry.countryCode)
      || null,
    phoneNumber: (ownProps.clientPhone && ownProps.clientPhone.phoneNumber)
      || state.invoice.invoice.client.unformattedPhoneNumber,
  },
});

const mapDispatchToProps = dispatch => ({
  clearClient: () => dispatch(clearClient()),
});

const validate = values => {
  const errors = { message: {} };

  return errors;
};

function ConfirmSmsModal(props) {
  const {
    classes,
    clearClient,
    invalid,
    open,
    handleClose,
    handleSubmit,
    submitting,
    translate,
    title = 'smsInvoiceTo',
  } = props;

  useEffect(() => {
    return () => clearClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmitForm(event) {
    return withStopPropagation(handleSubmit)(event);
  }

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      transition={Grow}
    >
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmitForm}
      >
        <DialogTitle disableTypography className={classes.teamDialogTitle}>
          <Typography variant='h6' className={classes.teamDialogTitleText}>{translate(`Common:${title}`)}</Typography>
          {open ? (
            <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Grid container direction='row' spacing={1} alignItems='center'>
            <Grid item md={6} sm={12}>
              <Field
                name="countryPhoneCode"
                margin="dense"
                component={SelectBox}
                label={translate('Common:countryCode')}
                options={countryPhoneCodeOptions}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Field
                component={TextInput}
                name="phoneNumber"
                label={translate('Common:phoneNumber')}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            {translate('Common:cancel')}
          </Button>
          <Button
            color="primary"
            disabled={invalid || submitting}
            variant="contained"
            size="small"
            type="submit"
          >
            {translate('Common:sendSms')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(reduxForm({ form: 'paymentSms', validate, enableReinitialize: true, destroyOnUnmount: true })(ConfirmSmsModal));

import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Box,
  Chip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LocalOfferOutlined } from '@material-ui/icons';
import styles from './components.module.scss';

const VisitOnFilter = ({
  t,
  setSelectedTags,
  tags = [],
  selectedTags = [],
}) => {
  const handleOnChange = (event, value) => {
    const newSelectedTags = [...selectedTags, value];
    setSelectedTags(newSelectedTags);
  };

  const handleDeleteTag = (tagId) => {
    const updatedTagsFilter = selectedTags.filter((tag) => tag.id !== tagId);
    setSelectedTags(updatedTagsFilter);
  };

  return (
    <Grid>
      <Box mb={2}>
        <Typography>{t('filterByTag')}</Typography>
      </Box>

      <Autocomplete
        onChange={handleOnChange}
        options={tags}
        getOptionLabel={(option) => option.text}
        getOptionDisabled={(option) =>
          selectedTags.filter((tag) => tag.id === option.id).length !== 0
        }
        getOptionSelected={(option, value) => value.id === option.id}
        style={{ width: '100%' }}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('inputTag')}
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
              },
              classes: { underline: styles.no_border },
              className: `${styles.inputTagInputWrapper} input_no_border`,
              startAdornment: (
                <InputAdornment position="start">
                  <LocalOfferOutlined style={{ color: 'black' }} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Grid>
        {selectedTags.map((tag) => (
          <Chip
            key={tag.id}
            label={tag.text}
            onDelete={() => handleDeleteTag(tag.id)}
            color="secondary"
            // className={classes.chipStyle}
            style={{
              marginTop: 10,
              marginRight: 8,
              backgroundColor: '#000',
              opacity: 0.5,
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default VisitOnFilter;

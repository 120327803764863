import { GET, POST, PATCH, DELETE } from 'lib/api';

// Actions

const initialState = {
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default: return state;
  }
}

// Action Creators

// side effects, only as applicable
// e.g. thunks, epics, etc
export function getCategories(options = {}) {
  return (_dispatch, getState) => {
    const { accessToken } = getState().auth;
    const { limit, skip, textSearch, ...remainParams } = options;
    const queryParams = {
      $limit: options.limit || 10,
      $skip: options.skip || 0,
      ...remainParams
    };

    if (options.textSearch) {
      queryParams['$q'] = options.textSearch;
    }
    const params = { params: queryParams };
    return GET('/categories', accessToken, params).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function getCategory(categoryId) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/categories/${categoryId}`, accessToken).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function getCategoryWithParams(params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET('/categories', accessToken, params).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function addCategory(data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return POST('/categories', accessToken, { data }).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function updateCategory(data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return PATCH(`/categories/${data.id}`, accessToken, { data }).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function deleteCategory(data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return DELETE(`/categories/${data.id}`, accessToken).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

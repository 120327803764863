import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid,
  IconButton,
  Typography,
  TablePagination,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getTimesheet,
  updateClockInOut,
  createClockInOut,
  deleteClockInOut,
} from 'redux/timesheet';
import { moment } from 'lib/app-moment';
import {
  // CheckCircleOutline as CheckCircleOutlineIcon,
  Add as AddIcon,
  Info as InfoIcon,
  Edit as EditIcon,
  // HighlightOff as HighlightOffIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import { EditClockInOutModal } from '../modal';
import styles from './styles.module.scss';
import { ChipBag } from '../utils';
import lateLogo from 'assets/images/timesheet/Late.png';
import overtimeLogo from 'assets/images/timesheet/Overtime.png';
import { showErrorMessage, showInfoMessage } from 'lib/notifier';
import { getTimeFormat } from 'lib/formatter';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  addBtn: {
    textAlign: 'right',
    color: theme.palette.background.wm_clock_in_out,
  },
  groupBtn: {
    color: theme.palette.background.wm_clock_in_out,
  },
  subTitle: {
    color: theme.palette.background.wm_clock_in_out,
    // fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
  },
  btnIcon: {
    marginRight: 5,
  },
  customHoverFocus: {
    opacity: 0.5,
    '&:hover': { opacity: 1 },
  },
  approveBtn: {
    '&:hover': { color: theme.palette.background.wm_sucess },
  },
  declineBtn: {
    '&:hover': { color: theme.palette.background.wm_decline },
  },
  visitRow: {
    '& td': {
      // fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&:hover': {
      backgroundColor: theme.palette.background.wm_hover_color,
    },
  },
  dotContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  dot: {
    height: 4,
    width: 4,
    backgroundColor: '#D9006E',
    borderRadius: '50%',
    display: 'inline-block',
    margin: theme.spacing(1),
  },
  chipPreviousDay: {
    marginLeft: theme.spacing(1),
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 3,
    fontSize: '11px !important',
    maxHeight: 25,
    fontWeight: 500,
  },
  iconInfo: {
    color: '#DADADA',
  },
}));

function ClockInOut(props) {
  const { translate, query, currentStaffId } = props;
  const [timesheets, setTimeSheet] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [openAddModal, setOpenModal] = useState(false);
  const [isEditCICO, setIsEditCICO] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeSelectedRow, setTimeSelectedRow] = useState(moment());
  const [currentSelectedCICO, setCurrentSelectedCICO] = useState({});
  const classes = useStyles();
  let tableOpts = {
    userId: query.userId,
    $from: query.fromDate,
    $to: query.toDate,
    $limit: rowsPerPage,
    $skip: page * rowsPerPage,
    currentStaffId,
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleAddSubmit = async (val) => {
    const { id, ...form } = val;
    const inTime = moment(form.clockInDate)
      .hour(moment(form.clockInTime).hour())
      .minute(moment(form.clockInTime).minute());

    const outTime = moment(form.clockOutDate)
      .hour(moment(form.clockOutTime).hour())
      .minute(moment(form.clockOutTime).minute());
    if (moment(outTime).isBefore(moment(inTime))) {
      return showErrorMessage(translate('Timesheet:outTimebeforInTime'));
    }
    const opts = {
      inTime,
      outTime,
      currentQuery: tableOpts,
      userId: tableOpts.currentStaffId,
    };
    const result = await props.createClockInOut(opts).catch((error) => ({
      error,
    }));
    if (result.error) return showErrorMessage(result.error);
    setOpenModal(false);
    showInfoMessage(translate('Timesheet:createSuccess'));
    result.data ? setTimeSheet(result.data) : setPage(0);
  };

  const handleEditSubmit = async (val) => {
    const { id, ...form } = val;

    const inTime = moment(form.clockInDate)
      .hour(moment(form.clockInTime).hour())
      .minute(moment(form.clockInTime).minute());

    const outTime = moment(form.clockOutDate)
      .hour(moment(form.clockOutTime).hour())
      .minute(moment(form.clockOutTime).minute());

    if (moment(outTime).isBefore(moment(inTime))) {
      return showErrorMessage(translate('Timesheet:outTimebeforInTime'));
    }
    const opts = {
      inTime,
      outTime,
      currentQuery: tableOpts,
    };
    const result = await props.updateClockInOut(id, opts).catch((error) => ({
      error,
    }));
    if (result.error) return showErrorMessage(result.error);
    setOpenModal(false);
    showInfoMessage(translate('Timesheet:updateSuccess'));
    result.data ? setTimeSheet(result.data) : setPage(0);
  };

  const handleDeleteSubmit = async () => {
    setOpenDeleteModal(false);
    const { id } = currentSelectedCICO;
    const opts = { currentQuery: tableOpts };
    const result = await props.deleteClockInOut(id, opts).catch((error) => ({
      error,
    }));
    if (result.error) return showErrorMessage(result.error);
    showInfoMessage(translate('Timesheet:deleteSuccess'));
    // update timesheet list after delete
    result.data ? setTimeSheet(result.data) : setPage(0);
  };
  const fetchTimeSheet = async () => {
    try {
      setIsLoading(true);
      if (query.showLateOnly)
        tableOpts = { ...tableOpts, lateOnly: query.showLateOnly };
      const result = await props.getTimesheet(tableOpts);
      setTimeSheet(result.data);
      setTotal(result.total);
    } catch (err) {
      setTimeSheet([]);
      setTotal(0);
      showErrorMessage(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTimeSheet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, rowsPerPage, page]);
  if (isLoading) {
    return (
      <Grid
        container
        className={styles.loadingScreen}
        justify="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      <Grid
        className={styles.tableContainer}
        container
        direction="column"
        justify="space-between"
      >
        <div className={styles.tableContent}>
          <Table>
            <RenderTimesheetTableHeader translate={translate} />
            <TableBody>
              {timesheets &&
                timesheets.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <RenderTimesheetDateHeader
                        setTimeSelectedRow={setTimeSelectedRow}
                        timesheet={item}
                        translate={translate}
                        classes={classes}
                        setIsEditCICO={setIsEditCICO}
                        setOpenModal={setOpenModal}
                      />
                      <RenderTimesheetRow
                        setCurrentSelectedCICO={setCurrentSelectedCICO}
                        setOpenModal={setOpenModal}
                        setIsEditCICO={setIsEditCICO}
                        setOpenDeleteModal={setOpenDeleteModal}
                        timesheets={(item && item.clockIns) || []}
                        translate={translate}
                        classes={classes}
                      />
                    </Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          className={styles.tablePagination}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {openAddModal && (
        <PopupComponent
          timeSelectedRow={timeSelectedRow}
          title={translate(
            isEditCICO
              ? 'Timesheet:editClockInOut'
              : 'Timesheet:createClockInOut'
          )}
          handleSubmit={isEditCICO ? handleEditSubmit : handleAddSubmit}
          translate={translate}
          timesheet={isEditCICO ? currentSelectedCICO : null}
          setOpenModal={setOpenModal}
        />
      )}
      {openDeleteModal && (
        <DeleteComponent
          handleDeleteSubmit={handleDeleteSubmit}
          translate={translate}
          timesheet={currentSelectedCICO}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getTimesheet: (opts) => dispatch(getTimesheet(opts)),
  updateClockInOut: (id, opts) => dispatch(updateClockInOut(id, opts)),
  createClockInOut: (opts) => dispatch(createClockInOut(opts)),
  deleteClockInOut: (id, opts) => dispatch(deleteClockInOut(id, opts)),
});

export default compose(connect(null, mapDispatchToProps))(ClockInOut);

const convertSecondToFormat = (second, translate) => {
  const hours = Math.floor(second / 3600);
  const minutes = Math.round((second % 3600) / 60);
  let format = '';
  if (hours > 0) format += `${hours} ${translate('Timesheet:Hrs')}`;
  format += `${minutes} ${translate('Timesheet:Mins')}`;
  return format;
};
const convertSecondToHour = (second) => {
  let hh = Math.floor(second / 3600);
  let mm = Math.round((second % 3600) / 60);
  mm = mm < 10 ? `0${mm}` : mm;
  hh = hh < 10 ? `0${hh}` : hh;
  const workedHours = [hh, mm].join(':');
  return workedHours;
};
const RenderTimesheetTableHeader = (props) => {
  const { translate } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell className={styles.stickyHeader} colSpan={1} />
        <TableCell
          className={styles.stickyHeader}
          style={{ position: 'sticky', top: 0 }}
          colSpan={8}
        >
          {translate('Timesheet:clockIn')}
        </TableCell>
        <TableCell
          className={styles.stickyHeader}
          style={{ position: 'sticky', top: 0 }}
          colSpan={8}
        >
          {translate('Timesheet:clockOut')}
        </TableCell>
        <TableCell
          style={{ position: 'sticky', top: 0 }}
          className={styles.durationHeader}
          colSpan={2}
        >
          {translate('Timesheet:duration')}
        </TableCell>
        <TableCell
          style={{ position: 'sticky', top: 0 }}
          className={styles.actionHeader}
          colSpan={1}
        >
          {translate('Timesheet:actions')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const RenderTimesheetDateHeader = (props) => {
  const {
    classes,
    translate,
    timesheet,
    setOpenModal,
    setIsEditCICO,
    setTimeSelectedRow,
  } = props;
  const totalHours = `${convertSecondToFormat(
    timesheet.workedSecond,
    translate
  )}`;
  return (
    <>
      <TableRow className={styles.borderBottom}>
        <TableCell colSpan={1} />
        <TableCell colSpan={17} className={classes.subTitle}>
          <div className={classes.dotContainer}>
            <span>{moment(timesheet.startDay).format('MMM DD, YYYY')}</span>
            <span className={classes.dot} />
            <span>
              {translate('Timesheet:total')}: {totalHours}
            </span>
          </div>
        </TableCell>
        <TableCell colSpan={2} className={classes.addBtn}>
          <Button
            onClick={() => {
              setTimeSelectedRow(timesheet.startDay);
              setIsEditCICO(false);
              setOpenModal(true);
            }}
            className={classes.subTitle}
          >
            <AddIcon className={classes.btnIcon} />
            {translate('Timesheet:add')}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const RenderTimesheetRow = (props) => {
  const {
    timesheets,
    classes,
    translate,
    setCurrentSelectedCICO,
    setIsEditCICO,
    setOpenModal,
    setOpenDeleteModal,
  } = props;
  return (
    <>
      {timesheets.length > 0 &&
        timesheets.map((timesheet, index) => {
          let lateTime;
          let overTime;
          if (timesheet.overtimeInSecond)
            overTime = `${convertSecondToFormat(
              timesheet.overtimeInSecond,
              translate
            )} ${translate('Timesheet:overtime')}`;
          if (timesheet.lateInSecond)
            lateTime = `${convertSecondToFormat(
              timesheet.lateInSecond,
              translate
            )} ${translate('Timesheet:late')}`;
          return (
            <TableRow className={classes.visitRow} key={index}>
              <TableCell className={styles.labelIcon} colSpan={1}>
                <div className={styles.groupBag}>
                  <div>
                    {timesheet.overtimeInSecond > 0 && (
                      <Tooltip title={overTime} aria-label="add">
                        <img
                          className={styles.overTime}
                          src={overtimeLogo}
                          alt="overtime"
                        />
                      </Tooltip>
                    )}
                  </div>
                  <div>
                    {timesheet.lateInSecond > 0 && (
                      <Tooltip title={lateTime} aria-label="add">
                        <img
                          className={styles.avatarLate}
                          src={lateLogo}
                          alt="late"
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </TableCell>
              <TableCell colSpan={8}>
                {timesheet.inTime ? (
                  <>
                    {moment(timesheet.inTime).format(
                      getTimeFormat()
                    )}
                    {timesheet.isPreviousDayOverlapping && (
                      <ChipBag label={translate('Timesheet:previousDay')} />
                    )}
                  </>
                ) : null}
              </TableCell>
              <TableCell colSpan={8}>
                {timesheet.outTime ? (
                  <>
                    {moment(timesheet.outTime).format(
                      getTimeFormat()
                    )}
                    {timesheet.isNextDayOverlapping && (
                      <ChipBag label={translate('Timesheet:nextDay')} />
                    )}
                  </>
                ) : null}
              </TableCell>
              <TableCell className={styles.durationCell} colSpan={2}>
                <Tooltip title={translate('Timesheet:info')} aria-label="add">
                  <InfoIcon className={classes.iconInfo} />
                </Tooltip>
                {convertSecondToHour(timesheet.durationInSecond)}
              </TableCell>
              <TableCell colSpan={1} className={styles.groupBtn}>
                {/* <IconButton disabled className={classes.customHoverFocus}>
                  // <CheckCircleOutlineIcon />
                </IconButton>
                <IconButton disabled className={classes.customHoverFocus}>
                  <HighlightOffIcon />
                </IconButton> */}
                <Tooltip
                  onClick={() => {
                    setIsEditCICO(true);
                    setCurrentSelectedCICO(timesheet);
                    setOpenModal(true);
                  }}
                  title={translate('Timesheet:edit')}
                  aria-label="add"
                >
                  <IconButton className={classes.customHoverFocus}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={translate('Timesheet:delete')} aria-label="add">
                  <IconButton
                    onClick={() => {
                      setCurrentSelectedCICO(timesheet);
                      setOpenDeleteModal(true);
                    }}
                    className={classes.customHoverFocus}
                    style={{ color: '#FF3B30' }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      {timesheets.length === 0 && (
        <TableRow>
          <TableCell colSpan={20} className={classes.emptyList}>
            <Grid
              className={styles.notimesheetDataRow}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography
                style={{
                  verticalAlign: 'middle',
                  textAlign: 'center',
                  color: '#7d7d7d',
                }}
              >
                {translate('Timesheet:noTimesheetFound')}
              </Typography>
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const PopupComponent = (props) => {
  const {
    timesheet,
    translate,
    handleSubmit,
    setOpenModal,
    title,
    timeSelectedRow,
  } = props;
  return (
    <Dialog
      maxWidth="lg"
      labelled="customized-dialog-title"
      open
      classes={{ paper: styles.modalDefaultWidth }}
    >
      <DialogTitle disableTypography>
        <Typography className={styles.modalTitle} variant="h4">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <EditClockInOutModal
          timeSelectedRow={timeSelectedRow}
          onSubmit={(val) => handleSubmit(val)}
          timesheet={timesheet}
          translate={translate}
          setOpenModal={setOpenModal}
        />
      </DialogContent>
    </Dialog>
  );
};

const DeleteComponent = (props) => {
  const { translate, setOpenDeleteModal, handleDeleteSubmit } = props;
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      labelled="confirmation-dialog-title"
      open
    >
      <DialogTitle id="confirmation-dialog-title">
        {translate('Timesheet:askConfirmationBeforeDelete')}
      </DialogTitle>

      <DialogActions>
        <Button onClick={() => setOpenDeleteModal(false)} color="primary">
          {translate('Common:cancel')}
        </Button>
        <Button onClick={handleDeleteSubmit} color="primary">
          {translate('Common:ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

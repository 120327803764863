import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  IconButton,
  Fade,
} from '@material-ui/core';
import styles from './notification.module.scss';
import { Close } from '@material-ui/icons';
import DownloadSrc from 'assets/images/enterprise/download.png';

let timeout;

const DownloadingNotification = ({
  t,
  setIsOpenDownloadModal,
  isOpenDownloadModal,
}) => {
  useEffect(() => {
    timeout = setTimeout(() => {
      setIsOpenDownloadModal(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isOpenDownloadModal]);

  const handleClose = () => setIsOpenDownloadModal(false);

  return (
    <Fade in={isOpenDownloadModal}>
      <Card className={styles.card_wrapper}>
        <Box p={2}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <Close className="close-icon" />
            </IconButton>
          </Box>
          <Grid container>
            <Grid item md={3}>
              <img src={DownloadSrc} alt="DownloadSrc" />
            </Grid>
            <Grid item md={9}>
              <Typography>{t('downloadFiles')}</Typography>
              <Typography variant="subtitle1" className="mt_5">
                {t('downloadDescription')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Fade>
  );
};

export default DownloadingNotification;

export default theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.common.white,
  },
  paper: {
    position: 'absolute',
    top: 40,
    right: 5,
    width: 300,
    border: '1px solid #DFE3E8',
    zIndex: 9
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '63px',
    flexShrink: 0,
  },
  logoLink: {
    fontSize: 0,
  },
  image: {
    cursor: 'pointer',
    height: 50,
  },
  backIcon: {
    fontSize: 0,
    height: '100%'
  }
});

import React from 'react';

// Externals
import PropTypes from 'prop-types';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { getInvoice, updateInvoiceDetails, clearInvoice, setCurrentInvoice } from 'redux/invoice';
import { getProducts } from 'redux/product';

// Material components
import { Grid, withStyles } from '@material-ui/core';

// Custom components
import { InvoiceToolbar } from '../components/utils';

import { showErrorMessage, showInfoMessage } from 'lib/notifier';

// Component styles
import styles from './style';
import { InvoiceDetailsForm } from '../components/forms';
import FormValidation from '../components/validation/formValidation';

import { LoadingModal } from 'pages/Dashboard/components';

const mapStateToProps = state => ({
  client: state.invoice.invoice.client,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoice: (id) => dispatch(getInvoice(id)),
  clearInvoice: () => dispatch(clearInvoice()),
  setCurrentInvoice: (InvoiceDetails) => dispatch(setCurrentInvoice(InvoiceDetails)),
  updateInvoiceDetails: (InvoiceDetails) => dispatch(updateInvoiceDetails(InvoiceDetails)),
  getProducts: (type) => { dispatch(getProducts(type)); }
});

class UpdateInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitAction: null,
      submitActionValue: null,
      submitAndApproveInvoice: false,
      isLoading: false
    };
    this.handleInvoiceSubmit = this.handleInvoiceSubmit.bind(this);
    this.setSubmitAction = this.setSubmitAction.bind(this);
    this.setSubmitActionValue = this.setSubmitActionValue.bind(this);
    this.setConfirmStatus = this.setConfirmStatus.bind(this);
    this.handleCloseLoading = this.handleCloseLoading.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchInvoiceDetails();
    this.props.getProducts('products');
    this.props.getProducts('services');
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.clearInvoice();
  }

  fetchInvoiceDetails() {
    const { getInvoice, invoiceId } = this.props;
    getInvoice(invoiceId);
  }

  setSubmitAction(value) {
    this.setState({ submitAction: value });
  }

  setSubmitActionValue(value) {
    this.setState({ submitActionValue: value });
  }

  setConfirmStatus(value) {
    this.setState({ submitAndApproveInvoice: value });
  }

  handleCloseLoading = () => this.setState({ isLoading: false });

  async handleInvoiceSubmit(invoiceValues) {
    const { updateInvoiceDetails, history, translate } = this.props;
    const { submitAction, submitActionValue, submitAndApproveInvoice } = this.state;
    const statusId = submitAndApproveInvoice ? 'APPROVED': 'AWAITING_APPROVAL';
    invoiceValues.statusId = statusId;
    let opts = { invoiceValues };

    if (submitAction === 'email') {
      opts = {
        ...opts,
        actionCallback: {
          isSendEmail: true,
          isSendSms: false,
          emails: [submitActionValue.email],
        },
      };
      showInfoMessage(translate('Invoice:sendingEmail'));
    } else if (submitAction === 'sms') {
      opts = {
        ...opts,
        actionCallback: {
          isSendEmail: false,
          isSendSms: true,
          phoneNumbers: [
            submitActionValue.countryPhoneCode + submitActionValue.phoneNumber,
          ],
        },
      };
      showInfoMessage(translate('Invoice:sendingSMS'));
    }

    this.setState({ isLoading: true });
    try {
      const response = await updateInvoiceDetails(opts);
      this.setState({ isLoading: false });
      if (response.status === 200) {
        setCurrentInvoice(response.data.data);
        history.push(`/invoices/${invoiceValues.id}`);
      } else {
        showErrorMessage(response.data.message);
      }
    } catch (error) {
      console.log('error', error);
      this.setState({ isLoading: false });
      showErrorMessage(error);
    }
  };

  validateAndCallSubmit(values) {
    const { translate } = this.props;
    const errors = FormValidation(values);
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === 'object') {
        const message = Object.values(errors.message).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      } else {
        const message = Object.values(errors).map(msg => translate(`Error:${msg}`)).join('<br />');
        messages.push(message);
      }

      showErrorMessage(messages.join('<br />'));
      throw new SubmissionError(errors);
    }

    this.handleInvoiceSubmit(values);
  };

  render() {
    const { history, classes, client, clearInvoice, translate } = this.props;
    const { isLoading } = this.state;
    const { displayName } = (client || {});
    const clientName = displayName;

    function handleCancelUpdateInvoice() {
      clearInvoice();
      history.goBack();
    };

    return (
      <div className={classes.root}>
        <Grid container spacing={4} justify='center'>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
          >
            <InvoiceToolbar
              clientName={clientName}
              match={this.props.match}
              translate={translate}
            />
          </Grid>
          <Grid
            container
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <InvoiceDetailsForm
              onSubmit={values => this.validateAndCallSubmit(values)}
              handleCancelInvoiceForm={handleCancelUpdateInvoice}
              setSubmitActionValue={this.setSubmitActionValue}
              setSubmitAction={this.setSubmitAction}
              setConfirmStatus={this.setConfirmStatus}
              translate={translate}
            />
          </Grid>
        </Grid>
        { isLoading &&
          <LoadingModal open={isLoading} handleClose={this.handleCloseLoading} />
        }
      </div>
    );
  }
};

UpdateInvoice.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(UpdateInvoice);

import { PATCH, GET } from 'lib/api';
import { store } from 'services/store';

export function getSubscription() {
  const { getState } = store;
  const { accessToken } = getState().auth;
  return GET('/subscription-plan', accessToken)
    .then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    )
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function setSubscription(data) {
  const { getState } = store;
  const { accessToken } = getState().auth;
  return PATCH('/subscription-plan', accessToken, { data })
    .then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    )
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getPaymentMethod() {
  const { getState } = store;
  const { accessToken } = getState().auth;
  return GET('/subscription-payment', accessToken)
    .then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    )
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function activateAccount(data) {
  const { getState } = store;
  const { accessToken } = getState().auth;
  return PATCH('/subscription-activate', accessToken, { data })
    .then(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    )
    .catch((err) => {
      return Promise.reject(err);
    });
}

import React, { useEffect, useState } from "react";

// Externals
import classNames from "classnames";
import moment from "moment";

// Material components
// Material helpers
import {
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";

// Shared components
import { Portlet, PortletContent } from "pages/Dashboard/components";
import { formatPrice, formatDate } from "lib/formatter";

// Component styles
import { InvoiceDetailsTableStyles } from "./styles";

const round = (num) => Math.round(parseFloat(num) * 100) / 100;
const UNAPPROVAL_STATUS = ["DRAFT", "AWAITING_APPROVAL"];
const InvoiceDetailsTable = (props) => {
  const { invoiceDetails, classes, handleOpenPaymentModal, translate } = props;
  const [isDraftInvoice, setIsDraftInvoice] = useState(true);
  useEffect(() => {
    if (
      invoiceDetails &&
      invoiceDetails.statusId &&
      !UNAPPROVAL_STATUS.includes(invoiceDetails.statusId)
    ) {
      return setIsDraftInvoice(false);
    }
    setIsDraftInvoice(true);
  }, [invoiceDetails]);
  return (
    <Portlet className={classes.portlet}>
      <PortletContent noPadding>
        <Grid container direction="row" alignItems="center">
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell
                  className={classNames(classes.rootCell)}
                  align="left"
                >
                  {translate("productService")}
                </TableCell>
                <TableCell
                  className={classNames(classes.rootCell)}
                  align="right"
                >
                  {translate("quantity")}
                </TableCell>
                <TableCell
                  className={classNames(classes.rootCell)}
                  align="right"
                >
                  {translate("unitPrice")}
                </TableCell>
                <TableCell
                  className={classNames(classes.rootCell)}
                  align="right"
                >
                  {translate("taxRate")}
                </TableCell>
                <TableCell
                  className={classNames(classes.rootCell)}
                  align="right"
                >
                  {translate("beforeTax")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceDetails.items &&
                invoiceDetails.items.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="left">
                        <Grid container direction="column">
                          <Typography className={classes.itemTitle}>
                            {row.name}
                          </Typography>
                          <Typography className={classes.itemDescription}>
                            {row.description}
                          </Typography>
                        </Grid>
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.rootCell,
                          classes.itemCell
                        )}
                        align="right"
                      >
                        {row.quantity}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.rootCell,
                          classes.itemCell
                        )}
                        align="right"
                      >
                        {formatPrice(row.unitPrice, row.currency)}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.rootCell,
                          classes.itemCell
                        )}
                        align="right"
                      >
                        {(row.taxRate && row.taxRate.percentage) || "0.00"}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.rootCell,
                          classes.itemCell
                        )}
                        align="right"
                      >
                        {formatPrice(row.total, row.currency)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Grid>

        <Grid container direction="row">
          <Grid item sm={12} md={7}>
            <Table>
              <TableBody>
                {invoiceDetails.signatureFile && invoiceDetails.signedAt && (
                  <>
                    <TableRow>
                      <TableCell
                        className={classes.signatureTextCell}
                        align="left"
                      >
                        {translate("signature")}
                      </TableCell>
                      <TableCell
                        className={classes.dateTextCell}
                        align="center"
                      >
                        {translate("signedAt")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={classNames(
                          classes.rootCell,
                          classes.signatureCell
                        )}
                        align="left"
                      >
                        <img
                          src={`${invoiceDetails.signatureFile}`}
                          className={classes.signatureFile}
                          alt="signature"
                        />
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.rootCell,
                          classes.signatureCell
                        )}
                        align="center"
                      >
                        {moment(invoiceDetails.signedAt).format("MMM DD, YYYY")}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sm={12} md={5}>
            <Table>
              <TableBody>
                <TableRow className={classes.clientMessage}>
                  <TableCell className={classes.rootCell} align="left">
                    {translate("subtotal")}
                  </TableCell>
                  <TableCell className={classes.rootCell} align="right">
                    {formatPrice(
                      invoiceDetails.subtotal,
                      invoiceDetails.currency
                    )}
                  </TableCell>
                </TableRow>
                {invoiceDetails.discount && invoiceDetails.discount !== 0 && (
                  <TableRow className={classes.taxRows}>
                    <TableCell className={classes.rootCell} align="left">
                      {`${translate("discount")} (${invoiceDetails.currency})`}
                    </TableCell>
                    <TableCell className={classes.rootCell} align="right">
                      {formatPrice(
                        invoiceDetails.discount,
                        invoiceDetails.currency
                      )}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow className={classes.taxRows}>
                  <TableCell className={classes.rootCell} align="left">
                    {translate("tax")}
                  </TableCell>
                  <TableCell className={classes.rootCell} align="right">
                    {formatPrice(invoiceDetails.tax, invoiceDetails.currency)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className={classNames(
                      classes.rootCell,
                      classes.totalCell,
                      classes.totalRows
                    )}
                    align="left"
                  >
                    {translate("totalDue")}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.rootCell,
                      classes.totalCell,
                      classes.totalRows
                    )}
                    align="right"
                  >
                    {formatPrice(invoiceDetails.total, invoiceDetails.currency)}
                  </TableCell>
                </TableRow>
                {invoiceDetails.payments &&
                  invoiceDetails.payments.map((payment) => {
                    return (
                      <TableRow className={classes.taxRows} key={payment.id}>
                        <TableCell
                          className={classNames(
                            classes.rootCell,
                            classes.paymentCell
                          )}
                          align="left"
                        >
                          <Link
                            className={classes.paymentLink}
                            component="button"
                            onClick={() => handleOpenPaymentModal(payment)}
                          >
                            <Typography className={classes.paymentButton}>
                              {`${translate("Common:payment")} - ${formatDate(
                                payment.paymentDate
                              )}`}
                            </Typography>
                          </Link>
                        </TableCell>
                        <TableCell className={classes.rootCell} align="right">
                          {`- ${formatPrice(
                            payment.amount,
                            invoiceDetails.currency
                          )}`}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {!isDraftInvoice && (
                  <>
                    <TableRow>
                      <TableCell
                        className={classNames(
                          classes.rootCell,
                          classes.totalCell,
                          classes.totalRows
                        )}
                        align="left"
                      >
                        {translate("invoiceBalance")}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.rootCell,
                          classes.totalCell,
                          classes.totalRows
                        )}
                        align="right"
                      >
                        {formatPrice(
                          invoiceDetails.invoiceBalance,
                          invoiceDetails.currency
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.balanceCell} align="left">
                        {translate("accountBalance")}
                      </TableCell>
                      <TableCell className={classes.balanceCell} align="right">
                        {formatPrice(
                          invoiceDetails.accountBalance,
                          invoiceDetails.currency
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </PortletContent>
    </Portlet>
  );
};

export default withStyles(InvoiceDetailsTableStyles)(InvoiceDetailsTable);

import { GET } from 'lib/api';

export function getTags(options = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    const params = { params: options };

    return GET('/tags', accessToken, params).then((response) => {
      return Promise.resolve(response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };

}
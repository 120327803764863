export default theme => ({
  productName: {
    marginTop: theme.spacing(1),
  },
  productDesc: {
    margin: 0,
  },
  addCustomButton: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  deleteButton: {
    color: theme.palette.danger.main,
    borderColor: theme.palette.danger.main,
  },
  groupStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  groupBadgeStyles: {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  },
  inputTaxRow: {
    marginTop: 8,
    paddingTop: 8,
    height: 40,
    lineHeight: '40px',
    textAlign: 'center'
  }
});

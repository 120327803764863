import React, { useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import styles from './filter.module.scss';
import { useDispatch, useSelector, connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { toggleFilterSidebar } from 'redux/global';
import { downloadJobList } from 'redux/enterprise';
import { showErrorMessage } from 'lib/notifier';

import moment from 'moment';

import filterSrc from 'assets/images/timesheet/Filter.png';
import downloadSrc from 'assets/images/icons/download.svg';

const filterFormSelector = formValueSelector('filterForm');

const mapStateToProps = (state) => {
  const { categoryIds, createdOn, scheduledOn, status } = filterFormSelector(
    state,
    'categoryIds',
    'createdOn',
    'scheduledOn',
    'status'
  );
  return { categoryIds, createdOn, scheduledOn, status };
};

const FilterHeader = ({ translate, categoryIds, createdOn, scheduledOn, status }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleDownload = async () => {
    const data = {
      action: 'EXPORT_CSV',
      statusIds: (status && status !== 'ALL' &&
        (Array.isArray(status) ? status.map(item => item.value) : status)) || '',
      createdOnFrom: createdOn && createdOn[0] && moment(createdOn[0]).startOf('day'),
      createdOnTo: createdOn && createdOn[1] && moment(createdOn[1]).endOf('day'),
      scheduledOnFrom: scheduledOn[0] && moment(scheduledOn[0]).startOf('day'),
      scheduledOnTo: scheduledOn[1] && moment(scheduledOn[1]).endOf('day'),
      categoryIds,
    };
    setIsLoading(true);
    const result = await dispatch(downloadJobList({ data }));
    setIsLoading(false);
    if (result.e) return showErrorMessage(result.e);
    if (result.url) return window.open(result.url, '_blank');
  };
  const isJobsFiltering = useSelector(
    (state) => state.global.isJobFiltering || false
  );

  const handleShowFilterSidebar = () => dispatch(toggleFilterSidebar(true));

  return (
    <Box style={{ position: 'relative' }}>
      <Box pb={2} display="flex" justifyContent="flex-end">
        <Button onClick={handleShowFilterSidebar} className={styles.btnFilter}>
          <Box mr={1}>
            <img src={filterSrc} alt="filterSrc" />
          </Box>
          <Typography>
            {translate('Common:filter')}:{' '}
            {isJobsFiltering ? translate('Common:on') : translate('Common:off')}
          </Typography>
        </Button>
        <Button
          disabled={isLoading}
          className={styles.btnNew}
          onClick={handleDownload}
        >
          <Box mr={1}>
            <img src={downloadSrc} alt="downloadSrc" />
          </Box>
          <Typography>{translate('Common:download')}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps, null)(FilterHeader);

import React from 'react';

// Redux
import { Field } from 'redux-form';

import {
  Grid,
  Typography,
} from '@material-ui/core';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'pages/Dashboard/components';
import { DatePicker, TimePicker } from 'components/inputs';

// Component styles
import { DateTimeScheduleCardStyle } from './styles';

const DateTimeScheduleCard = props => {
  const { classes, translate } = props;

  return (
    <Portlet>
      <PortletHeader className={classes.cardHeader}>
        <PortletLabel title={translate('schedule')} />
      </PortletHeader>
      <PortletContent style={{ overflow: 'unset', zIndex: 2 }}>
        <Grid className={classes.startTime}>
          <Grid container direction='column'>
            <Typography className={classes.titleDate} variant='h6'>
              {translate('startTime')}
            </Typography>
          </Grid>
          <Grid container direction='row' spacing={2} justify='space-between'>
            <Grid item md={6} sm={12}>
              <Field
                name='startDate'
                component={DatePicker}
                label={translate('startDate')}
                minDate={null}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Field
                name='startTime'
                component={TimePicker}
                label={translate('startTime')}
                minDate={null}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid container direction='column'>
            <Typography variant='h6' className={classes.titleDate}>
              {translate('endTime')}
            </Typography>
          </Grid>
          <Grid container direction='row' spacing={2} justify='space-between'>
            <Grid item md={6} sm={12}>
              <Field
                name='endDate'
                component={DatePicker}
                label={translate('endDate')}
                minDate={null}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Field
                name='endTime'
                component={TimePicker}
                value=''
                label={translate('endTime')}
                minDate={null}
              />
            </Grid>
          </Grid>
        </Grid>
      </PortletContent>
    </Portlet>
  );
};

export default withStyles(DateTimeScheduleCardStyle)(DateTimeScheduleCard);
import React, { useEffect } from 'react';
import { TextInput } from 'components/inputs';
import { Button } from '@material-ui/core';
import { Field, reduxForm, initialize } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.scss';

const CategoryForm = props => {
  const { handleSubmit, onCloseForm, catIcon, formName, category={} } = props;
  const { t: translate } = useTranslation('Common');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!Object.keys(category).length) return;
    dispatch(initialize(formName, {
      id: category.id,
      name: category.name,
      parentCategoryId: category.parentCategoryId
    }));
  }, [formName, category]);

  return (
    <div className={styles.categoryFormWrap}>
      {catIcon}
      <form onSubmit={handleSubmit} className={styles.catForm}>
        <Field
          name="name"
          className={styles.inputCategory}
          component={TextInput}
        />
        <Button
          type="submit"
          className={styles.submitBtn}
          startIcon={<DoneIcon />}
        >
          {translate('Common:create')}
        </Button>

      </form>
      <Button
        className={styles.closeBtn}
        startIcon={<CloseIcon />}
        onClick={onCloseForm}
      >
        {translate('Common:close')}
      </Button>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { category={} } = ownProps;
  return ({
    // initial formName, dont remove form attribute
    form: ownProps.formName || 'categoryForm',
    initialValues: {
      parentCategoryId: category.parentCategoryId || null,
      name: category.name || null
    },
    formName: ownProps.formName || 'categoryForm',
    category
  });
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm()
)(CategoryForm);

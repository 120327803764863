export default theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  clientDialogTitle: {
    // minWidth: 800,
  },
  teamDialogTitle: {
    minWidth: 300,
  },
});
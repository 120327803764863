import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Paper, withStyles, Typography } from '@material-ui/core';
import { getInvoices } from 'redux/invoice';
import { showErrorMessage } from 'lib/notifier';
import MaterialTable from 'material-table';

import moment from 'moment';
import styles from './style';
import { StatusChip } from 'pages/Dashboard/components';
import { formatPrice, getDateTimeFormat } from 'lib/formatter';

const options = {
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  actionsColumnIndex: -1,
  sorting: true,
  search: false,
  draggable: false,
  toolbar: false,
};

const renderColumns = (translate) => [
  {
    field: 'subject',
    title: 'Subject',
    sorting: false,
    filtering: false,
  },
  {
    field: 'issuedAt',
    title: translate('Invoice:issuedAt'),
    sorting: true,
    filtering: false,
    render: (rowData) => {
      if (!rowData.issuedAt) return null;
      return (
        <Typography variant="body1">
          {moment(rowData.issuedAt).format(getDateTimeFormat())}
        </Typography>
      );
    },
  },
  {
    field: 'dueBy',
    title: translate('Invoice:due'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!rowData.dueBy) return null;
      return (
        <Typography variant="body1">
          {moment(rowData.dueBy).format(getDateTimeFormat())}
        </Typography>
      );
    },
  },
  {
    field: 'jobIds',
    title: translate('Job:id'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!Array.isArray(rowData.jobIds)) return null;
      return (
        <Typography variant="body1">{rowData.jobIds.join(', ')}</Typography>
      );
    },
  },
  {
    field: 'statusId',
    title: translate('Job:status'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!rowData.statusId) return null;
      return <StatusChip invoice status={rowData.statusId} />;
    },
  },
  {
    field: 'total',
    title: translate('Account:total'),
    sorting: false,
    filtering: false,
    render: (rowData) => {
      if (!rowData.total || !rowData.currency) return null;
      return (
        <Typography>{formatPrice(rowData.total, rowData.currency)}</Typography>
      );
    },
  },
];

const Invoices = (props) => {
  const { classes, translate, clientDetails = {} } = props;
  const tableRef = useRef();
  const dispatch = useDispatch();
  const handleClickRow = (e, rowData) =>
    window.open(`/invoices/${rowData.id}`, '_blank');

  const columns = renderColumns(translate);

  const fetchVisits = async (query) => {
    const { page, pageSize, orderBy, orderDirection } = query;
    let sortParams = { '$sort[issuedAt]': -1 };
    if (orderBy) {
      const sortOrder = orderDirection === 'asc' ? 1 : -1;
      const sortKey = `$sort[${orderBy['field']}]`;
      sortParams = { [sortKey]: sortOrder };
    }

    const params = {
      $limit: pageSize,
      $skip: page * pageSize,
      clientId: clientDetails.id,
      ...sortParams,
    };
    const response = await dispatch(
      getInvoices({ params, url: 'v2/invoices' })
    ).catch((e) => ({ e }));
    if (response.e) {
      showErrorMessage(response.e);
      return Promise.reject(response.e);
    }
    return Promise.resolve({
      data: response.data.data,
      page,
      totalCount: response.data.total,
    });
  };

  return (
    <div className="list-visit">
      <div className="list-visit-wraper">
        <Paper className={classes.paperStyle}>
          <MaterialTable
            title={null}
            columns={columns}
            options={options}
            tableRef={tableRef}
            data={fetchVisits}
            onRowClick={handleClickRow}
          />
        </Paper>
      </div>
    </div>
  );
};

export default compose(withStyles(styles), withRouter)(Invoices);

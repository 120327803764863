import React, { useState, useEffect, createRef, Fragment } from 'react';
// Redux
import { connect } from 'react-redux';
import { reduxForm, submit } from 'redux-form';
import { Button, Grid, List, TextField, InputAdornment,
  CircularProgress, ListItem, Card, Typography, Divider } from '@material-ui/core';
import styles from './styles.module.scss';
import SearchIcon from '@material-ui/icons/Search';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { moment } from 'lib/app-moment';
import Loader from 'react-loader-spinner';
import { getJobs } from 'redux/job';
import { getVisits } from 'redux/visit';
import { StatusChip } from 'pages/Dashboard/components';
import { showErrorMessage } from 'lib/notifier';
import { getTimeFormat } from 'lib/formatter';

let SelectingJobAndVisitForCreateTimesheet = (props) => {
  const { translate,classes, setOpenModal, getJobs: getJobsApiFunc, getVisits: getVisitsApiFunc,
    setSelectedVisitForAddingTimesheet, staffId } = props;
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [isFetchingVisit, setIsFetchingVisit] = useState(false);
  const [isFetchingJob, setIsFetchingJob] = useState(false);

  return (
    <Grid className={styles.jobContainer} container direction="column">
      <Grid className={styles.subContainer} container>
        <Grid item xs={6} className={styles.jobSelectionContainer}>
          <JobSelection
            translate={translate}
            classes={classes}
            setSelectedJob={setSelectedJob}
            selectedJob={selectedJob}
            getJobsApiFunc={getJobsApiFunc}
            setSelectedVisit={setSelectedVisit}
            isFetchingVisit={isFetchingVisit}
            isFetchingJob={isFetchingJob}
            setIsFetchingJob={setIsFetchingJob}
            staffId={staffId}
          />
        </Grid>
        <Grid item xs={6} className={styles.visitSelectionContainer}>
          <Grid className={styles.textContainer}>
            <Typography
              component="h4"
              className={styles.titleSelectTimesheet}
            >
              {translate('Timesheet:selectJob')}
            </Typography>
          </Grid>
          {selectedJob && (
            <VisitSelection
              selectedJob={selectedJob}
              translate={translate}
              selectedVisit={selectedVisit}
              setSelectedVisit={setSelectedVisit}
              getVisitsApiFunc={getVisitsApiFunc}
              isFetchingVisit={isFetchingVisit}
              setIsFetchingVisit={setIsFetchingVisit}
              isFetchingJob={isFetchingJob}
              staffId={staffId}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        className={styles.groupBtnContainer}
        container
        justify="flex-end"
        alignItems="center"
      >
        <Button onClick={() => {
          setSelectedVisitForAddingTimesheet(null);
          setOpenModal(false);
        }}
        >
          {translate('Timesheet:cancelUpperCase')}
        </Button>
        <Button
          type="button"
          onClick={() => {
            setSelectedVisitForAddingTimesheet(selectedVisit);
            setOpenModal(false);
          }}
          classes={{ containedPrimary: styles.btnEnable }}
          variant="contained"
          color="primary"
          disabled={!selectedVisit}
        >
          {translate('Timesheet:createTimesheetUpperCase')}
        </Button>
      </Grid>
    </Grid>
  );
};

const JobSelection = (props) => {
  const { selectedJob, setSelectedJob, getJobsApiFunc, setSelectedVisit, isFetchingVisit,
    isFetchingJob, setIsFetchingJob, staffId } = props;
  const [searchString, setSearchString] = useState('');

  const WAIT_INTERVAL = 500;
  const ENTER_KEY = 13;
  let timer = null;

  const handleChange = (e) => {
    clearTimeout(timer);
    const tempString = e.target.value;
    timer = setTimeout(() => setSearchString(tempString), WAIT_INTERVAL);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(timer);
      const tempString = e.target.value;
      setSearchString(tempString);
    }
  };

  const searchField = createRef();

  useEffect(()=>{
    setSelectedJob(null);
    if(searchField && searchField.current && !isFetchingJob) {
      searchField.current.focus();
    }
  }, [isFetchingJob]);

  return (
    <>
      <Grid item className={styles.jobSelection}>
        <TextField
          fullWidth
          className={styles.searchBox}
          placeholder="Search Job title, Job ID"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          disabled={isFetchingJob}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          inputRef={searchField}
        />
        <Grid className={styles.jobList} item>
          <JobItems
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
            searchString={searchString}
            getJobsApiFunc={getJobsApiFunc}
            setIsFetchingJob={setIsFetchingJob}
            setSelectedVisit={setSelectedVisit}
            isFetchingVisit={isFetchingVisit}
            isFetchingJob={isFetchingJob}
            staffId={staffId}
          />
        </Grid>

        {isFetchingJob && (
          <Grid container className={styles.loadingScreen} alignItems="center" justify="center">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </>
  );
};
const JobItems = (props)=>{
  const { classes, selectedJob, setSelectedJob, searchString, getJobsApiFunc, setIsFetchingJob,
    setSelectedVisit, isFetchingVisit, isFetchingJob, staffId } = props;
  const [jobList, setJobList] = useState([]);

  async function fetchJobList(option = {}) {
    try {
      setJobList([]);
      setIsFetchingJob(true);
      const res = await getJobsApiFunc(option);
      setIsFetchingJob(false);
      if (res.status === 200) {
        setJobList(res.data.data);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  useEffect(() => {
    fetchJobList({ limit: 50, searchString, staffIds: staffId });
  }, [searchString]);

  return (
    <List style={{ maxHeight: '100px', width: '100%' }}>
      {jobList.map((job) => {
        return (
          <ListItem
            style={{ padding: 0, paddingBottom: 10 }}
            key={job.publicId}
            onClick={() => {
              if (
                !isFetchingVisit &&
                (!selectedJob || job.publicId !== selectedJob.publicId)
              ) {
                setSelectedJob(job);
                setSelectedVisit(null);
              }
            }}
          >
            <JobItem
              classes={classes}
              jobData={job}
              selected={selectedJob && job.publicId === selectedJob.publicId}
            />
          </ListItem>
        );
      })}
      {!isFetchingJob && jobList.length === 0 && 'No records to display'}

    </List>
  );
};

const JobItem = (props) => {
  const { jobData, selected } = props;
  return (
    <>
      <Card
        style={{ width: '100%' }}
        className={selected ? styles.jobSelected : ''}
      >
        <Grid container spacing={0}>
          <Grid
            container
            item
            xs={1}
            justify="center"
            alignItems="center"
            alignContent="flex-start"
            style={{ paddingTop: 16 }}
          >
            <BusinessCenterIcon classes={{ root: styles.colorIcon }} />
          </Grid>
          <Grid
            item
            xs={11}
            style={{ padding: 0, paddingTop: 16, paddingBottom: 16 }}
          >
            <Typography className={styles.textJobDetail} color="inherit">
              {jobData.publicId}
            </Typography>
            <Typography className={styles.companyName} color="inherit">
              {jobData.title}
            </Typography>
            <Typography color="inherit">
              <span className={styles.textJobDetail}>Client:</span>
              <span>
                { (jobData.client || {}).displayName }
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

const VisitSelection = (props) => {
  const { getVisitsApiFunc, setSelectedVisit, selectedJob, selectedVisit,
    isFetchingVisit, setIsFetchingVisit, isFetchingJob, staffId, translate } = props;
  return (
    <>
      <Grid item className={styles.visitListItem}>
        <VisitItems
          translate={translate}
          selectedVisit={selectedVisit}
          selectedJob={selectedJob}
          getVisitsApiFunc={getVisitsApiFunc}
          setSelectedVisit={setSelectedVisit}
          setIsFetchingVisit={setIsFetchingVisit}
          isFetchingJob={isFetchingJob}
          isFetchingVisit={isFetchingVisit}
          staffId={staffId}
        />
        {isFetchingVisit &&
          <Grid style={{ height: '100%' }} container alignItems='center' justify='center' direction='column'>
            <Loader color="primary" height={80} width={80} />
          </Grid>}
      </Grid>
    </>
  );
};

const VisitItems = (props)=>{
  const { selectedJob, getVisitsApiFunc, setSelectedVisit, setIsFetchingVisit, selectedVisit, isFetchingJob,
    isFetchingVisit, staffId, translate } = props;
  const [visitList, setVisitList] = useState([]);

  async function fetchVisitList(option = {}) {
    try {
      setVisitList([]);
      setIsFetchingVisit(true);
      const res = await getVisitsApiFunc(option);
      setIsFetchingVisit(false);
      if (res.status === 200) {
        setVisitList(res.data.data);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }
  useEffect(() => {
    if(selectedJob)
      fetchVisitList({ limit: 50, jobId: selectedJob.publicId, staffId, notTimesheetYet: true, staffIds: [staffId] });
  },[selectedJob]);
  useEffect(() => {
    if(selectedJob) setVisitList([]);
  },[isFetchingJob]);
  return (
    <List style={{ width: '100%', maxHeight: '100px' }}>
      {visitList &&
        visitList.length > 0 &&
        visitList.map((visit, visitIndex) => {
          return (
            <Fragment key={visit.id}>
              <ListItem
                style={{ padding: 0 }}
                key={visit.publicId}
                onClick={() => {
                  if (!selectedVisit || visit.publicId !== selectedVisit.id)
                    setSelectedVisit(visit);
                }}
              >
                <Grid
                  style={{ width: '100%' }}
                  className={
                    selectedVisit && visit.id === selectedVisit.id
                      ? styles.vistSelected
                      : ''
                  }
                  container
                  direction="row"
                >
                  <Grid item xs={9}>
                    <Typography className={styles.visitTitle} color="inherit" component="h3">
                      {visit.title}
                    </Typography>
                    <Typography className={styles.visitTime} color="inherit" style={{ marginBottom: 12 }}>
                      {moment(visit.start).isSame(visit.end, 'day')
                        ? `${moment(visit.start).format(getTimeFormat())} - ${moment(
                          visit.end
                        ).format('HH:mm, MMM DD, YYYY')}`
                        : `${moment(visit.start).format(
                          'HH:mm, MMM DD, YYYY'
                        )} - ${moment(visit.end).format(
                          'HH:mm, MMM DD, YYYY'
                        )}`}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    container
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                  >
                    <StatusChip job status={visit.statusId} />
                  </Grid>
                </Grid>
              </ListItem>
              {visitIndex !== visitList.length - 1 && <Divider />}
            </Fragment>
          );
        })}
      {!isFetchingVisit && visitList.length === 0 && translate('Timesheet:noVisit')}

    </List>
  );
};

const mapDispatchToProps = (dispatch) => ({
  submitClientForm: () => dispatch(submit('clientForm')),
  getJobs: (options = {}) => dispatch(getJobs(options)),
  getVisits: (options) => dispatch(getVisits(options)),
});

SelectingJobAndVisitForCreateTimesheet = reduxForm({
  form: 'EditClockInOutForm',
  enableReinitialize: false,
  destroyOnUnmount: true,
})(SelectingJobAndVisitForCreateTimesheet);

export default connect(
  null,
  mapDispatchToProps
)(SelectingJobAndVisitForCreateTimesheet);

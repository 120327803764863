import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

// Material helpers
import {
  Dialog,
  Button,
  Grid,
  Typography,
  Box,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import styles from './styles.module.scss';

import {
  VisitDetails,
  GeneralInfo,
  ClientDetails,
  Visits,
  Team,
  ServiceReport
} from './components/tabs';

import {
  ArrowBack as ArrowBackIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  InfoOutlined as InfoOutlinedIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
} from '@material-ui/icons';

import visitDetailsIcon from 'assets/images/icons/visit-icon.png';
import visitIcon from 'assets/images/icons/visits.svg';
import teamIcon from 'assets/images/icons/team.svg';
import serviceIcon from 'assets/images/icons/sr.svg';

import { STATUS_TO_ACTION } from 'common/constant';
import { deleteVisit, updateVisitByAction } from 'redux/visit';

import { showErrorMessage } from 'lib/notifier';
import { prepareFormValue } from './components/utils';
import { ConfirmReviewModal }
  from 'pages/Dashboard/views/Jobs/JobDetails/components/ConfirmReviewModal/components/ReviewDialog';
import { BackdropCustom, useBackdrop } from 'lib/loading';

const FORM_NAME = 'visitDetailsForm';

const generateTab = ({ t, numberCreatedSr }) => [
  {
    icon: <InfoOutlinedIcon style={{ fontSize: 16 }} />,
    text: t('generalInfo'),
    tabContent: GeneralInfo,
  },
  {
    icon: (
      <PersonOutlineOutlinedIcon style={{ fontSize: 16 }} />
    ),
    text: t('clientDetails'),
    tabContent: ClientDetails,
  },
  {
    icon: <img src={visitIcon} alt="visitIcon" />,
    text: t('visit'),
    tabContent: Visits,
  },
  {
    icon: <img src={serviceIcon} alt="serviceReport" />,
    text: t('serviceReportNumber', { numberCreatedSr }),
    tabContent: ServiceReport,
  },
  {
    icon: <img src={teamIcon} alt="teamIcon" />,
    text: t('team'),
    tabContent: Team,
  },
];

const VisitDetailsModal = (props) => {
  const {
    showJobDetails,
    handleCloseVisitDetails,
    callbackAfterMarkJobComplete,
    callbackAfterDeleteVisit,
    translate,
    visitId,
    visitDetails,
    fetchData,
    handleCheckJobReviewed
  } = props;

  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isUpdateTimestamp , setIsUpdateTimestamp] = useState(false);
  const [selectedTab, setSelectedTab] = useState(-1);
  const [openBackdrop, withLoading]= useBackdrop();

  useEffect(() => {
    if (!showJobDetails) setIsUpdateTimestamp(false);
  }, [showJobDetails]);

  const updateFormValues = ({ data = {} }) => {
    const {
      statusId,
      ontheway,
      arrived,
      start,
      completed,
      cancelled,
    } = prepareFormValue(data, translate);
    dispatch(change(FORM_NAME, 'statusId', statusId));
    dispatch(change(FORM_NAME, 'ontheway', ontheway));
    dispatch(change(FORM_NAME, 'arrived', arrived));
    dispatch(change(FORM_NAME, 'start', start));
    dispatch(change(FORM_NAME, 'completed', completed));
    dispatch(change(FORM_NAME, 'cancelled', cancelled));
  };

  const generatedTab = generateTab({ t: translate, numberCreatedSr: visitDetails ? visitDetails.createdSr : '' });

  const handleUpdateStatus = (status) => handleCheckJobReviewed(
    async () => {
      const action = STATUS_TO_ACTION[status];
      const result = await dispatch(
        updateVisitByAction({ visitId, action })
      ).catch((e) => ({ e }));

      if (result.e) return showErrorMessage(result.e);

      updateFormValues(result);
      fetchData();
      callbackAfterMarkJobComplete && callbackAfterMarkJobComplete(result);
    }
  );

  const handleClickDeleteVisit = () => handleCheckJobReviewed(() => setShowConfirmModal(true));

  const handleConfirmedDelete = async () => {
    setShowConfirmModal(false);
    const result = await dispatch(deleteVisit(visitId)).catch((e) => ({ e }));

    if (result.e) return showErrorMessage(result.e);
    callbackAfterDeleteVisit && callbackAfterDeleteVisit(result);
  };

  const handleClickItem = (index = -1) => setSelectedTab(index);

  const Comp =
    selectedTab === -1 ? VisitDetails : generatedTab[selectedTab].tabContent;

  const onCloseHandle = () => {
    handleCloseVisitDetails && handleCloseVisitDetails(isUpdateTimestamp);
    handleClickItem();
  };

  return (
    <>
      <Dialog
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to delete this visit?
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setShowConfirmModal(false)} color="primary">
            {translate('Common:cancel')}
          </Button>
          <Button onClick={handleConfirmedDelete} color="primary" autoFocus>
            {translate('Common:ok')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{ paper: styles.dialog_wrapper }}
        open={showJobDetails}
        onClose={onCloseHandle}
      >
        <Grid container className={styles.height_100}>
          <Grid item sm={3} md={3} className={styles.left_sidebar}>
            <Box p={2}>
              <Button onClick={onCloseHandle}>
                <ArrowBackIcon className="mr_5" />
                {translate('Common:back')}
              </Button>

              <Box
                mt={2}
                py={2}
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                className={selectedTab === -1 ? styles.selected : styles.cursor}
                onClick={() => handleClickItem(-1)}
              >
                <Box display="flex" alignItems="center">
                  <img
                    src={visitDetailsIcon}
                    alt="visitDetailsIcon"
                    className="mr_5"
                  />
                  <Typography>{translate('visitDetails')}</Typography>
                </Box>
                <KeyboardArrowRightIcon />
              </Box>

              <Box mt={3} display="flex" flexDirection="column">
                <Box mb={1}>
                  <Typography variant="h6" className={styles.text_grey} style={{ fontSize: 12 }}>
                    {translate('jobContainVisit')}
                  </Typography>
                </Box>

                {generatedTab.map((item, index) => (
                  <Grid
                    key={index}
                    className={
                      selectedTab === index ? styles.selected : styles.cursor
                    }
                    onClick={() => handleClickItem(index)}
                  >
                    <Box display="flex" alignItems="center" p={1}>
                      {item.icon && (
                        <Grid item className="mr_5">
                          {item.icon}
                        </Grid>
                      )}
                      {item.text && (
                        <Grid item className={styles.text_grey}>
                          {item.text}
                        </Grid>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item sm={9} md={9} className={styles.right_sidebar}>
            <Comp
              translate={translate}
              {...props}
              handleClickDeleteVisit={handleClickDeleteVisit}
              handleUpdateStatus={handleUpdateStatus}
              setIsUpdateTimestamp={setIsUpdateTimestamp}
            />
          </Grid>
        </Grid>
        <ConfirmReviewModal
          jobId={visitDetails?.job.publicId}
          refetchJobDetail={fetchData}
          withLoading={withLoading}
        />
        <BackdropCustom open={openBackdrop} />
      </Dialog>
    </>
  );
};

export default VisitDetailsModal;

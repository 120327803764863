import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getHubDetails, hubAction } from "redux/hub";
import { WAREHOUSE_ACTIONS } from "common/constant";
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { Form } from "./components";
import styles from "../styles.module.scss";
import { showErrorMessage, showSuccessMessage } from "lib/notifier";

const WarehouseDetails = (props) => {
  const {
    history,
    match: {
      params: { hubId },
    },
    location: { state = {} },
  } = props;

  const enterpriseId = state.data && state.data.enterpriseId;
  const goBackRoute = enterpriseId ? `/enterprise/${enterpriseId}` : "/hub";

  const { t: translate } = useTranslation("Hub");
  const dispatch = useDispatch();
  const handleBack = () => history.push(goBackRoute);
  const [warehouseDetails, setWarehouseDetails] = useState({});
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);

  const handleCloseModal = () => setIsOpenModalConfirm(false);
  const handleClickArchive = () => setIsOpenModalConfirm(true);

  const handleConfirm = async (id) => {
    const isArchive = warehouseDetails.archived;
    const payload = {
      action: isArchive
        ? WAREHOUSE_ACTIONS.UNARCHIVE_WAREHOUSE
        : WAREHOUSE_ACTIONS.ARCHIVE_WAREHOUSE,
      hubId: id,
      enterpriseId,
    };
    const result = await dispatch(hubAction(payload)).catch((e) => ({
      e,
    }));
    if (result.e) return showErrorMessage(result.e);
    setIsOpenModalConfirm(false);
    showSuccessMessage(
      translate(isArchive ? "unarchiveConfirm" : "archiveConfirm")
    );

    const updateResult = { ...warehouseDetails, ...result };
    setWarehouseDetails(updateResult);
  };

  useEffect(() => {
    (async () => {
      const params = { enterpriseId };
      const result = await dispatch(getHubDetails(hubId, params)).catch(
        (e) => ({
          e,
        })
      );

      if (result.e) return showErrorMessage(result.e);
      setWarehouseDetails(result);
    })();
  }, [dispatch, hubId]);

  const handleClickEdit = () =>
    history.push(`/hub/${hubId}/edit`, { data: { enterpriseId } });

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={handleBack}>
          <ArrowBackIcon className="mr_5" />
          {translate("Common:backHubs")}
        </Button>

        <Box display="flex">
          <Box mr={2}>
            <Button
              variant="contained"
              className={styles.btn_action}
              onClick={handleClickArchive}
            >
              {translate(
                warehouseDetails.archived
                  ? "Common:unarchive"
                  : "Common:archive"
              )}
            </Button>
          </Box>

          <Button
            className={styles.btn_action}
            onClick={handleClickEdit}
            variant="contained"
          >
            {translate("edit")}
          </Button>
        </Box>
      </Box>

      <Dialog
        open={isOpenModalConfirm}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography variant="h4">
            {translate(
              warehouseDetails.archived ? "unarchiveHub" : "archiveHub"
            )}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            className={styles.close_btn}
          >
            {translate("Common:close")}
          </Button>
          <Button
            onClick={() => handleConfirm(hubId)}
            className={styles.confirm_archived}
            variant="contained"
            autoFocus
          >
            {translate(
              warehouseDetails.archived ? "Common:unarchive" : "Common:archive"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Form
        {...props}
        translate={translate}
        warehouseDetails={warehouseDetails}
      />
    </Box>
  );
};

export default WarehouseDetails;

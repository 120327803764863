import React from 'react';

import {
  Button,
  Grid,
  Typography,
  Box,
  ClickAwayListener,
} from '@material-ui/core';

import styles from '../../components.module.scss';

import TeamPopover from '../../popover/TeamPopover';
import AvatarSrc from 'assets/images/icons/user.svg';
import { getTeamStatus } from 'pages/Dashboard/helpers/getTeamStatus';

const renderFieldTeam = (props) => {
  const { input, label, translate, handleClick, inputEl, handleClose } = props;
  if (!Array.isArray(input.value) || !input.value.length) return null;

  const displayUsers = input.value.slice(0, 2);
  return (
    <>
      <Box
        ref={inputEl}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={styles.label}>{label}</Typography>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid>
            <Button onClick={handleClick}>
              {translate('viewAll', { total: input.value.length })}
            </Button>
            <TeamPopover {...props} />
          </Grid>
        </ClickAwayListener>
      </Box>

      <Grid container spacing={2}>
        {displayUsers.map((user, index) => (
          <Grid key={index} item md={6} sm={6}>
            <Box p={2} className={styles.team_card} display="flex" width="100%">
              <img
                className={styles.user_icon}
                src={AvatarSrc}
                alt="AvatarSrc"
              />
              <Box width="75%">
                <Typography variant="h6">{user.fullName} {getTeamStatus(user.isDeleted, user.isArchived, translate)}</Typography>
                <Typography className={`${styles.word_break} mt_5`}>
                  {user.email}
                </Typography>
                <Typography>
                  {[user.countryPhoneCode, user.phoneNumber]
                    .filter(Boolean)
                    .join('')}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default renderFieldTeam;

import React from 'react';

// Externals
import classNames from 'classnames';

// Material components
import { FormControl, FormLabel, Button, ButtonGroup } from '@material-ui/core';

// Material helpers
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  groupLabel: {
    padding: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: 12,
  },
  button: {
    borderRadius: 4,
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
  },
});

export default withStyles(styles)((props) => {
  const { classes, data, groupLabel, input, isFullWidth = true } = props;

  const handleClick = (value) => input.onChange(value);

  return (
    <FormControl fullWidth={isFullWidth}>
      <FormLabel className={classes.groupLabel}>{groupLabel}</FormLabel>
      <ButtonGroup fullWidth>
        {data &&
          data.map((item) => {
            const selected = input.value === item.id;

            return (
              <Button
                key={item.id}
                className={classNames(classes.button, {
                  [classes.selectedButton]: selected,
                })}
                selected={selected}
                onClick={() => handleClick(item.id)}
              >
                {item.name}
              </Button>
            );
          })}
      </ButtonGroup>
    </FormControl>
  );
});

import palette from '../palette';

export default {
  root: {
    color: palette.secondary.main,
    '& .MuiInputBase-underline:before': {
      borderBottomColor: palette.secondary.main,
    },
  },
};

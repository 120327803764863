import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import styles from './components.module.scss';
import { useTranslation } from 'react-i18next';

const StatusChip = ({ isActive }) => {
  const { t } = useTranslation('Common');

  if (!isActive) {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        className={styles.deactivatedStatusChip}
      >
        <Typography className={styles.deactivatedText}>
          {t('deactivated')}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={styles.activeStatusChip}
    >
      <Typography className={styles.activeText}>{t('active')}</Typography>
    </Grid>
  );
};

export default StatusChip;

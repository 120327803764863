import React from 'react';
import moment from 'moment';
// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

// components
import { Grid, Typography, Tooltip, Box } from '@material-ui/core';
import { StatusChip } from '../../../components';
import styles from '../../details.module.scss';

const FORM_NAME = 'UserInfoDetailsView';

const renderTextField = ({ noMargin, input, label }) => {
  if (!input.value) return null;
  return (
    <Grid className={!noMargin && 'mt_15'}>
      <Typography className={styles.label}>{label}</Typography>
      <Typography variant="h5" className={styles.content}>
        {input.value}
      </Typography>
    </Grid>
  );
};

const renderCategoryTags = ({ input, label }) => {
  const deepClone = [...input.value];
  if (!Array.isArray(deepClone) || !deepClone.length > 0) return null;
  const showingTags = deepClone.splice(0, 3);
  return (
    <Grid className="mt_15">
      <Typography className={styles.label}>{label}</Typography>
      <Box display='flex' flexWrap='wrap'>
        {showingTags.map((tag, index) => (
          <Grid key={index} className={styles.tag}>
            {tag.name}
          </Grid>
        ))}
        {deepClone.length > 0 && (
          <Tooltip
            title={deepClone.map((item) => item.name).join(', ')}
            className={styles.cursor}
          >
            <Grid className={styles.tag}>+{deepClone.length}</Grid>
          </Tooltip>
        )}
      </Box>
    </Grid>
  );
};

const renderStatusChip = ({ input, label }) => {
  return (
    <Grid className="mt_15">
      <Typography className={styles.label}>{label}</Typography>
      <StatusChip isActive={!input.value} />
    </Grid>
  );
};

const EnterpriseForm = (props) => {
  const { t } = props;

  return (
    <>
      <Grid item md={7}>
        <Field
          noMargin
          name="id"
          component={renderTextField}
          label={t('userId')}
        />
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Field
              name="firstName"
              component={renderTextField}
              label={t('Common:firstName')}
            />
          </Grid>
          <Grid item md={6}>
            <Field
              name="lastName"
              component={renderTextField}
              label={t('Common:lastName')}
            />
          </Grid>
        </Grid>
        <Field
          name="email"
          component={renderTextField}
          label={t('Common:email')}
        />
        <Field
          name="phoneNumber"
          component={renderTextField}
          label={t('Common:phoneNumber')}
        />
        <Field
          name="title"
          component={renderTextField}
          label={t('Common:title')}
        />
        <Field
          name="dateJoined"
          component={renderTextField}
          label={t('dateJoined')}
        />
      </Grid>

      <Grid item md={5}>
        <Field
          noMargin
          name="roleId"
          component={renderTextField}
          label={t('role')}
        />
        <Field
          name="accountStatus"
          component={renderStatusChip}
          label={t('accountStatus')}
        />
        <Field
          name="categories"
          component={renderCategoryTags}
          label={t('Category:categories')}
        />
        <Field name="notes" component={renderTextField} label={t('notes')} />
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { userDetails, t } = ownProps;
  const dateJoined = userDetails.joinedDate || userDetails.createdAt;
  return {
    initialValues: {
      id: userDetails.id,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      phoneNumber: [userDetails.countryPhoneCode, userDetails.phoneNumber].join(
        ''
      ),
      title: userDetails.title,
      accountStatus: userDetails.archived,
      notes: userDetails.notes,
      dateJoined: dateJoined && moment(dateJoined).format(t('Job:dateFormat')),
      roleId: userDetails.roleId,
      categories: userDetails.categories || [],
    },
  };
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })
)(EnterpriseForm);

import common from './common';
import sidebar from './sidebar';
import landingPage from './landingPage';
import authenticationPages from './authenticationPages';
import clientPages from './clientPages';
import jobPages from './jobPages';
import invoicePages from './invoicePages';
import staffPages from './staffPages';
import productsServicesPages from './productsServicesPages';
import calendar from './calendar';
import tracking from './tracking';
import errorMessages from './errorMessages';
import timesheetPages from './timesheetPages';
import location from './location';
import quotePage from './quotePages';
import staffInvitation from './staffInvitation';
import companySetting from './companySetting';
import workSetting from './workSetting';
import accountSetting from './accountSetting';
import account from './account';
import category from './category';
import hub from './hub';

export default {
  Common: { ...common },
  Sidebar: { ...sidebar },
  LandingPage: { ...landingPage },
  Auth: { ...authenticationPages },
  Client: { ...clientPages },
  Quote: { ...quotePage },
  Job: { ...jobPages },
  Invoice: { ...invoicePages },
  Staff: { ...staffPages },
  Product: { ...productsServicesPages },
  Calendar: { ...calendar },
  Tracking: { ...tracking },
  Error: { ...errorMessages },
  Timesheet: { ...timesheetPages },
  Location: { ...location },
  StaffInvitation: { ...staffInvitation },
  CompanySetting: { ...companySetting },
  WorkSetting: { ...workSetting },
  AccountSetting: { ...accountSetting },
  Account: { ...account },
  Category: { ...category },
  Hub: { ...hub }
};

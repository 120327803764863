import React from 'react';
import { Grid, Card, Typography, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

// import component
import { connect } from 'react-redux';
import ServiceReportHeader from '../../components/utils/ServiceReportHeader';
import styles from './styles.module.scss';
import moment from 'moment';
import CustomerInfo from './CustomerInformation';
import DeviceInfo from './DeviceInfo';
import ServiceProvide from './ServiceProvide';
import JobImages from './JobImages';
import { VIEW_SERVICE_REPORT_FORM_NAME } from 'common/constant';
import { getDateTimeFormat } from 'lib/formatter';

const ServiceReportTypePictorial = ['customer_info', 'job_image'];
const ServiceReportTypeFull = [
  'customer_info',
  'device_info',
  'service_provide',
  'job_image',
];

const DEFAULT_SERVICE_PROVIDE = [
  {
    name: '',
    description: '',
    quantity: '',
    unitPrice: '',
    total: '',
  },
];

const DEFAULT_DEVICE_INFO = [
  {
    modelNumber: '',
    serialNumber: '',
    serviceType: '',
  },
];

const mapNameToComponent = {
  customer_info: CustomerInfo,
  device_info: DeviceInfo,
  service_provide: ServiceProvide,
  job_image: JobImages,
};

const mapStateToProps = (state, ownProps) => {
  const reportDetails = state.job.reportDetails || {};
  const medias = reportDetails.medias || [];
  // split base on phase type
  const [evaluationMedia, afterJobMedia] = medias.reduce(
    // Use "deconstruction" style assignment
    (result, element) => {
      result[element.mediaPhase === '0' ? 0 : 1].push(element);
      return result;
    },
    [[], []]
  );
  const {
    serviceItems,
    deviceInfo,
    jobId,
    visitId,
    customer,
    address,
    phoneNumber,
    requestedAt,
    scheduledAt,
    description,
    remark,
    reportNumber,
    createdAt,
    visit,
    job,
  } = reportDetails;

  const serviceProvided =
    Array.isArray(serviceItems) && serviceItems.length
      ? serviceItems
      : DEFAULT_SERVICE_PROVIDE;
  const products =
    Array.isArray(deviceInfo) && deviceInfo.length
      ? deviceInfo
      : DEFAULT_DEVICE_INFO;
  const { translate } = ownProps;
  return {
    initialValues: {
      jobId,
      visitId,
      customer,
      address,
      phoneNumber,
      requestedDate:
        (requestedAt && moment(requestedAt).format(translate('dateFormat'))) ||
        '',
      scheduledDate:
        (scheduledAt && moment(scheduledAt).format(translate('dateFormat'))) ||
        '',
      deviceInfo: products,
      description,
      actionTaken: remark,
      serviceProvided,
      timeOfCompletion:
        (reportDetails.workerTimelog &&
          reportDetails.workerTimelog.completion_at &&
          moment(reportDetails.workerTimelog.completion_at).format(
            getDateTimeFormat()
          )) ||
        '',
      timeArrival:
        (reportDetails.workerTimelog &&
          reportDetails.workerTimelog.arrival_at &&
          moment(reportDetails.workerTimelog.arrival_at).format(
            getDateTimeFormat()
          )) ||
        '',
      serviceBy:
        (reportDetails.workerTimelog && reportDetails.workerTimelog.worker) ||
        '',
      evaluation: evaluationMedia || [],
      afterJob: afterJobMedia || [],
      signatureFile:
        (reportDetails.signature && reportDetails.signature.signatureFile) ||
        '',
      signatureDate:
        reportDetails.signature &&
        moment(reportDetails.signature.createdAt).format(
          getDateTimeFormat()
        ),
      visitPublicId: visit && visit.publicId,
      orderId: job && job.orderId,
    },
    deviceInfo: products,
    serviceProvided,
    reportNumber,
    createdAt:
      (createdAt && moment(createdAt).format(translate('dateFormat'))) || '',
    evaluation: evaluationMedia || [],
    afterJob: afterJobMedia || [],
    reportType: reportDetails.reportType,
  };
};

const ServiceReportDetailsForm = (props) => {
  const {
    translate,
    reportNumber,
    createdAt,
    deviceInfo,
    serviceProvided,
    reportType,
    evaluation,
    afterJob,
    componentRef,
  } = props;
  const renderTypography = ({ title, input, bold, variant = 'body1' }) => {
    return (
      <>
        {title && <Typography>{title}:</Typography>}

        <Typography variant={variant} className={bold ? styles.boldText : null}>
          {input.value}
        </Typography>
      </>
    );
  };

  const renderSignature = ({ input }) => {
    if (!input.value) return null;
    return (
      <img className={styles.signatureImg} src={input.value} alt="signature" />
    );
  };

  const components =
    reportType === 'FULL' ? ServiceReportTypeFull : ServiceReportTypePictorial;

  return (
    <>
      {/* Service report content */}
      <Grid className={styles.viewContent}>
        {/* <Grid item sm={2} className="pr_20">
          <Card>Page 1</Card>
        </Grid> */}
        <Grid item sm={12} ref={componentRef}>
          <Card className="p_20">
            <ServiceReportHeader
              noPadding
              reportNo={reportNumber}
              createdAt={createdAt}
            />

            <Grid container className="mt_20">
              <Grid item className={`${styles.dp_flex} mr_20`}>
                <Field
                  style={{ width: '100%' }}
                  name="jobId"
                  component={renderTypography}
                  bold
                  title={translate('job')}
                  margin="dense"
                />
              </Grid>

              <Grid item className={`${styles.dp_flex} mr_20`}>
                <Field
                  style={{ width: '100%' }}
                  name="visitPublicId"
                  component={renderTypography}
                  bold
                  title={translate('visit')}
                  margin="dense"
                />
              </Grid>

              <Grid item className={`${styles.dp_flex} mr_20`}>
                <Field
                  style={{ width: '100%' }}
                  name="orderId"
                  component={renderTypography}
                  bold
                  title={translate('orderId')}
                  margin="dense"
                />
              </Grid>
            </Grid>

            {components.map((compName, index) => {
              const Comp = mapNameToComponent[compName];
              if (!Comp) return null;
              return (
                <Comp
                  key={index}
                  reportType={reportType}
                  translate={translate}
                  renderTypography={renderTypography}
                  deviceInfo={deviceInfo}
                  serviceProvidedList={serviceProvided}
                  evaluation={evaluation}
                  afterJob={afterJob}
                />
              );
            })}

            <Grid className={`mt_20 ${styles.certified} page-break-inside`}>
              <Grid container>
                <Typography variant="h5" className={styles.boldText}>
                  {translate('certified')}
                </Typography>
              </Grid>
              <Grid container className="mt_25 mb_15">
                <Typography variant="body1" className={styles.signatureTitle}>
                  {translate('servicedBy')}:
                </Typography>
                <Field name="serviceBy" component={renderTypography} />
              </Grid>
              <Grid container className="mb_15">
                <Typography variant="body1" className={styles.signatureTitle}>
                  {translate('timeArrival')}:
                </Typography>
                <Field name="timeArrival" component={renderTypography} />
              </Grid>
              <Grid container className="mb_15">
                <Typography variant="body1" className={styles.signatureTitle}>
                  {translate('timeOfCompletion')}:
                </Typography>
                <Field name="timeOfCompletion" component={renderTypography} />
              </Grid>
              <Grid className={styles.signature}>
                <Grid container direction="column">
                  <Typography variant="h5">
                    {translate('clientSignature')}
                  </Typography>
                  <Box ml={2}>
                    <Field name="signatureFile" component={renderSignature} />
                  </Box>
                </Grid>
                <Grid className={styles.signatureName}>
                  <Typography align="center" className={styles.boldText}>
                    {translate('Common:name')}
                  </Typography>
                  <Typography variant="body2" align="center">
                    {translate('signatureDescription')}
                  </Typography>
                </Grid>
                <Grid className={styles.signatureDate}>
                  <Typography align="center" className={styles.boldText}>
                    {translate('Common:date')}
                  </Typography>
                  <Grid container alignItems="flex-end">
                    <Field
                      name="signatureDate"
                      variant="body2"
                      component={renderTypography}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default compose(
  connect(mapStateToProps, null),
  withRouter,
  reduxForm({
    form: VIEW_SERVICE_REPORT_FORM_NAME,
  })
)(ServiceReportDetailsForm);

export default theme => ({
  billingHistoryCard: {
    marginTop: 30,
  },
  listItem: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.muted2,
    }
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  topDate: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  balanceFooter: {
    fontWeight: 600,
  },
});
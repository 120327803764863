import React, { useState } from 'react';
import { Box, Card, Typography, Button } from '@material-ui/core';
import { Field } from 'redux-form';
import styles from '../card.module.scss';
import defaultAvatarSrc from 'assets/images/icons/default-avatar.svg';

import PropertiesModal from './components/propertiesModal';

const renderField = ({ input, label, customStyle }) => {
  if (!input.value) return null;
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {label && (
        <Box>
          <Typography className={styles.grey_color}>{label}</Typography>
        </Box>
      )}
      <Typography className={customStyle}>{input.value}</Typography>
    </Box>
  );
};

const renderPropertyField = ({ input, label, t, handleOpenModal }) => {
  if (!input.value || !Object.keys(input.value).length) return null;
  const { unitNumber, address, city } = input.value;

  return (
    <Box display="flex" flexDirection="column">
      {label && (
        <Box justifyContent="space-between" display="flex" alignItems="center">
          <Typography className={styles.grey_color}>{label}</Typography>
          <Button className={styles.view_all_btn} onClick={handleOpenModal}>{t('viewAll')}</Button>
        </Box>
      )}
      <Typography className='font_bold'>{input.value.name}</Typography>
      <Typography>{[unitNumber, address, city].filter(Boolean).join(', ')}</Typography>
    </Box>
  );
};

const JobDetails = (props) => {
  const { t } = props;
  const [isOpenModalProperties, setIsOpenModalProperties] = useState(false);

  const handleOpenModal = () => setIsOpenModalProperties(true);
  const handleCloseModal = () => setIsOpenModalProperties(false);

  return (
    <>
      {isOpenModalProperties && (
        <PropertiesModal {...props} handleClose={handleCloseModal} />
      )}
      <Card className={styles.card}>
        <Box p={2} display="flex" alignItems="center" flexDirection="column">
          <img src={defaultAvatarSrc} alt="defaultAvatarSrc" />
          <Typography className={`${styles.grey_color} font_bold mt_5`}>{t('Common:clientDetailsUpper')}</Typography>
          <Field name="clientName" component={renderField} customStyle="font_bold" />
        </Box>
        <Box p={2}>
          <Field
            name="property"
            label={t('propertyAddress')}
            t={t}
            component={renderPropertyField}
            handleOpenModal={handleOpenModal}
          />
          <Box mt={2}>
            <Field
              name="clientEmail"
              label={t('Common:email')}
              component={renderField}
            />
          </Box>
          <Box mt={2}>
            <Field
              name="clientPhoneNumber"
              label={t('Common:phoneNumber')}
              component={renderField}
            />
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default JobDetails;

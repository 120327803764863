import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { formatPrice } from 'lib/formatter';

const ItemOnly = (props) => {
  const { translate, itemDetails } = props;
  const currency = useSelector(
    (state) => state.auth.currentUser.companies[0].currency
  );

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!Array.isArray(itemDetails)) return true;
    const newValue = itemDetails.reduce((prev, current) => {
      if (!current.inventories) return prev;
      return [...prev, ...current.inventories];
    }, []);

    setItems(newValue);
  }, [itemDetails]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align='left'>{translate('name')}</TableCell>
          <TableCell align='left'>{translate('model')}</TableCell>
          <TableCell align='left'>{translate('serialNumber')}</TableCell>
          <TableCell align='left'>{translate('unitPrice')}</TableCell>
          <TableCell align='left'>{translate('quantity')}</TableCell>
          <TableCell align='left'>{translate('total')}</TableCell>
          <TableCell align='left'>Self Collected</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(items) &&
          items.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell align='left'>{row.name}</TableCell>
                <TableCell style={{ minWidth: 150 }} align='left'>
                  {row.model}
                </TableCell>
                <TableCell align='left'>{row.serialNumber}</TableCell>
                <TableCell style={{ minWidth: 150 }} align='left'>
                  {formatPrice(row.unitPrice, currency)}
                </TableCell>
                <TableCell align='left'>{row.quantity}</TableCell>
                <TableCell style={{ minWidth: 150 }} align='left'>
                  {formatPrice(row.total, currency)}
                </TableCell>
                <TableCell>{row.selfCollected ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default ItemOnly;

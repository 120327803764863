import { setIsLoading } from './global';
import { setCompanySetupCompleted } from './auth';
import { PATCH, GET, POST, DELETE } from 'lib/api';

const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
const SET_CLEAR_COMPANY = 'SET_CLEAR_COMPANY';

const initialState = {
  details: {}
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        details: {
          ...action.details,
        }
      };

    case SET_CLEAR_COMPANY:
      return {
        ...state,
        details: {}
      };

    default: return state;
  }
}

// Action Creators
export function setCurrentCompanyDetails(details) {
  return { type: SET_CURRENT_COMPANY, details };
}

export function clearCompanyDetails() {
  return { type: SET_CLEAR_COMPANY };
}

export function companyDetailsUpdate(companyInfo) {
  return (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const { accessToken } = getState().auth;
    return PATCH('/company-details', accessToken, { data: companyInfo }).then((response) => {
      if (response.status === 200) {
        dispatch(setCompanySetupCompleted(response.data.setupCompleted));
      };
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function updateCompanyTaxSettings(taxSettingsData) {
  return (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const { accessToken } = getState().auth;
    return PATCH('/company-tax-rates', accessToken, { data: taxSettingsData }).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function getCompanyDetails() {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET('/company-details', accessToken).then((response) => {
      if (response.status === 200) {
        const companyDetails = response.data;
        if (companyDetails.workingHours) {
          companyDetails.workingHours = companyDetails.workingHours.map((item) => {
            if (!item.working) {
              item.startTime = null;
              item.endTime = null;
              return item;
            };

            // item.startTime = parseTimeToDateTime(item.startTime, 'HH:mm');
            // item.endTime = parseTimeToDateTime(item.endTime, 'HH:mm');
            return item;
          });
        }
        dispatch(setCurrentCompanyDetails(companyDetails));
        response.data = { ...response.data, companyDetails };
      };

      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function updateCompanyDetailForm(companyDetails) {
  const { id, companySizeId, startingYear, userRoleInCompany, leadSourceId,
    extraForIndustry, industries, setupCompleted } = companyDetails;

  return {
    id,
    companySizeId,
    startingYear,
    userRoleInCompany,
    leadSourceId,
    extraForIndustry,
    industries: industries || [],
    setupCompleted,
  };
}


export function getCompanyWorkSettings() {
  return (_dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET('/company-work-settings', accessToken).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function getCompanyTaxRates() {
  return (_dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET('/company-tax-rates', accessToken).then((response) => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(err => {
      return Promise.reject(err);
    });
  };
}

export function submitNewEnterpriseAccount(payload) {
  return async (_dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/companies', accessToken, { data: payload });
  };
}

export function submitEditEnterpriseAccount(id, payload) {
  return async (_dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(`/companies/${id}`, accessToken, { data: payload });
  };
}

export function enterpriseDetails(id) {
  return async (_dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/companies/${id}`, accessToken);
  };
}

export function deleteEnterpriseAccount(id) {
  return async (_dispatch, getState) => {
    const { accessToken } = getState().auth;
    return DELETE(`/companies/${id}`, accessToken);
  };
}
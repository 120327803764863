import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Button } from '@material-ui/core';
import styles from '../components.module.scss';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const CalendarListToolbar = (props) => {
  const { onNavigate, label } = props;

  const { t } = useTranslation('Calendar');

  return (
    <Grid
      className={styles.toolBar_wrapper}
      container
      display="flex"
      alignItems="center"
    >
      <Box display="flex">
        <Button
          className={`${styles.btn_today} ${styles.font_weight_700} ${styles.text_responsive}`}
          size="medium"
          variant="contained"
          onClick={() => onNavigate('TODAY')}
        >
          {t('Today').toUpperCase()}
        </Button>

        <Box
          ml={2}
          display="flex"
          alignItems="center"
          className={`${styles.month_selector} ${styles.font_weight_700} ${styles.text_responsive}`}
        >
          <Button
            className="toolbar-navigation-button"
            type="button"
            onClick={() => onNavigate('PREV')}
          >
            <ChevronLeft className="navigate-icon" />
          </Button>

          <span>{label.toUpperCase()}</span>

          <Button
            className="toolbar-navigation-button"
            type="button"
            onClick={() => onNavigate('NEXT')}
          >
            <ChevronRight className="navigate-icon" />
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default CalendarListToolbar;

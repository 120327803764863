import React, { useState } from 'react';

// Redux
import { connect, useDispatch } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import moment from 'moment';

// Material helpers
import {
  Dialog,
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  Tooltip,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import styles from './styles.module.scss';
import { VisitDetailsForm, VisitTimeStepperFields } from './components';
import StatusBtn from './components/button/StatusBtn';
import { VisitTimeAdjustmentModal } from 'pages/Dashboard/components';

import {
  Close as CloseIcon,
  Check as CheckIcon,
  HighlightOff as HighlightOffIcon,
} from '@material-ui/icons';

import trashIcon from 'assets/images/icons/u_trash.svg';
import editIcon from 'assets/images/icons/edit.svg';
import lateIcon from 'assets/images/icons/late.png';

import {
  STATUSES,
  STATUS_TO_ACTION,
  PROGRESS_TIMESTAMP_ADJUSTMENT,
} from 'common/constant';
import { deleteVisit, updateVisitByAction } from 'redux/visit';

import { showErrorMessage } from 'lib/notifier';
import { getDateTimeFormat } from 'lib/formatter';
import { useCheckJobReviewed } from '../ConfirmReviewModal/hook';

const FORM_NAME = 'visitDetailsForm';
const STATUES_HIDE_BTN = [STATUSES.COMPLETED, STATUSES.CANCELLED];
const SHOW_EDIT_BTN_ROLES = ['OWNER', 'ADMIN'];

const prepareFormValue = (data, translate) => {
  const {
    publicId,
    title,
    createdAt,
    start,
    end,
    job,
    team,
    description,
    statusId,
    onTheWayAt,
    arrivedAt,
    startedAt,
    completedAt,
    cancelledAt,
  } = data;
  const property = (job && job.property) || {};

  return {
    publicId,
    title,
    createdAt:
      createdAt && moment(createdAt).format(getDateTimeFormat()),
    startDateTime:
      start && moment(start).format(getDateTimeFormat()),
    endDateTime: end && moment(end).format(getDateTimeFormat()),
    address: [property.name, property.unitNumber, property.address]
      .filter(Boolean)
      .join(', '),
    team,
    description,
    statusId,
    ontheway:
      onTheWayAt && moment(onTheWayAt).format(getDateTimeFormat()),
    arrived:
      arrivedAt && moment(arrivedAt).format(getDateTimeFormat()),
    start:
      startedAt && moment(startedAt).format(getDateTimeFormat()),
    completed:
      completedAt &&
      moment(completedAt).format(getDateTimeFormat()),
    cancelled:
      cancelledAt &&
      moment(cancelledAt).format(getDateTimeFormat()),
  };
};

let VisitDetailsModal = (props) => {
  const {
    showJobDetails,
    handleCloseVisitDetails,
    callbackAfterMarkJobComplete,
    callbackAfterDeleteVisit,
    translate,
    handleOpenEditModal,
    visitId,
    visitDetails,
    setSelectedVisitDetails,
    userRole,
    callbackUpdateStatusId
  } = props;
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isOpenEditVisitTimeModal, setIsOpenEditVisitTimeModal] = useState(
    false
  );

  const updateFormValues = ({ data = {} }) => {
    const {
      statusId,
      ontheway,
      arrived,
      start,
      completed,
      cancelled,
    } = prepareFormValue(data, translate);
    dispatch(change(FORM_NAME, 'statusId', statusId));
    dispatch(change(FORM_NAME, 'ontheway', ontheway));
    dispatch(change(FORM_NAME, 'arrived', arrived));
    dispatch(change(FORM_NAME, 'start', start));
    dispatch(change(FORM_NAME, 'completed', completed));
    dispatch(change(FORM_NAME, 'cancelled', cancelled));
  };

  const handleUpdateStatus = async (status) => {
    const action = STATUS_TO_ACTION[status];
    const result = await dispatch(
      updateVisitByAction({ visitId, action })
    ).catch((e) => ({ e }));

    if (result.e) return showErrorMessage(result.e);

    updateFormValues(result);

    callbackAfterMarkJobComplete && callbackAfterMarkJobComplete(result);
  };

  const handleCheckPrevPhaseTime = (newStatus) =>{
    const { onTheWayAt, arrivedAt, startedAt } = visitDetails;
    const currentTime = moment();
    const prevPhaseTime = startedAt || arrivedAt || onTheWayAt;
    if(currentTime.isAfter(moment(prevPhaseTime))) {
      handleUpdateStatus && handleUpdateStatus(newStatus);
    }
    else{
      showErrorMessage(translate('Job:incorrectPrevTime'));
    }
  };


  const handleConfirmedDelete = async () => {
    setShowConfirmModal(false);
    const result = await dispatch(deleteVisit(visitId)).catch((e) => ({ e }));

    if (result.e) return showErrorMessage(result.e);
    callbackAfterDeleteVisit && callbackAfterDeleteVisit(result);
  };

  const updateFormValue = (data) => {
    const { onTheWayAt, arrivedAt, startedAt, completedAt, cancelledAt, statusId } = data;

    const newData = {
      ...visitDetails,
      onTheWayAt,
      arrivedAt,
      startedAt,
      completedAt,
      cancelledAt,
      statusId
    };

    setSelectedVisitDetails && setSelectedVisitDetails(newData);
  };

  const handleSubmit = async (value) => {
    const steps = ['ontheway', 'arrived', 'start', 'completed', 'cancelled'];
    const dateList = steps.map((step) => {
      const visitDate = value[`${step}_visitDate`];
      const visitTime = value[`${step}_visitTime`];
      if (visitDate && visitTime) {
        const fullDatetime =
          moment(visitDate).format('MM/DD/YYYY ') +
          moment(visitTime).format('HH:mm');
        return { key: step, value: fullDatetime };
      }
      return true;
    });
    const check = dateList.find((dateTime)=> moment().isBefore(moment(dateTime.value)));
    const originalList = dateList.filter((item) => {
      return item.key;
    });
    if (!originalList.length)
      return showErrorMessage(translate('Timesheet:noTimeValid'));
    const sortedList = [...originalList].sort(
      (a, b) => moment(a.value) - moment(b.value)
    );
    if (JSON.stringify(originalList) !== JSON.stringify(sortedList) || check) {
      return showErrorMessage(translate('Timesheet:timeNotCorrect'));
    }
    let payload = {
      visitId: visitDetails.id,
      action: PROGRESS_TIMESTAMP_ADJUSTMENT,
    };
    const statusToPayload = {
      ontheway: 'onTheWayAt',
      arrived: 'arrivedAt',
      start: 'startedAt',
      completed: 'completedAt',
      cancelled: 'cancelledAt',
    };
    originalList.forEach((item) => {
      payload = { ...payload, [statusToPayload[item.key]]: moment(item.value) };
    });

    const result = await dispatch(updateVisitByAction(payload)).catch((e) => ({
      e,
    }));

    if (result.e) return showErrorMessage(result.e);

    setIsOpenEditVisitTimeModal(false);
    updateFormValue(result.data);
    callbackUpdateStatusId();
  };

  const isShowBtn = SHOW_EDIT_BTN_ROLES.includes(userRole);
  const { handleCheckJobReviewed } = useCheckJobReviewed();

  const onCompleteClick = () => {
    handleCheckJobReviewed(() =>
      handleCheckPrevPhaseTime(STATUSES.COMPLETED)
    );
  };

  const onCancelClick = () => {
    handleCheckJobReviewed(() =>
      handleUpdateStatus(STATUSES.CANCELLED)
    );
  };

  const handleClickDeleteVisit = () => handleCheckJobReviewed(() => setShowConfirmModal(true));

  const onEditButtonClick = () => handleCheckJobReviewed(() => handleOpenEditModal());

  const onChangeStatusClick = (status) => handleCheckJobReviewed(() =>
    handleUpdateStatus(status)
  );

  const onEditProgressTrackerClick = () => handleCheckJobReviewed(() =>setIsOpenEditVisitTimeModal(true));

  return (
    <>
      {isOpenEditVisitTimeModal && (
        <VisitTimeAdjustmentModal
          onSubmit={handleSubmit}
          visitData={visitDetails}
          setShowAddModal={setIsOpenEditVisitTimeModal}
          translate={translate}
          visitDetails={visitDetails}
        />
      )}
      <Dialog
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate('deleteVisit')}
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setShowConfirmModal(false)} color="primary">
            {translate('Common:cancel')}
          </Button>
          <Button onClick={handleConfirmedDelete} color="primary" autoFocus>
            {translate('Common:ok')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{ paper: styles.dialog_wrapper }}
        open={showJobDetails}
        onClose={handleCloseVisitDetails}
      >
        <Box
          pl={2}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className={styles.modal_title}>
            {translate('visitDetails')}
          </Typography>
          <IconButton onClick={handleCloseVisitDetails}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className={styles.border_vertical}>
          <Grid container>
            <Box px={2} py={1} className={styles.border_right}>
              {!STATUES_HIDE_BTN.includes(visitDetails.statusId) && (
                <>
                  <Button
                    onClick={onCompleteClick}
                    className={styles.btn_complete}
                    variant="contained"
                  >
                    <CheckIcon className="mr_5" />
                    {translate('complete')}
                  </Button>
                  <Button
                    onClick={onCancelClick}
                    variant="contained"
                    className={`${styles.btn_cancel} ml_5 mr_5`}
                  >
                    <HighlightOffIcon className="mr_5" />
                    {translate('cancel')}
                  </Button>
                </>
              )}
              <Tooltip title={translate('edit')}>
                <IconButton
                  onClick={onEditButtonClick}
                  className={styles.icon_btn}
                >
                  <img src={editIcon} alt="editIcon" />
                </IconButton>
              </Tooltip>
              <Tooltip title={translate('delete')}>
                <IconButton
                  onClick={handleClickDeleteVisit}
                  className={styles.icon_btn}
                >
                  <img src={trashIcon} alt="trashIcon" />
                </IconButton>
              </Tooltip>
            </Box>

            <Box px={2} py={1} display="flex" flexWrap="noWrap">
              {visitDetails && visitDetails.isLateVisit && (
                <Grid container alignItems="center" className="mr_5">
                  <Tooltip title={translate('Job:lateVisit')}>
                    <img
                      style={{ minHeight: 24, minWidth: 24 }}
                      src={lateIcon}
                      alt="lateIcon"
                    />
                  </Tooltip>
                  <Typography className={styles.late_visit_text}>{translate('Job:lateVisit')}</Typography>
                </Grid>
              )}
              <Typography>
                <StatusBtn
                  {...props}
                  translate={translate}
                  handleUpdateStatus={onChangeStatusClick}
                />
              </Typography>
            </Box>
          </Grid>
        </Box>

        <Grid container className={styles.content_wrapper}>
          <Grid item md={8} sm={8} className={styles.border_right}>
            <Box p={2}>
              <VisitDetailsForm {...props} translate={translate} />
            </Box>
          </Grid>
          <Grid item sm={4} md={4}>
            <Box p={2}>
              <Typography className={styles.tracker_title}>
                {translate('progressTracker')}
              </Typography>
              {isShowBtn && (
                <Button
                  onClick={onEditProgressTrackerClick}
                  variant="contained"
                  className="mt_10 mb_10"
                  style={{
                    background: '#F0F0F0',
                    borderRadius: 4,
                    boxShadow: 'unset',
                  }}
                >
                  <img src={editIcon} alt="edit_icon" className="mr_5" />
                  <Typography>{translate('Common:edit')}</Typography>
                </Button>
              )}
              <Grid className="mt_5">
                <VisitTimeStepperFields translate={translate} />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { translate } = ownProps;
  const visitDetails = ownProps.visitDetails || {};
  const userRole = (state.auth.currentUser.companies[0] || {}).roleId;

  const data = prepareFormValue(visitDetails, translate);
  return {
    initialValues: {
      ...data,
    },
    userRole,
  };
};

VisitDetailsModal = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(VisitDetailsModal);

export default connect(mapStateToProps, null)(VisitDetailsModal);

import React, { useEffect, useRef, useState } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

// Redux
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, formValueSelector, change, arraySplice } from 'redux-form';
import { setIsSaving } from 'redux/request';

// Material components
import {
  Grid,
  Typography,
  Button,
  Box,
  withStyles,
  ListItem,
  ListItemText,
  List,
} from '@material-ui/core';

// Material icons
import {
  EditOutlined as EditOutlinedIcon,
  Add as AddIcon,
} from '@material-ui/icons';

// Customised components
import {
  ServiceProductCard, // Sophie
  InternalNotesCard,
} from '../cards';
import RequestClientDetailsForm from './RequestClientDetailsForm';
// Shared components
import {
  Portlet,
  PortletContent,
  CategoryModal,
} from 'pages/Dashboard/components';

// Component styles
import { RequestFormStyle } from './styles';
import { OnlyDecimal } from 'components/converters';

const requestForm = formValueSelector('requestForm');
let timer;

const mapStateToProps = (state) => {
  const { request } = state;
  const { selectedClient, selectedProperty } = request || {};
  const { requestSetting } = state.config.configs;
  let requestTitle = (requestSetting && requestSetting.defaultTitle) || '';
  if (selectedClient)
    requestTitle = requestTitle.replace(/{clientName}/gi, selectedClient.name);
  // check whether selectedClient existed
  const disabledBtn = !(
    requestForm(state, 'selectedClient') &&
    requestForm(state, 'categories').length > 0
  );
  return {
    disabledBtn,
    initialValues: {
      publicId: null,
      categories: [],
      selectedClient,
      selectedProperty,
      internalNotes: null,
      title: requestTitle,
      description: null,
      products: [
        {
          name: '',
          description: '',
          quantity: 1,
          unitPrice: '0.00',
          total: '0.00',
          inventories: [
            {
              name: '',
              serialNumber: '',
              model: '',
              quantity: 1,
              total: '0.00',
              unitPrice: '0.00',
              selfCollected: false,
            },
          ],
        },
      ],
    },
    currentCategories: requestForm(state, 'categories') || [],
    requestItems: requestForm(state, 'products'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setIsSaving: () => dispatch(setIsSaving()),
  updateRequestForm: (attribute, value) => {
    dispatch(change('requestForm', attribute, value));
  },
  updateRequestItem: (index, newItem) => {
    dispatch(arraySplice('requestForm', 'products', index, 1, newItem));
  },
  clearTeam: () => {
    dispatch(change('requestForm', 'team', null));
  },
  updateTeam: (team) => {
    dispatch(change('requestForm', 'team', team));
  },
});

let RequestDetails = (props) => {
  const {
    classes,
    className,
    handleCancelRequestForm,
    handleSubmit,
    requestType,
    translate,
    quoteId,
    isEditForm,
    disabledBtn,
    requestItems,
    discountValue,
    discountType,
    updateRequestItem,
    updateRequestForm,
    currentCategories,
  } = props;

  const dispatch = useDispatch();
  const rootClassName = classNames(classes.root, className);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openCategoryModalStaff, setOpenCategoryModalStaff] = useState(false);
  const currentCategoriesStaff = useRef();

  // Sophie
  function updateRequestWhenItemChange() {
    let quoteTaxAmount = 0;
    let discountAmount = 0;
    let totalAmount = 0;
    let discountPercentage = 0;

    const sustotalBeforeDiscount = (requestItems || []).reduce(
      (sum, currentItem) => {
        return (
          sum +
          parseFloat(currentItem.unitPrice) * parseFloat(currentItem.quantity)
        );
      },
      0
    );

    if (!discountValue || parseFloat(discountValue) <= 0) {
      discountAmount = 0;
      discountPercentage = 0;
    } else if (discountType !== '%') {
      discountAmount = parseFloat(discountValue);
      discountPercentage = parseFloat(discountValue) / sustotalBeforeDiscount;
    } else {
      discountPercentage = parseFloat(discountValue);
      discountAmount =
        (parseFloat(discountValue) * sustotalBeforeDiscount) / 100;
    }
    const sustotalAfterDiscount =
      parseFloat(sustotalBeforeDiscount) - parseFloat(discountAmount);

    (requestItems || []).forEach((item, index) => {
      let sumTaxPercentage = 0;
      if (item.taxRate) {
        sumTaxPercentage = parseFloat(item.taxRate.percentage || 0);
      }

      if (item.taxComponent) {
        sumTaxPercentage = (item.taxComponent.taxRates || []).reduce(
          (sum, taxRate) => {
            return sum + parseFloat(taxRate.percentage || 0);
          },
          0
        );
      }
      const itemInitalTotal =
        parseFloat(item.quantity) * parseFloat(item.unitPrice);
      const itemDiscount =
        (parseFloat(itemInitalTotal) * discountPercentage) / 100;
      const itemTotalAfterDiscount = parseFloat(itemInitalTotal) - itemDiscount;
      const itemTaxAmount =
        (itemTotalAfterDiscount * parseFloat(sumTaxPercentage)) / 100;

      const totalIncludeTax = OnlyDecimal(
        itemTotalAfterDiscount + itemTaxAmount
      );

      const inventories = (item.inventories || []).map((inventory) => {
        if (!inventory.total || !inventory.unitPrice)
          return {
            ...inventory,
            total: '0.00',
          };
        return {
          ...inventory,
          total: OnlyDecimal(
            inventory.quantity * parseFloat(inventory.unitPrice)
          ),
        };
      });

      const calculatedSubtotal = OnlyDecimal(
        parseFloat(
          inventories.reduce((prev, current) => {
            return prev + parseFloat(current.total);
          }, 0)
        ) + parseFloat(totalIncludeTax)
      );

      const newItem = {
        ...item,
        inventories,
        total: OnlyDecimal(itemTotalAfterDiscount),
        tax: itemTaxAmount,
        totalIncludeTax,
        calculatedSubtotal,
      };

      updateRequestItem(index, newItem);
      quoteTaxAmount += itemTaxAmount;
    });

    totalAmount =
      parseFloat(sustotalAfterDiscount) + parseFloat(quoteTaxAmount);
    updateRequestForm('subtotal', OnlyDecimal(sustotalAfterDiscount));
    updateRequestForm('discount', OnlyDecimal(discountAmount));
    updateRequestForm('tax', OnlyDecimal(quoteTaxAmount));
    updateRequestForm('total', OnlyDecimal(totalAmount));
  }

  const handleCloseSelectCategoryModal = (_e) => {
    setOpenCategoryModal(false);
  };

  const handleCloseSelectCategoryModalStaff = (_e) => {
    setOpenCategoryModalStaff(false);
  };

  const handleCategorySelection = (data) => {
    dispatch(change('requestForm', 'categories', data));
    setOpenCategoryModal(false);
  };

  const handleCategorySelectionStaff = (data) => {
    dispatch(change('newStaff', 'categories', data));
    currentCategoriesStaff.current = data;
    setOpenCategoryModalStaff(false);
    if (Array.isArray(data) && data.length) {
      const categoryIds = data.map((category) => category.id);
      dispatch(change('newStaff', 'categoryIds', categoryIds));
    }
  };

  // Sophie
  useEffect(() => {
    updateRequestWhenItemChange();
  }, [discountValue, requestItems, discountType]);

  const handleOpenModalCategory = () => setOpenCategoryModalStaff(true);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          clearTimeout(timer);
          timer = setTimeout(() => handleSubmit(), 500);
        }}
      >
        <Box
          mb={3}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid container>
            <Typography variant='h5'>{translate('Common:backTo')}</Typography>
            <Link to='/enterprise-requests'>
              <Typography variant='h5' className='ml_5'>
                {translate('requests')}
              </Typography>
            </Link>
          </Grid>

          <Grid container justify='flex-end'>
            <Button onClick={handleCancelRequestForm}>
              {translate('Common:cancel')}
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={disabledBtn}
              className={classes.save_btn}
            >
              {translate('Common:save')}
            </Button>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12}>
            <Portlet className={rootClassName} requestLabel>
              <PortletContent
                noPadding
                style={{ overflow: 'unset', zIndex: 2 }}
              >
                <Grid container>
                  <Grid item xs={6} className={classes.requestClientDetail}>
                    <RequestClientDetailsForm
                      fixedClient={!!quoteId}
                      requestType={requestType}
                      translate={translate}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    alignItems='flex-start'
                    className={classes.requestAdditionalSettings}
                  >
                    <Grid
                      item
                      container
                      direction='row'
                      style={{ height: '100%' }}
                    >
                      {/* Request setting here */}
                      <Grid
                        item
                        md={6}
                        xs={12}
                        className={classes.trackingSetting}
                      ></Grid>
                      <Grid
                        item
                        container
                        md={6}
                        xs={12}
                        alignItems='flex-start'
                        alignContent='flex-start'
                      >
                        <Grid
                          item
                          container
                          alignItems='center'
                          justify='space-between'
                          style={{ height: 40 }}
                        >
                          <Typography variant='body1'>
                            {translate('Request:requestCategory')}
                          </Typography>
                          <Button
                            size='small'
                            onClick={(_e) => setOpenCategoryModal(true)}
                            className={classes.save_btn}
                          >
                            {currentCategories.length === 0 && (
                              <>
                                {translate('Common:add')}
                                <AddIcon fontSize='small' />
                              </>
                            )}
                            {currentCategories.length > 0 && (
                              <>
                                {translate('Common:edit')}
                                <EditOutlinedIcon fontSize='small' />
                              </>
                            )}
                          </Button>
                        </Grid>

                        {/* Category list */}
                        {currentCategories.length > 0 && (
                          <Grid item container className={classes.categoryList}>
                            <List dense style={{ width: '100%' }}>
                              {currentCategories.map((category) => {
                                return (
                                  <ListItem
                                    key={category.id}
                                    className={classes.catItem}
                                  >
                                    <ListItemText primary={category.name} />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Grid>
                        )}

                        {/* No category added */}
                        {currentCategories.length === 0 && (
                          <Grid item container className={classes.noCategory}>
                            <Typography variant='body1'>
                              {translate('Category:noCategoryAdded')}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </PortletContent>
            </Portlet>
          </Grid>
          <Grid item md={12} sm={12} style={{ zIndex: 1 }}>
            <Portlet className={rootClassName}>
              <PortletContent noPadding>
                <Grid
                  container
                  className={classes.requestType}
                  direction='row'
                  spacing={2}
                >
                  <Grid item md={12} sm={12}>
                    <Grid
                      container
                      direction='row'
                      spacing={3}
                      alignItems='stretch'
                    >
                      {/* Internal Notes */}
                      <Grid item md={12} sm={12}>
                        <InternalNotesCard translate={translate} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </PortletContent>
            </Portlet>

            {/* Product & Service card - Sophie */}
            <Box mt={3}>
              <ServiceProductCard translate={translate} />
            </Box>
          </Grid>
        </Grid>
      </form>
      {openCategoryModal && (
        <CategoryModal
          open={openCategoryModal}
          handleCloseSelectCategoryModal={handleCloseSelectCategoryModal}
          translate={translate}
          categories={currentCategories}
          handleCategorySelection={handleCategorySelection}
          isEnterprise={true}
        />
      )}
      {openCategoryModalStaff && (
        <CategoryModal
          open={openCategoryModalStaff}
          handleCloseSelectCategoryModal={handleCloseSelectCategoryModalStaff}
          translate={translate}
          categories={currentCategoriesStaff.current}
          handleCategorySelection={handleCategorySelectionStaff}
          isEnterprise={true}
        />
      )}
    </>
  );
};

RequestDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

RequestDetails = reduxForm({ form: 'requestForm', destroyOnUnmount: true })(
  RequestDetails
);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(RequestFormStyle)
)(RequestDetails);

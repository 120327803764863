import React, { useState, createRef, forwardRef } from 'react';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getQuotes, getQuote } from 'redux/quote';

// Material helpers
import QuoteListTableStyles from './QuoteListTableStyles';
import { reduxForm, Field } from 'redux-form';

// Custom component
import { TextInput, DatePicker } from 'components/inputs';

// Material components
import { Grid, withStyles } from '@material-ui/core';

// Material icons
import {
  ArrowUpward,
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ToggleOff,
  Visibility,
  ViewColumn,
} from '@material-ui/icons';

// Shared components
import {
  Portlet,
  PortletContent,
  StatusChip,
} from 'pages/Dashboard/components';
import Loader from 'components/loaders/Loader';
import { showErrorMessage } from 'lib/notifier';
import { formatPrice, getDateTimeFormat } from 'lib/formatter';

const mapStateToProps = (state) => ({
  quoteStatuses: state.config.configs.quoteStatuses,
});

const mapDispatchToProps = (dispatch) => ({
  getQuote: (id) => dispatch(getQuote(id)),
  getQuotes: (params) => dispatch(getQuotes(params)),
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ToggleOff: forwardRef((props, ref) => <ToggleOff {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
};

let QuotesTable = (props) => {
  const {
    classes,
    className,
    getQuote,
    translate,
    quoteStatuses,
  } = props;
  const tableRef = createRef();

  const dueDateOptions = [
    { id: 'ALL', name: translate('Common:all') },
    { id: 'LAST_30_DAYS', name: translate('Common:last30Days') },
    { id: 'THIS_MONTH', name: translate('Common:thisMonth') },
    { id: 'LAST_MONTH', name: translate('Common:lastMonth') },
    { id: 'THIS_YEAR', name: translate('Common:thisYear') },
    { id: 'CUSTOM', name: translate('Common:custom') },
  ];

  const [dueDateFilter, setDueDateFilter] = useState('ALL');
  const [isDueDateCustom, setIsDueDateCustom] = useState(false);
  const [dueDateFrom, setDueDateFrom] = useState(null);
  const [dueDateTo, setDueDateTo] = useState(null);
  const [statusFilter, setStatusFilter] = useState('ALL');
  function getFilterDueDate() {
    const filterDueDayOptions = {};
    const date = new Date();
    let fromDate;
    let toDate;

    switch (dueDateFilter) {
      case 'LAST_30_DAYS':
        date.setDate(date.getDate() - 30);

        filterDueDayOptions['expiredAt[$lt]'] = new Date();
        filterDueDayOptions['expiredAt[$gte]'] = date;
        break;

      case 'THIS_MONTH':
        fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
        toDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);

        filterDueDayOptions['expiredAt[$lt]'] = toDate;
        filterDueDayOptions['expiredAt[$gte]'] = fromDate;
        break;

      case 'LAST_MONTH':
        fromDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        toDate = new Date(date.getFullYear(), date.getMonth(), 1);

        filterDueDayOptions['expiredAt[$lt]'] = toDate;
        filterDueDayOptions['expiredAt[$gte]'] = fromDate;
        break;

      case 'THIS_YEAR':
        fromDate = new Date(date.getFullYear(), 0, 1);
        toDate = new Date(date.getFullYear() + 1, 0, 1);

        filterDueDayOptions['expiredAt[$lt]'] = toDate;
        filterDueDayOptions['expiredAt[$gte]'] = fromDate;
        break;

      case 'CUSTOM':
        if (dueDateTo)
          filterDueDayOptions['expiredAt[$lt]'] = new Date(dueDateTo);
        if (dueDateFrom)
          filterDueDayOptions['expiredAt[$gte]'] = new Date(dueDateFrom);
        break;

      default:
        break;
    }

    return filterDueDayOptions;
  }

  const handleRefreshListQuote = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handleChangeDueDay = (event) => {
    const filterValue = event.target.value;
    setDueDateFilter(filterValue);

    if (filterValue !== 'CUSTOM') {
      setIsDueDateCustom(false);
      handleRefreshListQuote();
    } else {
      setIsDueDateCustom(true);
    }
  };

  const changeDueDateFrom = (date) => {
    if (date && date.isValid()) {
      setDueDateFrom(date);
      handleRefreshListQuote();
    }
  };
  const changeDueDateTo = (date) => {
    if (date && date.isValid()) {
      setDueDateTo(date);
      handleRefreshListQuote();
    }
  };

  const changeStatusFilter = (event) => {
    setStatusFilter(event.target.value);
    handleRefreshListQuote();
  };

  const queryToFetchQuotes = (query) => {
    const { getQuotes } = props;
    return new Promise((resolve, reject) => {
      let { pageSize, page, orderBy, orderDirection, search } = query;

      const sortParams = {};

      if (orderBy && orderDirection) {
        orderDirection = orderDirection === 'asc' ? 1 : -1;
        sortParams[`$sort[${orderBy.field}]`] = orderDirection;
      }

      const queryParams = {
        $limit: pageSize,
        $skip: pageSize * page,
        $q: search,
      };

      if (statusFilter !== 'ALL') {
        queryParams['status'] = statusFilter;
      }

      const dueDateOptions = getFilterDueDate();
      Object.assign(queryParams, dueDateOptions, sortParams);

      getQuotes({
        params: queryParams,
      }).then(
        (response) => {
          resolve({
            data: response.data.data,
            page: query.page,
            totalCount: parseInt(response.data.total),
          });
        },
        (error) => {
          showErrorMessage(error);
          reject(error);
        }
      );
    });
  };

  const rootClassName = classNames(classes.root, className);

  const cellStyle = {
    verticalAlign: 'top',
    minWidth: 150,
  };

  const options = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    actionsColumnIndex: -1,
    headerStyle: cellStyle,
    sorting: true,
    debounceInterval: 1000,
    search: true,
    searchFieldAlignment: 'right',
    draggable: false
  };

  const columns = [
    {
      field: 'status',
      title: translate('status'),
      cellStyle,
      sorting: true,
      filtering: false,
      render: (rowData) => {
        return (
          <StatusChip
            quote
            status={rowData.status}
            archived={rowData.archived}
          />
        );
      },
    },
    {
      field: 'id',
      title: translate('Common:number'),
      cellStyle,
      sorting: true,
      filtering: false,
      render: (rowData) => {
        return <span>{rowData.id}</span>;
      },
    },
    {
      field: 'createdAt',
      title: translate('createdOn'),
      cellStyle,
      sorting: true,
      filtering: false,
      render: (rowData) => {
        const createdAt =
          (rowData.createdAt &&
            moment(rowData.createdAt).format(getDateTimeFormat())) ||
          'N/A';
        return <span>{createdAt}</span>;
      },
    },
    {
      field: 'client',
      title: translate('Common:client'),
      cellStyle,
      sorting: true,
      filtering: false,
      render: (rowData) => (
        <span>
          {rowData.client.useCompanyName
            ? rowData.client.companyName
            : [rowData.client.firstName, rowData.client.lastName]
                .filter(Boolean)
                .join(' ')}
        </span>
      ),
    },
    {
      field: 'jobTitle',
      title: translate('quoteTitle'),
      cellStyle,
      sorting: true,
      filtering: false,
      render: (rowData) => {
        return <span>{rowData.jobTitle}</span>;
      },
    },
    {
      field: 'totalFinal',
      title: translate('Quote:quotePrice'),
      cellStyle,
      sorting: false,
      filtering: false,
      render: (rowData) => {
        return <span>{formatPrice(rowData.totalFinal, rowData.currency)}</span>;
      },
    },
    {
      field: 'issuedBy',
      title: translate('createdBy'),
      cellStyle: { ...cellStyle, minWidth: 150 },
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <>
          {rowData.issuedBy
            ? `${rowData.issuedBy.firstName} ${rowData.issuedBy.lastName}`
            : ''}
        </>
      ),
    },
  ];

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <MaterialTable
          title={null}
          tableRef={tableRef}
          columns={columns}
          options={options}
          icons={tableIcons}
          data={(query) => queryToFetchQuotes(query)}
          onRowClick={(event, rowData) => {
            getQuote(rowData.id).then(
              (response) => {
                if (response.status === 200) {
                  window.open(`/quotes/${rowData.id}`, '_blank');
                }
              },
              (error) => {
                showErrorMessage(error);
              }
            );
          }}
          components={{
            OverlayLoading: (props) => <Loader height={50} width={50} />,
            Toolbar: (props) => (
              <>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-end"
                  className={classes.quoteTableToolbar}
                >
                  <Grid
                    item
                    sm={8}
                    spacing={1}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item sm={3} md={3}>
                      <div className={classes.filterDropdownWrap}>
                        <Field
                          name="statusId"
                          component={TextInput}
                          variant="outlined"
                          select
                          label={translate('Quote:status')}
                          SelectProps={{ native: true }}
                          InputLabelProps={{ shrink: true }}
                          onChange={changeStatusFilter}
                          className={classes.filterDropdown}
                          margin="dense"
                        >
                          <option value="ALL">{translate('Common:all')}</option>
                          {quoteStatuses &&
                            Object.keys(quoteStatuses).length > 0 &&
                            quoteStatuses.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                        </Field>
                      </div>
                    </Grid>
                    <Grid item sm={3} md={3}>
                      <div className={classes.filterDropdownWrap}>
                        <Field
                          name="dateId"
                          component={TextInput}
                          variant="outlined"
                          label={translate('Quote:dueDate')}
                          select
                          SelectProps={{ native: true }}
                          InputLabelProps={{ shrink: true }}
                          onChange={handleChangeDueDay}
                          className={classes.filterDropdown}
                          margin="dense"
                        >
                          {dueDateOptions &&
                            Object.keys(dueDateOptions).length > 0 &&
                            dueDateOptions.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                        </Field>
                      </div>
                    </Grid>
                    {isDueDateCustom ? (
                      <>
                        <Grid item item sm={3} md={3}>
                          <Field
                            style={{ width: '100%' }}
                            name="dueDateFrom"
                            component={DatePicker}
                            label={translate('startDate')}
                            onChange={(event, date) => changeDueDateFrom(date)}
                          />
                        </Grid>
                        <Grid item item sm={3} md={3}>
                          <Field
                            name="dueDateTo"
                            style={{ width: '100%' }}
                            component={DatePicker}
                            label={translate('endDate')}
                            onChange={(event, date) => changeDueDateTo(date)}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                  <Grid
                    xs={4}
                    item
                    style={{ minHeight: 'unset' }}
                    container
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <MTableToolbar {...props} />
                  </Grid>
                </Grid>
              </>
            ),
          }}
          localization={{
            toolbar: {
              searchTooltip: translate('Common:search'),
              searchPlaceholder: translate('Common:search'),
            },
            pagination: {
              labelRowsSelect: translate('Common:rows'),
              labelDisplayedRows: ` {from}-{to} ${translate(
                'Common:of'
              )} {count}`,
              firstTooltip: translate('Common:firstPage'),
              previousTooltip: translate('Common:previousPage'),
              nextTooltip: translate('Common:nextPage'),
              lastTooltip: translate('Common:lastPage'),
            },
            header: {
              actions: translate('Common:actions'),
            },
            body: {
              emptyDataSourceMessage: translate('noQuoteMatches'),
            },
          }}
        />
      </PortletContent>
    </Portlet>
  );
};

QuotesTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

QuotesTable = reduxForm({
  form: 'QuotesTable',
})(QuotesTable);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(QuoteListTableStyles)
)(QuotesTable);

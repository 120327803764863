import React, { Component } from 'react';

// Redux
import { Field, FieldArray } from 'redux-form';
// Material components
import { Button, Grid, IconButton } from '@material-ui/core';
import {
  DeleteRounded as DeleteIcon,
  Add
} from '@material-ui/icons';

// Customised components
import { TextInput, Checkbox, } from 'components/inputs';

//  import { required } from '../ClientDetails/validate';

// Component styles
import Countries from 'lib/countryList';
import './index.scss';

const countryPhoneCodeOptions = new Countries().countryPhoneCodes();

const emailObject = {
  typeId: 'WORK',
  email: null
};
const phoneObject = {
  typeId: 'WORK',
  phoneNumber: null,
  countryPhoneCode: null
};

const titleOptions = [
  { id: 'mr.', name: 'Mr.' },
  { id: 'ms.', name: 'Ms.' },
  { id: 'mrs.', name: 'Mrs.' },
  { id: 'miss.', name: 'Miss.' },
  { id: 'dr.', name: 'Dr.' }
];

const renderInputSelectionGroup = props => {
  const { fields, fieldType, typeOptions, translate } = props;

  function appendField() {
    if (fieldType === 'email') {
      fields.push(emailObject);
    } else if (fieldType === 'phone') {
      // TODO: Will update it later by using current company. Waiting for WM-904
      const { countryCode } = require('services/store').store.getState().auth.currentUser.companies[0];
      fields.push({ ...phoneObject, countryPhoneCode: new Countries().getCountryPhoneCodeByCountryCode(countryCode) });
    }
  }

  function handleDelete(index) {
    // Remove element
    fields.remove(index);
  }

  return (
    <div className="input-selection-group">
      {fields.map((field, index) => {
        return (
          <Grid item key={index} className="item-groups">
            {fieldType === 'phone' ?
              <PhoneGroupInput
                fieldName={field}
                index={index}
                deleteButton={index !== 0}
                handleDelete={() => handleDelete(index)}
                typeOptions={typeOptions}
                translate={translate}
              />
              :
              <EmailGroupInput
                fieldName={field}
                index={index}
                deleteButton={index !== 0}
                handleDelete={() => handleDelete(index)}
                typeOptions={typeOptions}
                translate={translate}
              />
            }
          </Grid>
        );
      }
      )}
      <Button
        className="add-btn"
        onClick={appendField}
      >
        <Add />
        {fieldType === 'phone' ? translate('Common:addPhoneNumber') : translate('Common:addEmail')}
      </Button>
    </div>
  );
};


const EmailGroupInput = props => {
  const {
    fieldName,
    deleteButton = false,
    handleDelete,
    typeOptions,
    index,
    translate,
  } = props;

  const emailTypeOptions =
    index === 0 ?
      typeOptions.filter(item => item.id === 'MAIN')
      :
      typeOptions.filter(item => item.id !== 'MAIN');


  return (
    <Grid container direction='row' spacing={1} className="email-group">

      <Grid item md={3}>
        <Field
          component={TextInput}
          margin="dense"
          name={`${fieldName}.typeId`}
          // required
          select
          SelectProps={{ native: true }}
          variant="outlined"
          label={translate('Common:type')}
        >
          {
            emailTypeOptions && emailTypeOptions.length > 0 ?
              emailTypeOptions.map(option => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))
              :
              <option key='' value='' />
          }
        </Field>
      </Grid>
      <Grid item md={deleteButton ? 8 : 9}>
        <Field
          name={`${fieldName}.email`}
          component={TextInput}
          label={translate('Common:email')}
          margin="dense"
          // required
          type={translate('Common:email')}
          variant="outlined"
        />
      </Grid>
      {
        deleteButton &&
        <Grid item md={deleteButton ? 1 : null}>
          <IconButton aria-label='Delete' onClick={handleDelete} className="delete-btn">
            <DeleteIcon style={{ width: 30, height: 30, color: 'red' }} />
          </IconButton>
        </Grid>
      }
    </Grid>
  );
};

const PhoneGroupInput = props => {
  const {
    fieldName,
    index,
    deleteButton = false,
    handleDelete,
    typeOptions,
    translate
  } = props;

  const phoneTypeOptions =
    index === 0 ?
      typeOptions.filter(item => item.id === 'MAIN')
      :
      typeOptions.filter(item => item.id !== 'MAIN');

  return (
    <Grid className="phone-group" container direction='row' spacing={1}>
      <Grid item xs={6} md={4}>
        <Field
          component={TextInput}
          margin="dense"
          name={`${fieldName}.countryPhoneCode`}
          required
          select
          SelectProps={{ native: true }}
          variant="outlined"
          className='field'
          label={translate('Common:countryCode')}
        >
          <option key='' value='' disabled />
          {
            countryPhoneCodeOptions && countryPhoneCodeOptions.length > 0 &&
            countryPhoneCodeOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))
          }
        </Field>
      </Grid>
      <Grid item xs={6} md={8}>
        <Field
          name={`${fieldName}.phoneNumber`}
          component={TextInput}
          label={translate('Common:phone')}
          margin="dense"
          required
          type='number'
          variant="outlined"
          className='field'
          onKeyDown={e => /[+\-.,eE]$/.test(e.key) && e.preventDefault()}
        />
      </Grid>
      <Grid item xs={5} md={4}>
        <Field
          component={TextInput}
          margin="dense"
          name={`${fieldName}.typeId`}
          required
          select
          SelectProps={{ native: true }}
          variant="outlined"
          className='field'
          label={translate('Common:type')}
        >
          {
            phoneTypeOptions && phoneTypeOptions.length > 0 ?

              phoneTypeOptions.map(option => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))
              :
              <option key='' value='' />
          }
        </Field>
      </Grid>
      <Grid item xs={deleteButton ? 6 : 7} md={deleteButton ? 7 : 8}>
        <Field
          name={`${fieldName}.canReceiveSms`}
          component={Checkbox}
          label={translate('Common:receiveSms')}
        />
      </Grid>

      {
        deleteButton &&
        <Grid item md={1}>
          <IconButton aria-label='Delete' onClick={handleDelete}>
            <DeleteIcon style={{ width: 30, height: 30, color: 'red' }} />
          </IconButton>
        </Grid>
      }
    </Grid>

  );
};

class ClientDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledCheckbox: props.disablePrimaryNameCheckbox
    };
    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
  }

  handleCompanyNameChange(e) {
    if (e.target.value.length > 0) {
      this.setState({ disabledCheckbox: false });
    } else {
      this.setState({ disabledCheckbox: true });
      this.props.resetUsingCompanyName();
    }

  }

  render() {
    const { classes, translate, clientTitle } = this.props;

    return (
      <div
        className={classes.section}
      >
        <Grid item container direction='row' spacing={0}>
          <Grid item xs={6} lg={2} md={3} sm={3}>
            <Field
              name='title'
              component={TextInput}
              select
              label={translate('Common:title')}
              margin="dense"
              variant="outlined"
              SelectProps={{ native: true }}
            >
              <option key='' value='' />
              {/* This is to solve title from importing client from csv which title is not correct formatting as from website. */}
              {clientTitle && !titleOptions.find(item => item.id === clientTitle) &&
                <option key={clientTitle} value={clientTitle}>{clientTitle}</option>
              }
              {titleOptions && titleOptions.length &&
                titleOptions.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))
              }
            </Field>
          </Grid>
          <Grid item xs={6} lg={5} md={4} sm={4}>
            <Field
              name='firstName'
              component={TextInput}
              label={translate('Common:firstName')}
              margin="dense"
              required
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} lg={5} md={5} sm={5}>
            <Field
              name='lastName'
              component={TextInput}
              label={translate('Common:lastName')}
              margin="dense"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Field
            name='companyName'
            component={TextInput}
            label={translate('Common:companyName')}
            margin="dense"
            variant="outlined"
            helperText={translate('Common:companyNameOptional')}
            onChange={event => this.handleCompanyNameChange(event)}
          />
          <Field
            name='useCompanyName'
            component={Checkbox}
            label={translate('Common:companyNamePrimary')}
            disabled={this.state.disabledCheckbox}
          />
        </Grid>
        <FieldArray name='phoneNumbers' fieldType='phone' typeOptions={this.props.phoneTypes} component={renderInputSelectionGroup} translate={translate} />

        <FieldArray name='emails' fieldType='email' typeOptions={this.props.emailTypes} component={renderInputSelectionGroup} translate={translate} />


      </div>
    );
  }
}

export default ClientDetailsForm;
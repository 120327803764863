export default theme => ({
  root: {},
  tableRow: {
    height: '64px'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },

  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    cursor: 'pointer'
  },
  includeArchiveCheckbox: {
    position: 'absolute',
    left: 20,
    top: 15
  },
  chip: {
    margin: theme.spacing(0),
  },
  capitalize: {
    textTransform: 'capitalize'
  }
});

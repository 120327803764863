export default theme => ({
  root: {},
  title: {
    fontWeight: 600,
    marginBottom: 10,
  },
  textDetails: {
    color: theme.palette.text.secondary,
  },
  noContacts: {
    fontSize: 11,
    color: theme.palette.text.grey700,
  }
});

import React, { useEffect, useState } from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { getStaffs } from 'redux/staff';
import { showErrorMessage } from 'lib/notifier';
// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Grow,
  IconButton,
  Typography,
} from '@material-ui/core';

// Material icons
import { Close as CloseIcon } from '@material-ui/icons';

// Customised components
import { Checkbox } from 'components/inputs';

// Component styles
import SelectTeamModalStyle from './styles';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getStaffsDispatch: (params) => dispatch(getStaffs(params)),
});

function SelectTeamModal(props) {
  const {
    classes,
    handleClearTeam,
    open,
    finishTeamSelection,
    handleCloseSelectTeamModal,
    handleCreateUser,
    translate,
    staffs,
    getStaffsDispatch,
  } = props;
  const [renderStaff, setRenderStaffs] = useState([]);
  const [staffList, setStaffList] = useState([]);

  useEffect(() => {
    // no need to fetch, comp before fetch already (except calendar screen)
    if (!props.isFromCalendar) return setStaffList(staffs);

    // get staff list once open, in case calendar
    const fetchStaff = async () => {
      const result = await getStaffsDispatch({ $scope: 'JOB_MANAGEMENT|ASSIGN_JOB' }).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
      setStaffList(result.data.data);
    };
    fetchStaff();
  }, []);

  useEffect(() => {
    function parsedStaff() {
      if (!staffList) {
        return;
      }

      let data = staffList && staffList.length;
      const copy = staffList.map((staff) => staff);
      const arrangedStaffs = [];

      while (data > 0) {
        const a = copy.splice(0, 4);
        arrangedStaffs.push(a);
        data -= a.length;
      }

      setRenderStaffs(arrangedStaffs);
    }

    parsedStaff();
  }, [staffList]);

  return (
    <Dialog
      maxWidth="lg"
      aria-labelledby="customized-dialog-title"
      open={open}
      transition={Grow}
    >
      <DialogTitle disableTypography className={classes.teamDialogTitle}>
        <Typography variant="h6" className={classes.teamDialogTitleText}>
          {translate('Job:selectTeam')}
        </Typography>
        {open ? (
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={handleCloseSelectTeamModal}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        {/* TO ADD SEARCH/FILTER STAFF FUNCTION */}
        {renderStaff && renderStaff.length > 0 ? (
          <Grid container direction="column">
            {renderStaff.map((columns, index) => {
              return (
                <Grid
                  key={index + 1}
                  item
                  container
                  direction='row'
                  md={12}
                >
                  {columns.map((staff) => {
                    return (
                      <Grid md={3} key={staff.id}>
                        <Field
                          color="default"
                          className={classes.teamCheckbox}
                          key={staff.id}
                          name={`team.${staff.id}`}
                          value={staff.id}
                          options={staffList}
                          component={Checkbox}
                          label={staff.fullName}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography className={classes.noneAssignText}>
            {translate('Job:noStaffAddUser')}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.teamDialogAction}>
        <Grid item>
          <Button
            color="primary"
            onClick={() => {
              handleCreateUser();
            }}
          >
            {translate('Job:createUser')}
          </Button>
          <Button color="primary" onClick={handleClearTeam}>
            {translate('Job:clearTeam')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={finishTeamSelection}
          >
            {translate('Job:done')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(SelectTeamModalStyle)
)(SelectTeamModal);

import React, { useEffect } from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, change } from 'redux-form';
import { getProducts } from 'redux/product';

// Material helpers
import { withStyles } from '@material-ui/core';

// Shared components
import { ProductInputsForm } from '../forms';

// Component styles
import { ServiceProductCardStyle } from './styles';

const mapStateToProps = (state) => ({
  currencyCode: state.auth.currentUser.companies[0].currency,
  products: state.product.products || [],
  services: state.product.services || [],
  selectedProducts:
    (state.form.requestForm &&
      state.form.requestForm.values &&
      state.form.requestForm.values.products) ||
    [],
});

const mapDispatchToProps = (dispatch) => ({
  getProductsDispatch: (type) => {
    dispatch(getProducts(type));
  },
  confirmTeamForm: (team) => {
    dispatch(change('requestForm', 'team', team));
  },
  clearTeam: () => {
    dispatch(change('requestForm', 'team', null));
  },
});

function ServiceProductCard(props) {
  const {
    getProductsDispatch,
    currencyCode,
    translate,
  } = props;
  useEffect(() => {
    getProductsDispatch('products');
    getProductsDispatch('services');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FieldArray
      name="products"
      component={ProductInputsForm}
      currencyCode={currencyCode}
      translate={translate}
    />
  );
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(ServiceProductCardStyle)
)(ServiceProductCard);

/* eslint jsx-a11y/label-has-associated-control: 0 */
/* eslint jsx-a11y/label-has-for: 0 */
import React from 'react';
import { CardElement } from 'react-stripe-elements';
import styles from './styles.module.css';

const style = {
  base: {
    color: '#32325d',
    fontFamily: 'DM Sans',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

function CardSection() {
  return (
    <div>
      <label className={styles.label}>
        Card details
      </label>
      <CardElement style={style} hidePostalCode />
    </div>
  );
}

export default CardSection;
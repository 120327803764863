import React from "react";
import { useDispatch } from "react-redux";
import { Button, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { addHub } from "redux/hub";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { submit } from "redux-form";
import { showErrorMessage, showSuccessMessage } from "lib/notifier";

import { Form } from "../components";

import styles from "../styles.module.scss";

const FORM_NAME = "warehouseManagement";

const NewWarehouse = (props) => {
  const {
    history,
    location: {
      state: {
        data: { enterpriseId },
      },
    },
  } = props;

  const dispatch = useDispatch();
  const { t: translate } = useTranslation("Hub");
  const handleBack = () => history.goBack();

  const handleSubmitNewForm = () => dispatch(submit(FORM_NAME));

  const handleSubmit = async (values) => {
    const newValues = {
      ...values,
      enterpriseId,
    };

    const result = await dispatch(addHub(newValues)).catch((e) => ({
      e,
    }));

    if (result.e) return showErrorMessage(result.e);

    showSuccessMessage(translate("createSuccessfully"));
    history.replace(`/hub/${result.id}`, { data: { enterpriseId } });
  };

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={handleBack}>
          <ArrowBackIcon className="mr_5" />
          {translate("Common:back")}
        </Button>

        <Box display="flex">
          <Button variant="contained" className="mr_5" onClick={handleBack}>
            {translate("Common:discard")}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmitNewForm}
            className={styles.btn_save}
          >
            {translate("Common:save")}
          </Button>
        </Box>
      </Box>

      <Form {...props} translate={translate} onSubmit={handleSubmit} />
    </Box>
  );
};

export default NewWarehouse;

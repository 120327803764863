import React, { useState } from 'react';
import moment from 'moment';
import { Menu, Grid, Typography, Box, Card } from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';
import { defaultStaticRanges } from './defaultStatisRange';
import styles from './timerange.module.scss';

import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';

const TimeRange = ({
  t,
  selectedDateRange,
  setSelectedDateRange,
  showDateOnly = false,
  customTextContainer
}) => {
  const handleOnChange = ({ selection }) => {
    const { startDate: start, endDate: end } = selection;
    setSelectedDateRange({
      ...selectedDateRange,
      startDate: new Date(start),
      endDate: new Date(end).setHours(23, 59, 59, 999),
    });
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  if (!selectedDateRange) return null;

  return (
    <>
      {showDateOnly ? (
        <Box display="flex" onClick={handleClick} style={{ cursor: 'pointer' }} className={customTextContainer}>
          <Grid className={styles.tag}>
            {moment(selectedDateRange.startDate).format(t('Job:dateFormat'))} -
            {moment(selectedDateRange.endDate).format(t('Job:dateFormat'))}
          </Grid>
        </Box>
      ) : (
        <Card className={styles.card} onClick={handleClick}>
          <Grid container className={styles.cursor}>
            <Typography variant="body1" className={styles.titleFilter}>
              {t('timeRange')}
            </Typography>
            <KeyboardArrowDownIcon />
          </Grid>
          <Box display="flex" mt={1}>
            <Grid className={styles.tag}>
              {moment(selectedDateRange.startDate).format(t('Job:dateFormat'))}{' '}
              -{moment(selectedDateRange.endDate).format(t('Job:dateFormat'))}
            </Grid>
          </Box>
        </Card>
      )}
      <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <DateRangePicker
          showPreview={false}
          onChange={handleOnChange}
          staticRanges={defaultStaticRanges}
          ranges={[selectedDateRange]}
        />
      </Menu>
    </>
  );
};

export default TimeRange;

import React from 'react';
import { TextInput } from 'components/inputs';
import { Button, FormLabel, Grid, IconButton } from '@material-ui/core';
// Redux
import { Field } from 'redux-form';

import { DeleteRounded as DeleteIcon } from '@material-ui/icons';

const taxNumberObject = {
  name: null,
  number: null
};

const renderTaxNumbers = props => {
  const { styles, fields, translate } = props;

  function addTaxNumber() {
    fields.push(taxNumberObject);
  }

  function handleDeleteTaxNumber(index) {
    fields.remove(index);
  }

  return (
    <Grid item container>
      <Grid item xs={12} sm={12} md={12} className={styles.labelActionHeader}>
        <FormLabel component="legend" className={styles.sectionLabel}>
          {translate('CompanySetting:taxNumbers')}</FormLabel>
        <Button onClick={addTaxNumber} className={styles.addBtn}>{translate('CompanySetting:addTaxID')}</Button>
      </Grid>
      {
        fields.map((field, index) => {
          return (
            <Grid container direction='row' justify='center' alignItems="center" spacing={2} key={index}>
              <Grid item lg={6} md={6} sm={12}>
                <Field
                  inputClass={styles.productName}
                  name={`${field}.name`}
                  component={TextInput}
                  label={translate('CompanySetting:taxID')}
                  variant='outlined'
                  margin='normal'
                  required
                  index={index}
                />

              </Grid>
              <Grid item lg={5} md={5} sm={11}>
                <Field
                  name={`${field}.number`}
                  label={translate('CompanySetting:taxNumber')}
                  component={TextInput}
                  variant='outlined'
                  required
                  margin='normal'
                  type='text'
                />
              </Grid>

              <Grid item lg={1} md={1} sm={1}>
                <IconButton aria-label='Delete' onClick={e => handleDeleteTaxNumber(index)}>
                  <DeleteIcon style={{ width: 30, height: 30, color: 'red' }} />
                </IconButton>
              </Grid>
            </Grid>
          );
        })
      }
    </Grid>
  );
};

export default renderTaxNumbers;

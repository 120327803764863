export default theme => ({
  root: {},
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  jobDetailsTitle: {
    fontWeight: 600,
  },
  chip: {
    fontWeight: 600,
  },
  jobId: {
    color: theme.palette.primary.main
  },
  invoiceChip: {
    backgroundColor: theme.palette.chip.invoice.light,
    color: theme.palette.chip.invoice.dark,
  },
  lateVisit: {
    backgroundColor: theme.palette.chip.lateVisit.dark,
    color: theme.palette.chip.lateVisit.light,
  },
  unscheduled: {
    backgroundColor: theme.palette.chip.unscheduled.dark,
    color: theme.palette.text.primary,
  },
  unassigned: {
    backgroundColor: theme.palette.chip.unassigned.dark,
    color: theme.palette.text.primary,
  },
  onStandby: {
    backgroundColor: theme.palette.chip.onStandby.dark,
    color: theme.palette.text.primary,
  },
  onTheWay: {
    backgroundColor: theme.palette.chip.onTheWay.dark,
    color: theme.palette.text.primary,
  },
  arrived: {
    backgroundColor: theme.palette.chip.arrived.dark,
    color: theme.palette.text.primary,
  },
  started: {
    backgroundColor: theme.palette.chip.started.dark,
    color: theme.palette.text.primary,
  },
  completed: {
    backgroundColor: theme.palette.chip.completed.dark,
    color: theme.palette.text.primary,
  },
  abandoned: {
    backgroundColor: theme.palette.chip.abandoned.dark,
    color: theme.palette.chip.abandoned.light,
  },
  archived: {
    backgroundColor: theme.palette.chip.archived.dark,
    color: theme.palette.chip.archived.light,
  },
  titleColor: {
    color: theme.palette.text.gray
  }
});
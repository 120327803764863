import React from 'react';
import { HubTable } from 'pages/Dashboard/components';

const EntepriseHub = (props) => {
  const {
    t,
    match: { params },
  } = props;
  return (
    <HubTable
      {...props}
      tableTitle={t('Hub:hub')}
      enterpriseId={params.enterpriseId}
      {...props}
    />
  );
};

export default EntepriseHub;

export default {
  // common && crumbs
  staff: "Staff",
  manageTeam: "Manage Team",
  manageTeamDesc:
    "Add or manage users that need to log into WorkPulse in the office or in the field. Dispatch them to job sites or give them access to more WorkPulse features.",
  addStaff: "+ New Staff",
  staffDetails: "Staff Details",
  editStaff: "Edit Staff",
  team: "Team",

  // staff table
  showDeactivated: "Show Deactivated",
  search: "Search",
  picture: "Picture",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phone: "Phone",
  role: "Role",
  actions: "Actions",
  rows: "rows",
  of: "of",
  editUser: "Edit User",
  deactivate: "Deactivate",
  activate: "Activate",
  delete: "Delete",
  reinvite: "Reinvite",

  // staff details
  personalInfo: "Personal Info",
  uploadPicture: "Upload Picture",
  roles: "Roles",
  permissionLevel:
    "What level of permission would you like this worker to have?",
  makeAdmin: "Make administrator",
  adminPermissions:
    "Administrators have full permissions. Make this user a non-admin to adjust permissions.",
  ownerPermissions: "Owner has full permissions.",
  select: "Select",
  assignTo: "Optional - Assign a person to report to",
  reporting: "Assigned to",
  saveDetails: "Save details",
  addNewStaff: "Add staff",
  askConfirmationBeforeDelete: "Do you want to delete this staff?",
  updatedSuccess: "{{staffName}} has been updated successfully.",
  addedSuccess: "{{staffName}} has been added successfully.",

  reinviteSucess: "Reinvite staff successfully.",
  cropImage: "Crop profile picture",
  cropAndSave: "Crop and save",
  crop: "Crop",

  clearFilter: "Clear Filter",
};

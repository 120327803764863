import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enterpriseDetails } from 'redux/company';
import { showErrorMessage } from 'lib/notifier';
import {
  Grid,
  Typography,
  Button,
  Card,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { GeneralInfo, EnterpriseUser, EnterpriseHub,
  EnterpriseProducts, EnterpriseServices } from './components';

import styles from './details.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className={styles.tab_panel}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const tabLabels = [
  'generalInfo',
  'enterpriseUser',
  'warehouse',
  'service',
  'product',
];

const tabContent = [GeneralInfo, EnterpriseUser, EnterpriseHub, EnterpriseServices, EnterpriseProducts];

const EnterpriseAccountDetails = (props) => {
  const {
    history,
    match: { params },
  } = props;
  const dispatch = useDispatch();
  const [companyDetails, setCompanyDetails] = useState({});

  useEffect(() => {
    if (!params.enterpriseId) return;
    (async () => {
      const response = await dispatch(
        enterpriseDetails(params.enterpriseId)
      ).catch((e) => ({ e }));
      if (response.e) return showErrorMessage(response.e);

      setCompanyDetails(response.data);
    })();
  }, [params.enterpriseId]);

  const { t } = useTranslation('Enterprise');
  const [value, setValue] = useState(0);

  const handleClickBack = () => history.push('/enterprise');

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <>
      <Grid style={{ padding: 24 }}>
        {/* header */}
        <Grid container justify="space-between" direction="row" className="mb_15">
          <Grid className={styles.headerWrapper} item>
            <Typography>{t('backTo')}</Typography>
            <Button onClick={handleClickBack} variant="text">
              <Typography variant="h5">{t('enterpriseAccounts')}</Typography>
            </Button>
          </Grid>
        </Grid>

        <AppBar
          className={styles.appBarVisit}
          classes={{ root: styles.app_bar }}
          position="static"
        >
          <Tabs
            variant="fullWidth"
            classes={{ indicator: styles.indicator }}
            value={value}
            onChange={handleChange}
          >
            {tabLabels.map((label) => (
              <Tab
                classes={{
                  root: styles.rootTabs,
                  wrapper: styles.alignLeft,
                  selected: styles.selected,
                }}
                label={t(`Common:${label}`)}
              />
            ))}
          </Tabs>
        </AppBar>
        <Card className={styles.cardWrapper}>
          {tabContent.map((Comp, index) => {
            if (!Comp) return null;
            return (
              <TabPanel value={value} index={index}>
                <Comp {...props} companyDetails={companyDetails} t={t} />
              </TabPanel>
            );
          })}
        </Card>
      </Grid>
    </>
  );
};

export default EnterpriseAccountDetails;

import { EMAIL_VALIDATION } from 'common/constant';

const validate = values => {
  const errors = { message: {} };
  const requiredFields = [
    'firstName',
    'countryPhoneCode',
    'phoneNumber',
    'email',
    'address',
    'city',
    'zipCode',
    'countryCode'
  ];
  requiredFields.forEach(field => {
    if (!(values[field]?.trim())) {
      errors[field] = 'required';
      errors.message[field] = `${field}Required`;
    }
  });
  if (
    values.email &&
    !EMAIL_VALIDATION.test(values.email)
  ) {
    errors.email = 'invalidEmail';
    errors.message.email = 'invalidEmail';
  }
  if (!values.countryPhoneCode || values.countryPhoneCode === '') {
    errors.countryPhoneCode = 'required';
    errors.message.countryPhoneCode = 'countryPhoneCodeRequired';
  }
  if (values.roleId === undefined || values.roleId === null) {
    errors.roleId = 'chooseUserRole';
    errors.message.roleId = 'chooseUserRole';
  }
  return errors;
};

export default validate;
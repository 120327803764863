import React, { useState } from 'react';
import { Grid, Typography, Button, Menu, MenuItem } from '@material-ui/core';
import DocsSrc from 'assets/images/service-report/doc.svg';
import {
  downloadTemplateServiceReport,
} from 'redux/job';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';

const DownloadPopup = ({ styles, translate, dispatch, jobId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const togglePopup = (event) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const handleDownloadTemplate = async (isTemplateWithoutJobId) => {
    const payload = {
      action: 'DOWNLOAD_TEMPLATE',
      jobId,
      isTemplateWithoutJobId
    };
    setAnchorEl(null);
    setIsLoading(true);
    const result = await dispatch(
      downloadTemplateServiceReport(payload)
    ).catch((e) => ({ e }));
    setIsLoading(false);
    if (result.e || (!result.data && !result.data.url))
      return showErrorMessage(result.e);
    showSuccessMessage(translate('downloadSuccessfully'));
    window.location.href = result.data.url;
  };

  return (
    <>
      <Grid
        item
        container
        wrap="nowrap"
        justify="center"
        className={styles.btnDownload}
      >
        {isLoading ? (
          <div className="loader">Loading...</div>
        ) : (
          <Button fullWidth onClick={togglePopup}>
            <img src={DocsSrc} alt="alt_doc" />
            <Grid item className={styles.content}>
              <Typography
                className={`${styles.blueText} font_bold`}
                variant="h5"
              >
                {translate('btnTitle')}
              </Typography>
              <Typography className={styles.blueText} variant="body2">
                {translate('btnDescription')}
              </Typography>
            </Grid>
          </Button>
        )}
      </Grid>
      <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: styles.menuPaper }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleDownloadTemplate(false)}>Blank template with Job ID</MenuItem>
        <MenuItem onClick={() => handleDownloadTemplate(true)}>Blank template only</MenuItem>
      </Menu>
    </>
  );
};
export default DownloadPopup;

import PopoverWithTopArrow from 'common/styles/PopoverWithTopArrow';

export default theme => ({
  root: {},
  clientName: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(3),
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  linkIcon: {
    color: theme.palette.primary.main,
    fontSize: 30,
    marginLeft: theme.spacing(2),
    verticalAlign: 'center',
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  mainActionButton: {
    fontWeight: 600,
  },
  popoverWithArrow: {
    ...PopoverWithTopArrow,
  },
  textColor: {
    color: theme.palette.text.gray,
    fontSize: 16
  },
  jobTitle: {
    fontWeight: 600,
  },
  headerContainer: {
    marginBottom: '20px'
  }
});

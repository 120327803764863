export default theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  backTitle: {},
  backTo: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  capitalize: {
    textTransform: 'capitalize'
  }
});

import React, { useState } from 'react';
import {
  Card,
  IconButton,
  Popover,
  Grid,
  List,
  ListItem,
} from '@material-ui/core';

import styles from '../../card.module.scss';
import moreSrc from 'assets/images/icons/more.svg';

const MorePopover = (props) => {
  const {
    translate,
    setIsOpenConfirmModal,
    setIsOpenConfirmDeleteModal,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleClick = () => {
    setIsOpenConfirmModal(true);
    handlePopoverClose();
  };

  const handleClickDeleteRequest = () => {
    setIsOpenConfirmDeleteModal(true);
    handlePopoverClose();
  };

  return (
    <Grid>
      <IconButton
        onClick={handlePopoverOpen}
        className={`${styles.icon_btn} mr_5`}
      >
        <img src={moreSrc} alt='moreSrc' />
      </IconButton>
      <Popover
        id='mouse-over-popover'
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Card>
          <List>
            <ListItem
              className={styles.border_bottom}
              button
              onClick={handleClick}
            >
              {translate('Common:archive')}
            </ListItem>

            <ListItem
              button
              className={styles.deleteBtn}
              onClick={handleClickDeleteRequest}
            >
              {translate('deleteRequest')}
            </ListItem>
          </List>
        </Card>
      </Popover>
    </Grid>
  );
};

export default MorePopover;

import React, { useMemo } from "react";
import {
  FormControlLabel,
  Checkbox,
  withStyles,
  Button,
  Box,
  Typography,
  SvgIcon,
} from "@material-ui/core";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { change } from "redux-form";
import doubleCheckSrc from "assets/images/double-check.svg";
import { ReactComponent as CheckboxCustom } from "assets/images/icons/indeterminate_check_box.svg";
import { useCategoryChecked } from "../hook";

const styles = () => ({
  labelNoMargin: {
    margin: 0,
  },
  checkboxState: {
    color: "#479ECF !important",
  },
  checkboxStateGray: {
    color: "gray !important",
  },
  selectInsideItemText: {
    color: "#0075FE",
  },
  wrapper: {
    "& button": {
      display: "none",
    },
    "&:hover": {
      "& button": {
        display: "inline-flex",
        "&:hover": {
          background: "#EAEFFE !important",
        },
      },
    },
  },
});

let selectedList = {};

// this component is using for return item object instead of object with true false
export default withStyles(styles)((props) => {
  const {
    input: { onChange, value },
    classes,
    label,
    handleClick,
    labelClassName,
    labelPlacement = "end",
    item,
    key,
    isDisabled = false,
    haveSubCategories = false,
    formName,
    translate,
    selectedCategoryList = [],
    ...rest
  } = props;
  const dispatch = useDispatch();
  const [
    isAllChildrenCategoryChecked,
    isAllChildrenCategoryUnChecked,
  ] = useCategoryChecked(selectedCategoryList, item);
  const handleChange = () =>
    onChange({
      ...item,
      checked: isAllChildrenCategoryChecked || !value.checked,
    });

  const mapTreeCategoriesToCategoryObject = (categoryList, state) => {
    categoryList.forEach((category) => {
      selectedList = {
        ...selectedList,
        [category.id]: {
          ...category,
          checked: state,
        },
      };
      if (
        !Array.isArray(category.childrenCategories) ||
        !category.childrenCategories.length
      )
        return null;
      mapTreeCategoriesToCategoryObject(category.childrenCategories, state);
    });
  };
  useMemo(() => {
    if (isAllChildrenCategoryChecked) {
      onChange({ ...item, checked: true });
    }
  }, [isAllChildrenCategoryChecked]);

  const handleSelectInside = () => {
    // clear value
    selectedList = {};
    // find next state of checkbox base on current value
    let nextCheckedState = true;
    if (props.input.value.checked) nextCheckedState = false;
    mapTreeCategoriesToCategoryObject([item], nextCheckedState);
    dispatch(
      change(formName, "categories", {
        ...selectedCategoryList,
        ...selectedList,
      })
    );
  };
  const getCheckbox = () => {
    if (haveSubCategories) {
      if (isAllChildrenCategoryUnChecked) {
        return (
          <Checkbox
            classes={{
              checked: classNames(classes.checkboxStateGray),
            }}
            checked={!!value.checked}
            onChange={handleChange}
            onClick={handleClick}
            disabled={isDisabled}
            {...rest}
          />
        );
      }
      return (
        <Checkbox
          classes={{
            checked: classNames(
              isAllChildrenCategoryChecked
                ? classes.checkboxState
                : classes.checkboxStateGray
            ),
          }}
          icon={<SvgIcon component={CheckboxCustom} viewBox="-3 -3 24 24" />}
          checked={!!value.checked}
          onChange={handleChange}
          onClick={handleClick}
          disabled={isDisabled}
          {...rest}
        />
      );
    }
    return (
      <Checkbox
        classes={{ checked: classNames(classes.checkboxState) }}
        checked={!!value.checked}
        onChange={handleChange}
        onClick={handleClick}
        disabled={isDisabled}
        {...rest}
      />
    );
  };
  return (
    <FormControlLabel
      className={classes.wrapper}
      control={
        isDisabled ? (
          <></>
        ) : (
          <Box>
            {haveSubCategories && (
              <Button
                onClick={handleSelectInside}
                className={classes.selectInsideBtn}
              >
                <img
                  className={`mr_5 ${classes.selectInsideItemText}`}
                  src={doubleCheckSrc}
                  alt="doubleCheckSrc"
                  color="red"
                />
                <Typography
                  variant="subtitle1"
                  className={classes.selectInsideItemText}
                >
                  {translate("Category:selectDeselect")}
                </Typography>
              </Button>
            )}
            {getCheckbox()}
          </Box>
        )
      }
      label={label}
      labelPlacement={labelPlacement}
      classes={{
        labelPlacementStart: classNames(classes.labelNoMargin, labelClassName),
      }}
    />
  );
});

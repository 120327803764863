import React, { useState, useMemo } from 'react';
import { Box, CircularProgress, Grid, Icon, SvgIcon } from '@material-ui/core';
import { MEDIA_TYPES } from 'common/constant';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import styles from './styles/styles.module.scss';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';

const MediaFileItem = (props) => {
  const { media } = props;
  const [isLoading, setLoading] = useState(true);
  const getMediaAsync = () => {
    if (media.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };
  useMemo(() => {
    getMediaAsync();
  }, [media]);
  if (!media) return null;
  return (
    <Box>
      {
        isLoading ?
          <Box className={styles.itemLoading}>
            <CircularProgress style={{ color: '#479ECF' }} />
          </Box>
          :
          <Box>
            {media.mediaType === MEDIA_TYPES.PHOTO.toString() && (
              <img
                className={styles.imgConfig}
                alt="media_photo"
                src={media.mediaUrl}
              />
            )}
            {media.mediaType === MEDIA_TYPES.FILE.toString() && (
              <>
                <Grid item>
                  <Icon style={{ fontSize: 30, height: '100%' }}>
                    <SvgIcon
                      component={FileIcon}
                    />
                  </Icon>
                </Grid>
                <Grid item>{media.fileName || 'Untitled'}</Grid>
              </>
            )}
            {media.mediaType === MEDIA_TYPES.VIDEO.toString()&& (
              <>
                <Grid
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    background: 'black',
                    opacity: '50%',
                  }}
                />
                <PlayCircleOutlineIcon
                  style={{
                    color: 'white',
                    fontSize: '80',
                    position: 'absolute',
                  }}
                />
                <video
                  height="100%"
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                >
                  <track kind='captions' />
                  <source src={media.mediaUrl} type="video/mp4" />
                </video>
              </>
            )}
          </Box>
      }
    </Box>
  );
};

export default MediaFileItem;
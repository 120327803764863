export default theme => ({
  root: {
    padding: theme.spacing(4)
  },
  formField: {
    margin: 0,
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.background.muted2
  },
  leftContent: {
    backgroundColor: theme.palette.background.muted2
  },
  invoiceInfo: {
    padding: 20
  },
  title: {
    width: '100%',
    fontWeight: 600,
    paddingBottom: 10
  },

  subtitle: {
    width: '100%',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    }
  },

  actionButton: {
    textAlign: 'right',
    justifyContent: 'center',
    paddingTop: 30
  },

  contact: {
    display: 'flex',
    padding: 20
  },

  contactItem: {},

  addressValue: {
    paddingTop: 5
  },

  rightAlign: {
    textAlign: 'right'
  },

  rightContent: {
    padding: 20
  },

  itemGroup: {
    padding: 20
  }
});
import React, { useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addVisit } from 'redux/visit';
import { showErrorMessage } from 'lib/notifier';

// Material helpers
import { Dialog, Typography, Box, IconButton } from '@material-ui/core';
import styles from './styles/card.module.scss';
import { VisitDetailsForm } from 'pages/Dashboard/components';

import { Close as CloseIcon } from '@material-ui/icons';
import { getIsOpenJob } from 'redux/jobVerification';
import { useReviewJobApi } from '../../JobDetails/components/ConfirmReviewModal/hook';
import { CREATE_VISIT_ONLY_STATUS } from '../../JobDetails/components/ConfirmReviewModal/constants';

const NewVisitModal = (props) => {
  const {
    translate,
    isOpen,
    afterSaveVisitDetails,
    setIsOpenEditModal,
    job,
  } = props;

  const dispatch = useDispatch();
  const isOpenJob = useSelector(getIsOpenJob);
  const { openAndCreateVisit, createVisitOnly } = useReviewJobApi();

  const handleCloseEditForm = () => setIsOpenEditModal(false);

  const [isDisable, setIsDisable] = useState(false);

  const handleScheduleLater = event => {
    setIsDisable(event.target.checked);
  };

  const handleAddVisit = (payload) => {
    switch (isOpenJob) {
      case CREATE_VISIT_ONLY_STATUS.OPEN_AND_CREATE_VISIT:
        return openAndCreateVisit({ jobId: job.publicId, dataVisit: payload });
      case CREATE_VISIT_ONLY_STATUS.CREATE_VISIT_ONLY:
        return createVisitOnly({ jobId: job.publicId, visitData: payload });
      default:
        return dispatch(addVisit(payload)).catch((e) => ({ e }));
    }
  };

  const handleSubmit = async (values) => {
    const {
      title,
      startOnDate,
      startOnTime,
      endOnDate,
      endOnTime,
      team,
      description,
      isScheduleLater
    } = values;

    const payload = {
      jobId: job.publicId,
      title,
      description,
      team:
        Array.isArray(team) &&
        team.map((item) => ({
          id: item.id,
        })),
      start:
        startOnDate &&
        startOnTime &&
        moment(startOnDate)
          .hour(moment(startOnTime).hour())
          .minute(moment(startOnTime).minute())
          .toISOString(),
      end:
        endOnDate &&
        endOnTime &&
        moment(endOnDate)
          .hour(moment(endOnTime).hour())
          .minute(moment(endOnTime).minute())
          .toISOString(),
      isSetUnschedule: isScheduleLater,
    };

    const result = await handleAddVisit(payload);
    if (result.e) return showErrorMessage(result.e);

    const { data } = result.data;
    handleCloseEditForm();
    afterSaveVisitDetails && afterSaveVisitDetails(data);
  };

  return (
    <Dialog
      classes={{ paper: styles.dialog_edit_visit }}
      open={isOpen}
      onClose={handleCloseEditForm}
    >
      <Box
        className={styles.border_bottom}
        pl={2}
        py={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={styles.modal_title}>
          {translate('newVisit')}
        </Typography>
        <IconButton onClick={handleCloseEditForm}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box p={2}>
        <VisitDetailsForm
          {...props}
          onSubmit={handleSubmit}
          handleCloseForm={handleCloseEditForm}
          handleScheduleLater={handleScheduleLater}
          isDisable={isDisable}
        />
      </Box>
    </Dialog>
  );
};

export default NewVisitModal;

import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid } from '@material-ui/core';

// Custom components
import { ClientForm } from '../components';

// Redux
import { connect } from 'react-redux';

import { compose } from 'redux';

import { withRouter } from 'react-router-dom';

// Action for client
import { updateClientDetails, clearClient, getClientDetails } from 'redux/client';

// Notify to show message
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

const mapDispatchToProps = dispatch => ({
  clearClient: () => dispatch(clearClient()),
  getClientDetails: (clientId,) => dispatch(getClientDetails(clientId)),
  updateClientDetails: (data) => dispatch(updateClientDetails(data))
});

class UpdateClient extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      clientDetails: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.prepareClientDataBeforeSave = this.prepareClientDataBeforeSave.bind(this);
    this.fetchClientDetails = this.fetchClientDetails.bind(this);
    this.handleCancelClientForm = this.handleCancelClientForm.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchClientDetails();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.clearClient();
  }

  async fetchClientDetails() {
    const { clientId, getClientDetails } = this.props;
    try {
      this.setState({ isLoading: true });
      const response = await getClientDetails(clientId);
      const client = response.data;
      if (client && client.archived) {
        this.props.history.push(`/clients/${client.id}`);
      }
      if (this._isMounted) {
        const billingAddress = client.billingAddress || {};

        const isBillingAddressIndex = client.properties.findIndex((item) => {
          return item.address === billingAddress.address &&
            item.unitNumber === billingAddress.unitNumber &&
            item.city === billingAddress.city &&
            item.countryCode === billingAddress.countryCode &&
            item.state === billingAddress.state &&
            item.zipCode === billingAddress.zipCode;
        });
        if (isBillingAddressIndex !== -1) {
          client.properties[isBillingAddressIndex].isBillingAddress = true;
          client.billingAddress = null;
        }
        this.setState({ clientDetails: client, isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      showErrorMessage(error);
    }
  }

  async handleSubmit(values) {
    const { updateClientDetails, history, translate } = this.props;
    const clientDetails = this.prepareClientDataBeforeSave(values);
    try {
      const response = await updateClientDetails(clientDetails);
      if (response.status === 200) {
        const client = response.data;
        const clientName = client.displayName;
        showSuccessMessage(translate('updatedSuccess', { clientName }));
        history.push(`/clients/${clientDetails.id}`);
      } else {
        showErrorMessage(response.data.message);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  handleCancelClientForm() {
    this.props.history.goBack();
  }

  prepareClientDataBeforeSave(values) {
    const { properties } = values;
    const isBillingAddress = properties.find(item => item.isBillingAddress);
    if (isBillingAddress) {
      // Init billing address base on property details
      const billingAddess = {
        address: isBillingAddress.address,
        unitNumber: isBillingAddress.unitNumber,
        city: isBillingAddress.city,
        zipCode: isBillingAddress.zipCode,
        state: isBillingAddress.state,
        countryCode: isBillingAddress.countryCode,
      };

      Object.assign(values, { billingAddress: billingAddess });
    }

    return values;
  }

  render() {
    const { classes, translate } = this.props;
    const { clientDetails } = this.state;
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            { clientDetails &&
              <ClientForm
                clientDetails={clientDetails}
                onSubmit={values => this.handleSubmit(values)}
                editPage
                handleCancelClientForm={this.handleCancelClientForm}
                translate={translate}
              />}
          </Grid>
        </Grid>
      </div>
    );
  }
}

UpdateClient.propTypes = {
  classes: PropTypes.object.isRequired
};


export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(UpdateClient);

import React from 'react';
import './index.scss';

class Schedules extends React.Component {

  render() {
    return (
      <div className="schedule-section">
        <div className="section-header">
          <div className="title">Schedules</div>
        </div>
        <div className="content">
          <div className="address"> Content for schedules here.</div>
        </div>
      </div>
    );
  }
}

export default Schedules;

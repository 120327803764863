export default {
  dashboard:	'Dashboard',
  calendar:	'ปฏิทิน',
  tracking:	'การติดตาม',
  clients:	'ลูกค้า',
  people:	'ลูกค้า',
  work:	'จัดการงาน',
  jobs:	'งาน',
  invoices:	'ใบแจ้งหนี้/ใบกำกับภาษี',
  quotes: 'ใบเสนอราคาทั้งหมด',
  businessManagement:	'จัดการธุรกิจ',
  team:	'ทีมงาน',
  productsServices:	'สินค้า/บริการ',
  businessSettings:	'ตั้งค่าบริษัท',
  closeSidebar: 'ปิดแถบด้านข้าง',
  showSidebar: 'โชว์แถบด้านข้าง',
  timesheet: 'บันทึกการทำงาน',
  workSettings: 'การตั้งค่าเกี่ยวกับงาน',
  accountSetting: 'การตั้งค่าบัญชี',
  accountManagement: 'การจัดการบัญชี',
  accountInformation: 'ข้อมูลบัญชี',
  planBilling: 'แพลนและราคา',
  powerBy: 'Powered By',
  category: 'Category Tag',
  hub: 'Hub'
};
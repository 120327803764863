import React, { useEffect, useState } from 'react';
import { showErrorMessage } from 'lib/notifier';

// redux
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { searchPlaces, getPlaceDetails } from 'redux/location';

// components
import {
  Grid,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Box,
  ClickAwayListener,
} from '@material-ui/core';
import { TextInput } from 'components/inputs';
import SwitchButton from '../switch';
import Category from '../category';

import GoogleSrc from 'assets/images/google.svg';
import AvatarDefault from 'assets/images/enterprise/avatar-default.svg';
import UploadIcon from 'assets/images/enterprise/upload.svg';
import styles from './form.module.scss';
import Countries from 'lib/countryList';

const countryList = new Countries();
const countryCodeOptions = countryList.countryCodes();
const ALLOW_FILE_TYPES = ['jpg', 'jpeg', 'png', 'svg', 'heic'];
const FORM_NAME = 'enterpriseForm';
const SEARCH_TIMEOUT = 800;
let timer = 0;

const validateFileType = (file = {}) => {
  const { name, type } = file;
  const fileExtArray = name.split('.');
  const fileExt =
    fileExtArray[fileExtArray.length - 1] &&
    fileExtArray[fileExtArray.length - 1].toLowerCase();
  const error = type || fileExt;
  return ALLOW_FILE_TYPES.includes(fileExt) ? null : error;
};

function parsePlaceDetails(place) {
  let placeAddress = place.formatted_address;
  if (place.name && !place.formatted_address.includes(place.name)) {
    placeAddress = `${place.name}, ${place.formatted_address}`;
  }
  const location = {
    address: placeAddress,
    placeId: place.place_id,
    lat: place.geometry.location.lat,
    lng: place.geometry.location.lng,
    name: place.name,
  };
  const addressComponents = place.address_components;
  const floor = addressComponents.find((item) => item.types.includes('floor'));
  const room = addressComponents.find((item) => item.types.includes('room'));
  const city = addressComponents.find((item) =>
    item.types.includes('locality')
  );
  const postalCode = addressComponents.find((item) =>
    item.types.includes('postal_code')
  );
  const state = addressComponents.find((item) =>
    item.types.includes('administrative_area_level_1')
  );
  const country = addressComponents.find((item) =>
    item.types.includes('country')
  );
  location.unitNumber = [floor && floor.long_name, room && room.long_name]
    .filter(Boolean)
    .join(' ');
  location.city = (city && city.long_name) || '';
  location.postalCode = (postalCode && postalCode.long_name) || '';
  location.state = (state && state.long_name) || '';
  location.countryCode = (country && country.short_name) || '';
  return location;
}

const fileToDataUri = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

const EnterpriseForm = (props) => {
  const {
    t,
    setIsReadyToSubmit,
    pristine,
    invalid,
    submitting,
    placeSearchOptions,
  } = props;
  const [imgSrc, setImgSrc] = useState('');
  const [isSearchingPlace, setIsSearchingPlace] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pristine || invalid || submitting) return setIsReadyToSubmit(false);
    setIsReadyToSubmit(true);
  }, [pristine, invalid, submitting]);

  const renderImages = ({ input }) => {
    const onSubmit = async (file) => {
      const filesLength = file.length;
      if (!filesLength) return null;
      let error = null;
      for (let i = 0; i < filesLength; i += 1) {
        error = validateFileType(file[i]);
        if (error) break;
      }
      if (error) return showErrorMessage(`Invalid file format: ${error}`);
      dispatch(change(FORM_NAME, 'sourceFile', file));
      const dataUri = await fileToDataUri(file[0]);
      setImgSrc(dataUri);
    };

    return (
      <label className={styles.uploadCursor} htmlFor="upload-photo">
        <Grid
          item
          container
          justify="center"
          direction="column"
          alignItems="center"
        >
          <input
            style={{ display: 'none' }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            onChange={(e) => onSubmit(e.target.files)}
          />
          <img
            className={styles.avatar}
            src={imgSrc || input.value || AvatarDefault}
            alt="AvatarDefault"
          />
          <Grid item className={styles.uploadWrapper}>
            <img src={UploadIcon} alt="UploadIcon" />
            <Typography>{t('upload')}</Typography>
          </Grid>
        </Grid>
      </label>
    );
  };

  const addressSearching = (event) => {
    const textSearch = event.target.value;
    clearTimeout(timer);
    if (!textSearch) {
      setIsSearchingPlace(false);
      return setSearchResults(null);
    }
    setIsSearchingPlace(true);
    // eslint-disable-next-line no-return-assign
    return (timer = setTimeout(async () => {
      const response = await dispatch(
        searchPlaces(textSearch, placeSearchOptions)
      ).catch((error) => ({ error }));
      setIsSearchingPlace(false);
      if (response && response.error) return showErrorMessage(response.error);
      if (response && response.status !== 200)
        return showErrorMessage(response.data && response.data.message);
      const { predictions } = response.data;
      setSearchResults(predictions);
    }, SEARCH_TIMEOUT));
  };

  const handleSelectLocation = ({
    countryCode,
    postalCode,
    city,
    address,
    state,
  }) => {
    dispatch(change(FORM_NAME, 'country', countryCode));
    dispatch(change(FORM_NAME, 'postalCode', postalCode));
    dispatch(change(FORM_NAME, 'city', city));
    dispatch(change(FORM_NAME, 'address', address));
    dispatch(change(FORM_NAME, 'state', state));
  };

  async function chooseLocation(event, location) {
    event.preventDefault();
    const response = await dispatch(
      getPlaceDetails(location.place_id)
    ).catch((error) => ({ error }));
    if (response && response.error) return showErrorMessage(response.error);
    if (response && response.status !== 200)
      return showErrorMessage(
        response && response.data && response.data.message
      );
    const { result } = response.data;
    const locationDetail = parsePlaceDetails(result);
    handleSelectLocation(locationDetail);
    setSearchResults(null);
  }

  const handleClickAway = () => setSearchResults(null);

  return (
    <Grid container className={styles.spacingVertical}>
      <Grid
        item
        container
        alignItems="center"
        sm={3}
        md={3}
        direction="column"
        className={styles.rightSpacing}
      >
        <Field name="avatar" component={renderImages} />
        <Grid item className={styles.textWrapper}>
          <Typography align="center">{t('helpRecognize')}</Typography>
          <Typography align="center" className={styles.accepted}>
            {t('acceptedFormat')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item sm={5} md={5}>
        <Field
          name="registeredName"
          component={TextInput}
          inputClass={styles.noneTopMargin}
          label={t('registerName')}
          variant="outlined"
          margin="dense"
        />
        <Field
          name="tradingName"
          component={TextInput}
          label={t('tradingName')}
          margin="dense"
          variant="outlined"
        />

        <Grid container className={styles.topSpacing}>
          <Typography variant="h5">{t('registerAddress')}</Typography>
          <Field
            name="address"
            component={TextInput}
            label={t('address')}
            margin="dense"
            variant="outlined"
            autoComplete="nickname"
            onChange={(e) => addressSearching(e)}
            InputProps={{
              endAdornment: isSearchingPlace && (
                <CircularProgress size={25} position="end" color="secondary" />
              ),
            }}
          />
          {Array.isArray(searchResults) && !!searchResults.length && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Grid container className={styles.container}>
                <div className={styles.addressWrapper}>
                  <Grid className={styles.addressList}>
                    <List>
                      {searchResults.map((item) => {
                        return (
                          <ListItem
                            key={item.place_id}
                            className={styles.itemAddress}
                            onClick={(e) => chooseLocation(e, item)}
                          >
                            <ListItemText
                              primary={
                                item.structured_formatting &&
                                item.structured_formatting.main_text
                              }
                              secondary={
                                item.structured_formatting &&
                                item.structured_formatting.secondary_text
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Box
                    alignItem="center"
                    display="flex"
                    justifyContent="center"
                    p={1}
                  >
                    <Typography
                      variant="subtitle1"
                      className={styles.poweredBy}
                    >
                      {t('Common:poweredBy')}
                    </Typography>
                    <img className="ml_5" src={GoogleSrc} alt="GoogleSrc" />
                  </Box>
                </div>
              </Grid>
            </ClickAwayListener>
          )}

          <Field
            name="address_line_2"
            component={TextInput}
            label={t('address_line_2')}
            margin="dense"
            variant="outlined"
          />

          <Grid container item spacing={2}>
            <Grid item md={6} sm={6}>
              <Field
                name="city"
                component={TextInput}
                label={t('city')}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6}>
              <Field
                name="state"
                component={TextInput}
                label={t('state')}
                margin="dense"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item md={6} sm={6}>
              <Field
                name="postalCode"
                component={TextInput}
                label={t('postalCode')}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6}>
              <Field
                name="country"
                component={TextInput}
                label={t('countryCode')}
                select
                SelectProps={{ native: true }}
                margin="dense"
                variant="outlined"
              >
                <option key="" value="">
                  {' '}
                </option>
                {countryCodeOptions &&
                  countryCodeOptions.length &&
                  countryCodeOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </Field>
            </Grid>
          </Grid>

          <Grid className={styles.buttonGroup}>
            <Field
              name="accountStatus"
              component={SwitchButton}
              label={t('accountStatus')}
              options={[
                { key: true, name: t('active') },
                { key: false, name: t('deactivated') },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={4} md={4} className={styles.rightSpacing}>
        <Field name="currentCategories" t={t} component={Category} />
      </Grid>
    </Grid>
  );
};

const getSearchOptions = ({ auth = {} }) => {
  const { currentUser } = auth;
  let language = 'en';
  let placeSearchOptions = {
    language,
  };
  // pick first company in list for country code
  const companies = (currentUser && currentUser.companies) || [];
  if (
    Array.isArray(companies) &&
    companies.length > 0 &&
    companies[0].countryCode
  ) {
    const { countryCode } = companies[0];
    // get language from user first, if not have get from countrycode
    language =
      currentUser && currentUser.language
        ? currentUser.language
        : countryList.getLanguageCodeByCountryCode(countryCode);
    placeSearchOptions = {
      ...placeSearchOptions,
      language,
      components: `country:${countryCode}`,
    };
  }
  return placeSearchOptions;
};

const mapStateToProps = (state, ownProps) => {
  const { companyDetails = {} } = ownProps;
  return {
    initialValues: {
      registeredName: companyDetails.name,
      tradingName: companyDetails.tradingName,
      address: companyDetails.address,
      address_line_2: companyDetails.addressLine2,
      city: companyDetails.city,
      state: companyDetails.state,
      postalCode: companyDetails.zipCode,
      country: companyDetails.countryCode,
      accountStatus: companyDetails.active || false,
      currentCategories: companyDetails.categories,
      avatar: companyDetails.profilePicture,
    },
    placeSearchOptions: getSearchOptions(state),
  };
};

const REQUIRED_FIELDS = [
  'registeredName',
  'address',
  'city',
  'state',
  'postalCode',
  'country',
];

const validate = (values) => {
  const errors = {};
  REQUIRED_FIELDS.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'required';
    }
  });
  return errors;
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
  })
)(EnterpriseForm);
